@import '~theme/modules/variables';
@import '~theme/mixins';

.roiSection {
  padding: 60px 0 18px;
  background: #77c082;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    padding-right: 118px;
  }

  .titleContent {
    display: flex;
    flex-direction: column;
    max-width: 618px;
    width: 100%;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 17px;
  }

  .desc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
  }

  .moreStatsBtn {
    width: 276px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: white;
    font-size: 15px;
    color: black;
    font-weight: 600;
    margin-top: 65px;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 32px;

    .list__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 390px;
    }

    .item_header {
      display: flex;
      align-items: flex-end;

      .item_value {
        color: white;
        font-family: iorad;
        font-size: 204px;
        font-weight: 400;
        line-height: 67%;
      }

      .item_unit {
        margin-left: 6px;
        color: white;
        font-size: 32px;
        font-weight: 600;
        line-height: 37px;
        margin-bottom: 7px;
      }

      .item_title {
        margin-left: 15px;
        color: black;
        font-size: 21px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 10px;
      }
    }

    .item_content {
      color: black;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      margin-top: 2px;
    }
  }

  .data_source {
    margin: 0;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 136%;
    color: black;
  }
}

@include breakpoint-sm {
  .roiSection {
    padding: 57px 0 28px;

    .contentWrapper {
      max-width: 100%;
      padding: 0 24px;
    }

    .titleWrapper {
      flex-direction: column;
      padding-right: 0;
    }

    .titleContent {
      max-width: 100%;
    }

    .title {
      font-size: 21px;
      margin-bottom: 18px;
    }

    .desc {
      font-size: 13px;
    }

    .moreStatsBtn {
      max-width: 295px;
      margin: 0 auto;
      width: 100%;
      font-size: 13px;
      margin-top: 72px;
    }

    .list {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 50px 0;
      margin-top: 57px;

      .list__item {
        max-width: 100%;
      }

      .item_header {
        flex-wrap: wrap;

        .item_value_img {
          height: 145px;
          width: auto;
        }

        .item_unit {
          margin-left: 27px;
          font-size: 21px;
          line-height: 24px;
          margin-bottom: 6px;
        }

        .item_title {
          flex-grow: 1;
          margin-left: 0;
          margin-bottom: 0;
          margin-top: 11px;
        }
      }

      .item_content {
        font-size: 13px;
        line-height: 18px;
        margin-top: 21px;
      }
    }

    .data_source {
      display: block;
      margin-left: auto;
      margin-top: 20px;
      font-size: 10px;
      line-height: 150%;
    }
  }
}
