@import '../../variables';

.privacy {
  display: flex;
  flex-direction: column;
  height: 100%;

  .privacy__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: $defaultHeightBtn;

    .privacy__content_inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: $defaultWidthContent;
    }
  }

  .privacy__list {
    display: flex;
    flex-direction: column;

    .privacy__list_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      padding: 16px;
      gap: 16px;

      &.active {
        svg {
          visibility: visible;
        }
      }

      .privacy__list_item_label {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        flex-grow: 1;

        label {
          @include defaultFont;
          font-weight: 600;
          margin: 0;
          flex-grow: 1;
        }

        span {
          @include defaultFont;
          color: rgba(0, 0, 0, 0.4);
        }
      }

      svg {
        visibility: hidden;
      }

      button {
        background: none;
        border: none;
        font-weight: 600;

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .privacy {
    padding-left: 24px;
    padding-right: 13px;

    .privacy__list {
      .privacy__list_item {
        label {
          min-width: 100px;
        }
      }
    }
  }
}

.list {
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    background: white;
    border-radius: 8px;
    transition: box-shadow 0.3s;
  }

  button {
    color: rgba(0, 0, 0, 0.6);
  }
}

.popup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
  display: flex;
  flex-direction: column;

  button {
    background: none;
    border: none;

    &:hover {
      color: var(--site-theme-color);
    }

    &.disabled, &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h4 {
      @include defaultFont;
      font-weight: 600;
      margin: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: white;
    height: 100%;
    overflow-y: auto;
    flex-grow: 1;
    gap: 16px;
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: sticky;
  top: -56px;
  background: #FFF;
  z-index: 3;

  label {
    @include defaultFont;
    font-family: Libre Franklin;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
    color: rgba(0, 0, 0, 0.5);
    ;
  }

  input {
    width: 100%;
    background: none;
    border: 0 none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    padding: 16px;
    padding-right: 40px;
    padding-left: 0;
    height: auto;

    &:hover, &:focus {
      border-color: black;
    }
  }

  button {
  }
}

.inputGroup {
  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
  }
}