@import 'base';
@import '~components/Account/partials';
@import '~theme/modules/variables';
@import '~theme/mixins';

$pad: 16px;

.my_plan {
  font-family: $fontBaseV2;
  margin-top: 40px;
  padding-bottom: 100px;
  display: flex;

  @include breakpoint-sm {
    margin-top: 24px;
    padding-bottom: 40px;
    flex-direction: column;
  }
}

.wrapper {
  width: 500px;

  @include breakpoint-sm {
    width: 100%;
  }
}

.planName {
  font-family: 'Fahkwang';
  font-weight: 500;
  font-size: 35px;
  color: black;
  margin-bottom: 20px;

  @include breakpoint-sm {
    font-size: 21px;
    line-height: 24px;
    word-break: break-word;
  }
}

.nextInvoiceDate {
  color: #000000;
  opacity: 0.4;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
}

.chargeAmountContainer {
  color: #000000;
  font-weight: 600;
  font-size: 21px;
  margin-bottom: 5px;
}

.creators {
  color: #000000;
  font-size: 15px;
  margin-bottom: 40px;
}

.buttonContainer {
  display: block;
}

.button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  width: 240px;
  margin-bottom: 10px;
  text-align: center;
  color: #000000;
  display: block;
  font-size: 15px;
  font-weight: 600;

  &.disabled {
    cursor: default;
    color: #000000;
  }

  &:not(.disabled) {
    &[href]:hover,
    &:focus {
      text-decoration: none;
      color: var(--site-theme-color);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.black_link {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 136%;
  color: #000000;
  cursor: pointer;
  margin-top: 40px;
  transition: color 0.3s ease;
  position: relative;

  svg {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;

    > {
      path,
      circle {
        transition: all 0.3s ease;
      }
    }
  }
  &.disabled {
    cursor: default;
  }

  &:not(.disabled):hover {
    color: var(--site-theme-color);

    svg > {
      path {
        fill: var(--site-theme-color);
      }
    }
  }

  &.link_cancel {
    padding-left: 38px;
    svg {
      top: 2px;
    }
  }
}

.subtitle {
  font-size: 15px;
  line-height: 136%;
  color: #000000;
  margin-bottom: 2px;
  margin-top: 10px;
}

.subscriptionForm {
  width: 500px;
  max-width: 100%;
  position: relative;
  padding-right: 100px;
  margin-top: 50px;

  @include breakpoint-sm {
    padding-right: 0;
    padding-bottom: 20px;
    width: 100%;
  }
}

.separator {
  content: '';
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  height: 312px;
  width: 1px;
  right: 0;
  top: -167px;

  @include breakpoint-sm {
    width: 100%;
    height: 1px;
    bottom: 0;
    top: initial;
    left: 0;
  }
}

.creatorWrapper {
  margin-bottom: 40px;
}

.checkoutForm {
  padding-left: 88px;
  margin-top: 100px;
  width: 430px;

  @include breakpoint-sm {
    padding-left: 0;
    margin-top: 20px;
    padding-top: 20px;
    width: 100%;
  }
}

.newCostWrapper {
  margin-bottom: 35px;
}

.checkoutBtn {
  margin: 25px 50px 0 0;
}

.next_invoice {
  margin-top: 15px;
  line-height: 25px;
  text-align: left;
  font-size: 15px;

  @include breakpoint-sm {
    font-size: 13px;
  }
}

.exportLinkGroup {
  display: none;

  &.account_setting {
    padding-bottom: 30px;
    @include breakpoint-sm {
      padding-bottom: 0;
      display: block;
      margin-top: 50px;

      .export {
        margin-bottom: 10px;
      }
    }
  }

  @include breakpoint-sm {
    &.mobile {
      display: block;
    }
  }

  @include breakpoint-md-plus {
    &.desktop {
      display: block;
      margin-top: 20px;
    }
  }
}
.export {
  text-align: left;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .blue_link {
    width: 100%;
    height: 100%;
    display: inline;
    text-align: left;
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.3s ease;
    position: relative;
    padding-left: 38px;

    @include breakpoint-sm {
      font-size: 15px;
      font-weight: 400;
    }

    svg {
      position: absolute;
      margin: 0;
      left: 0;
      top: 0;

      > {
        path,
        circle {
          transition: all 0.3s ease;
        }
      }
    }

    &:hover {
      text-decoration: none;
      color: var(--site-theme-color);

      &.link_export {
        svg > {
          path {
            fill: var(--site-theme-color);
          }
        }
      }
      &.link_eye_closed {
        svg > {
          path {
            stroke: var(--site-theme-color);
          }
          circle {
            fill: var(--site-theme-color);
          }
        }
      }
      &.link_change_password {
        svg > {
          path {
            fill: var(--site-theme-color);
          }
        }
      }
      &.link_manage_2FA {
        svg > {
          path {
            fill: var(--site-theme-color);
          }
        }
      }
      &.link_delete_account {
        svg > {
          circle {
            fill: var(--site-theme-color);
          }
          path:nth-child(2) {
            stroke: var(--site-theme-color);
          }
          path:nth-child(3) {
            fill: var(--site-theme-color);
          }
        }
      }
    }
  }
}
