@import "~theme/mixins";
@import "~theme/guidelines_variables";

.frequency {
	display: flex;
  width: 100%;
	margin: 0 auto;

  label {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    color: #000000;
    position: relative;
    margin-right: 8px;

    &:global(.checked) {

      &.plan_changed {
        border-color: $blue;
      }
    }

    input:checked ~ div::before {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    input:checked ~ div::after {
      display: block;
      width: 8px;
      height: 8px;
      background-color: #000000;
      left: 4px;
      top: 5px;
    }

    input:checked ~ .inputText {
      font-weight: bold;
    }

    .inputText {
      margin-left: 28px;
    }

    & > div {
      flex: 1;

      &::before, &::after {
        display: block;
        position: absolute;
        left: 0;
        top: 1px;
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: #F2F2F2;
      }

      &::after {
        display: none;
      }
    }

    input {
      display: none;
    }
  }

	@include breakpoint-md-plus {
    flex-direction: row;

    label {
      margin-bottom: 5px;
    }
  }
  

  @include breakpoint-sm {
    label {
      font-size: 13px;
    }
	}
}

.yearlyPlan {
  display: flex;
  margin-right: 17px;
}

.discountWrapper {
  width: 107px;
}

.discount {
  color: var(--site-theme-color);
  font-size: 15px;
  line-height: 136%;
}
