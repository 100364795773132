@import '../../variables';

.language {
  display: flex;
  flex-direction: column;
  height: 100%;

  .language__header {
    input {
      padding: 0 16px;
    }
  }

  .language__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: $defaultHeightBtn;

    .language__content_inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: $defaultWidthContent;
    }
  }

  .language__list {
    display: flex;
    flex-direction: column;

    .language__list_empty {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      @include defaultFont;
    }

    .language__list_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 24px;
      flex-shrink: 0;
      height: $defaultHeightBtn;
      padding: 0 16px;
      background: white;

      span {
        @include defaultFont;
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .language {
    padding-left: 24px;
    padding-right: 13px;

    .language__header {
      padding-top: 24px;
    }

    .language__content {
      padding-bottom: 24px;
    }
  }
}
