@import '~theme/modules/variables';

.showCreateHelpcenterPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $topNavBarZIndex + 2;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .showCreateHelpcenterPopup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    background: white;
    padding: 56px 80px 40px;

    h3 {
      font-size: 21px;
      font-weight: 600;
      line-height: 24px;
      color: black;
      margin: 0;
      margin-top: 40px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: black;
      margin: 0;
      margin-top: 30px;
    }
  }

  .showCreateHelpcenterPopup__submitBtn {
    outline: none;
    border: none;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    padding: 10px;
    height: 56px;
    width: 246px;
    color: white;
    transition: background 0.3s ease;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 40px;

    &:hover {
      background: var(--site-theme-color);
    }
  }

  .showCreateHelpcenterPopup__dismissBtn {
    outline: none;
    border: none;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 56px;
    width: 246px;
    color: black;
    transition: color 0.3s ease;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 10px;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}
