@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 145px;
  overflow-x: hidden;

  .titleWrapper {
    background: #2891be;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 52px 64px 45px 55px;
  }

  .titleContent {
    @include ioradFont;
  }

  .you_ask {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 77px 64px 0;
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: black;
      margin: 0;
      margin-bottom: 23px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      max-width: 622px;
      margin: 0;
    }
  }

  .chapters {
    margin-bottom: 58px;
    padding-top: 69px;
    padding-left: 64px;
    padding-right: 143px;
    display: flex;

    @media screen and (min-width: 1366px) {
      margin-left: calc((100% - 1366px) / 2);
    }
  }

  .chapterTabs {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    width: 222px;
    flex-shrink: 0;
  }

  .chapterTab {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
    transition: color 0.3s ease;
    cursor: pointer;
    flex-shrink: 0;

    &:hover,
    &.actived {
      color: var(--site-theme-color);
    }
  }

  .chapterContentWrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 22px 0 17px 20px;
    margin-left: 78px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background: $backgroundTiles;
      z-index: -1;
    }
  }

  .chapterContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > div {
      display: none;

      &.visible {
        display: block;
      }
    }

    iframe {
      width: 827px;
      height: 464px;
      border: none;
    }

    h4 {
      margin: 0;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }

    p {
      max-width: 727px;
      margin: 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;

      a {
        font-weight: 600;

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .meet_the_square {
    .meet_the_square__desc {
      margin-top: 44px;
    }
  }

  .getting_started {
    h4 {
      margin-bottom: 12px;
      &:first-child {
        margin-top: 12px;
      }
    }
    p {
      margin-bottom: 17px;
    }
    iframe {
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .code_of_conduct {
    h4 {
      margin-bottom: 5px;
      margin-top: 73px;

      &:first-child {
        margin-top: 12px;
      }
    }

    .code_of_conduct__desc {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .code_of_conduct__desc_section {
      display: flex;
      flex-direction: column;
      max-width: 616px;
      row-gap: 20px;

      div {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        flex-shrink: 0;
      }
    }

    > p {
      max-width: 616px;
      margin-top: 73px;

      a {
        text-decoration: underline;
      }

      &:last-child {
        margin-top: 74px;
      }
    }
  }

  .ask_questions {
    p {
      margin-top: 12px;
      margin-bottom: 51px;
    }
  }

  .answer_questions {
    p {
      margin-top: 12px;
      margin-bottom: 51px;
    }
  }

  .awards {
    p:first-child {
      margin-top: 12px;
      margin-bottom: 44px;
    }

    img {
      max-width: 918px;
      margin-bottom: 44px;
    }

    ul {
      list-style-type: disc;
      padding-left: 17px;
      margin-top: 23px;
      margin-bottom: 39px;

      li {
        font-size: 15px;
        line-height: 20px;
        color: black;
      }
    }
  }

  .leave_feedback {
    p:first-child {
      margin-top: 12px;
      margin-bottom: 21px;
    }

    span {
      display: block;
      margin-top: 19px;
      font-size: 15px;
      line-height: 20px;
      color: black;
      font-weight: 600;
    }

    .leave_feedback__img {
      display: flex;
      justify-content: flex-end;
      margin-top: 43px;

      img {
        max-width: 643px;
      }
    }
  }

  .chapterNavigations {
    display: flex;
    justify-content: space-between;
    margin-top: 83px;
  }

  .chapterNavigation {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: 19px;

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      svg path {
        stroke: var(--site-theme-color);
      }

      span {
        color: var(--site-theme-color);
      }
    }

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 170px 143px 140px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 84px;

    .title {
      padding: 66px 0 105px;
    }

    .you_ask {
      max-width: 100%;
      padding: 30px 24px 0;

      h3 {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        max-width: 100%;
      }
    }

    .chapters {
      margin-bottom: 43px;
      padding: 0;
      padding-top: 38px;
      flex-direction: column;
      position: relative;
    }

    .chapterTabs {
      align-items: flex-end;
      padding: 0 24px 25px;
      position: absolute;
      right: 0;
      top: 38px;
      z-index: 2;
      width: 100%;
      background: white;

      &.isShowDropdown {
        .chapterTab {
          display: block;

          &.actived svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .chapterTab {
      display: none;

      &.actived {
        display: block;
        color: black;
        order: -1;

        svg {
          margin-left: 19px;
        }
      }
    }

    .chapterContentWrapper {
      margin: 0;
      margin-top: 45px;
      padding: 40px 24px 25px;
      background: $backgroundTiles;

      &:after {
        content: unset;
      }
    }

    .chapterContent {
      p {
        font-size: 13px;
        line-height: 18px;
        max-width: 100%;
      }

      iframe {
        width: 100%;
        height: 584px;
      }
    }

    .meet_the_square {
      iframe {
        height: 164px;
        margin-top: 13px;
      }

      .meet_the_square__desc {
        margin-bottom: 41px;
      }
    }

    .getting_started {
      iframe {
        margin-bottom: 25px;
      }
      h4 {
        margin-bottom: 9px;
        margin-top: 38px;
        &:first-child {
          margin-top: 0;
        }
      }
      p {
        margin-bottom: 21px;
      }
    }

    .code_of_conduct {
      > p {
        margin-top: 59px;
        &:last-child {
          margin-top: 59px;
        }
      }
      h4 {
        margin-bottom: 8px;
        margin-top: 59px;
        &:first-child {
          margin-top: 0;
        }
      }

      .code_of_conduct__desc {
        row-gap: 22px;
      }

      .code_of_conduct__desc_section {
        flex-direction: column;
        row-gap: 22px;

        div {
          width: 100%;
          row-gap: 2px;
        }
      }
    }

    .ask_questions {
      p {
        margin-top: 0;
      }
    }

    .answer_questions {
      p {
        margin-top: 0;
      }
    }

    .awards {
      p {
        &:first-child {
          margin-top: 0;
          margin-bottom: 15px;
        }
      }

      img {
        margin-bottom: 40px;
        max-width: 100%;
      }

      ul {
        margin-top: 17px;
        margin-bottom: 17px;
        padding-left: 21px;

        li {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    .leave_feedback {
      p {
        &:first-child {
          margin-top: 0;
          margin-bottom: 22px;
        }
      }

      span {
        font-size: 13px;
        line-height: 18px;
        margin-top: 22px;
      }

      .leave_feedback__img {
        margin-top: 10px;
        justify-content: center;

        img {
          max-width: 100%;
        }
      }
    }

    .chapterNavigations {
      margin-top: 60px;
    }

    .chapterNavigation {
      span {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .learnMoreBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
