@import '~theme/modules/variables';
@import '~theme/mixins';

.successStoriesSection {
  margin-top: 139px;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: black;
    margin-bottom: 22px;
  }

  .desc {
    font-size: 15px;
    line-height: 136%;
    color: black;
    width: 100%;
    max-width: 618px;
    margin-bottom: 85px;
  }

  .stories_list {
    display: flex;
    flex-wrap: wrap;
    gap: 60px 20px;

    .stories_item {
      display: flex;
      flex-direction: column;
      width: 294px;

      img {
        width: 100%;
        height: 147px;
      }

      h5 {
        margin: 0;
        font-size: 21px;
        line-height: 24px;
        color: black;
        font-weight: 600;
        margin-top: 30px;
        min-height: 48px;
      }

      p {
        font-size: 15px;
        line-height: 136%;
        color: black;
        margin: 0;
        margin-top: 5px;
      }

      button {
        border: none;
        outline: none;
        padding: 0;
        background-color: transparent;
        display: flex;
        align-items: center;
        column-gap: 24px;
        margin-top: 13px;

        &:hover {
          svg path {
            fill: var(--site-theme-color);
          }

          span {
            color: var(--site-theme-color);
          }
        }

        span {
          font-size: 15px;
          font-weight: 600;
          color: black;
        }
      }
    }
  }

  .see_all {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    a {
      font-size: 15px;
      font-weight: 600;
      text-decoration: underline;
      color: black;
    }
  }

  .videoPopup {
    position: fixed;
    z-index: 40;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 86px 0;
    background-color: #000;
    z-index: #{$embedButtonZIndex + 1};

    .videoContent {
      height: 100%;
    }

    .closeBtn {
      position: absolute;
      right: 64px;
      top: 24px;
      cursor: pointer;
    }
  }
}

@include breakpoint-sm {
  .successStoriesSection {
    margin-top: 119px;

    .contentWrapper {
      max-width: 100%;
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      margin-bottom: 18px;
    }

    .desc {
      font-size: 13px;
      max-width: 100%;
      margin-bottom: 71px;
    }

    .stories_list {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 39px 0;

      .stories_item {
        width: 100%;

        img {
          height: 163px;
        }

        h5 {
          min-height: auto;
        }

        p {
          font-size: 13px;
          margin-top: 21px;
        }

        button {
          column-gap: 21px;
          margin-top: 16px;

          span {
            font-size: 13px;
          }
        }
      }
    }

    .see_all {
      margin-top: 16px;

      a {
        font-size: 13px;
      }
    }

    .videoPopup {
      padding: 0;

      .closeBtn {
        right: 24px;
      }

      .videoContent {
        display: flex;

        iframe {
          max-height: 211px;
          margin: auto 0;
        }
      }
    }
  }
}
