@import '../variables';

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  height: 80px;
  background-color: white;
  padding: 0 40px;

  &.center {
    justify-content: center;
  }

  &:not(.center) {
    label.next {
      margin-left: auto;
    }
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 24px;

    span {
      @include defaultFont;
      color: black;

      b {
        font-weight: 600;
      }
    }

    &.back {
      span {
        font-weight: 600;
      }
      svg {
        transform: rotate(90deg);
      }
    }

    &.next {
      svg {
        transform: rotate(270deg);
      }
    }
  }
}