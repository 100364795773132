@import '~theme/mixins';

.text_field {
  text-align: left;
  padding: 22px 24px;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid transparent;
  display: block;
  color: #000000;
  background-color: #F2F2F2;
  border-radius: 5px;
  width: 100%;
  min-width: 130px;
  height: 64px;

  @include breakpoint-sm {
    height: 40px;
    padding: 18px 13px;
  }

  &::placeholder { /* Chrome/Opera/Safari */
    color: #828282;
  }

  &.error {
    border: 1px solid #FF6363 !important;
  }

  &.filled {
    border: 1px solid #F2F2F2;
    background-color: #FFFFFF;
  }
}
