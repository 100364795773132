@import '../../theme/guidelines_variables';
@import '../Auth/page';
@import '~theme/mixins';
@import '~theme/modules/variables';

.contact_us {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: max-content;
  padding-top: 102px;
  background-color: #f4f8fa;

  &.user {
    padding-top: 24px;
    height: auto;
  }

  &.leaveReview {
    padding-top: 10px;

    .formSpace {
      max-width: 100%;
      margin: 0 auto;
      padding: 0;
    }

    .contactUsFormIframe {
      height: 1470px;
    }

    .contactUsFormThankYou {
      margin: 100px 0;
    }
  }

  .contactInfo {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
    width: 100%;

    * {
      text-align: left;
    }
  }

  .titleContact {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 22px;
  }

  .descContact {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 20px;
  }

  .infoWrapper {
    display: flex;
    column-gap: 42px;
    font-size: 15px;
    line-height: 136%;
    color: #000000;

    .link {
      font-weight: 600;
      cursor: pointer;
    }
  }

  .formSpace {
    max-width: $maxWidthContent;
    margin: 71px auto 58px;
    padding: 0 64px;
    width: 100%;

    &.trialsForm {
      margin-top: 0;
    }
  }

  .contactUsFormIframe {
    width: 100%;
    height: 1105px;
    background-color: transparent;
  }

  .contactUsFormThankYou {
    width: 100%;

    &_inner {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
      margin-top: 30px;
      background: #fff;
      box-shadow: 0 4px 8px 0 rgba(53, 105, 128, .3), 0 6px 20px 0 rgba(165, 200, 213, .41);
      padding: 40px;
      font-size: 16px;
      color: #000000;

      p {
        margin-block-start: 16px;
        margin-block-end: 16px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }
    }
  }
}

.backgroundGradient {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-bottom: 118px;
  height: 116px;

  @include breakpoint-sm {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_small.png');
    background-size: 100% 115px;
    margin-bottom: 182px;
  }
}

@include breakpoint-sm {
  .contact_us {
    height: auto;
    min-height: 500px;
    padding-top: 14px;

    .contactInfo {
      padding: 0 24px;
    }

    .titleContact {
      font-size: 21px;
      margin-bottom: 21px;
    }

    .descContact {
      font-size: 13px;
      margin-bottom: 18px;
    }

    .infoWrapper {
      gap: 13px 29px;
      font-size: 13px;
      flex-wrap: wrap;

      .link {
        font-size: 13px;
        text-decoration: underline;

        &::after {
          content: unset;
        }

        &:nth-child(1),
        &:nth-child(2) {
          min-width: 92px;
        }

        &:nth-child(2) {
          order: 1;
        }

        &:nth-child(3) {
          min-width: 125px;
        }
      }

      a:nth-child(4) {
        order: 2;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .formSpace {
      margin: 55px 0;
      padding: 0 24px;
      width: 100%;
    }
  }
}
