@import '~theme/mixins';

.form {
  max-width: 900px;
  margin: 0 auto;

  @include breakpoint-sm {
    margin: 0 35px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    .input_label {
      display: flex;
      flex-direction: column;

      .labelText {
        font-weight: 300;
        line-height: 15px;
        font-size: 10px;
        color: #000000;
        opacity: 0.4;

        &.onRight {
          padding-left: 5px;
        }
      }

      :global(.easy-textfield) {
        width: 100%;
        margin-bottom: 23px;
      }

      .label-text {
      }
    }

    :global(.easy-checkbox) {
      margin: 4px 5px 0 0;
      top: 2px;

      &:after {
        background-color: #eee;
      }
    }
  }

  .input_row {
    display: flex;
    column-gap: 15px;

    @include breakpoint-sm {
      flex-direction: column;
    }
  }

  .buttonBar {
    display: flex;
    justify-content: center;
  }
}
