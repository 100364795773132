$blue: #0047ff;
$darkblue: #004cba;
$linkblue: #0047ff;
$lightblue: #22baef;
$red: #e23b3b;
$gold: #ffe2a9;
$darkgold: #fe9e31;
$lightgrey: #e2e2e2;
$lightergrey: #eeeeee;
$lightgreytutorials: #f7f7f7;
$buttonGreyBorder: #e0e0e0;
$grey: #c8c8c8;
$darkgrey: #999;
$darkergrey: #828282;
$blackgrey: #515151;
$black: #111;
$white: #fff;
$green: #00b20d;
$darkgreen: #27ae60;
$lightgreen: #edfaee;
$labelhover: #808080;
$purple: #9379ba;
$warningColor: #8a6d3b;
$fontBaseV2: 'Libre Franklin', sans-serif;
$fontFranklin: 'Libre Franklin', sans-serif;
$fontFahkwang: 'Fahkwang', sans-serif;
$topNavBarZIndex: 20;
$topNavBarMoreShownZIndex: 30;
$topNavBarHeight: 92px;
$topNavBarHeightLogged: 48px;
$topNavBarHeightMobile: 58px;
$embedButtonZIndex: 2147483001;
$scheduleDemoBackground: #f5f8fa;
$blogNewBackground: #f5f8fa;
$maxWidthContent: 1366px;
$backgroundTiles: #f4f4f4;
$maxWidthSocialContent: 900px;
$socialSidebarWidth: 400px;
$socialOverlayZIndex: 10;
$topbarMobileHeight: 40px;
$topbarMediaPopupHeight: 85px;
