@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  width: 100%;
  max-width: 810px;
  padding-top: 36px;
  display: flex;

  * {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }
}

.content {
  position: relative;
}

:global(.ReactCollapse--collapse) {
  transition: height 300ms;
}

.item {
  margin-bottom: 8px;
  display: none;

  &.show {
    display: block;
  }
}

.titleItem {
  font-weight: 600;
  font-size: 21px;
  line-height: 116%;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: var(--site-theme-color);
  }
}

.descItem {
  padding-top: 4px;
  padding-bottom: 64px;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.listItem {
  padding-left: 17px;

  li + li {
    margin-top: 20px;
  }

  strong {
    display: block;
    padding-top: 2px;
    padding-bottom: 4px;
  }
}

.subTitleItem {
  display: block;
  font-weight: 600;
  margin-top: 22px;
  margin-bottom: 3px;
}

.subListItem {
  padding-left: 17px;
}

.item.subprocessors {
  max-width: 100%;

  .descItem {
    padding-bottom: 4px;
  }
}

.listDecimal {
  padding-left: 14px;

  li {
    list-style-type: decimal;
  }
}

.tableWrapper {
  width: 100%;
  margin-left: auto;
  padding-top: 65px;

  &.supplementalNotice {
    padding-bottom: 68px;
  }

  table {
    width: 100%;
    border: none;
    table-layout: fixed;

    tr {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
      height: 53px;
    }

    th,
    td {
      padding: 14px 18px;

      &:first-child {
        width: 441px;
      }
    }

    ul {
      padding-left: 14px;
    }
  }
}

.lastUpdated {
  font-size: 10px;
  line-height: 150%;
  display: block;
  margin-top: 29px;
  margin-bottom: 70px;
}

@include breakpoint-sm {
  .container {
    max-width: 100%;
    padding-top: 50px;

    * {
      font-size: 13px;
    }
  }

  .item {
    margin-bottom: 8px;
    max-width: 100%;
    padding: 0 24px;

    &.lastItem {
      margin-bottom: 152px;
    }
  }

  .titleItem {
    font-size: 13px;
  }

  .descItem {
    padding-top: 9px;
    padding-bottom: 65px;
  }

  .listItem {
    padding-left: 16px;

    li + li {
      margin-top: 20px;
    }
  }

  .subTitleItem {
    margin-top: 18px;
    margin-bottom: 9px;
  }

  .subListItem {
    padding-left: 16px;
  }

  .item.subprocessors {
    max-width: 100%;

    .descItem {
      padding-top: 28px;
      padding-bottom: 4px;
    }
  }

  .tableWrapper {
    width: calc(100% + 48px);
    margin-left: -24px;
    padding-top: 0;
    overflow-x: auto;

    table {
      width: 100%;
      border: none;

      tr {
        height: 58px;

        &.headerTable {
          height: 40px;
        }

        th:first-child,
        td:first-child {
          padding-left: 24px;
          width: 150px;
        }

        th:last-child,
        td:last-child {
          padding-right: 24px;
          min-width: 90px;
        }
      }

      th,
      td {
        padding: 0;
        font-size: 10px !important;
        line-height: 150% !important;

        * {
          font-size: 10px !important;
          line-height: 150% !important;
        }
      }

      th {
        text-align: center;

        &:first-child {
          text-align: left;
        }
      }

      a {
        font-size: 10px;
      }
    }
  }

  .lastUpdated {
    font-size: 10px;
    padding: 0 24px;
  }
}
