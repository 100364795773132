@import '~theme/mixins';
@import '~theme/modules/variables';

@mixin mobile-style {
  .products_table_header span {
    font-size: 14px;
  }
  .products_table_header span:first-child {
    justify-content: center;
  }
  .products_table_row_inner > div:first-child {
    justify-content: center;
  }
  .products_table_row_inner > div.product_info label {
    display: none;
  }
}

.ioradical_profile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 17;

  &__header {
    display: flex;
    flex-direction: row;
    background: #f4f4f4;
    flex-shrink: 0;
    height: 48px;
    cursor: pointer;
    position: relative;

    &:hover {
      .ioradical_profile__header_title::after {
        content: 'Back';
        background: #f4f4f4;
        @include fontExtension;
        color: black;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &_back {
      width: 48px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      column-gap: 16px;
      text-align: center;
      @include fontExtension;
      color: black;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    &_left {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: $socialSidebarWidth;
      border-right: 1px solid rgba(0, 0, 0, 0.05);

      @media screen and (max-width: 1300px) {
        width: 300px;
      }
    }

    &_right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }

    @media (max-width: 767px) {
      flex-direction: column;

      &_left {
        width: 100%;
      }
    }
  }

  &__general_info {
    display: flex;
    flex-direction: column;
    padding: 24px;
    position: relative;

    &_avatar {
      width: 40px;
      height: 40px;
      position: relative;

      img {
        width: 100%;
      }

      svg {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
      }
    }

    &_name {
      font-size: 21px;
      line-height: 24px;
      font-weight: 400;
      color: black;
      margin-top: 24px;
    }

    &_actions {
      position: absolute;
      right: 0;
      top: 12px;
      z-index: 1;
      display: flex;
    }
  }

  &__copy_url_tooltip {
    background: white;
    padding: 4px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(var(--site-theme-color-rgb), 0.05);
    }

    * {
      color: var(--site-theme-color);
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__copy_url_btn {
    margin-top: 8px;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }

  &__bio {
    display: flex;
    flex-direction: column;
    &_input {
      padding: 0 24px 24px !important;
    }

    &_group_btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 24px;

      span {
        @include fontHelpCenter;

        &:hover {
          color: var(--site-theme-color);
        }

        &.ioradical_profile__bio_save {
          font-weight: 600;
          margin-right: 13px;
        }
      }
    }

    &_text {
      margin: 0;
      padding: 0 24px 24px;
      @include fontHelpCenter;
      word-break: break-word;
    }
  }

  &__points {
    display: flex;
    align-items: center;
    column-gap: 16px;
    flex-shrink: 0;
    height: 56px;
    padding: 0 24px;
    @include fontHelpCenter;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    label {
      margin: 0;
      font-weight: 400;
      flex-grow: 1;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    padding: 16px 0;

    &_search {
      display: flex;
      flex-shrink: 0;
      height: 56px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
      padding: 0 24px;

      &:focus-within {
        border-bottom-color: black;
      }

      input {
        margin: 0;
        padding: 0 16px;
        border: none;
        outline: none;
        width: 100%;
        @include fontHelpCenter;
        caret-color: var(--marker-color);
      }
    }

    &_products {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
    }

    &.isSmallScreen {
      @include mobile-style;
    }

    &.noSearch {
      .products_table_header {
        top: 0;
      }
    }
  }

  .btn {
    width: 86px;
    min-width: 86px;
    height: 40px;
    font-size: 18px;
    margin-top: 12px;
    margin-right: 24px;

    .hover_text {
      display: none;
    }

    &.active {
      &, &:hover, &:focus {
        background: none;
        color: rgba(0, 0, 0, 0.4);
      }

      &:hover {
        .default_text {
          display: none;
        }
        .hover_text {
          display: block;
        }
      }
    }
  }
}

.ioradical_menu {
  position: relative;

  &_icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  &_list {
    display: none;
    position: absolute;
    right: 100%;
    top: 10px;
    flex-direction: column;
    width: 185px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0;

    &.is_show {
      display: flex;
    }
  }

  &_item {
    display: flex;
    align-items: center;
    padding: 7px 0 7px 20px;
    @include fontHelpCenter;

    &:hover {
      span {
        color: var(--site-theme-color);
      }
    }
  }
}

.products_table {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &_header {
    display: flex;
    flex-shrink: 0;
    height: 80px;
    position: sticky;
    top: 56px;
    background: #fff;
    width: 100%;
    max-width: $maxWidthSocialContent;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      @include fontHelpCenter;
      color: rgba(0, 0, 0, 0.4);

      svg {
        opacity: 0.4;
      }

      &:first-child {
        justify-content: flex-start;
        padding-left: 24px;
      }

      &.active {
        color: #000;
        svg {
          opacity: 1;
        }
      }
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &_row {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    height: 80px;
    cursor: pointer;

    &_inner {
      display: flex;
      max-width: $maxWidthSocialContent;
      width: 100%;
      margin: 0 auto;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        @include fontHelpCenter;

        &:first-child {
          justify-content: flex-start;
          padding-left: 24px;

        }

        &.product_info {
          img {
            width: 24px;
            height: auto;
            flex-shrink: 0;
          }

          label {
            margin: 0;
            margin-left: 24px;
            font-weight: 400;
            cursor: inherit;
          }
        }
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }


  @media (max-width: 767px) {
    @include mobile-style;
  }
}


