@import '~theme/modules/variables';
@import '~theme/mixins';

.dropdownContainer {
  height: 100%;
  position: relative;

  background: white;
  font-family: "Libre Franklin", serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  font-size: 15px;
  color: black;
  width: 100%;
  max-width: 320px;
  min-width: 240px;

  .selectButton, .listBox {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    padding: 0 16px;
    border: none;
  }

  .selectButton {
    position: relative;
    background: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 0;
    gap: 20px;

    .selectButtonLabel {
      flex-grow: 1;
      white-space: nowrap;
    }

    &:focus-visible {
      outline: 0;
      box-shadow: none;
    }

    .toggleButton {
      margin: 0;
    }

    .toggleButtonOpen {
      transform: scale(-1);
    }
  }

  .listBoxContainer {
    position: relative;
    max-height: 40vh;
    background: #FFFFFF;

    .listBox {
      display: none;
      border: 1px solid rgba(0, 0, 0, 0.1);

      color: #3f403b;
      position: relative;
      left: 0;
      right: 0;
      overflow-y: auto;
      list-style-type: none;
      padding: 0;
      z-index: 2;

      &.open {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #3f403b;
        position: relative;
        max-height: 50vh;
      }

      .listItem {
        cursor: pointer;
        background: #fff;
        display: flex;
        align-items: center;
        width: 100%;
        height: 56px;
        min-height: 56px;
        max-height: 56px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0 0 0 16px;
        position: relative;
        gap: 20px;

        &:hover {
          background: #f4f4f4;
        }

        .itemLabel {
          flex-grow: 1;
          white-space: nowrap;
        }

        .itemSelected {
          padding: 13px;
        }
      }
    }
  }
}

.tooltip {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  outline: none;
  box-shadow: none;
  margin-left: -30px !important;
  margin-top: 25px !important;
  border-radius: 0;

  :global(> .arrow) {
    display: none;
  }

  :global(> .popover-content) {
    font-size: 13px;
    padding: 5px 10px;
    background: #FFFFFF;
    color: #000000;
    white-space: nowrap;
  }
}
