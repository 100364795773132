/**
 *  Define scss variables here.
 *
 *  Available options for Bootstrap:
 *  http://getbootstrap.com/customize/
 *
 */

// Custom Colors
$cyan: #33e0ff;
$humility: #777;
$darkblue: #004cba;
$gold: #ffe2a9;
$lightgrey: #e2e2e2;
$black: #111;
$white: #fff;

// Buttons
$btn-fancy-blue: #0047FF;
$btn-fancy-blue-hover: #22baef;
$btn-fancy-blue-active: #007aa5;

$btn-fancy-green: #4ece54;
$btn-fancy-green-hover: #64e264;
$btn-fancy-green-active: #3caa3c;

$btn-fancy-pink: var(--site-theme-color);
$btn-fancy-pink-hover: #CC2256;
$btn-fancy-pink-active: #B31E4B;

$btn-fancy-red: #ff6464;
$btn-fancy-red-hover: #ffa9a9;
$btn-fancy-red-active: #bf3636;

$btn-fancy-grey: #666;
$btn-fancy-grey-hover: #9b9b9b;
$btn-fancy-grey-active: #5b5b5b;

$btn-fancy-white: #fff;

// Links
$link-color: #0047FF;
// $link-hover-color: #22BAEF;
$link-hover-color: #0047FF;
$link-hover-decoration: none;
$link-fancy-blue: #0047FF;

// Dropdown menus
$dropdown-toggle-border: #acacac;
$dropdown-text: #3e3e3e;
$dropdown-caret: #676767;

$iorad-popover-item-hover: #f9f9f9;

// Text input fields
$text-field-text-color: #444;
$text-field-placeholder: #BDBDBD;
$text-field-placeholder-hover: #BDBDBD;
$text-field-bg: #eee;
$text-field-bg-focus: #fff;
$text-field-border-hover: #999;
$text-field-border-focus: #0047FF;
$label-hover: #808080;
$label-focus: #515151;

// Tables
$table-header-border: #ddd;
$table-header-color: #aaa;
$table-data-border: #ddd;
$table-data-highlight: #fff9f2;
$lightgrey: #e2e2e2;

$checkbox-regular-background: #c8c8c8;

// Notifications
$notification-success-bg: #32b643;
$notification-error-bg: #ffe6fb;
$notification-warning-bg: #F2F2F2;
$notification-info-bg: #5755d9;
$notification-link: #0ca5ff;
// Bootstrap Variables
$brand-primary: darken(#428bca, 6.5%);
$brand-secondary: #e25139;
$brand-success: #5cb85c;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;
$brand-info: #5bc0de;

$text-color: #333;

$font-size-base: 14px;

$font-family-sans-serif: "Libre Franklin", Arial, sans-serif;


$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// App
$menubar-height: 162px;
