@import '~theme/modules/variables';
@import '~theme/mixins';

$maxWidthContent: 1440px;
$maxHeightContent: 798px;
$minHeightContent: 768px;

.generalSize {
  height: 100vh;
  min-height: $minHeightContent;
  max-height: $maxHeightContent;
  width: 100%;
  max-width: $maxWidthContent;
  margin: 0 auto;

  @include breakpoint-sm {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
}

.sectionWrapper {
  font-family: $fontBaseV2;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.title {
  font-family: $fontFahkwang;
  font-weight: 500;
  font-size: 35px;
  line-height: 116%;
  text-align: center;
  margin: 0;
  margin-top: 90px;
  color: #000;

  @include breakpoint-sm {
    font-size: 18px;
  }
}

.button {
  width: 276px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 600;
  font-size: 15px;
  background: #fff;

  @include breakpoint-sm {
    width: 295px;
    font-size: 13px;
  }
}

.captureBtn {
  width: 305px;
  min-width: 305px;
  height: 48px;
  min-height: 48px;
  background: var(--site-theme-color);
  color: #fff;
  padding: 0 39px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 15px;

  &.alt {
    z-index: 32;
    color: var(--site-theme-color);
    background: #fff;
  }

  svg {
    margin-right: 11px;
  }
}

.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100vh - #{$topNavBarHeight});
  max-height: 100vh;
  position: relative;

  @include breakpoint-sm {
    height: calc(100vh - 52px);
  }

  .title {
    margin-bottom: 40px;
    margin-top: 184px;
    min-height: 80px;

    &.alt {
      z-index: 32;
      color: #fff;
    }

    @include breakpoint-sm {
      margin-top: 162px;
      width: 100%;
      padding: 0 20px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: var(--site-theme-color);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 31;
    pointer-events: none;
    transition: 0.5s;
    opacity: 0;

    @include breakpoint-sm {
      top: 0;
    }

    &.visible {
      transition: 0.5s;
      opacity: 1;
    }
  }

  .arrowDownIcon {
    width: 50px;
    height: 50px;
    margin-top: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint-sm {
      margin-top: 16px;
    }
  }
}

.section2 {
  .blockItemWrapper {
    margin-bottom: 63px;
  }
}

.videoSection {
  position: relative;
  @extend .generalSize;

  @include breakpoint-sm {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 46px;
  }

  .videoContainer {
    width: 100%;
    height: 100%;
    max-height: 615px;
    position: relative;
    box-sizing: content-box;

    @include breakpoint-sm {
      height: 211px;
    }
  }

  .videoPlaceholder {
    width: 100%;
    height: 100%;
    cursor: pointer;

    &.hidden {
      display: none;
    }

    .playIconWrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
    }

    .playIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    .bgVideo {
      height: 100%;
      width: 100%;
      max-height: 615px;
    }
  }

  .videoPopup {
    display: none;
    position: fixed;
    z-index: 40;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 86px 0;
    background-color: #000;
    z-index: #{$embedButtonZIndex + 1};

    @include breakpoint-sm {
      padding: 0;
    }

    &.isShowVideo {
      display: block;
    }

    .videoContent {
      height: 100%;

      @include breakpoint-sm {
        display: flex;

        iframe {
          max-height: 211px;
          margin: auto 0;
        }
      }
    }

    .closeBtn {
      position: absolute;
      right: 64px;
      top: 24px;
      cursor: pointer;

      @include breakpoint-sm {
        right: 24px;
      }
    }
  }
}

.section3, .section4 {
  .contentSection {
    max-height: 0;
    margin-bottom: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, margin-bottom 0.3s ease-out;

    &.isExpanded {
      max-height: 2000px;
      margin-bottom: 90px;
      transition: max-height 0.3s ease-in, margin-bottom 0.3s ease-in;

      @include breakpoint-sm {
        margin-bottom: 62px;
      }
    }
  }
}

.section3, .section4, .section5 {
  .contentSection {
    background: rgba(0, 0, 0, 0.05);
  }

  .contentWrapper {
    width: 100%;
    max-width: $maxWidthContent;
    padding: 0 64px;
    margin: 0 auto;

    @include breakpoint-sm {
      padding: 0 24px;
      max-width: 100%;
    }
  }
}

.section3 {
  margin-top: 90px;

  @include breakpoint-sm {
    margin-top: 133px;
  }

  .titleContent {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    color: #000;
    margin-top: 27px;
    margin-bottom: 36px;

    @include breakpoint-sm {
      font-size: 15px;
      line-height: 136%;
    }
  }

  .content {
    padding: 0;
    margin-bottom: 29px;
    display: flex;
    justify-content: space-between;

    @include breakpoint-sm {
      margin-top: 0;
      margin-bottom: 0;
      flex-direction: column;
    }
  }

  .item {
    list-style-type: none;
    width: 337px;
    font-family: $fontBaseV2;

    img {
      width: 100%;
    }

    @include breakpoint-sm {
      width: 100%;

      img {
        margin-bottom: 37px;
        width: 100%;
      }
    }
  }

  .titleItem {
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    margin: 0;
    margin-bottom: 11px;
    color: #000;

    @include breakpoint-sm {
      font-size: 13px;
      line-height: 136%;
      margin-bottom: 9px;
    }
  }

  .descItem {
    font-size: 15px;
    line-height: 136%;
    min-height: 75px;
    margin: 0;
    margin-bottom: 32px;
    color: #000;

    @include breakpoint-sm {
      font-size: 13px;
      margin-bottom: 15px;
    }
  }
}

.section4 {

  .titleContent {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    color: #000;
    margin-top: 27px;
    margin-bottom: 45px;

    @include breakpoint-sm {
      font-size: 15px;
      line-height: 136%;
    }
  }

  .content {
    margin-bottom: 20px;
  }

  .listItem {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;

    @include breakpoint-sm {
      margin-bottom: 0;
      justify-content: center;
    }
  }

  .item {
    list-style-type: none;
    font-family: $fontBaseV2;

    @include breakpoint-sm {
      margin-right: 41px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .listSubItem {
    padding: 0;
  }

  .subItem {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    @include breakpoint-sm {
      margin-bottom: 37px;
    }

    .icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 30px;
      }
    }

    .name {
      margin-left: 21px;
      font-size: 15px;
      line-height: 136%;
      font-weight: normal;
      margin-bottom: 7px;
      color: #000;

      @include breakpoint-sm {
        display: none;
      }
    }
  }

  .action {
    display: flex;
    font-family: $fontBaseV2;
    margin-top: 65px;

    &:last-child {
      margin-top: 50px;

      @include breakpoint-sm {
        margin-top: 64px;

        .button {
          width: 296px;
          height: 52px;
        }
      }
    }

    @include breakpoint-sm {
      margin-top: 0;
      flex-direction: column;
    }
  }

  .titleAction {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    margin: 0;
    min-width: 120px;
    color: #000;

    @include breakpoint-sm {
      font-size: 13px;
      line-height: 136%;
      margin-bottom: 7px;
    }
  }

  .descAction {
    font-size: 15px;
    line-height: 136%;
    width: 420px;
    margin: 0 123px;
    color: #000;
    padding-top: 3px;

    @include breakpoint-sm {
      width: 100%;
      margin: 0;
      font-size: 13px;
    }
  }

  .button {
    @include breakpoint-sm {
      margin: 38px auto 0;
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint-sm {
      margin-bottom: 16px;
    }

    .button {
      border: 5px solid var(--site-theme-color);
    }
    .tooltip {
      width: 79px;
      height: 32px;
      background: var(--site-theme-color);
      font-weight: 600;
      font-size: 17px;
      line-height: 150%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      position: relative;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-bottom: 8px solid var(--site-theme-color);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.section5 {

  .blockItemWrapper {
    margin-bottom: 62px;

    @include breakpoint-sm {
      margin-bottom: 26px;
    }
  }

  .desktop {
    padding-bottom: 0;
    width: 85%;
    right: 0;
    position: relative;
    margin-left: auto;
    max-height: 539px;
  }

  .mobile {
    padding: 0;
    width: 297px;
    margin-left: 305px;
    position: relative;
    max-height: 539px;
  }

  .nameIframe {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 48px;
  }

  .preview_container {
    display: flex;
    width: 100%;
    height: 600px;

    @include breakpoint-sm {
      background-color: #fff;
      height: 100%;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--site-theme-color-rgb), 0.05);
    color: #fff;
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .switch_container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .tab_selector {
      font-family: $fontFahkwang;
      color: #000;
      font-size: 35px;
      cursor: pointer;

      &.selected {
        color: var(--site-theme-color);
      }

      &:nth-child(2) {
        margin-top: 16px;
        margin-bottom: 78px;
      }
    }
  }

  .left_col {
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint-sm {
      display: none;
    }
  }

  .right_col {
    width: 100%;
    margin-top: 31px;
    margin-bottom: 30px;
    position: relative;

    @include breakpoint-sm {
      margin: 0;
      background: rgba(0, 0, 0, 0.05);
    }

    .desktop,
    .mobile {
      display: none;

      @include breakpoint-sm {
        width: 300px;
        margin: auto;
      }

      &.visible {
        display: block;
      }
    }
  }

  .head {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    color: #000000;
    margin-top: 38px;
    width: 233px;
  }
}

.section6 {
  @include breakpoint-sm {
    padding: 0 24px;

    .title {
      margin-top: 97px;
    }
  }

  .content {
    width: 100%;
    max-width: $maxWidthContent;
    padding: 0 64px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 142px;
    padding-bottom: 50px;

    @include breakpoint-sm {
      padding: 0;
      margin-top: 49px;
    }
  }
}

.section7 {
  @extend .generalSize;

  .title {
    @include breakpoint-sm {
      margin-top: 80px;
    }
  }

  .row {
    display: flex;
    margin: 38px 0 0 64px;

    @include breakpoint-sm {
      flex-direction: column;
      margin: 38px 24px 0;
    }

    &.proof {
      margin-right: 0;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%;
        width: 500px;
        background: rgba(0, 0, 0, 0.05);
      }

      .titleRow {
        margin-top: 34px;
      }

      @include breakpoint-sm {
        margin: 0;
        margin-top: 28px;
        position: relative;

        .titleRow {
          position: absolute;
          top: 14px;
          left: 24px;
          margin-top: 0;
        }
      }
    }
  }

  .titleRow {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    min-width: 150px;
    max-width: 150px;
    margin-right: 34px;
    margin-top: -4px;
    color: #000;

    @include breakpoint-sm {
      font-size: 13px;
      line-height: 136%;
      margin-bottom: 10px;
    }
  }

  .listItem {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    max-width: calc((191px + 25px) * 5); // 5 column

    @include breakpoint-sm {
      justify-content: space-between;

      &:after {
        content: '';
        width: 98px;
      }
    }
  }

  .item {
    list-style-type: none;
    width: 191px;
    font-size: 15px;
    line-height: 136%;
    color: #000;
    margin-bottom: 5px;
    margin-right: 25px;

    @include breakpoint-sm {
      font-size: 13px;
      width: 98px;
      margin-bottom: 8px;
      white-space: nowrap;
      margin-right: 0;
    }

    a {
      &:hover {
        color: #000;
        text-decoration: underline;
      }
      &:focus {
        color: #000;
      }
    }
  }

  .slides {
    padding: 45px 41px 67px;
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    min-width: calc(100% - 184px);

    @include breakpoint-sm {
      padding: 48px 24px 21px;
      min-width: 100%;
    }
  }

  .cards {
    display: flex;

    @include breakpoint-sm {
      flex-direction: column;
    }
  }

  .card {
    min-width: 425px;
    max-width: 425px;
    margin-right: 145px;

    @include breakpoint-sm {
      min-width: 100%;
      max-width: 100%;
      margin-right: 0;
      padding-right: 20px;
    }
  }

  .titleCard {
    display: flex;

    img {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      filter: grayscale(1);
      object-fit: cover;
      object-position: top;

      @include breakpoint-sm {
        min-width: 56px;
        max-width: 56px;
        min-height: 56px;
        max-height: 56px;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    margin-left: 33px;
    margin-top: -4px;

    @include breakpoint-sm {
      margin-left: 18px;
      margin-top: 0;
      height: 56px;
      display: flex;
      justify-content: space-between;
    }
  }

  .position {
    font-weight: normal;
    font-size: 15px;
    line-height: 136%;
    margin-bottom: 6px;
    color: #000;

    @include breakpoint-sm {
      font-size: 13px;
      margin-bottom: 0;
      margin-top: -3px;
    }
  }

  .name {
    font-weight: 600;
    font-size: 18px;
    line-height: 136%;
    margin: 0;
    color: #000;

    @include breakpoint-sm {
      font-size: 13px;
      margin-bottom: -2px;
    }
  }

  .contentCard {
    margin-top: 9px;
    min-height: 130px;
    font-size: 15px;
    line-height: 136%;
    color: #000;

    @include breakpoint-sm {
      margin-top: 16px;
      margin-bottom: 36px;
      min-height: unset;
      font-size: 13px;
    }
  }
}

.section8 {
  margin-top: 92px;
  align-items: center;

  @include breakpoint-sm {
    margin-top: 7px;
  }

  .title {
    margin-bottom: 181px;

    @include breakpoint-sm {
      margin-bottom: 93px;
    }
  }

  .captureBtn:hover {
    color: #fff;
  }

  .ioradLogo {
    width: 16px;
  }

  .leaveText {
    margin-top: 261px;
    margin-bottom: 85px;
    font-size: 15px;
    line-height: 136%;
    color: rgba(0, 0, 0, 0.4);

    @include breakpoint-sm {
      font-size: 13px;
      margin-top: 365px;
      margin-bottom: 97px;
    }
  }
}

.bouncingArrow {
  animation: bouncingArrow 2s infinite;
}

.blockItemWrapper {
  display: flex;
  flex-direction: row;
  width: 100vw;
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 0 64px;
  margin-bottom: 85px;
  transition: margin-bottom 0.3s ease-out;

  &.isExpanded {
    margin-bottom: 63px;
    transition: margin-bottom 0.3s ease-in;
  }

  .left {
    min-width: 155px;
    margin-right: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 100%;

    h2 {
      font-family: $fontFahkwang;
      font-weight: 500;
      font-size: 35px;
      line-height: 116%;
      margin-top: 0;
      margin-bottom: 39px;
      max-width: 705px;
      color: #000;

      a {
        cursor: pointer;
      }
    }

    .descWrapper {
      display: flex;
      justify-content: space-between;
      font-family: $fontBaseV2;
      font-size: 15px;
      line-height: 136%;
      color: #000;

      p {
        max-width: 537px;
        margin-bottom: 0;
      }

      .linkDesc {
        cursor: pointer;
        color: var(--site-theme-color);
      }

      .learnMore {
        font-weight: 600;
        cursor: pointer;
      }
    }
  }

  @include breakpoint-sm {
    flex-direction: column;
    padding: 0 24px;
    margin-bottom: 96px;

    &.isExpanded {
      margin-bottom: 26px;
    }

    .titleWrapper {
      display: flex;
      align-items: center;

      h2 {
        font-family: $fontFahkwang;
        margin: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 116%;
        color: #000;
      }
    }

    .icon {
      min-width: 70px;
      max-width: 70px;
      display: flex;
      justify-content: center;
      margin-right: 38px;
    }

    .descWrapper {
      font-size: 13px;
      line-height: 136%;
      color: #000;
      margin-top: 17px;

      p {
        margin: 0;
      }

      a {
        cursor: pointer;
        color: var(--site-theme-color);
      }
    }
  }
}

@keyframes bouncingArrow {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@media screen and (max-width: 1440px) {
  .sectionWrapper {
    max-width: 100%;
  }
}
