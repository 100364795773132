@import '../../../theme/modules/variables';
@import '../../../theme/mixins';

.guestTopbar {
  position: fixed;
  width: 100vw;
  background-color: #fff;
  z-index: $topNavBarZIndex;
  transition: transform 0.12s;

  &.moreNavShown {
    z-index: $topNavBarMoreShownZIndex;
  }

  &.hideMenuBar {
    transform: translateY(-100%);
  }

  &.forceGuest {
    position: absolute;
    width: calc(100% - 256px); // 240px (menu bar width) + 16px (margin)

    .ioradLogoBar {
      display: none;
    }

    .link_container {
      margin-left: 0;
    }
  }
}

.visibleBlock {
  width: 100%;
  height: $topNavBarHeight;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 54px;
}

.ioradLogo {
  width: 117px;
}

.iconMenu {
  display: none;
}

.blockLeft {
  display: flex;
}

.blockRight {
  display: flex;
  align-items: flex-end;
  padding-right: 10px;

  .link {
    margin-bottom: 14px;
  }
}

.link_container {
  display: flex;
  align-items: flex-end;
  margin-left: 68px;
  margin-bottom: 10px;

  > * + * {
    margin-left: 42px;
  }

  @media only screen and (max-width: 1280px) {
    margin-left: 27px;
  }

  @media only screen and (max-width: 1240px) {
    margin-left: 10px;
  }
}

.linkWrapper {
  position: relative;
}

.link {
  font-weight: 600;
  font-size: 15px;
  line-height: 136%;
  transition: color 0.1s linear;
  color: #000;
  cursor: pointer;
  display: block;
  width: max-content;

  &.active,
  &:hover {
    color: var(--site-theme-color);
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }
}

.tryBtn {
  width: 276px;
  height: 48px;
  background: #000;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  line-height: 136%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  margin-right: 45px;

  &:hover {
    color: #fff;
    background-color: var(--site-theme-color);
  }
}

.additionalNavMenu {
  position: absolute;
  width: 211px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #ffffff;
  padding: 20px 24px;
  top: 32px;
  right: -24px;
  display: none;

  &.isShow {
    display: block;
  }

  .menuItem {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }

    * {
      width: 100%;
    }

    * + * {
      padding-top: 15px;
    }
  }

  span.titleItem {
    font-weight: 600;
  }

  .titleItem {
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    color: #000;
    text-align: end;

    &.active {
      color: var(--site-theme-color);
    }

    &.titleLink:hover {
      color: var(--site-theme-color);
    }
  }

  .link {
    font-weight: normal;
    text-align: end;
  }
}

@media only screen and (max-width: 1200px) {
  .visibleBlock {
    height: $topNavBarHeightMobile;
    padding: 11px 8px 0 19px;
    position: relative;
  }

  .ioradLogo {
    width: 67px;
  }

  .blockRight {
    display: none;
  }

  .link_container {
    display: none;
    position: absolute;
    height: calc(100vh - #{$topNavBarHeightMobile});
    margin: 0;
    width: 100vw;
    left: 0;
    top: $topNavBarHeightMobile;
    background: #ffffff;
    padding: 27px 24px 20px;
    overflow-y: auto;

    > * + * {
      margin-left: 0;
      margin-top: 22px;
    }

    &.active {
      display: block;
    }
  }

  .iconMenu {
    display: block;
    background: url('~assets/img/redesign2021/global/mobile/menu.svg') no-repeat
      center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-top: 2px;

    &.active {
      background: url('~assets/img/redesign2021/global/mobile/close_menu.svg')
        no-repeat center;
    }
  }

  .additionalNavMenu {
    position: unset;
    border: none;
    width: 100%;
    padding: 22px 20px 0;

    .menuItem {
      align-items: flex-start;
    }

    a.titleItem {
      padding-bottom: 8px;
    }

    .titleItem,
    .link {
      text-align: left;
    }
  }
}
