@import '~theme/mixins';
@import '~theme/modules/variables';

$disabledOpacity: 0.4;

.checkbox_btn {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  padding-left: 25px;
  margin: 0;

  &.disabled {
    opacity: $disabledOpacity;
    pointer-events: none;
  }
  &.disabledButClickable {
    opacity: $disabledOpacity;
  }

  input[type='checkbox']:checked ~ span::after {
    content: '';
    background-image: url('../../assets/img/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    border: none;
  }

  input[type='checkbox'] {
    position: absolute;
    cursor: pointer;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    bottom: 3px;
    left: 0;
    margin: 0;
    opacity: 0;
    top: 3px;
    z-index: 1;
  }

  span:after {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    content: '';
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1px;
  }
}

.step {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f4f4f4;
  color: black;
  position: relative;
  z-index: 0;
  height: 173px;
  transition: height 0.3s ease;

  svg {
    color: inherit;

    [stroke] {
      color: inherit;
      stroke: currentColor;
    }
    [fill] {
      color: inherit;
      fill: currentColor;
    }
  }

  &.opaque {
    opacity: $disabledOpacity;
    pointer-events: none;
  }

  &.active {
    height: 320px;
    z-index: 1;

    .step_block__left,
    .step_block__center,
    .step_block__right {
      flex: 1;
    }

    &.avatar {
      height: 499px;
    }

    &.editor {
      height: 384px;
    }

    .step__video_player {
      :global(.videoLayerControl) {
        video {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  &.hasScreenshot {
    height: auto;
    flex-direction: column;
    position: relative;

    .step_screenshot {
      display: block;
    }

    .step_content {
      min-height: 173px;
    }

    &.active {
      height: auto;

      &.avatar {
        height: auto;
      }
    }
  }

  .step_screenshot {
    width: 100%;
    height: 300px;
    display: none;
  }

  .step_content {
    display: flex;
    width: 100%;
    position: relative;
  }

  .step_block__left {
    display: flex;
    flex-grow: 1;

    .editVideoInfo {
      padding: 25px 25px 50px 25px;
      position: relative;
      width: 100%;

      h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: black;
        margin: 0;
        margin-bottom: 15px;
      }

      p {
        color: #000;
        font-size: 14px;
      }

      .buttonGroup {
        position: absolute;
        bottom: 20px;
        left: 25px;
      }

      button {
        outline: 0;
        background: none;
        box-shadow: none;
        margin-top: 10px;
        font-weight: 600;
        display: block;
        width: 175px;
        border: 0 none;
        text-align: left;
        font-size: 15px;
        font-family: Libre Franklin;

        &:hover {
          color: var(--site-theme-color);

          svg {
            path {
              stroke: var(--site-theme-color);
            }
          }
        }

        &.fill {
          &:hover {
            svg {
              path {
                fill: var(--site-theme-color);
              }
            }
          }
        }

        span {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          label {
            margin: 0;
            cursor: pointer;
            font-size: 15px;
            font-family: Libre Franklin;
            font-weight: 600;
            margin-left: 16px;
          }

          svg {
            width: 17px;
          }
        }

        &.disabled {
          pointer-events: none;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .step_block__center {
    display: flex;
    flex-grow: 1;
  }

  .step_block__right {
    display: flex;
    flex: 0 0 232px;
    // transition: all 0.3s ease;
    position: relative;

    &.empty {
      flex: none;
    }

    .avatarVideoDetail {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .step__close_btn {
    position: absolute;
    top: 0;
    right: -10px;
    transform: translateX(24px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: #000;
    color: #FFF;

    &:hover {
      background: var(--site-theme-color);
    }
  }
}

.step_icon {
  transition: none;
  margin-top: -10px;

  &.step_icon_READ {
  }

  &.step_icon_LEFT_CLICK {
  }

  &.step_icon_SCROLL {
  }

  &.step_icon_TYPING {
  }

  &.step_icon_DRAG_AND_DROP {
  }

  &.step_icon_HOVER {
  }
}

.step_info_wrapper {
  display: flex;
  flex-grow: 1;
  padding: 24px;
}

.step_info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 16px;

  .step_preview_image {
    cursor: pointer;
    margin-left: 5px;
    svg {
      path[fill] {
        fill: #c2c3c2;
      }
      path[stroke] {
        stroke: #c2c3c2;
      }
    }
    &:hover,
    &.active {
      svg {
        path[fill] {
          fill: var(--site-theme-color);
        }
        path[stroke] {
          stroke: var(--site-theme-color);
        }
      }
    }
  }

  .step_title {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: inherit;
    margin: 0;
    margin-bottom: 10px;
  }

  .step_description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: inherit;
    flex-grow: 1;
    word-break: break-word;
    max-width: 526px;
    height: 100%;
    overflow: hidden;
    padding-right: 11px;
    cursor: text;

    &:hover {
      overflow-y: scroll;
      padding-right: 0px;
    }

    &:focus {
      color: #000;
    }

    b {
      font-weight: 400;
    }

    a {
      color: inherit;
      transition: none;
    }
  }

  .step__recording {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 19px;
  }

  .recording_time_left {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: inherit;
    margin: 0;
  }
}

.emptyVideoGroup {
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    .empty_video_overlay {
      display: none;
    }
  }
}

.empty_video, .empty_video_overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 173px;
  height: 100%;
  flex-direction: row;
  gap: 10px;

  &:hover {
    background: white;
    color: var(--site-theme-color);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(var(--site-theme-color-rgb), 0.05);
    }

    svg path {
      stroke: var(--site-theme-color);
    }
  }
}

.empty_video_overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
}

.empty_video_text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: black;
  border-left: 1px solid #ffffff;
}

.step__select_tabs {
  display: flex;
  flex-grow: 1;
  column-gap: 1px;
  background: #fff;

  .step_block__right {
    padding: 0;
  }

  .step__select_tab_btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    flex-grow: 1;
    background: #f4f4f4;
    height: 100%;

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: black;
    }

    input[type='file'] {
      width: 0;
      height: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    &:hover {
      background: white;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(var(--site-theme-color-rgb), 0.05);
      }

      span {
        color: var(--site-theme-color);
      }

      svg {
        path,
        circle {
          &[stroke] {
            stroke: var(--site-theme-color);
          }
          &[fill] {
            fill: var(--site-theme-color);
          }
        }
      }
    }
  }
}

.step__video_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &.expanding {
    border-left: 1px solid #fff;

    .step__video_player {
      height: calc(100% - 64px);
    }
  }
}

.step__video_player {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(var(--site-theme-color-rgb), 0.05);
    visibility: hidden;
  }

  &:hover {
    .step__video_slider {
      visibility: visible;
    }
    .step__video_play_btn {
      visibility: visible;
    }
  }

  :global(.videoLayerControl) {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    // background: #fff;
    overflow: hidden;

    video {
      object-fit: fill;
      // max-width: 100%;
      // max-height: 100%;
      // width: 100%;
      max-width: 232px;
    }
  }

  .step__video_play_btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #f4f4f4;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  .step__video_slider {
    position: absolute;
    bottom: 44px;
    height: 3px;
    width: calc(100% - 131px);
    margin-right: 88px;
    margin-left: 43px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: black;
    visibility: hidden;
    left: 0;

    &.visible {
      visibility: visible;
    }
  }
  .step__video_slider__handle {
    width: 10px;
    height: 10px;
    background: black;
    border: 1px solid white;
    transform: rotate(-45deg);
    position: absolute;
    top: -5px;
    left: -5px;
  }
  .step__video_slider__completed {
    background: white;
    height: 100%;
    width: 0%;
  }

  .step__video_timeleft {
    position: absolute;
    bottom: 33px;
    right: 25px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
  }
}

.step__video_editor {
  display: flex;
  flex-grow: 1;
  position: relative;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
  }

  &.reverse {
    flex-direction: row-reverse;

    // .step__video_player {
    //   padding-bottom: 30px;
    // }
  }

  .step__video_controls {
    display: flex;
    flex-shrink: 0;
    height: 64px;
    border-top: 1px solid #fff;
  }

  .step__video_control_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-right: 1px solid #fff;

    &:last-child {
      border-right: none;
    }

    button {
      min-width: 0;
      height: 100%;
      border: none;

      &:disabled {
        opacity: $disabledOpacity;
      }

      // &:hover {
      //   svg path {
      //     &[stroke='black'] {
      //       stroke: var(--site-theme-color);
      //     }
      //     &[fill='black'] {
      //       fill: var(--site-theme-color);
      //     }
      //   }
      // }
    }
  }
}

.step__recorder {
  display: flex;
  flex-grow: 1;
  min-height: 173px;

  .has_error {
    border-right: 1px solid #fff;
  }

  .step__confirm_reset {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    .step__confirm_reset_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #fff;
      padding: 24px 24px 0;
      height: calc(100% - 64px);

      p {
        margin-bottom: 24px;
      }

      .checkbox_btn span:after {
        background-color: #f4f4f4;
      }

      * {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: black;
        text-align: center;
      }
    }

    .recording_btn_group {
      display: flex;
      flex-shrink: 0;
      column-gap: 1px;
      background: white;
    }

    .confirm_reset_btn,
    .cancel_reset_btn {
      width: 100%;
      height: 64px;
      min-height: 64px;
      max-height: 64px;
      font-size: 18px;
      background: #f4f4f4;
      border: none;
      flex: 1;
    }
  }

  .step__recorder_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    position: relative;

    .step_info {
      margin-left: 0;
      padding: 24px;
      border-bottom: 1px solid #fff;
      height: calc(100% - 64px);
    }

    .get_ready {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f4f4f4;

      span {
        font-weight: 400;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #000;
      }
    }
  }

  .step__recorder_preview {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .visualizer {
    width: 100%;
    height: 100%;
  }

  .counting_start {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);

    span {
      font-weight: 500;
      font-size: 35px;
      line-height: 41px;
      color: white;
    }
  }

  .connecting {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #fff;

    span {
      color: black;
      font-weight: 600;
      font-size: 21px;
      line-height: 24px;
    }
  }

  .step__recorder_messages_block {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: white;
    column-gap: 1px;
  }

  .step__recorder_message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    background: #f4f4f4;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin-top: 10px;
    }
  }

  .select_devices {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .select_devices_content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      row-gap: 24px;
      padding: 24px;
      border-bottom: 1px solid #fff;
    }

    :global(.resizable-input) {
      display: none;
    }

    .select_devices_title {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
    }

    .select_devices_submit {
      margin-top: auto;
      width: 100%;
      height: 64px;
      font-size: 18px;
      border: none;
    }
  }

  .recording_btn_group {
    display: flex;
    flex-shrink: 0;
    column-gap: 1px;
    background: white;
  }

  .start_recording_btn,
  .stop_recording_btn,
  .reset_recording_btn {
    width: 100%;
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    font-size: 18px;
    background: #f4f4f4;
    border: none;
    flex: 1;

    &:hover {
      svg {
        path,
        rect {
          fill: var(--site-theme-color);
        }
      }
    }
  }
}

.step__processing {
  .processing_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @keyframes dots {
      to {
        width: 1em;
      }
    }

    span {
      color: black;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;

      &.loading_dot {
        position: relative;

        &:after {
          position: absolute;
          overflow: hidden;
          display: inline-block;
          vertical-align: bottom;
          content: '\2026';
          width: 0px;
          animation: dots 1s steps(4, end) infinite;
        }
      }
    }
  }

  .step__video_thumbnail {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .processing_animation {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);

    &.solid {
      background-color: #f4f4f4;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}

:global(.video-editor-tooltip) {
  :global(.tooltip-arrow) {
    display: none;
  }

  :global(.tooltip-inner) {
    border-radius: 0;
    padding: 5px 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: black;
    background: white;
    text-align: left;
  }
}