@import '~theme/mixins';
@import '~theme/modules/variables';

.learners_content {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  margin-top: 40px;

  .learners_content__tutorial {
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;

    .learners_content__tutorial_title {
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 24px;
      color: #000000;
      flex-grow: 1;
    }

    .learners_content__tutorial_open {
      flex-shrink: 0;
      margin-left: auto;
      padding-left: 15px;
      cursor: pointer;

      &:hover {
        svg path {
          fill: var(--site-theme-color);
        }
      }
    }
  }

  .learners_content__filters {
    display: flex;
    column-gap: 40px;
    align-items: center;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;

    @include breakpoint-sm {
      flex-direction: column;
      align-items: unset;
      position: unset;
      row-gap: 10px;

      .filter_modes,
      .learners_content__search_sort {
        flex: unset;
      }
    }
  }

  .filter_modes {
    display: flex;
    flex: 1;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;

    &.open {
      .filter_modes_input_icon {
        transform: rotate(180deg);
      }
    }

    .filter_modes_input {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
    }
    .filter_modes_input_value {
      display: flex;
      align-items: center;

      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin-left: 20px;
      }
    }
    .filter_modes_input_icon {
      position: absolute;
      right: 12px;
    }
    .filter_modes_dropdown {
      position: absolute;
      z-index: 1;
      top: 56px;
      display: flex;
      flex-direction: column;
      background: white;
      width: 100%;
      padding: 12px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-top: none;
    }
    .filter_modes_dropdown_item {
      display: flex;
      align-items: center;
      height: 32px;
      cursor: pointer;

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      svg {
        margin-right: 20px;
      }

      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
      }
    }
  }

  .learners_content__search_sort {
    display: flex;
    flex: 1;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    padding: 0 12px;

    .sortFilters {
      display: none;
    }

    &:focus-within {
      border-color: black;
    }

    &.defaultMode {
      .searchContainer {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }

      .searchInputWrapper {
        width: 100%;
      }

      .searchIcon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .searchInput {
        background: transparent;
        border: 0;
      }

      .sortOptionActived {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: capitalize;
      }

      .sortIconContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 40px;
        cursor: pointer;
        margin-right: 4px;
        flex-shrink: 0;
      }
    }

    &.sortModeActive {
      justify-content: flex-start;
      user-select: none;

      .searchInputWrapper {
        display: none;
      }

      .sortActive {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .sortOptionActived {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: black;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          text-transform: capitalize;
        }

        .sortIconContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 40px;
          cursor: pointer;
          margin-right: 9px;
        }
      }

      .sortFilters {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 56px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        padding: 12px;
        z-index: 2;

        .sortOptionItem {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: black;
          height: 32px;
          text-transform: capitalize;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.searchFocused {
      .sortIconContainer,
      .sortOptionActived,
      .sortFilters {
        display: none;
      }

      .searchInput {
        padding-right: 10px;
      }
    }
  }

  .learners_content__table {
    display: flex;
    flex-direction: column;
  }

  .learners_content__table_header {
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: 56px;
    padding: 0 24px;
    position: sticky;
    z-index: 1;
    top: 56px;
    background: #fff;

    & > span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
      flex: 1;
      display: flex;
      justify-content: flex-start;

      &:last-child {
        max-width: 80px;
        justify-content: flex-end;
      }

      .text {
        cursor: pointer;
      }

      .sortIconContainer {
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  .learners_content__table_body {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
  }

  .learners_content__table_hint {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    padding: 18px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .learners_content__table_row {
    display: flex;
    column-gap: 8px;
    padding: 18px 24px;
    background: #f4f4f4;
    position: relative;

    &:hover {
      cursor: pointer;

      .learners_content__table_row_learnerName label {
        cursor: pointer;
        color: var(--site-theme-color);
      }

      .learners_content__table_row_actions {
        display: flex;
      }
    }

    .learners_content__table_row_cell {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      flex: 1;

      img {
        width: 20px;
        flex-shrink: 0;
      }

      label {
        font-weight: 400;
        word-break: break-word;
        margin: 0;
        margin-left: 20px;
      }

      &:last-of-type {
        max-width: 80px;
        justify-content: flex-end;
      }
    }

    .learners_content__table_row_actions {
      display: none;
      position: absolute;
      align-items: center;
      top: 0;
      right: -40px;
      height: 100%;

      .learners_content__table_row_underlay {
        display: none;
        content: '';
        z-index: 0;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: alias;
        z-index: 2;
        background: #fff;
        opacity: 0.2;
      }

      .learners_content__table_row_menu_icon {
        cursor: pointer;
        width: 40px;
        height: 100%;
        display: flex;
        padding-top: 19px;
        justify-content: center;
        position: relative;
        z-index: 3;
      }

      .learners_content__table_row_menu {
        display: none;
        position: absolute;
        right: 40px;
        top: -1px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #fff;
        padding: 0;
        z-index: 3;
        min-width: 200px;
        margin: 0;

        li {
          display: flex;
          align-items: center;
          padding: 18px 24px;
          cursor: pointer;
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
          color: black;

          &:hover {
            color: var(--site-theme-color);
            svg {
              circle,
              path {
                fill: var(--site-theme-color);
                stroke: var(--site-theme-color);
              }
            }
          }

          svg {
            margin-right: 15px;
          }
        }
      }

      &.open {
        .learners_content__table_row_menu,
        .learners_content__table_row_underlay {
          display: block;
        }
      }
    }
  }

  .loading_dot {
    position: relative;
    width: 11px;
    color: black;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    @keyframes dots {
      to {
        width: 1em;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      content: '\2026';
      width: 0px;
      animation: dots 1s steps(4, end) infinite;
    }
  }

  .csv_only {
    display: none;
  }
}

.learner_detail_popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;

  .underlay {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .learner_detail_popup__container {
    height: 100%;
    width: 100%;
    max-width: 1328px;
    background: white;
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding-bottom: 40px;
  }

  .learner_detail_popup__content {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    padding-left: 80px;
    overflow: auto;
  }

  .learner_detail_popup__user_info {
    display: flex;
    width: 215px;
    flex-shrink: 0;
    margin-top: 80px;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      word-break: break-word;
      margin-left: 20px;
    }
  }

  .learner_detail_popup__list {
    max-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 62px;
    margin-left: 30px;
  }

  .learner_detail_popup__item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #fff;
    background: #f4f4f4;
  }

  .learner_detail_popup__item_title {
    display: flex;
    justify-content: space-between;
    padding: 18px 24px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    cursor: default;

    label {
      margin: 0;
      font-weight: 600;
    }

    &.openable:hover {
      color: var(--site-theme-color);
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }
  }

  .learner_detail_popup__item_content {
    padding: 0 24px 24px;
  }

  .learner_detail_popup__item_table {
    display: flex;
    flex-direction: column;
    background: #fff;
  }

  .learner_detail_popup__item_table__header {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 0 24px;
    height: 56px;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
      flex: 1;
      text-align: right;

      &:first-child {
        text-align: left;
      }
    }
  }

  .learner_detail_popup__item_table__body {
    display: flex;
    flex-direction: column;
  }

  .learner_detail_popup__item_table__row {
    padding: 18px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border-top: 1px solid #f4f4f4;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      text-transform: capitalize;

      label {
        margin: 0;
        margin-left: 20px;
        font-weight: 400;
      }

      &:first-child {
        justify-content: flex-start;
      }
    }
  }

  .learner_detail_popup__close_btn {
    position: absolute;
    top: 4px;
    right: 4px;
    background: black;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    &:hover {
      background: var(--site-theme-color);
    }
  }
}
