@import '../../variables';

.share {
  display: flex;
  flex-direction: column;
  height: 100%;

  .share__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    position: relative;
    // background-color: rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.2);

    h4 {
      font-size: 21px;
      line-height: 24px;
      margin: 0;
      margin-bottom: 24px;
    }

    p {
      @include defaultFont;
      margin: 0;
    }

    .share__header_close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: $defaultHeightBtn;
      width: $defaultHeightBtn;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .share__content {
    display: flex;
    flex-direction: column;
    background: $defaultColor;
    height: 100%;
    overflow: hidden;
    padding: 16px;
  }

  .share__content_tabs {
    background: white;

    > div:first-child {
      height: $defaultHeightBtn;
      border: 1px solid white;

      > div > span {
        font-weight: 400 !important;
        order: 1;
      }
    }
  }

  .share__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 80px;
    background-color: white;
    padding: 0 40px;

    label {
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 24px;

      span {
        @include defaultFont;
        color: black;

        b {
          font-weight: 600;
        }
      }

      &.back {
        span {
          font-weight: 600;
        }
        svg {
          transform: rotate(90deg);
        }
      }

      &.next {
        svg {
          transform: rotate(270deg);
        }
      }
    }
  }

  .share_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $defaultHeightBtn 0;

    .share_link__inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: $defaultWidthContent;
      row-gap: 16px;
    }

    .share_link__select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 24px;
      height: $defaultHeightBtn;
      margin: 0;
      font-weight: normal;

      .head {
        @include defaultFont;
        color: rgba(0, 0, 0, 0.4);
      }

      .share_link__select_label {
        max-width: 300px;
        @include defaultFont;

        :global(.simple-option):hover {
          background: var(--background-color);
          text-decoration: none !important;
        }
      }
    }

    .share_link__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      margin-top: 40px;

      button {
        max-width: 320px;
      }
    }
  }

  .share_embed {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $defaultHeightBtn 0;

    .share_embed__inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: $defaultWidthContent;
      row-gap: 16px;
    }

    .share_embed__select {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 24px;
      height: $defaultHeightBtn;
      margin: 0;
      font-weight: normal;

      .head {
        @include defaultFont;
        color: rgba(0, 0, 0, 0.4);
      }

      .share_embed__select_label {
        @include defaultFont;
        max-width: 300px;

        :global(.simple-option):hover {
          background: var(--background-color);
          text-decoration: none !important;
        }
      }
    }

    .share_embed__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
      margin-top: 40px;

      button {
        max-width: 320px;
      }
    }
  }

  .share_invite {
    display: flex;
    flex-direction: column;
    height: 100%;

    .share_invite__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: $defaultHeightBtn;
    }

    .share_invite__inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: $defaultWidthContent;
      padding: 0 16px;
    }

    .share_invite__footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 24px;
      padding-bottom: 16px;
      row-gap: 24px;

      button {
        max-width: 320px;
      }
    }

    .share_invite__emails_input {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 16px;

      input {
        padding-left: 16px;
        padding-right: 123px;
      }

      .share_invite__upload_btn {
        @include defaultFont;
        font-weight: 600;
        margin: 0;
        position: absolute;
        right: 0;

        input {
          display: none;
        }

        &:hover {
          color: var(--marker-color);
        }
      }

      .share_invite__emails_suggestion {
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 1;
        width: 100%;
        background: white;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(0, 0, 0, 0.1);

        .share_invite__emails_suggestion_item {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          height: $defaultHeightBtn;
          @include defaultFont;
          padding: 0 16px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);

          &:hover {
            background: var(--background-color);
          }

          &:first-child {
            border-top: none;
          }
        }
      }
    }

    .share_invite__emails_sending {
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 16px;

      .share_invite__emails_sending_item {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: $defaultHeightBtn;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 16px;

        &:first-child {
          border-top: none;
        }

        span {
          @include defaultFont;
          flex-grow: 1;
          overflow: hidden;
        }

        .share_invite__emails_sending_item_remove {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: $defaultHeightBtn;
          height: 100%;
        }
      }
    }

    .share_invite__enable_domain_sharing {
      height: $defaultHeightBtn;
      width: 100%;
      margin: 0 16px;
    }

    .share_invite__domain_sharing {
      display: flex;
      flex-direction: column;
      padding-left: 48px;

      .share_invite__domain_sharing_input {
        display: flex;
        align-items: center;
        position: relative;

        input {
          padding-right: 115px;
        }

        .share_invite__domain_sharing_add_btn {
          @include defaultFont;
          font-weight: 600;
          margin: 0;
          position: absolute;
          right: 0;

          &:hover {
            color: var(--marker-color);
          }
        }
      }

      .share_invite__domain_sharing_list {
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 16px;

        .share_invite__domain_sharing_list_item {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          height: $defaultHeightBtn;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          padding-left: 16px;

          &:first-child {
            border-top: none;
          }

          svg {
            flex-shrink: 0;
          }

          span {
            @include defaultFont;
            flex-grow: 1;
            overflow: hidden;
            margin-left: 16px;
          }

          .share_invite__domain_sharing_list_item_remove {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: $defaultHeightBtn;
            height: 100%;
            margin: 0;
          }
        }
      }
    }

    .share_invite__checkboxes {
      display: flex;
      flex-direction: column;

      .share_invite__checkboxes_checkbox {
        height: $defaultHeightBtn;
        margin: 0 16px;
      }
    }
  }

  .share_access {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .share_access__inner {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      width: 100%;
      max-width: $defaultWidthContent;
      padding-top: $defaultHeightBtn;
    }

    .share_access__group {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .share_access__group_title {
        @include defaultFont;
        font-weight: 600;
        margin: 0;
      }
    }

    .share_access__list {
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(0, 0, 0, 0.1);

      .share_access__list_item {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        column-gap: 16px;
        height: $defaultHeightBtn;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 8px;

        &:first-child {
          border-top: none;
        }

        img {
          width: 40px;
          height: 40px;
        }

        span {
          @include defaultFont;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .share_access__list_item_action {
          display: flex;
          justify-content: flex-end;
          min-width: 70px;

          &:hover {
            p {
              display: none;
            }

            label {
              display: block;
            }
          }

          p {
            @include defaultFont;
            margin: 0;
            opacity: 0.4;
          }

          label {
            @include defaultFont;
            margin: 0;
            display: none;
          }
        }

        .share_access__list_item_remove {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: $defaultHeightBtn;
          height: 100%;
        }
      }
    }

    .share_access__delete_btn {
      max-width: 320px;
      margin: 24px auto;
    }

    .share_access__empty {
      @include defaultFont;
      text-align: center;
      margin: 0;

      span {
        text-decoration: underline;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: var(--marker-color);
        }
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .share {
    .share_link {
      padding: 24px 13px 24px 24px;

      .share_link__select {
        .share_link__select_label {
          max-width: 210px;
        }
      }
    }

    .share_embed {
      padding: 24px 13px 24px 24px;

      .share_embed__select {
        .share_embed__select_label {
          max-width: 210px;
        }
      }
    }

    .share_invite {
      .share_invite__content {
        padding: 24px 13px 24px 24px;
      }

      .share_invite__inner {
        padding: 0;
      }
    }

    .share_access {
      padding-left: 24px;
      padding-right: 13px;

      .share_access__inner {
        padding-top: 24px;
      }
    }
  }
}
