$primaryColor: var(--site-theme-color);
$secondaryColor: $primaryColor;//#CF174E;

.progressPageLoadingWrapper {
  position: fixed;
  left: -45px;
  right: 0;
  top: 0;
  height: 3px;
  z-index: 1050;
  overflow: hidden;
  background-color: $primaryColor;

  &.belowSocialPopupHeader {
    top: 48px;
  }

  &.hidden {
    visibility: hidden;
    transition: visibility;
    transition-delay: 0.5s;

    .progressPageLoading {
      visibility: hidden;
      transition: visibility;
      transition-delay: 0.5s;
    }
  }

  &.immediateHidden {
    opacity: 0;
  }
}

.progressPageLoading {
  background: -webkit-repeating-linear-gradient(
    45deg,
    transparent 1px,
    $secondaryColor 2px,
    $secondaryColor 8px,
    transparent 9px,
    transparent 16px
  );

  height: 100%;
  visibility: visible;
}
