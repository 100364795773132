$maxWidth: 960px;

.checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 40px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  padding-left: 32px;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  input[type='checkbox']:checked ~ span::after {
    background-image: url('~assets/img/icons/checkmark.svg');
    background-size: 76%;
  }

  input[type='checkbox'] {
    display: none;
  }

  span:after {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    content: '';
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.adminSettingTab {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 24px;
  padding-bottom: 56px;
  padding-left: 11px;
  overflow-y: scroll;

  &.extensionEmbed {
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 13px;

    .adminSettingTab_content {
      .adminSettingTab_content_title {
        font-size: 15px;
        line-height: 20px;
      }
    }

    .adminSettingTab_desc {
      height: auto;

      p {
        padding: 12px;
        margin: 0;
        font-size: 13px;
        line-height: 18px;
      }
    }

    .adminSettingTab_list {
      .adminSettingTab_item_checkbox {
        font-size: 13px;
        line-height: 18px;
      }

      .adminSettingTab_item_lock {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .adminSettingTab_inner {
    max-width: $maxWidth;
    width: 100%;
    margin: 0 auto;
  }

  .adminSettingTab_block {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
  }

  .adminSettingTab_content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .adminSettingTab_content_title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }
  }

  .adminSettingTab_divider {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    margin: 12px 0;
  }

  .adminSettingTab_desc {
    display: flex;
    align-items: center;
    flex: 1;
    height: 80px;
    background: #f4f4f4;
    margin-top: 8px;

    p {
      font-size: 15px;
      line-height: 20px;
      margin: 0 16px;
    }
  }

  .adminSettingTab_list {
    display: flex;
    flex-direction: column;

    .adminSettingTab_item_checkbox {
      @extend .checkbox;
    }

    .adminSettingTab_item_lock {
      display: flex;
      align-items: center;
      column-gap: 16px;
      flex-shrink: 0;
      height: 40px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      cursor: pointer;
      
      .adminSettingTab_item_lock_icon {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 16px;
      }

      .adminSettingTab_item_lock_label {
        flex-grow: 1;
      }
    }
  }
}

.extensionSettingsTab {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 24px;
  padding-bottom: 56px;
  padding-left: 11px;
  overflow-y: scroll;

  &.extensionEmbed {
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 13px;

    .extensionSettingsTab_block {
      flex-direction: column;
      row-gap: 24px;
    }

    .extensionSettingsTab_block_content {
      .extensionSettingsTab_content_title {
        font-size: 15px;
        line-height: 20px;
      }
    }

    .defaultSettings {
      row-gap: 12px;

      .defaultSettings_item {
        .formGroup {
          .formGroup_select {
            max-width: 100%;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }

    .capturingShortcuts {
      .capturingShortcuts_item {
        label {
          font-size: 13px;
          line-height: 18px;
        }

        .capturingShortcuts_item_keyInput {
          .capturingShortcuts_item_keyPair {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }

    .extensionSettingsTab_list {
      margin-top: 24px;

      .extensionSettingsTab_list_item {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .extensionSettingsTab_inner {
    max-width: $maxWidth;
    width: 100%;
    margin: 0 auto;
  }

  .extensionSettingsTab_block {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
  }

  .extensionSettingsTab_block_content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .extensionSettingsTab_content_title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }
  }

  .defaultSettings {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .defaultSettings_item {
      display: flex;
      flex-direction: column;

      .formGroup {
        display: flex;
        flex-direction: column;

        .formGroup_label {
          font-size: 10px;
          line-height: 15px;
          font-weight: 400;
          opacity: 0.4;
          margin: 0;
        }

        .formGroup_select {
          max-width: 320px;
        }
      }
    }
  }

  .capturingShortcuts {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .capturingShortcuts_item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 40px;

      label {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        flex-grow: 1;
      }

      .capturingShortcuts_item_keyInput {
        display: flex;
        align-items: center;
        height: 32px;
        cursor: pointer;

        input {
          opacity: 0;
          width: 0;
          border: 0;
          padding: 0;
          margin: 0;
        }

        .capturingShortcuts_item_keyPair {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 16px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
        }
      }
    }
  }

  .extensionSettingsTab_list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 44px;

    .extensionSettingsTab_list_item {
      @extend .checkbox;
    }
  }
}

.integrationSettingsTab {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 24px;
  padding-bottom: 56px;
  padding-left: 11px;
  overflow-y: scroll;

  &.extensionEmbed {
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 13px;
    
    .integrationSettingsTab_description {
      font-size: 13px;
      line-height: 18px;
      padding: 12px;
      margin-bottom: 24px;
    }

    .integrationSettingsTab_title {
      font-size: 15px;
      line-height: 20px;
    }

    .integrationSettingsTab_list {
      .integrationSettingsTab_formGroup {
        .integrationSettingsTab_formInput_input {
          font-size: 13px;
          line-height: 18px;
        }

        .integrationSettingsTab_formSelect {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    
    .integrationSettingsTab_formHelp {
      display: none;
    }

    .integrationSettingsTab_domainList {
      max-width: 100%;

      .integrationSettingsTab_domainListItem {
        padding: 8px 12px;

        label {
          font-size: 10px;
          line-height: 15px;
        }

        span {
          font-size: 13px;
          line-height: 18px;
        }

        .integrationSettingsTab_domainListItem_delete {
          visibility: visible;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .integrationSettingsTab_inner {
    max-width: $maxWidth;
    width: 100%;
    margin: 0 auto;
  }

  .integrationSettingsTab_description {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    padding: 16px;
    background: #f4f4f4;
    margin-bottom: 40px;
  }

  .integrationSettingsTab_title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
  }

  .integrationSettingsTab_list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  
  .integrationSettingsTab_formGroup {
    display: flex;
    gap: 40px;

    &:hover {
      .integrationSettingsTab_formHelp {
        opacity: 1;
      }
    }

    .integrationSettingsTab_formInput {
      display: flex;
      flex-direction: column;
      flex: 1;

      .integrationSettingsTab_formInput_label {
        font-size: 10px;
        line-height: 15px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 0;
        
        i {
          color: var(--site-theme-color);
          font-style: normal;
        }
      }

      .integrationSettingsTab_formInput_input {
        width: 100%;
        height: 40px;
        padding: 0;
        margin: 0;
        background: transparent;
        outline: none;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 15px;
        line-height: 20px;

        &:focus {
          border-color: black;
        }
      }
    }

    .integrationSettingsTab_formSelect {
      flex-grow: 1;
    }

    .integrationSettingsTab_formButton {
      margin-top: 24px;
      max-width: 240px;
    }

    .integrationSettingsTab_formHelp {
      font-size: 15px;
      line-height: 20px;
      opacity: 0.5;
      flex: 1;

      p {
        margin: 0;
      }
    }
  }

  .integrationSettingsTab_domainList {
    display: flex;
    flex-direction: column;
    max-width: 471px;
    min-height: 216px;
    overflow-y: scroll;
    margin-top: 16px;

    .integrationSettingsTab_domainListItem {
      display: flex;
      flex-direction: column;
      padding: 8px 16px;
      position: relative;

      &:nth-child(odd) {
        background: #f4f4f4;
      }

      &:hover {
        .integrationSettingsTab_domainListItem_delete {
          visibility: visible;
        }
      }

      label {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        opacity: 0.5;
        margin: 0;
      }

      span {
        font-size: 15px;
        line-height: 20px;
      }

      .integrationSettingsTab_domainListItem_delete {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
      }
    }
  }
}
