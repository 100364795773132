@import '~theme/guidelines_variables';
@import '~theme/mixins.scss';
@import '../../Auth/page';

$fontBase: 'Libre Franklin', sans-serif;
$fontTitle: 'Fahkwang', sans-serif;

.feedback_root {
  box-sizing: border-box;
  padding: 0;
  font-family: $fontBase;
  font-weight: normal;
  font-size: 15px;
  line-height: 136%;
  margin-top: 48px;
  padding-left: 25px;

  @include breakpoint-sm {
    margin-top: 32px;
    padding: 0 10px;
    font-size: 13px;
  }

  .ioradLogo {
    width: 16px;
    height: 16px;
  }

  .feedback_content {
    margin: 82px auto 0;
    display: flex;
    flex-direction: column;
    max-width: 512px;
    width: 100%;
  }

  h1 {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 40px;
    color: black;
  }

  .feedback_form {
    width: 100%;

    .options {
      margin-left: 2px;
    }

    .form_row {
      text-align: left;

      &.input_text {
        margin-top: 24px;
      }

      &.submit_btn {
        margin-top: 20px;
      }

      &.hidden {
        display: none;
      }

      .answer_options {
        height: 40px;
      }

      button {
        min-width: auto;
        width: auto;
        padding: 0 40px;
      }

      input,
      textarea {
        width: 100%;
        min-height: 40px;
        outline: none;
        border: none;
        border-radius: 0;
        padding: 0;
        text-align: left;
        color: black;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 15px;
        line-height: 136%;
        caret-color: var(--site-theme-color);

        @include breakpoint-sm {
          font-size: 13px;
        }

        &:focus {
          border-bottom: 1px solid rgba(0, 0, 0, 0.8);
        }

        &::placeholder {
          color: rgba(0, 0, 0, 0.4);
        }
      }

      input[type='radio'] {
        display: none;

        + label {
          display: flex;
          align-items: center;
          font-size: 15px;
          line-height: 136%;
          font-weight: normal;
          cursor: pointer;
          user-select: none;
          text-align: left;
          margin-bottom: 0px;
          width: 100%;
          height: 100%;

          @include breakpoint-sm {
            font-size: 13px;
          }
        }

        &:checked {
          + label {
            &:after {
              content: '';
              width: 16px;
              height: 16px;
              background-image: url('../../../assets/img/icons/checked.svg');
              background-repeat: no-repeat;
              background-position: center;
              margin-left: 24px;
            }
          }
        }
      }

      textarea {
        display: block;
        resize: none;
      }
    }

    :global(.text-field) {
      color: #000000;
      background: white;
      border: 1px solid transparent;
      border-radius: 5px;

      &::placeholder {
        color: #828282;
      }
    }
  }

  .reinstall_btn {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 40px;
    cursor: pointer;

    &:hover {
      svg path {
        fill: var(--site-theme-color);
      }
      span {
        color: var(--site-theme-color);
      }
    }

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin-left: 25px;
    }
  }
}
