@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    background: #f4f4f4;

    .titleContent {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding-top: 40px;
      padding-left: 64px;
      height: 608px;
      position: relative;

      h2 {
        font-family: iorad;
        font-size: 204px;
        font-weight: 400;
        line-height: 67%;
        color: black;
        margin: 0;
        text-transform: uppercase;
      }

      p {
        color: black;
        font-size: 32px;
        font-weight: 600;
        line-height: 136%;
        max-width: 396px;
        width: 100%;
        margin: 0;
      }

      .bg_image {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
      }
    }

    .capture {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 19px 64px 0px;
    }
  }

  .leaderList {
    display: flex;
    flex-wrap: wrap;
    gap: 73px 49px;
    max-width: $maxWidthContent;
    margin: 37px auto 78px;
    padding: 0 64px;

    .leaderItem {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: calc(100% / 3 - 50px * 2);
      max-width: 380px;

      .infoWrapper {
        display: flex;
        height: 125px;
      }

      .avatar {
        min-width: 125px;
        max-width: 125px;

        img {
          width: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 15px;
        line-height: 136%;
        color: black;
        margin-left: 22px;

        .name {
          font-weight: 600;
          margin-bottom: 10px;
        }

        .viewBio {
          line-height: 100%;
          font-weight: 600;
          text-decoration: underline;
          transition: all 0.3s ease;
          cursor: pointer;

          &:hover {
            color: var(--site-theme-color);
            text-decoration: none;
          }
        }
      }

      .desc {
        width: 100%;
        margin-top: 35px;
        display: none;

        p {
          font-size: 15px;
          line-height: 136%;
          color: black;
          margin: 0;
        }

        &.show {
          display: block;
        }
      }
    }
  }

  .howItAllStarted {
    background: #f4f4f4;

    .howItAllStarted_content {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 52px 64px 50px;
    }

    .howItAllStarted_title {
      display: flex;
      flex-direction: column;

      h2 {
        color: black;
        font-family: iorad;
        font-size: 204px;
        font-weight: 400;
        line-height: 67%;
        letter-spacing: -1.224px;
        margin: 0;
        text-transform: uppercase;

        &.end {
          text-align: end;
        }
      }
    }

    .howItAllStarted_desc {
      color: black;
      font-size: 15px;
      line-height: 136%;
      max-width: 810px;
      width: 100%;
      margin: 0;
      margin-top: 88px;

      b {
        font-weight: 600;
      }
    }
  }

  .coreValues {
    background: #77c082;

    .coreValues_wrapper {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 82px 64px 36px;
    }

    .coreValues_title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: black;
      margin: 0;
      margin-bottom: 22px;
    }

    .coreValues_desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      width: 100%;
      max-width: 618px;
      margin: 0;

      a {
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .coreValues_list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .coreValues_list_item {
        width: 405px;
        height: 240px;
        display: flex;
        align-items: center;
        padding: 0 42px;
        position: relative;

        h3 {
          font-size: 40px;
          font-weight: 600;
          line-height: 136%;
          color: black;
          position: relative;
          z-index: 1;
        }

        img {
          display: none;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }

        &:hover {
          h3 {
            color: white;
          }
          img {
            display: block;
          }
        }
      }
    }
  }
}

.footerSection {
  background: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 180px;
  padding-bottom: 100px;
  margin-top: -2px;

  .footerSection_wrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .footerSection_content {
    display: flex;
    justify-content: space-between;
    padding-left: 64px;
    padding-right: 158px;
  }

  .footerSection_title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .authWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .signUpBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-bottom: 22px;

    &:hover {
      color: #fff;
      background-color: var(--site-theme-color);
    }
  }

  .seePurchase {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;

    a {
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .titleWrapper {
      .titleContent {
        max-width: 100%;
        padding: 69px 24px 0;
        height: calc(100vh - 94px);

        .tilte_image {
          width: 100%;
        }

        p {
          font-size: 21px;
          line-height: 24px;
          max-width: 100%;
          padding: 0 10px;
        }

        .bg_image {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          height: auto;
        }
      }

      .capture {
        max-width: 100%;
        margin: 0 auto;
        padding: 0;
      }
    }

    .leaderList {
      gap: 43px;
      margin: 16px 0 42px;
      padding: 0 24px;

      .leaderItem {
        flex-basis: 100%;
        max-width: 100%;
      }

      .infoWrapper {
        height: 80px;
      }

      .avatar {
        min-width: 80px;
        max-width: 80px;
      }

      .info {
        font-size: 13px;
        margin-left: 17px;

        .name {
          margin-bottom: 13px;
        }
      }

      .desc {
        p {
          font-size: 13px;
        }
      }
    }

    .howItAllStarted {
      .howItAllStarted_content {
        max-width: 100%;
        padding: 62px 24px;
      }

      .howItAllStarted_title {
        img {
          width: 100%;
        }
      }

      .howItAllStarted_desc {
        font-size: 13px;
        max-width: 100%;
        margin-top: 35px;
      }
    }

    .coreValues {
      .coreValues_wrapper {
        max-width: 100%;
        padding: 54px 24px 0;
      }

      .coreValues_title {
        font-size: 21px;
        margin-bottom: 18px;
      }

      .coreValues_desc {
        font-size: 13px;
        max-width: 100%;
      }

      .coreValues_list {
        flex-direction: column;
        flex-wrap: nowrap;
        row-gap: 43px;
        margin-top: 39px;

        .coreValues_list_item {
          width: 100%;
          height: 295px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          &.iorad_logo {
            height: auto;
            justify-content: flex-start;

            svg {
              width: 36px;
            }
          }

          h3 {
            font-size: 21px;
            color: white;
          }

          img {
            display: block;
          }
        }
      }
    }
  }

  .footerSection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;
    padding-top: 166px;
    padding-bottom: 198px;

    .footerSection_content {
      flex-direction: column;
      padding: 0 24px;
    }

    .footerSection_title {
      font-size: 21px;
      margin-bottom: 71px;
    }

    .authWrapper {
      padding: 0 16px;
    }

    .signUpBtn {
      width: 295px;
      font-size: 13px;
    }

    .seePurchase {
      font-size: 13px;
    }
  }
}
