.container {
  margin: 20px 20px 0;

  h3 {
    margin-bottom: 30px;
    margin-top: 40px;
  }
}

.root {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.input {
  width: 225px;

  & > div {
    width: 100%;
  }

  align-items: center;
  display: flex;
  flex-wrap: wrap;

  &.number {
    min-width: 60px;
    width: 60px;

    :global(input.text-field) {
      text-align: center;
    }
  }

  &.name {
    min-width: 250px;
    width: 250px;
  }

  &.planIds {
    min-width: 150px;
    width: 150px;
  }

  :global(input.text-field) {
    width: 100%;
    padding: 3px;
  }

  label {
    font-weight: 600;
  }
}

.list {
  & > .root:not(:first-child) {
    :global(.text-field-label) {
      display: none;
    }

    .dash {
      margin-top: 0;
    }
  }
}

.button {
  max-width: 50px;
  display: flex;
  align-items: center;
  padding-top: 18px;

  button {
    height: 30px;
    margin-right: 5px;
  }
}

.dash {
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.selectName {
  max-width: 180px;
}