.contactInfo {
  display: flex;
  flex-direction: column;
}

.formGroup {
  display: flex;
  align-items: center;
  gap: 16px;

  .formLabel {
    display: flex;
    align-items: center;
    flex: 1;
    height: 56px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    opacity: 0.5;
    margin: 0;
  }

  .formInput {
    display: flex;
    align-items: center;
    flex: 1;
    height: 56px;
    padding: 0;
    font-size: 15px;
    line-height: 20px;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    min-width: 320px;

    &:focus {
      border-color: var(--site-theme-color);
    }
  }

  .formSelect {
    flex: 1;
    min-width: 320px;

    :global(.react-selectize-search-field-and-selected-values) {
      padding-left: 0 !important;
    }

    :global(.dropdown-menu) {
      max-height: 156px !important;
    }
  }
}
