@import '~theme/modules/variables';
@import '~theme/mixins';

.featureSection {
  background: #f4f4f4;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding-left: 64px;
  }

  .titleContent {
    @include ioradFont;
    padding-top: 53px;
    padding-bottom: 75px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 158px;
    padding-bottom: 50px;
  }

  .desc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    width: 536px;
  }

  .seePricingBtn {
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background: #ffffff;
    width: 276px;
    height: 48px;
    transition: all 0.3s ease;

    &:hover {
      color: #ffffff;
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .featureSection {
    position: relative;
    padding: 40px 24px 174px;
    margin-top: -2px;

    .contentWrapper {
      padding: 0;
      margin: 0;
    }

    .titleContent {
      font-size: 80px;
      padding-top: 49px;
      padding-bottom: 0;
    }

    .content {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    .desc {
      font-size: 13px;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 74px;
    }

    .seePricingBtn {
      width: 295px;
      font-size: 13px;
    }
  }
}
