@import './variables';

.setting_content__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;

  .setting_content__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    height: $defaultHeightHeader;
    background: $defaultColor;
    cursor: pointer;

    .setting_content__header_text {
      @include defaultFont;
    }

    .setting_content__header_close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $defaultHeightHeader;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .setting_content__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}
