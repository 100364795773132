.period__date {
  color: var(--site-theme-color);
  font: inherit;

  &:hover {
    text-decoration: underline;
    text-decoration-style: dotted;
  }
}

.period__dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 56px * 12;
  overflow: hidden;
  color: black;
}

.period__tabs {
  display: flex;
  align-items: center;
  column-gap: 24px;
  flex-shrink: 0;
  height: 56px;
  padding: 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .period__tabs_item {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    opacity: 0.5;
    cursor: pointer;

    &.active,
    &:hover {
      opacity: 1;
    }
  }
}

.period__tab_content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.period__list {
  list-style: none;
  padding: 0;
  margin: 0;

  .period__list_header {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &:first-child {
      border-top: none;
    }

    strong {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 56px;
      padding: 0 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
    }

    ul {
      padding: 0;

      li {
        padding-left: 48px;
      }
    }
  }

  .period__list_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 56px;
    padding: 0 24px;
    font-size: 15px;
    line-height: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
    }

    p {
      margin: 0;
      opacity: 0.5;
    }
  }
}

.period__calendar {
  display: flex;
  flex-direction: column;

  .period__calendar_header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    column-gap: 16px;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 15px;
    line-height: 20px;

    .period__calendar_date {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }

  .period__calendar_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .period__calendar_picker {
      width: 100%;
      border: none;
    }
  }

  .period__calendar_footer {
    display: flex;
    justify-content: center;
    padding: 8px 0;

    button {
      width: 320px;
      height: 48px;
    }
  }
}
