@import '../../../variables';

.learner_view {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .learner_view__header {
    display: flex;
    flex-direction: column;
    margin: 16px 16px 0;
    background: white;

    .learner_view__header_select {
      border-bottom: none;
      justify-content: center;
      z-index: 2;

      > div {
        width: auto;

        > div {
          font-weight: 600;
        }
      }
    }

    .learner_view__header_input {
      display: flex;
      align-items: center;
      position: relative;

      &.has_button {
        input {
          padding-right: 132px;
        }
      }

      input {
        padding-left: 16px;
        padding-right: 16px;
      }

      .learner_view__expand_all {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0;
        @include defaultFont;
        font-weight: 600;
        padding: 0 16px;
        cursor: pointer;
      }
    }
  }

  .learner_view__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .learner_view__footer {
    display: flex;
  }
}

.learner_view__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 100%;
  @include defaultFont;
  background: white;
}

.learner_view__list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  padding-left: 16px;
  padding-right: 5px;

  .dragingPlaceholder {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    display: none;

    &.active {
      display: block;
    }
  }

  .dragingItemList {
    z-index: 1;
    position: relative;
    height: 100%;
    background: white;
  }

  &.isDragging {
    .learner_view__list_item_header_label {
      &[role='button']:hover {
        color: inherit;
      }
    }

    .dragZone,
    .learner_view__list_item {
      &.dragging:not(.after) {
        display: none;
      }
    }

    .learner_view__list_item_header {
      background: white;
    }
  }

  &.isDropped {
    .learner_view__list_item_header {
      background: white;
    }
    .learner_view__list_item_header_label {
      &[role='button']:hover,
      &:hover {
        color: inherit;
      }
    }
  }
}

.learner_view__list_item {
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  &:first-child {
    border-top: none;
  }

  &.blink {
    .learner_view__list_item_header,
    .learner_view__subList_item_header {
      animation: blink 0.3s linear infinite;
    }
  }

  &.dragging {
    .learner_view__list_item_header {
      background: var(--background-color);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }

  &.hidden {
    visibility: hidden;
  }
}
.learner_view__list_item_header {
  display: flex;
  align-items: center;
  padding: 16px;
  background: white;
  @include defaultFont;

  &:hover {
    background: var(--background-color);

    .learner_view__list_item_header_label .learner_view__list_item_header_open,
    .learner_view__list_item_header_remove {
      visibility: visible;
    }
  }

  .learner_view__list_item_header_expand {
    display: flex;
    align-items: center;
    align-self: flex-start;
    height: 24px;
    margin-right: 16px;
  }

  .learner_view__list_item_header_label {
    margin: 0;
    flex-grow: 1;

    span {
      @include defaultFont;
      word-break: break-word;
      margin-right: 16px;
    }

    .learner_view__list_item_header_open {
      visibility: hidden;
      opacity: 0.4;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .learner_view__list_item_header_remove {
    @include defaultFont;
    font-weight: 600;
    margin-left: auto;
    visibility: hidden;
    padding-left: 16px;
    align-self: flex-end;
  }
}

.dragZone {
  z-index: 3;
  position: relative;
  width: 100%;
  margin-top: -19px;
  margin-bottom: -19px;

  .dragZoneInner {
    visibility: hidden;
    height: 38px;
    border: 1px solid var(--site-theme-color);
    pointer-events: none;
  }

  &.active {
    padding-top: 19px;
    padding-bottom: 19px;

    .dragZoneInner {
      height: 100%;
      visibility: visible;
    }
  }
}
.learner_view__subList {
  &.isDragging {
    .dragZone,
    .learner_view__subList_item {
      &.dragging:not(.after) {
        display: none;
      }
    }
    .learner_view__subList_item_header {
      background: white;
    }
  }

  &.isDropped {
    .learner_view__subList_item_header {
      background: white;
    }
  }

  // 2nd level or more
  .learner_view__subList {
    .learner_view__subList_item_header {
      padding-left: 80px;
    }
  }
}
.learner_view__subList_item {
  &.dropped {
    .learner_view__subList_item_header {
      animation: blink 0.3s linear infinite;
    }
  }

  &.dragging {
    .learner_view__subList_item_header {
      background: var(--background-color);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }

  &.hidden {
    visibility: hidden;
  }
}
.learner_view__subList_item_header {
  display: flex;
  align-items: center;
  padding: 16px;
  padding-left: 48px;
  background: white;

  &:hover {
    background: var(--background-color);

    .learner_view__subList_item_header_label
      .learner_view__subList_item_header_open {
      visibility: visible;
    }
  }

  &:not(.checked) {
    .learner_view__subList_item_header_label {
      &:not([role='button']:hover) {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.learner_view__subList_item_header_expand {
  display: flex;
  align-items: center;
  align-self: flex-start;
  height: 24px;
  margin-right: 16px;
}

.learner_view__subList_item_header_label {
  margin: 0;
  flex-grow: 1;

  span {
    @include defaultFont;
    word-break: break-word;
    margin-right: 16px;
  }

  .learner_view__subList_item_header_open {
    visibility: hidden;
    opacity: 0.4;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
.learner_view__subList_item_header_check {
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  flex-shrink: 0;
  position: relative;

  &[role='button']:hover {
    color: var(--marker-color);
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &::after {
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 50%;
    width: 16px;
    height: 16px;
    content: '';
    transform: translateY(-50%);
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &.checked::after {
    background-image: url('~assets/img/icons/checkmark.svg');
    background-size: 76%;
  }
}
