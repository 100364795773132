@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    background: #f4f4f4;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 54px 64px 45px;
  }

  .titleContent {
    @include ioradFont;
  }

  .exploreWrapper {
    background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
    background-position: top center;
    background-repeat: no-repeat;
    margin-top: -2px;
  }

  .explore {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
    display: flex;
    flex-direction: column;
  }

  .arrowDown {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 85px;
    margin-bottom: 73px;
    cursor: pointer;

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin-bottom: 14px;
    }

    &:hover {
      span {
        color: var(--site-theme-color);
      }

      svg path {
        stroke: var(--site-theme-color);
      }
    }
  }

  .exploreList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 142px;
    gap: 70px 20px;
  }

  .exploreItem {
    display: flex;
    flex-direction: column;
    width: 294px;
  }

  .exploreImage {
    width: 100%;
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  .exploreTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 20px;
  }

  .exploreDesc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .ioradHelpTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 13px;
  }

  .ioradHelpDesc {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: black;
    max-width: 728px;
    margin: 0;
    margin-bottom: 33px;
  }

  .popularContent {
    display: flex;
    flex-direction: column;
    max-width: 1366px;
    margin: 112px auto 0;
    padding: 0 64px;

    &__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 7px;
    }

    &__desc {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      margin: 0;
      margin-bottom: 32px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 53px 20px;
      margin-bottom: 30px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 294px;

      &_img {
        width: 100%;
        height: 147px;
        margin-bottom: 21px;

        img {
          width: 100%;
        }
      }

      &_tag {
        color: #000;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        opacity: 0.4;
        margin-bottom: 13px;
      }

      &_title {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin: 0;
        margin-bottom: 5px;
      }

      &_desc {
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin: 0;
      }
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 206px 158px 167px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .title {
      padding: 62px 0 102px 21px;
    }

    .exploreWrapper {
      background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
      background-size: 100% 255px;
      margin-top: 0;
    }

    .explore {
      padding: 0 24px;
    }

    .arrowDown {
      margin-top: 57px;
      margin-bottom: 79px;

      span {
        font-size: 13px;
        line-height: 136%;
        margin-bottom: 12px;
      }
    }

    .exploreList {
      flex-direction: column;
      margin-bottom: 118px;
      gap: 39px;
    }

    .exploreItem {
      width: 100%;
    }

    .exploreImage {
      height: 100%;
      margin-bottom: 24px;

      img {
        width: 100%;
      }
    }

    .exploreTitle {
      font-size: 21px;
      line-height: 116%;
      margin-bottom: 21px;
    }

    .exploreDesc {
      font-size: 13px;

      a {
        font-weight: 600;
      }
    }

    .ioradHelpTitle {
      font-size: 21px;
      line-height: 116%;
    }

    .popularContent {
      padding: 0 24px;

      &__title {
        font-size: 21px;
        line-height: 116%;
      }

      &__list {
        flex-direction: column;
        flex-wrap: nowrap;
        row-gap: 39px;
      }

      &__item {
        width: 100%;

        &_img {
          height: 100%;
        }
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 237px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      line-height: 136%;
      margin-bottom: 70px;
    }

    .learnMoreBtn {
      width: 295px;
      background: #000;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}

.helpcenter_iframe {
  width: 100%;
  height: 600px;
  border: 1px solid #f1f1f1;

  @include breakpoint-sm {
    height: 500px;
  }
}
