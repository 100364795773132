@import '../../theme/guidelines_variables';
@import '../../theme/guidelines.scss';
@import '../../theme/mixins.scss';
@import '../../theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/link_underline';

$grey : #444;
$fontBase: 'Libre Franklin', sans-serif;

.auth {
  $font: $fontBaseV2;
  overflow-y: auto;
  margin: 0;
  font-family: $fontBaseV2;

  &:has(> .shareForEmbedInfo) {
    height: 100%;
  }

  .contentWrapper {
    margin-top: 102px;
    margin-bottom: 65px;
    background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    height: calc(100vh - 194px);
    min-height: max-content;

    &.withoutMenuBar {
      height: calc(100vh - 103px);
    }

    &.shareForEmbedInfo {
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
    }

    &.cookieDisabled {
      display: none;
    }
  }

  .content {
    position: relative;
    max-width: $maxWidthContent;
    width: 100%;
    margin: 0 auto;
    padding: 0 64px;
    display: flex;
    column-gap: 64px;
  }

  .titleWrapper {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 22px;
    }

    .titleLink {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;

      a {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  .waitable {
    width: 348px;
    margin-top: 58px;
    margin-bottom: 92px;
  }

  .emailFormGroup {
    height: 140px;
  }

  :global(.form-group) {
    margin-bottom: 33px;

    &:global(.error) {
      margin-bottom: 5px;
    }

    &.messageFormGroup {
      margin-bottom: 75px;
    }

    &.passwordFormGroup {
      position: relative;
      margin-bottom: 0px;
    }

    .passwordField {
      position: relative;
    }
  }

  :global(.text-field), button {
    width: 100%;
    min-width: 130px;
    height: 34px;
  }

  :global(.text-field), :global(.text-field:hover) {
    display: block;
    text-align: left;
    padding: 6px 0;
    &:global(.error) {
      border-width: 1px !important;
    }
  }

  :global(.text-field.textarea) {
    resize: none;
    height: auto;
    line-height: 150%;
    box-sizing: content-box;
  }
  :global(.text-field:disabled) {
    opacity: 0.5;
  }

  .eyeIconWapper {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 55%;
    transform: translateY(-50%);

    svg {
      width: 18px;
      height: 13px;
    }

    &:hover {
      svg {
        path {
          stroke: var(--site-theme-color);
        }
        circle {
          fill: var(--site-theme-color);
        }
      }
    }
  }

  .generatePassword {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  button {
    font-family: $fontBaseV2;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    color: #FFFFFF;
    outline: none;
    height: 48px;
    flex-shrink: 0;

    &.google,
    &.microsoft {
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        vertical-align: bottom;
        border-radius: 2px;
        background-color: #fff;
        background-size: auto 20px;
        background-position: center;
        background-repeat: no-repeat;
        width: 23px;
        height: 23px;
        margin-right: 20px;
      }
    }
    &.google {
      &:before {
        background-image: url('../../assets/img/icons/google.svg');
      }
    }
    &.microsoft {
      &:before {
        background-image: url('../../assets/img/icons/microsoft.svg');
      }
    }
  }

  .errors_container {
    margin-top: $gl_gap;

    .error {
      border-radius: 5px;
      position: relative;
      color: var(--site-theme-color);
      text-align: left;
      font-family: $fontBaseV2;
      font-weight: $regularFontWeight;
      font-size: 15px;
      line-height: 136%;
      margin: 0;

      &.errorOther {
        text-align: center;
      }
    }

  }

  .get_password_link {
    margin: 0;
    margin-top: 15px;
    font-family: $fontBaseV2;
    font-weight: $regularFontWeight;
    font-size: 15px;
    line-height: 136%;
  }

  :global(#g-recaptcha) > div {
    margin: 0 auto;
  }


  .buttonGroup {
    width: 276px;
    margin: 45px auto 0;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
  }

  .authButtonWrapper {
    flex-grow: 1;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    &.ssoButtonWrapper {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .authButton {
    cursor: pointer;
    background-color: #FFF;
    color: #000;
    border-radius: 0px;
    border: 1px solid rgba(0,0,0,0.2);
    padding: 0px;

    &.thirdButton {
      font-weight: normal;
    }

    &:hover {
      color: var(--site-theme-color);
    }
  }
  .forgot_password {
    text-align: left;
  }

  .link {
    @extend .link-underline;
  }

  .linkPointer {
    @extend .link-underline;
    cursor: pointer;
  }

  .or {
    font-size: 15px;
    opacity: 0.2;
    text-align: center;
    margin: 11px 0;
  }

  .checkbox, .radio {
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    padding-left: 4 * $gl_gap;
    font-weight: inherit;
    user-select: none;
    color: #000000;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin: 10px 0;

    @media (max-width: 767px) {
      &,a {
        font-size: 13px!important;
      }
    }

    input[type="checkbox"]:checked ~ label::after,
    input[type="radio"]:checked ~ label::after {
      background-image: url("../../assets/img/icons/checkmark.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 76%;
      border: none;
    }

    input[type='checkbox'], input[type='radio'] {
      position: absolute;
      cursor: pointer;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      bottom: 3px;
      left: 0;
      margin: 0;
      opacity: 0;
      top: 3px;
      z-index: 1;
    }

    label:after {
      position: absolute;
      cursor: pointer;
      left: 0;
      top: 4px;
      width: 16px;
      height: 16px;
      content: '';
      background-color: white;
      background-repeat: no-repeat;
      background-position: center center;
      border: 1px solid rgba(0,0,0,0.2);
      border-radius: 1px;
    }

    a {
      margin: 0;
    }
  }

  &.loading, &.completed {
    :global(.form-group) {
      opacity: 0.2;
      pointer-events: none;
    }
  }
  &.loading {
  }
  &.completed {
  }

  .closeAuth {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 4px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    cursor: pointer;

    &:hover {
      background: var(--site-theme-color);
    }

    &.hide {
      display: none;
    }
  }
}

@mixin smallScreenAuthStyle {
  .contentWrapper {
    margin-top: 16px;
    height: calc(100vh - 74px);
    margin-bottom: 182px;
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_small.png');
    background-size: 100% 115px;

    &.withoutMenuBar {
      height: calc(100vh - 16px);
    }

    &.extensionEmbed {
      margin-bottom: 0;
      height: 100%;
    }
  }

  .content {
    padding: 0 14px;
    flex-direction: column;
  }

  .titleWrapper {

    .title {
      font-size: 21px;
      margin-bottom: 21px;
    }

    .titleLink {
      font-size: 13px;
    }
  }

  .waitable {
    width: 100%;
    margin-top: 26px;
    margin-bottom: 55px;
  }

  .link,
  .or {
    font-size: 13px;
  }

  .errors_container {
    width: 100%;

    .error {
      font-size: 13px;
    }
  }

  .get_password_link {
    margin-top: 10px;
    font-size: 13px;
  }

  .buttonGroup {
    width: 100%;
    margin-top: 20px;
  }

  .authButtonWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      margin: 0;
    }

    &.ssoButtonWrapper {
      gap: 4px;
    }
  }

  button {
    width: 100%;
    font-size: 13px;
    margin: 0 auto;
  }
}

@include breakpoint-sm {
  .auth:not(.withSideView) {
    @include smallScreenAuthStyle;
  }
}
.auth.withSideView {
  @include smallScreenAuthStyle; // always use mobile styles

  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: white;
  padding-top: 60px;
  overflow: hidden;

  .ioradLogoBar {
    position: fixed;
    top: 19px;
    left: 19px;
    display: flex;

    .ioradLogo {
      width: 67px;
    }
  }

  .contentWrapper {
    width: 50%;
    flex-shrink: 0;
    height: 100%;
    @include nice-scrollbar;
  }

  .checkbox,
  .radio {
    white-space: normal;
  }

  .buttonGroup {
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    margin: 0;
    padding: 100px 24px 0 24px;
    display: flex;
    flex-direction: column;
    z-index: 20;
    background: rgba(0, 0, 0, 0.05);
    @include nice-scrollbar;
  }

  .authButtonWrapper a {
    margin: 0;
    display: flex;
  }

  .authButton {
    background-color: transparent;
  }

  button {
    width: 100%;
    max-width: 295px;

    &.google:before {
      content: none;
    }
  }
}

.cookieDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

