.aliasModal {
  max-width: 661px;
  margin: 0 auto;

  * {
    font-family: 'Libre Franklin', sans-serif;
  }

  .row {
    margin-left: 45px;
  }

  .row.current {
    .title {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.3);
      min-height: 15px;
    }

    .accountName {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #000000;
      min-height: 40px;
      margin-top: 8px;
    }
  }

  .row.numberOfAliaes {
    margin-top: 45px;

    .title {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.3);
      min-height: 15px;
    }

    .col {
      display: flex;
      margin-top: 34px;

      .count {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        min-height: 29px;
      }

      .btnCreate {
        margin-left: auto;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        background: rgba(0, 0, 0, 0.1);
        border-radius: 40px;
      }
    }
  }

  .separator {
    height: 5px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    margin-top: 39px;
    margin-bottom: 24px;
  }

  .editor {
    .head {
      margin-bottom: 24px;
      margin-left: 41px;

      .empty {
        font-size: 18px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #000000;
        min-height: 56px;
      }

      .accountName {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #000000;
        min-height: 56px;
      }
    }

    .list {
      .listItem {
        display: flex;

        .domainName {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #000000;
          min-height: 56px;
          margin-left: 41px;
          margin-right: auto;
        }

        input {
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #000000;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          border-radius: 4px;
          height: 56px;
          margin-left: 16px;
          flex-grow: 1;
          margin-right: 19px;
          padding: 0 25px;
        }

        .btn {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #0477FF;
          background: none;
          border: 0;

          &:focus {
            outline: none;
          }

          &.btnRemove {
            color: #FF291C;
          }
        }

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }

        &.new, &.editing {
          &:hover {
            background: transparent;
          }
        }

        &.deleted {
          .domainName {
            color: rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}