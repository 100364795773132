.creators {
	display: flex;
	height: 45px;
	align-items: center;
	font-size: 15px;
	font-weight: 600;
	position: relative;
	border-bottom: 1px solid #000000;


  &.disabled {
    div button {
      @extend .fade;
    }
		.button:hover {
			cursor: default;
			color: #999999;
		}
  }

  &.hideButtons {
    div button {
      visibility: hidden;
      cursor: default;
    }
  }
}

.button {
	flex: 1;
	color: #999999;
	padding: 0;
	border: none;
	outline: none;
  background: none;
  transition: color .3s ease;

	&:hover {
		color: #000;
	}

	&.leftButton {
		text-align: left;
	}

	&.rightButton {
		text-align: right;
	}
}

.user_count {
	flex: 1;
  width: 100px;
  text-align: center;
  background: white;
  border: none;
  color: #000000;
}


.fade {
  opacity: .5;
  cursor: default;
}

.creatorCostWrapper {
	display: block;
}
