@import '~theme/modules/variables';
@import '~theme/variables';
@import '~theme/mixins';
@import '~theme/guidelines_variables';

$sidebar-bg: #1e1e26;
$navbar-index-bg: #fff;
$darkblue: #006faf;
$lightcyan: #9bcbdd;
$grey: #55616d;
$blackblue: #323438;
$bgcolor-menu-selected: rgba(0, 144, 226, 0.1);

.app {
  height: 100%;

  :global(.headroom) {
    top: 0;
    left: 0;
    right: 0;
    z-index: 5 !important;
  }

  :global(.headroom--unfixed) {
    position: relative;
    transform: translateY(0);
  }

  :global(.headroom--scrolled) {
    transition: transform 200ms ease-in-out;
  }

  :global(.headroom--unpinned) {
    position: fixed;
    transform: translateY(-100%);
  }

  :global(.headroom--pinned) {
    position: fixed;
    transform: translateY(0%);
  }

  :global(.headroom-wrapper) {
    height: 0 !important;
  }

  :global(.navbar-fixed-top) {
    position: relative;
  }

  :global(.noScrollApp) {
    overflow: hidden !important;
  }
}

.outerContainer {
  height: 100%;
}

.appContent {
  background: $sidebar-bg;
  height: 100%;
  margin: 0;

  &.screenSized {
    main {
      height: 100%;
    }
  }

  main {
    background: $white;
    height: auto;
    transition: left 0.3s ease-out;

    > div {
      transition: width 0.5s;
      transition-delay: 0.55s;
    }
  }

  :global(.contentClassName) {
    background-color: #ffffff;
    left: 0 !important;
    transition: none !important;

    &:before {
      // This element is needed to mask the space
      // between sidebar and content area
      // while they're toggling
      content: '';
      position: absolute;
      left: -3px;
      height: 100vh;
      background: rgba(0, 0, 0, 0.8);
      width: 3px;
    }
  }

  :global(.noScroll) {
    overflow: hidden !important;
  }
}

:global(#sidebar-content.videoShowing) {
  :global(#page-wrap) {
    position: relative;
    z-index: $topNavBarZIndex + 1;
  }
}

:global(.top-menu-shown) {
  :global(.contentClassName) {
    &:before {
      height: calc(100vh - 186px);
    }
  }
}

:global(.bm-menu-wrap) {
  font-family: 'Libre Franklin', sans-serif;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  top: 10px !important;
  left: 12px !important;
  bottom: auto !important;
  max-height: calc(100% - 10px - 10px) !important;
  width: 351px !important;
  max-width: calc(100% - 12px - 12px) !important;

  @include breakpoint-sm {
    width: 100% !important;
    max-width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    height: 100vh;
    max-height: 100vh !important;
  }

  .links {
    a {
      display: block;
      color: black;
      font-size: 16px;
      font-weight: normal;
      text-decoration: none;
      margin: 0;
      padding: 10px 14px 10px 24px;
      text-align: left;
      outline: none;
      position: relative;

      @include breakpoint-sm {
        text-align: center;
        padding: 10px 14px;
      }

      &:hover,
      &.selected {
        background: rgba(0, 0, 0, 0.05);
        text-decoration: none;
        color: black;
      }

      &::-moz-focus-inner {
        border: 0;
      }
    }
  }

  .notLogged {
    .links {
      a {
        color: $blue2;
        &:hover {
          color: #ff291c;
          text-decoration: underline;
        }
        &.selected {
          background: transparent;
          text-decoration: none;
          color: black;
        }
      }
    }
    margin-top: -36px;
  }

  .avatar {
    border-style: none;
    display: block;
    height: 72px;
    width: 72px;
    margin: 0 auto;
    margin-bottom: 2 * $gl_gap;
  }

  .sidebar_logo {
    margin: 4 * $gl_gap auto;
    text-align: center;
  }

  .bmMenu {
    width: 100%;
  }

  .bmMenuHeader {
    width: 100%;
    padding: 18px 0;

    @include breakpoint-sm {
      padding: 12px 0;
    }
    .captureBtn {
      display: flex;
      margin-top: -6px;
      @include breakpoint-sm {
        display: none;
      }
    }

    .captureBtn,
    .user {
      height: 100px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      @include breakpoint-sm {
        height: 180px;
      }
    }

    .closeBtn {
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px;
      cursor: pointer;

      svg {
        width: 18px;
        height: 18px;
      }

      &:hover {
        svg path {
          // stroke: #FF291C
        }
      }
    }

    .user {
      display: flex;
      align-items: center;
      padding: 0 24px;

      .avatar {
        width: 64px;
        height: 64px;
        margin-bottom: 0;
        margin-right: 24px;
      }

      .name {
        color: black;
        display: block;
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        margin-top: 0;
        margin-bottom: 10px;
        max-width: 200px;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoint-sm {
          text-align: center;
        }
      }

      .logOut {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        // color: #0047FF;
      }

      @include breakpoint-sm {
        text-align: center;
        flex-direction: column;
        justify-content: center;
        .avatar {
          margin-right: 0;
        }
        .name {
          margin-top: 16px;
        }
        .logOut {
        }
      }
    }
  }

  .bmMenuFooter {
    margin-top: 8px;
    padding-left: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    a {
      padding: 0 12px;
      color: rgba(0, 0, 0, 0.3);

      &:hover {
        color: #ff291c;
        text-decoration: underline;
      }
    }

    @include breakpoint-sm {
      position: fixed;
      bottom: 0;
      width: 100vw;
      padding-left: 0;
      display: flex;
      justify-content: center;
    }
  }

  .bmMenuInner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    min-height: 100%;

    @include breakpoint-sm {
      justify-content: flex-start;
      flex-direction: column;
    }

    &:global(.not-logged) {
      justify-content: center;

      .bmMenuHeader {
        padding: 0;
      }
    }
  }
}

.sidebarButton {
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-color: transparent;
  left: 0;
  margin-top: 10px;
  margin-left: 10px;
  position: fixed;
  z-index: 1020;
  box-sizing: content-box;

  .avatar {
    visibility: visible !important;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    }
  }

  &.open {
    display: none;
  }

  .closeIcon {
    position: relative;
    top: 2px;
    left: 2px;
    svg {
      width: 28px;
      height: 16px;
    }
  }
}

.mask {
  background: rgba(0, 0, 0, 0.55);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1090;
}

:global(.oembed) {
  border: 2px solid #ebebeb;
}

.page1 {
  padding-top: 62px !important;
}

.scrollbars {
  & > div + div + div {
    opacity: 0 !important;
    top: 4px !important;
    bottom: 4px !important;
    right: 4px !important;
    transition: opacity 0.3s ease-in-out;
    div {
      background-color: rgba(256, 256, 256, 0.2) !important;
    }
  }
}

.scrollbars:hover {
  & > div + div + div {
    opacity: 1 !important;
  }
}

.avatarOpened {
  width: auto !important;

  @include breakpoint-sm {
    width: 100% !important;
  }
}

.scheduleACallPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $topNavBarZIndex + 2;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .scheduleACallPopup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    background: white;
    padding: 56px 40px 40px;
    position: relative;

    h3 {
      font-size: 21px;
      font-weight: 600;
      line-height: 24px;
      color: black;
      margin: 0;
      margin-top: 40px;
      text-align: center;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: black;
      margin: 0;
      margin-top: 30px;
      text-align: center;
    }
  }

  .scheduleACallPopup__submitBtn {
    outline: none;
    border: none;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    padding: 10px 20px;
    height: 56px;
    color: white;
    transition: background 0.3s ease;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 40px;

    &:hover {
      background: var(--site-theme-color);
    }
  }

  .scheduleACallPopup__dismissBtn {
    outline: none;
    border: none;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 56px;
    width: 246px;
    color: black;
    transition: color 0.3s ease;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 10px;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  .scheduleACallPopup__close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }
}

@include breakpoint-sm {
  :global(.contentClassName) {
    &:before {
      height: auto !important;
    }
  }
  :global(#sidebar-content.videoShowing) {
    inset: 0 !important;
  }
}

:global(#page-wrap) {
  &:global(.fixedHeight) {
    height: 100%;
    @include inheritHeight;
    :global(.notifications-wrapper) {
      flex-grow: 0;
    }
  }
  &:global(.fixedHeightDesktop) {
    @include breakpoint-md-plus {
      height: 100%;
      @include inheritHeight;
      :global(.notifications-wrapper) {
        flex-grow: 0;
      }
    }
  }
  &:global(.withTrialFooter) {
    height: calc(100% - 48px);
  }

  :global(#page-trial-footer) {
    height: 48px;
    min-height: 48px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(var(--site-theme-color-rgb), 0.05);
      z-index: -1;
    }
  }
}
