@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/guidelines.scss';
@import '~theme/modules/variables';

$widgetPseudoContainerWidth: 316px;
$headerHeight: 100px;

.helpCenterSettings {
  min-height: 100%;
  display: flex;
  flex-direction: row;

  .tab_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;

    .tab_header {
      position: sticky;
      top: 0;
      width: max-content;
      height: $headerHeight;
      z-index: 3;
      background: white;
      display: flex;
      align-items: center;
      padding: 0 40px;

      .tab_header_nav {
        display: flex;
        column-gap: 24px;

        &::before,
        &::after {
          content: unset;
        }
      }

      .tab_header_nav_item {
        a {
          padding: 0;
          font-weight: 600;
          font-size: 15px;
          outline: none;

          &:hover,
          &:focus {
            background: transparent;
          }
        }

        &:global(.active) {
          a {
            color: var(--site-theme-color);
          }
        }
      }
    }

    .tab_content {
      display: flex;
      flex-direction: column;
    }
  }

  &.insideTutorials {
    height: 100%;
    .tab_header {
      height: 50px;
    }
    .tab_contents {
      overflow: auto;
      height: 100%;
    }
  }

  @include breakpoint-sm {
    .tab_container {
      .tab_header {
        width: 100%;
        height: auto;
        padding: 24px;
        z-index: 5;

        .tab_header_nav_item {
          a {
            padding: 0;
            font-weight: 600;
            font-size: 15px;
            outline: none;

            &:hover,
            &:focus {
              background: transparent;
            }
          }
        }
      }

      .tab_content {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.label {
  font-weight: normal;
  font-size: 15px;
  color: #999999;
  font-size: 10px;
  margin-bottom: 3px;
}

.input {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  padding: 0;
  height: 35px;
  font-size: 15px;
  font-weight: normal;

  &:active,
  &:focus {
    color: #000000;
    border-bottom: 1px solid var(--site-theme-color);
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;

  &:focus .label {
    color: #000000;
  }
}

.copyContainer {
  display: flex;
  align-items: center;
  position: relative;

  .input {
    padding-right: 40px;
  }

  &.morePadding {
    .input {
      padding-right: 60px;
    }
  }
}

.linkContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;

  .link {
    margin-right: 10px;
    color: #999999;
    font-size: 10px;
    transition: color 0.3s ease;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #000;
    }
  }
}

.checkbox {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000000;
  font-weight: 300;
  font-family: $fontBaseV2;
  padding-left: 25px;
  padding-top: 2px;

  input[type='checkbox']:checked ~ span::after {
    content: '';
    background-image: url('../../assets/img/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    border: none;
  }

  input[type='checkbox'] {
    position: absolute;
    cursor: pointer;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    bottom: 3px;
    left: 0;
    margin: 0;
    opacity: 0;
    top: 3px;
    z-index: 1;
  }

  span:after {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    content: '';
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1px;
  }
}
