.myPlan {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  padding-bottom: 24px;
  width: 100%;
  max-width: 960px;
  color: black;
}

.myPlanTitle {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: 0;
}

.myPlanContent {
  display: flex;
  column-gap: 56px;
}

.myPlanContentLeft {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.subscriptionForm {
  display: flex;
  flex-direction: column;
  background: #f4f4f4;

  .formRow {
    display: flex;
    row-gap: 24px;

    > * {
      flex: 1;
    }
  }

  .formLabel {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    opacity: 0.5;
    margin: 0;
  }
}

.planInfoGroup {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
}

.planName {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 16px;
}

.nextInvoiceDate {
  opacity: 0.4;
  font-size: 15px;
  line-height: 20px;
}

.chargeAmount {
  font-size: 21px;
  line-height: 24px;
  font-weight: 600;
}

.formRowCreators {
  margin-top: 24px;
}

.creatorCost {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
}

.subscriptionInfoGroup {
  display: flex;
  flex-direction: column;
  border-top: 1px solid white;
  padding: 24px;
}

.checkoutInfoGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.nextInvoiceMessage {
  display: flex;
  justify-content: center;
  font-size: 15px;
  line-height: 20px;
  margin-top: 24px;

  b {
    font-weight: 600;
  }
}

.planActions {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 264px;

  .planAction {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    column-gap: 16px;
    height: 40px;
    padding: 0 16px;
    font-size: 15px;
    font-weight: 600;
    color: inherit;
    cursor: pointer;

    .planActionIcon {
      display: flex;
      align-items: center;
      width: 16px;
      flex-shrink: 0;
    }

    &:hover {
      background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
    }
  }
}


