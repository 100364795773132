@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  background: #f4f4f4;
  padding: 54px 0 64px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleContent {
    @include ioradFont;
  }
}

.formWrapper {
  max-width: 100%;
  margin: 0 auto;
  padding: 64px;
  background: #f4f8fa;

  .formContent {
    width: 100%;
    height: 1560px;
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;
    padding: 70px 0 39px;

    &.isLogged {
      margin-top: 46px;
    }

    .titleWrapper {
      padding: 0;

      img {
        width: 100%;
      }
    }
  }

  .formWrapper {
    padding: 24px;
    margin-bottom: 236px;

    .formContent {
      height: 2050px;
    }
  }
}
