@import '~theme/mixins';
@import '~theme/modules/variables';

.hc_analytics__popup {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  :global(.popup-content) {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;
  }

  .hc_analytics__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    background: #f4f4f4;
    border-top: 1px solid white;
    padding: 0 24px;
  }

  .hc_analytics__container {
    display: flex;
    background: white;
    height: calc(100% - 56px);
    @include nice-scrollbar;
    margin-bottom: 40px;
  }

  .hc_analytics__left_container {
    background: white;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 11px;
    margin-top: 40px;
    overflow: hidden;
    position: sticky;
    top: 40px;
    z-index: 12; // higher .popup-header-wrapper

    &:hover {
      overflow-y: scroll;
      padding-right: 0;
    }
  }

  .hc_analytics__right_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 40px;
    padding-left: 29px;
  }

  .hc_analytics__left_content {
    width: 340px;
    background: $backgroundTiles;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  .hc_analytics__tabs {
    display: flex;
    column-gap: 20px;
    padding: 24px;
    background: #f4f4f4;
    position: sticky;
    top: 0;
    z-index: 1;

    div {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
      transition: all 0.3s ease;

      &:hover,
      &.actived {
        color: var(--site-theme-color);
      }
    }
  }

  .hc_analytics__actions {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: #f4f4f4;
    row-gap: 10px;
    border-top: 1px solid #fff;

    .hc_analytics__action_item {
      display: flex;
      align-items: center;
      column-gap: 20px;
      font-size: 15px;
      line-height: 20px;
      color: black;
      font-weight: 600;
      height: 40px;
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);

        svg {
          path[stroke] {
            stroke: var(--site-theme-color);
          }
          path[fill],
          circle[fill] {
            fill: var(--site-theme-color);
          }
        }
      }
    }
  }

  .next_refresh_hint {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.4);
    padding: 16px 0;
  }
}

@include breakpoint-sm {
  .hc_analytics__popup {
    .hc_analytics__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      background: white;
      border-top: none;
      padding: 24px;
    }
    .hc_analytics__container {
      flex-direction: column;
      height: 100%;
      margin-bottom: 0;
      overflow: hidden;
      overflow-y: scroll;
    }
    .hc_analytics__left_container {
      margin-top: 0;
      position: unset;
      padding-left: 24px;
      padding-right: 13px;
      overflow: unset;
      z-index: unset;
      
      &:hover {
        overflow-y: unset;
        padding-right: 13px;
      }
    }
    .hc_analytics__left_content {
      width: 100%;
    }
    .hc_analytics__right_container {
      padding-left: 24px;
      padding-right: 13px;
      padding-bottom: 40px;
    }
  }
}

.filter_modes {
  display: flex;
  flex: 1;
  height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;

  &.open {
    .filter_modes_input_icon {
      transform: rotate(180deg);
    }
  }

  .filter_modes_input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .filter_modes_input_value {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
    flex-grow: 1;
    margin-left: 10px;
  }
  .filter_modes_dropdown {
    position: absolute;
    z-index: 1;
    top: 56px;
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
  }
  .filter_modes_dropdown_item {
    display: flex;
    align-items: center;
    height: 32px;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
}

.hc__search_sort {
  display: flex;
  flex: 1;
  height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 0 12px;

  .sortFilters {
    display: none;
  }

  &:focus-within {
    border-color: black;
  }

  &.defaultMode {
    .searchContainer {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    .searchInputWrapper {
      width: 100%;
    }

    .searchIcon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .searchInput {
      background: transparent;
      border: 0;
    }

    .sortOptionActived {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-transform: capitalize;
    }

    .sortIconContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40px;
      cursor: pointer;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }

  &.sortModeActive {
    justify-content: flex-start;
    user-select: none;

    .searchInputWrapper {
      display: none;
    }

    .sortActive {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .sortOptionActived {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: capitalize;
      }

      .sortIconContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 40px;
        cursor: pointer;
        margin-right: 9px;
      }
    }

    .sortFilters {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 56px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-top: none;
      padding: 12px;
      z-index: 2;

      .sortOptionItem {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 32px;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.searchFocused {
    .sortIconContainer,
    .sortOptionActived,
    .sortFilters {
      display: none;
    }

    .searchInput {
      padding-right: 10px;
    }
  }
}

.loading_dot {
  position: relative;
  width: 11px;
  color: black;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  @keyframes dots {
    to {
      width: 1em;
    }
  }

  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    content: '\2026';
    width: 0px;
    animation: dots 1s steps(4, end) infinite;
  }
}
