@import '../../theme/modules/variables';
@import '../../theme/mixins';

$mainColor: #fff;
$activeColor: rgba(0, 0, 0, 0.05);
.notificationsQuick {
  position: fixed;
  right: 62px;
  top: 48px;
  width: 320px;
  max-height: calc(100vh - 93px);
  // background: #f4f4f4;
  background: $mainColor;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  z-index: #{$topNavBarMoreShownZIndex + 1};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: none;
  margin-top: -1px;

  .notificationsQuick_list {
    display: flex;
    flex-direction: column;
    row-gap: 0;
    flex-grow: 1;
    @include nice-scrollbar;
  }

  .notificationsQuick_time {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
    height: 56px;
    border-bottom: 1px solid $activeColor;
    flex-shrink: 0;
  }

  .notificationsQuick_item {
    background: $mainColor;
    padding: 12px 16px;
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid $activeColor;

    &:not(.isNew) {
      .notificationsQuick_item_newIndicator {
        visibility: hidden;
      }
    }

    &:hover {
      background: $activeColor;
    }
  }

  .notificationsQuick_item_userAvatar {
    width: 32px;
    height: 32px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .notificationsQuick_item_text {
    margin-left: 17px;
    // margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    flex-grow: 1;
    word-break: break-word;

    a {
      &, &:hover, &:focus {
        color: #000;
        text-decoration: none;
      }

      // &:hover {
      //   text-decoration: none;
      // }
    }
  }

  .notificationsQuick_item_newIndicator {
    margin-left: 17px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: var(--site-theme-color);
    flex-shrink: 0;
  }

  .notificationsQuick_expandButton {
    margin-top: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    flex-shrink: 0;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  @include breakpoint-sm {
    right: 0;
    height: 100vh;
    max-height: 100%;
    width: 100vw;
    padding: 0;

    .notificationsQuick_closeBanner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      padding-inline: 24px;
      position: relative;
      background: #f4f4f4;
      border-bottom: 2px solid #fff;
      cursor: pointer;

      span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: black;
      }
    }

    .notificationsQuick_list {
      padding: 10px;
    }

    .notificationsQuick_expandButton {
      display: none;
    }
  }
}
