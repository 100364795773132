@import '../../theme/mixins';

.contactInfo {
  :global(.text-field) {
    width: 100%;
    margin-bottom: 13px;
    padding: 0;
    padding-left: 1px;
  }
  :global(.easy-select input.text) {
    margin-left: 1px;
  }
  .cols2 {
    display: flex;
    justify-content: space-between;
    :global(.text-field-group) {
      width: 200px;
    }
    @include breakpoint-sm {
      flex-direction: column;
      :global(.text-field-group) {
        width: auto;
        :global(.easy-select) {
          width: 100%;
          margin-bottom: 13px;
        }
      }
      // :first-child {
      //   flex-basis: 3;
      //   margin-right: 22px;
      // }
      // :last-child {
      //   flex-basis:  5;
      // }
    }
  }
}
