@import '~theme/modules/variables';
@import '~theme/mixins';

.tutorialBuilderSection {
  background-image: url('~assets/img/redesign2021/productpage/bg_tutorial_builder_section.png');
  background-repeat: no-repeat;
  background-position: bottom center;

  .contentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $maxWidthContent;
    height: 100%;
    padding: 139px 0 159px 64px;
    margin: 0 auto;
  }

  .leftContent {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    color: #000000;
    width: 790px;
  }
}

@include breakpoint-sm {
  .tutorialBuilderSection {
    background-image: url('~assets/img/redesign2021/productpage/mobile/bg_tutorial_builder_section.png');
    background-size: contain;

    .contentWrapper {
      padding: 125px 24px 113px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .leftContent {
      width: 261px;
      font-size: 13px;
      line-height: 136%;
      margin-bottom: 23px;
    }
  }
}
