.creators {
	display: flex;
	align-items: center;
	height: 56px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);


  &.disabled {
    .button {
			opacity: 0.5;
			cursor: not-allowed;
			pointer-events: none;
		}
  }

  &.hideButtons {
    .button {
      visibility: hidden;
    }
  }
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 100%;
	border: none;
	background: transparent;
	outline: none;
	padding: 0;

	.fade {
		opacity: 0.5;
		cursor: default;
	}
}

.user_count {
	flex: 1;
	width: 1000%;
  text-align: center;
  background: transparent;
  border: none;
	padding: 0;
	font-size: 15px;
	line-height: 20px;
	font-weight: 400;
}

