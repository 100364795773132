@import '~theme/mixins';
@import '~theme/modules/variables';

.ioradical_avatar {
  position: relative;

  .ioradical_avatar__is_ambassador {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
  }
}

.ioradical_avatar__tooltip {
  position: absolute;
  z-index: $socialOverlayZIndex + 6;
  background: #00000033;
  padding: 8px;

  &::before {
    content: '';
    background:white;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: -1;
  }
  
  &[data-type="top"] {
    padding-bottom: 18px;
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 10px), 10px calc(100% - 10px), 0 100%);

    &::before {
      clip-path: polygon(0% 0%, 100% 0, 100% calc(100% - 10px), 9px calc(100% - 10px), 0 calc(100% - 1px));
    }
  }

  &[data-type="bottom-left"] {
    padding-top: 18px;
    clip-path: polygon(0 100%, 100% 100%, 100% 0, calc(100% - 10px) 10px, 0 10px);

    &::before {
      clip-path: polygon(0 100%, 100% 100%, 100% 1px, calc(100% - 9px) 10px, 0 10px);
    }
  }

  &[data-type="bottom-right"] {
    padding-top: 18px;
    clip-path: polygon(0 100%, 100% 100%, 100% 10px, 10px 10px, 0 0);

    &::before {
      clip-path: polygon(0 100%, 100% 100%, 100% 10px, 9px 10px, 0 1px);
    }
  }
}

.ioradical_avatar__tooltip_content {
  display: flex;
  flex-direction: column;
}

.ioradical_avatar__tooltip_username {
  position: relative;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  width: max-content;

  &.is_ambassador {
    margin-right: 8px;
  }

  svg {
    position: absolute;
    top: -7px;
    right: -10px;
  }
}

.ioradical_avatar__tooltip_usertype {
  display: flex;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
    margin-left: 8px;
  }
}