.root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border: 1px solid #f4f4f4;
  border-bottom: 0 none;

  &:not(.loaded) {
    &:before {
      content: 'Loading screenshot...';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #b8b7b7;
      font-size: 15px;
      font-style: italic;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 0;
    }
  }

  canvas {
    z-index: 1;
  }
}