@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  width: 100%;
  max-width: 810px;

  * {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }
}

.content_wrapper {
  position: relative;
  padding-top: 40px;
}

.content {
  max-width: 100%;
}

.navigate {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: black;
  text-decoration: underline;
  position: absolute;
  right: 0;
  top: 0;
}

.item {
  margin-top: 71px;
  display: none;

  &.show {
    display: block;
  }
}

.titleItem {
  font-weight: 600;
  font-size: 21px;
  line-height: 116%;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: var(--site-theme-color);
  }
}

.descItem {
  margin-top: 6px;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.listItem {
  padding-left: 17px;

  li + li {
    margin-top: 24px;
  }
}

.subTitleItem {
  display: block;
  font-weight: 600;
  margin-top: 24px;
}

.subListItem {
  padding-left: 17px;
}

.lastUpdated {
  display: block;
  font-size: 10px;
  line-height: 15px;
  color: black;
  margin-top: 61px;
  margin-bottom: 121px;
}

.navigate {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: black;
  text-decoration: underline;
  margin-left: 58px;
}

@include breakpoint-sm {
  .container {
    max-width: 100%;

    * {
      font-size: 13px;
    }
  }

  .content_wrapper {
    padding-top: 50px;
  }

  .navigate {
    font-size: 13px;
    line-height: 15px;
    right: 24px;
  }

  .content {
    max-width: 100%;
    padding: 0 24px;
  }

  .item {
    margin-top: 64px;
  }

  .titleItem {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
  }

  .descItem {
    margin-top: 10px;
  }

  .listItem {
    padding-left: 16px;

    li + li {
      margin-top: 20px;
    }
  }

  .subTitleItem {
    margin-top: 20px;
  }

  .subListItem {
    padding-left: 16px;
  }

  .lastUpdated {
    font-size: 10px;
    margin-bottom: 226px;
  }
}
