@import '../styles/common';
@import '../../../theme/variables.scss';
.planDiscounts table td {
  vertical-align: middle !important;
}
.newDiscountForm {
  display: flex;
  margin-top: 20px;
  align-items: flex-end;
  & > div {
    margin-right: 10px;
  }
}
.subCount {
  color: $blue;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
}

.disabled {
  background-color: #FeFeFe;
}
