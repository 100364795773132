@import '~theme/modules/variables';
@import '../includes';

.container {
  height: 100%;
  background: white;
  @include ontopOfOverlay();
  display: flex;
  flex-direction: column;
  padding-top: 26px;

  &.blur {
    opacity: 0.2;
    pointer-events: none;
  }

  &.isMinimize {
    height: max-content;
  }

  .SelectionTags {
    padding-bottom: $bottomBarHeight + 30px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    .tagsColumn {
      .tagItem {
        padding: 5px;
      }
    }
  }

  .header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    height: 36px;
    padding-bottom: 10px;

    .headerText {
      font-size: 15px;
      margin-right: 20px;
      font-family: $fontBaseV2;
      flex-grow: 1;
      color: #000000;
      font-weight: bold;
    }
  }
}

.tagItem {
  width: 219px;
  margin-right: 60px;
}
