@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    background: #2891be;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 56px 64px 45px;
  }

  .titleContent {
    @include ioradFont;
  }

  .leaderList {
    display: flex;
    flex-wrap: wrap;
    gap: 73px 49px;
    max-width: $maxWidthContent;
    margin: 37px auto 78px;
    padding: 0 64px;
  }

  .leaderItem {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: calc(100% / 3 - 50px * 2);
    max-width: 380px;
  }

  .infoWrapper {
    display: flex;
    height: 125px;
  }

  .avatar {
    min-width: 125px;
    max-width: 125px;

    img {
      width: 100%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin-left: 22px;

    .name {
      font-weight: 600;
      margin-bottom: 10px;
    }

    .viewBio {
      line-height: 100%;
      font-weight: 600;
      text-decoration: underline;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);
        text-decoration: none;
      }
    }
  }

  .desc {
    width: 100%;
    margin-top: 35px;
    display: none;

    p {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }

    &.show {
      display: block;
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 170px 158px 140px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .title {
      padding: 70px 24px 49px;
    }

    .leaderList {
      gap: 43px;
      margin: 16px 0 42px;
      padding: 0 24px;
    }

    .leaderItem {
      flex-basis: 100%;
      max-width: 100%;
    }

    .infoWrapper {
      height: 80px;
    }

    .avatar {
      min-width: 80px;
      max-width: 80px;
    }

    .info {
      font-size: 13px;
      margin-left: 17px;

      .name {
        margin-bottom: 13px;
      }
    }

    .desc {
      p {
        font-size: 13px;
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .learnMoreBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
