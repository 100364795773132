.container {
  margin: 20px 20px 0;

  .header {
    display: flex;
    flex-direction: row;

    button {
      max-width: none;
      width: auto;
      margin-left: 25px;
    }
  }

  table {
    tbody {
      tr {
        td {
          textarea {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
