@import '~theme/modules/variables';
@import '~theme/mixins';
@import '../general';

.integrationForm {
  display: flex;
  flex-direction: column;
  max-width: 427px;

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    font-size: 21px;
    font-weight: 600;
    line-height: 24px;
    color: black;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__submitBtn {
    max-width: 276px;
    margin-top: 50px;
  }
}

.formGroup {
  width: 100%;

  & + & {
    margin-top: 12px;
  }

  &__label {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 12px;

    a {
      color: black;
      text-decoration: underline;
    }
  }

  &__content {
    position: relative;
    width: 100%;
  }

  &__input {
    width: 100%;
    color: black;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    padding: 0;
    height: 34px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;

    &:focus {
      border-bottom-color: black;
    }
  }

  &__error {
    color: var(--site-theme-color);
    font-size: 15px;
    line-height: 20px;
    margin-top: 5px;
  }

  &__info {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    margin: 0;
    margin-top: 5px;
    text-align: center;
  }
}

@include breakpoint-sm {
  .integrationForm {
    max-width: 100%;

    &__content {
      padding: 0 24px;
    }

    &__submitBtn {
      max-width: 276px;
      margin-top: 24px;
      flex-shrink: 0;
    }
  }
}
