.installExtension {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .content {
    flex-grow: 1;
    display: flex;
    font-family: 'Libre Franklin', sans-serif;
    padding: 58px 65px;
  }

  .logo {
    background-repeat: no-repeat;
    background-image: url('../../assets/img/logo/logo-square-small.svg');
    width: 32px;
    height: 32px;
    background-size: auto 32px;
    margin-bottom: 140px;
  }

  .bigText {
    margin: 0;
    font-family: Fahkwang;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 59px;
    color: #000000;
    min-height: 153px;
  }

  .installButton {
    width: 311px;
    height: 48px;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: black;
    margin-top: 97px;
    margin-bottom: 32px;

    font-family: Libre Franklin;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;

    &:hover {
      background: #333333;
    }
  }

  .smallText {
    height: 40px;
    display: flex;
    align-items: center;

    font-family: Libre Franklin;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
  }

  .iconWarning {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }
}
