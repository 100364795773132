@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  position: relative;
  background: white;
  padding: 0 12px 12px;
  left: 0;
  top: 100%;
  z-index: 22; // one more than the overlay
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include breakpoint-sm {
    padding: 0 10px 10px;
  }

  &.isMobile {
    flex-direction: column;
    row-gap: 10px;

    .text {
      font-size: 13px;
    }
  }

  .columContainer {
    display: flex;
    column-gap: 51px;

    @include breakpoint-sm {
      column-gap: 0;
      justify-content: space-between;
      width: 100%;
    }
  }

  .column {
    width: auto;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .menuItem {
    position: relative;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 32px;
    font-weight: bold;
    cursor: pointer;

    &:hover,
    &.active {
      .text {
        color: var(--site-theme-color);
      }
      .icon svg {
        path[stroke] {
          stroke: var(--site-theme-color);
        }
        path[fill],
        circle[fill] {
          fill: var(--site-theme-color);
        }
      }
    }

    &.blur {
      opacity: 0.2;
      pointer-events: none;
    }

    &.visibility {
      &.enabled {
        img {
          // shift the enabled icon to align with disabled icon
          padding-bottom: 3px;
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    font-family: $fontBaseV2;
    font-size: 15px;
    color: #000000;
    line-height: 15px;
    white-space: nowrap;
  }

  .close_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background: transparent;
    flex-shrink: 0;
    padding: 0;
    width: 24px;

    &:hover {
      svg rect {
        fill: var(--site-theme-color);
      }
    }
  }
}

.blockingOverlay {
  position: fixed;
  z-index: 21; // one more than the top menu bar
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
