@import '~theme/mixins';
@import '~theme/modules/variables';

.summary_content {
  display: flex;
  flex-direction: column;

  .summary_content__container {
    max-width: 900px;
    width: 100%;

    .summary_content__container__header {
      margin-top: 32px;
      margin-bottom: 70px;
      display: flex;
      flex-direction: row;

      .summary_content__container__header__title {
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 24px;
        color: #000000;
        flex-grow: 1;
      }

      .summary_content__container__header__open {
        flex-shrink: 0;
        margin-left: auto;
        padding-left: 15px;
        cursor: pointer;

        &:hover {
          svg path {
            fill: var(--site-theme-color);
          }
        }
      }
    }
  }
}
