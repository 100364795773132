@import '~theme/mixins';
@import '~theme/modules/variables';

.header {
  height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  html[data-extensionEmbed='true'] & {
    border-bottom: none;
  }
  &.fullheight {
    height: 100%;
  }
  &.backgroundImage {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &:not(.fontsLoaded) {
    .header__content_info {
      visibility: hidden;
    }
  }

  .header__inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    overflow-y: auto;

    &_logo {
      width: auto;
      height: 32px;
      margin-bottom: 56px;

      .logoImage {
        height: 100%;
        width: auto;
      }

      &.hidden {
        display: none;
      }
    }

    &_info {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 48px;
      padding: 0 128px;
    }
  }

  .header__footer {
    display: flex;
    height: 48px;
    gap: 1px;
    z-index: 1;
    background: white;

    &_button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      cursor: pointer;
      flex: 1;
      background: #f4f4f4;
      color: black;
      position: relative;
      padding: 0 16px;

      &.profile {
        max-width: 33.3%;
      }

      &_content {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 16px;
        opacity: 0.5;
        width: 100%;

        img {
          max-width: 100%;
          max-height: 100%;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
        }

        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 18px;
        }

        svg {
          flex-shrink: 0;
        }

        &:not(.profile_content) {
          svg {
            color: inherit;

            [fill] {
              color: inherit;
              fill: currentColor;
            }
          }
        }
      }

      &:hover {
        .header__footer_button_content {
          opacity: 1;
        }
      }
      &.active {
        background: #fff;
        .header__footer_button_content {
          opacity: 1;
        }
      }
    }
  }

  .header__popup {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 48px);
    display: flex;
    background: #fff;
    border-top: 0 none;
    padding: 0;
    flex-direction: column;
    cursor: auto;

    &_header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: 56px;
      @include fontHelpCenter;
    }

    &_content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }

    .profile {
      display: flex;
      flex-direction: column;
      max-width: 900px;
      width: 100%;
      margin: 0 auto;
      padding: 0 24px;

      &__info {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
      }

      &__username {
        display: flex;
        align-items: flex-end;

        img {
          width: 56px;
          height: 56px;
          flex-shrink: 0;
        }

        h4 {
          margin: 0;
          margin-left: 32px;
          font-size: 21px;
          font-weight: 400;
          line-height: 21px;
          color: black;
          text-overflow: hidden;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &__bio {
        margin: 0;
        margin-top: 24px;
        @include fontHelpCenter;
        word-break: break-word;
      }

      &__stats {
        display: flex;
        align-items: center;
        column-gap: 16px;
        margin-top: 24px;
        margin-bottom: 24px;
        @include fontHelpCenter;

        label {
          margin: 0;
          font-weight: 400;
        }
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 35px;
    line-height: 41px;
    color: #000000;
  }
  .title,
  .subtitle {
    max-width: 900px;
    text-align: center;
  }
  .subtitle,
  .durationText {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }
  .buttonList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    border-radius: 0;
    width: auto;
  }
}

.underlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.scoreBoard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 1px;

  .scoreBoardItem {
    position: relative;
    min-width: 75px;
    font-size: 15px;
    flex: 1;

    .default,
    .hover {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      width: 100%;

      svg {
        height: 24px;
      }
    }

    .default {
      .current {
        display: none;
      }
    }

    .hover {
      position: absolute;
      top: 0;
      left: 0;

      p {
        text-align: center;
      }
    }

    .arrow {
      position: absolute;
      left: 100%;
      top: 0;
      width: 100%;
      height: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;

      svg {
        width: 70%;
        margin-left: -35%;
      }
    }

    &.reached .hover,
    .hover {
      display: none;
    }

    &.reached.current {
      .default {
        .current {
          display: inline-block;
        }
        .flag {
          display: none;
        }
      }
    }

    &:not(.reached) {
      cursor: pointer;
      color: #bababa;

      .default,
      .hover {
        svg {
          color: inherit;

          [fill] {
            color: inherit;
            fill: currentColor;
          }
        }
      }

      &:hover {
        color: #000;

        .default {
          visibility: hidden;
        }
        .hover {
          display: flex;
        }

        p {
          span {
            color: #bababa;
          }
        }
      }
    }

    &:last-child {
      .arrow {
        display: none;
      }
    }
  }
}

@media (max-width: 1050px) {
  .header {
    .header__content {
      justify-content: flex-start;
      padding: 56px 0;

      &_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 34px;
        padding: 0 16px;
      }
    }

    .title {
      font-weight: 600;
      font-size: 21px;
      line-height: 24px;
      text-align: center;
    }
    .subtitle,
    .durationText {
      display: none;
    }
  }
}

// Small height (desktop & mobile)
@media screen and (max-height: 390px) {
  .header {
    .header__content {
      padding: 24px 0;
    }
  }
}
