.table {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  padding-top: 16px;
  padding-left: 16px;
  background: #f4f4f4;

  button {
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}

.table__sidebar {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  overflow-y: scroll;

  &.disabled {
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      background-color: #FFF;
      opacity: 0.4;
      pointer-events: none;
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .table__sidebar_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 331px;
    background: white;
  }

  .table__sidebar__header {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  .table__sidebar__dropdown {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    column-gap: 16px;
    padding: 0 24px;
    height: 56px;
    font-size: 15px;
    line-height: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table__sidebar__search {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      width: 100%;
      height: 100%;
      font-size: 15px;
      line-height: 20px;
      padding: 0 24px;
      border: none;
      outline: none;
      background: white;
    }
  }
}

.table__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 5px;
  overflow-y: scroll;
}

.table__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: white;
}

.table__header {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: white;

  .table__header_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table__header_actions_line {
    width: 1px;
    height: 35px;
    background: rgba(0, 0, 0, 0.1);
  }

  .table__header_title {
    display: flex;
    align-items: center;
    column-gap: 16px;

    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }

    h4 {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      margin: 0;
    }
  }

  .table__header_actions_block {
    display: flex;
    align-items: center;
    column-gap: 24px;

    button {
      display: flex;
      align-items: center;
      column-gap: 16px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;
      outline: none;
      border: none;
      background: none;
      padding: 0;

      &.table__viewType {
        opacity: 0.5;

        &:hover,
        &.active {
          opacity: 1;
        }
      }

      &.table__settings,
      &.table__export {
        svg path,
        svg circle {
          fill: currentColor;
        }

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .table__header_search {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      width: 100%;
      height: 100%;
      font-size: 15px;
      line-height: 20px;
      padding: 0 24px;
      border: none;
      outline: none;
      background: white;
    }
  }

  .table__header_filter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 56px;
    width: 56px;
    padding: 0;
    outline: none;
    cursor: pointer;
    background: transparent;
    border: none;
  }

  .table__header_titles {
    display: flex;
    align-items: center;
    column-gap: 16px;
    flex-shrink: 0;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    span {
      flex: 1;
      font-size: 15px;
      line-height: 20px;
      text-align: right;
      opacity: 0.5;

      &[data-align='left'] {
        text-align: left;
      }
    }
  }
}

.table__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 56px;

  .table__row {
    display: flex;
    align-items: center;
    column-gap: 16px;
    flex-shrink: 0;
    height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border-bottom: none;
    }

    .table__row_cell {
      flex: 1;
      font-size: 15px;
      line-height: 20px;
      text-align: right;

      &[data-align='left'] {
        text-align: left;
      }

      &.hasIcon {
        display: flex;
        align-items: center;
        column-gap: 16px;
  
        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }

    .table__row_empty {
      flex: 1;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      opacity: 0.5;

      &.textLeft {
        text-align: left;
      }
    }

    .table__row_learner {
      display: flex;
      align-items: center;
      column-gap: 16px;

      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }
  }
}

.table__settings__dropdown {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .table__settings__dropdown_item {
    display: flex;
    align-items: center;
    column-gap: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 56px;
    padding: 0 16px;

    .table__settings__dropdown_item_checkbox {
      white-space: nowrap;
      position: relative;
      cursor: pointer;
      user-select: none;
      color: black;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      margin: 0;
      padding-left: 32px;

      input[type='checkbox']:checked ~ span::after {
        background-image: url('~assets/img/icons/checkmark.svg');
        background-size: 76%;
        border: none;
      }

      input[type='checkbox'] {
        display: none;
      }

      span:after {
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 2px;
        width: 16px;
        height: 16px;
        content: '';
        background-color: white;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.table_filter_dropdown {
  display: flex;
  flex-direction: column;

  .table_filter_dropdown__header {
    display: flex;
    align-items: center;
    column-gap: 24px;
    padding: 0 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .table_filter_dropdown__header_item {
      display: flex;
      align-items: center;
      column-gap: 16px;
      height: 56px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;
      opacity: 0.5;

      &.active {
        opacity: 1;
      }
    }
  }

  .table_filter_dropdown__body {
    display: flex;
    flex-direction: column;
    max-height: calc(56px * 8);
    overflow-y: scroll;
    
    .table_filter_dropdown__body_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 16px;
      flex-shrink: 0;
      height: 56px;
      padding-left: 24px;
      padding-right: 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 15px;
      line-height: 20px;
      cursor: pointer;

      label {
        font-weight: 400;
        margin: 0;
      }

      span {
        opacity: 0.4;
      }

      &:first-child {
        border-top: none;
      }

      &.subItem {
        padding-left: 48px;
      }

      &.active {
        color: var(--site-theme-color);

        svg {
          path {
            stroke: var(--site-theme-color);
          }
        }
      }
    }
  }
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  .underlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .inner {
    position: relative;
    z-index: 1;
    max-width: 1280px;
    margin: 0 auto;
    background-color: #f4f4f4;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    display: flex;
  }

  .table {
    padding: 16px;

    .table__container {
      padding-right: 0;
      overflow: auto;
    }
  }
}