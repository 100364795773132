@import "~theme/mixins";

@include breakpoint-sm {
  .smReversedColumns {
    display: flex;
    flex-direction: column-reverse;
  }
}

@include breakpoint-md-plus {
  .alignedVertically {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.reversedColumns {
      flex-direction: row-reverse;
    }
  }
}

// .aboutPage img {
//   width: 100%;

//   &.withLimitedHeight {
//     max-height: 450px;
//     @include breakpoint-sm {
//       margin-bottom: 40px;
//     }
//   }
// }

.extBtn {
  width: 100%;
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  /* height: 60px; */
  display: inline-block;
  margin: 16px 0 0;
}
.btnText {
  vertical-align: middle;
}
.alignedVertically,
.reversedColumns {
  margin-bottom: 48px;
}
.plusIcon {
  font-size: 32px;
  font-style: normal;
  font-family: serif;
  font-weight: lighter;
  vertical-align: middle;
  margin-right: 7px;
}

.aboutPage {
  display: flex;
  width: 100%;
  max-width: 720px;
  text-align: left;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 475px;

  @include breakpoint-sm {
    max-width: none;
    padding: 0 24px;
    transform: none;
    margin-top: 10px;
    margin-left: 0;
  }

  @include breakpoint-md {
    max-width: none;
    padding: 0 24px;
    transform: none;
    margin-top: 0;
  }
}

.title {
  font-weight: 600;
  line-height: 96px;
  font-size: 24px;
}

.info_block {
  margin-bottom: 28px;
}

.question_line {
  font-weight: 500;
  font-family: "Fahkwang", sans-serif;
  font-size: 48px;
  margin-bottom: 20px;

  @include breakpoint-sm {
    font-size: 27px;
    margin-bottom: 10px;
  }
}

.answer_line {
  font-family: 'Libre Franklin', sans-serif;
  line-height: 150%;
  font-weight: 500;
  font-size: 18px;
  max-width: 581px;
  padding: 20px 0;

  @include breakpoint-sm {
    font-size: 13px;
    max-width: none;
    padding: 10px 0;
  }

  .answer {
    margin-bottom: 30px;

    span {
      display: block;
      margin-bottom: 10px;
    }
  }
}

.members {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .card {
    display: flex;
    margin: 20px 0;
    width: 240px;
    font-family: 'Libre Franklin', sans-serif;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      margin-right: 15px;
    }

    .info {
      display: flex;
      flex-direction: column;

      @include breakpoint-sm {
        justify-content: flex-end;
        margin-bottom: -2px;
      }
    }

    .name {
      font-weight: 600;
      font-size: 18px;
      @include breakpoint-sm {
        font-size: 13px;
      }
    }
    .position {
      font-size: 14px;
      @include breakpoint-sm {
        font-size: 13px;
      }
    }
    .email {
      font-size: 14px;
      @include breakpoint-sm {
        font-size: 13px;
      }
    }

    @include breakpoint-sm {
      width: 100%;
      margin: 12px 0;

      img {
        width: 56px;
        height: 56px;
      }
    }
  }
}
