@import '~theme/modules/variables';
@import '~theme/mixins';

.homepage {
  margin-top: 75px;

  &.isLogged {
    margin-top: 119px;
  }

  .sectionBreadcrumb {
    display: flex;
    max-width: $maxWidthContent;
    width: 100%;
    margin: 0 auto 33px;
    padding: 0 64px;

    .sectionBreadcrumb__button {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-size: 15px;
        line-height: 20px;
        text-decoration: underline;
        margin-left: 15px;
        color: black;
      }

      &:hover {
        svg path {
          fill: var(--site-theme-color);
        }
        span {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .sectionTitle {
    background: #f4f4f4;

    .sectionTitle__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 54px 64px 98px;

      h2 {
        @include ioradFont;
      }
    }
  }

  .sectionContent {
    background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
    background-position: top center;
    background-repeat: no-repeat;
    margin-top: -2px;

    .sectionContent__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 0 64px;
      display: flex;
      flex-direction: column;
    }

    .sectionContent__desc {
      font-size: 15px;
      line-height: 20px;
      color: black;
      width: 100%;
      max-width: 622px;
      margin-bottom: 92px;
    }

    .sectionContent__list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 50px 20px;
    }

    .sectionContent__item {
      display: flex;
      flex-direction: column;
      width: 294px;

      .sectionContent__item_img {
        width: 100%;
        height: 147px;
        margin-bottom: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .sectionContent__item_title {
        font-weight: 600;
        font-size: 32px;
        line-height: 136%;
        color: #000000;
        margin: 0;
        margin-bottom: 20px;
      }

      .sectionContent__item_desc {
        font-size: 15px;
        line-height: 136%;
        color: #000000;
        margin: 0;
      }
    }
  }

  .sectionTestimonial {
    max-width: $maxWidthContent;
    width: 100%;
    margin: 103px auto 0;
    padding: 0 64px;
    display: flex;
    flex-direction: column;

    .sectionTestimonial__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 22px;
    }

    .sectionTestimonial__desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      margin-bottom: 57px;
    }

    .sectionTestimonial__list {
      display: flex;
      column-gap: 94px;
    }

    .sectionTestimonial__item {
      width: 100%;
      max-width: 350px;
    }

    .card {
      display: flex;
      flex-direction: column;

      .card__header {
        display: flex;
        min-height: 90px;
      }

      .card__image {
        width: 41px;
        height: 41px;
        filter: grayscale(1);
        object-fit: cover;
        object-position: top;
        margin-top: 4px;
        flex-shrink: 0;
      }

      .card__info {
        display: flex;
        flex-direction: column;
        color: #000000;
        width: 100%;
        margin-left: 32px;
      }

      .card__position {
        font-size: 15px;
        line-height: 136%;
        margin-bottom: 0;
        margin-right: 15px;
        font-weight: normal;
      }

      .card__name {
        font-weight: 600;
        font-size: 15px;
        line-height: 136%;
        margin: 0;
      }

      .card__content {
        font-size: 15px;
        line-height: 136%;
        color: black;
        margin: 0;
        margin-top: 30px;
      }
    }
  }

  .sectionOtherArticles {
    max-width: $maxWidthContent;
    width: 100%;
    margin: 103px auto 0;
    padding: 0 64px;
    display: flex;
    flex-direction: column;

    .sectionOtherArticles__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 22px;
    }

    .sectionOtherArticles__desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      margin-bottom: 64px;
    }

    .sectionOtherArticles__list {
      display: flex;
      column-gap: 20px;
    }

    .sectionOtherArticles__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 294px;

      &_img {
        width: 100%;
        height: 147px;
        margin-bottom: 21px;

        img {
          width: 100%;
        }
      }

      &_tag {
        color: #000;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        opacity: 0.4;
        margin-bottom: 13px;
      }

      &_title {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin: 0;
        margin-bottom: 5px;
      }

      &_desc {
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }

  @include breakpoint-sm {
    margin-top: 24px;

    &.isLogged {
      margin-top: 24px;
    }

    .sectionBreadcrumb {
      max-width: 100%;
      padding: 0 24px;
      margin-bottom: 24px;
    }

    .sectionTitle {
      .sectionTitle__content {
        padding: 62px 0 102px;

        img {
          width: 100%;
        }
      }
    }

    .sectionContent {
      background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
      background-size: 100% 255px;
      margin-top: 0;

      .sectionContent__content {
        padding: 0 24px;
      }

      .sectionContent__desc {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 98px;
      }

      .sectionContent__list {
        flex-direction: column;
        gap: 39px;
      }

      .sectionContent__item {
        width: 100%;

        .sectionContent__item_img {
          margin-bottom: 22px;
          height: 162px;
        }

        .sectionContent__item_title {
          font-size: 21px;
          line-height: 24px;
          margin-bottom: 20px;
        }

        .sectionContent__item_desc {
          font-size: 13px;
        }
      }
    }

    .sectionTestimonial {
      max-width: 100%;
      margin-top: 115px;
      padding: 0 24px;

      .sectionTestimonial__title {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      .sectionTestimonial__desc {
        font-size: 13px;
        margin-bottom: 81px;
      }

      .sectionTestimonial__list {
        flex-direction: column;
        row-gap: 33px;
      }

      .sectionTestimonial__item {
        max-width: 100%;
      }

      .card {
        .card__header {
          display: flex;
          min-height: auto;
        }

        .card__image {
          width: 56px;
          height: 56px;
        }

        .card__info {
          margin-left: 16px;
          justify-content: space-between;
        }

        .card__position {
          font-size: 13px;
          margin-right: 0;
        }

        .card__name {
          font-size: 13px;
          line-height: 13px;
        }

        .card__content {
          font-size: 13px;
          margin-top: 16px;
        }
      }
    }

    .sectionOtherArticles {
      max-width: 100%;
      margin: 116px 0 0;
      padding: 0 24px;

      .sectionOtherArticles__title {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      .sectionOtherArticles__desc {
        font-size: 13px;
        margin-bottom: 78px;
      }

      .sectionOtherArticles__list {
        flex-direction: column;
        row-gap: 63px;
      }

      .sectionOtherArticles__item {
        max-width: 100%;

        &_img {
          height: 164px;
          margin-bottom: 23px;
        }

        &_tag {
          margin-bottom: 9px;
        }

        &_title {
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 10px;
        }

        &_desc {
          font-size: 13px;
          -webkit-line-clamp: 2;
          line-clamp: 2;
        }
      }
    }
  }
}

.detailpage {
  margin-top: 128px;
  display: flex;
  flex-direction: column;

  &.isLogged {
    margin-top: 172px;
  }

  .sectionTitle {
    background: #f4f4f4;
    min-height: 370px;
    flex-shrink: 0;

    .sectionTitle__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 52px 64px 98px;

      h2 {
        @include ioradFont;
      }
    }
  }

  .sectionContent {
    max-width: $maxWidthContent;
    width: 100%;
    margin: 34px auto 0;
    padding: 0 64px;
    display: flex;
    flex-direction: column;

    .sectionContent__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 810px;
    }

    .sectionContent__breadcrumb {
      font-weight: 400;
      font-size: 15px;
      line-height: 136%;
      color: rgba(0, 0, 0, 0.4);
      margin: 0;
      margin-bottom: 22px;

      a {
        color: inherit;

        &:hover {
          color: black;
        }
      }
    }

    .sectionContent__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }

    .sectionContent__desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      margin-bottom: 27px;

      b {
        font-weight: 600;
      }

      a {
        text-decoration: underline;
      }
    }

    .sectionContent__iframe {
      max-width: 809px;
      width: 100%;
      height: 455px;
      border: none;
      outline: none;
    }

    .sectionContent__tldr {
      margin-top: 25px;

      .sectionContent__tldr_title {
        font-size: 15px;
        line-height: 136%;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 4px;
      }

      .sectionContent__tldr_list {
        margin: 0;
        padding: 0;
        margin-left: -8px;
        list-style-type: none;

        li {
          margin: 0;
          display: flex;
          align-items: center;

          svg {
            flex-shrink: 0;
          }

          span {
            font-size: 15px;
            line-height: 136%;
            margin-left: 10px;
            color: black;
          }
        }
      }
    }
  }

  .sectionExploreMore {
    display: flex;
    flex-direction: column;
    margin-top: 103px;

    .sectionExploreMore__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 0 64px;
    }

    .sectionExploreMore__title {
      font-size: 32px;
      line-height: 136%;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 22px;
    }

    .sectionExploreMore__desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin-bottom: 62px;
    }

    .sectionExploreMore__list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 52px 20px;
    }

    .sectionExploreMore__item {
      display: flex;
      flex-direction: column;
      width: 294px;

      .sectionExploreMore__item_img {
        width: 100%;
        height: 147px;
        margin-bottom: 21px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }

      .sectionExploreMore__item_title {
        font-weight: 600;
        font-size: 15px;
        line-height: 136%;
        color: #000000;
        margin: 0;
        margin-bottom: 10px;
      }

      .sectionExploreMore__item_desc {
        font-size: 15px;
        line-height: 136%;
        color: #000000;
        margin: 0;
      }
    }
  }

  @include breakpoint-sm {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .sectionTitle {
      min-height: auto;

      .sectionTitle__content {
        padding: 64px 0 49px;

        img {
          width: 100%;
        }
      }
    }

    .sectionContent {
      max-width: 100%;
      margin-top: 33px;
      padding: 0 24px;

      .sectionContent__content {
        max-width: 100%;
      }

      .sectionContent__breadcrumb {
        font-size: 13px;
        margin-bottom: 23px;
      }

      .sectionContent__title {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 19px;
      }

      .sectionContent__desc {
        font-size: 13px;
        margin-bottom: 47px;
      }

      .sectionContent__iframe {
        height: 185px;

        &.isIoradEmbed {
          height: 584px;
        }
      }

      .sectionContent__tldr {
        margin-top: 42px;

        .sectionContent__tldr_title {
          margin-bottom: 16px;
        }

        .sectionContent__tldr_list {
          margin-left: -8px;

          li {
            align-items: flex-start;

            span {
              font-size: 13px;
            }
          }
        }
      }
    }

    .sectionExploreMore {
      margin-top: 114px;

      .sectionExploreMore__content {
        max-width: 100%;
        padding: 0 24px;
      }

      .sectionExploreMore__title {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      .sectionExploreMore__desc {
        font-size: 13px;
        margin-bottom: 82px;
      }

      .sectionExploreMore__list {
        flex-direction: column;
        row-gap: 63px;
      }

      .sectionExploreMore__item {
        width: 100%;

        .sectionExploreMore__item_img {
          height: 163px;
          margin-bottom: 22px;
        }

        .sectionExploreMore__item_title {
          font-size: 13px;
          margin-bottom: 11px;
        }

        .sectionExploreMore__item_desc {
          font-size: 13px;
        }
      }
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: 31px;

  .footer {
    max-width: $maxWidthContent;
    width: 100%;
    margin: 0 auto;
    padding: 126px 158px 167px 64px;
    display: flex;
    justify-content: space-between;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }

  @include breakpoint-sm {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;
    margin-top: 47px;

    .footer {
      padding: 164px 24px 237px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      line-height: 136%;
      margin-bottom: 70px;
    }

    .learnMoreBtn {
      width: 295px;
      background: #000;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}

.use_cases {
  .sectionContent__item_img {
    &[data-id='education'] {
      background-image: url('~assets/img/explore_usecases/usecases/education.png');
    }
    &[data-id='customer_training'] {
      background-image: url('~assets/img/explore_usecases/usecases/customer_training.png');
    }
    &[data-id='sales_enablement'] {
      background-image: url('~assets/img/explore_usecases/usecases/sales_enablement.png');
    }
    &[data-id='operations'] {
      background-image: url('~assets/img/explore_usecases/usecases/operations.png');
    }
    &[data-id='human_resources'] {
      background-image: url('~assets/img/explore_usecases/usecases/human_resources.png');
    }
    &[data-id='product_management'] {
      background-image: url('~assets/img/explore_usecases/usecases/product_management.png');
    }
    &[data-id='information_technology'] {
      background-image: url('~assets/img/explore_usecases/usecases/information_technology.png');
    }
    &[data-id='learning_and_development'] {
      background-image: url('~assets/img/explore_usecases/usecases/learning_and_development.png');
    }
  }
}

.sales_enablement {
  .sectionContent__item_img,
  .sectionExploreMore__item_img {
    &[data-id='training_library'] {
      background-image: url('~assets/img/explore_usecases/sales_enablement/training_library.png');
    }
    &[data-id='smart_demos'] {
      background-image: url('~assets/img/explore_usecases/sales_enablement/smart_demos.png');
    }
    &[data-id='sales_q_&_a'] {
      background-image: url('~assets/img/explore_usecases/sales_enablement/sales_q_&_a.png');
    }
    &[data-id='sales_engineering'] {
      background-image: url('~assets/img/explore_usecases/sales_enablement/sales_engineering.png');
    }
    &[data-id='tech_stacks'] {
      background-image: url('~assets/img/explore_usecases/sales_enablement/tech_stacks.png');
    }
    &[data-id='follow_up_process'] {
      background-image: url('~assets/img/explore_usecases/sales_enablement/follow_up_process.png');
    }
    &[data-id='free_help_center'] {
      background-image: url('~assets/img/explore_usecases/sales_enablement/free_help_center.png');
    }
  }
}

.learning_and_development {
  .sectionContent__item_img,
  .sectionExploreMore__item_img {
    &[data-id='help_centers'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/help_centers.png');
    }
    &[data-id='sme_utilization'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/sme_utilization.png');
    }
    &[data-id='tutorial_creation'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/tutorial_creation.png');
    }
    &[data-id='upgrading_an_lms'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/upgrading_an_lms.png');
    }
    &[data-id='live_training'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/live_training.png');
    }
    &[data-id='blended_learning'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/blended_learning.png');
    }
    &[data-id='tutorial_sharing'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/tutorial_sharing.png');
    }
    &[data-id='learning_styles'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/learning_styles.png');
    }
    &[data-id='micro_learning'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/micro_learning.png');
    }
    &[data-id='version_control'] {
      background-image: url('~assets/img/explore_usecases/learning_and_development/version_control.png');
    }
  }
}

.customer_training {
  .sectionContent__item_img,
  .sectionExploreMore__item_img {
    &[data-id='just_in_time_content'] {
      background-image: url('~assets/img/explore_usecases/customer_training/just_in_time_content.png');
    }
    &[data-id='mitigate_one_off_questions'] {
      background-image: url('~assets/img/explore_usecases/customer_training/mitigate_one_off_questions.png');
    }
    &[data-id='decrease_live_training'] {
      background-image: url('~assets/img/explore_usecases/customer_training/decrease_live_training.png');
    }
    &[data-id='better_knowledge_articles'] {
      background-image: url('~assets/img/explore_usecases/customer_training/better_knowledge_articles.png');
    }
    &[data-id='tutorials_in_your_academy'] {
      background-image: url('~assets/img/explore_usecases/customer_training/tutorials_in_your_academy.png');
    }
    &[data-id='learning_styles'] {
      background-image: url('~assets/img/explore_usecases/customer_training/learning_styles.png');
    }
    &[data-id='white_glove_support'] {
      background-image: url('~assets/img/explore_usecases/customer_training/white_glove_support.png');
    }
    &[data-id='change_management'] {
      background-image: url('~assets/img/explore_usecases/customer_training/change_management.png');
    }
  }
}

.product_management {
  .sectionContent__item_img,
  .sectionExploreMore__item_img {
    &[data-id='release_notes'] {
      background-image: url('~assets/img/explore_usecases/product_management/release_notes.png');
    }
    &[data-id='product_feedback'] {
      background-image: url('~assets/img/explore_usecases/product_management/product_feedback.png');
    }
  }
}

.information_technology {
  .sectionContent__item_img,
  .sectionExploreMore__item_img {
    &[data-id='standardize_software_usage'] {
      background-image: url('~assets/img/explore_usecases/information_technology/standardize_software_usage.png');
    }
    &[data-id='tech_docs'] {
      background-image: url('~assets/img/explore_usecases/information_technology/tech_docs.png');
    }
    &[data-id='help_desk_tickets'] {
      background-image: url('~assets/img/explore_usecases/information_technology/help_desk_tickets.png');
    }
  }
}

.operations {
  .sectionContent__item_img,
  .sectionExploreMore__item_img {
    &[data-id='standardize_processes'] {
      background-image: url('~assets/img/explore_usecases/operations/standardize_processes.png');
    }
    &[data-id='create_documentation'] {
      background-image: url('~assets/img/explore_usecases/operations/create_documentation.png');
    }
    &[data-id='change_management'] {
      background-image: url('~assets/img/explore_usecases/operations/change_management.png');
    }
  }
}

.human_resources {
  .sectionContent__item_img,
  .sectionExploreMore__item_img {
    &[data-id='employee_profiles'] {
      background-image: url('~assets/img/explore_usecases/human_resources/employee_profiles.png');
    }
    &[data-id='scale_onboarding'] {
      background-image: url('~assets/img/explore_usecases/human_resources/scale_onboarding.png');
    }
    &[data-id='free_help_center'] {
      background-image: url('~assets/img/explore_usecases/human_resources/free_help_center.png');
    }
  }
}

.education {
  .sectionTestimonialVideo {
    max-width: $maxWidthContent;
    width: 100%;
    margin: 103px auto 0;
    padding: 0 64px;
    display: flex;
    flex-direction: column;

    .sectionTestimonialVideo__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 22px;
    }

    .sectionTestimonialVideo__desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      margin-bottom: 28px;
    }

    .sectionTestimonialVideo__list {
      display: flex;
      flex-direction: column;
    }
  }

  .sectionTestimonialText {
    margin-top: 106px;

    .sectionTestimonialText__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 0 64px;
      display: flex;
      flex-direction: column;
    }

    .sectionTestimonialText__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 22px;
    }

    .sectionTestimonialText__desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      margin-bottom: 28px;
    }

    .sectionTestimonialText__list {
      display: flex;
      flex-shrink: 0;
      background: #f4f4f4;
      padding-top: 44px;
      height: 444px;
    }

    .sectionTestimonialText__list_content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 0 64px;
      display: flex;
      justify-content: space-between;
    }

    .sectionTestimonialText__logos {
      display: grid;
      grid-template-columns: 198px 198px 198px;
      grid-template-rows: 115px 115px;
      gap: 5px;
    }

    .sectionTestimonialText__logos_item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;

      img {
        width: 100%;
      }

      &:hover,
      &.active {
        background: transparent;
      }
    }

    .sectionTestimonialText__item {
      width: 100%;
      max-width: 344px;
      margin-right: 30px;

      .card {
        display: flex;
        flex-direction: column;

        .card__header {
          display: flex;
          min-height: 90px;
        }

        .card__image {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          filter: grayscale(1);
          object-fit: cover;
          object-position: top;
          margin-top: 4px;
        }

        .card__info {
          display: flex;
          flex-direction: column;
          color: #000000;
          width: 100%;
          margin-left: 24px;
        }

        .card__position {
          font-size: 15px;
          line-height: 136%;
          margin-bottom: 0;
          font-weight: normal;
        }

        .card__name {
          font-weight: 600;
          font-size: 15px;
          line-height: 136%;
          margin: 0;
        }

        .card__content {
          font-size: 15px;
          line-height: 136%;
          color: black;
          margin: 0;
          margin-top: 24px;
        }
      }
    }
  }

  .sectionContent {
    .sectionContent__image {
      margin-top: 60px;
      margin-bottom: 56px;
    }
  }

  .videoContainer {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: content-box;

    .videoPlaceholder {
      width: 100%;
      height: 100%;
      cursor: pointer;

      .playIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;

        svg path {
          transition: fill 0.3s ease;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      .duration {
        position: absolute;
        right: 16px;
        bottom: 16px;
        background: rgba($color: #000000, $alpha: 0.3);
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff;
        padding: 0 4px;
      }

      &:hover {
        .playIcon svg path {
          fill: var(--site-theme-color);
        }
      }
    }
  }

  .sectionContent__item_img,
  .sectionExploreMore__item_img {
    &[data-id='flipping_the_classroom'] {
      background-image: url('~assets/img/explore_usecases/education/flipping_the_classroom.png');
    }
    &[data-id='instructional_coach_support'] {
      background-image: url('~assets/img/explore_usecases/education/instructional_coach_support.png');
    }
    &[data-id='scalable_support_for_teachers'] {
      background-image: url('~assets/img/explore_usecases/education/scalable_support_for_teachers.png');
    }
    &[data-id='teaching_teachers_new_technology'] {
      background-image: url('~assets/img/explore_usecases/education/teaching_teachers_new_technology.png');
    }
    &[data-id='new_technology_demand'] {
      background-image: url('~assets/img/explore_usecases/education/new_technology_demand.png');
    }
  }

  @include breakpoint-sm {
    .sectionTestimonialVideo {
      max-width: 100%;
      margin-top: 139px;
      padding: 0 24px;

      .sectionTestimonialVideo__title {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      .sectionTestimonialVideo__desc {
        font-size: 13px;
        margin-bottom: 85px;
      }
    }

    .sectionTestimonialText {
      margin-top: 119px;

      .sectionTestimonialText__content {
        max-width: 100%;
        padding: 0 24px;
      }

      .sectionTestimonialText__title {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      .sectionTestimonialText__desc {
        font-size: 13px;
        margin-bottom: 42px;
      }

      .sectionTestimonialText__list {
        padding-top: 43px;
        padding-bottom: 36px;
        height: auto;
      }

      .sectionTestimonialText__list_content {
        max-width: 100%;
        padding: 0 24px;
        flex-direction: column;
      }

      .sectionTestimonialText__logos {
        grid-template-columns: 162px 162px;
        grid-template-rows: 94px 94px 94px;
      }

      .sectionTestimonialText__item {
        max-width: 100%;
        margin-right: 0;
        margin-top: 48px;

        .card {
          .card__header {
            min-height: auto;
          }

          .card__image {
            width: 56px;
            height: 56px;
          }

          .card__info {
            margin-left: 16px;
            justify-content: space-between;
          }

          .card__position {
            font-size: 13px;
          }

          .card__name {
            font-size: 13px;
            line-height: 13px;
          }

          .card__content {
            font-size: 13px;
            margin-top: 16px;
          }
        }
      }
    }

    .videoContainer {
      .playIcon svg {
        width: 40px;
        height: 40px;
      }

      .videoPlaceholder .duration {
        right: 9px;
        bottom: 9px;
        font-size: 11.684px;
        padding: 0 2px;
      }
    }
  }
}

.videoPopup {
  position: fixed;
  z-index: 40;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 86px 0;
  background-color: #000;
  z-index: #{$embedButtonZIndex + 1};

  .videoContent {
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    right: 64px;
    top: 24px;
    cursor: pointer;
  }

  @include breakpoint-sm {
    padding: 0;

    .closeBtn {
      right: 24px;
    }

    .videoContent {
      display: flex;

      iframe {
        max-height: 211px;
        margin: auto 0;
      }
    }
  }
}
