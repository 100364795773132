.extensionSettings {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  padding-bottom: 24px;
  width: 100%;
  max-width: 960px;
  color: black;

  .extensionSettings_title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .extensionSettings_tabs {
    display: flex;

    .extensionSettings_tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 1;
      height: 56px;
      font-size: 15px;
      line-height: 20px;
      border: 1px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;

      label {
        margin: 0;
        font-weight: 400;
      }

      &.active {
        border-color: rgba(0, 0, 0, 0.2);
        border-bottom-color: transparent;
        cursor: default;
      }


      &:not(.active):hover {
        background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
      }
    }
  }

  .extensionSettings_content {
    display: flex;
    flex-direction: column;

    .extensionSettings_content_desc {
      font-size: 15px;
      line-height: 20px;
      padding: 16px;
      background: #f4f4f4;
      margin: 24px 0;
    }
  }

  &.extensionEmbed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    padding: 0;
    display: flex;
    flex-direction: column;

    .extensionSettings_title {
      display: none;
    }

    .extensionSettings_tabs {
      padding: 0;
      height: 56px;
      background: #F4F4F4;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      flex-direction: row;
      gap: 24px;
      align-items: center;
      position: relative;

      .backBtn {
        width: 56px;
        height: 24px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .extensionSettings_tab {
        border: 0;
      }
    }

    .extensionSettings_content {
      padding: 0;
    }
  }
}