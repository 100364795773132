@import '~theme/mixins';
@import '~theme/modules/variables';

$disabledOpacity: 0.4;

.mediaEditor {
  height: 100%;

  .lockableArea {
    height: 100%;
  }

  &.withFocusedStep :global(.popup-header-wrapper) {
    opacity: $disabledOpacity;
    pointer-events: none;
  }
}

.generateAll {
  padding: 30px 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.generateAllPopup {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 935px;
  height: 555px;
  background: #f4f4f4;
}

.audioPlayer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.error {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  opacity: 0.95;
}

.audioControls {
  flex-shrink: 0;
}

.noBorder {
  border: 0 none;
}

.mediaEditor_generating {
  position: fixed;
  left: 0;
  top: 48px;
  z-index: 6;
  width: 100%;
  height: calc(100% - 48px);
  background: rgba(255, 255, 255, 0.8);
}

.lockOverlay {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 6;
  background: rgba(255, 255, 255, 0.8);
}

.processing_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  @keyframes dots {
    to {
      width: 1em;
    }
  }

  span {
    color: black;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    &.loading_dot {
      position: relative;

      &:after {
        position: absolute;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        content: '\2026';
        width: 0px;
        animation: dots 1s steps(4, end) infinite;
      }
    }
  }
}

.checkbox_group {
  display: flex;
  align-items: center;
  column-gap: 50px;
  position: sticky;
  top: $topbarMediaPopupHeight;
  z-index: 2;
  background: #fff;
  padding-bottom: 40px;
}

.checkbox_btn {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  font-weight: 400;
  padding-left: 25px;
  margin: 0;

  &.disabled {
    opacity: $disabledOpacity;
    pointer-events: none;
  }
  &.disabledButClickable {
    opacity: $disabledOpacity;
  }

  input[type='checkbox']:checked ~ div::after {
    content: '';
    background-color: transparent;
    background-image: url('../../assets/img/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    border: none;
  }

  input[type='checkbox'] {
    position: absolute;
    cursor: pointer;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    bottom: 3px;
    left: 0;
    margin: 0;
    opacity: 0;
    top: 2px;
    z-index: 1;
  }

  div:after {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 2px;
    width: 16px;
    height: 16px;
    content: '';
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.downloadSrt,
.reWriteSteps {
  cursor: pointer;
  margin: 0;

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &:hover {
    color: var(--site-theme-color);
  }
}

.steps_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  .steps_block {
    flex-grow: 1;
    width: 100%;
    flex-shrink: 0;
    max-width: 875px;
  }

  .step_actions {
    display: flex;
    padding-bottom: 12px;
    position: sticky;
    top: 145px;
    z-index: 2;
    background: white;
    width: 100%;
  }

  .steps_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 875px;
    margin: 0 auto;

    :global(.root-node.react-selectize.default.root-node).publishedMediaSelect {
      width: auto;
      min-width: 150px;
      margin-right: 20px;
    }

    .delete_all_btn {
      outline: none;
      border: none;
      background: transparent;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      padding: 0;
      display: block;
      transition: color 0.3s ease;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }
}

.steps_list {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  position: relative;
}

.infoText {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  & > iframe:first-child {
    border: 1px solid #f4f4f4;
    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
  }

  .infoTextInner {
    width: 100%;
    height: 100%;
    padding: 35px 25px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: inherit;
  }

  .sectionTitle {
    color: inherit;
    flex-shrink: 0;
    height: 45px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }

  .sectionContent {
    margin-top: 3px;
    flex-grow: 1;
    overflow-y: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: inherit;
    @include nice-scrollbar;
  }

  .sectionContent,
  .sectionTitle {
    u {
      text-decoration: none;
      padding-bottom: 1px;
      border: none;
      background: var(--underline-bg);
      color: var(--underline-color);
    }

    b,
    strong {
      color: inherit;
      font-weight: bold;
    }

    a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid;
      border-bottom-color: currentColor;
      padding-bottom: 1px;
      transition: 0.3s ease;
      display: inline;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }

    ul {
      list-style-type: disc;
      list-style-position: outside;
      padding-left: 24px;
    }

    ol {
      list-style-type: decimal;
      counter-reset: step-counter;

      li {
        display: flex;
        list-style: none;
        counter-increment: step-counter;

        &::before {
          content: counter(step-counter);
          text-align: left;
          display: inline-block;
          width: 29px;
          flex-shrink: 0;
        }
      }
    }

    :global(.wysiwyg-text-align-left) {
      text-align: left;
    }

    :global(.wysiwyg-text-align-right) {
      text-align: right;
    }

    :global(.wysiwyg-text-align-center) {
      text-align: center;
    }
  }
}

:global(.media-editor-tooltip) {
  :global(.tooltip-arrow) {
    display: none;
  }

  :global(.tooltip-inner) {
    border-radius: 0;
    padding: 5px 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: black;
    background: white;
    text-align: left;
  }
}
