@use "sass:math";

@mixin placeholder ($color) {
  &::placeholder {
    color: $color;
  }
}

@function svg-url($svg){
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(math.div(str-length($svg), $slice));
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode (may need a few extra replacements)
    //
    $chunk: str-replace($chunk,'"','\'');
    $chunk: str-replace($chunk,'<','%3C');
    $chunk: str-replace($chunk,'/','%2F');
    $chunk: str-replace($chunk,' ','%20');
    $chunk: str-replace($chunk,'\'','%22');
    $chunk: str-replace($chunk,':','%3A');
    $chunk: str-replace($chunk,';','%3B');
    $chunk: str-replace($chunk,'{','%7B');
    $chunk: str-replace($chunk,'}','%7D');
    $chunk: str-replace($chunk,',','%2C');
    $chunk: str-replace($chunk,'>','%3E');
    $chunk: str-replace($chunk,'&','%26');
    // $chunk: str-replace($chunk,'|', '%7C');
    // $chunk: str-replace($chunk,'[', '%5B');
    // $chunk: str-replace($chunk,']', '%5D');
    // $chunk: str-replace($chunk,'^', '%5E');
    // $chunk: str-replace($chunk,'`', '%60');
    // $chunk: str-replace($chunk,';', '%3B');
    // $chunk: str-replace($chunk,'?', '%3F');
    // $chunk: str-replace($chunk,'%', '%25');
    $chunk: str-replace($chunk,'=','%3D');
    $chunk: str-replace($chunk,'#','%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index +
      str-length($search)), $search, $replace);
  }
  @return $string;
}

/* custom mixin to replace a defined fill color with another one */
@function svg-url-with-replaced-fill($svg, $fill-to-replace, $new-fill) {
  $replaced-svg: str-replace($svg, $fill-to-replace, $new-fill);
  $replaced-svg-url: svg-url('#{$replaced-svg}');
  @return $replaced-svg-url;
}

@mixin breakpoint-xs {
  @media only screen and (max-width: 450px) {
    @content;
  }
}

@mixin breakpoint-sm {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin breakpoint-md() {
  @media only screen and (min-width: 768px) and (max-width: 1079px) {
    @content;
  }
}

@mixin breakpoint-lg() {
  @media only screen and (min-width: 1080px) {
    @content;
  }
}

@mixin breakpoint-lg-plus() {
  @media only screen and (min-width: 1441px) {
    @content;
  }
}

@mixin breakpoint-md-plus {
  @media only screen and (min-width: 768px) {
    @content;
  }
}


@mixin breakpoint-huge() {
  @media only screen and (min-width: 1920px) {
    @content;
  }
}


@mixin roboto_16 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
}

@mixin roboto_18 {
  font-family: Roboto;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}

@mixin roboto_32 {
  font-family: Roboto;
  font-size: 32px;
  line-height: 40px;
}

@keyframes pulse {
  0% {
   opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

@mixin nice-scrollbar {
  overflow: overlay;
  @supports not (overflow: overlay) {
    overflow: auto;
    scrollbar-color: rgba(0, 0, 0, 0.2) #fff;
    scrollbar-width: thin;
  }
}

@keyframes blink-animation-keyframe {
  0% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}
$halfBlinkDuration: 300ms;
@mixin blinkAnimation {
  animation: blink-animation-keyframe $halfBlinkDuration linear infinite alternate;
}

@mixin ioradFont {
  font-family: 'iorad';
  font-size: 204px;
  line-height: 67%;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: -0.006em;
  color: #000000;
}

@mixin inheritHeight {
  display: flex;
  flex-direction: column;
  & > * {
    flex: 1;
    min-height: 1px;
  }
}

@mixin fontHelpCenter {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: black;
}

@mixin fontExtension {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
