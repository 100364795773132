@import '~theme/modules/variables';
@import '~theme/mixins';

.breadcrumb {
  display: flex;
  max-width: $maxWidthContent;
  width: 100%;
  margin: 60px auto 33px;
  padding: 0 64px;

  .breadcrumbButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 15px;
      line-height: 20px;
      text-decoration: underline;
      margin-left: 15px;
      color: black;
    }

    &:hover {
      svg path {
        fill: var(--site-theme-color);
      }
      span {
        color: var(--site-theme-color);
      }
    }
  }
}

.serviceDetail {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  &__header {
    height: 286px;
    background: #f4f4f4;
    display: flex;
    flex-shrink: 0;

    &_content {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 54px 156px 0 64px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &_left {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      &_right {
        display: flex;
        flex-direction: column;
      }
    }

    &_info {
      display: flex;
      align-items: center;

      &_icon {
        width: 64px;
        height: 64px;
        flex-shrink: 0;
        margin-right: 33px;

        img {
          width: 100%;
        }
      }

      h6 {
        margin: 0;
        font-size: 21px;
        font-weight: 600;
        line-height: 24px;
        color: black;
      }
    }

    &_row {
      display: flex;
      justify-content: space-between;
      padding-left: 97px;
      padding-right: 168px;
    }

    &_moreInfo {
      display: flex;
      flex-direction: column;

      label {
        margin: 0;
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: black;

        span {
          font-weight: 600;
        }

        a {
          text-decoration: underline;
          color: inherit;

          &:hover {
            color: var(--site-theme-color);
          }
        }
      }
    }

    &_reviews {
      span {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: black;
      }
    }

    &_rating {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        margin-right: 15px;
      }

      a {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: black;
        cursor: pointer;

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }

    &_button {
      margin-top: 10px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 600;
      width: 276px;
      height: 48px;
      background-color: black;
      color: white;
      transition: background-color 0.3s ease;

      &:hover,
      &:focus {
        color: white !important;
        background-color: var(--site-theme-color);
      }
    }
  }

  &__content {
    max-width: $maxWidthContent;
    min-height: 285px;
    width: 100%;
    margin: 0 auto;
    margin-top: 37px;
    padding: 0 64px;
  }
}

.footerWrapper {
  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 145px 158px 160px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .leaveReviewBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .breadcrumb {
    max-width: 100%;
    margin: 24px 0 45px;
    padding: 0 24px;
  }

  .serviceDetail {
    padding: 0 24px;
    margin-bottom: 30px;

    &__header {
      height: 450px;

      &_content {
        flex-direction: column;
        max-width: 100%;
        padding: 30px 28px 102px;
      }

      &_info {
        align-items: flex-end;
        &_icon {
          width: 32px;
          height: 32px;
          margin-right: 16px;

          img {
            width: 100%;
          }
        }

        h6 {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: -4px;
        }
      }

      &_row {
        flex-direction: column;
        flex-grow: 1;
        padding: 0;
        margin-top: 42px;
      }

      &_moreInfo {
        display: flex;
        flex-direction: column;

        label {
          margin: 0;
          margin-bottom: 5px;
          font-size: 15px;
          font-weight: 400;
          line-height: 24px;
          color: black;

          span {
            font-weight: 600;
          }

          a {
            text-decoration: underline;
            color: inherit;

            &:hover {
              color: var(--site-theme-color);
            }
          }
        }
      }

      &_reviews {
        margin-top: auto;
        margin-bottom: 39px;
      }

      &_rating {
        display: flex;
        align-items: center;

        > div {
          display: flex;
          margin-right: 15px;
        }

        a {
          cursor: pointer;
        }
      }

      &_button {
        margin: 0 auto;
        width: 230px;
      }
    }

    &__content {
      max-width: 100%;
      min-height: auto;
      margin-top: 24px;
      padding: 0;
    }
  }

  .footerWrapper {
    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .leaveReviewBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
