@import '~theme/modules/variables';
@import '~theme/guidelines';
@import '~theme/form';
@import '~theme/mixins';

.add_email {
  position: relative;
  width: 300px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  > div:first-child {
    flex-grow: 1;
  }

  .suggestion {
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
  }

  .input {
    border: none;
  }

  .trailing {
    height: 100%;
    color: #ccc;
    margin-left: 5px;
    margin-top: 2px;

    @include breakpoint-sm {
      display: none;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 40px;
    margin: 0 auto $gl_gap * 2 auto;
  }

  &.v2 {
    border: 0 none;
    width: 100%;
    line-height: 22px;
    .input {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      padding: 16px;
      padding-right: 80px;
      height: auto;

      &:focus, &:hover {
        border-bottom-color: #000;
      }
    }
    .trailing {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      color: rgba($color: #333, $alpha: 0.4);
      font-weight: 600;
    }
  }
}

.new_email {
  color: #000;

  input {
    padding: 0;
    border: transparent;
    min-width: 400px !important;

    @media (max-width: 768px) {
      min-width: 250px !important;
    }

    &:focus {
      outline: none;
    }
  }
}

.tooltip_error {
  top: 100% !important;
  left: auto !important;
}
