@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/Account/partials';

.saml_setting {
  display: flex;
  column-gap: 10px;
  min-height: unset !important;

  .saml_setting__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
  }

  .saml_setting__header {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
    padding-top: 40px;
  }

  .saml_setting__title {
    font-weight: 600;
    line-height: 24px;
    font-size: 20px;
    color: black;
    margin: 0;
    margin-bottom: 40px;
  }

  .saml_setting__tabs {
    display: flex;
    column-gap: 30px;
    padding: 16px 0;

    .saml_setting__tab {
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: black;
      cursor: pointer;

      &:hover,
      &.active {
        color: var(--site-theme-color);
      }
    }
  }

  .saml_setting__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }

  .saml_setting__footer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 176px 16px 16px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
    align-self: flex-start;

    button {
      width: 320px;
      font-size: 18px;
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin-top: 32px;

    @include breakpoint-sm {
      width: 100%;
      margin-right: 0;
      margin-top: 24px;
    }
  }

  .label {
    font-family: $fontBaseV2;
    font-weight: 400;
    line-height: 18px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.4);
    margin: 0;

    i {
      color: var(--site-theme-color);
      font-style: normal;
    }
  }

  :global(.text-field) {
    @extend .text_field;
  }

  .input {
    width: 100%;
    background-color: #fff;
    color: black;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    border-left: none;
    border-radius: 0;
    padding: 0;
    font-family: $fontBaseV2;
    height: 40px;
    caret-color: var(--site-theme-color);

    &:focus {
      border-bottom: 1px solid #000;
    }
  }

  .textarea {
    width: 100%;
    background-color: #fff;
    color: black;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    border-left: none;
    border-radius: 0;
    padding: 0 0 10px 0;
    font-family: $fontBaseV2;
    height: 100px;
    caret-color: var(--site-theme-color);
    resize: vertical;

    &:focus {
      border-bottom: 1px solid #000;
    }
  }

  .formGroup {
    display: flex;

    .select {
      width: 100%;

      :global(.text) {
        color: black;
        font-size: 15px;
      }
    }

    @include breakpoint-sm {
      margin-bottom: 17px;
      flex-direction: column;
    }
  }

  .inputWrapper {
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    max-width: 430px;
    margin-right: 40px;

    @include breakpoint-sm {
      max-width: 100%;
      margin-right: 0;
    }

    .samlCertOptionWrapper {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;

      .samlCertOption {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 15px;
        font-weight: 400;
        color: #000000;
        margin: 0;
        position: relative;
        padding-left: 26px;

        &:has(input[type='radio']:checked):after {
          position: absolute;
          cursor: pointer;
          left: 4px;
          top: 50%;
          transform: translateY(-50%);
          width: 8px;
          height: 8px;
          content: '';
          background-color: black;
          border-radius: 100%;
        }

        input[type='radio'] {
          display: none;
        }

        &:before {
          position: absolute;
          cursor: pointer;
          left: 0;
          top: 50%;
          width: 16px;
          height: 16px;
          content: '';
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 100%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .easyCheckboxWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 34px;
    color: black;

    input[type='checkbox'] {
      margin: 0;
      margin-right: 10px;

      &::before {
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0;
      }
    }

    span {
      font-size: 15px;
    }
  }

  .helpblock {
    font-family: $fontBaseV2;
    line-height: 20px;
    font-size: 15px;
    color: #000000;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    align-self: flex-start;
    width: 100%;

    p {
      margin: 0;
    }

    @include breakpoint-sm {
      display: none;
    }
  }

  .buttonSubmit {
    width: 301px;
    height: 48px;
    font-size: 15px;
    color: #fff;
    background: #000;
    font-weight: 600;
    border-radius: 0;
    border: none;
    outline: none;
    transition: background 0.3s ease;
    margin-bottom: 9px;

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &:hover {
      background: var(--site-theme-color);
    }

    @include breakpoint-sm {
      width: 100%;
    }
  }

  .buttonDelete {
    margin-top: 9px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #000;
    transition: color 0.3s ease;
    margin-bottom: 9px;

    &:hover {
      color: var(--site-theme-color);
      background: #fff;
    }
  }

  .copyContainer {
    display: flex;
    align-items: center;
    position: relative;

    .input {
      padding-right: 40px;
    }

    &.generateConatiner {
      .input {
        padding-right: 60px;
      }
    }
  }

  .linkContainer {
    position: absolute;
    right: 0;
    bottom: 4px;
    width: 62px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .fadeOut {
      background: linear-gradient(
        to left,
        #ffffff 13.81%,
        rgba(255, 255, 255, 0) 99.61%
      );
      width: 31px;
      height: 100%;
    }

    .link {
      margin-right: 10px;
      color: #999999;
      font-size: 13px;
      line-height: 18px;
      transition: color 0.3s ease;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: #000;
      }
    }
  }

  .padding0 {
    margin-bottom: 0;
  }

  .labelSelect {
    margin-bottom: 0;
  }
}

@include breakpoint-sm {
  .saml_setting {
    flex-direction: column;
    row-gap: 24px;

    .saml_setting__header {
      padding-top: 24px;
    }

    .saml_setting__title {
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 40px;
    }

    .saml_setting__tabs {
      column-gap: 16px;
      justify-content: space-between;
    }

    .saml_setting__footer {
      padding: 0 16px 24px;
      position: relative;
      align-self: unset;

      button {
        width: 100%;
        font-size: 15px;
      }
    }

    .formContainer {
      row-gap: 24px;
    }

    .inputWrapper {
      .samlCertOptionWrapper {
        flex-direction: column;
      }
    }
  }
}
