@import '~theme/mixins';
@import '~theme/modules/variables';

@mixin mobile-style {
  .ioradical_points__group_time {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .ioradical_points__group_list {
    row-gap: 0;
  }
  .ioradical_points__group_item {
    border: 0 none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
    padding-top: 40px;
  }
  .ioradical_points__group_item_content {
    margin: 0;
  }
  .ioradical_points__group_item_avatar {
    display: none;
  }
  .ioradical_points__group_item_point {
    position: absolute;
    top: 10px;
    left: 20px;
  }
}

.ioradical_points {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 17;

  &__header {
    display: flex;
    flex-direction: row;
    background: #f4f4f4;
    flex-shrink: 0;
    height: 48px;
    cursor: pointer;
    position: relative;

    &:hover {
      .ioradical_points__header_title::after {
        content: 'Back';
        background: #f4f4f4;
        @include fontExtension;
        color: black;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &_back {
      width: 48px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      column-gap: 16px;
      text-align: center;
      @include fontExtension;
      color: black;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }

  &__no_results {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0;
    @include fontHelpCenter;
  }

  &__group {
    display: flex;
    flex-direction: column;

    &_time {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 0;
      @include fontHelpCenter;
      color: rgba(0, 0, 0, 0.4);
    }

    &_list {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;
    }

    &_item {
      display: flex;
      width: 100%;
      max-width: $maxWidthSocialContent;
      padding: 20px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      cursor: pointer;

      &_content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0 24px;
      }

      &_avatar {
        width: 24px;
        flex-shrink: 0;
      }

      &_title {
        display: inline;
        @include fontHelpCenter;
        word-break: break-word;
      }

      &_product {
        margin-top: 10px;
        @include fontHelpCenter;
        color: rgba(0, 0, 0, 0.4);
      }

      &_point {
        display: flex;
        align-items: center;
        align-self: flex-start;
        column-gap: 16px;

        span {
          @include fontHelpCenter;
        }
      }

      a,
      a:hover,
      a:focus {
        color: #000;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    @media (max-width: 767px) {
      @include mobile-style;
    }
  }

  &__content {
    &.isSmallScreen {
      @include mobile-style;
    }
  }
}
