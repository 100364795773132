@import '~theme/mixins';
@import '~theme/modules/variables';

.backgroundMusicEditor {
  display: flex;
  flex-direction: column;

  .checkbox_group {
    display: flex;
    position: sticky;
    top: $topbarMediaPopupHeight;
    z-index: 2;
    background: #fff;
    padding-bottom: 40px;

    .checkbox_btn {
      white-space: nowrap;
      position: relative;
      cursor: pointer;
      user-select: none;
      color: #000000;
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #000000;
      font-weight: 400;
      padding-left: 25px;
      padding-top: 2px;
      margin: 0;

      input[type='checkbox']:checked ~ span::after {
        content: '';
        background-image: url('../../assets/img/icons/checkmark.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 76%;
        border: none;
      }

      input[type='checkbox'] {
        position: absolute;
        cursor: pointer;
        vertical-align: middle;
        width: 16px;
        height: 16px;
        bottom: 3px;
        left: 0;
        margin: 0;
        opacity: 0;
        top: 3px;
        z-index: 1;
      }

      span:after {
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 3px;
        width: 16px;
        height: 16px;
        content: '';
        background-color: white;
        background-repeat: no-repeat;
        background-position: center center;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 1px;
      }
    }
  }
  .music_editor__container {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .music_editor__content {
    display: flex;
    flex-direction: column;
    min-width: 1035px;
  }
  .upload_container {
    display: flex;
    flex-direction: column;
  }
  .upload_label {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #000000;

    label {
      margin: 0;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }
  .music_editor__list_uploads {
    margin-top: 44px;
  }
  .music_editor__list_defaults {
    margin-top: 30px;
  }
  .music_editor__list_title {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
  }
  .music_editor__list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;
  }
  .music_editor__item {
    display: flex;
    height: 64px;

    .invisible {
      display: none;
    }

    &:hover {
      .invisible {
        display: flex;
      }
    }
  }
  .music_editor__item_content {
    display: flex;
    background: #f4f4f4;
    width: 841px;
  }
  .music_editor__item_controls {
    border-right: 1px solid #fff;
  }
  .music_editor__item_control_btn {
    width: 64px;
    min-width: 0;
    height: 100%;
    border: none;

    &:hover {
      svg path {
        &[stroke='black'] {
          stroke: var(--site-theme-color);
        }
        &[fill='black'] {
          fill: var(--site-theme-color);
        }
      }
    }
  }
  .music_editor__item_title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 24px;
    color: black;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }
  .music_editor__item_preview {
    display: flex;
    width: auto;
    flex-shrink: 0;
    margin-left: 24px;

    &.disabled {
      pointer-events: none;
      :global(.rhap_progress-indicator) {
        background: rgba(0, 0, 0, 0.1);
      }
      :global(.rhap_progress-filled) {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .music_editor__item_duration {
      display: flex;
      align-items: center;
      width: 43px;
      color: black;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      margin-right: 24px;
    }
    :global(.rhap_container) {
      padding: 0;
      background: transparent;
      box-shadow: none;
      width: 255px;
    }
    :global(.rhap_controls-section):nth-child(2) {
      display: none;
    }
    :global(.rhap_progress-container) {
      height: 100%;
      margin: 0;
    }
    :global(.rhap_progress-bar) {
      height: 10px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 0;
    }
    :global(.rhap_progress-indicator) {
      width: 2px;
      margin-left: -1px;
      height: 64px;
      top: -27px;
      background: black;
      border-radius: 0;
      box-shadow: none;
    }
    :global(.rhap_progress-filled) {
      background: black;
      border-radius: 0;
    }
  }
  .music_editor__item_volume_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 64px;
    opacity: 0.4;
    margin-left: 10px;

    &:hover,
    &.active {
      opacity: 1;
    }
  }
  .music_editor__item_actions {
    display: flex;
    align-items: center;
    column-gap: 10px;
    height: 100%;
    margin-left: 10px;
  }
  .music_editor__item_action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: auto;
    min-width: 64px;
    background: #f4f4f4;

    &.active {
      background: white;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(var(--site-theme-color-rgb), 0.05);
      }

      svg path {
        &[stroke] {
          stroke: var(--site-theme-color);
        }
        &[fill] {
          fill: var(--site-theme-color);
        }
      }
    }

    &:hover {
      svg path {
        &[stroke] {
          stroke: var(--site-theme-color);
        }
        &[fill] {
          fill: var(--site-theme-color);
        }
      }

      span {
        color: var(--site-theme-color);
      }
    }

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      padding: 0 32px;
      color: black;
    }
  }
  .music_editor__volume_input {
    display: flex;
    align-items: center;
    width: 138px;
    height: 15px;
    cursor: pointer;

    &:hover {
      .music_editor__volume_thumb {
        background: var(--site-theme-color);
      }
    }

    .music_editor__volume_bar {
      position: relative;
      width: 100%;
      height: 5px;
      background: rgba(0, 0, 0, 0.2);
    }
    .music_editor__volume_thumb {
      position: absolute;
      width: 13px;
      height: 13px;
      margin-left: -6px;
      left: 0;
      top: -4px;
      z-index: 3;
      background: #000;
      cursor: pointer;
    }
    .music_editor__volume_filled {
      height: 100%;
      position: absolute;
      z-index: 2;
      background-color: black;
    }
  }
  .music_editor__item_volume_vertical {
    display: flex;
    align-items: center;
    width: 184px;
    height: 212px;
    background: white;
    position: relative;
    z-index: 1;

    .music_editor__volume_input {
      display: flex;
      align-items: center;
      width: 40px;
      height: 165px;
      cursor: pointer;

      .music_editor__volume_bar {
        position: relative;
        width: 100%;
        height: 100%;
        background: #f4f4f4;
      }
      .music_editor__volume_filled {
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 2;
        background-color: black;
      }
    }
  }
  .music_editor__info {
    position: sticky;
    bottom: 0;
    z-index: 1;
    height: 40px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;

    a:hover {
      color: var(--site-theme-color);
    }
  }
  .upload_popup {
    position: fixed;
    top: 48px;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    display: flex;
    flex-direction: column;

    .upload_popup__header {
      display: flex;
      align-items: center;
      height: 56px;
      background: #f4f4f4;
      cursor: pointer;
      border-top: 1px solid #fff;

      &:hover {
        .upload_popup__header_close_icon {
          opacity: 1;
        }
      }

      .upload_popup__header_upload_icon {
        width: 56px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        flex-grow: 1;
      }
      .upload_popup__header_close_icon {
        width: 56px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.4;
      }
    }
    .upload_popup__content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      padding-bottom: 96px;
    }
    .upload_popup__form {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      width: 100%;
      max-width: 480px;
    }
    .upload_popup__file_input {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 64px;
      background: #f4f4f4;
      padding: 0 24px;

      input,
      audio {
        display: none;
      }
    }
    .upload_popup__choose_file {
      flex-grow: 1;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: #000000;
    }
    .upload_content_file_name {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: #000000;
    }

    input {
      height: 56px;
      padding: 0 24px;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      background: transparent;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: #000000;

      &:focus {
        border-bottom-color: black;
      }
    }
    .upload_popup__upload_btn {
      width: 227px;
      font-size: 18px;
      font-weight: 600;
      margin: 10px auto 0;
    }
  }
}
