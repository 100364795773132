@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/AccountV2/partials';

.captureSettings {
  display: flex;
  padding-bottom: 40px;

  .contentWrapper {
    flex-grow: 1;
  }

  .content {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 900px;
  }

  .loading {
    padding: 40px 0;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding: 40px 0;

    .formGroup {
      display: flex;
      gap: 40px;

      .formInput {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 460px;

        label {
          display: inline-block;
          max-width: 100%;
          font-size: 13px;
          line-height: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 0;
        }

        .select {

        }
      }

      .shortcuts {
        display: flex;
        flex-direction: column;

        .shortcutItem {
          display: flex;
          align-items: center;
          user-select: none;
          height: 56px;
          gap: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          label {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 136%;
            color: black;
            width: 150px;
            padding: 0;
          }

          .keyInput {
            cursor: pointer;

            input {
              opacity: 0;
              width: 0;
              border: 0;
              padding: 0;
              margin: 0;
            }

            .keyPair {
              display: inline-block;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 136%;
              color: black;
              font-weight: 600;
              animation: color 0.3s ease;

              &:hover {
                color: var(--site-theme-color);
              }

              span {
                text-align: right;
                padding: 0;

                &.left,
                &.right {
                  display: inline-block;
                  height: 100%;
                  text-align: right;
                  box-sizing: border-box;
                  text-transform: lowercase;
                }

                &.left {
                  margin-right: 0;
                }

                &.right {
                  margin-left: 0;
                }

                &.pressKey {
                  color: var(--site-theme-color);
                }
              }
            }
          }

          &.edit {
            .keyPair {
              color: #000000;

              &:hover {
                color: var(--site-theme-color);
              }
            }
          }

          &.saved {
            .keyPair {
              animation: savedColorAnimation 2s;

              @keyframes savedColorAnimation {
                from {
                  color: #25c16f;
                }

                to {
                  color: #25c16f;
                }
              }
            }
          }

          &.error {
            label,
            .keyInput .keyPair span.pressKey {
              color: red;
            }
          }
        }
      }
    }
  }
}