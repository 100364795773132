@import '../../variables';

.theme {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .theme__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    position: relative;
    // background-color: rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.2);

    h4 {
      font-size: 21px;
      line-height: 24px;
      margin: 0;
      margin-bottom: 24px;
    }

    p {
      @include defaultFont;
      margin: 0;
    }

    .theme__header_close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: $defaultHeightBtn;
      width: $defaultHeightBtn;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .theme__inner {
    display: flex;
    flex-direction: column;
    background: $defaultColor;
    padding: 24px 13px 24px 24px;
    height: 100%;
    overflow-y: scroll;
  }

  .theme__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    background: white;
    padding: 24px;
  }

  .theme__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }
}

.theme_options {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .theme_options_header {
    height: $defaultHeightBtn;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    @include defaultFont;
    margin: 0;
    opacity: 0.4;
  }

  .theme_options_option {
    margin-bottom: 10px;
    padding-inline: 0;

    .theme_options_option_preset {
      display: flex;
      align-items: center;
      column-gap: 20px;

      > div {
        display: flex;
        align-items: center;
      }

      svg {
        flex-shrink: 0;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  .color_picker_input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .color_picker_input__input {
      padding: 10px 0;
      height: $defaultHeightBtn;

      span {
        width: 24px;
        height: 24px;
      }

      label {
        text-transform: unset;
        @include defaultFont;
      }

      svg {
        [fill] {
          fill: currentColor;
        }

        [stroke] {
          stroke: currentColor;
        }
      }
    }

    .color_picker_input__popover {
      margin-top: 0;
      width: 100%;
      height: 288px;
      background: white;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      left: unset;
    }

    .color_picker_input__buttons {
      padding: 16px;
      top: unset;
      bottom: 0;

      :global(.btn-fancy-filled):hover {
        background: var(--site-theme-color) !important;
      }

      :global(.btn-fancy-cancel):hover {
        background: white !important;
        color: var(--site-theme-color) !important;
      }
    }
  }
}

.theme__features {
  display: flex;
  flex-direction: column;

  .theme__features_header {
    height: $defaultHeightBtn;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    @include defaultFont;
    margin: 0;
    opacity: 0.4;
  }

  .theme__features_item {
    height: $defaultHeightBtn;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    label {
      @include defaultFont;
      margin: 0;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .theme {
    .theme__inner {
      padding: 24px 13px 24px 24px;
      overflow-y: scroll;
      position: unset;
    }
  }

  .theme_options {
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 40px;
    width: 100%;

    .color_picker_input {
      flex: 1;

      .color_picker_input__input {
        padding: 10px 16px;

        span {
          width: 24px;
          height: 24px;
        }

        svg {
          [fill] {
            fill: currentColor;
          }

          [stroke] {
            stroke: currentColor;
          }
        }
      }

      .color_picker_input__popover {
        min-width: 100%;
      }
    }
  }
}
