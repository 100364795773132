@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/guidelines.scss';
@import '~theme/modules/variables';

.account {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: $topNavBarHeightLogged;
  left: 0;
  z-index: $topNavBarZIndex - 1;
  width: 100vw;
  height: calc(100vh - #{$topNavBarHeightLogged});
  background: white;
  transition: none !important;
}

@media screen and (min-width: 768px) {
  :global(.accountmyplan), :global(.accountquota),
  :global(.accountpersonal), :global(.accountbilling),
  :global(.account) {
    overflow-y: scroll;
    height: 100vh !important;
  }
}

.tab_container {
  font-family: $fontBaseV2;
  @include inheritHeight;
  height: 100%;
  flex-grow: 1;

  .center_tab_headers {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;
    height: 47px;
    margin: 0;
    column-gap: 32px;

    &::before,
    &::after {
      content: unset;
    }

    :global(li) {
      &.tab_nav {
        display: flex;
        align-items: center;
        height: 100%;
        color: #000000;

        a {
          display: flex;
          align-items: center;
          height: 100%;
          color: inherit;
          cursor: pointer;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          padding: 0;

          @media screen and (max-width: 767px) {
            font-size: 13px;
          }

          &:focus {
            outline: none;
            background: none;
          }
          &:hover {
            background-color: transparent; // override
          }
        }
      }
      &:hover {
        color: var(--site-theme-color);
      }

      &:global(.active) {
        color: var(--site-theme-color);

        a {
          cursor: default;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .tab_nav_more {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;

      &:hover {
        color: black;
      }
      
      span {
        color: black;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        cursor: pointer;
        transition: color .3s ease;

        &:hover,
        &.active {
          color: var(--site-theme-color);
        }
      }

      .moreTabs {
        position: absolute;
        top: 100%;
        left: -12px;
        z-index: 2;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        width: 211px;
        display: none;

        &.isShow {
          display: block;
        }

        .tab_nav_more_list {
          margin: 0;
          padding: 21px 24px;

          .tab_nav_more_item {
            padding-top: 7px;
            padding-bottom: 8px;
            text-align: right;
            color: black;
    
            a {
              color: inherit;
              cursor: pointer;
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
              padding: 0;
    
              @media screen and (max-width: 767px) {
                font-size: 13px;
              }
    
              &:focus {
                outline: none;
                background: none;
              }
              &:hover {
                background-color: transparent; // override
              }
            }
            &:hover {
              color: var(--site-theme-color);
            }
      
            &:global(.active) {
              color: var(--site-theme-color);
      
              a {
                cursor: default;
      
                &:focus {
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .tabsHeadSection {
    padding-left: 40px;
    display: flex;
    flex: 0;
    min-height: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @include breakpoint-sm {
      padding-left: 0;
      background: #f4f4f4;
      height: 48px;
      position: relative;
      flex-shrink: 0;
      flex-basis: auto;
      border-bottom: none;

      h5 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 16px;
        font-weight: 600;
        color: black;
        margin: 0;
      }
    }
  }

  .close_popup_btn {
    border: none;
    outline: none;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 40px;
    height: 40px;
    align-self: center;
    margin-right: 4px;

    svg path {
      stroke: white;
    }

    &:hover {
      background: #3b3b3b;
    }

    @include breakpoint-sm {
      position: absolute;
      right: 0;
      top: 8px;
      z-index: 2;
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }

  .tab_contents {
    display: block;
    width: 100%;
    padding-left: 40px;
    @include inheritHeight;
    @include nice-scrollbar;

    &.noscroll {
      padding: 0;
      overflow: hidden;
    }

    :global(.tab-pane.active) {
      @include inheritHeight;
      & > * {
        width: 100%;
      }
    }

    @include breakpoint-sm {
      padding-left: 24px;
      padding-right: 13px;
      overflow-y: scroll;
    }
  }
}

.userName {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: $semibold;
  line-height: 40px;
  font-size: 32px;
  color: #000000;
  margin-top: 40px;
  margin-bottom: 32px;
  text-align: center;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 15px;
  }

  @media screen and (min-width: 769px) {
    width: 428px;
  }
}

.waitable {
  position: relative;
  margin: 0 auto;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: $regularFontWeight;
  border-radius: 3px;
  margin-bottom: 4 * $gl_gap;
}

@media screen and (max-width: 768px) {
  :global(body.easy-popup-open) {
    .account{
      margin-bottom: 0;
    }
    .center_tab_headers{
      display: none;
    }
    .userName {
      display: none;
    }
  }
}
