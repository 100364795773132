.delete_success {
  width: 300px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 200px;

  .iorad_logo {
    margin-bottom: 20px;
  }

  .text, .error {
    font-family: "Libre Franklin";
    font-size: 15px;
    line-height: 136%;
  }
}
