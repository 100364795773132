.link, .link[href] {
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  max-width: fit-content;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.hidden {
    visibility: hidden;
  }

  button {
    font-family: 'Libre Franklin', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 48px;
    height: 48px;
    width: 216px;
  }
}

.capture {
  background: rgba(0, 0, 0, 1);

  &:hover {
    background: rgba(0, 0, 0, 0.84);
  }
}

.captureBtnGroup {
  display: flex;
  position: relative;
  margin-right: 18px;
  height: 100%;

  &:not(:hover) {
    .captureBtnMenu {
      display: none;
    }
  }
}

.captureBtnMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 0 none;
  padding: 4px 0;

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  button {
    background-color: transparent;
    border: 0 none;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 16px;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}