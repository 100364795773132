.ssoSetup {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  width: 100%;
  height: 100%;
  max-width: 960px;
  color: black;
  position: relative; // for download button .ssoSetup_downloadBtn

  .ssoSetup_title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .ssoSetup_methods {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    max-width: 460px;

    .ssoSetup_methods_label {
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      margin: 0;
    }

    .ssoSetup_select {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .ssoSetup_downloadBtn {
    position: absolute;
    right: 0;
    top: 124px;
    min-width: auto;
    height: 60px;
  }
}

.ssoSetupTab {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .ssoSetupTab_tabs {
    display: flex;

    .ssoSetupTab_tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 1;
      height: 56px;
      font-size: 15px;
      line-height: 20px;
      border: 1px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;

      &.active {
        border-color: rgba(0, 0, 0, 0.2);
        border-bottom-color: transparent;
        cursor: default;
      }
      

      &:not(.active):hover {
        background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
      }
    }
  }

  .ssoSetupTab_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .ssoSetupTab_form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 40px 0;

    .ssoSetupTab_formGroup {
      display: flex;
      gap: 40px;

      &:hover {
        .ssoSetupTab_formHelp {
          opacity: 1;
        }
      }

      .ssoSetupTab_formInput {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 460px;

        .ssoSetupTab_formInput_label {
          font-size: 13px;
          line-height: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 0;
          
          i {
            color: var(--site-theme-color);
            font-style: normal;
          }
        }

        .ssoSetupTab_formInput_input {
          width: 100%;
          height: 40px;
          padding: 0;
          margin: 0;
          background: transparent;
          outline: none;
          border: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          font-size: 15px;
          line-height: 20px;

          &:focus {
            border-color: black;
          }
        }
      }

      .ssoSetupTab_formCheckbox {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 1;
        height: 24px;
        margin: 0;
        max-width: 460px;

        input[type='checkbox']:checked ~ span::after {
          background-image: url('~assets/img/icons/checkmark.svg');
          background-size: 76%;
          border: none;
        }

        input[type='checkbox'] {
          display: none;
        }

        span {
          margin-left: 32px;
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
        }

        span:after {
          position: absolute;
          cursor: pointer;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          content: '';
          background-color: transparent;
          background-repeat: no-repeat;
          background-position: center;
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      .ssoSetupTab_formSelect {
        flex-grow: 1;
        max-width: 460px;
      }

      .ssoSetupTab_formRadio {
        display: flex;
        gap: 24px;
        flex-grow: 1;
        max-width: 460px;
  
        .ssoSetupTab_formRadio_item {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 15px;
          font-weight: 400;
          color: black;
          margin: 0;
          position: relative;
          padding-left: 26px;
  
          &:has(input[type='radio']:checked):after {
            position: absolute;
            cursor: pointer;
            left: 4px;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            content: '';
            background-color: black;
            border-radius: 100%;
          }
  
          input[type='radio'] {
            display: none;
          }
  
          &:before {
            position: absolute;
            cursor: pointer;
            left: 0;
            top: 50%;
            width: 16px;
            height: 16px;
            content: '';
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 100%;
            transform: translateY(-50%);
          }
        }
      }

      .ssoSetupTab_formInput_wrapper {
        position: relative;
      }

      .ssoSetupTab_formCheckbox_wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        flex-grow: 1;
        max-width: 460px;

        .ssoSetupTab_formCheckbox {
          height: 40px;
        }
      }

      .ssoSetupTab_formRadio_wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        flex-grow: 1;
        max-width: 460px;
      }

      .ssoSetupTab_formInput_actions {
        position: absolute;
        right: 0;
        bottom: 1px;
        width: 62px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        .fadeOut {
          background: linear-gradient(to left, #ffffff 13.81%, rgba(255, 255, 255, 0) 99.61%);
          width: 31px;
          height: 100%;
        }
  
        .ssoSetupTab_formInput_actions_item {
          margin-right: 10px;
          font-size: 15px;
          line-height: 20px;
          cursor: pointer;
          color: rgba(0, 0, 0, 0.2);
          background: white;
  
          &:last-child {
            margin-right: 0;
          }
  
          &:hover {
            color: black;
          }
        }
      }

      .ssoSetupTab_formHelp {
        font-size: 15px;
        line-height: 20px;
        opacity: 0.5;
        flex: 1;

        p {
          margin: 0;
        }
      }
    }
  }
  
  .ssoSetupTab_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 24px;
    padding-top: 16px;
    padding-bottom: 24px;
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 1;

    .ssoSetupTab_footer_deleteBtn {
      background: white;
    }
  }

  .ioradSettings {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 24px;

    .ioradSettings_header {
      display: flex;
      align-items: center;
      gap: 56px;
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 16px;

      p {
        margin: 0;
        flex: 1;
      }

      .ioradSettings_header_addBtn {
        min-width: auto;
      }
    }

    .ioradSettings_list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .ioradSettings_listItem {
      display: flex;
      flex-direction: column;
      background: #f4f4f4;

      .ioradSettings_listItem_header {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        gap: 16px;
        height: 56px;
        padding: 0 24px;
        cursor: pointer;

        &.open {
          .ioradSettings_listItem_header_icon svg {
            transform: rotate(180deg);
          }
        }

        .ioradSettings_listItem_header_icon {
          display: flex;
          align-items: center;
        }

        label {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          flex: 1;
          margin: 0;
        }

        .ioradSettings_listItem_header_deleteBtn {
          opacity: 0.5;
          cursor: pointer;
          outline: none;
          border: none;
          background: transparent;
          padding: 0;
          margin: 0;

          &:hover {
            opacity: 1;
          }
        }
      }

      .ssoSetupTab_form {
        padding: 24px;
      }

      .rowInputWrapper {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
      }
    }
  }
}