@import '~theme/modules/variables';
@import '../includes';
@import '../leftBarShares';

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  width: 100%;

  &:hover {
    .name {
      color: var(--site-theme-color);
      text-decoration: none !important;
    }

    .editIcon {
      opacity: 1;

      path {
        &[stroke] {
          stroke: var(--site-theme-color);
        }
        &[fill] {
          fill: var(--site-theme-color);
        }
      }
    }
  }

  &.isEditing:hover {
    .buttonIcon {
      path {
        &[stroke] {
          stroke: var(--site-theme-color);
        }
        &[fill] {
          fill: var(--site-theme-color);
        }
      }
    }
  }

  .itemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    &:not(:hover) {
      .editIcon {
        display: none;
      }
    }

    &:hover {
      .name {
        color: var(--site-theme-color);
        text-decoration: none;
      }
      .itemCount {
        display: none;
      }
      .editIcon {
        opacity: 1;

        path {
          &[stroke] {
            stroke: var(--site-theme-color);
          }
          &[fill] {
            fill: var(--site-theme-color);
          }
        }
      }
    }
  }

  .name {
    @include tagName();

    .discard {
      display: none;
      font-size: 13px;
      font-weight: bold;
      color: black;
    }

    &:hover {
      .discard {
        display: block;
      }
    }
  }

  .buttonIcon {
    margin-right: 21px;
    margin-top: 2px;
    opacity: 0.4;
    flex-shrink: 0;

    &.undoIcon {
      opacity: 1;
      margin-right: 22px;
    }

    &:hover {
      opacity: 1;

      &.undoIcon {
        path {
          &[stroke] {
            stroke: var(--site-theme-color);
          }
          &[fill] {
            fill: var(--site-theme-color);
          }
        }
      }
    }

    &:last-child {
      margin-left: 15px;
      margin-right: 0;
    }
  }

  .buttonIcon img {
    width: 16px;
    margin: 0 15px;
  }

  .itemCount {
    color: #999999;
    font-size: 10px;
    font-family: $fontBaseV2;
    text-align: right;
    margin-top: 4px;
    margin-left: auto;
    min-width: 24px;
  }

  .notifyText {
    color: var(--site-theme-color);
    font-size: 10px;
    font-family: $fontBaseV2;
    text-align: right;
    margin-top: 4px;
    @include blinkAnimation();
  }

  .editIcon {
    display: block;
    flex-shrink: 0;
    margin-left: 9px;
    opacity:0;
  }


  .blur {
    opacity: 0.2;
    pointer-events: none;
  }

  input {
    max-height: 33px;
  }

  &.addedForChange {
    .name {
      color: rgba(0, 0, 0, .2);
      &:hover {
        text-decoration: none;
      }
    }
    .itemCount {
      display: none;
    }

    &:hover {
      position: relative;

      .name {
        color: #000;
        width: 100%;
        max-width: 100%;
      }

      &::after {
        content: 'Remove';
        font-weight: 600;
        font-size: 10px;
        line-height: 150%;
        font-family: $fontBaseV2;
        color: #000;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #fff;
        padding-left: 12px;
        height: 100%;
        padding-top: 4px;
        pointer-events: none;
      }
    }
  }

  &.isTagChangeActive:not(.addedForChange):hover {
    position: relative;
    .name {
      color: var(--site-theme-color);
      width: 100%;
      max-width: 100%;
      padding-right: 24px;
    }

    .itemCount {
      display: none;
    }

    &::after {
      content: 'Add';
      font-weight: 600;
      font-size: 10px;
      line-height: 150%;
      font-family: $fontBaseV2;
      color: var(--site-theme-color);
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      padding-top: 4px;
      pointer-events: none;
    }
  }

  &.trash {
    .name {
      color: #999999 !important;
    }
  }

  &:not(.isEditing):not(.isTagChangeActive):hover {
    .name:not(.highlight) {
      text-decoration: underline;
    }
  }

  &.isRename {
    align-items: center;

    .buttonIcon {
      margin-top: -3px;
    }

    .itemCount {
      display: none;
    }
  }
}
