@import '~theme/modules/variables';
@import '~theme/mixins';

.competitorBreakdownSection {
  background: #2891be;
  padding-top: 39px;
  padding-bottom: 62px;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleWrapper {
    position: relative;
    display: flex;

    img {
      width: 427px;
      flex-shrink: 0;
      align-self: start;
      margin-top: 87px;
    }

    .title {
      display: flex;
      flex-direction: column;
      margin-left: -86px;

      span {
        font-family: 'iorad';
        font-size: 204px;
        line-height: 67%;
        color: black;
        margin: 0;
        letter-spacing: -1.224px;
        text-transform: uppercase;

        &:not(:first-child) {
          margin-top: -13px;
        }

        &:last-child {
          margin-left: 250px;
        }
      }
    }
  }

  .descWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 82px;
  }

  .descContent {
    width: 100%;
    max-width: 618px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: black;

    p {
      margin-bottom: 0;

      a {
        text-decoration: underline;
      }
    }
  }
}

@include breakpoint-sm {
  .competitorBreakdownSection {
    padding: 26px 0 126px;

    .contentWrapper {
      padding: 0;
    }

    .titleImg {
      padding-left: 18px;
      padding-right: 15px;

      img {
        width: 100%;
      }
    }

    .descWrapper {
      padding: 0 24px;
      margin-top: 61px;
      flex-direction: column;
    }

    .descContent {
      p {
        margin: 0;
      }
    }
  }
}
