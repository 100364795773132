@import '~theme/form';

.searchInputWrapper {
  position: relative;
  width: 305px;

  &.iconLeft {
    .searchField {
      padding-left: 40px;
    }
    .searchIcon {
      left: 2px;
    }
  }

  &.iconRight {
    .searchField {
      padding-right: 40px;
    }
    .searchIcon {
      right: 2px;
    }
  }

  &.hideSearchIcon {
    .searchField {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .searchIcon:hover {
    opacity: 1;
    path {
      stroke: var(--site-theme-color);
    }
  }
}

.searchField {
  width: 100%;
  padding-right: 40px;
  height: 34px;
  line-height: 136%;
  caret-color: var(--site-theme-color);

  &:focus {
    border-bottom: 1px solid #000000;
  }

  &::-webkit-input-placeholder {
    color: rgba($color: #000000, $alpha: 0.4);
  }

  &:-ms-input-placeholder {
    color: rgba($color: #000000, $alpha: 0.4);
  }

  &::placeholder {
    color: rgba($color: #000000, $alpha: 0.4);
  }
}

.searchIcon {
  width: max-content;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  &:not(.isBlur):hover {
    svg path {
      stroke: var(--site-theme-color);
    }
  }

  &.isBlur {
    opacity: 0.4;
  }
}

.cheatingCloseSearch {
  display: none;
}
