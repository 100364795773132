@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  padding-top: 25px;

  .header {
    display: flex;
    flex-direction: column;
    padding: 0 64px;
  }

  .titleHeader {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    max-width: 810px;
    margin: 0;
    margin-bottom: 62px;
  }

  .contentWrapper {
    background-image: url('~assets/img/redesign2021/trustcenterpage/accessibility.png');
    background-color: #f4f4f4;
    background-position: top center;
    background-size: 100% 376px;
    background-repeat: no-repeat;
  }

  .content {
    padding: 19px 64px 67px;
  }

  .list {
    margin: 0;
    padding: 0;
    padding-left: 16px;

    li {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
    }
  }

  .desc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-top: 227px;
    max-width: 616px;
  }

  .block {
    max-width: 616px;
    margin-top: 70px;

    &:last-child {
      margin-top: 78px;
    }
  }

  .titleBlock {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    margin: 0;
    margin-bottom: 26px;
  }

  .descBlock {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }
}

@include breakpoint-sm {
  .container {
    padding-top: 0;

    .header {
      background-color: #f4f4f4;
      padding: 45px 24px 0;
    }

    .titleHeader {
      font-size: 21px;
      max-width: 100%;
      margin-bottom: 23px;
    }

    .contentWrapper {
      background-image: url('~assets/img/redesign2021/trustcenterpage/mobile/accessibility.png');
      background-size: 100% 624px;
      background-position: top 167px center;
    }

    .content {
      width: 100%;
      padding: 0 24px 80px;
    }

    .list {
      li {
        font-size: 13px;
      }
    }

    .desc {
      font-size: 13px;
      margin-top: 186px;
      max-width: 100%;
    }

    .block {
      max-width: 100%;
      margin-top: 240px;

      &:last-child {
        margin-top: 68px;
      }
    }

    .titleBlock {
      font-size: 21px;
      margin-bottom: 21px;
    }

    .descBlock {
      font-size: 13px;
    }
  }
}
