.spinnerAnimation {
  display: inline-block;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(0,144,226, 0.2);
  border-right: 3px solid rgba(0,144,226, 0.2);
  border-bottom: 3px solid rgba(0,144,226, 0.2);
  border-left: 3px solid #0047FF;
  transform: translateZ(0);
  animation: spinnerAnticlockwise 1.1s infinite linear;
  &.v0_2 {
    height: 64px;
    width: 62px;
  }
  &.style1 {
    border-width: 4px;
    border-color: rgba(255,43,107, 0.2);
    border-left-color: var(--site-theme-color);
    animation: spinnerClockwise 1.1s infinite linear;
    width: 88px;
    height: 88px;
  }
  &.paused {
    animation: none;
  }
}
.spinnerAnimation, .spinnerAnimation:after {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
@-webkit-keyframes spinnerAnticlockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinnerAnticlockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinnerClockwise {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes spinnerClockwise {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}