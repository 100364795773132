@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/guidelines.scss';
@import '~theme/modules/variables';

.media_popup {
  :global(.popup-content) {
    height: 100%;
  }
}

.tab_container {
  font-family: $fontBaseV2;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: auto;

  @include breakpoint-sm {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
  }

  .tab_navbar {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: fixed;
    top: 48px;
    z-index: 2;
    height: $topbarMediaPopupHeight;
    background: #fff;
    width: 100%;

    li {
      &.tab_nav {
        display: inline-block;
        color: #000000;
        margin-right: 29px;

        &:last-child {
          margin-right: 0;
        }

        a {
          color: inherit;
          cursor: pointer;
          padding: 0;
          font-size: 15px;
          font-family: $fontBaseV2;
          font-weight: 600;
          line-height: 136%;

          &:focus {
            outline: none;
            background: none;
          }
          &:hover {
            background-color: transparent; // override
          }
        }
      }
      &:hover {
        color: var(--site-theme-color);
      }

      &:global(.active) {
        color: var(--site-theme-color);

        a {
          cursor: default;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .tab_contents {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    margin-top: $topbarMediaPopupHeight;

    &:global(.popup-opened) {
      z-index: 3;
    }

    &:global(.tab-content > .active) {
      height: 100%;
    }
  }

  .tabsHeadSection {
    margin-top: 40px;
  }
}
