@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  background: #f4f4f4;

  &.isLogged {
    margin-top: 172px;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 55px 64px 65px;
  }

  .titleContent {
    @include ioradFont;
  }

  .descWrapper {
    padding-top: 20px;
  }

  .desc {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;

    p {
      width: 810px;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: -2px;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 260px 158px 160px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .title {
      padding: 68px 24px 0;
    }

    .descWrapper {
      padding: 39px 0;
    }

    .desc {
      padding: 0 24px;

      p {
        width: 100%;
        font-size: 13px;
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .learnMoreBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
