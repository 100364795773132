@import '~theme/modules/variables';
@import '~theme/mixins';

.search_and_sort_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 481px;
  height: 40px;
  background: transparent;
  position: relative;
  z-index: 15;

  &::after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
  }

  &.input_focused {
    &::after {
      background: black;
    }

    .search_icon_button {
      opacity: 1;

      &:hover {
        svg path {
          stroke: var(--site-theme-color);
        }
      }
    }
  }

  @include breakpoint-sm {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 10px;
  }

  .search_wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;

    &.hidden {
      visibility: hidden;
    }
  }
  .search_icon_button {
    width: 32px;
    min-width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.4;
  }
  .search_input {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0;
    padding-left: 8px;
    caret-color: var(--site-theme-color);
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    color: black;
    background: transparent;

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }

    @include breakpoint-sm {
      &:not(.focused) {
        max-width: 100px;
      }
    }
  }

  .filter_button {
    display: flex;

    & > span {
      display: flex;
    }

    @include breakpoint-sm {
      margin-right: 7px;
    }
  }

  .sort_wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .sort_option_actived {
    font-size: 15px;
    line-height: 136%;
    color: black;
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 24px;
    margin-left: 5px;
    order: 1;

    @include breakpoint-sm {
      font-size: 13px;
      line-height: 150%;
      font-weight: 400;
      margin-right: 5px;
      margin-left: 0;
      order: 0;
    }
  }
  .sort_icon_button {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sort_list {
    position: absolute;
    right: 0;
    top: 36px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    padding-right: 24px;
    padding-bottom: 13px;
    padding-top: 4px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    list-style-type: none;

    @include breakpoint-sm {
      background: white;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-right: 58px;
      background: $backgroundTiles;
    }
  }
  .sort_item {
    font-size: 15px;
    line-height: 136%;
    color: black;
    padding: 7px 0;
    text-align: right;
    &:hover {
      text-decoration: underline;
    }

    @include breakpoint-sm {
      font-size: 13px;
      line-height: 150%;
      font-weight: 400;
    }
  }
}
