@import '~theme/modules/variables';
@import '~theme/mixins';
@import '~components/Integrations/general';

$header-height: 56px;

.easyPopup {
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;

  .header {
    display: flex;
    align-items: center;
    height: $header-height;

    .integrationTabs {
      display: flex;
      align-items: center;
      flex-grow: 1;
      height: 100%;
      justify-content: space-around;
      gap: 1px;

      .tab {
        display: flex;
        align-items: center;
        gap: 10px;
        background: #F4F4F4;
        width: 100%;
        height: 100%;
        justify-content: center;
        cursor: pointer;

        &:global(.active) {
          background: #FFFFFF;
        }

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.3;
        }

        &:hover {
          color: #000;
          background: #FFFFFF;
        }
      }
    }

    .logo {
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: flex-start;
      margin: 56px 40px 0 40px;

      .integrationLogo {
        max-height: $header-height - 10px;
        height: $header-height - 10px;
        min-height: $header-height - 10px;
        width: $header-height - 10px;
        min-width: $header-height - 10px;
        @include integrationIcon;
      }

      .xLogo {
        height: 16px;
        width: 16px;
        margin: 0 24px;
      }

      .ioradLogo {
        height: $header-height;
        width: auto;
      }
    }

    .closeButton {
      height: $header-height;
      width: $header-height;
      background-color: #000000;
      border: none;
      outline: none;
    }

    .helpButton {
      height: $header-height;
      width: $header-height;
      background: #F4F4F4;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 1px;

      &:hover {
        background: #FFFFFF;
      }
    }
  }

  .content {
    flex-grow: 1;
    overflow: hidden;
    max-height: 100%;
  }
}
