@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/HelpCenterSettings/HelpCenterSettings';
@import '~components/Integrations/general';

body:global(.easy-popup-open) {
  .webTab {
    display: none;
  }
}

@keyframes blink-animation-keyframe {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.2;
  }
}

.webTab {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  .rolesContainer {
    width: 100%;
  }

  .rolesContainer_header {
    z-index: 2;
    background: white;
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    width: 100%;
    align-items: center;

    &:has(.helpCenterForIntegration) {
      align-items: center;
    }
  }

  .rolesContainer_header_search_sort {
    display: flex;
    flex-direction: row;
    width: 450px;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-left: auto;

    &:focus-within {
      border-color: black;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &.defaultMode {
      .rolesContainer_header_search {
        flex-grow: 1;
      }
      .rolesContainer_header_search_inputWrapper {
        width: 100%;
      }
      .rolesContainer_header_search_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        left: 0;
      }
      .rolesContainer_header_search_input {
        background: transparent;
        border: 0;
        height: 56px;
        padding-left: 52px;
        padding-right: 0;
      }

      .sortOptionActived {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: capitalize;
      }

      .sortIconContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 56px;
        cursor: pointer;
      }
    }

    &.sortMode {
      justify-content: flex-start;
      user-select: none;
      position: relative;

      .sortActive {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .sortOptionActived {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: black;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          text-transform: capitalize;
        }

        .sortIconContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 56px;
          cursor: pointer;
        }
      }

      .sortFilters {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 56px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        padding-top: 7px;
        padding-right: 15px;
        padding-bottom: 13px;
        z-index: 2;

        .sortOptionItem {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: black;
          padding: 7px 0;
          text-align: right;
          text-transform: capitalize;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @include breakpoint-sm {
      width: 100%;
      margin-left: 0;
    }
  }
  .sortModeActiveOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: alias;
    background: rgba(255, 255, 255, 0.4);
    z-index: 2;
  }
  .activeRoleOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: alias;
    background: rgba(255, 255, 255, 0.4);
    z-index: 3;

    @include breakpoint-sm {
      display: none;
    }
  }

  .createNewRole,
  .restoreAllRole {
    background: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    height: 25px;

    svg {
      color: inherit;

      [stroke] {
        color: inherit;
        stroke: currentColor;
      }
      [fill] {
        color: inherit;
        fill: currentColor;
      }
    }

    span {
      color: inherit;
      margin-left: 20px;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      color: var(--site-theme-color);
    }

    &.limitReached,
    &:disabled {
      color: rgba(0, 0, 0, 0.2);

      &:hover {
        color: rgba(0, 0, 0, 0.2);
      }
    }

    @include breakpoint-sm {
      display: none;
    }
  }

  .rolesContainer_list {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .rolesContainer_list_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;

    &.active {
      position: relative;
      z-index: 4;
      border-color: rgba(0, 0, 0, 0.2);

      &:hover {
        .rolesContainer_list_item_name {
          color: black;
        }
      }
    }

    &:hover {
      border-color: rgba(0, 0, 0, 0.2);
    }

    &.blink {
      background: #fef2fd;
      animation: blink-animation-keyframe 0.5s linear infinite alternate;

      .rolesContainer_list_item_name {
        color: var(--site-theme-color);
      }
    }

    &:not(.roleEnabled) {
      .rolesContainer_list_item_name {
        color: #bababa;
      }
    }

    @include breakpoint-sm {
      padding: 16px;
    }
  }
  .rolesContainer_list_item_info {
    display: flex;
    align-items: center;
    min-height: 32px;
    column-gap: 24px;

    @include breakpoint-sm {
      flex-wrap: wrap;
      min-height: auto;
    }
  }
  .rolesContainer_list_item_name {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;

    @include breakpoint-sm {
      width: 100%;
      margin-bottom: 10px;
      white-space: unset;
    }
  }

  .rolesContainer_list_item_lastUpdated {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
    width: 200px;
    text-align: end;
    flex-shrink: 0;

    @include breakpoint-sm {
      width: auto;
      margin-left: auto;
    }
  }

  .rolesContainer_list_item_controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 23px;

    .rolesContainer_list_item_controls_list {
      display: grid;
      grid-template-columns: auto auto auto auto;
      gap: 3px 51px;

      div[role='button'] {
        display: flex;
        align-items: center;

        &:hover {
          svg [stroke] {
            stroke: var(--site-theme-color);
          }
          svg [fill] {
            fill: var(--site-theme-color);
          }
          span {
            color: var(--site-theme-color);
          }
        }

        .rolesContainer_list_item_icon {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
        }

        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: black;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }

        &.limitReached,
        &.helpcenterDisabled {
          opacity: 0.2;
          cursor: default;
        }
      }
    }

    .rolesContainer_list_item_controls_close {
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;
      background: transparent;
      flex-shrink: 0;
      padding: 0;
      width: 24px;

      &:hover {
        svg rect {
          fill: var(--site-theme-color);
        }
      }
    }

    @include breakpoint-sm {
      flex-direction: column;
      align-items: flex-end;
      margin-top: 24px;

      .rolesContainer_list_item_controls_list {
        grid-template-columns: auto auto;
        row-gap: 10px;
        column-gap: 41px;
        margin-bottom: 24px;
        width: 100%;

        span {
          white-space: nowrap;
        }
      }
    }
  }

  .rolesContainer_list_item_copy_url {
    input {
      position: fixed;
      top: -10000px;
      left: -10000px;
    }
  }

  .rolesContainer_list_item-renaming {
    .rolesContainer_list_item-renaming_inner {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #000000;
      width: 100%;
      height: 32px;
      padding: 0;
      box-sizing: border-box;

      &:focus-within {
        border-color: var(--site-theme-color);
      }
    }
    .rolesContainer_list_item-renaming_icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rolesContainer_list_item-renaming_input {
      border: 0;
      background: transparent;
      flex-grow: 1;
      padding: 0;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      caret-color: var(--site-theme-color);
    }
    .rolesContainer_list_item-renaming_cancel {
      border: 0;
      margin: 0;
      padding: 0;
      background: transparent;
      margin-left: 12px;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000000;

      &:hover {
        color: var(--site-theme-color);
      }
      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
    .rolesContainer_list_item-renaming_save {
      border: 0;
      margin: 0;
      padding: 0;
      background: transparent;
      margin-left: 12px;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #000000;

      &:hover {
        color: var(--site-theme-color);
      }
      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  &.insideTutorials {
    .rolesContainer_header {
    }
  }
}

.text {
  font-size: 15px;
  margin: 20px 0 30px;
}

.tabContainer {
  display: flex;
  padding: 28px 45px 30px 40px;

  @include breakpoint-sm {
    flex-direction: column;
    padding: 0 24px;
  }
}

.tooltip {
  &:global(.tooltip.bottom) {
    :global(.tooltip-arrow) {
      display: none;
    }

    :global(.tooltip-inner) {
      border-radius: 0;
      padding: 5px 10px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: black;
      background: #f4f4f4;
      text-align: left;
    }
  }
}

.activeHelpCenterCheckbox {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  .formGroup,
  .checkbox {
    margin-bottom: 0;
  }
}

.tabHeader {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  gap: 57px;
  background: #fff;
  z-index: 2;
  min-width: 237px;

  &.insideTutorials {
    gap: 24px;

    .activeHelpCenterCheckbox {
      margin-top: 0;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.tabHeaderList {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.tabHeaderListDropdown {
  display: none;
}

.tabHeaderItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: black;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;

  svg {
    color: inherit;
    margin-right: 20px;
    width: 20px;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  &.active,
  &:hover {
    color: var(--site-theme-color);
  }
}

.tabContent {
  width: 100%;
  max-width: 900px;
  margin-left: 131px;
}

.insideTutorials {
  .tabContent {
    margin-left: 0;
  }
}

@include breakpoint-sm {
  .tabHeader {
    gap: 0;
    min-width: 100%;
    position: relative;
    z-index: 3;
  }

  .tabHeaderList {
    display: none;
  }

  .tabHeaderListDropdown {
    display: flex;
    position: relative;
    margin-top: 10px;

    .tabHeaderListDropdown_input {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 40px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      svg {
        width: 20px;
      }

      span {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;
        margin-left: 20px;
        flex-grow: 1;
      }

      .tabHeaderListDropdown_input_icon {
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.isDropdownShow {
          transform: rotate(180deg);
        }
      }
    }

    .tabHeaderListDropdown_dropdown {
      position: absolute;
      top: 100%;
      left: -12px;
      transform: translateY(-1px);
      border: 1px solid rgba(0, 0, 0, 0.2);
      display: none;
      flex-direction: column;
      background: white;
      width: calc(100% + 24px);
      padding: 7px 12px;

      &.show {
        display: flex;
      }

      .tabHeaderListDropdown_dropdown_item {
        display: flex;
        align-items: center;
        padding: 6px 0;

        svg {
          width: 20px;
        }

        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: black;
          margin-left: 20px;
        }
      }
    }
  }

  .tabContent {
    max-width: 100%;
    margin-left: 0;
    margin-top: 23px;
  }
}

.shareForEmbedInfo {
  &.noHelpCenter {
    text-align: center;
    padding: 20px;
    font-style: italic;
  }

  &.webTab {
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    justify-content: flex-start;
    max-height: 100%;
    padding-bottom: 10px;
    width: 100%;
    margin: 0 auto;
    height: 100%;

    @media screen and (max-width: 1260px) {
      padding: 80px 40px 10px;
    }

    .tabContainer {
      flex-grow: 1;
      padding: 0;
      margin: 0;

      .tabHeader {
        display: none;
      }

      .tabContent {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        max-width: 1200px;

        .createNewRole {
          display: none;
        }

        .rolesContainer {
          opacity: 1;
        }
      }
    }

    .helpCenterForIntegration {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 10px;

      font-family: Libre Franklin, serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 20.4px;
      text-align: left;

      .integrationLogo {
        width: 24px;
        height: 24px;
        min-width: 16px;
        min-height: 16px;
        @include integrationIcon;
      }

      .integrationName {
        text-transform: capitalize;
      }
    }

    .helpCenterForEmbedMenu {
      padding-bottom: 40px;
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      gap: 20px;

      .menuItem {
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;

        &:global(.active) {
          color: var(--site-theme-color);
        }
      }
    }

    .embedToPartnerVendor {
      cursor: not-allowed;

      &.roleEnabled {
        cursor: pointer;
      }
    }
  }
}
