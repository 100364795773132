@import '~theme/guidelines_variables';
@import '~theme/mixins';
@import '~components/NewPricing/partials';

$black: #000000;

.box {
  color: #000;
  display: flex;
  height: 422px;
  width: 295px;
  position: relative;
  background-color: #ffffff;
  box-sizing: border-box;

  .bold {
    font-weight: 600;
  }

  .planPrice {
    font-weight: 600;
    font-size: 21px;
    line-height: 136%;
    margin: 0;
    margin-bottom: 51px;

    &.hasSubText {
      margin-bottom: 0px;
    }

    @include breakpoint-sm {
      padding: 0 30px;
      margin-bottom: 37px;

      &.hasSubText {
        margin-bottom: 5px;
      }
    }
  }

  .priceAdditional {
    font-size: 15px;
    line-height: 136%;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 6px;
    height: 42px;

    @include breakpoint-sm {
      font-size: 13px;
      height: 22px;
      margin-bottom: 12px;
      padding: 0 30px;
    }
  }

  .userIcon {
    margin: 7px 4px;
  }

  .checkoutBtn {
    width: 229px;
    height: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #000000;
    outline: none;
    font-weight: 600;
    font-size: 15px;
    font-weight: 600;

    &:not(:disabled):hover {
      color: var(--site-theme-color);
    }

    &:disabled {
      background-color: #e5e5e5;
      cursor: not-allowed;
    }

    @include breakpoint-sm {
      width: 100%;
      border: none;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 13px;
    }
  }

  @include breakpoint-sm {
    width: 100%;
    height: 314px;
  }
}

.column {
  text-align: left;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 136%;
  position: relative;
  padding: 24px 32px 12px;

  @include breakpoint-sm {
    padding: 0;
    padding-top: 26px;
  }

  .mostPopular {
    position: absolute;
    top: 5px;
    right: 17px;
    color: var(--site-theme-color);
    font-size: 15px;
    &::after {
      content: '';
      background-image: url('~assets/img/pricing/most-popular.svg');
      width: 37px;
      height: 44px;
      position: absolute;
      top: -28px;
    }
  }

  .titleBox {
    font-weight: 600;
    font-size: 21px;
    line-height: 136%;
    margin: 0;
    margin-bottom: 20px;

    @include breakpoint-sm {
      margin-bottom: 0px;
      padding: 0 30px;
    }
  }

  .monthPrice {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    margin: 0;
  }

  .arrow {
    display: none;

    @include breakpoint-sm {
      display: block;
    }
  }

  .arrowRight {
    content: '';
    background: url('../../../assets/img/icons/link-arrow-black.svg') 85% center
      no-repeat;
    color: #000;
    height: 40px;
    width: 11px;
    position: absolute;
    right: 7px;
    top: -2px;
    padding: 16px 20px;
  }

  .arrowLeft {
    content: '';
    background: url('../../../assets/img/icons/link-arrow-black.svg') 85% center
      no-repeat;
    color: #000;
    height: 40px;
    width: 11px;
    position: absolute;
    left: 7px;
    top: -3px;
    padding: 16px 20px;
    transform: rotate(180deg);
  }
}

.colLeft {
  padding: 20px;
  padding-right: 40px;

  .description {
    border: none;
  }

  h1 {
    margin-top: 0;
    font-weight: 600;
    line-height: 56px;
    font-size: 48px;
  }
}

.colRight {
  padding: 20px;
  padding-left: 40px;

  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 20px;
  }

  svg {
    margin-right: 15px;
  }

  li {
    font-weight: normal;
    line-height: 46px;
    font-size: 16px;
    text-align: left;
  }

  h1 {
    margin: 0;
    font-weight: 600;
    line-height: 36px;
    font-size: 28px;
  }

  i {
    font-size: 16px;
  }
}

.level {
  font-family: 'Libre Franklin', sans-serif;
  color: #444444;
  font-size: 24px;
  font-weight: $semibold;
  line-height: 24px;
}

.price {
  color: $black;
  margin-top: $gl_gap_padding;
  font-weight: $lighter;
  font-size: 56px;
  line-height: 60px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    font-size: 40px;
  }

  span {
    font-weight: lighter;
    font-size: 48px;
    font-family: 'Libre Franklin', sans-serif;
    display: inline-block;

    &:last-child {
      color: $black;
      font-size: 26px;
    }
  }
}

.priceBtn {
  display: block;
  margin-top: 8px;
  margin-bottom: 20px;
  // max-width: 140px;
  height: 64px;
  line-height: 64px;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: $lighter;
  border: 1px solid #fff;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    text-decoration: inherit;
    color: #fff;
    background-color: $blue;
  }

  @extend .priceBtnExt;
}

.note {
  color: #999999;
  font-size: 12px;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.description {
  font-size: 15px;
  line-height: 136%;
  flex-grow: 1;

  @include breakpoint-sm {
    font-size: 13px;
    padding: 0 30px;
  }

  a {
    text-decoration: underline;
  }
}
@media screen and (max-width: 1360px) {
  .container {
    width: 100%;
  }
}
@media screen and (max-width: 970px) {
  .col {
    width: 100%;
    padding: 0 15px;
  }

  .price {
    text-align: center;
    margin-top: $gl_gap * 3;
    font-size: 56px;

    span {
      &:last-child {
        color: $grey;
      }
    }
  }
}
