.table__sidebar__list {
  display: flex;
  flex-direction: column;
  padding-bottom: 56px;

  .table__sidebar__list_group {
    display: flex;
    flex-direction: column;

    .table__sidebar__list_title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: 56px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      opacity: 0.5;
      margin: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .table__sidebar__list_group_expand {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 56px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      padding: 0 24px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }

    .table__sidebar__list_subGroup {
      display: flex;
      flex-direction: column;

      .table__sidebar__list_item {
        padding-left: 72px;
      }

      &.noPadding {
        .table__sidebar__list_subGroup_expand {
          padding: 0 24px;
        }
        .table__sidebar__list_item {
          padding: 0 48px;
        }
      }
    }

    .table__sidebar__list_subGroup_expand {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 56px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      padding: 0 48px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }

  .table__sidebar__list_item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 18px 24px;
    min-height: 56px;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &:first-child {
      border-top: none;
    }

    &:hover,
    &.active {
      color: var(--site-theme-color);
    }
  }
}
