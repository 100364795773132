@import '~theme/mixins';

.cardInfo {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  @include breakpoint-sm {
    width: 100%;
    align-items: unset;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 12px 24px;
    border-radius: 16px;
    height: 226px;
    width: 352px;
    max-width: 100%;
    background-color: #fff;

    @include breakpoint-sm {
      height: 100%;
      width: 100%;
    }

    &::before, &::after {
      content: none;
    }

    &.btnCancel {
      align-self: flex-end;
    }
  }

  .btnGroup {
    display: flex;
    margin-top: 86px;

    button {
      width: 106px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(0, 0, 0, 0.2);
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000;
      background-color: #fff;

      &:disabled {
        color: rgba(0, 0, 0, 0.4);
        pointer-events: none;
      }

      &:first-child {
        margin-right: 29px;
      }

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .cardFooter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;

    > span {
      line-height: 46px;
    }
  }

  .brandLogo {
    display: block;
    background-size: 14px;
    width: 50px;
    height: 40px;
    max-height: 40px;
    background: url('~assets/img/visa.svg') no-repeat 0 center;

    &.mastercard {
      background: url('~assets/img/mastercard.svg') no-repeat 0 center;
    }

    &.unionpay {
      background: url('~assets/img/cards/unionpay.svg') no-repeat 0 center;
      background-size: 50px auto;
      flex: auto;
    }

    &.americanexpress {
      background: url('~assets/img/cards/american.svg') no-repeat -20px center;
      width: auto;
      flex: auto;
    }

    &.dinersclub {
      background: url('~assets/img/cards/diners-club.svg') no-repeat -17px center;
      width: auto;
      flex: auto;
    }

    &.discover {
      background: url('~assets/img/cards/discover.svg') no-repeat -10px center;
      width: auto;
      flex: auto;
    }

    &.jcb {
      background: url('~assets/img/cards/jcb.svg') no-repeat -24px center;
      width: auto;
      flex: auto;
    }
  }

  .updateCard {
    width: 106px;
    height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    align-self: flex-end;
    text-decoration: none;

    span {
      color: #000;
      font-size: 15px;
      line-height: 136%;
      font-weight: 600;
    }

    &:hover {
      background-color: #fff;

      span {
        color: var(--site-theme-color);
      }
    }
  }

  .cardNumber {
    color: #000;
    font-size: 16px;
    margin: auto 0 0 0;
    width: auto;
    text-align: left;

    .cardBrand {
      display: none;;
    }

    .cnPlaceholder {
      color: rgba(0, 0, 0, 0.2);
    }

    .last4 span {
      font-weight: bold;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;

    img {
      margin: 0;
      width: 16%;
      max-height: 35px;
    }
  }
}

.cardWrapper {
  height: 100%;
}

.cardSimple {
  width: 100%;
  padding-right: 10px;

  @include breakpoint-sm {
    width: 100%;
  }

  .formGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
    height: auto;
    width: auto;
    border-radius: none;
    padding: 0;
    background: transparent;
  }


  .formGroup {
    border: none;
    border-radius: 0;
  }

  .brandLogo {
    flex: 0;
    min-height: 40px;
    min-width: 40px;
    margin-right: 40px;

    @include breakpoint-sm {
      margin-right: 30px;
    }
  }

  .cardNumber {
    flex: auto;
    text-align: center;
    margin: 0;
  }

  .changeCardButton {
    margin: 0;
    align-self: center;
  }

  .cardFooter {
    margin-bottom: 0;
    flex-grow: 1;
  }
}

.changeCardButton {
  font-size: 15px;
  align-items: center;
  text-decoration-line: underline;

  a {
    color: #000000;
  }
}

.last4 span {
  font-weight: bold;
}
