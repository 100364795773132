@import '~theme/mixins';
@import '~theme/modules/variables';

.learners_content {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  margin-top: 40px;

  .learners_content__filters {
    display: flex;
    column-gap: 40px;
    align-items: center;
    margin-bottom: 10px;
    position: sticky;
    z-index: 2;
    top: 0;
    background: #fff;

    @include breakpoint-sm {
      position: unset;
    }

    > div {
      max-width: 430px;
    }
  }

  .learners_content__table {
    display: flex;
    flex-direction: column;
  }

  .learners_content__table_header {
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: 56px;
    padding: 0 24px;
    position: sticky;
    z-index: 1;
    top: 56px;
    background: #fff;

    & > span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
      flex: 1;
      display: flex;
      justify-content: flex-start;

      &:last-child {
        max-width: 80px;
        justify-content: flex-end;
      }

      @include breakpoint-sm {
        &:nth-child(3) {
          display: none;
        }
      }

      .text {
        cursor: pointer;
      }

      .sortIconContainer {
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  .learners_content__table_body {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
  }

  .learners_content__table_hint {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    padding: 18px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .learners_content__table_row {
    display: flex;
    column-gap: 8px;
    padding: 18px 24px;
    background: #f4f4f4;
    position: relative;
    cursor: pointer;

    &:hover {
      .learners_content__table_row_learnerName label {
        cursor: pointer;
        color: var(--site-theme-color);
      }
    }

    .learners_content__table_row_cell {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      flex: 1;

      img {
        width: 20px;
        flex-shrink: 0;
      }

      label {
        font-weight: 400;
        word-break: break-word;
        margin: 0;
        margin-left: 20px;
      }

      &:last-of-type {
        max-width: 80px;
        justify-content: flex-end;
      }

      @include breakpoint-sm {
        &:nth-child(3) {
          display: none;
        }
      }
    }

    .learners_content__table_row_actions {
      display: flex;
      position: absolute;
      align-items: center;
      top: 0;
      right: -40px;
      height: 100%;
      z-index: 0;

      .learners_content__table_row_underlay {
        display: none;
        content: '';
        z-index: 0;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: alias;
        z-index: 2;
        background: #fff;
        opacity: 0.2;
      }

      .learners_content__table_row_menu_icon {
        cursor: pointer;
        width: 40px;
        height: 100%;
        display: flex;
        padding-top: 19px;
        justify-content: center;
        position: relative;
        z-index: 3;
      }

      .learners_content__table_row_menu {
        display: none;
        position: absolute;
        right: 40px;
        top: -1px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #fff;
        padding: 0;
        z-index: 3;
        min-width: 200px;
        margin: 0;

        li {
          display: flex;
          align-items: center;
          padding: 18px 24px;
          cursor: pointer;
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
          color: black;

          &:hover {
            color: var(--site-theme-color);
            svg {
              circle,
              path {
                fill: var(--site-theme-color);
                stroke: var(--site-theme-color);
              }
            }
          }

          svg {
            margin-right: 15px;
          }
        }
      }

      &.open {
        z-index: 1;
        .learners_content__table_row_menu,
        .learners_content__table_row_underlay {
          display: block;
        }
      }
    }
  }

  .loading_dot {
    position: relative;
    width: 11px;
    color: black;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    @keyframes dots {
      to {
        width: 1em;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      content: '\2026';
      width: 0px;
      animation: dots 1s steps(4, end) infinite;
    }
  }

  .csv_only {
    display: none;
  }
}

.learner_detail_popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;

  .underlay {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .learner_detail_popup__container {
    height: 100%;
    width: 100%;
    max-width: 1328px;
    background: white;
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding-bottom: 40px;
  }

  .learner_detail_popup__content {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    padding-left: 80px;
    overflow: auto;
  }

  .learner_detail_popup__user_info {
    display: flex;
    width: 215px;
    flex-shrink: 0;
    margin-top: 80px;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      word-break: break-word;
      margin-left: 20px;
    }
  }

  .learner_detail_popup__detail {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 900px;
    margin-top: 62px;
    margin-left: 30px;
  }

  .learner_detail_popup__filters {
    display: flex;
    column-gap: 40px;
    align-items: center;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
  }

  .learner_detail_popup__table {
    display: flex;
    flex-direction: column;
    background: #f4f4f4;
    padding: 0 24px 24px;
  }

  .learner_detail_popup__table_header {
    display: flex;
    column-gap: 20px;
    background: #f4f4f4;
    position: sticky;
    z-index: 1;
    top: 56px;
    padding: 24px 0;

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
      cursor: pointer;

      &:hover,
      &.active {
        color: var(--site-theme-color);
      }
    }
  }

  .learner_detail_popup__close_btn {
    position: absolute;
    top: 4px;
    right: 4px;
    background: black;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    &:hover {
      background: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .learner_detail_popup {
    .learner_detail_popup__container {
      padding-bottom: 24px;
    }

    .learner_detail_popup__content {
      padding: 12px;
      flex-direction: column;
    }

    .learner_detail_popup__user_info {
      margin-top: 0;
    }

    .learner_detail_popup__detail {
      margin-top: 40px;
      margin-left: 0px;
    }

    .learner_detail_popup__filters {
      column-gap: 24px;
    }

    .learner_detail_popup__table {
      padding: 0 12px 12px;
    }

    .learner_detail_popup__table_header {
      column-gap: 12px;
      padding: 12px 0;
    }

    .learner_detail_popup__close_btn {
      width: 24px;
      height: 24px;
    }
  }
}
