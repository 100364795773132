@import '~theme/modules/variables';
@import '../includes';

$zIndexBase: 22;

.fixedBottomToolbar {
  position: fixed;
  bottom: 0;
  height: $bottomBarHeight;
  width: 100vw;
  background: #fff;
  display: flex;
  align-items: flex-end;
  z-index: $zIndexBase;
  transition: none !important;

  .wrapper {
    display: flex;
    align-items: center;
    height: calc(#{$bottomBarHeight} - 1px);
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.05);
    padding-left: 363px;
    font-family: $fontBaseV2;
    font-size: 15px;
    color: #000;
  }

  .actions {
    font-weight: bold;
    font-family: $fontBaseV2;
    color: #000000;
    font-size: 15px;
    margin-left: 15px;

    .action {
      padding: 15px;
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);
      }

      &.disabled {
        opacity: 0.4;
        cursor: default;
        &:hover {
          color: inherit;
        }
      }
    }
  }

  .close {
    position: fixed;
    right: 157px;
    cursor: pointer;
    display: flex;

    .buttonIcon {
      margin-right: 15px;

      &:hover {
        path {
          stroke: var(--site-theme-color);
        }
      }

      &:last-child {
        margin-left: 15px;
        margin-right: 0;
      }

      img {
        width: 16px;
        margin: 0 15px;
      }
    }
  }
  &.ontop1 {
    z-index: $zIndexBase + 1;
  }
}

@media screen and (max-width: 1200px) {
  .fixedBottomToolbar {
    .wrapper {
      padding-left: 0;
      justify-content: center;
    }
    .close {
      right: 51px;
    }
  }
}
