@import '../../variables';

.settings {
  display: flex;
  flex-direction: column;
  height: 100%;

  .settings__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    position: relative;
    // background-color: rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.2);

    h4 {
      font-size: 21px;
      line-height: 24px;
      margin: 0;
      margin-bottom: 24px;
    }

    p {
      @include defaultFont;
      margin: 0;
    }

    .settings__header_close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: $defaultHeightBtn;
      width: $defaultHeightBtn;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .settings__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $defaultColor;
    height: 100%;
    overflow: hidden;
    padding: 16px;
  }

  .settings__content_tabs {
    background: white;
    max-width: 600px;
    width: 100%;
    position: relative;

    > div:first-child {
      height: $defaultHeightBtn;
      border: 1px solid white;

      > div > span {
        font-weight: 400 !important;
        order: 1;
      }
    }
  }

  .settings__navigation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    height: 80px;
    background-color: white;
    padding: 0 40px;

    label {
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 24px;

      span {
        @include defaultFont;
        color: black;

        b {
          font-weight: 600;
        }
      }

      &.back {
        span {
          font-weight: 600;
        }
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .settings {
  }
}
