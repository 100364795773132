@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  background: #f4f4f4;
  padding: 53px 0 74px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;

    .titleContent {
      display: flex;
      justify-content: space-between;
    }

    .titleText {
      @include ioradFont;
    }

    .titleLogo {
      flex-shrink: 1;

      img {
        width: 235px;
      }
    }
  }
}

.spotlightContentSection {
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 80px 64px 0;

  &.lastItem {
    padding-bottom: 80px;
  }

  .contentSection {
    width: 811px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    margin: 0;
    margin-bottom: 24px;
  }

  .contentItem {
    margin-bottom: 72px;

    .titleItem {
      font-weight: 600;
      font-size: 21px;
      line-height: 116%;
      color: #000000;
      margin: 0;
      margin-bottom: 28px;
    }

    .descItem {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }
  }
}

.tldrSection {
  background-image: url('~assets/img/redesign2021/partnerspotlight/TLDR.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #f4f4f4;
  background-size: cover;
  height: 516px;
  margin-top: -37px;

  .tldrContentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .tldrContent {
    width: 516px;
    margin-left: auto;
    margin-right: 242px;
    padding-top: 38px;
  }

  .tldrTitle {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    color: #000000;
    margin: 0;
  }

  .tldrDesc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-top: 26px;
  }

  .tldrList {
    margin: 0;
    padding: 0;
    margin-top: 24px;
    list-style-type: none;

    li {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      position: relative;
      padding-left: 21px;

      &::before {
        content: url('~assets/img/redesign2021/communitypage/checked.svg');
        position: absolute;
        left: 0;
        top: 2px;
        width: 16px;
        height: 16px;
      }
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;
    padding: 69px 0 37px;

    &.isLogged {
      margin-top: 46px;
    }

    .titleWrapper {
      padding: 0;

      .titleContent {
        flex-direction: column;

        > img {
          padding-left: 19px;
          padding-bottom: 18px;
          width: max-content;
        }
      }

      .titleLogo {
        padding-left: 24px;

        img {
          max-width: 130px;
        }
      }
    }
  }

  .spotlightContentSection {
    padding: 119px 24px 18px;

    &.lastItem {
      padding-top: 40px;
      padding-bottom: 175px;
    }

    .contentSection {
      width: 100%;
    }

    .title {
      font-size: 21px;
      line-height: 136%;
      margin-bottom: 17px;
    }

    .contentItem {
      margin-bottom: 58px;

      .titleItem {
        font-size: 13px;
        line-height: 150%;
        margin-bottom: 8px;
      }

      .descItem {
        font-size: 13px;
      }
    }
  }

  .tldrSection {
    background-image: url('~assets/img/redesign2021/partnerspotlight/TLDR_mobile.png');
    background-position: bottom center;
    background-size: 100% 476px;
    height: 667px;
    margin-top: 0;
    padding-top: 34px;
    position: relative;

    .tldrContentWrapper {
      padding: 0 24px;
    }

    .tldrContent {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .tldrDesc {
      font-size: 13px;
      margin-top: 21px;
    }

    .tldrList {
      margin: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 213px;

      li {
        font-size: 13px;
        line-height: 150%;
      }
    }
  }
}
