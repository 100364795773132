@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  width: 100%;
  max-width: 810px;
  padding-top: 36px;

  * {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }
}

.item {
  margin-bottom: 8px;
  display: none;

  &.show {
    display: block;
  }
}

.titleItem {
  font-weight: 600;
  font-size: 21px;
  line-height: 116%;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: var(--site-theme-color);
  }
}

.descItem {
  padding-top: 4px;
  padding-bottom: 64px;
}

.subTitleItem {
  display: block;
  font-weight: 600;
  margin-top: 22px;
  margin-bottom: 3px;
}

.item.subprocessors {
  max-width: 100%;

  .descItem {
    padding-bottom: 4px;
  }
}

.tableWrapper {
  width: 100%;
  margin-left: auto;
  padding-top: 65px;
  padding-bottom: 45px;

  .tableContent {
    width: 100%;
    border: none;
    table-layout: fixed;

    .tableTitle {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 13px;
    }

    table {
      border: none;

      tr {
        th,
        td {
          border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
          padding: 16px 69px 16px 0;

          &:nth-last-child(1) {
            width: 236px;
            padding-right: 23px;
          }
          &:nth-last-child(2) {
            width: 239px;
          }
          &:nth-last-child(3) {
            width: 188px;
          }
          &:nth-last-child(4) {
            width: 358px;
            padding-left: 23px;
            word-break: break-all;

            @media screen and (max-width: 1100px) {
              width: 100%;
            }
          }
        }

        &.lastItem td {
          padding-bottom: 90px;
        }
      }
    }

    .empty {
      display: block;
      margin-left: 30px;
    }
  }
}

.lastUpdated {
  font-size: 10px;
  line-height: 150%;
  display: block;
  margin-bottom: 121px;
}

@include breakpoint-sm {
  .container {
    max-width: 100%;
    padding-top: 46px;

    * {
      font-size: 13px;
    }
  }

  .item {
    margin-bottom: 8px;
    max-width: 100%;
    padding: 0 24px;
  }

  .titleItem {
    font-size: 21px;
  }

  .descItem {
    padding-top: 9px;
    padding-bottom: 65px;
  }
  .subTitleItem {
    margin-top: 18px;
    margin-bottom: 9px;
  }

  .item.subprocessors {
    max-width: 100%;

    .descItem {
      padding-top: 20px;
      padding-bottom: 4px;
    }
  }

  .tableWrapper {
    padding-top: 0;
    margin-left: 0;

    .tableContent {
      width: 100%;

      table {
        width: calc(100% + 48px);
        margin-left: -24px;
        table-layout: fixed;

        tr {
          th,
          td {
            border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
            padding: 15px 11px 10px 0;
            font-size: 10px;
            line-height: 150%;

            &:nth-child(1) {
              padding-left: 24px;
              word-break: break-word;
            }

            &:nth-child(2),
            &:nth-child(3) {
              width: 84px;
            }

            &:nth-child(4) {
              width: 99px;
              padding-right: 24px;
            }
          }

          &.lastItem td {
            padding-bottom: 75px;
          }
        }
      }
    }
  }

  .lastUpdated {
    font-size: 10px;
    padding: 0 24px;
    margin-bottom: 226px;
  }
}
