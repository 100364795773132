@import '~theme/modules/variables';
@import '~theme/mixins';

.downloadExtension {
  display: flex;
  height: 100vh;
  min-height: 600px;

  .downloadExtension__block {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .downloadExtension__header {
    .downloadExtension__logo {
      padding: 24px 24px 40px;
    }
  }

  .downloadExtension__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 44px;
    padding-bottom: 24px;
    overflow-y: auto;
  }

  .downloadExtension__content_inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 64px;
    }
  }

  .downloadExtension__buttons {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      column-gap: 10px;
      width: 320px;
      height: 56px;

      &:hover {
        svg path {
          &[fill] {
            fill: currentColor;
          }
          &[stroke] {
            stroke: currentColor;
          }
        }
      }
    }
  }

  .downloadExtension__footer {
    display: flex;
    justify-content: center;
    padding: 40px 24px;
  }

  .downloadExtension__image {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #bababa;
    padding-left: 56px;

    img {
      width: 100%;
      height: auto;
    }
  }
}
