@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    background: #2891be;
    min-height: 371px;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 56px 64px 30px;
    position: relative;
  }

  .titleContent {
    @include ioradFont;
    max-width: 1165px;
  }
}

.feature_container {
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 15px 40px 35px 64px;

  .feature_content {
    max-width: 809px;
    width: 100%;

    h5 {
      font-weight: 600;
      font-size: 21px;
      line-height: 24px;
      color: black;
      margin-top: 70px;
      margin-bottom: 0;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin-top: 7px;
      margin-bottom: 0;

      a {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      b {
        font-weight: 600;
      }
    }
  }

  .servicesSection {
    display: flex;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-bottom: 142px;

    &__left {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 327px;
      height: 100%;
      padding: 107px 36px 0 0;
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
    }

    &__filter {
      h4 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 14px;
      }

      &_search {
        width: 100%;

        &_input {
          width: 100%;
        }

        &_filterIcon {
          display: none;
        }
      }

      &_list {
        margin: 0;
        margin-top: 12px;
        padding: 0;
        list-style-type: none;
      }

      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
        font-size: 15px;
        line-height: 20px;
        color: black;

        &:hover,
        &.active {
          color: var(--site-theme-color);
        }

        span:first-child {
          text-transform: capitalize;
        }
      }
    }

    &__rating {
      margin-top: 26px;
      display: none;

      h4 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 21px;
      }

      &_list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        row-gap: 21px;
      }

      &_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        input:checked ~ div::before {
          border: 1px solid rgba(0, 0, 0, 0.1);
        }

        input:checked ~ div::after {
          display: block;
          width: 8px;
          height: 8px;
          background-color: #000000;
          left: 4px;
          top: 5px;
        }

        & > div {
          flex: 1;
          padding-left: 29px;

          &::before,
          &::after {
            display: block;
            position: absolute;
            left: 0;
            top: 1px;
            content: '';
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background-color: #f2f2f2;
          }

          &::after {
            display: none;
          }
        }

        input {
          display: none;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      flex-grow: 1;
      margin-top: 107px;
    }

    &__apps {
      display: flex;
      flex-wrap: wrap;
      gap: 18px 14px;
      list-style-type: none;
      margin: 0;
      padding: 0;

      &_item {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 292px;
        min-height: 292px;
        padding: 30px 25px 7px 29px;
        margin: 0;
        background: #f2f2f2;

        &_header {
          display: flex;

          &_icon {
            width: 32px;
            height: 32px;
            flex-shrink: 0;

            img {
              width: 100%;
            }
          }

          h6 {
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;
            color: black;
            margin: 0;
            margin-top: auto;
            margin-left: 20px;
            margin-right: 13px;
            flex-grow: 1;
          }

          a {
            margin-right: 2px;
            opacity: 0.4;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }
        }

        &_body {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin-top: 40px;
        }

        &_desc {
          font-size: 15px;
          line-height: 20px;
          color: black;
          margin-bottom: 40px;
          flex-grow: 1;
        }

        &_connected_info {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin-bottom: 40px;

          span {
            font-size: 15px;
            line-height: 20px;
            color: black;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &_star {
          display: flex;
          align-items: center;

          > div {
            column-gap: 3px;
            margin-right: 19px;
          }

          span {
            font-size: 10px;
            line-height: 15px;
            color: black;
            text-transform: capitalize;
          }
        }

        &_footer {
          margin-top: 18px;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: 600;
            max-width: 230px;
            width: 100%;
            height: 48px;
            background-color: black;
            color: white;
            transition: background-color 0.3s ease;

            &:hover {
              background-color: var(--site-theme-color);
            }
          }
        }
      }
    }
  }

  .more_features {
    margin-top: 65px;

    h4 {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: black;
      margin: 0;
      margin-bottom: 60px;
    }

    .more_features__list {
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
    }

    .more_features__item {
      display: flex;
      flex-direction: column;
      width: 294px;
      cursor: pointer;

      &:hover {
        .more_features__item_desc {
          color: var(--site-theme-color);
        }
      }
    }

    .more_features__item_name {
      height: 147px;
      width: 100%;
      padding: 12px;
      background: #f4f4f4;

      span {
        font-family: 'iorad';
        text-transform: uppercase;
        font-size: 48px;
        line-height: 32px;
        color: black;
        font-weight: 400;
      }
    }

    .more_features__item_desc {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
      padding: 15px 0;
      margin: 0;
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: -2px;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 260px 158px 160px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .contactBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    .titleWrapper {
      background: #2891be;
      min-height: 336px;
    }

    .title {
      max-width: 100%;
      padding: 64px 24px 0;

      img {
        width: 100%;
      }
    }
  }

  .feature_container {
    max-width: 100%;
    padding: 30px 24px 125px;

    .feature_content {
      max-width: 100%;

      h5 {
        margin-top: 0;
      }

      p {
        margin-top: 20px;
      }
    }

    .servicesSection {
      flex-direction: column;
      max-width: 100%;
      margin-bottom: 66px;

      &__left {
        width: 100%;
        padding: 0;
        position: relative;
      }

      &__filter {
        margin-top: 40px;
        h4 {
          display: none;
        }

        &_search {
          width: 100%;
          position: relative;

          &_filterIcon {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: 0;
            bottom: 1px;
            width: 33px;
            height: 33px;
            cursor: pointer;
          }
        }

        &_list {
          display: none;
        }

        &_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px 0;
          font-size: 15px;
          line-height: 20px;
          color: black;

          &:hover,
          &.active {
            color: var(--site-theme-color);
          }

          span:first-child {
            text-transform: capitalize;
          }
        }
      }

      &__rating {
        margin-top: 26px;
        display: none;

        h4 {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: black;
          margin: 0;
          margin-bottom: 21px;
        }

        &_list {
          margin: 0;
          padding: 0;
          list-style-type: none;
          display: flex;
          flex-direction: column;
          row-gap: 21px;
        }

        &_item {
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;

          input:checked ~ div::before {
            border: 1px solid rgba(0, 0, 0, 0.1);
          }

          input:checked ~ div::after {
            display: block;
            width: 8px;
            height: 8px;
            background-color: #000000;
            left: 4px;
            top: 5px;
          }

          & > div {
            flex: 1;
            padding-left: 29px;

            &::before,
            &::after {
              display: block;
              position: absolute;
              left: 0;
              top: 1px;
              content: '';
              width: 16px;
              height: 16px;
              border-radius: 100%;
              background-color: #f2f2f2;
            }

            &::after {
              display: none;
            }
          }

          input {
            display: none;
          }
        }
      }

      &__right {
        margin-top: 28px;
      }

      &__apps {
        flex-direction: column;
        row-gap: 25px;

        &_item {
          width: 100%;
          min-height: 215px;
          padding: 30px 31px 29px;

          &_header {
            h6 {
              margin-right: 0;
            }
          }

          &_body {
            margin-top: auto;
            flex-grow: unset;
          }

          &_desc {
            display: none;
          }

          &_connected_info {
            display: none;
          }

          &_footer {
            a {
              margin: 0 auto;
            }
          }
        }
      }
    }

    .more_features {
      h4 {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 38px;
      }

      .more_features__list {
        flex-direction: column;
        gap: 21px;
      }

      .more_features__item {
        width: 100%;

        &:hover {
          .more_features__item_desc {
            color: var(--site-theme-color);
          }
        }
      }

      .more_features__item_name {
        padding: 12px 24px;
      }
    }
  }

  .filtersModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 21;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: white;

    &__header {
      display: flex;
      align-items: center;
      padding-left: 27px;
      padding-right: 24px;
      flex-shrink: 0;
      height: 48px;
      background: #f4f4f4;
      cursor: pointer;

      span {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;
        flex-grow: 1;
        margin-left: 27px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;
      padding: 15px 24px 24px;
    }

    &__categories {
      margin: 0;
      padding: 0;
      list-style-type: none;

      &_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
        font-size: 15px;
        line-height: 20px;
        color: black;

        &:hover,
        &.active {
          color: var(--site-theme-color);
        }

        span:first-child {
          text-transform: capitalize;
        }
      }
    }

    &__rating {
      margin-top: 26px;

      h4 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 21px;
      }

      &_list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        row-gap: 21px;
      }

      &_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        input:checked ~ div::before {
          border: 1px solid rgba(0, 0, 0, 0.1);
        }

        input:checked ~ div::after {
          display: block;
          width: 8px;
          height: 8px;
          background-color: #000000;
          left: 4px;
          top: 5px;
        }

        & > div {
          flex: 1;
          padding-left: 29px;

          &::before,
          &::after {
            display: block;
            position: absolute;
            left: 0;
            top: 1px;
            content: '';
            width: 16px;
            height: 16px;
            border-radius: 100%;
            background-color: #f2f2f2;
          }

          &::after {
            display: none;
          }
        }

        input {
          display: none;
        }
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .contactBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
