.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: black;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.dropdown_header {
  display: flex;
  align-items: center;
  column-gap: 16px;
  font: inherit;
  font-weight: 600;

  .dropdown_arrow {
    display: flex;
    align-items: center;

    &.open {
      transform: rotate(180deg);
    }
  }
}

.dropdown_content {
  position: absolute;
  transform: translateX(-50%);
  background-color: white;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  z-index: 1000;
  width: 400px;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s ease-in-out;

  .dropdown_content_arrow {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='12' viewBox='0 0 24 12' fill='none'%3E%3Cpath d='M12.0002 0L23.3139 11.3137H0.686523L12.0002 0Z' fill='white'/%3E%3C/svg%3E");
    width: 24px;
    height: 12px;
    z-index: -1;
  }

  .dropdown_content_item {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 56px;
    padding: 0 16px;
    font-size: 15px;
    line-height: 20px;
    color: black;
    cursor: pointer;

    &:not(.locked):not(.selected):hover {
      background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
    }

    .dropdown_content_item_hover {
      display: flex;
      align-items: center;
      column-gap: 16px;
      visibility: hidden;
      font-weight: 600;
      margin-left: auto;

      svg {
        transform: rotate(270deg);
      }
    }

    &.locked {
      cursor: default;

      &:hover {
        .dropdown_content_item_hover {
          visibility: visible;
        }
      }
    }

    &.selected {
      color: var(--site-theme-color);
      span {
        flex-grow: 1;
      }
    }
  }
}
