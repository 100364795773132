@import '~components/HelpCenterSettings/HelpCenterSettings';
@import '~theme/mixins';

.widgetTab {
  width: 100%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  font-family: $fontBaseV2;
  padding: 0px 45px 30px 40px;

  @include breakpoint-sm {
    padding: 0 24px;
    max-width: 100%;
  }
}

.activeWidget {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
  min-width: 237px;
  padding-top: 28px;

  &.disabled {
    opacity: 0.5;

    .checkbox {
      pointer-events: none;
    }
  }

  @include breakpoint-sm {
    min-width: 100%;
    position: relative;
  }
}

.tooltip {
  width: 100%;
  background: #f2f2f2;
  padding: 16px;
  font-size: 15px;
  margin-top: 10px;
  line-height: 136%;

  @include breakpoint-sm {
    width: 100%;
  }
}

.form {
  width: 100%;
  margin-top: 10px;
  opacity: 0.4;
  pointer-events: none;

  &.activated {
    opacity: 1;
    pointer-events: auto;
  }

  @include breakpoint-sm {
    margin-top: 24px;
  }
}
