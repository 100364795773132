@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  .section1 {
    height: calc(100vh - 48px);
    min-height: 600px;
    display: flex;
    max-width: $maxWidthContent;
    margin: 0 auto;

    .section1__left_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        overflow: hidden;
        background-image: url('~assets/img/thesquare/bubbles.png');
        z-index: -1;
      }
    }

    .section1__title {
      margin-top: 150px;
      margin-bottom: 250px;
      padding-left: 111px;
      width: 100%;

      h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        color: black;
        margin: 0;
      }
    }

    .section1__linkbtn {
      width: 276px;
      height: 48px;
      background: #000;
      color: #fff;
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      transition: background-color 0.3s ease;
      margin-bottom: 77px;

      &:hover {
        color: #fff;
        background-color: var(--site-theme-color);
      }
    }

    .section1__nextbtn {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      margin-top: auto;
      margin-bottom: 105px;

      p {
        font-weight: 600;
        font-size: 15px;
        line-height: 136%;
        color: #000000;
        margin-bottom: 12px;
      }

      &:hover {
        p {
          color: var(--site-theme-color);
        }

        svg path {
          stroke: var(--site-theme-color);
        }
      }
    }

    .section1__right_content {
      display: flex;
      align-items: flex-end;
    }

    .section1__video_container {
      position: relative;
      box-sizing: content-box;
      width: 673px;
      height: 381px;
    }

    .section1__video_placeholder {
      width: 100%;
      height: 100%;
      cursor: pointer;

      .section1__play_icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;

        svg path {
          transition: fill 0.3s ease;
        }
      }

      img {
        max-width: 673px;
        width: 100%;
        height: 100%;
      }

      .section1__duration {
        position: absolute;
        right: 16px;
        bottom: 16px;
        background: rgba($color: #000000, $alpha: 0.3);
        font-weight: 600;
        font-size: 21px;
        line-height: 116%;
        color: #ffffff;
        padding: 0 4px;
      }

      &:hover {
        .section1__play_icon svg path {
          fill: var(--site-theme-color);
        }
      }
    }

    .section1__video_popup {
      display: none;
      position: fixed;
      z-index: 40;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 86px 0;
      background-color: #000;
      z-index: #{$embedButtonZIndex + 1};

      &.isShow {
        display: block;
      }

      .section1__video_content {
        height: 100%;
      }

      .section1__video_closebtn {
        position: absolute;
        right: 64px;
        top: 24px;
        cursor: pointer;
      }
    }
  }

  .answers_tag {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: black;
    margin: 0 auto;
    padding: 145px 64px 0;
    max-width: $maxWidthContent;

    p {
      max-width: 791px;
      margin-bottom: 24px;
    }
  }

  .asking_questions {
    margin: 208px auto 0;
    padding: 0 64px;
    max-width: $maxWidthContent;
    display: flex;
    position: relative;

    .asking_questions__left {
      h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        color: black;
        margin: 0;
        margin-bottom: 259px;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        max-width: 804px;
      }
    }

    .asking_questions__right {
      display: flex;
      position: absolute;
      right: 103px;
      width: 537px;
      flex-shrink: 0;

      .asking_questions__open_tag {
        display: flex;
        flex-direction: column;

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          padding: 2px 8px;
          background: #ffe3e3;
          margin-bottom: 4px;
          align-self: flex-start;
        }
      }

      .asking_questions__charactor {
        font-family: 'iorad';
        font-weight: 400;
        font-size: 680px;
        line-height: 680px;
        color: black;
        position: absolute;
        right: 0;
        top: -142px;
      }
    }
  }

  .open_question {
    background: #f4f4f4;
    padding-top: 53px;
    padding-bottom: 50px;
    margin-top: 89px;

    .open_question__content {
      margin: 0 auto;
      padding: 0 64px;
      max-width: $maxWidthContent;
      position: relative;
    }

    .open_question__image {
      position: absolute;
      left: 98px;
      top: 0;
      z-index: 1;
      max-width: 1176px;
    }

    .open_question__image_blur {
      margin-top: 90px;
      margin-bottom: 59px;
      max-width: 1176px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      max-width: 804px;
    }
  }

  .answering_questions {
    margin: 196px auto 0;
    padding: 0 64px;
    max-width: $maxWidthContent;
    display: flex;
    position: relative;

    .answering_questions__left {
      h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        color: black;
        margin: 0;
        margin-bottom: 259px;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        max-width: 804px;
      }
    }

    .answering_questions__right {
      display: flex;
      position: absolute;
      right: 64px;
      top: -40px;
      width: 498px;
      height: 100%;
      flex-shrink: 0;

      .answering_questions__right_content {
        position: relative;
        width: 100%;
      }

      .answering_questions__pending_tag_left,
      .answering_questions__pending_tag_right {
        display: flex;
        flex-direction: column;

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          padding: 2px 8px;
          background: rgba(246, 192, 0, 0.4);
          margin-bottom: 7px;
          align-self: flex-start;
        }
      }

      .answering_questions__pending_tag_left {
        position: absolute;
        top: 100px;
        left: 0;
      }

      .answering_questions__pending_tag_top {
        position: absolute;
        top: 0;
        right: 125px;
      }

      .answering_questions__pending_tag_right {
        position: absolute;
        right: 0;
        top: 79px;
      }

      .answering_questions__charactor {
        font-family: 'iorad';
        font-weight: 400;
        font-size: 680px;
        line-height: 680px;
        color: black;
        position: absolute;
        right: 19px;
        top: -74px;
      }
    }
  }

  .pending_question {
    background-image: url('~assets/img/redesign2021/global/gradient_grey_bottom.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    padding-bottom: 96px;
    margin-top: 153px;

    .pending_question__content {
      margin: 0 auto;
      padding: 0 64px;
      max-width: $maxWidthContent;

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        max-width: 804px;
        margin-top: 58px;
        margin-bottom: 0;
      }

      img {
        max-width: 1207px;
      }
    }
  }

  .verified_questions {
    margin: 197px auto 0;
    padding: 0 64px;
    max-width: $maxWidthContent;

    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: black;
      margin: 0;
      margin-bottom: 32px;
    }

    .verified_questions__content {
      display: flex;
      align-items: center;
      height: 450px;
    }

    .verified_questions__content_left {
      position: relative;
      width: 353px;
      height: 100%;
      flex-shrink: 0;
    }

    .verified_questions__content_left_charactor {
      font-family: 'iorad';
      font-weight: 400;
      font-size: 680px;
      line-height: 680px;
      color: black;
      position: absolute;
      left: -9px;
      top: -140px;
    }

    .verified_questions__content_left_icon {
      position: absolute;
      right: -100px;
      top: 0;
      display: flex;

      span {
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        color: #a5cd50;
        margin-top: -10px;
        margin-left: 10px;
      }
    }

    .verified_questions__content_desc {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      max-width: 493px;
      margin: 0;
      margin-left: 37px;
      margin-top: 19px;
    }

    .verified_questions__content_right {
      position: relative;
      width: 325px;
      height: 100%;
      flex-shrink: 0;
      margin-right: 19px;
    }

    .verified_questions__content_right_tag {
      background: rgba(144, 211, 3, 0.6);
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      padding: 2px 8px;
      color: black;
      position: absolute;
      left: 16px;
      top: 76px;
    }

    .verified_questions__content_right_charactor {
      font-family: 'iorad';
      font-weight: 400;
      font-size: 680px;
      line-height: 680px;
      color: black;
      position: absolute;
      right: 0;
      top: -137px;
    }

    .verified_questions__content_right_icon {
      position: absolute;
      right: 20px;
      top: -70px;
    }
  }

  .ioradical_profile {
    background-image: url('~assets/img/redesign2021/global/gradient_grey_bottom.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    padding-bottom: 65px;
    margin-top: 102px;

    .ioradical_profile__content {
      margin: 0 auto;
      padding: 0 64px;
      max-width: $maxWidthContent;

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        max-width: 804px;
        margin-top: 55px;
        margin-bottom: 0;

        a {
          font-weight: 600;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      img {
        max-width: 1207px;
      }
    }
  }

  .spacer_bg {
    background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    height: 204px;
  }

  .quick_start {
    background: #f4f4f4;

    .quick_start__content {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 18px 143px 54px 64px;
    }

    .quick_start__title {
      font-weight: 400;
      font-size: 200px;
      line-height: 200px;
      font-family: 'iorad';
      color: black;
      margin: 0;
    }

    .quick_start__desc {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 49px;

      p {
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin: 0;
        max-width: 437px;
      }
    }

    .quick_start__btn {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      background: #ffffff;
      width: 276px;
      height: 48px;
      transition: all 0.3s ease;

      &:hover {
        color: #ffffff;
        background-color: var(--site-theme-color);
      }
    }
  }
  .footer {
    background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 195px;
    padding-bottom: 142px;

    .footer__container {
      max-width: $maxWidthContent;
      margin: 0 auto;
    }

    .footer__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 64px;
      padding-right: 143px;
    }

    .footer__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: black;
      max-width: 556px;
      margin: 0;
    }

    .footer__signup_btn {
      border: none;
      outline: none;
      width: 276px;
      height: 48px;
      background: #000;
      color: #fff;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease;

      &:hover {
        color: #fff;
        background-color: var(--site-theme-color);
      }
    }
  }

  @include breakpoint-sm {
    .section1 {
      flex-direction: column;
      max-width: 100%;
      margin-top: 48px;

      .section1__left_content {
        align-items: flex-start;
        flex-grow: 0;
      }

      .section1__title {
        margin: 0;
        padding-top: 159px;
        padding-left: 0;
        padding-bottom: 143px;

        h3 {
          font-size: 21px;
          line-height: 29px;
          text-align: center;
        }
      }

      .section1__linkbtn {
        display: none;
      }

      .section1__nextbtn {
        position: absolute;
        top: calc(100% + 230px);
        left: 50%;
        transform: translateX(-50%);

        p {
          font-size: 13px;
          margin-bottom: 10px;
        }
      }

      .section1__video_container {
        width: 100%;
        height: 212px;
      }

      .section1__play_icon svg {
        width: 40px;
        height: 40px;
      }

      .section1__video_placeholder .section1__duration {
        right: 9px;
        bottom: 9px;
        font-size: 11.684px;
        padding: 0 2px;
      }

      .section1__video_popup {
        padding: 0;

        .section1__video_closebtn {
          right: 24px;
        }
      }

      .section1__video_content {
        display: flex;

        iframe {
          max-height: 211px;
          margin: auto 0;
        }
      }
    }

    .answers_tag {
      padding: 125px 24px 0;
      max-width: 100%;
      font-size: 13px;
      line-height: 18px;

      p {
        max-width: 100%;
        margin-bottom: 19px;
      }
    }

    .asking_questions {
      flex-direction: column;
      margin-top: 150px;
      padding: 0 24px;

      .asking_questions__left {
        h3 {
          font-size: 21px;
          line-height: 24px;
          margin-bottom: 292px;
        }

        p {
          font-size: 13px;
          line-height: 18px;
          max-width: 100%;
        }
      }

      .asking_questions__right {
        left: 24px;
        top: 67px;
        width: 267px;

        .asking_questions__open_tag {
          span {
            font-size: 13px;
            padding: 0 5px;
            margin-bottom: 2px;
          }

          svg {
            width: 90px;
            height: 85px;
          }
        }
        .asking_questions__charactor {
          font-size: 334px;
          line-height: 334px;
          top: -67px;
        }
      }
    }

    .open_question {
      margin-top: 28px;
      padding-top: 29px;
      padding-bottom: 66px;

      p {
        font-size: 13px;
        line-height: 18px;
        max-width: 100%;
      }

      .open_question__content {
        padding: 0 24px;
        max-width: 100%;
      }

      .open_question__image {
        left: 24px;
        max-width: calc(100% - 65px);
      }

      .open_question__image_blur {
        margin-top: 79px;
        margin-left: 17px;
        margin-bottom: 36px;
        max-width: calc(100% - 17px);
      }
    }

    .answering_questions {
      margin-top: 64px;
      padding: 0 24px;
      max-width: 100%;

      .answering_questions__left {
        h3 {
          font-size: 21px;
          line-height: 28px;
          margin-bottom: 315px;
        }

        p {
          font-size: 13px;
          line-height: 18px;
          max-width: 100%;
        }
      }

      .answering_questions__right {
        left: 24px;
        top: 69px;
        width: calc(100% - 48px);

        .answering_questions__charactor {
          font-size: 334px;
          line-height: 334px;
          left: 81px;
          top: -18px;
        }

        .answering_questions__pending_tag_left {
          top: 48px;

          span {
            font-size: 13px;
            line-height: 20px;
            padding: 0px 6px;
          }

          svg {
            width: 105px;
            height: 100px;
          }
        }
        .answering_questions__pending_tag_top {
          left: 176px;

          svg {
            width: 36px;
            height: 34px;
          }
        }

        .answering_questions__pending_tag_right {
          top: 40px;
          left: 223px;

          span {
            font-size: 13px;
            line-height: 20px;
            padding: 0px 6px;
          }

          svg {
            width: 53px;
            height: 50px;
          }
        }
      }
    }

    .pending_question {
      margin-top: 27px;
      padding-bottom: 38px;
      background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_bottom.png');
      background-size: contain;

      .pending_question__content {
        max-width: 100%;
        padding: 0 24px;

        p {
          font-size: 13px;
          line-height: 18px;
          margin-top: 45px;
          max-width: 100%;
        }

        img {
          max-width: 100%;
        }
      }
    }

    .verified_questions {
      margin-top: 64px;
      padding: 0 24px;
      max-width: 100%;

      h3 {
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 36px;
      }

      .verified_questions__content {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
      }

      .verified_questions__content_left {
        height: 230px;
        width: 196px;
      }

      .verified_questions__content_left_charactor {
        font-size: 334px;
        line-height: 334px;
        top: -74px;
        left: -10px;
      }

      .verified_questions__content_left_icon {
        right: 0;

        svg {
          width: 38px;
          height: 33px;
        }

        span {
          font-size: 15px;
          line-height: 20px;
          margin-top: -4px;
          margin-left: 6px;
        }
      }

      .verified_questions__content_desc {
        font-size: 13px;
        line-height: 18px;
        margin-top: 24px;
        margin-left: 0;
        max-width: 100%;
      }

      .verified_questions__content_right {
        height: 220px;
        width: 179px;
        margin-right: 32px;
        margin-left: auto;
      }

      .verified_questions__content_right_tag {
        font-size: 13px;
        line-height: 20px;
        padding: 0 6px;
        top: 34px;
        left: 0;
      }

      .verified_questions__content_right_charactor {
        font-size: 334px;
        line-height: 334px;
        top: -57px;
      }

      .verified_questions__content_right_icon {
        top: -14px;

        svg {
          width: 68px;
          height: 68px;
        }
      }
    }

    .ioradical_profile {
      margin-top: 13px;
      background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_bottom.png');
      background-size: contain;

      .ioradical_profile__content {
        padding: 0 24px;

        p {
          font-size: 13px;
          line-height: 18px;
          margin-top: 46px;
          max-width: 100%;
        }

        img {
          max-width: 100%;
        }
      }
    }

    .spacer_bg {
      display: none;
    }

    .quick_start {
      .quick_start__content {
        padding: 124px 24px 69px;
      }

      .quick_start__desc {
        flex-direction: column;
        align-items: center;
        margin-top: 41px;

        p {
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 73px;
        }
      }

      .quick_start__btn {
        width: 295px;
        font-size: 13px;
      }
    }

    .footer {
      background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
      background-size: 100%;
      padding-top: 161px;

      .footer__container {
        max-width: 100%;
      }

      .footer__content {
        padding: 0 24px;
        flex-direction: column;
      }

      .footer__title {
        font-size: 21px;
        line-height: 28px;
        max-width: 100%;
      }

      .footer__signup_btn {
        width: 295px;
        font-size: 13px;
        margin-top: 71px;
        margin-bottom: 0;
      }
    }
  }
}
