@import '~theme/mixins';
@import '~theme/modules/variables';

@keyframes dots {
  to {
    width: 1em;
  }
}

.translation_modal_container {
  overflow: hidden;

  :global(.popup-content) {
    overflow: hidden;
    padding: 0;
    height: 100%;
  }
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  align-items: center;

  button[disabled] {
    opacity: 0.7;
    pointer-events: none;
  }

  svg {
    color: inherit;

    [fill] {
      color: inherit;
      fill: currentColor;
    }

    [stroke] {
      color: inherit;
      stroke: currentColor;
    }
  }

  &.loading {
    &:after {
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.5);
      pointer-events: none;
    }
  }
}

.box {
  height: 52px;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  background: #f4f4f4;
  margin: 0 auto;
  padding: 16px;
}

.searchColumn, .group {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.searchColumn {
  background: white;
  position: sticky;
  top: 0;
  z-index: 4;
  padding-top: 24px;
  margin-bottom: 24px;

  .searchField {
    width: 100%;
    margin: 0;

    &:focus {
      border-bottom: 1px solid #000000;
    }

    @include breakpoint-sm {
      input {
        padding-left: 56px !important;
        padding-right: 0 !important;
      }

      :global(.input_search-icon) {
        left: 24px !important;
      }
    }
  }
}

.groupContainer {
  position: relative;
  flex-grow: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.group {
  z-index: 1;
  background: #FFF;

  // &:not(.collapsed) {
  //   flex-grow: 1;
  // }

  &.collapsed {
    .translationList {
      display: none;
    }
  }
}


.underlay {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: alias;
  background-color: rgba(255, 255, 255, 0.02);
}

.heading {
  --top: 56px;
  position: sticky;
  top: var(--top);
  z-index: 2;

  &:nth-child(0n+1 of .heading) {
    top: var(--top);
  }
  &:nth-child(0n+2 of .heading) {
    top: calc(var(--top) * 2);
  }
  &:nth-child(0n+3 of .heading) {
    top: calc(var(--top) * 3);
  }
  &:nth-child(0n+4 of .heading) {
    top: calc(var(--top) * 4);
  }
  &:nth-child(0n+5 of .heading) {
    top: calc(var(--top) * 5);
  }

  &.bottom {
    top: auto;
    bottom: 0;
  }

  &.ontop {
    z-index: 3;
  }

  &.collapsed {
    .tool, & + .group .translationList {
      display: none;
    }
  }

  .inner {
    max-width: 600px;
    width: 100%;
    height: 56px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.05);
  }

  h3 {
    font-size: 21px;
    flex-grow: 1;
    margin: 0;
    color: #000;
    font-weight: 400;
    margin-left: 16px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }

  b {
    margin-right: 16px;
    cursor: pointer;
  }

  .tool {
    position: relative;
    height: 100%;
    align-items: center;
    display: flex;
  }

  .toolIcon {
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    display: inline-flex;
    width: 32px;
    justify-content: center;
    align-items: center;

    &:hover,
    &.active {
      color: #000;
    }
  }

  .toolContent {
    position: absolute;
    top: 100%;
    width: 100vw;
    max-width: 320px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #FFF;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 4;

    button {
      padding: 16px;
      border: 0 none;
      gap: 16px;
      display: flex;
      align-items: center;
      background-color: #FFF;
      color: #373737;

      &:hover,
      &:focus {
        outline: 0 none;
        color: #000;
      }
    }
  }
}

.translationList {
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  min-height: 90px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    margin: 0;
    height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-top: 0 none;
    position: relative;

    date {
      position: absolute;
      left: 220px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      color: #999;
      font-weight: 300;
      font-size: 13px;
    }

    a {
      font-size: 15px;
      font-family: $fontBaseV2;
      // color: #999999;
      color: #000;
      font-weight: 300;
    }

    b {
      margin-right: 16px;
    }

    &.default a {
      color: #000;
    }

    &.default .mutedText {
      color: #000;
    }

    &.inactive {
      color: inherit;
    }

    span {
      color: #999;
      padding: 0 16px;
    }

    &:hover {
      background: #f2f2f2;

      a {
        color: #000;
      }

      .hoverHidden {
        display: none;
      }

      @include breakpoint-sm {
        date {
          display: none;
        }
      }
    }

    &:not(:hover) {
      .actions:not(.generating) {
        display: none;
      }
    }
  }
}

.translationOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;

  &.disabled {
    pointer-events: none;
    opacity: 0.75;
  }

  .translationOptions_checkboxs {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 5px;

    label {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-family: 'Libre Franklin', sans-serif;
      color: #000000;
      font-weight: 300;
      margin: 0;
      padding: 16px;
    }

    input[type='checkbox'] {
      margin-right: 10px;
      margin-top: 0;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;

  &.hidden {
    display: none;
  }

  .actionButton {
    color: #999999;
    // font-size: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    height: 48px;

    &:hover {
      color: #000000;
    }

    &.isDisabled,
    &.isLoading {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.dot {
  position: relative;
  color: #999;

  &:after {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    content: '\2026';
    width: 0px;
    animation: dots 1s steps(4, end) infinite;
  }
}