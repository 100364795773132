@import '~theme/modules/variables';
@import '~theme/mixins';

.proofSection {
  margin-top: 110px;
  background: #f4f4f4;

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 52px 64px 0;

    .title {
      @include ioradFont;
    }

    .desc {
      width: 100%;
      max-width: 618px;
      margin: 0;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin-top: 83px;
    }
  }

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 32px 64px 110px;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    .blockTitle {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      margin-bottom: 8px;
    }

    .logoList {
      display: grid;
      grid-template-columns: 198px 198px;
      grid-template-rows: 115px 115px 115px;
      gap: 5px;

      @media screen and (max-width: 1264px) {
        grid-template-columns: 14vw 14vw;
        grid-template-rows: 10vh 10vh 10vh;
      }
    }

    .logoItem {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;

      &:hover,
      &.active {
        background: transparent;
      }
    }

    .card {
      padding-top: 24px;
    }

    .headerCard {
      display: flex;
      min-height: 90px;

      img {
        width: 41px;
        height: 41px;
        filter: grayscale(1);
        object-fit: cover;
        object-position: top;
        margin-top: 4px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      color: black;
      width: 253px;
      margin-left: 22px;

      @media screen and (max-width: 1075px) {
        width: 100%;
      }
    }

    .position {
      font-size: 15px;
      line-height: 136%;
      margin-bottom: 0;
      font-weight: normal;
    }

    .name {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      margin: 0;
    }

    .contentCard {
      width: 344px;
      margin-top: 30px;
      font-size: 15px;
      line-height: 136%;
      color: black;

      @media screen and (max-width: 1075px) {
        width: 100%;
      }
    }
  }
}

@include breakpoint-sm {
  .proofSection {
    padding-top: 27px;
    padding-bottom: 24px;

    .titleWrapper {
      padding: 0;
      margin-bottom: 57px;

      .title_img {
        width: 100%;
      }

      .desc {
        max-width: 100%;
        font-size: 13px;
        line-height: 18px;
        margin-top: 31px;
        padding: 0 24px;
      }
    }

    .contentWrapper {
      flex-direction: column;
      padding: 0 24px;
      gap: 0;

      .blockTitle {
        font-size: 13px;
        margin-bottom: 21px;
      }

      .logoList {
        grid-template-columns: 162px 162px;
        grid-template-rows: 94px 94px 94px;
        gap: 4px;
      }

      .card {
        padding-top: 48px;
        padding-bottom: 67px;

        &:last-child {
          padding-bottom: 0;
        }
      }

      .headerCard {
        min-height: 56px;

        img {
          width: 56px;
          height: 56px;
          margin-top: 0;
        }
      }

      .info {
        width: 100%;
        margin-left: 16px;
        justify-content: space-between;
      }

      .position {
        font-size: 13px;
      }

      .name {
        font-size: 13px;
        line-height: 100%;
      }

      .contentCard {
        width: 100%;
        margin-top: 16px;
        font-size: 13px;
      }
    }
  }
}
