.accountModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .accountModal_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .accountModal_content {
    position: relative;
    z-index: 1;
    background: white;
    width: 752px;
    max-height: calc(100% - 48px);
    color: black;
    padding: 56px 56px 0;
    overflow-y: auto;

    .accountModal_content_title {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 56px;
    }

    .accountModal_content_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      margin-top: 40px;
      padding: 8px;
      position: sticky;
      bottom: 0;
      background: white;
      z-index: 1;
    }
  }
}

.changePasswordForm {
  display: flex;
  flex-direction: column;

  .formGroup {
    display: flex;
  }

  .formGroupLabel {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    height: 56px;
    opacity: 0.5;
  }

  .formGroupInput {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 320px;

    &.error {
      input {
        border-color: var(--site-theme-color);
      }
    }

    input {
      width: 100%;
      height: 56px;
      background-color: transparent;
      padding: 0;
      outline: none;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 15px;
      line-height: 20px;

      &:focus {
        border-bottom-color: black;
      }
    }
  }

  .formGroupError {
    margin-top: 5px;
    font-size: 15px;
    line-height: 20px;
    color: var(--site-theme-color);
  }

  .formGroupInputWrapper {
    position: relative;

    .generatePassword {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      height: calc(100% - 1px);
      padding-left: 16px;
      background: white;
      bottom: 1px;
      z-index: 1;
      font-size: 15px;
      line-height: 20px;
    }
  }

  .formActions {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 56px;
    padding-top: 16px;
    padding-bottom: 56px;
  }
}

.manage2FAForm {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .formGroup {
    display: flex;
    column-gap: 16px;

    &:hover {
      .formHelp {
        opacity: 1;
      }
    }
  }

  .formGroupInput {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 320px;

    input {
      width: 100%;
      height: 56px;
      background-color: transparent;
      padding: 0;
      outline: none;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 15px;
      line-height: 20px;

      &:focus {
        border-bottom-color: black;
      }
    }
  }

  .formGroupRadio {
    display: flex;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    position: relative;

    input:checked ~ span::before {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    input:checked ~ span::after {
      display: block;
      width: 8px;
      height: 8px;
      background-color: #000000;
      left: 4px;
      top: 5px;
    }

    & > span {
      margin-left: 28px;
      flex: 1;

      &::before, &::after {
        display: block;
        position: absolute;
        left: 0;
        top: 1px;
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background-color: #F2F2F2;
      }

      &::after {
        display: none;
      }
    }

    input {
      display: none;
    }
  }

  .formGroupInputWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 8px;
  }

  .formInputPhoneNumber {
    position: relative;

    :global(.react-tel-input) {
      :global(.form-control) {
        padding: 0;
        padding-left: 56px;
        font-size: 15px;
        line-height: 20px;
        color: black;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0;
        height: 56px;
        width: 100%;
        box-shadow: none;

        &:focus {
          border-bottom-color: black;
        }
      }
      :global(.flag-dropdown) {
        background: transparent;
        border-radius: 0;
        border: none;
        bottom: 1px;
      }
      :global(.selected-flag) {
        width: 40px;
        border-radius: 0;
      }
    }

    .resetPhoneNumberBtn {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      height: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .confirmCodeInput {
    position: relative;
    flex-grow: 1;
    max-width: 312px;

    .confirmedText {
      font-size: 15px;
      line-height: 40px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .formHelp {
    font-size: 15px;
    line-height: 20px;
    opacity: 0.5;
    flex: 1;
  }

  .formActions {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 56px;
    padding-top: 16px;
    padding-bottom: 56px;
  }
}

.exportDataContent {
  display: flex;
  flex-direction: column;
  position: relative;

  .exportList {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
  
    .exportItem {
      display: flex;
      align-items: center;
      column-gap: 4px;
      font-size: 15px;
      line-height: 20px;
      padding: 16px 24px;
      cursor: pointer;
      color: inherit;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
      }

      .exportItemTitle {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        label {
          font-weight: 400;
          margin: 0;
        }

        p {
          margin: 0;
          margin-top: 4px;
          color: rgba(0, 0, 0, 0.5);

          a {
            text-decoration: underline;
            color: inherit;

            &:hover {
              color: black;
            }
          }
        }
      }

      .exportItemIcon {
        display: flex;
        align-items: center;
      }
    }
  }
}

.downloadProgress {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);

  .downloadProgress_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    background: white;
    padding: 24px;

    h5 {
      margin: 0;
    }
  }
}

.billing {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: black;

  .billing_tab_disabled {
    font-size: 15px;
    line-height: 20px;
  }

  .billingInfo {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }

  .invoiceHistory {
    display: flex;
    flex-direction: column;

    .invoiceHistory_title {
      display: flex;
      align-items: center;
      column-gap: 16px;
      cursor: pointer;
      padding: 18px 0;
      transition: color 0.3s ease;

      &:hover {
        color: var(--site-theme-color);
      }

      &.open {
        svg {
          transform: rotate(180deg);
        }
      }

      span {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
      }

      svg path {
        fill: currentColor;
      }
    }

    .invoiceHistory_list {
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-top: 24px;
    }

    .invoiceHistory_item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      column-gap: 24px;
      height: 56px;
      padding: 0 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 15px;
      line-height: 20px;

      &:last-child {
        border-bottom: none;
      }

      .invoiceHistory_item_refunded {
        font-weight: 600;
      }

      .invoiceHistory_item_download {
        display: flex;
        align-items: center;
        column-gap: 24px;
        margin-left: auto;

        span {
          font-weight: 600;
          cursor: pointer;
          transition: color 0.3s ease;

          &:hover {
            color: var(--site-theme-color);
          }
        }
      }
    }
  }
}

.deactivateAccountForm {
  display: flex;
  flex-direction: column;

  .deactivateAccountForm_description {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 56px;
    
    a {
      text-decoration: underline;
    }
  }

  .formGroup {
    display: flex;
  }

  .formGroupLabel {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
    height: 56px;
  }

  .formGroupInput {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 320px;

    input {
      width: 100%;
      height: 56px;
      background-color: transparent;
      padding: 0;
      outline: none;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 15px;
      line-height: 20px;

      &:focus {
        border-bottom-color: black;
      }
    }
  }

  .formGroupError {
    margin-top: 5px;
    font-size: 15px;
    line-height: 20px;
    color: var(--site-theme-color);
  }

  .formActions {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 56px;
    padding-top: 16px;
    padding-bottom: 56px;
  }
}
