@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  background: url('~assets/img/redesign2021/partnerpage/partner_programs_bg.png');
  background-position: bottom;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  padding-bottom: 72px;
  margin-bottom: 91px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 355px);
    background-color: #77c082;
    z-index: 0;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    position: relative;
  }

  .title {
    padding: 52px 64px 134px;
  }

  .titleContent {
    @include ioradFont;
  }

  .list {
    padding: 0 64px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .item {
    background: white;
    width: 295px;
    min-height: 422px;
    padding: 24px 32px 12px;
    display: flex;
    flex-direction: column;

    h4 {
      font-weight: 600;
      font-size: 21px;
      line-height: 28px;
      color: black;
      margin: 0;
      margin-bottom: 26px;
    }

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
      min-height: 80px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-top: 31px;
    }

    a {
      cursor: pointer;
      width: 100%;
      height: 48px;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      transition: color 0.3s ease;
      margin-top: auto;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }
}

@mixin paddingItem {
  padding-left: 29px;
  padding-right: 32px;
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;
    background-image: url('~assets/img/redesign2021/partnerpage/mobile/partner_programs_bg.png');
    background-size: 100% 360px;

    &::before {
      height: calc(100% - 360px);
    }

    .contentWrapper {
      max-width: 100%;
    }

    .title {
      padding: 69px 0 94px;

      img {
        width: 100%;
      }
    }

    .list {
      padding: 0 24px;
      gap: 24px;
    }

    .item {
      width: 100%;
      min-height: 314px;
      padding: 0;

      h4 {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 33px;
        margin-top: 26px;
        @include paddingItem;
      }

      span {
        font-size: 13px;
        line-height: 18px;
        min-height: auto;
        @include paddingItem;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        margin-top: 13px;
        @include paddingItem;
      }

      a {
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}
