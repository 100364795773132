.creditCardForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .card_brand {
    display: flex;
    flex-shrink: 0;
    width: 40px;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .card_number {
    margin-top: auto;
  }

  .exp_cvc {
    display: flex;
    column-gap: 24px;
    margin-top: 20px;
  }

  .card_input {
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: 56px;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    padding: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    font-family: 'Courier New';
    color: black;

    &.error {
      border-bottom-color: var(--site-theme-color);
    }

    &:focus {
      border-bottom-color: black;
    }
  }
}