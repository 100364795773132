@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;

  .titleWrapper {
    background: $backgroundTiles;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 53px 64px 180px;
  }

  .titleContent {
    @include ioradFont;
  }

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 37px auto 151px;
    padding: 0 64px;
  }

  .newsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .newsItem {
    width: 294px;
    display: flex;
    flex-direction: column;

    .newsItemImage {
      height: 147px;
    }

    .newsItemTime {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.4);
      margin-top: 16px;
    }

    .newsItemTitle {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-top: 11px;
    }

    .newsItemDesc {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-top: 6px;
    }
  }
}

.demo_defense {
  max-width: $maxWidthContent;
  margin: 121px auto 151px;
  padding: 0 64px;

  .demo_defense__container {
    max-width: 808px;
    width: 100%;
  }

  .demo_defense__prev_btn {
    display: flex;
    align-items: center;
    column-gap: 15px;

    > * {
      opacity: 0.4;
    }

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }

    &:hover > * {
      opacity: 1;
    }
  }

  .demo_defense__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 37px;
    color: black;
    margin: 0;
    margin-top: 25px;
  }

  .demo_defense__image {
    height: 404px;
    width: 100%;
    margin-top: 62px;
  }

  .demo_defense__time {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 60px;
  }

  .demo_defense__content {
    margin-top: 27px;

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-bottom: 18px;
    }

    h6 {
      font-weight: 600;
      font-size: 21px;
      line-height: 24px;
      color: black;
      margin-top: 71px;
      margin-bottom: 4px;
    }

    a {
      text-decoration: underline;
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    .title {
      padding: 67px 0 300px;
    }

    .contentWrapper {
      max-width: 100%;
      margin-top: 55px;
      margin-bottom: 233px;
      padding: 0 24px;
    }

    .newsItem {
      width: 100%;

      .newsItemImage {
        height: 164px;
        width: 100%;
      }

      .newsItemTime {
        margin-top: 14px;
      }

      .newsItemTitle {
        font-size: 13px;
        line-height: 18px;
        margin-top: 9px;
      }

      .newsItemDesc {
        font-size: 13px;
        line-height: 18px;
        margin-top: 9px;
      }
    }
  }

  .demo_defense {
    max-width: 100%;
    padding: 0 24px;
    margin-top: 22px;
    margin-bottom: 233px;

    .demo_defense__container {
      max-width: 100%;
    }

    .demo_defense__prev_btn {
      span {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .demo_defense__title {
      font-size: 21px;
      line-height: 24px;
      margin-top: 21px;
    }

    .demo_defense__image {
      height: 163px;
      margin-top: 37px;
    }

    .demo_defense__time {
      font-size: 13px;
      line-height: 18px;
      margin-top: 26px;
    }

    .demo_defense__content {
      margin-top: 22px;

      p {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 17px;
      }

      h6 {
        font-size: 13px;
        line-height: 18px;
        margin-top: 21px;
        margin-bottom: 10px;
      }
    }
  }
}
