@import '../../theme/modules/variables';
@import '../../theme/guidelines';
@import '~theme/mixins';

.link_tab {  
  width: 450px;

  @include breakpoint-sm {
    width: 100%;
  }
}
