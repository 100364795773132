@import '~theme/modules/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
  padding-right: 14px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
}

.headerTitle {
  font-family: $fontBaseV2;
  font-size: 15px;
  color: var(--site-theme-color);
  font-weight: bold;
}
