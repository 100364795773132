@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/guidelines.scss';
@import '~theme/modules/variables';

.privateAnswersPopup {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 100;
  background: white;
  width: calc(100vw - 100%);
  height: 100%;
  display: flex;
  flex-direction: column;

  .privateAnswersPopup__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    height: 48px;
    position: relative;

    .privateAnswersPopup__header_closeBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      width: 48px;
      height: 100%;
      background: transparent;
    }
  }

  .privateAnswersPopup__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow-y: auto;
    padding: 40px;
  }

  .privateAnswersPopup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    width: 100%;
    max-width: 900px;
  }

  .privateAnswersPopup__title {
    text-align: center;
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    color: black;
    margin: 0;
  }

  .privateAnswersPopup__desc {
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    color: black;
    margin: 0;
  }

  .privateAnswersPopup__helpBlock {
    padding: 10px;
    background: #f4f4f4;
    font-size: 15px;
    line-height: 20px;
    color: black;
  }

  .privateAnswersPopup__addNewBtn {
    height: 56px;
    width: 246px;
    column-gap: 20px;
  }

  .privateAnswersPopup__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
  }

  .privateAnswersPopup__form_group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .privateAnswersPopup__form_label {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 10px;
    }
    .privateAnswersPopup__form_input {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 15px;
      line-height: 20px;
      color: black;
      height: 56px;
      column-gap: 10px;
      caret-color: var(--site-theme-color);

      &:focus-within {
        border-bottom-color: black;
      }

      input {
        padding: 0;
        margin: 0;
        outline: none;
        border: none;
        flex-grow: 1;
        height: 100%;
      }

      button {
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        outline: none;
        font-weight: 600;
      }
    }
  }

  .privateAnswersPopup__form_buttons {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 40px;

    .privateAnswersPopup__form_submitBtn {
      max-width: 246px;
      column-gap: 20px;
    }

    .privateAnswersPopup__form_cancelBtn {
      max-width: 246px;
      background: #f4f4f4;
      border: none;
    }

    .privateAnswersPopup__data_addBtn {
      height: 56px;
      padding: 0 24px;
    }
  }

  .privateAnswersPopup__data_addBtn {
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    column-gap: 16px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: black;
    height: 40px;
    position: relative;

    svg {
      color: inherit;

      [fill] {
        color: inherit;
        fill: currentColor;
      }

      [stroke] {
        color: inherit;
        stroke: currentColor;
      }
    }

    &:hover {
      color: var(--site-theme-color);
    }

    input[type="file"] {
      opacity: 0;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding-left: 100%;
      cursor: pointer;
    }
  }

  .privateAnswersPopup__data {
    display: flex;
    flex-direction: column;
    width: 100%;

    .privateAnswersPopup__data_header {
      display: flex;
      gap: 24px;
    }

    .privateAnswersPopup__data_content {
      display: flex;
      flex-direction: column;
    }

    .privateAnswersPopup__data_table {
      display: flex;
      flex-direction: column;
    }

    .privateAnswersPopup__data_table_header {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 10px;

      span {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        flex: 1;
      }
    }

    .privateAnswersPopup__data_table_body {
      display: flex;
      flex-direction: column;
    }

    .privateAnswersPopup__data_table_row {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 40px;
      padding: 0 10px;
      position: relative;

      &:hover {
        background: rgba(0, 0, 0, 0.05);

        button {
          visibility: visible;
        }
      }

      span {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: black;
        flex: 1;

        &.privateAnswersPopup__data_table_row_variations {
          color: rgba(0, 0, 0, 0.4);
        }
      }

      button {
        padding: 0;
        background: transparent;
        border: none;
        outline: none;
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        visibility: hidden;
      }
    }
    .privateAnswersPopup__data_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      margin-top: 40px;
    }

    .privateAnswersPopup__data_donetBtn {
      max-width: 246px;
    }
    .privateAnswersPopup__data_clearBtn {
      max-width: 246px;
      background: #f4f4f4;
      border: none;
    }
  }
}

.itemValue {
  input[type="text"] {
    border: 0 none;
    border: 0 none;
    padding: 0;
  }

  span {
    opacity: 0.4;
  }
}
