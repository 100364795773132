@import './mixins';
@import './modules/variables';

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;

  &:focus .label {
    color: #000000;
  }
}

.label {
  font-weight: normal;
  color: #999999;
  font-size: 10px;
  margin-bottom: 3px;
  font-family: $fontBaseV2;
}

.input {
  font-family: $fontBaseV2;
  width: 100%;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  padding: 0;
  height: 35px;
  font-size: 15px;
  font-weight: normal;

  &:active,
  &:focus {
    color: #000000;
    border-bottom: 1px solid #000000;
  }
}

.copyContainer {
  display: flex;
  align-items: center;
  position: relative;

  .input {
    padding-right: 40px;
  }

  &.morePadding {
    .input {
      padding-right: 60px;
    }
  }
}

.linkContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;

  .link {
    margin-right: 10px;
    color: #999999;
    font-size: 10px;
    cursor: pointer;
    font-family: $fontBaseV2;
    transition: color 0.3s ease;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #000;
    }
  }
}

.options .checkbox {
  margin-bottom: 10px;
}

.checkbox {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000000;
  font-weight: 300;
  font-family: $fontBaseV2;
  padding-left: 25px;
  padding-top: 2px;

  input[type='checkbox']:checked ~ span::after {
    content: '';
    background-image: url('../assets/img/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
  }

  input[type='checkbox'] {
    position: absolute;
    cursor: pointer;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    bottom: 3px;
    left: 0;
    margin: 0;
    opacity: 0;
    top: 4px;
    z-index: 1;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  span {
    word-break: break-all;
    white-space: normal;
  }

  span:after {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    content: '';
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1px;
  }
}
