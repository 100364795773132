@import '~theme/guidelines_variables';
@import '~theme/mixins';
@import '~theme/modules/variables';

.wrapper {
  padding-left: 4px;
  padding-right: 4px;
  font-size: .9em;
  padding-bottom: 20px;

  .greyText {
    color: $dark_grey;
  }

  .blackText {
    color: $black;
  }

  .loading {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 12px;
    top: 8px;
    background: url('~assets/img/animations/iorad_animated_logo_grayscale.gif') center center;
    background-size: contain;
  }

  .table {
    margin-top: 0;
    margin-bottom: 15px;
    border-width: 0;
    border-bottom-width: 1px;
  }

  .textInput {
    height: 36px;
    border-radius: 0;
    padding-right: 8px;
    padding-left: 8px;
    border: 0;
    border-bottom: 1px;
    font-size: 12px;

    &:active {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .tableAction {
    width: 120px;
    white-space: nowrap;
  }

  .hidden {
    position: absolute;
    left: -99999px;
  }

  .pagination {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ioradCreate {
    padding-left: 35px;
    padding-right: 15px;
    position: relative;
    color: inherit !important;
    margin-left: 6px;

    &:before, &:after {
      content: '';
      position: absolute;
      left: 20px;
      width: 2px;
      height: 13px;
      top: 50%;
      transform: translate(0, -50%);
      background-color: #000;
      transition: background-color 0.1s linear;
    }
    &:after {
      transform: translate(0, -50%) rotate(90deg);
    }
  }
}
