// Category and tag lists have lots of styles in common
// Sharing them here to prevent double-code copy-pasting
$headerHeight: 36px;
$otherHeadersNumber: 3; // draft, archived, trash
@mixin listBlock() {
  min-height: $headerHeight;
  display: block;
  height: 45%;
  max-height: calc(100% - #{$headerHeight} * (#{$otherHeadersNumber} + 1)); // gap for possible headers (+1 for tags/categories)
  display: flex;
  flex-direction: column;

  .headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $headerHeight;
  }

  &.isExpanding {
    height: auto;
  }

  &:not(:global(.animation-active)):not(.isExpanding):not(.splitMode) {
    .content, .searchBox, .otherContent {
      display: none;
    }
  }

  &.splitMode {
    // don't update max-height until animation is complete
    &:not(:global(.animation-pending)):not(:global(.animation-active)) {
      height: auto;
      max-height: calc(50% - (#{$headerHeight} * #{$otherHeadersNumber} / 2)); // categories and tags will share the gap (devide it by 2)
    }
  }

  &.isMinimize {
    height: min-content;

    .header {
      height: $headerHeight;
    }
  }

  &:hover {
    .headerIcon {
      visibility: visible;
      pointer-events: all;
    }
  }
}

@mixin tagName() {
  font-size: 15px;
  font-family: $fontBaseV2;
  word-break: break-word;
  line-height: 136%;
  max-width: 188px;
  flex-grow: 1;
  color: black;

  &.highlight {
    color: var(--site-theme-color);
  }

  &.isEditing {
    max-width: 155px;
  }
}
