@import '~theme/guidelines_variables';
@import '~theme/mixins';
@import '~components/NewPricing/partials';
@import '~components/Tab/styles';
@import '~theme/link_underline';
@import '~theme/modules/variables';

$black: #000000;
$mobileWidth: 970px;
$floatingNavHeight: 52px;
$floatingNavHeightMobile: 40px;
$floatingNavMaxWidth: 1030px;
$sidebarWidth: 224px;
$fontBase: 'Libre Franklin', sans-serif;
$fontTitle: 'Fahkwang', sans-serif;

.planTabsNav {
  @include breakpoint-sm {
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &::before {
    content: none;
  }

  .planTab {
    width: max-content;
    margin-right: 58px;

    &:hover {
      a {
        color: #ffffff;
      }
    }

    @include breakpoint-sm {
      margin-right: 0 !important;
    }

    a {
      font-weight: 600;
      font-size: 21px;
      line-height: 116%;
      padding: 0;
      border-bottom: none;
      color: #000000;
      font-weight: 600;

      @include breakpoint-sm {
        font-size: 13px;
      }
    }

    .planTabActive {
      color: #ffffff;
    }
  }
}

.planBoxWrapper {
  display: flex;
  gap: 19px;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 18px;

  @include breakpoint-sm {
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
  }
}

.checkoutFaqContainer {
  display: flex;
  flex-direction: column;
  background-image: url('~assets/img/redesign2021/global/gradient_grey_bottom.png');
  background-position: top center;
  background-repeat: no-repeat;
  padding: 240px 0 48px;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 354px);
    background-color: #f4f4f4;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -115px;
    width: 100%;
    height: 115px;
    background-image: url('~assets/img/redesign2021/global/gradient_grey_small_180.png');
    background-position: top center;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .checkoutContent {
    position: relative;
    z-index: 2;
    width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;

    @media screen and (max-width: 1380px) {
      width: 100%;
    }
  }

  .checkoutFormWrapper {
    width: 1020px;
    margin: 0 auto;
    padding-left: 22px;

    @media screen and (max-width: 1100px) {
      width: 100%;
    }
  }

  .pageTitle {
    margin-bottom: 21px;
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000;
  }

  @include breakpoint-sm {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_bottom.png');
    background-size: 100% 255px;
    padding: 174px 0 51px;

    &::before {
      height: calc(100% - 254px);
    }

    &::after {
      height: 115px;
      bottom: -115px;
      background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_small_180.png');
      background-size: contain;
    }

    .checkoutContent {
      width: 100%;
      padding: 0 24px;
    }

    .checkoutFormWrapper {
      width: 100%;
      padding-left: 0;
    }

    .pageTitle {
      font-size: 21px;
      line-height: 116%;
      margin-bottom: 64px;
    }
  }
}

.checkout_form {
  color: #000;
  padding: 0;
  display: flex;

  @include breakpoint-sm {
    flex-direction: column;
    min-height: auto;
    position: relative;
  }

  :global(.new-plan-options) {
    width: 100%;
    max-width: 340px;

    @include breakpoint-sm {
      max-width: 100%;
    }
  }

  :global(.new-plan-details) {
    margin-bottom: 33px;

    @include breakpoint-sm {
      margin-top: 74px;
    }
  }

  .newCostWrapper {
    display: flex;
    justify-content: center;

    .newCostTitle {
      font-size: 15px;
      line-height: 136%;
      font-weight: normal;
      color: #000000;
      margin: 0;
    }

    .newCostAmount {
      line-height: unset;

      :global(.amount),
      :global(.frequency) {
        font-weight: 600;
        font-size: 15px;
        line-height: 136%;
        color: #000000;
      }
    }

    @include breakpoint-sm {
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;

      .newCostTitle {
        font-size: 13px;
        padding-top: 2px;
      }

      .newCostAmount {
        :global(.amount),
        :global(.frequency) {
          font-size: 13px;
        }
      }
    }
  }

  :global(.checkout-area) {
    margin: 0;
    margin-left: 135px;
    margin-top: -50px;

    @include breakpoint-sm {
      margin: 0;
      position: relative;
      padding: 0;
    }

    .subtitle {
      margin-bottom: 2px;
      margin-top: 0;
    }

    .checkout_btn,
    .signup_checkout_btn {
      margin-left: auto;
      margin-right: auto;
      margin-top: 35px;
      width: 276px;

      @include breakpoint-sm {
        width: 295px;
        margin-top: 28px;
        font-size: 13px;
      }
    }

    .btnGroup {
      justify-content: center;
      margin-top: 51px;
    }
  }

  .subtitle {
    font-size: 15px;
    line-height: 136%;
    color: #000;
    font-weight: normal;
    text-align: left;
    margin-top: 61px;
    margin-bottom: 8px;
    font-family: $fontBase;

    &.creatorsLabel {
      margin-top: 42px;
    }

    @include breakpoint-sm {
      font-size: 13px;
      margin-top: 51px;

      &.creatorsLabel {
        margin-top: 37px;
      }
    }
  }

  .planDropdown {
    .subtitle {
      margin: 0;
      margin-bottom: 6px;
    }

    .planSelect {
      background-color: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      width: 100%;

      &:hover,
      &:focus {
        outline: none;
      }

      :global(.text) {
        font-weight: 600;
        font-size: 15px;
        margin-left: 0px;
        line-height: 40px;
        font-family: $fontBase;

        @include breakpoint-sm {
          font-size: 13px;
        }
      }
    }
  }

  .frequency {
    flex-direction: row;
    align-items: flex-start;
    padding-top: 9px;
    padding-bottom: 11px;

    @include breakpoint-sm {
      margin-left: 0;
    }

    label {
      border: none;
      max-width: 130px;

      &:first-child:not(:last-child) {
        margin-right: 15px;
      }

      input ~ div::before {
        background-color: #e5e5e5;
      }
      input:checked ~ div::before {
        border: 4px solid #e5e5e5;
      }

      > div {
        flex: none;
      }

      &:global(.checked) {
        font-weight: bold;
        border: none;

        :global(.term) {
          font-weight: 600;
        }
      }

      :global(.term) {
        color: #000;
        font-size: 15px;
        font-weight: normal;

        @include breakpoint-sm {
          font-size: 13px;
        }
      }
    }

    :global(.discount-container) {
      position: relative;
    }

    :global(.discount) {
      font-size: 15px;

      @include breakpoint-sm {
        font-size: 13px;
      }
    }

    :global(.savings) {
      color: var(--site-theme-color);
      line-height: 24px;
      margin-left: 16px;
      font-weight: normal;
      position: relative;
      white-space: nowrap;

      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
  }

  :global(.creators) {
    height: 40px;
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 15px;
    border-radius: 0;
    padding: 0;

    input {
      background: transparent;
    }

    @include breakpoint-sm {
      font-size: 13px;
    }

    div button {
      color: #000;
      line-height: 136%;
      font-weight: 600;
      font-size: 15px;

      @include breakpoint-sm {
        font-size: 13px;
      }
    }

    :global(.text-field) {
      font-weight: bold;
      font-size: 15px;

      @include breakpoint-sm {
        font-size: 13px;
      }
    }
  }

  :global(.creator-cost) {
    font-size: 15px;
    line-height: 136%;
    text-align: center;

    @include breakpoint-sm {
      font-size: 13px;
      padding-top: 6px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    :global(.amount) {
      font-weight: normal;
    }
  }

  .next_invoice {
    margin-top: 20px;
    line-height: 25px;
    text-align: center;
    font-size: 15px;

    @include breakpoint-sm {
      font-size: 13px;
      margin-top: 18px;
    }
  }

  :global(.cursor) {
    border: none;
    right: 0;
    top: 0;
    background-image: url('~assets/img/icons/arrow-down.svg');
    width: 40px;
    height: 40px;
    transform: rotate(0);

    &:global(.isOpen) {
      transform: rotate(180deg);
    }
  }

  :global(.options-container) {
    font-family: 'Libre Franklin', sans-serif;
    font-size: 15px;
    background-color: #f2f2f2;
    color: #000;
  }

  :global(.options) {
    padding-top: 4px;
    padding-bottom: 11px;

    :global(.option) {
      padding-top: 8px;
      padding-bottom: 7px;
      padding-left: 24px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }

  &.usingNewLayout {
    .planNavContainer.isSticky {
      width: calc(100% - 256px); // 240px (sidebar) + 16px (margin)
      left: 248px;
      top: 100px; // 92px (menu bar) + 8px (margin)
    }
    &.hideGuestMenu {
      margin-top: 0 !important;
      
      .planNavContainer.isSticky {
        top: 8px;
      }
    }
  }

  :global(.gl-header) {
    margin-top: 0px;
    margin-bottom: 12px;
  }

  :global(.gl-subheader) {
    margin-bottom: 16px;
  }

  @include breakpoint-sm {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }
  }
}

.expandCollapseAll {
  height: 35px;
  background-color: transparent;
  border: 0;
  text-align: left;
  font-weight: $normal;
  font-size: 14px;
  color: $blue;
  margin-left: 5px;

  @media screen and (min-width: 950px) {
    width: 100%;
    margin-left: 0;
    margin-top: 4px;
  }

  &:focus {
    outline: none;
  }

  & > img {
    width: 13px;
    position: relative;
    left: -7px;

    &:global(.up) {
      transform: rotate(180deg);
    }
  }
}

.title {
  margin-top: 70px;
  color: #020202;
  font-size: 25px;
  text-align: center;
  font-family: $fontBase;
  line-height: 27px;
  br {
    display: none;
  }
}

.subtitle {
  margin-top: 9px;
  color: #444444;
  font-size: 16px;
  text-align: center;
  font-family: $content_font;
  line-height: 17px;
}

.freePlan {
  margin-top: 10px;
  color: #999999;
  font-size: 12px;
  text-align: right;
  padding-right: 15px;
  line-height: 13px;
}

.planBox {
  margin: 18px 0 0;
  display: flex;
  justify-content: space-between;
}

.tabsBox {
  display: block;
  background: url('~assets/img/redesign2021/global/gradient_blue.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 355px);
    background-color: #2891be;
    z-index: 0;
  }

  @include breakpoint-sm {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_blue.png');
    background-size: 100% 360px;

    &::before {
      height: calc(100% - 360px);
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 54px 0 70px;
  position: relative;
  // z-index: 2;

  .titleContent {
    @include ioradFont;
    padding-left: 55px;
  }

  @include breakpoint-sm {
    padding: 68px 24px 200px;
  }
}

.planTabs {
  margin-top: 42px;
  padding: 0 64px;

  &.isSticky {
    margin-top: 109px;
  }

  @include breakpoint-sm {
    margin-top: 32px;
    padding: 0;

    &.isSticky {
      margin-top: 64px;
    }
  }
}

.planNavContainer {
  width: 100%;
  height: 67px;
  display: flex;
  align-items: center;
  background: #2891be;

  &.isSticky {
    position: fixed;
    top: $topNavBarHeight;
    left: 0;
    padding: 0 64px;
    z-index: 2;
    transition: top 0.32s;
    height: 92px;

    &.isLogged {
      top: 48px;
    }

    &.isHideMenuBar {
      top: 0;
    }
  }

  @include breakpoint-sm {
    height: 32px;

    &.isSticky {
      top: 58px;
      padding: 0 24px;

      &.isLogged {
        top: 48px;
      }

      &.isHideMenuBar {
        top: 0;
        height: 60px;
      }
    }
  }

  h1 {
    margin-top: 0;
    padding-top: 35px;
    background: #fff;
  }
}

.featuresBox {
  width: 1020px;
  margin: 150px auto 0;

  @media screen and (max-width: 1035px) {
    width: 100%;
  }

  @include breakpoint-sm {
    width: 100%;
    margin-top: 130px;
  }
}

.col {
  max-width: 25%;
  padding: 0 15px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.featuresTitle {
  font-size: 22px;
  color: black;
  font-family: $fontBase;
  margin-bottom: $gl_gap * 2;
  text-align: center;
  width: max-content;
  margin: 12px auto;
  height: 42px;
  cursor: pointer;

  &:hover {
    opacity: 0.86;
  }

  @media screen and (min-width: 769px) {
  }

  p {
    width: max-content;
    margin: 0 auto 24px;
    font-size: 18px;
  }
}

.featuresContainer {
  margin-bottom: 73px;
  padding-top: $floatingNavHeight;

  &.learnersPlans {
    margin-bottom: 0;
  }

  .lastRow {
    display: none;
  }

  @include breakpoint-sm {
    margin-bottom: 57px;
    padding-top: $floatingNavHeightMobile;
  }
}

.containerGap {
  margin-top: 50px;
}

.featureBoxContent {
  position: relative;
}

.subtitleContainer {
  height: $floatingNavHeight;
  width: 100%;
  background: white;
  position: absolute;
  left: 0;
  top: 0;

  &.isSticky {
    position: fixed;
    top: calc(#{$topNavBarHeight} + 66px);
    left: 50%;
    transform: translateX(-50%);
    width: 1020px;
    margin: 0 auto;
    z-index: 1;
    height: 53px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    transition: top 0.32s;

    &.isLogged {
      top: 114px;
    }

    &.isHideMenuBar {
      top: 66px;
    }
  }

  @include breakpoint-sm {
    height: $floatingNavHeightMobile;

    &.isSticky {
      position: fixed;
      top: 89px;
      left: 0;
      transform: unset;
      width: 100vw;

      &.isLogged {
        top: 79px;
      }
    }
  }
}

.learnersContainer {
  width: calc(100% + 30px);
  height: $floatingNavHeight;
  margin-left: -15px;
  position: relative;
  margin-top: 8px;

  & > div > div:first-child {
    color: #ff578f;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 22px;
  }

  // .showFirstHeader {
  .planTitle {
    visibility: hidden;
  }
  // }

  .secondHeader {
    .planTitle {
      visibility: visible;
    }
  }
}

.faqHeader {
  display: none;
  margin-top: 22px;
}

.showFaqHeader {
  display: block;
}

.featuresSubtitleContainer {
  display: flex;
  height: $floatingNavHeight;
  width: 100%;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  @include breakpoint-sm {
    height: $floatingNavHeightMobile;
    padding: 0 24px;
  }
}

.featuresSubtitle {
  display: flex;
  align-items: center;
  padding: 0 22px;
  font-weight: 600;
  font-size: 15px;
  line-height: 136%;
  color: #000000;
  width: 360px;

  @include breakpoint-sm {
    font-size: 13px;
    width: 148px;
    padding: 0;
  }
}

.planTitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 136%;
  flex: 1 1 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;

  @include breakpoint-sm {
    font-size: 13px;
    padding: 0;
  }
}

.faqContainer {
  margin: 204px auto -5px;
  width: $maxWidthContent;
  padding: 0 64px;

  @media screen and (max-width: 1380px) {
    width: 100%;
  }

  @include breakpoint-sm {
    margin-top: 175px;
    padding: 0 24px;
    width: 100%;
  }

  a {
    @extend .link-underline;
    font-size: 15px;

    @include breakpoint-sm {
      font-size: 13px;
    }
  }

  .faqHead {
    cursor: pointer;
    margin-bottom: 23px;

    &:hover {
      opacity: 0.86;
    }
    .faqTitle {
      font-weight: 600;
      font-size: 32px;
      line-height: 116%;
      color: #000000;
      margin-bottom: 25px;

      @include breakpoint-sm {
        font-size: 21px;
        line-height: 116%;
        margin-bottom: 21px;
      }
    }
  }

  .faqList {
    .scrollbars {
      max-height: 500px;
    }
  }

  .pricingFaq {
    font-size: 25px;
    color: $black;
    padding-bottom: 45px;
  }
  .question {
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin-bottom: 5px;

    @include breakpoint-sm {
      font-size: 13px;
      line-height: 136%;
      margin-bottom: 10px;
    }
  }
  .response {
    font-size: 15px;
    line-height: 136%;
    color: #000000;

    @include breakpoint-sm {
      font-size: 13px;
      line-height: 136%;
    }
  }

  .faqBox {
    width: 808px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoint-sm {
      width: 100%;
    }
  }
}

.featuresTabsBox {
  display: none;
}

.planTabsContent {
  display: flex;
  justify-content: space-between;

  @include breakpoint-sm {
    width: 100%;
    flex-direction: column;
  }
}

.nav_dropdown {
  position: fixed;
  top: 10px;
  z-index: 10;

  :global(.react-selectize) {
    max-width: 260px;
  }
}

.textDescription {
  font-size: 16px;
  color: red;
}
.pre {
  color: rgb(130, 130, 130);
  font-family: $fontBase, sans-serif;
  position: absolute;
}
.ioradLink {
  font-size: 16px;
}

.pricingFooter {
  padding-top: 266px !important;

  @include breakpoint-sm {
    padding-top: 221px !important;

    &.hasFAQs {
      padding-top: 253px !important;
    }
  }
}
