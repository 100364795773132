.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.line, .loading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}