@import '~theme/mixins';

.modal_dialog {
  padding: 0;

  :global(.popup-content) {
    margin-top: 48px;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .modal_dialog__content {
    width: 100%;
    height: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cropImg {
    max-height: 440px;

    img {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  :global(.ReactCrop__image) {
    cursor: crosshair;
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: 440px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    max-width: 320px;
    margin-top: 24px;
    width: 100%;

    button {
      height: 56px;
      width: 100%;
    }
  }
}
