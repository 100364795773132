@import '~theme/mixins';

.slidesSection {
  position: relative;
}

.slidesWrapper {
  width: 100%;
  box-sizing: content-box;
  overflow: hidden;
}

.slidesContent {
  display: flex;
  height: 70px;
  align-items: center;

  &:hover {
    .slides {
      animation-play-state: paused;
    }
  }
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

.slides {
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: animate 180s linear infinite;
  animation-delay: -180s; // NOTE: keep as separate property as CssMinimizerPlugin throws on production when it is in 'animation' shorthand
  will-change: transform;
  height: 100%;

  &:nth-child(2) {
    animation: animate2 180s linear infinite;
    animation-delay: -90s; // NOTE: keep as separate property as CssMinimizerPlugin throws on production when it is in 'animation' shorthand
  }
}

.slides_item {
  display: flex;
  height: 100%;
  margin-left: 150px;

  img {
    height: 100%;
  }
}

@include breakpoint-sm {
  .slidesContent {
    height: 40px;
  }
}
