@import "../../theme/modules/variables";
@import "../../theme/mixins";

.simple_select:global(.root-node):global(.react-selectize):global(.default):global(.root-node) {
  width: 100%;

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  :global(.react-selectize-control) {
    font: inherit;
    font-family: $fontBaseV2;
    color: #000;
    line-height: normal;
    border: none;
    border-radius: 0;
    outline: none;
    padding: 0;
    background: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    :global(.react-selectize-search-field-and-selected-values) {
      padding-left: 0;
      flex-wrap: nowrap;

      :global(.value-wrapper) {
        max-height: 100%;
      }

      :global(.resizable-input) {
        padding: 0;
      }
    }

    :global(.react-selectize-placeholder) {
      line-height: 32px;
      color: rgb(51, 51, 51);
      text-indent: 0;
    }

    :global(.simple-value) {
      margin: 0;
      white-space: nowrap;
    }
  }

  :global(.react-selectize-toggle-button-container) {
    height: 32px;
    width: 32px;
  }

  :global(.dropdown-menu) {
    font: inherit;
    font-family: $fontBaseV2;
    box-shadow: none;
    background: white;
    padding: 0;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
    margin-left: -20px;
    max-height: 350px;

    :global(.option-wrapper.highlight) {
      background: transparent;
    }

    :global(.simple-option) {
      font: inherit;
      color: black;
      white-space: nowrap;
      padding: 10px 20px;

      &:global(.selected) {
        color: var(--site-theme-color);
      }

      &:hover {
        text-decoration-line: underline;
      }
    }
  }

  .toggleButton.isOpen {
    transform: scale(-1);
  }

  &:global(.disabled) {
    opacity: 0.4;
  }

  &.disabledSearch {
    :global(.resizable-input) {
      width: 0 !important;
    }
  }

  &.small { // height: 20px
    :global(.react-selectize-control) {
      border-bottom: none;

      :global(.react-selectize-search-field-and-selected-values) {
        min-height: 20px;
        height: 20px;
        justify-content: flex-end;
      }
      :global(.react-selectize-placeholder) {
        line-height: 20px;
      }
    }
    :global(.react-selectize-toggle-button-container) {
      height: 20px;
      width: 20px;
      justify-content: flex-end;
      margin-left: 10px;
    }
    :global(.dropdown-menu) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;

      :global(.simple-option) {
        text-align: right;
      }
    }
  }

  &.medium { // height: 40px
    :global(.react-selectize-control) {
      :global(.react-selectize-search-field-and-selected-values) {
        min-height: 40px;
      }
    }
    :global(.react-selectize-toggle-button-container) {
      height: 40px;
      width: 40px;
    }
    :global(.dropdown-menu) {
      :global(.simple-option) {
        padding: 16px 20px;
      }
    }
  }

  &.large { // height: 56px
    :global(.react-selectize-control) {
      :global(.react-selectize-search-field-and-selected-values) {
        padding-left: 16px;
        min-height: 56px;
      }
    }
    :global(.react-selectize-toggle-button-container) {
      height: 56px;
      width: 40px;
    }
    :global(.dropdown-menu) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;

      :global(.simple-option) {
        padding: 16px 20px;
      }
    }
  }
}


