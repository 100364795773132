.accountNavMobile {
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 48px);
  left: 0;
  top: 48px;
  background: #fff;
  z-index: 14;
  padding: 25px 24px 72px;

  .accountNavMobile__menuItem_title {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
    padding-block: 11px;
    cursor: pointer;

    &:hover,
    &.active {
      color: var(--site-theme-color);
    }

    &.alignRight {
      text-align: right;
    }
  }

  .accountNavMobile__menuItem {
    display: flex;
    flex-direction: column;
  }

  .accountNavMobile__menuItem_childs {
    display: none;
    flex-direction: column;
    padding-left: 20px;

    &.isShow {
      display: flex;
    }
  }

  .accountNavMobile__menuItem_childs_title {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    padding-block: 7px;

    &:hover,
    &.active {
      color: var(--site-theme-color);
    }
  }
}
