@import '../../theme/form';

.copy {
  margin-bottom: 10px;
}

.options {
  margin-top: 10px;

  .checkbox {
    margin-bottom: 0;
  }
}


.label {
  display: flex;
  align-items: center;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.input {
  height: 34px;

  &:active,
  &:focus {
    border-bottom: 1px solid var(--site-theme-color);
  }
}

.linkContainer {
  bottom: 1px;
  height: 100%;

  .fadeOut {
    width: 33px;
    height: calc(100% - 1px);
    background: linear-gradient(to left, #FFFFFF 13.81%, rgba(255, 255, 255, 0) 99.61%);
  }

  .linkGroup {
    display: flex;
    align-items: center;
    height: calc(100% - 1px);
    background: #fff;
    padding-left: 10px;
  }

  .link {
    margin-top: 1px;
  }
}
