@import '~theme/mixins';
@import '~theme/modules/variables';
@import '~components/Integrations/general';

.filter_bar {
  margin-top: 2px;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  padding-right: 375px;

  @media screen and (max-width: 1300px) {
    flex-direction: column;

    .filter_tags {
      margin-left: 0;
      max-width: 481px;
    }
  }
}

.filter_tags {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 427px;
  height: 40px;
  margin-left: 20px;
  overflow: hidden;

  .filter_tags__content {
    display: flex;
    width: 100%;
    align-items: center;
    background: rgba(0, 0, 0, 0.05);
    padding: 2px 8px;
  }

  .filter_tags__title {
    flex-grow: 1;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    padding-right: 24px;
    word-break: break-word;
  }

  .filter_tags__buttons {
    display: flex;
    align-items: center;
    column-gap: 15px;

    button {
      background: 0;
      border: 0;
      outline: 0;
      padding: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  @include breakpoint-sm {
    flex-basis: auto;
    margin-left: 0;
    height: auto;

    .filter_tags__content {
      padding: 8px 12px;
      margin: 10px 12px 0;
      width: auto;
    }

    .filter_tags__title {
      font-size: 13px;
    }

    .filter_tags__buttons {
      button {
        font-size: 13px;
      }
    }
  }
}

.shareForEmbedInfo {
  &.filter_bar {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 26px;
    align-items: center;

    .search_and_sort_wrapper {
      height: 56px;
    }
  }

  .tutorialForIntegration {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;

    font-family: Libre Franklin, serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;

    .integrationLogo {
      width: 24px;
      height: 24px;
      min-width: 16px;
      min-height: 16px;
      @include integrationIcon;
    }

    .integrationName {
      text-transform: capitalize;
    }
  }
}
