@use "sass:math";
@import '~theme/variables';
@import '~theme/modules/variables';
@import '~theme/mixins';
@import '../../styles/common';

$text-field-width: 240px;
.new_plan_modal,
.add_plan_modal {
  padding-top: 55px;
  @include breakpoint-sm {
  }

  :global(.gl-content-wrapper) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  :global(.popup-header) {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
  }

  .footer {
    background-color: #fff;
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    padding: 15px;
    text-align: center;
    width: 100%;


    :global(.btn-fancy) {
      margin: 0 7px 0 7px;

      @include breakpoint-sm {
        margin: 7px;
      }
    }
  }

  :global(.text-field),
  :global(.iorad-popover-toggle),
  :global(.dropdown-toggle) {
    font-size: 14px;
    position: relative;
    top: 31px;
    width: $text-field-width;
    padding: 9px 20px;
    height: 40px;

    &:hover {
      + .label {
        color: $labelhover;
      }
    }

    &:focus {
      + .label {
        color: $blackgrey;
      }
    }
  }
  :global(.react-google-flight-datepicker) {
    position: relative;
    top: 31px;
    height: 40px;
  }

  :global(.focusable) {
    top: 31px;
    width: $text-field-width;
  }

  :global(.iorad-popover) {
    top: 48px !important;

    ul {
      min-width: $text-field-width;
      left: -(math.div($text-field-width, 2));

      &::before { // Arrow
        $arrow-size: 20px;

        position: relative;
        content: '';
        width: 0;
        height: 0;
        left: math.div($text-field-width, 2) - math.div($arrow-size, 2);
        top: -29px;
        border-style: solid;
        border-width: 0 math.div($arrow-size, 2) math.div($arrow-size, 2) math.div($arrow-size, 2);
        border-color: transparent transparent #ffffff transparent;
      }
    }
  }

  :global(.iorad-popover-toggle) {
    &::after { // Down arrow
      left: $text-field-width - 26;
    }
  }

  :global(.easy-checkbox) {
    &:after {
      background-color: #eee;
    }

    ~ label {
      position: relative;
      bottom: 1px;
      left: 4px;
      font-weight: normal;
      cursor: pointer;
      user-select: none;
    }
  }
  .base_price_container {
    margin-bottom: 30px;
  }

  label {
    input[type="checkbox"] + span {
      font-weight: normal;
      display: inline-block;
      padding-left: 5px;
      vertical-align: baseline;
      position: relative;
      top: -1px;
      cursor: pointer;
    }
  }
}

.change_password_modal {
  @extend .new_plan_modal;

  justify-content: center;

  .label {
    text-align: left;
  }
}

.row {
  height: auto;
  font-size: 16px;
  margin: 0 40px;
  text-align: center;

  @include breakpoint-sm {
    font-size: 12px;
    margin: 0;
    left: 0;
    position: relative;
    align-self: center;
  }
}
