@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  background: #f4f4f4;
  padding: 55px 0 74px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleContent {
    @include ioradFont;
    padding-bottom: 101px;
    max-width: 635px;

    @media screen and (max-width: 1180px) {
      font-size: 17vw;
    }
  }

  .applyBtn {
    width: 276px;
    height: 48px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    transition: color 0.3s ease;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}

.supportSection {
  .supportContent {
    padding: 80px 64px;
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .titleContent {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    margin: 0;
    margin-bottom: 26px;
  }

  .descWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    max-width: 616px;

    p {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }
  }
}

.benefitsSection {
  padding-bottom: 110px;

  .benefitsContent {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 26px;
  }

  .benefitList {
    display: flex;
    gap: 127px;
    flex-wrap: wrap;
  }

  .benefitItem {
    display: flex;
    flex-direction: column;
    width: 296px;
  }

  .benefitImg {
    background: #77c082;

    img {
      width: 100%;
      height: 156px;
    }
  }

  .benefitContent {
    margin-top: 26px;
  }

  .benefitTitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 116%;
    color: #000000;
    margin: 0;
    margin-bottom: 13px;
  }

  .benefitDesc {
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;

    a {
      text-decoration: underline;
    }
  }
}

.resultsSection {
  padding-bottom: 104px;

  .resultsContent {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: black;
    margin: 0;
    margin-bottom: 15px;
  }

  .desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    color: black;
    margin: 0;
    max-width: 418px;
  }

  .resultsList {
    display: flex;
    padding: 0;
    margin: 0;
    margin-top: 45px;
    flex-direction: row;
    justify-content: space-between;
  }

  .resultsItem {
    display: flex;
    flex-direction: column;
    width: 50%;

    &:nth-child(even) {
      align-items: flex-end;
    }
  }

  .resultNumber {
    font-family: 'iorad';
    font-style: normal;
    font-weight: 400;
    font-size: 11vw;
    line-height: 72%;
    letter-spacing: -0.006em;
    color: black;
    margin-bottom: 15px;

    @media screen and (min-width: 1900px){
      font-size: 155px;
    }
  }

  .resultName {
    font-family: Libre Franklin;
    font-size: 32px;
    font-weight: 600;
    font-size: 15px;
    line-height: 15.52px;
    color: black;
  }
}

.communitySection {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-bottom: 83px;

  .communityContent {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 15px;
  }

  .desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    margin: 0;
    margin-bottom: 32px;
    max-width: 418px;
  }

  .spotLightList {
    display: flex;
    column-gap: 20px;
  }

  .spotlightItem {
    display: flex;
    flex-direction: column;
    max-width: 294px;

    &:hover {
      color: #000000;
    }
  }

  .spotlightImage {
    width: 100%;
    height: 147px;
    background: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 128px;
    }
  }

  .spotlightContent {
    margin-top: 15px;
  }

  .spotlightTitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    margin: 0;
  }

  .spotlightDesc {
    font-size: 15px;
    line-height: 136%;
    margin: 0;
    margin-top: 5px;
    color: #000000;
  }
}

.applicationSection {
  background: #f4f4f4;

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleContent {
    @include ioradFont;
    padding-top: 59px;
    padding-bottom: 90px;

    @media screen and (max-width: 1044px) {
      font-size: 19vw;
    }
  }

  .headerDesc {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    color: #000000;
    margin: 0;
    margin-bottom: 14px;
  }

  .descWrapper {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    max-width: 618px;
    padding-bottom: 51px;
  }
}

.formWrapper {
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 78px 64px 56px;

  .formContent {
    max-width: 575px;
  }

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .label {
    font-weight: normal;
    font-size: 15px;
    color: #999999;
    font-size: 10px;
    margin-bottom: 6px;
  }

  .input {
    width: 100%;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    padding: 0;
    height: 35px;
    font-size: 15px;
    font-weight: normal;
    outline: none;

    &::placeholder {
      color: var(--site-theme-color);
      opacity: 0.4;
    }

    &:active,
    &:focus {
      color: #000000;
      border-bottom: 1px solid var(--site-theme-color);
    }

    &.isError {
      border-color: var(--site-theme-color);
    }
  }

  .textarea {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    outline: none;
    resize: none;
    width: 100%;
    background-color: #fff;
    font-size: 15px;
    padding: 5px 0;
    overflow-y: auto;
    min-height: 34px;

    &:active,
    &:focus {
      color: #000000;
      border-bottom: 1px solid var(--site-theme-color);
    }

    &.isError {
      border-color: var(--site-theme-color);
    }
  }

  .applyBtn {
    width: 276px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;

    &:focus .label {
      color: #000000;
    }

    &.hasError {
      .label {
        color: var(--site-theme-color);
      }

      .input {
        border-bottom: 1px solid var(--site-theme-color);
      }
    }
  }

  .formTextArea {
    height: 237px;
    position: relative;
    margin-top: 22px;

    .titleTextArea {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 5px;
    }

    .descTextArea {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }

    .textarea {
      position: absolute;
      bottom: 32px;
      left: 0;
      width: 100%;
    }

    .requiredLabel,
    .fieldError {
      font-size: 10px;
      line-height: 150%;
      color: #000000;
      opacity: 0.4;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .fieldError {
      bottom: 15px;
      color: #ff7373;
      font-size: 10px;
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: center;
    margin-top: 31px;
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;
    padding: 69px 0 13px;

    &.isLogged {
      margin-top: 46px;
    }

    .titleWrapper {
      padding: 0 24px;

      img {
        padding-left: 19px;
        padding-bottom: 78px;
      }
    }

    .applyBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }

  .supportSection {
    .supportContent {
      padding: 119px 24px 80px;
    }

    .titleContent {
      font-size: 21px;
      line-height: 136%;
      margin-bottom: 18px;
    }

    .descWrapper {
      max-width: 100%;

      p {
        font-size: 13px;
      }
    }
  }

  .benefitsSection {
    padding-top: 29px;
    padding-bottom: 80px;

    .benefitsContent {
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      line-height: 116%;
      margin-bottom: 18px;
    }

    .benefitList {
      row-gap: 43px;
    }

    .benefitItem {
      flex-direction: column;
    }

    .benefitImg {
      img {
        width: 100%;
        height: 163px;
        object-fit: cover;
      }
    }

    .benefitContent {
      max-width: 100%;
      margin-top: 24px;
      margin-left: 0;
    }

    .benefitTitle {
      font-size: 21px;
      line-height: 116%;
    }

    .benefitDesc {
      font-size: 13px;
      max-width: 100%;
    }
  }

  .resultsSection {
    padding-bottom: 80px;

    .resultsContent {
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      line-height: 116%;
      margin-bottom: 18px;
    }

    .desc {
      font-size: 13px;
      max-width: 100%;
    }

    .resultsList {
      gap: 20px;
    }

    .resultsItem {
      width: 46%;
    }

    .resultNumber {
      font-size: 11vw;
      margin-bottom: 10px;
    }

    .resultName {
      // font-size: 13px;
    }
  }

  .communitySection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_small.png');
    padding-bottom: 126px;

    .communityContent {
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      line-height: 116%;
      margin-bottom: 18px;
    }

    .desc {
      font-size: 13px;
    }

    .spotLightList {
      flex-wrap: wrap;
      row-gap: 38px;
    }

    .spotlightItem {
      max-width: 100%;
    }

    .spotlightTitle {
      font-size: 13px;
    }

    .spotlightDesc {
      font-size: 13px;
      margin-top: 13px;
    }
  }

  .applicationSection {
    .titleWrapper {
      padding: 0 24px;

      img {
        padding-top: 66px;
        padding-bottom: 36px;
        margin-left: -4px;
      }
    }

    .headerDesc {
      font-size: 13px;
      line-height: 136%;
      margin-bottom: 9px;
    }

    .descWrapper {
      font-size: 13px;
      max-width: 100%;
      padding-bottom: 38px;
    }
  }

  .formWrapper {
    padding: 125px 24px 121px;

    .formContent {
      max-width: 100%;
    }

    .applyBtn {
      width: 295px;
    }

    .formTextArea {
      height: 241px;

      .titleTextArea {
        font-size: 13px;
        line-height: 136%;
      }

      .descTextArea {
        font-size: 13px;
        line-height: 136%;
      }
    }
  }
}
