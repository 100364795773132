@import '~theme/modules/variables';
@import '~theme/mixins';

.panel {
  // width: 100%;
  // height: 100%;
  // overflow: hidden;
  // display: flex;
  // align-items: center;
}

.inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: $maxWidthContent;
  margin: 0 auto;
}

.inner_content {
  max-height: 768px;
  height: 100%;
  width: 100%;
  display: flex;
}

.panel__left {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 565px;
  flex-shrink: 0;
  padding: 56px 64px 42px;

  h4 {
    font-size: 32px;
    line-height: 136%;
    color: black;
    font-weight: 600;
    margin: 0;
    margin-bottom: 17px;
  }

  p {
    font-size: 15px;
    line-height: 136%;
    color: black;
    margin: 0;

    b {
      font-weight: 600;
    }

    a {
      text-decoration: underline;
    }
  }

  span {
    font-size: 32px;
    line-height: 136%;
    margin-top: auto;
    color: black;
  }
}

.panel__right {
  display: flex;
  flex-grow: 1;
  padding-top: 68px;

  img {
    max-width: 100%;
  }
}

@include breakpoint-sm {
  .inner {
    max-width: 100%;
  }

  .inner_content {
    flex-direction: column;
  }

  .panel__left {
    max-width: 100%;
    flex-shrink: 0;
    padding: 13px 24px 35px;

    h4 {
      font-size: 21px;
      margin-bottom: 18px;
    }

    p {
      font-size: 13px;
    }

    span {
      order: -1;
      font-size: 13px;
      font-weight: 600;
      text-align: end;
      margin-bottom: 21px;
    }
  }

  .panel__right {
    align-items: flex-end;
    padding-top: 0;
    padding-left: 24px;
    max-height: 300px;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
