@import '~theme/modules/variables';

.container {
  display: block;
  padding-right: 16px;
  padding-left: 5px;
  position: relative;
  z-index: 1;
  background: white;

  &.blur {
    opacity: 0.2;
  }

  &:hover {
    .headerText {
      color: var(--site-theme-color);
    }

    .headerIcon {
      svg {
        [stroke] {
          stroke: var(--site-theme-color);
        }

        [fill] {
          fill: var(--site-theme-color);
        }
      }
    }
  }
}

.isMinimize {
  height: max-content;
}

.isExpanding {
  .headerText {
    color: var(--site-theme-color);
  }

  .headerIcon {
    svg {
      [stroke] {
        stroke: var(--site-theme-color);
      }
      [fill] {
        fill: var(--site-theme-color);
      }
    }
  }
}

.isShared {
  .headerIcon {
    margin-left: -1px;
  }
  .headerText {
    margin-left: -5px;
  }
}

.isArchived {
  .headerIcon {
    margin-left: -1px;
  }
  .headerText {
    margin-left: -3px;
  }
}
.isDeactivated {
  .headerIcon {
    margin-left: -1px;
    margin-right: 15px;
  }
  .headerText {
    margin-left: -3px;
  }
}

.isHelpCenter {
  .headerIcon {
    margin-left: -1px;
  }
  .headerText {
    margin-left: -6px;
  }
}

.isDashboard {
  .headerIcon {
    margin-right: 11px;
  }
}

.header {
	position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background: white;
  height: 36px;
}

.headerIcon {
  margin-right: 20px;
}

.headerText {
	font-size: 15px;
  font-family: $fontBaseV2;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}
