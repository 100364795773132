.gracehill {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .gracehill__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    font-family: 'Libre Franklin', sans-serif;
    padding: 58px 65px;
  }

  .gracehill__logo {
    display: flex;
    align-items: center;
    column-gap: 40px;

    img {
      height: 100px;
      width: auto;
    }

    span {
      font-size: 30px;
      color: black;
    }
  }

  .gracehill__title {
    margin: 0;
    margin-top: 40px;
    font-size: 15px;
    line-height: 20px;
    color: black;
  }

  .gracehill__installButton {
    max-width: 320px;
    margin-top: 40px;
  }

  .gracehill__subtitle {
    margin: 0;
    margin-top: 40px;
    font-size: 15px;
    line-height: 20px;
    color: black;
  }

  .gracehill__activities {
    margin-top: 40px;
    width: 100%;
    max-width: 760px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;

    img {
      width: 100%;
    }

    span {
      position: absolute;
      width: 160px;
      height: 80px;
      border: 4px solid red;
      position: absolute;
      bottom: 40px;
      left: 373px;
    }
  }
}
