@import '~theme/form';
@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  display: flex;
  width: 100%;

  @include breakpoint-sm {
    width: 100%;
    flex-direction: column;
  }
}

.tabContent {
  @media screen and (min-width: 768px) {
    margin-left: 530px;
  }
}

.formContainer {
  margin-right: 100px;

  @include breakpoint-sm {
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 768px) {
    position: fixed;
    top: 153px;
    left: 40px;
  }
}

.inviteCheckbox {
  margin-bottom: 50px;

  span {
    font-size: 21px;
    color: #000000;
    font-family: $fontBaseV2;
    font-weight: 600;
    line-height: 21px;
  }
}

.title {
  font-size: 21px;
  color: #000000;
  font-family: $fontBaseV2;
  font-weight: 600;
  margin-bottom: 30px;
}

.emailListWrapper {
  margin-top: 50px;

  @include breakpoint-sm {
    width: 100%;
  }
}
