@import '~theme/mixins';
@import '~theme/modules/variables';

.tutorials_view {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  row-gap: 0;
  background: white;

  .tutorials_view__hint {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    padding: 10px 24px;
  }

  .tutorial_item__row {
    display: flex;
    column-gap: 8px;
    padding: 10px 24px;
    background: white;
    position: relative;
    cursor: pointer;
    color: black;

    &:hover {
      color: var(--site-theme-color);

      .row__progress {
        display: none;
      }
      .row__open_icon {
        display: flex;
      }
    }

    .tutorial_item__row_cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: inherit;
      flex: 1;

      &:not(:first-child) {
        justify-content: flex-end;
        max-width: 128px;
      }

      @include breakpoint-sm {
        &:last-child {
          display: none;
        }
      }
    }
  }
}

.categories_view {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .categories_view__hint {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    padding: 10px 24px;
  }

  .category_item__row_container {
    display: flex;
    flex-direction: column;

    .category_item__row_header {
      display: flex;
      column-gap: 8px;
      padding: 18px 24px;
      background: white;
      position: relative;
      cursor: pointer;
      color: black;

      &:hover {
        color: var(--site-theme-color);
      }

      .category_item__row_cell {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: inherit;
        flex: 1;

        &:not(:first-child) {
          justify-content: flex-end;
          max-width: 128px;
        }
      }

      .category_item__name {
        margin: 0;
        font-size: 21px;
        line-height: 24px;
        font-weight: 400;
      }
    }

    .category_item__row_content {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 0 24px 24px 48px;
    }

    .category_item__tutorial_name {
      display: flex;
      align-items: center;

      &:hover {
        span {
          color: var(--site-theme-color);
        }
        .row__progress {
          display: none;
        }
        .row__open_icon {
          display: flex;
        }
      }

      span {
        padding: 10px 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        cursor: pointer;
        flex: 1;
      }

      .open_icon {
        display: flex;
        align-items: center;
        margin-left: 24px;
        visibility: hidden;
      }
    }

    .category_item__sub_category {
      display: flex;
      flex-direction: column;

      .category_item__sub_category_name {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin: 0;
        padding: 10px 0;
      }
    }
  }
}

.tags_view {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  .tags_view__hint {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    padding: 10px 24px;
  }

  .tag_item__row_container {
    display: flex;
    flex-direction: column;

    .tag_item__row_header {
      display: flex;
      column-gap: 8px;
      padding: 18px 24px;
      background: white;
      position: relative;
      cursor: pointer;
      color: black;

      &:hover {
        color: var(--site-theme-color);
      }

      .tag_item__row_cell {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: inherit;
        flex: 1;

        &:not(:first-child) {
          justify-content: flex-end;
          max-width: 128px;
        }
      }

      .tag_item__name {
        margin: 0;
        font-size: 21px;
        line-height: 24px;
        font-weight: 400;
      }
    }

    .tag_item__row_content {
      display: flex;
      flex-direction: column;
      background: white;
      padding: 0 24px 24px 48px;
    }

    .tag_item__tutorial_name {
      display: flex;
      align-items: center;

      &:hover {
        span {
          color: var(--site-theme-color);
        }
        .row__progress {
          display: none;
        }
        .row__open_icon {
          display: flex;
        }
      }

      span {
        padding: 10px 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        cursor: pointer;
        flex: 1;
      }

      .open_icon {
        display: flex;
        align-items: center;
        margin-left: 24px;
        visibility: hidden;
      }
    }
  }
}

.row__fixed_cell {
  display: flex;
  justify-content: flex-end;
  margin-left: 24px;
  width: 40px;
  flex-shrink: 0;

  .row__progress {
    opacity: 0.4;
    padding: 0 !important;
  }

  .row__open_icon {
    display: none;
    align-items: center;
  }
}

@include breakpoint-sm {
  .tutorials_view {
    padding: 12px 0;

    .tutorials_view__hint {
      padding: 10px 12px;
    }

    .tutorial_item__row {
      padding: 10px 12px;

      .tutorial_item__row_cell:last-child {
        display: none;
      }
    }
  }

  .categories_view {
    .categories_view__hint {
      padding: 10px 12px;
    }

    .category_item__row_container {
      display: flex;
      flex-direction: column;

      .category_item__row_header {
        padding: 10px 12px;

        .category_item__row_cell:last-child {
          display: none;
        }

        .category_item__name {
          font-size: 15px;
          line-height: 20px;
        }
      }

      .category_item__row_content {
        padding: 0 12px;
      }

      .category_item__tutorial_name {
        .open_icon {
          margin-left: 12px;
        }
      }
    }
  }

  .tags_view {
    .tags_view__hint {
      padding: 10px 12px;
    }

    .tag_item__row_container {
      display: flex;
      flex-direction: column;

      .tag_item__row_header {
        padding: 10px 12px;

        .tag_item__row_cell:last-child {
          display: none;
        }

        .tag_item__name {
          font-size: 15px;
          line-height: 20px;
        }
      }

      .tag_item__row_content {
        padding: 0 12px;
      }

      .tag_item__tutorial_name {
        .open_icon {
          margin-left: 12px;
        }
      }
    }
  }
}
