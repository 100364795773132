@import "../../theme/modules/variables";
@import "../../theme/mixins";

.multiSelect {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  color: #000000;
  outline: none;
  position: relative;

  .headerDropdown,
  .dropdownItem > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 40px;
  }

  .dropdownItem > label {
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    border: none;
    white-space: nowrap;
    background: transparent;
    width: 100%;
  }

  .arrowIcon {
    width: 40px;
    height: 40px;
    text-align: center;
  }

  ul.contentDropdown {
    padding: 12px 24px;
    overflow-y: auto;
    max-height: 40vh;
    background-color: #fff;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    z-index: 101;
    position: absolute;
    top: 100%;

    .dropdownItem {
      list-style: none;

      label:hover {
        text-decoration: underline;
      }

      input[type="checkbox"] {
        appearance: none;
        width: 16px;
        height: 16px;
        display: inline-block;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 1px;
        outline: none;
        margin: 0;
        cursor: pointer;

        &:checked {
          background-image: url("../../assets/img/icons/checkmark.svg");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 70% 70%;
        }
      }
    }
  }
}
