@import '../../theme/modules/variables';
@import '../../theme/guidelines';
@import "~theme/mixins";

.container {
  display: block;
  font-family: $fontBaseV2;

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }
}

.inviteForm {
  display: flex;
  flex-direction: column;
}

.emailListContent {

}

.emailForm {
  
}

.addEmailContainer {
  max-width: 600px;
}

.emailInput {

}

.btnUploadCsv {
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  padding: 16px;
  border: 0 none;
  background-color: transparent;
  line-height: 24px;

  input {
    display: none;
  }

  label {
    display: block;
    cursor: pointer;
    font-weight: 600;
    margin: 0;
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  @include breakpoint-sm {
    display: none;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 16px;

  @include breakpoint-sm {
    flex-direction: column;
    align-items: flex-start;
  }
}

.remaingInvites {
  font-family: $fontBaseV2;
  color: var(--site-theme-color);
  font-size: 15px;
}

.inviteButton {
  width: 216px;
  height: 48px;

  @include breakpoint-sm {
    margin-top: 16px;
  }
}

.emailListContainer {
  margin-top: 8px;
  padding: 16px;
  padding-left: 0;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.invitingContainer {
  flex-grow: 1;
  max-width: 600px;
}

.emailListTitle {
  font-size: 15px;
  font-weight: 600;
  font-family: $fontBaseV2;
  margin-bottom: 16px;
  position: sticky;
  top: 96px;
  background-color: #FFF;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;

  .emptyNowBtn {
    width: fit-content;
    height: auto;
    padding: 0;
    border: 0 none;
    gap: 10px;
    line-height: 14px;
    font-size: 13px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.checkoutContainer {
  margin-top: 20px;
}

.deletedContent {

}

.deletedEmail {
  margin-bottom: 49px;
}


.listItem {
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  border-top-color: transparent;
  margin-top: -1px;
  color: rgba($color: #000000, $alpha: 0.4);

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  &:first-child {
    border-top-color: rgba($color: #000000, $alpha: 0.1);
  }

  &:hover {
    border-color: rgba($color: #000000, $alpha: 0.25);
    border-top-color: rgba($color: #000000, $alpha: 0.25);
    color: #000000;
  }

  .black {
    color: #000;
  }

  button {
    background-color: transparent;
    border: 0 none;
    padding: 0;
    display: flex;
    align-items: center;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}

.listItemEmail {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 16px;
  line-height: 24px;
  color: rgba($color: #000000, $alpha: 0.4);
}

.listItemPermission {
  width: 205px;
  margin-right: 16px;
  border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
  padding-right: 16px;
}
