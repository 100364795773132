@import '~theme/modules/variables';
@import '~theme/mixins';

.nameWorkspace {
  display: flex;
  height: 100vh;
  min-height: 600px;

  .nameWorkspace__block {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .nameWorkspace__header {
    .nameWorkspace__logo {
      padding: 24px 24px 40px;
    }
  }

  .nameWorkspace__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 84px;
    padding-bottom: 24px;
    overflow-y: auto;
  }

  .nameWorkspace__content_inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 40px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-bottom: 40px;
    }

    input {
      width: 100%;
      height: 56px;
      padding: 0 16px;
      font-size: 15px;
      line-height: 20px;
      color: black;
      border: none;
      background: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0px;
      margin-bottom: 40px;

      &:focus {
        border-color: black;
      }
    }

    button {
      width: 320px;
      height: 56px;
      margin: 0 auto;
    }
  }

  .nameWorkspace__image {
    width: 100%;
    height: 100%;
    background: #bababa;
    padding: 56px;

    img {
      width: 950px;
    }
  }
}
