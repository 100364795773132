@import '~theme/modules/variables';

.container {
  display: block;
}

.list {
  padding-right: 14px;
}

.categoryItem {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;

  &.selected {
    .categoryName {
      color: var(--site-theme-color);
    }
  }
}

.categoryName {
  font-family: $fontBaseV2;
  font-size: 13px;
  color: #000000;
  word-break: break-word;
  padding-right: 20px;
}

.subCategories {
  padding-left: 20px;
}

.itemCount {
  color: #999999;
  font-size: 10px;
  font-family: $fontBaseV2;
  text-align: right;
  padding-right: 4px;
}