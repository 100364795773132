@import '~theme/modules/variables';
@import '~theme/mixins';

.footerSection {
  padding-top: 177px;
  padding-bottom: 137px;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding-left: 64px;
    padding-right: 158px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .authWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .signUpBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-bottom: 22px;

    &:hover {
      color: #fff;
      background-color: var(--site-theme-color);
    }
  }

  .seePurchase {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;

    a {
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@include breakpoint-sm {
  .footerSection {
    padding-top: 205px;
    padding-bottom: 237px;
    margin-top: 0;

    .content {
      flex-direction: column;
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      margin-bottom: 71px;
    }

    .authWrapper {
      align-items: center;
    }

    .signUpBtn {
      width: 295px;
      font-size: 13px;
      margin: 0;
    }
  }
}
