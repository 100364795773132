@import '~theme/modules/variables';
@import '~theme/mixins';

.root {
  display: flex;
  margin: 30px auto;
  width: 100%;
  max-width: 600px;
  background-color: #f4f4f4;
  position: relative;
  height: 385px;

  &.loading {
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #f4f4f4;
      opacity: 0.3;
      z-index: 1;
    }
  }

  button {
    &.fakeDisabled {
      background: #ccc !important;
    }
  }
}

.content {
  width: 410px;
  position: relative;
}

.calendar {
  width: 350px;

  :global(.dialog-date-picker .calendar-wrapper.single) {
    width: 284px; // fix wrapper width for animation
  }

  :global(.dialog-date-picker .month-calendar.isAnimating) {
    left: -309px // fix position for animation
  }

  :global(.dialog-date-picker.open),
  :global(.dialog-date-picker.hide) {
    top: 0 !important;
    left: 0;
    animation: none;
    display: block !important;
    visibility: visible;
    transform: scale(1);
    opacity: 1;
    box-shadow: none;
    border-radius: 0;
    background-color: #f2f2f2;
  }
  :global(.dialog-header),
  :global(.date-picker-input),
  :global(.dialog-footer) {
    display: none !important;
  }

  :global(.calendar-flippers .flipper-button:first-child) {
    margin-left: 30px;
  }

  :global(.calendar-flippers .flipper-button:last-child) {
    margin-right: 70px;
  }

  :global(.month-calendar .month-name) {
    text-align: center;
  }
}

.controls {
  width: 150px;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}

.date,
.time :global(.react-time-picker__wrapper) {
  border: 0 none;
  border-bottom: 1px solid #e1e1e1;
  font-weight: bold;
  color: #000000;
  font-size: 15px;
  margin-bottom: 15px;
}

.time {
  :global(.react-time-picker__inputGroup__input) {

  }
}

.scheduleBtn, .deleteBtn {
  margin-bottom: 10px;
  margin-top: 42px;
}
