@import '../../../variables';

.external_tutorials {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .external_tutorials__header {
    display: flex;
    flex-direction: column;
    margin: 0 16px;

    .external_tutorials__header_input {
      display: flex;
      align-items: center;
      position: relative;

      input {
        padding-left: 16px;
        padding-right: 245px;
      }
    }

    .external_tutorials__header_buttons {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      height: 100%;

      .filter_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        margin: 0;
      }
      .vertical_line {
        width: 1px;
        height: 24px;
        background: rgba(0, 0, 0, 0.2);
      }
      .add_new_external_btn {
        margin: 0;
        @include defaultFont;
        font-weight: 600;
        padding-left: 20px;
        padding-right: 16px;
      }
    }
  }

  .external_tutorials__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 5px;
  }

  .external_tutorials__footer {
    display: flex;
  }

  .external_tutorials__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: white;
    height: 100%;

    span {
      @include defaultFont;
      margin-bottom: $defaultHeightBtn;
    }
  }

  .external_tutorials__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: white;

    .external_tutorials__list_item {
      display: flex;
      align-items: center;
      padding: 16px 0;
      background: white;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      .external_tutorial_menu {
        position: relative;
        display: flex;
        align-items: center;
        align-self: flex-start;
        height: 24px;

        .external_tutorial_menu__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 0 16px;
          opacity: 0.4;

          &:hover {
            opacity: 1;
          }
        }

        .external_tutorial_menu__list {
          display: none;
          position: absolute;
          left: 100%;
          top: -1px;
          z-index: 1;
          flex-direction: column;
          background: white;
          border: 1px solid rgba(0, 0, 0, 0.2);

          &.is_show {
            display: flex;
          }
        }

        .external_tutorial_menu__list_item {
          display: flex;
          align-items: center;
          padding: 16px;
          @include defaultFont;
          white-space: nowrap;

          &:hover {
            background: var(--background-color);
          }
        }
      }

      &:hover {
        background: var(--background-color);

        .external_tutorials__list_item_open,
        .external_tutorials__list_item_add {
          visibility: visible;
        }
      }

      &:first-child {
        border-top: none;
      }

      label {
        margin: 0;

        span {
          @include defaultFont;
          word-break: break-word;
          margin-right: 16px;
        }

        .external_tutorials__list_item_open {
          visibility: hidden;
          opacity: 0.4;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }

      .external_tutorials__list_item_add {
        @include defaultFont;
        font-weight: 600;
        padding: 0 16px;
        margin-left: auto;
        visibility: hidden;
        align-self: flex-end;
      }
    }
  }

  &.v2 {
    .external_tutorials__header external_tutorials__header_input input {
      padding-right: 185px;
    }
  }
}

.adding_external_tutorials {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding-left: 16px;
  padding-right: 5px;

  .adding_external_tutorials__form {
    background: white;
    flex: 1;

    .adding_external_tutorials__form_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      // row-gap: 16px;
      padding: $defaultHeightBtn 24px;

      .or_separator {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: $defaultHeightBtn;
        @include defaultFont;
      }

      button {
        max-width: 320px;

        &:last-child {
          margin-top: 16px;
        }
      }

      input {
        padding: 0 16px;
      }

      .inputWrapper {
        position: relative;
        width: 100%;
      
        .icon {
          position: absolute;
          left: 16px;
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      
        input {
          padding-left: 50px;
        }
      }
    }
  }

  .button {
    width: 100%;
    margin: 0;
  }

  &.v2 {
    .adding_external_tutorials__form {
      .adding_external_tutorials__form_content {
        padding-top: 16px;
      }
    }
  }
}

.updating_external_tutorials {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  z-index: 3;

  .updating_external_tutorials__form {
    margin: 0 16px;
    padding-top: 16px;
    background: white;
    height: 100%;

    .updating_external_tutorials__form_title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      @include defaultFont;
      text-align: center;
      margin: 0;
    }

    .updating_external_tutorials__form_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;
      padding: 24px;

      .or_separator {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: $defaultHeightBtn;
        @include defaultFont;
      }

      button {
        max-width: 320px;
      }

      input {
        padding: 0 16px;
      }
    }

    .updating_external_tutorials__form_footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;
      padding: 24px;

      button {
        min-width: auto;
        max-width: 320px;
      }
    }
  }
}

.choose_external_tutorials {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  z-index: 3;
  display: flex;
  flex-direction: column;

  .choose_external_tutorials__header {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    background: white;

    .choose_external_tutorials__header_title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: $defaultHeightBtn;
      @include defaultFont;
      margin: 0;
    }

    .choose_external_tutorials__header_input {
      display: flex;
      align-items: center;
      position: relative;

      input {
        padding-left: 16px;
        padding-right: $defaultHeightBtn;
      }

      label {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0;
        width: fit-content;
        padding-right: 6px;

        .reset {
          font-size: 18px;
          margin-right: 10px;
          font-weight: 500;
        }
      }
    }
  }

  .choose_external_tutorials__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 5px;
  }

  .choose_external_tutorials__footer {
    display: flex;
    justify-content: center;
    column-gap: 24px;
  }

  .choose_external_tutorials__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background: white;
    height: 100%;

    span {
      @include defaultFont;
      margin-bottom: $defaultHeightBtn;
    }
  }

  .choose_external_tutorials__list {
    display: flex;
    flex-direction: column;

    .choose_external_tutorials__list_item {
      display: flex;
      align-items: center;
      column-gap: 20px;
      padding: 16px;
      background: white;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      &.selected {
        .choose_external_tutorials__list_item_check {
          visibility: visible;
        }
      }

      &:hover {
        background: var(--background-color);

        .choose_external_tutorials__list_item_open {
          visibility: visible;
        }
      }

      &:first-child {
        border-top: none;
      }

      label {
        margin: 0;

        span {
          @include defaultFont;
          word-break: break-word;
          margin-right: 16px;
        }

        .choose_external_tutorials__list_item_open {
          visibility: hidden;
          opacity: 0.4;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }

      .choose_external_tutorials__list_item_check {
        margin: 0;
        margin-left: auto;
        visibility: hidden;
        align-self: flex-end;
      }
    }
  }

  &.disabled {
    & > div {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

.choose_products {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  z-index: 3;
  display: flex;
  flex-direction: column;

  .choose_products__header {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    background: white;

    .choose_products__header_title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: $defaultHeightBtn;
      @include defaultFont;
      margin: 0;
    }

    .choose_products__header_input {
      display: flex;
      align-items: center;
      position: relative;

      input {
        padding-left: 16px;
        padding-right: 120px;
      }

      label {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0;
        @include defaultFont;
        font-weight: 600;
        padding-right: 16px;
      }
    }
  }

  .choose_products__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 5px;
  }

  .choose_products__footer {
    display: flex;
  }

  .choose_products__list {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: white;

    .choose_products__list_empty {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 16px;
      @include defaultFont;
    }

    .choose_products__list_item {
      display: flex;
      align-items: center;
      column-gap: 20px;
      padding: 16px;
      background: white;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      &.selected {
        svg {
          visibility: visible;
        }
      }

      &:hover {
        background: var(--background-color);
      }

      &:first-child {
        border-top: none;
      }

      span {
        @include defaultFont;
        flex-grow: 1;
      }

      svg {
        visibility: hidden;
      }

      .img {
        width: 24px;
        height: 24px;
        flex-grow: 0;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.external_tutorials__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.4);
  cursor: alias;
}

.button {
  position: relative;
  padding-left: 50px;
  margin: 0 16px;
  width: calc(100% - 32px);
  padding: 10px 40px;

  .icon {
    position: absolute;
    left: 24px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      max-width: 16px;
      max-height: 16px;
    }
  }
}