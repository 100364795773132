@import '~theme/modules/variables';
@import '~theme/mixins';

.menu {
  display: flex;
  align-items: center;
  column-gap: 42px;

  .menu_item {
    position: relative;
    cursor: pointer;
    color: black;

    > span,
    > a {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: inherit;
    }

    &:hover,
    &.active {
      color: var(--site-theme-color);
    }
  }
}

.default_menu {
  position: absolute;
  left: 0;
  top: 100%;
  transform: translate(-23px, 14px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: white;
  padding: 12px 22px 35px;
  display: flex;
  column-gap: 49px;
  min-width: 223px;
  cursor: default;

  .default_menu__column {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .default_menu__title {
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    color: black;
    white-space: nowrap;
  }

  .default_menu__link {
    font-size: 15px;
    line-height: 22px;
    color: black;
    white-space: nowrap;

    &:hover,
    &.active {
      color: var(--site-theme-color);
    }
  }
}

.features_menu {
  position: absolute;
  left: 0;
  top: 100%;
  transform: translate(-23px, 14px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: white;
  display: flex;
  flex-direction: column;
  cursor: default;

  .features_menu__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    column-gap: 30px;
    background: #f4f4f4;
    height: 48px;
    padding: 0 23px;

    .features_menu__header_item {
      font-size: 15px;
      line-height: 22px;
      font-weight: 600;
      color: black;
      width: 100%;
      min-width: 165px;
    }
  }

  .features_menu__content {
    display: flex;
    column-gap: 30px;
    padding: 25px 23px 0;

    .features_menu__content_col {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;
      min-width: 165px;

      .features_menu__content_col_link {
        display: flex;
        flex-direction: column;

        label {
          font-size: 15px;
          line-height: 22px;
          font-weight: 400;
          color: black;
          margin: 0;
          cursor: inherit;
        }

        span {
          font-size: 10px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.4);
        }

        &:hover,
        &.active {
          label,
          span {
            color: var(--site-theme-color);
          }
        }
      }
    }
  }

  .features_menu__footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px 15px 12px;

    a {
      font-size: 15px;
      line-height: 22px;
      font-weight: 600;
      color: black;
      display: flex;
      align-items: center;
      transition: none;

      span {
        margin-right: 19px;
      }

      &:hover {
        color: var(--site-theme-color);

        svg path {
          fill: var(--site-theme-color);
        }
      }
    }
  }
}

.solutions_menu {
  padding: 12px 21px 12px 22px;
  min-width: 450px;
  display: flex;
  flex-direction: column;

  .solutions_menu__content {
    display: flex;
    column-gap: 49px;
  }

  .solutions_menu__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 27px;

    a {
      font-size: 15px;
      line-height: 22px;
      font-weight: 600;
      color: black;
      display: flex;
      align-items: center;
      transition: none;

      span {
        margin-right: 19px;
      }

      &:hover {
        color: var(--site-theme-color);

        svg path {
          fill: var(--site-theme-color);
        }
      }
    }
  }
}

.menu_mobile {
  display: flex;
  flex-direction: column;
  row-gap: 22px;

  .menu_mobile__item {
    cursor: pointer;
    color: black;

    > span,
    > a {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: inherit;
      display: block;
    }

    &:hover,
    &.active {
      color: var(--site-theme-color);
    }

    &.active {
      .menu_mobile__subs {
        display: flex;
      }
    }
  }

  .menu_mobile__subs {
    display: none;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 20px;
    padding-left: 31px;

    .menu_mobile_subs_link {
      font-size: 15px;
      line-height: 22px;
      color: black;
      white-space: nowrap;

      &:hover,
      &.active {
        color: var(--site-theme-color);
      }
    }
  }
}
