@use "sass:math";
@import '~theme/variables';
@import '~theme/modules/variables';
@import '~theme/mixins';
@import '../styles/common';

$checkmark-icon: '<svg id="Content" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 12"><defs><style>.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><title>checkbox-mark</title><g id="Table_content" data-name="Table content"><polyline id="Check_icon" data-name="Check icon" class="cls-1" points="1 8 4 11 13 1"/></g></svg>';

$text-field-width: 240px;
.new_plan_modal,
.add_plan_modal {
  padding-top: 55px;
  @include breakpoint-sm {
  }

  :global(.gl-content-wrapper) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  :global(.popup-header) {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;
  }

  .footer {
    background-color: #fff;
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    padding: 15px;
    text-align: center;
    width: 100%;

    @include breakpoint-sm {
      flex-direction: column;
      row-gap: 10px;
      position: sticky;

      :global(.btn-fancy) {
        margin: 0;
      }
    }

    :global(.btn-fancy) {
      margin: 0 7px;
    }
  }

  :global(.text-field),
  :global(.iorad-popover-toggle),
  :global(.dropdown-toggle) {
    font-size: 14px;
    position: relative;
    top: 31px;
    width: $text-field-width;
    padding: 9px 20px;
    height: 40px;

    @include breakpoint-sm {
      width: 100%;
    }

    &:hover {
      + .label {
        color: $labelhover;
      }
    }

    &:focus {
      + .label {
        color: $blackgrey;
      }
    }
  }
  :global(.react-google-flight-datepicker) {
    position: relative;
    top: 31px;
    height: 40px;
  }

  :global(.focusable) {
    top: 31px;
    width: $text-field-width;

    @include breakpoint-sm {
      width: 100%;
    }
  }

  :global(.iorad-popover) {
    top: 48px !important;

    ul {
      min-width: $text-field-width;
      left: -(math.div($text-field-width, 2));

      &::before { // Arrow
        $arrow-size: 20px;

        position: relative;
        content: '';
        width: 0;
        height: 0;
        left: math.div($text-field-width, 2) - math.div($arrow-size, 2);
        top: -29px;
        border-style: solid;
        border-width: 0 math.div($arrow-size, 2) math.div($arrow-size, 2) math.div($arrow-size, 2);
        border-color: transparent transparent #ffffff transparent;
      }
    }
  }

  :global(.iorad-popover-toggle) {
    &::after { // Down arrow
      left: $text-field-width - 26;
    }
  }

  :global(.easy-checkbox) {
    &:after {
      background-color: #eee;
    }

    ~ label {
      position: relative;
      bottom: 1px;
      left: 4px;
      font-weight: normal;
      cursor: pointer;
      user-select: none;
    }
  }
  .base_price_container {
    margin-bottom: 30px;
  }

  label {
    input[type="checkbox"] + span {
      font-weight: normal;
      display: inline-block;
      padding-left: 5px;
      vertical-align: baseline;
      position: relative;
      top: -1px;
      cursor: pointer;
    }
  }
}

.change_password_modal {
  @extend .new_plan_modal;

  justify-content: center;

  .label {
    text-align: left;
  }
}

.tabs {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 20px;

  .tab {
    font-size: 16px;
    border: none;

    li, a {
      height: 40px;
    }

    a, a:hover {
      border: none;
      border-bottom: 3px solid transparent;
      color: #777;
      outline: 0;
      font-size: 14px;
      font-family: 'Libre Franklin';
      padding-left: 0;
      padding-right: 0;
      margin-left: 26px;
      margin-right: 26px;

      @include breakpoint-sm {
        margin-left: 14px;
        margin-right: 14px;
        font-size: 13px;
      }

      &:hover {
        color: #000;
        background: transparent;
      }
    }

    :global(.active) a,
    :global(.active) a:hover,
    :global(.active) a:focus {
      color: #000;
      border: none;
      border-bottom: 3px solid #000;
    }
  }
}

.row {
  height: auto;
  margin: 48px 50px 45px 40px;
  font-size: 16px;
  margin: 0 40px;
  text-align: center;

  @include breakpoint-sm {
    font-size: 12px;
    margin: 0;
    left: 0;
    position: relative;
    align-self: center;
    width: 100%;
  }
}

.column {
  display: inline-block;
  margin: 23px 30px 23px 42px;
  vertical-align: top;

  @include breakpoint-sm {
    margin: 10px 0;
    width: 100%;
  }
}

.label {
  bottom: 40px;
  position: relative;
}

.tab_settings {
  .row {
    text-align: left;

    &:first-child {
      .column {
        margin-top: 28px;
      }
    }

    @include breakpoint-sm {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .invite_email {
    position: relative;
    top: 31px;
    max-width: $text-field-width;

    @include breakpoint-sm {
      max-width: 100%;
    }

    :global(.invite-icon) {
      display: none;
    }

    .item {
      line-height: 23px;
      padding: 0 32px 1px 6px;
      margin: 0 1px 3px;

      span {
        font-size: 13px;
        line-height: 23px;
      }
    }

    .list {
      position: absolute;
      margin-top: 0;
      z-index: 2;

      .option {
        padding: 3px 20px;
        white-space: nowrap;
      }
    }

    .email_input_container {
      display: block;
      margin: 0;

      :global(.tooltip) {
        left: 0 !important;
        top: 0 !important;

        &:global(.right) {
          margin-left: 0;
          left: $text-field-width !important;
          white-space: nowrap;
          top: -34px !important;
        }
      }
    }

    .email_address_input {
      min-width: $text-field-width !important;
      width: $text-field-width !important;
      box-sizing: border-box !important;
      padding: 9px 20px;
      top: 0;

      @include breakpoint-sm {
        min-width: 100% !important;
        width: 100% !important;
      }
    }
  }

  .cc_required {
    top: 18px;
    position: relative;
  }
  .trial_quota_required {
    top: 18px;
    position: relative;
  }
}

.tab_features {
  $table-width: 1000px;
  $table-data-height: 60vh;


  display: flex;
  flex-direction: column;

  .table {
    min-width: $table-width;

    @include breakpoint-sm {
      min-width: 370px;
    }

    $table-header-font-size: 12px;
    $table-header-height: 29px;

    .table_header {
      width: 100%;
      text-align: left;
      border-bottom: 1px solid $table-header-border;
      border-top: 1px solid $table-header-border;
      color: $table-header-color;
      font-size: $table-header-font-size;
      font-weight: bold;
      text-transform: uppercase;
      height: $table-header-height;
      margin-bottom: 12px;
      padding-left: 10px;
      display: flex;
      align-items: center;
    }

    .header_col,
    .data_col {
      width: 33%;
      white-space: nowrap;
      display: flex;
      align-items: center;

      &.enableUpdateColumn {
        width: 12%;
        text-align: left;

        input[type="checkbox"] {
          margin: 0 6px 0 0;
        }
      }

      &.featureColumn,
      &.subFeatureColumn {
        width: 60%;
        text-align: left;
      }

      &.subFeatureColumn {
        padding-left: 25px;

        &.noGroupTitle {
          padding-left: 0;
        }
      }

      &.quantityColumn {
        width: 20%;
        text-align: left;

        > input[type="checkbox"] {
          margin: 0 6px 0 0;
        }

        label {
          font-weight: normal;
          margin-bottom: 0;

          input[type="checkbox"] {
            margin-right: 10px;
          }
        }

        .quantityField {
          width: 60px;
          margin-right: 15px;
        }
      }
    }

    .header_col {
      font-size: 10px;

      .switch {
        margin-right: 10px;
      }

      @include breakpoint-sm {
        padding: 0 13px;
      }
    }

    .table_data {
      height: calc(#{$table-data-height} - 70px);

      @include breakpoint-sm {
        height: calc(#{$table-data-height + 24} - 70px);
      }

      .data_row {
        height: 55px;
        text-align: left;
        padding-left: 10px;
        display: flex;
        align-items: center;

        .data_col {
          height: 100%;

          :global(.text-field) {
            width: 60px;
            top: 0;
            padding: 10px;
            text-align: center;
            margin-right: 15px;

            @include breakpoint-sm {
              width: 50px;
              margin-right: 0;
              padding: 10px 5px;
            }
          }

          .feature_title, .feature_head_title {
            font-size: 15px;
            margin-left: 10px;
            cursor: pointer;
            font-weight: normal;
          }
        }

        &:hover {
          background-color: #f9f9f9;
        }
      }

    }
  }

  .select_helper {
    position: sticky;
    display: block;
    margin-left: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.176);
    padding: 20px;
    width: 167px;
    bottom: 40%;
    text-align: left;

    @include breakpoint-sm {
      display: none;
    }
  }
}

.tab_price {
  .row {
    width: 267px;
    margin: auto;
    text-align: left;

    @include breakpoint-sm {
      width: 100%;
    }

    &.user_credit {
      width: auto;
      display: flex;
      margin-bottom: 20px;

      .user_credit_item {
        width: auto;
        display: flex;
        flex-direction: column;
        margin-right: 15px;

        .user_credit_item_label {
          bottom: 0;
          white-space: nowrap;
        }

        .user_credit_item_data {
          font-size: 14px;
          font-weight: normal;
          line-height: normal;

          :global(.amount) {
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
          }
        }
      }
    }
  }

  label {
    font-weight: 600;
    cursor: pointer;

    :global(.easy-checkbox) {
      margin: 4px 5px 0 0;
      top: 2px;
    }
  }

  :global(.text-field) {
    width: 150px;
  }


  .base_price_label {
    margin-bottom: 13px;
  }

  .base_price_container {
    white-space: nowrap;

    &.hidden {
      display: none;
    }

    :global(.text-field) {
      top: 0;
      margin-top: 0;
      width: 100px;
      margin-right: 20px;
    }

    .included_creators {
      display: inline-block;

      .input {
        width: 38px;
        text-align: center;
        padding: 9px 9px;
        display: inline-block;
        margin-right: 7px;
      }

      .description {
        display: inline-block;
      }
    }
  }

  .creator_price_container {
    height: 88px;
  }

  .creators_row {
    .column {
      margin: 20px 0 10px;
    }

    .separator {
      margin: 59px 45px 10px 40px;
    }

    :global(.text-field) {
      width: 70px;
    }
  }

  .user_creators_row {
    margin-top: 10px;

    :global(.text-field) {
      width: 70px;
    }
  }

  .live_row {
    margin-top: 32px;

    :global(.easy-select) {
      top: 0;
      margin-top: 10px;
    }
  }
}

.tab_summary {
  .listing:not(:last-child) {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 24px;
    padding-bottom: 22px;
  }

  .listing_title {
    margin-bottom: 14px;
  }

  .column {
    margin: 3px 0;
    width: 50%;
  }

  .title {
    text-align: left;
    white-space: nowrap;
  }

  .value {
    text-align: right;
  }

  .hidden {
    display: none;
  }

  :global(.scrollbar) {
    ::-webkit-scrollbar {
      visibility: hidden;
    }
  }
}

.hidden {
  visibility: hidden;
}

.add_plan_scrollbars {
  max-width: 395px;
  top: 10vh;
}

.add_plan_modal {
  .head_row {
    align-self: center;

    .checkmark {
      background-image: svg-url-with-replaced-fill($checkmark-icon, '#000', '#00cf48');
      background-repeat: no-repeat;
      width: 20px;
      height: 16px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 58px;
      margin-bottom: 32px;
    }

    .plan_name_added {
      font-weight: 600;
      font-size: 20px;
    }
  }

  .direct_link_row {
    margin: 80px 0 0 0;
    text-align: left;

    :global(.text-field) {
      width: 156px;
      color: $link-fancy-blue;
      cursor: pointer;
    }
  }

  .invite_row {
    text-align: left;
    margin: 70px 0px 0px 0;

    .label {
      bottom: 0;
    }

    $border-radius: 5px;
    .invite_email {
      max-width: 367px;
      border: 1px solid transparent;
      border-radius: $border-radius;

      &:hover {
        border: 1px solid $text-field-border-hover;
      }

      &.focused {
        border: 1px solid blue;
      }
    }

    .input_container {
      margin: 0;
    }

    .address_input {
      border: none;
      outline: none;
      height: 18px;
      min-width: 325px;
      top: 0;
      padding: 9px 20px;
      background-color: $text-field-bg;
      border-radius: 5px;

      &:focus {
        background-color: $text-field-bg-focus;

        &::placeholder {
          opacity: 0;
        }
      }
    }

    .item {
      display: block;
      margin: 0;
      border-radius: 0;
      background: transparent;
      padding: 9px 20px;
      height: 38px;
      line-height: 17px;

      &:first-child {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }

      &:hover {
        background: #f2f2f2;
      }

      ~ span {
        .address_input {
          border-radius: 0;
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }

      span {
        background: transparent;
        font-size: 14px;
        line-height: 20px;
        color: #414141;
      }

      button {
        border-radius: 0;
        color: $blue;
        background: none;

        &:hover {
          background: none;
        }
      }
    }

    .buttons {
      margin-top: 20px;
    }
  }
}

.tab_status {
  .sub_info {
    width: 80%;
    margin: auto;

    .line {
      height: 24px;

      .text {
        float: left;
      }
      .value {
        float: right;
      }
    }
  }

  .invoicing {
    margin-top: 20px;

    span {
      margin: 5px;
    }

    button {
      margin: 10px;
    }

    .active {
      color: $green;
    }
  }
}

.planSubs {
  :global(.popup-content) {
    height: 100%;
    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  :global(.search) {
    margin: 20px 20px 20px 0;
  }
  :global(.sticky-table) {
    .email,
    .url {
      color: $blue;
      cursor: pointer;
      &:hover {
        background-color: rgba(107, 107, 107, .1);
      }
    }
    :global(.btn-warning) {
      margin-left: 5px;
    }
    tr.edited {
      td.name {
        position: relative;

        &::after {
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          right: 0;
          top: calc(50% - 8px);
          opacity: .5;
          background-image: url("../../../theme/icons/edit.svg");
          background-position: center;
          background-size: auto 16px;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .loading_dot {
    margin: 10px auto;
  }
}

.changePlan {
  :global(.popup-content) {
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;

    :global(.dropdown-toggle) {
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      :global(.caret) {
        margin-left: 5px;
      }
    }
  }

  :global(.easy-select) {
    width: 100%;
  }

  :global(.search) {
    padding-left: 25px;
    margin: 15px 0;
    width: 100%;
  }
}

.planInfo {
  white-space: nowrap;
}

.maxCreators {
  white-space: nowrap;
}

.noMargin {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.noLeftMargin {
  margin-left: 0;
  text-align: left;
}
.noRightMargin {
  margin-right: 0;
  text-align: right;
}

.loading_dot {
  position: relative;
  width: 11px;
  color: black;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  @keyframes dots {
    to {
      width: 1em;
    }
  }

  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    content: '\2026';
    width: 0px;
    animation: dots 1s steps(4, end) infinite;
  }
}