@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  display: flex;
  margin-top: -18px;

  * {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }
}

.titleItem {
  font-weight: 600;
  font-size: 21px;
  line-height: 116%;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: var(--site-theme-color);
  }
}

.tableWrapper {
  width: 100%;

  table {
    width: 100%;
    border: none;

    tr {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
      height: 53px;
    }

    th {
      white-space: nowrap;
    }

    th,
    td {
      padding: 0 18px;
    }
  }
}

.lastUpdated {
  font-size: 10px;
  line-height: 150%;
  display: block;
  margin-top: 56px;
  margin-bottom: 121px;
}

@include breakpoint-sm {
  .container {
    margin-top: 0;

    * {
      font-size: 13px;
    }
  }

  .titleItem {
    font-size: 13px;
  }

  .tableWrapper {
    padding-top: 32px;
    overflow-x: auto;

    table {
      width: 100%;
      border: none;

      tr {
        height: 58px;

        &.headerTable {
          height: 40px;
        }

        th:first-child,
        td:first-child {
          padding-left: 24px;
        }

        th:last-child,
        td:last-child {
          padding-right: 24px;
          min-width: 90px;
        }

        td:nth-child(2) {
          padding: 0 20px;
        }
      }

      th,
      td {
        padding: 0;
        font-size: 10px !important;
        line-height: 150% !important;
      }

      th {
        text-align: center;

        &:first-child {
          text-align: left;
        }
      }

      a {
        font-size: 10px;
      }
    }
  }

  .lastUpdated {
    font-size: 10px;
    padding: 0 24px;
    margin-top: 67px;
    margin-bottom: 226px;
  }
}
