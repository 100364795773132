@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/Account/partials';

.container {
  margin: 0 30px 30px;

  .topMenu {
    margin: 20px 30px 40px;
    display: flex;
    align-items: flex-start;
  }

  .addDomainForm {
    margin-left: 40px;
    padding: 0 10px 0 40px;
    border-left: 1px solid #E0E0E0;

    .errorWrapper {
      display: block;
      height: 18px;
      padding-top: 4px;

      .error {
        font-family: "Libre Franklin", sans-serif;
        font-weight: 300;
        font-size: 12px;
        color: var(--site-theme-color);
      }
    }

    .formGroup {
      display: flex;
      align-items: flex-end;

      .inputWrapper {
        display: flex;
        flex-direction: column;
        margin-right: 25px;

        .label {
          font-family: "Libre Franklin", sans-serif;
          font-weight: 300;
          line-height: 24px;
          font-size: 10px;
          margin-bottom: 8px;
        }

        .input {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #E0E0E0;
          padding: 0;
          font-family: "Libre Franklin", sans-serif;
          height: 33px;
          outline: none;
        }

        .select {
          min-height: 33px;
          width: auto;
          min-width: 275px;
        }

        :global(.react-selectize.root-node.default) {
          :global(.react-selectize-control) {
            :global(.react-selectize-toggle-button-container) {
              width: auto;
            }

            :global(.react-selectize-search-field-and-selected-values) {
              flex-wrap: nowrap;
              width: 100%;
              overflow: hidden;

              :global(.value-wrapper) {
                width: 100%;
                position: relative;
                padding-left: 2px;
              }

              :global(.resizable-input) {
                position: absolute;
                left: 0;
              }

              :global(.simple-value) {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
              }
            }
          }
        }

      }

      .button {
        &[disabled] {
          pointer-events: none;
        }
      }
    }
  }

  .headerSortable {
    cursor: pointer;
  }

  .headerActive {
    font-weight: bold;
    color: black;
  }

  .deleteIcon {
    cursor: pointer;
  }

  .hideIcon {
    visibility: hidden;
  }
}
