@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/AccountV2/partials';

.aiSettings {
  display: flex;
  padding-bottom: 40px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  .loading {
    padding: 40px 0;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;

    .formContent {
      display: flex;
      flex-direction: column;
    }

    .formFooter {
      display: flex;
      justify-content: center;
      margin-top: 80px;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: not-allowed;
      }

      .showModalBtn {
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 15px;
        line-height: 20px;
        color: black;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .formBlock {
      display: flex;
      flex-direction: column;
      row-gap: 40px;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: not-allowed;
      }

      &.sticky {
        position: sticky;
        z-index: 1;
        top: 0;
        background: white;
        padding: 40px 0;
      }
    }

    .formGroup {
      display: flex;
      column-gap: 64px;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: not-allowed;
      }

      &.nested {
        .formLabel {
          padding-left: 40px;
        }
      }

      &.formHeader {
        label {
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          margin: 0;
          flex-grow: 1;

          &:nth-child(2) {
            max-width: 256px;
          }
          &:nth-child(3) {
            max-width: 200px;
          }
        }
      }

      .formLabel {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 316px;

        label {
          display: flex;
          align-items: center;
          flex-grow: 1;
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: black;
          margin: 0;
        }

        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
        }
      }

      .textInput {
        border: none;
        padding: 0;
        outline: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        height: 40px;
        font-size: 15px;
        line-height: 20px;
        width: 100%;
        min-width: 150px;
      }

      :global(.react-selectize) {
        width: 100%;
        max-width: 256px;

        &:global(.root-node) {
          max-height: 40px;
        }

        :global(.dropdown-menu) {
          max-width: calc(100% + 40px);
        }
      }

      :global(.react-selectize-search-field-and-selected-values) {
        min-height: 40px !important;
      }

      :global(.react-selectize-toggle-button-container) {
        width: 40px !important;
        height: 40px !important;
      }

      :global(.resizable-input) {
        width: 0 !important;
      }

      .quotaContainer {
        flex-grow: 1;
        max-width: 200px;
        display: flex;
        flex-direction: row;
        gap: 60px;
        padding-top: 10px;

        span {
        }
        a {
          font-weight: bold;
        }
      }

      &.checkboxWrapper {
        flex-direction: column;

        .checkbox_label {
          font-weight: 400;
          font-size: 15px;
          margin-bottom: 24px;

          input[type='checkbox'] {
            margin: 0;
            margin-right: 20px;
            position: relative;
            width: 16px;
            height: 16px;

            &::before,
            &::after {
              cursor: pointer;
              content: '';
              position: absolute;
            }

            &::before {
              border: 1px solid rgba($color: #000000, $alpha: 0.2);
              border-radius: 0;
              background-color: #fff;
              width: 100%;
              height: 100%;
            }

            &::after {
              height: 100%;
              width: 100%;
              background-image: url('~assets/img/icons/checkmark.svg');
              background-repeat: no-repeat;
              background-position: center;
              transition: background-size 0.1s;
              background-size: 0;
            }

            &:checked {
              &::after {
                background-size: 76%;
              }
            }
          }
        }

        .checkbox_help {
          width: 100%;
          padding: 10px;
          background: #f4f4f4;
          font-size: 15px;
          line-height: 20px;
          color: black;

          a {
            text-decoration: underline;
          }
        }
      }
    }

    .buttonSubmit {
      width: 246px;
      height: 56px;
      font-size: 15px;
      color: #fff;
      background: #000;
      font-weight: 600;
      border-radius: 0;
      border: none;
      outline: none;
      transition: background 0.3s ease;

      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &:hover {
        background: var(--site-theme-color);
      }
    }
  }

  .contentWrapper {
    flex-grow: 1;
  }

  .content {
    position: relative;
    display: flex;
    width: 100%;
  }

  &.extensionEmbed {
    .content {
      width: 100%;
      max-width: none;
      padding: 16px;

      .body {
        .formContent {
          .formBlock {
            .formGroup {
              &.formHeader {
                display: none;
              }
            }

            &.sticky {
              padding-top: 24px;
              padding-bottom: 24px;
              position: relative;
            }

            &:not(:first-child) {
              row-gap: 24px;

              .formGroup {
                display: flex;
                flex-direction: column;

                .formLabel {
                  padding-left: 0;

                  label {
                    font-weight: 600;
                  }

                  span {
                    margin-top: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.apiKeysModal {
  :global(.popup-header-wrapper) {
    pointer-events: all !important;
  }
  :global(.popup-header-wrapper .popup-header) {
    margin-left: 0;
    width: 100%;
    color: black;
    font-weight: 400;
  }
  :global(.popup-header-wrapper .close-button-tutorial .popup-header-returnTo) {
    width: 100%;
  }
  :global(.popup-content) {
    height: 100% !important;
    padding: 0 !important;
  }

  .apiKeysModal__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .apiKeysModal__form {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding: 24px 0;
    width: 100%;
    max-width: 320px;
  }

  .apiKeysModal__formGroup {
    display: flex;
    flex-direction: column;
  }

  .inputWrapper {
    position: relative;

    .label {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      margin: 0;
    }

    .inputContent {
      position: relative;
    }

    .inputHint {
      outline: none;
      border: none;
      background: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      opacity: 0.4;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        opacity: 1;
      }
    }

    .input {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 40px;
      width: 100%;
      font-size: 15px;
      line-height: 20px;
      outline: none;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding: 0;
      padding-right: 20px;

      &:focus {
        border-color: black;
      }
    }

    .helpBlock {
      position: absolute;
      top: 100%;
      left: 0;
      transform: translateY(-1px);
      width: 100%;
      max-width: 450px;
      padding: 10px;
      background: #f4f4f4;
      z-index: 1;
      font-size: 15px;
      line-height: 20px;
      color: black;

      &.--static {
        position: static;
      }

      a {
        text-decoration: underline;
      }

      ol {
        padding: 0;
        margin: 0;
        padding-left: 16px;

        li + li {
          margin-top: 10px;
        }
      }
    }

    .inputHelp {
      margin-top: 5px;
      padding: 10px;
      background: #f4f4f4;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }
  }

  .apiKeysModal__saveBtn {
    width: 100%;
    max-width: 246px;
    margin: 0 auto;
  }
}

@include breakpoint-sm {
  .aiSettings:not(.extensionEmbed) {
    .body {
      .formContent {
        flex-direction: column;
        row-gap: 24px;
      }

      .formFooter {
        margin-top: 24px;
      }

      .formBlock {
        row-gap: 16px;
        width: 100%;

        &.sticky {
          position: static;
          padding: 0;
        }
      }

      .formGroup {
        column-gap: 24px;

        &.formHeader {
          label:nth-child(3) {
            display: none;
          }
        }
        .quotaContainer {
          display: none;
        }

        :global(.react-selectize) {
          max-width: 150px;
          min-width: 150px;
        }
      }
    }
  }
}
