@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  background: #f4f4f4;
  padding: 71px 0 74px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleContent {
    @include ioradFont;
    padding-bottom: 101px;
  }

  .applyBtn {
    width: 276px;
    height: 48px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    transition: color 0.3s ease;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}

.supportSection {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
  background-position: bottom center;
  background-repeat: no-repeat;

  .supportContent {
    padding: 81px 64px 148px;
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .titleContent {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    margin: 0;
    margin-bottom: 26px;
  }

  .descWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    max-width: 616px;

    p {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }
  }
}

.revenueStreamsSection {
  padding: 52px 64px 87px;
  max-width: $maxWidthContent;
  margin: 0 auto;

  &__content {
    max-width: 809px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 75px;

    &:last-child {
      margin-bottom: 0;
    }

    h4 {
      font-size: 21px;
      font-weight: 600;
      line-height: 24px;
      color: black;
      margin: 0;
      margin-bottom: 6px;
    }

    p {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 28px;

      b {
        font-weight: 600;
      }
    }
  }

  &__videoFrame {
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}

.videoPopup {
  position: fixed;
  z-index: 40;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 86px 0;
  background-color: #000;
  z-index: #{$embedButtonZIndex + 1};

  .videoContent {
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    right: 64px;
    top: 24px;
    cursor: pointer;
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 206px 158px 167px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .footerBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;
    padding: 72px 0 13px;

    &.isLogged {
      margin-top: 46px;
    }

    .titleWrapper {
      padding: 0;

      img {
        width: 100%;
      }
    }

    .applyBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
      margin-top: 39px;
    }
  }

  .supportSection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_small.png');

    .supportContent {
      padding: 119px 24px 181px;
    }

    .titleContent {
      font-size: 21px;
      line-height: 28px;
      margin-bottom: 47px;
    }

    .descWrapper {
      max-width: 100%;

      p {
        font-size: 13px;
      }
    }
  }

  .revenueStreamsSection {
    padding: 27px 24px 87px;
    max-width: 100%;

    &__content {
      max-width: 100%;
    }

    &__item {
      margin-bottom: 70px;

      &:last-child {
        margin-bottom: 0;
      }

      h4 {
        margin-bottom: 21px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 38px;
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .footerBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }

  .videoPopup {
    padding: 0;

    .closeBtn {
      right: 24px;
    }

    .videoContent {
      display: flex;

      iframe {
        max-height: 211px;
        margin: auto 0;
      }
    }
  }
}
