@import '~theme/modules/variables';
@import '~theme/mixins';

.footerSection {
  padding-top: 106px;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .content {
    display: flex;
    align-items: flex-start;
    padding: 0 64px;
  }

  .logoFooter {
    margin-top: 4px;
  }

  .menuForMobile {
    display: none;
  }

  .menuList {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
  }

  .menuItem {
    margin-right: 64px;
    display: flex;
    flex-direction: column;
    min-width: 124px;

    @media screen and (max-width: 1255px) {
      margin-right: 55px;
    }

    @media screen and (max-width: 1170px) {
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;
    }

    .menuLink {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin-bottom: 16px;

      &:hover {
        color: var(--site-theme-color);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .menuTitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    display: block;
    margin-bottom: 22px;
  }

  .footer {
    display: flex;
    font-size: 15px;
    line-height: 136%;
    padding: 65px 64px 55px;
    color: #000000;
  }

  .leftFooter {
    display: flex;
  }

  .rightFooter {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    img {
      height: 16px;
      width: auto;
    }
  }

  .link + .link {
    margin-left: 32px;
  }
}

@include breakpoint-sm {
  .footerSection {
    padding-top: 0;

    .content {
      display: none;
    }

    .menuForMobile {
      display: flex;
    }

    .menuList {
      margin: 0;
      display: flex;
      column-gap: 32px;
      padding: 0 24px;
      width: 100%;
      justify-content: center;
    }

    .menuLink {
      font-size: 13px;
      line-height: 136%;
    }

    .footer {
      font-size: 10px;
      line-height: 150%;
      padding: 25px 24px 20px;
      flex-direction: column;
    }

    .leftFooter {
      flex-direction: column;
    }

    .rightFooter {
      order: -1;
      justify-content: center;
      margin-bottom: 57px;
    }
  }
}
