@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    background: #2891be;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 56px 64px 45px;
  }

  .titleContent {
    @include ioradFont;
  }

  .webinarsContent {
    display: flex;
    flex-direction: column;
    max-width: 1366px;
    margin: 75px auto 0;
    padding: 0 64px;

    &__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 24px;
    }

    &__desc {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000000;
      margin: 0;
      margin-bottom: 90px;
      max-width: 809px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 73px 20px;
      margin-bottom: 150px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 294px;

      &_img {
        width: 100%;
        height: 147px;
        margin-bottom: 21px;

        img {
          width: 100%;
        }
      }

      &_time {
        color: #000;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        opacity: 0.4;
        margin-bottom: 13px;
      }

      &_title {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin: 0;
        margin-bottom: 5px;
      }

      &_desc {
        font-size: 15px;
        line-height: 20px;
        color: #000000;
        margin: 0;
      }
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .title {
      padding: 0;

      img {
        width: 100%;
      }
    }
    .webinarsContent {
      padding: 0 24px;

      &__title {
        font-size: 21px;
        line-height: 116%;
      }

      &__list {
        flex-direction: column;
        flex-wrap: nowrap;
        row-gap: 39px;
      }

      &__item {
        width: 100%;

        &_img {
          height: 100%;
        }
      }
    }
  }
}
