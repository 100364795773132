@import '~theme/modules/variables';
@import '~theme/mixins';

.analytics__dropdown {
  display: flex;
  flex-direction: column;

  .analytics__dropdown_item {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 56px;
    padding: 0 16px;
    font-size: 15px;
    line-height: 20px;
    color: black;
    cursor: pointer;

    &:not(.locked):hover {
      color: var(--site-theme-color);
    }

    .analytics__dropdown_item_hover {
      display: flex;
      align-items: center;
      column-gap: 16px;
      visibility: hidden;
      font-weight: 600;
      margin-left: auto;

      svg {
        transform: rotate(270deg);
      }
    }

    &.locked {
      cursor: default;

      &:hover {
        .analytics__dropdown_item_hover {
          visibility: visible;
        }
      }
    }

    &.selected {
      color: var(--site-theme-color);
      span {
        flex-grow: 1;
      }
    }
  }
}

.analytics {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  color: black;

  &:not(.analytics__inside) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: white;
  }

  .analytics__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 24px;

    .analytics__header_line {
      width: 1px;
      height: 35px;
      background: rgba(0, 0, 0, 0.1);
      margin: 0 24px;
    }
  }

  .analytics__switch {
    display: flex;
    align-items: center;
    column-gap: 24px;

    .analytics__switch_item {
      display: flex;
      align-items: center;
      column-gap: 16px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;
      opacity: 0.5;

      &:hover,
      &.active {
        opacity: 1;
      }
    }
  }

  .analytics__selected_directory {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
  }

  .analytics__back {
    display: flex;
    align-items: center;
    column-gap: 16px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;

    svg path {
      fill: currentColor;
    }
  }

  .analytics__open {
    display: flex;
    align-items: center;
    column-gap: 16px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
    margin-left: auto;

    svg path {
      fill: currentColor;
    }
  }

  .analytics__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .analytics__content_header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    column-gap: 24px;
    padding: 16px 24px;
    max-height: 56px;
  }

  .analytics__content_header_left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .analytics__results {
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    flex-grow: 1;

    &.analytics__results_inside {
      font-size: 15px;
      line-height: 20px;
      flex-grow: 0;
    }
  }

  .analytics__refresh {
    margin-left: 16px;
    display: inline-flex;
    align-items: center;
    position: relative;

    svg {
      opacity: 0.5;
    }

    &:hover {
      svg {
        opacity: 1;
      }

      .analytics__refresh_text {
        display: block;
      }
    }

    .analytics__refresh_text {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: black;
      padding: 10px;
      background: #f4f4f4;
      display: none;
      width: 305px;
      transform: translate(16px, 16px);
    }
  }

  .analytics__chart_options {
    display: flex;
    align-items: center;
    column-gap: 24px;

    .analytics__chart_switch {
      display: flex;
      align-items: center;
      column-gap: 24px;

      .analytics__chart_switch_item {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        cursor: pointer;
        opacity: 0.5;

        &:hover,
        &.active {
          opacity: 1;
        }
      }
    }

    .analytics__chart_line {
      width: 1px;
      height: 35px;
      background: rgba(0, 0, 0, 0.1);
    }

    .analytics__chart_export {
      display: flex;
      align-items: center;
      column-gap: 16px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      cursor: pointer;

      svg path {
        fill: currentColor;
      }

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .analytics__content_body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
  }
}

.loading, .box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
  color: black;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  position: relative;
  font-size: 18px;
  text-transform: uppercase;
  background-color: #FFF;
}

.wrapper {
  display: flex;
  height: 100%;
  gap: 16px;
  flex-grow: 1;
  padding: 16px;
  background-color: #f4f4f4;
}

.sidebar {
  display: flex;
  height: 100%;
  flex-basis: 420px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #FFF;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #FFF;
  flex-grow: 1;
  overflow: hidden;
}