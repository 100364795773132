@import '~theme/mixins';

.integrationDetail {
  display: flex;
  flex-direction: column;

  &__images {
    display: flex;
    flex-wrap: wrap;
    gap: 39px;

    img {
      width: 100%;
      max-width: 357px;
      max-height: 204px;
      height: auto;
      object-fit: cover;
    }
  }

  &__description {
    margin-top: 37px;

    h4 {
      margin: 0;
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: black;
    }

    p {
      margin: 0;
      max-width: 697px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: black;
    }
  }

  &__iframes {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 100px;

    iframe {
      width: 784px;
      height: 440px;
      border: none;
      outline: none;
    }
  }
}

.preview_images {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: calc(100vw - 20%);
  height: calc(100vh - 20%);
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: none;
  &.show {
    display: flex;
  }

  :global(.slick-slider) {
    width: 100%;
  }

  :global(.slick-list),
  :global(.slick-track) {
    height: 100%;
  }

  :global(.slick-slide) > div {
    display: flex;
    height: 100%;
  }

  :global(.slick-prev) {
    left: -50px !important;
    &::before {
      content: none;
    }
  }
  :global(.slick-next) {
    right: -50px !important;
    &::before {
      content: none;
    }
  }

  .preview_images_img {
    display: flex !important;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }
}

@include breakpoint-sm {
  .integrationDetail {
    &__images {
      gap: 24px;

      img {
        max-width: 100%;
      }
    }

    &__description {
      margin-top: 32px;

      h4 {
        margin-bottom: 32px;
      }

      p {
        max-width: 100%;
        font-size: 13px;
        line-height: 18px;
      }
    }

    &__iframes {
      margin-top: 165px;

      iframe {
        width: 100%;
        height: 584px;
        border: none;
        outline: none;
      }
    }
  }
}
