@import '~theme/modules/variables';

.color_picker {
  position: relative;
  font-family: $fontBaseV2;

  .selectInput {
    display: flex;
    align-items: center;
    padding: 12px;

    &.open {
      svg {
        transform: rotate(180deg);
      }
    }

    span {
      width: 16px;
      height: 16px;
      border: 1px solid black;
      flex-shrink: 0;
    }

    label {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      white-space: nowrap;
      margin: 0 20px;
      flex-grow: 1;
      text-transform: uppercase;
    }

    svg {
      flex-shrink: 0;
    }
  }

  .swatch {
    padding: 0;
    background: #F2F2F2;
    display: inline-block;
    width: 100%;
    cursor: pointer;

    &.open {

    }
  }

  .colorWrapper {
    padding: 12px 0 12px 0;
    float: left;
  }

  .swatch > div.edit {
    font-weight: lighter;
    font-size: 16px;
    line-height: 20px;
    vertical-align: middle;
    text-transform: uppercase;
    padding: 10px 0 10px 27px;
    outline: 0px solid transparent;
    border: 1px solid transparent;
    border-radius: 5px;
    margin-top: 1px;

    &:focus {
      background-color: white;
      border: 1px solid #F2F2F2;
    }
  }

  &.simple {
    .swatch {
      background: none;
      border-radius: none;

      & > div.edit {
        border: 0 none;
        border-bottom: 1px solid #b8b8b8;
        border-radius: 0;
      }
    }
  }

  .popover {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    right: 0;
    margin-left: 0;
    margin-top: 13px;

    :global(.chrome-picker) {
      width: 100% !important;
      box-shadow: none !important;
    }

    > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) { /* hiding hex values */
      visibility: hidden;
    }
    > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(1) { /* hiding color */
      display: none;
    }
    > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:nth-child(2) { /* hiding alpha */
      display: none;
    }

    > div:nth-child(1) > div:nth-child(1) {
      padding-bottom: 124px !important;
    }

    > div:nth-child(1) > div:nth-child(2) {
      padding: 0 !important;
      height: 70 !important;

      :global(.flexbox-fix) {
        margin-top: 6px;
      }

      > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
        /* color slider */
        height: 8px !important;
        margin-bottom: 0 !important;

        > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
          /* circle handler in a slider */
          width: 5px !important;
          height: 16px !important;
          margin-top: -4px;
        }
      }
    }
  }

  .inputColorHex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 56px;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: black;
    text-transform: uppercase;
    font-weight: 400;
    width: 100%;
    position: absolute;
    top: 160px;
    caret-color: var(--site-theme-color);

    &[contenteditable="true"] {
      border-bottom-color: black;
  }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 153px;
    width: 100%;
  }

  .button {
    font-family: $fontBaseV2;
    width: 48% !important;
    min-width: unset;
    border-radius: 0;
  }

  .primarybtn {
    left: 0;
    background: black;
    color: white;
  }

  .cancelbtn {
    right: 0;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #000000;
  }

  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .disabledOverlay {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    cursor: default;
  }

  &.disabled {
    .disabledOverlay {
      display: block;
    }
  }
}
