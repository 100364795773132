@import '~theme/modules/variables';
@import '~theme/mixins';

.root {
  display: flex;
  height: 100vh;
  min-height: 600px;
  flex-direction: column;

  .header {
    position: absolute;
    left: 0;
    top: 0;
    .logo {
      padding: 24px 24px 40px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    padding: 16px;

    svg {
      color: inherit;
  
      [fill] {
        fill: currentColor;
        color: inherit;
      }
  
      [stroke] {
        stroke: currentColor;
        color: inherit;
      }
    }
  }

  .btnGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 100%;
  }

  h3 {
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    color: black;
    margin: 0;
    margin-bottom: 40px;
  }

  p {
    font-size: 15px;
    line-height: 20px;
    color: black;
    margin: 0;
    margin-bottom: 40px;
  }

  button {
    width: 100%;
    height: 56px;
    gap: 16px;
    max-width: 320px;
  }
}
