@import '~theme/mixins';
@import '~theme/modules/variables';

$backgroundColor: #e5e5e5;
.root {
  display: flex;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 15px;
  position: relative;

  .loading {
    text-align: center;
    padding: 50px;
  }

  .blocker,
  .overlay,
  .underlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(255, 255, 255, 0.5);
  }

  .underlay {
    z-index: 1;
  }

  .blocker {
    position: absolute;
    background: transparent;
    cursor: not-allowed;
    top: 65px;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    width: 400px;
    position: relative;

    .tabHeader {
      display: flex;
      align-items: center;
      gap: 40px;
      margin-top: 30px;
      padding-bottom: 12px;

      @include breakpoint-sm {
        margin-top: 24px;
      }

      .tabHeaderItem {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: black;
        cursor: pointer;
        margin: 0;

        &.active,
        &:hover {
          color: var(--site-theme-color);
        }
      }
    }

    .tabContent {
      padding: 35px 0;
      padding-right: 11px;
      overflow: hidden;
      flex-grow: 1;

      &:hover {
        overflow-y: scroll;
        padding-right: 0;
      }
    }
  }

  .content {
    background: $backgroundColor;
    width: calc(100% - 400px);

    .formGroup {
      :global(.react-selectize.root-node.default) {
        :global(.react-selectize-control) {
          :global(.react-selectize-search-field-and-selected-values) {
            :global(.value-wrapper) {
              &:after {
                content: '';
                height: 100%;
                width: 33px;
                position: absolute;
                right: 0;
                top: 0;
                background: linear-gradient(
                  to left,
                  $backgroundColor 13.81%,
                  rgba(255, 255, 255, 0) 99.61%
                );
              }
            }
          }
        }
      }
    }

    .demo {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      gap: 25px;
      padding: 20px 25px 25px;
      overflow: hidden;
    }

    .tabHeader {
      display: flex;
      gap: 40px;

      .tabcontrol {
        width: 50%;
      }

      .formGroup {
        width: 100%;
      }
    }

    .tabContent {
      flex-grow: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .scaledItem {
        width: 100%;
        height: 100%;
        transform-origin: left top;
        position: relative;
        background: #fff;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .form {
    max-width: 353px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .formControls {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 5px 0;

    label {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      cursor: pointer;
      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .formLabel {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.4);
  }

  .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 15px;

    :global(.react-selectize.root-node.default) {
      :global(.react-selectize-control) {
        :global(.react-selectize-toggle-button-container) {
          width: auto;
        }

        :global(.react-selectize-search-field-and-selected-values) {
          flex-wrap: nowrap;
          width: 100%;
          overflow: hidden;

          :global(.value-wrapper) {
            width: 100%;
            position: relative;
            padding-left: 2px;
          }

          :global(.resizable-input) {
            position: absolute;
            left: 0;
          }

          :global(.simple-value) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }
        }
      }

      &:global(.open) :global(.dropdown-menu) {
        width: calc(100% + 24px);
        margin-left: -12px;
        max-height: 250px;

        :global(.simple-option) {
          padding: 10px 11px;
          word-wrap: break-word;
          position: relative;

          &:global(.not-selectable) {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }

    .btn {
      background: transparent;
      padding: 0;
      cursor: pointer;
      outline: none;
      opacity: 0.6;
      border: 0;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: inherit;

        [fill] {
          fill: currentColor;
          color: inherit;
        }

        [stroke] {
          stroke: currentColor;
          color: inherit;
        }
      }

      &:hover {
        color: var(--site-theme-color);
        opacity: 1;
      }
    }

    &:focus .label {
      color: #000000;
    }

    &.error .label {
      color: $red;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &.hideLabel {
      .formLabel {
        display: none;
      }

      &.error {
        color: $red;

        input {
          border-bottom-color: $red;
        }
      }
    }

    &.isEditing {
      z-index: 2;
      position: relative;
    }

    &.larger {
      :global(.react-selectize.root-node.default) {
        &:global(.open) :global(.dropdown-menu) {
          :global(.simple-option) {
            span {
              height: 33px;
              display: flex;
              align-items: center;
            }

            &.isEditing {
              z-index: 2;
              &:global(.not-selectable) {
                opacity: 1;
                font-style: normal;
              }
            }

            &:global(.not-selectable) {
              pointer-events: auto;
            }

            .btnWrapper {
              position: absolute;
              display: none;
              top: 0;
              right: 0;
              height: 100%;
              background: #fff;
            }

            &:not(&:global(.not-selectable)) {
              &:hover {
                .btnWrapper {
                  display: flex;
                }
              }
            }

            
          }
        }
      }
    }
  }

  .fileName {
    font-size: 16px;
    line-height: 24px;
    color: black;
    flex-grow: 1;
  }

  .fileWrapper {
    display: none;
  }

  .slideWrapper {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .text {
      margin-right: 5px;
      height: 16px;
      width: 40px;
    }

    input {
      flex-grow: 1;
      -webkit-appearance: none;
      width: 100%;
      height: 8px;
      background: #f2f2f2;
      outline: none;
      opacity: 0.7;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 4px;
        height: 16px;
        background: #000;
        cursor: pointer;
      }

      &::-moz-range-thumb {
        width: 4px;
        height: 16px;
        background: #000;
        cursor: pointer;
      }
    }
  }

  .textWrapper {
    height: 33px;
    input {
      height: 100%;
      width: 100%;
      border: none;
      outline: none;
      caret-color: var(--site-theme-color);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-left: 0;
      background: transparent;
    }
  }

  .radioWrapper {
    display: flex;
    gap: 24px;
    margin: 10px 0;

    .radioOption {
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      input {
        margin: 0;
        visibility: hidden;

        & + label {
          position: relative;
          padding-left: 29px;
          margin-left: -13px;
          display: block;
          font-weight: 400;
          margin-bottom: 0;
          cursor: pointer;

          &:after {
            content: '';
            width: 16px;
            height: 16px;
            background: #ccc;
            border: 0 none;
            display: block;
            position: absolute;
            top: 2px;
            left: 0;
            border-radius: 50%;
          }
        }

        &:checked + label {
          &:after {
            border: 4px solid #ccc;
            background: #000;
          }
        }
      }
    }
  }

  .colorLabel {
    color: #000 !important;
  }

  .resetFontsBtn {
    max-width: 263px;
    margin: 20px auto 0;
  }

  @media screen and (max-width: 992px) {
    .content {
      display: none;
    }
  }

  &.combined {
    flex-direction: column;
    gap: 24px;

    .form {
      width: 100%;
      max-width: 100%;
    }

    .formLabel {
      color: black;
    }

    .label {
      font-size: 15px;
    }

    .formGroup {
      flex-direction: row;

      & > div {
        width: 100%;

        &.formLabel {
          flex-basis: 190px;
        }
      }
    }
  }
}

:global(.theme-settings-tooltip) {
  margin-top: -8px;
  margin-left: -24px;

  :global(.tooltip-arrow) {
    display: none;
  }

  :global(.tooltip-inner) {
    border-radius: 0;
    padding: 5px 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: black;
    background: #f4f4f4;
    text-align: left;
  }
}
