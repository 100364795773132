.chart {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 24px;

  .chart__chart {
    display: flex;
    flex-grow: 1;
  }

  .chart__legend {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 24px;
    flex-shrink: 0;
    height: 56px;

    .chart__legend_item {
      display: flex;
      align-items: center;
      column-gap: 16px;

      span {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}
