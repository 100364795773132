@import '~theme/mixins';
@import '~theme/modules/variables';
@import '~theme/custom_react_google_flight_datepicker';

.tutorial_analytics__popup {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  :global(.popup-content) {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;
  }

  .tutorial_analytics__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    background: #f4f4f4;
    border-top: 1px solid white;
    padding: 0 24px;
  }

  .tutorial_analytics__container {
    display: flex;
    background: white;
    height: calc(100% - 56px);
    @include nice-scrollbar;
    margin-bottom: 40px;
  }

  .tutorial_analytics__left_container {
    background: white;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 11px;
    margin-top: 40px;
    overflow: hidden;
    position: sticky;
    top: 40px;
    z-index: 12; // higher .popup-header-wrapper

    &:hover {
      overflow-y: scroll;
      padding-right: 0;
    }
  }

  .tutorial_analytics__right_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 40px;
    padding-left: 29px;
  }

  .tutorial_analytics__left_content {
    width: 340px;
    background: $backgroundTiles;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }

  .tutorial_analytics__tabs {
    display: flex;
    column-gap: 20px;
    padding: 24px;
    background: #f4f4f4;
    position: sticky;
    top: 0;
    z-index: 1;

    div {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
      transition: all 0.3s ease;

      &:hover,
      &.actived {
        color: var(--site-theme-color);
      }
    }
  }

  .tutorial_analytics_actions {
    display: flex;
    flex-direction: column;
    padding: 24px;
    background: #f4f4f4;
    row-gap: 10px;
    border-top: 1px solid #fff;

    .tutorial_analytics_action_item {
      display: flex;
      align-items: center;
      column-gap: 20px;
      font-size: 15px;
      line-height: 20px;
      color: black;
      font-weight: 600;
      height: 40px;
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);

        svg {
          path[stroke] {
            stroke: var(--site-theme-color);
          }
          path[fill],
          circle[fill] {
            fill: var(--site-theme-color);
          }
        }
      }
    }
  }

  .next_refresh_hint {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.4);
    padding: 16px 0;
  }

  &.insideHelpCenter {
    padding-top: 0;
    position: relative;
    width: 100%;

    > :global(.popup-header-wrapper) {
      display: none;
    }

    .tutorial_analytics__left_content,
    .tutorial_analytics__header {
      display: none;
    }
  }
}

@include breakpoint-sm {
  .tutorial_analytics__popup {
    .tutorial_analytics__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      background: white;
      border-top: none;
      padding: 24px;
    }

    .tutorial_analytics__container {
      flex-direction: column;
      height: 100%;
      margin-bottom: 0;
      overflow: hidden;
      overflow-y: scroll;
    }

    .tutorial_analytics__left_container {
      margin-top: 0;
      position: unset;
      padding-left: 24px;
      padding-right: 13px;
      overflow: unset;
      z-index: unset;

      &:hover {
        overflow-y: unset;
        padding-right: 13px;
      }
    }

    .tutorial_analytics__left_content {
      width: 100%;
    }

    .tutorial_analytics__right_container {
      padding-left: 24px;
      padding-right: 13px;
      padding-bottom: 40px;
    }
  }
}
