@import '../variables';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px 24px;
  outline: none;
  border: none;
  width: 100%;
  min-width: 320px;
  height: $defaultHeightBtn;
  @include defaultFont;
  font-weight: 600;
  transition: all 0.3s ease;

  svg {
    color: inherit;

    [fill] {
      color: inherit;
      fill: currentColor;
    }

    [stroke] {
      color: inherit;
      stroke: currentColor;
    }
  }

  &.primary {
    color: white;
    background: black;

    &:hover {
      background: var(--site-theme-color);
    }
  }

  &.secondary {
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    background: white;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  &.light {
    color: black;
    background: $defaultColor;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  &.link {
    color: black;
    background: white;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.noBorder {
    border: 0 none;
  }
}

.select_input {
  padding: 10px 16px;
  padding-right: 0;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: $defaultHeightBtn;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  .select_input__input {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    @include defaultFont;

    .select_input__input_value {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .select_input__input_placeholder {
      opacity: 0.2;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .select_input__input_arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: $defaultHeightBtn;
      flex-shrink: 0;

      &.open {
        transform: rotate(180deg);
      }
    }

    svg {
      [fill] {
        fill: currentColor;
      }

      [stroke] {
        stroke: currentColor;
      }
    }
  }

  .select_input__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    min-width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: calc($defaultHeightBtn * 6 + 2px);
    overflow-y: auto;

    .select_input__dropdown_item {
      display: flex;
      align-items: center;
      height: $defaultHeightBtn;
      padding: 0 16px;
      @include defaultFont;
      color: black;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        background: var(--background-color);
      }
    }
  }
}

.multi_select_input {
  padding: 10px 16px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: $defaultHeightBtn;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  .multi_select_input__input {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    @include defaultFont;
    white-space: nowrap;

    .multi_select_input__input_value {
      flex-grow: 1;
    }

    .multi_select_input__input_placeholder {
      opacity: 0.2;
      flex-grow: 1;
    }

    .multi_select_input__input_arrow {
      display: flex;
      align-items: center;
      margin-left: 20px;

      &.open {
        transform: rotate(180deg);
      }
    }

    svg {
      [fill] {
        fill: currentColor;
      }

      [stroke] {
        stroke: currentColor;
      }
    }
  }

  .multi_select_input__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: white;
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: calc($defaultHeightBtn * 6 + 2px);
    overflow-y: auto;

    .multi_select_input__dropdown_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: $defaultHeightBtn;
      padding: 0 16px;
      cursor: pointer;

      &:hover {
        background: var(--background-color);
      }

      span {
        @include defaultFont;
        color: black;
      }

      input[type='checkbox'] {
        appearance: none;
        width: 16px;
        height: 16px;
        display: inline-block;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 1px;
        outline: none;
        margin: 0;
        cursor: pointer;
        padding: 0;

        &:checked {
          background-image: url('~assets/img/icons/checkmark.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 70% 70%;
        }
      }
    }
  }
}

.text_input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
  @include defaultFont;
  width: 100%;
  height: $defaultHeightBtn;

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &:focus, &:hover {
    border-bottom-color: black;
  }
}

.checkbox {
  $size: 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;
  font-weight: normal;

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.uncheckable {
    opacity: 0.4;
  }

  &.empty {
    span {
      margin-left: $size;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  span {
    margin-left: #{$size + 16px};
    @include defaultFont;
  }

  span:after {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: $size;
    height: $size;
    content: '';
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  input[type='checkbox']:checked ~ span::after {
    background-image: url('~assets/img/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    border: none;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabs__header {
    display: flex;
    column-gap: 1px;
    flex-shrink: 0;
    background: white;
    height: 48px;

    .tabs__header_item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      column-gap: 20px;
      height: 100%;
      background: $defaultColor;
      cursor: pointer;

      > * {
        opacity: 0.4;
      }

      span {
        @include defaultFont;
        font-weight: 600;
      }

      &.active {
        background: white;

        > * {
          opacity: 1;
        }
      }

      &:hover {
        > * {
          opacity: 1;
        }
      }

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  .tabs__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    // order: -1;

    &.disabled_scroll {
      overflow-y: hidden;
    }
  }
}

.guide_tooltip {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (max-width: $mobileBreakpoint) {
  .select_input {
    .select_input__dropdown {
      width: 100%;
      max-height: calc($defaultHeightBtn * 3 + 2px);
    }
  }
}
