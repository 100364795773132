@import '~theme/modules/variables';
@import '~theme/mixins';

.onboarding {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  min-height: 600px;
  overflow: hidden;

  .onboarding__content {
    width: 100%;
    max-width: 600px;
    padding: 56px 0;
  }

  .onboarding__logo {
    margin-bottom: 64px;
  }

  .onboarding__title {
    font-size: 21px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 56px;
    color: black;
  }

  .onboarding__buttons {
    display: flex;
    column-gap: 24px;

    .onboarding__button {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 16px;
      height: 56px;
      width: 100%;
      background: transparent;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 0;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;

      svg path {
        &[fill] {
          fill: currentColor;
        }
        &[stroke] {
          stroke: currentColor;
        }
      }

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .onboarding__background {
    display: flex;
    flex-grow: 1;

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
