@mixin integrationIcon {
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-image: url('../../assets/img/devices/website-icon.svg');

  &[data-id='thesquare'] {
    background-image: url('../../assets/img/devices/the-square-icon.svg');
  }
  &[data-id='googleAnalytics'] {
    background-image: url('../../assets/img/devices/google-analytics-icon.png');
  }
  &[data-id='slack'] {
    background-image: url('../../assets/img/devices/slack-icon.png');
  }
  &[data-id='zendesk'] {
    background-image: url('../../assets/img/devices/zendesk-icon.svg');
  }
  &[data-id='helpdocs'] {
    background-image: url('../../assets/img/devices/helpdocs-icon.png');
  }
  &[data-id='helpscout'] {
    background-image: url('../../assets/img/devices/helpscout-icon.svg');
  }
  &[data-id='servicenow'] {
    background-image: url('../../assets/img/devices/servicenow-icon.svg');
  }
  &[data-id='salesforce'] {
    background-image: url('../../assets/img/devices/salesforce-icon.svg');
  }
  &[data-id='getguru'] {
    background-image: url('../../assets/img/devices/getguru-icon.svg');
  }
  &[data-id='freshservice'] {
    background-image: url('../../assets/img/devices/freshservice-icon.svg');
  }
  &[data-id='confluence'] {
    background-image: url('../../assets/img/devices/confluence-icon.svg');
  }
  &[data-id='dynamics'] {
    background-image: url('../../assets/img/devices/dynamics-icon.svg');
  }
  &[data-id='canvaslms'] {
    background-image: url('../../assets/img/devices/canvaslms-icon.png');
  }
  &[data-id='hubspot'] {
    background-image: url('../../assets/img/devices/hubspot-icon.svg');
  }
  &[data-id='youtube'] {
    background-image: url('../../assets/img/devices/youtube-icon.svg');
  }
  &[data-id='gdrive'] {
    background-image: url('../../assets/img/devices/google-drive-logo.svg');
  }
  &[data-id='wordpress'] {
    background-image: url('../../assets/img/devices/wordpress-logo.svg');
  }
  &[data-id='intercom'] {
    background-image: url('../../assets/img/devices/intercom-icon.svg');
  }
  &[data-id='confluenceServer'],
  &[data-id='confluenceCloud'],
  &[data-id='jiraServer'] {
    background-image: url('../../assets/img/devices/atlassian-logo.svg');
  }
  &[data-id='saml'] {
    background-image: url('../../assets/img/devices/sso.png');
  }
  &[data-id='oidc'] {
    background-image: url('../../assets/img/devices/oidc-icon.png');
  }
  &[data-id='360learning'] {
    background-image: url('../../assets/img/devices/360learning-icon.png');
  }
  &[data-id='coassemble'] {
    background-image: url('../../assets/img/devices/coassemble-icon.png');
  }
  &[data-id='docebo'] {
    background-image: url('../../assets/img/devices/docebo-icon.png');
  }
  &[data-id='easygenerator'] {
    background-image: url('../../assets/img/devices/easygenerator-icon.png');
  }
  &[data-id='freshdesk'] {
    background-image: url('../../assets/img/devices/freshdesk-icon.png');
  }
  &[data-id='groovehq'] {
    background-image: url('../../assets/img/devices/groovehq-icon.png');
  }
  &[data-id='lessonly'] {
    background-image: url('../../assets/img/devices/seismicLearning-icon.png');
  }
  &[data-id='litmos'] {
    background-image: url('../../assets/img/devices/litmos-icon.png');
  }
  &[data-id='rise'] {
    background-image: url('../../assets/img/devices/rise-icon.png');
  }
  &[data-id='skilljar'] {
    background-image: url('../../assets/img/devices/skilljar-icon.png');
  }
  &[data-id='slab'] {
    background-image: url('../../assets/img/devices/slab-icon.png');
  }
  &[data-id='talentlms'] {
    background-image: url('../../assets/img/devices/talentlms-icon.png');
  }
  &[data-id='trainual'] {
    background-image: url('../../assets/img/devices/trainual-icon.png');
  }
  &[data-id='workramp'] {
    background-image: url('../../assets/img/devices/workramp-icon.png');
  }
  &[data-id='absorblms'] {
    background-image: url('../../assets/img/devices/absorblms-icon.png');
  }
  &[data-id='notion'] {
    background-image: url('../../assets/img/devices/notion-icon.png');
  }
  &[data-id='hownow'] {
    background-image: url('../../assets/img/devices/hownow-icon.png');
  }
  &[data-id='bigtincan'] {
    background-image: url('../../assets/img/devices/bigtincan-icon.png');
  }
  &[data-id='northpass'] {
    background-image: url('../../assets/img/devices/gainsightCE-icon.png');
  }
  &[data-id='seismic'] {
    background-image: url('../../assets/img/devices/seismic-icon.png');
  }
  &[data-id='dokeos'] {
    background-image: url('../../assets/img/devices/dokeos-icon.png');
  }
  &[data-id='learnermobile'] {
    background-image: url('../../assets/img/devices/learnermobile-icon.png');
  }
  &[data-id='thoughtindustries'] {
    background-image: url('../../assets/img/devices/thoughtindustries-icon.png');
  }
  &[data-id='tovuti'] {
    background-image: url('../../assets/img/devices/tovuti-icon.png');
  }
  &[data-id='ewebinar'] {
    background-image: url('../../assets/img/devices/ewebinar-icon.png');
  }
  &[data-id='ceros'] {
    background-image: url('../../assets/img/devices/ceros-icon.png');
  }
  &[data-id='thinkific'] {
    background-image: url('../../assets/img/devices/thinkific-icon.png');
  }
  &[data-id='elucidat'] {
    background-image: url('../../assets/img/devices/elucidat-icon.png');
  }
  &[data-id='brightspace'] {
    background-image: url('../../assets/img/devices/brightspace-icon.png');
  }
  &[data-id='prezi'] {
    background-image: url('../../assets/img/devices/prezi-icon.png');
  }
  &[data-id='schoology'] {
    background-image: url('../../assets/img/devices/schoology-icon.png');
  }
  &[data-id='matrixlms'] {
    background-image: url('../../assets/img/devices/matrixlms-icon.png');
  }
  &[data-id='clickup'] {
    background-image: url('../../assets/img/devices/clickup-icon.png');
  }
  &[data-id='continu'] {
    background-image: url('../../assets/img/devices/continu-icon.png');
  }
  &[data-id='ihgskillsacademy'] {
    background-image: url('../../assets/img/devices/ihgskillsacademy-icon.png');
  }
  &[data-id='learnupon'] {
    background-image: url('../../assets/img/devices/learnupon-icon.png');
  }
  &[data-id='usewhale'] {
    background-image: url('../../assets/img/devices/usewhale-icon.png');
  }
  &[data-id='questionmark'] {
    background-image: url('../../assets/img/devices/questionmark-icon.png');
  }
  &[data-id='ispringlearn'] {
    background-image: url('../../assets/img/devices/ispringlearn-icon.png');
  }
  &[data-id='gcubelms'] {
    background-image: url('../../assets/img/devices/gcubelms-icon.png');
  }
  &[data-id='moodle'] {
    background-image: url('../../assets/img/devices/moodle-icon.png');
  }
  &[data-id='tiled'] {
    background-image: url('../../assets/img/devices/tiled-icon.png');
  }
  &[data-id='learntowin'] {
    background-image: url('../../assets/img/devices/learntowin-icon.png');
  }
  &[data-id='supered'] {
    background-image: url('../../assets/img/devices/supered-icon.svg');
  }
  &[data-id='canva'] {
    background-image: url('../../assets/img/devices/canva-icon.svg');
  }
  &[data-id='flowla'] {
    background-image: url('../../assets/img/devices/flowla-icon.svg');
  }
  &[data-id='trumpet'] {
    background-image: url('../../assets/img/devices/trumpet-icon.svg');
  }
  &[data-id='cd2learning'] {
    background-image: url('../../assets/img/devices/cd2learning-icon.png');
  }
  &[data-id='skyprep'] {
    background-image: url('../../assets/img/devices/skyprep-icon.svg');
  }
  &[data-id='partnerportal'] {
    background-image: url('../../assets/img/devices/partnerportal-icon.svg');
  }
  &[data-id='eloomi'] {
    background-image: url('../../assets/img/devices/eloomi-icon.png');
  }
  &[data-id='evolveauthoring'] {
    background-image: url('../../assets/img/devices/evolveauthoring-icon.svg');
  }
  &[data-id='document360'] {
    background-image: url('../../assets/img/devices/document360-icon.svg');
  }
  &[data-id='zoho'] {
    background-image: url('../../assets/img/devices/zoho-icon.svg');
  }
  &[data-id='gainsight'] {
    background-image: url('../../assets/img/devices/gainsight-icon.svg');
  }
  &[data-id='googlemail'] {
    background-image: url('../../assets/img/devices/googlemail-icon.svg');
  }
  &[data-id='googleclassroom'] {
    background-image: url('../../assets/img/devices/googleclassroom-icon.svg');
  }
  &[data-id='monday'] {
    background-image: url('../../assets/img/devices/monday-icon.svg');
  }
  &[data-id='gracehill'] {
    background-image: url('../../assets/img/devices/gracehill-icon.png');
  }
  &[data-id='gainsightcloud'] {
    background-image: url('../../assets/img/devices/gainsightcloud-icon.svg');
  }
}
