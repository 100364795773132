@import "../../components/HelpCenter/common";
@import "../../theme/mixins";

.helpCenter {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .mainContent {
    display: none;
  }
  &.tutorialSelected {
    .mainContent {
      display: flex;
    }
    .mainSide {
      display: none;
    }
  }
  &.emptyList {
    .searchFrame {
      padding-top: 200px;
    }
  }
}

.mainSide {
  $searchBarHeight: 28px;

  @include sideMenu;
  display: flex;
  position: relative;
  border-right: none;
  width: 100%;
  justify-content: flex-start;
  .domainFrame {
    width: 250px;
    margin-right: 132px;
    margin-left: 38px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    & > .title {
      color: var(--site-theme-color);
      font-size: 15px;
      font-weight: 600;
      display: flex;
      align-items: center;
      line-height: 22px;
      svg {
        margin-right: 26px;
      }
    }
    .searchBarContainer {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  .searchFrame {
    padding-top: 45px;
    width: 425px;
    display: flex;
    flex-direction: column;
  }
  .searchFrameHeader {
    display: flex;
    flex-direction: row;
  }
  .mainViewTypeContainer {
    margin-left: 50px;
    width: 200px;
    height: $searchBarHeight;

    :global(.root-node):global(.react-selectize):global(.default) {
      height: 100%;
    }
    :global(.root-node):global(.react-selectize):global(.default) :global(.react-selectize-control) {
      height: 100%;
    }

    &.hiddenVisibility {
      visibility: hidden;
    }
  }
  .bar {
    @include searchBar;
    padding: 0;
    height: $searchBarHeight;
    min-height: auto;
    .resetIconContainer {
      @include barLeftIcon;
      display: none;
    }
    .searchIconContainer {
      @include barLeftIcon;
    }
  }
  .searchResultList {
    overflow: auto;
    @include nice-scrollbar;
    padding: 0;
    .searchResultItem:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
  .categoryList {
    padding: 0;
    overflow: auto;
    @include nice-scrollbar;
  }
}

.searchBarContainer {
  margin-bottom: 32px;
  .overlay {
    display: none;
    z-index: 3;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .8);
  }
  .searchIconContainer {
    svg {
      width: 16px;
      height: 16px;
    }
    left: -6px;
    top: -6px;
  }
  & > .bar {
    position: relative;
    z-index: 4;
  }
  &.active {
    .overlay {
      display: block;
    }
  }
  &.searchTyped {
    .searchIconContainer {
      display: none;
    }
    .resetIconContainer {
      display: flex;
      top: -6px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.mainContent {
  display: flex;
  position: relative;
  width: 100%;
  .selectedTutorial {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    padding: 0;
    box-sizing: border-box;
    background: white;
    display: flex;
    iframe {
      width: 100%;
    }
  }
}

.splitView {
  display: flex;
  height: 100%;
  justify-content: center;
}

:global(html[data-pathname^="/public/"] #page-wrap) {
  height: 100%;
}

.domainsBar {
  z-index: 5;
}
.domainsList {
  padding-top: 0;
}

.guestUser .mainContent .selectedTutorial {
}

.domainsBar {
  margin-top: 24px;
  flex-shrink: 1;
  overflow-y: auto;
  @include nice-scrollbar;
  .domain {
    font-size: 15px;
    font-weight: 600;
    height: 30px;
    margin: 9px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    .domainValue {
      display: none;
    }
    .favicon {
      width: 24px;
      height: 24px;
      margin-right: 22px;
      &.loadError {
        opacity: 0;
      }
    }
    .closeIconContainer {
      margin-left: auto;
      height: 12px;
      margin-right: 0;
    }
    &:hover {
      .domainLabel {
        text-decoration: underline;
      }
    }
  }
  &.hasSelected {
    .domain:not(.selected) {
      opacity: 0.4;
    }
  }
}
