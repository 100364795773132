@import '~theme/modules/variables';
@import '~theme/form';
@import '../includes';

$mobileTutorialsBreakpoint: 500px;

.container {
  display: block;
  position: relative;
  margin-bottom: 5px;
  margin-right: 101px;

  &.isSelected,
  &.showOverlay {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  &.isEditing {
    background: white;

    .dateGroup,
    .backgroundContent {
      visibility: hidden;
    }

    .titleContainer {
      padding-right: 0;
      flex-grow: unset;
      max-width: 100%;

      input {
        padding-right: 20px;
        width: 79%;
      }
    }
  }

  // &.isTitleHover {
  //   &:not(.isSelected) {
  //     .backgroundContent {
  //       display: none;
  //     }
  //   }

  //   .contentWrapper {
  //     .header {
  //       .categoryName {
  //         color: var(--site-theme-color);
  //       }
  //     }
  //   }

  //   .content {
  //     .title,
  //     .date {
  //       text-decoration: none;
  //       color: var(--site-theme-color);
  //     }

  //     input[type='checkbox'] ~ span::after {
  //       border: 1px solid rgba(0, 0, 0, 0.2);
  //     }
  //   }
  // }

  &.isSelected:not(.showOverlay) {
    &:hover:not(.isTitleHover) {
      .backgroundContent {
        background-color: rgba(var(--site-theme-color-rgb), 0.05);
      }
    }

    .mobileHeader {
      > div {
        color: #000000;
      }
    }

    .backgroundContent {
      background-color: rgba($color: #000000, $alpha: 0.05);
    }
  }

  &.blur {
    opacity: 0.2;
  }

  &.isDraft {
    .title,
    .date {
      text-decoration: none;
    }
  }

  &.hidden {
    display: none;
  }

  &.highlight {
    .title,
    .date {
      color: var(--site-theme-color);
      font-weight: normal;
    }
  }

  @include breakpoint-sm {
    margin-right: 0;
  }
}

.selectedCounter {
  display: none;
  position: absolute;
  border: 1px solid red;
  background-color: red;
  color: white;
  border-radius: 50%;
  top: -15px;
  left: -15px;
  padding: 5px 10px;

  &.dragging {
    display: block;
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.backgroundContent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100vw - 361px);
  overflow: hidden;
  background-color: transparent;
  z-index: -1;
}

.categoryName {
  font-family: $fontBaseV2;
  font-size: 10px;
  color: #999999;
  padding-left: 2px;
}

.titleContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  max-width: 600px;
  padding-right: 50px;
  padding-left: 2px;
  min-height: 30px;
  align-items: center;
}

.titleNameInput {
  outline: none;
  padding: 0;
  margin: 0;
  caret-color: var(--site-theme-color);
  border-bottom: 1px solid var(--site-theme-color);
  width: 428px;
  font-size: 15px;
  font-family: $fontBaseV2;
  line-height: 136%;
  padding-bottom: 6px;
  padding-left: 2px;

  @include breakpoint-sm {
    width: calc(100% - 80px);
  }
}

.buttonGroup {
  position: absolute;
  right: 26px;
  bottom: 7px;

  @include breakpoint-sm {
    right: 0;
  }
}

.button {
  outline: none;
  border: none;
  background: none;
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  font-family: $fontBaseV2;
  color: #000;

  &:hover {
    color: var(--site-theme-color);
  }

  &.cancelBtn {
    font-weight: normal;
  }
}

.title {
  font-family: $fontBaseV2;
  font-size: 15px;
  line-height: 136%;
  color: #000000;
  cursor: pointer;
  max-width: 80%;
  padding: 4px 0 6px;
  word-wrap: break-word;

  &.highlightTitle {
    color: var(--site-theme-color);
  }

  @include breakpoint-sm {
    &:hover {
      text-decoration: none;
    }
  }
}

.content.loadingBar {
  width: 100%;
  padding-top: 13px;
  padding-bottom: 53px;
  visibility: hidden;
  display: flex;
  justify-content: center;
  pointer-events: none;

  img {
    width: 24px;
    height: 24px;
  }

  &.loadingMore {
    visibility: visible;
  }
}

.dateGroup {
  min-width: 168px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 30px;
  position: relative;
  top: -1px;

  &.blur {
    opacity: 0.2;
    pointer-events: none;
  }

  .dateWrapper {
    cursor: pointer;
  }

  .date {
    font-family: $fontBaseV2;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.4);
    min-width: 18px;
    text-align: right;
  }
}

.checkboxWrapper {
  position: absolute;
  top: 38px;
  right: -98px;

  &:hover {
    input[type='checkbox'].selectable ~ span::after {
      border: 1px solid black;
    }
  }

  @include breakpoint-sm {
    top: 30px;
    right: -40px;
  }
}

.notifyText {
  font-family: $fontBaseV2;
  font-size: 13px;
  color: var(--site-theme-color);
}

.cancelButton {
  display: flex;
  cursor: pointer;

  &:hover {
    color: var(--site-theme-color);
  }

  img {
    margin-right: 15px;
  }

  div {
    font-family: $fontBaseV2;
    font-size: 15px;
    font-weight: 600;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}

.sharedBy,
.archived,
.deactivated,
.draft {
  font-family: $fontBaseV2;
  font-size: 10px;
  color: #999999;
  white-space: nowrap;

  &.isTrashMode,
  &.isDraftMode,
  &.isArchivedMode,
  &.isDeactivatedMode,
  &.isMyMode,
  &.isSharedMode {
    min-width: auto;
  }
}

.contentWrapper {
  position: relative;
  z-index: 10;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;

  &:not(.showOverlay) {
    &:hover {
      border-color: rgba(0, 0, 0, 0.2);
    }

    &.isSelected {
      border: none;
    }
  }

  &.showOverlay {
    border: none;
    z-index: 22;
  }

  &.loading {
    border: none;
    padding: 0;
    cursor: default;
  }

  @include breakpoint-sm {
    padding: 10px;
  }
}

.highlight {
  font-weight: bold;
}

.restore {
  cursor: pointer;
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translate(0, -50%);

  &:hover {
    .restoreText {
      visibility: visible;
    }

    .undoIcon {
      opacity: 1;
    }
  }
}

.restoreText {
  visibility: hidden;
  padding-left: 14px;
  font-family: $fontBaseV2;
  font-size: 15px;
  font-weight: 600;
  color: var(--site-theme-color);
}

.undoIcon {
  margin-left: 14px;
  display: inline;
  opacity: 0.2;
}

.mobileHeader {
  justify-content: space-between;
  display: none;
  margin-bottom: 4px;
  padding: 0;

  .date {
    color: #999999;
  }
}

.mobileCheckbox {
  display: none;
  justify-content: flex-end;
  align-items: center;
}

@include breakpoint-sm {
  .noticeText {
    font-size: 13px;
    margin-bottom: 30px;
  }

  .titleContainer {
    padding-right: 0px;
  }

  .title {
    font-size: 13px;
    max-width: 100%;
  }

  .restoreText {
    display: none;
  }

  .dateGroup {
    display: none;
  }

  .date {
    font-size: 10px;
  }

  .notifyText {
    margin-right: 20px;
  }

  .header {
    display: none;
  }

  .mobileHeader {
    display: flex;
  }

  .mobileCheckbox {
    display: flex;
    align-items: center;
    padding-top: 3px;

    .restoreIcon {
      margin-right: 16px;
      width: 12px;
    }

    .checkbox {
      height: 16px;
    }
  }

  .backgroundContent {
    width: calc(100% + 14px);

    &.hidden {
      display: none;
    }
  }
}

.checkboxStaticOption {
  margin-left: 20px;
  margin-top: 4px;
}

.closeMenuIconWrapper {
  padding: 3px 6px;
  background: black;
  width: 25px;
}

.closeMenuIcon {
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-left: 1px;
}

.showOverlay {
  z-index: 22; // larger than overlay

  .overlay {
    display: block;
  }
}

.selectionEdit {
  &:hover {
    input[type='checkbox'] ~ span::after {
      border-color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  &.isSelected {
    .backgroundContent {
      background-color: transparent;
    }
  }
  &.actionTarget {
    .contentWrapper {
      z-index: auto;
    }
    .title {
      color: var(--site-theme-color);
      margin-right: 149px;

      @include ontopOfOverlay();
      pointer-events: none; // keeping on top just for the color
    }
    .cancelButton {
      @include ontopOfOverlay();
      position: absolute;
      top: 4px;
      right: 147px;
      .closeIcon {
        display: none;
      }
    }
  }
}

.overlay {
  display: none;
  position: fixed;
  z-index: 21; // larger than menu
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background: rgba(255, 255, 255, 0.7); // only use one blur
  cursor: alias;
}

.closeIconWrapper {
  position: absolute;
  z-index: 22;
  top: 2px;
  right: 9px;
  cursor: pointer;
}

.fakeTutorialItem {
  $backgroundColor: #f2f2f2; // like rgba(0, 0, 0, 0.5) on white background
  pointer-events: none; // prevent pink background on hover

  .categoryName {
    background: $backgroundColor;
    width: 150px;
    max-width: 100%;
    height: 100%;
  }

  .title {
    background: $backgroundColor;
    width: 500px;
    max-width: 100%;
    height: 100%;
    flex-grow: 0;

    &:hover {
      text-decoration: none;
    }

    @media only screen and (max-width: $mobileTutorialsBreakpoint) {
      width: 250px;
    }
  }

  .dateGroup {
    &::after {
      content: '';
      background: $backgroundColor;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: $mobileTutorialsBreakpoint) {
  .closeIconWrapper {
    display: none;
  }
}

.shareForEmbedInfo {
  margin-right: 0;

  .headerPlayerModeOption {
    flex-grow: 1;

    height: 100%;
    position: relative;
  }

  .contentWrapper {
    cursor: pointer;
  }

  .dateGroup {
    justify-content: flex-end;
  }

  .showOverlay {
    .dateGroup {
      display: none;
    }

    .closeIconWrapper {
      top: 0;
      right: 0;
    }
  }

  .backgroundContent {
    width: 100%;
  }
}

.integrationTags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
  margin-left: auto;
  margin-right: 50px;
  margin-bottom: 8px;

  .integrationTagItem {
    background: #FFFFFF;
    padding: 5px 10px 5px 10px;
    border: 1px solid #0000001A;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #00000066;
    border-radius: 0;

    &.integrationMoreLink {
      border: 1px solid transparent;
      padding: 5px 0 5px 10px;
      color: #000000;

      &:hover,
      &:visited,
      &:active,
      &:focus-visible,
      &:focus-within {
        color: #000000;
      }
    }

    &:hover,
    &:visited,
    &:active,
    &:focus-visible,
    &:focus-within {
      background: #FFFFFF;
      color: #00000066;
      outline: none;
    }
  }
}

.integrationAdditionalTagWrapper {
  flex-grow: 1;

  .integrationTags {
    margin-bottom: 0;
  }
}
