@import '~theme/modules/variables';
@import '~theme/mixins';
@import '../Integrations/general';

.content {
  margin-top: 128px;
  background: url('~assets/img/redesign2021/marketplacepage/gradient_bg.png');
  background-position: bottom;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  min-height: 666px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 355px);
    background-color: #f8c5a9;
    z-index: 0;
  }

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
    position: relative;
    z-index: 1;
  }

  .titleContent {
    @include ioradFont;
    padding-top: 60px;
    padding-bottom: 50px;
  }

  .descWrapper {
    display: flex;
    justify-content: space-between;
    padding-right: 93px;
  }

  .descContent {
    display: flex;
    flex-direction: column;
    max-width: 618px;
    margin-top: 15px;

    p {
      margin-bottom: 70px;
      color: black;
      font-size: 21px;
      line-height: 28px;
      font-weight: 600;
    }

    .search {
      z-index: 1;
      margin-top: 0;
      text-align: left;
      width: 432px;
      position: relative;

      .inputIcon {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;

        &:hover {
          .closeIcon path {
            stroke: var(--site-theme-color);
          }
        }
      }

      .closeIcon {
        cursor: pointer;
      }

      .searchInput {
        width: 100%;
        height: 32px;
        padding-left: 45px;
        padding-right: 15px;
        font-size: 15px;
        color: #000;
        border: none;
        outline: none;
        caret-color: var(--site-theme-color);

        &::placeholder {
          color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .navigations {
    display: flex;
    flex-direction: column;
    row-gap: 9px;

    a {
      display: flex;
      align-items: center;
      background: white;
      height: 56px;
      padding-left: 25px;
      padding-right: 21px;
      width: 368px;

      span {
        font-size: 21px;
        line-height: 28px;
        font-weight: 600;
        color: inherit;
        flex-grow: 1;
      }

      &:hover {
        svg path {
          fill: var(--site-theme-color);
        }
      }
    }
  }
}

.popularAppsSection {
  max-width: $maxWidthContent;
  margin: 0 auto;
  margin-top: -70px;
  padding: 0 64px 57px;
  position: relative;
  z-index: 2;

  .stat {
    font-size: 10px;
    line-height: 15px;
    color: black;
    margin-bottom: 5px;

    .number {
      margin-left: 5px;
      cursor: pointer;

      &:hover {
        font-weight: 600;
      }
    }

    .tutorials_published_tooltip {
      &:global(.tooltip.top) {
        :global(.tooltip-arrow) {
          display: none;
        }
      
        :global(.tooltip-inner) {
          border-radius: 0;
          padding: 5px 10px;
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: black;
          background: #f4f4f4;
          text-align: left;
        }
      }
    }
  }

  &__title {
    font-size: 32px;
    line-height: 44px;
    font-weight: 600;
    color: black;
    margin: 0;
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 63px;

    &_item {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 100%;
      max-width: 368px;
      min-height: 292px;
      padding: 30px 25px 7px 29px;
      margin: 0;
      background: #f2f2f2;

      &_header {
        display: flex;
        align-items: flex-end;

        &_icon {
          width: 32px;
          height: 32px;
          @include integrationIcon;
        }

        h6 {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: black;
          margin: 0;
          margin-left: 20px;
          margin-right: 13px;
          flex-grow: 1;
        }

        a {
          margin-right: 2px;
          opacity: 0.4;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }

      &_body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-top: 40px;
      }

      &_desc {
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin-bottom: 40px;
        flex-grow: 1;
      }

      &_connected_info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 40px;

        span {
          font-size: 15px;
          line-height: 20px;
          color: black;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &_star {
        display: flex;
        align-items: center;

        > div {
          column-gap: 3px;
          margin-right: 19px;
        }

        span {
          font-size: 10px;
          line-height: 15px;
          color: black;
          text-transform: capitalize;
        }
      }

      &_footer {
        margin-top: 18px;
        display: flex;
        justify-content: center;

        a {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          font-weight: 600;
          max-width: 230px;
          width: 100%;
          height: 48px;
          background-color: black;
          color: white;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: var(--site-theme-color);
          }
        }
      }
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 207px 158px 160px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .contactBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;
    background-image: url('~assets/img/redesign2021/marketplacepage/mobile/gradient_bg.png');
    background-size: 100% 255px;
    min-height: 1117px;

    &::before {
      height: calc(100% - 254px);
    }

    .titleWrapper {
      max-width: 100%;
      padding: 68px 24px 0;

      img {
        width: 100%;
      }
    }

    .descWrapper {
      flex-direction: column;
      padding-right: 0;
    }

    .descContent {
      max-width: 100%;
      margin-top: 35px;

      p {
        margin-bottom: 72px;
        font-size: 15px;
        line-height: 20px;
      }

      .search {
        width: 100%;
      }
    }

    .navigations {
      margin-top: 45px;

      a {
        padding-left: 13px;
        width: 100%;

        span {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }

  .popularAppsSection {
    max-width: 100%;
    margin-top: -283px;
    padding: 0 24px 130px;

    &__title {
      font-size: 21px;
      line-height: 28px;
      margin-bottom: 25px;
    }

    &__list {
      gap: 25px;

      &_item {
        max-width: 100%;
        min-height: 215px;
        padding: 30px 31px 29px;

        &_header {
          h6 {
            margin-right: 0;
          }
        }

        &_body {
          margin-top: auto;
          flex-grow: unset;
        }

        &_desc {
          display: none;
        }

        &_connected_info {
          display: none;
        }
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .contactBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
