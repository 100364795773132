@import '~theme/modules/variables';
@import '~theme/mixins';

.ftrs {
  margin-top: 31px;

  .ftrs__problems_objectives {
    display: flex;
    column-gap: 57px;
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;

    .ftrs__problems_objectives_bg {
      width: 83px;
    }

    .ftrs__problems_objectives_block {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 518px;
      margin-top: 22px;

      h4 {
        font-size: 21px;
        line-height: 24px;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 27px;
      }

      h6 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 26px;
      }

      p {
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin: 0;
        margin-bottom: 54px;
      }

      ul {
        margin: 0;
        padding: 0;
        padding-left: 14px;

        li {
          font-size: 15px;
          line-height: 20px;
          color: black;
        }
      }
    }
  }

  .ftrs__measurements {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: -17px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 27px;
    }

    h6 {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 26px;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: 14px;
      margin-bottom: 33px;

      li {
        font-size: 15px;
        line-height: 20px;
        color: black;
      }
    }

    .ftrs__measurements_bg {
      width: 100%;
      max-width: 809px;
      background: #a4ffb2;

      img {
        width: 100%;
      }
    }
  }

  .ftrs__competitive {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 57px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 27px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-bottom: 36px;
    }

    .ftrs__competitive_table {
      display: flex;
      flex-direction: column;
      row-gap: 6px;

      .ftrs__competitive_table_header {
        display: flex;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          flex-grow: 1;
          background: #f4f4f4;
          height: 76px;
          font-size: 21px;
          line-height: 24px;
          font-weight: 600;
          color: black;
          border-right: 6px solid white;
          text-align: center;

          &:last-child {
            border-right: none;
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(4) {
            max-width: 126px;
            flex-shrink: 0;
          }

          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(6) {
            max-width: 229px;
            flex-shrink: 0;
          }
        }
      }

      .ftrs__competitive_table_row {
        display: flex;

        &.iorad > div {
          background: #ffe5fb;
          border-right-color: #ffe5fb;
        }

        > div {
          padding: 15px;
          flex-shrink: 0;
          flex-grow: 1;
          font-size: 15px;
          line-height: 20px;
          color: black;
          background: #f4f4f4;
          border-right: 6px solid white;

          &:last-child {
            border-right: none;
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(4) {
            width: 100%;
            max-width: 126px;
            flex-shrink: 0;
          }

          &:nth-child(3),
          &:nth-child(5),
          &:nth-child(6) {
            width: 100%;
            max-width: 229px;
            flex-shrink: 0;
          }

          b {
            font-weight: 600;
          }

          ul {
            margin: 0;
            padding: 0;
            padding-left: 14px;
          }
        }
      }
    }
  }

  .ftrs__reasoning {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 55px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 27px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      width: 100%;
      max-width: 810px;
    }
  }

  .ftrs__my_role {
    background: #f2f2f2;
    margin-top: 109px;
    padding-bottom: 113px;

    .ftrs__my_role_inner {
      display: flex;
      flex-direction: column;
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 0 64px;
    }

    h2 {
      @include ioradFont;
      margin-top: 55px;
      margin-bottom: 70px;
    }

    .ftrs__my_role_list {
      display: flex;
      flex-wrap: wrap;
      gap: 79px 26px;
    }

    .ftrs__my_role_item {
      display: flex;

      .ftrs__my_role_item_img {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        width: 116px;
      }

      .ftrs__my_role_item_info {
        margin-left: 15px;
        width: 100%;
        max-width: 264px;

        h6 {
          font-size: 15px;
          line-height: 20px;
          color: black;
          font-weight: 600;
          margin: 0;
          margin-bottom: 3px;
        }
        p {
          font-size: 15px;
          line-height: 20px;
          color: black;
          margin: 0;
        }
      }
    }
  }

  .ftrs_value_add_proposition {
    margin-top: 136px;

    .ftrs_value_add_proposition_inner {
      display: flex;
      flex-direction: column;
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 0 64px;

      h4 {
        font-size: 32px;
        line-height: 44px;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 22px;
      }

      p {
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin: 0;
        width: 100%;
        max-width: 810px;
      }
    }

    .ftrs_value_add_proposition_list {
      display: flex;
      flex-direction: column;
      row-gap: 66px;
      max-width: $maxWidthContent;
      margin: 0 auto;
      margin-top: 82px;
    }

    .ftrs_value_add_proposition_item {
      display: flex;

      .ftrs_value_add_proposition_item_img {
        width: 639px;
        height: auto;
      }

      .ftrs_value_add_proposition_item_info {
        margin-left: 62px;
        margin-top: 37px;
        width: 100%;
        max-width: 617px;

        h6 {
          font-size: 32px;
          line-height: 44px;
          font-weight: 600;
          color: black;
          margin: 0;
          margin-bottom: 23px;
        }

        ul {
          margin: 0;
          padding: 0;
          padding-left: 14px;

          li {
            font-size: 15px;
            line-height: 20px;
            color: black;
          }
        }
      }
    }
  }

  .ftrs_design_process {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
    margin-top: 56px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 27px;
    }

    .ftrs_design_process_list {
      display: flex;
      justify-content: space-between;
    }

    .ftrs_design_process_item {
      display: flex;
      flex-direction: column;

      &:last-child {
        svg {
          display: none;
        }
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 160px;
      }

      h6 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;
        margin: 0;
      }

      p {
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin: 0;
        margin-top: 26px;
        max-width: 208px;
      }

      svg path {
        fill: var(--site-theme-color);
      }
    }
  }

  .ftrs__secondary_research {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 51px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 27px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      width: 100%;
      max-width: 810px;
    }

    .ftrs__secondary_research_comparison {
      display: flex;
      justify-content: space-between;
      margin-top: 61px;
    }

    .ftrs__secondary_research_comparison_block {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
      width: 293px;

      &.iorad {
        h6 {
          background: white;
          color: var(--site-theme-color);
        }

        p {
          background: white;
        }
      }

      h6 {
        height: 43px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f4f4f4;
        font-size: 21px;
        line-height: 24px;
        font-weight: 600;
        color: black;
        margin: 0;
      }

      p {
        min-height: 102px;
        width: 100%;
        padding: 11px 16px;
        background: #f4f4f4;
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin: 0;
      }

      div {
        display: flex;
        align-items: flex-end;
        column-gap: 6px;

        span {
          background: #f4f4f4;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          text-align: center;
          font-size: 15px;
          line-height: 20px;
          color: black;
        }
      }
    }
  }

  .ftrs_user_interviews {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 57px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 27px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      width: 100%;
      max-width: 810px;
    }

    .ftrs_user_interviews_list {
      display: flex;
      justify-content: space-between;
      margin-top: 99px;
    }

    .ftrs_user_interviews_item {
      display: flex;
      flex-direction: column;
      width: 350px;

      .ftrs_user_interviews_item_info {
        display: flex;

        img {
          width: 40px;
        }

        div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 30px;

          span {
            font-size: 15px;
            line-height: 20px;
            color: black;
          }

          h6 {
            font-size: 15px;
            line-height: 20px;
            color: black;
            font-weight: 600;
            margin: 0;
          }
        }
      }

      p {
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin: 0;
        margin-top: 47px;
      }
    }
  }

  .ftrs__user_persona {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 55px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 34px;
    }

    .ftrs__user_persona_main {
      display: flex;
    }

    .ftrs__user_persona_img {
      background: #f4f4f4;
      width: 380px;
      height: 383px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ftrs__user_persona_info {
      display: flex;
      flex-direction: column;
      margin-left: 68px;

      span {
        font-size: 15px;
        line-height: 20px;
        color: black;
      }

      h5 {
        font-size: 21px;
        line-height: 24px;
        font-weight: 600;
        color: black;
        max-width: 595px;
        margin: 0;
        margin-bottom: 21px;
      }

      .ftrs__user_persona_info_detail {
        display: flex;
        flex-wrap: wrap;
        column-gap: 62px;

        div {
          width: 219px;
          flex-shrink: 0;

          label {
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;
            color: black;
            margin: 0;
          }
        }
      }

      .ftrs__user_persona_info_about {
        margin-top: 25px;

        label {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: black;
          margin: 0;
          margin-bottom: 5px;
        }

        p {
          font-size: 15px;
          line-height: 20px;
          color: black;
          margin: 0;
          max-width: 616px;
        }
      }
    }

    .ftrs__user_persona_more {
      display: flex;
      justify-content: space-between;
      margin-top: 31px;

      .ftrs__user_persona_more_block {
        display: flex;
        flex-direction: column;
        width: 350px;

        label {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: black;
          margin: 0;
          margin-bottom: 5px;
        }

        ul {
          margin: 0;
          padding: 0;
          padding-left: 14px;

          li {
            font-size: 15px;
            line-height: 20px;
            color: black;
          }
        }
      }
    }
  }

  .ftrs__empathy_map {
    margin-top: 50px;

    .ftrs__empathy_map_inner {
      display: flex;
      flex-direction: column;
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 0 64px;

      h4 {
        font-size: 21px;
        line-height: 24px;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 27px;
      }

      p {
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin: 0;
        width: 100%;
        max-width: 810px;
      }
    }

    .ftrs__empathy_map_content {
      background: #f4f4f4;
      margin-top: 62px;

      .ftrs__empathy_map_content_inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: $maxWidthContent;
        padding-bottom: 122px;
        margin: 0 auto;
      }

      .ftrs__empathy_map_bubble {
        position: absolute;
        width: 157px;
        height: 147px;

        span {
          font-size: 15px;
          line-height: 20px;
          color: black;
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          height: 100px;
          padding: 0 14px;
        }

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
          top: 0;
          left: 0;
          background-image: url('~assets/img/redesign2021/customerpage/stories/bubble.svg');
        }

        &[data-position='top-right'] {
          &::before {
            transform: scaleY(-1);
          }

          span {
            margin-top: 47px;
          }
        }

        &[data-position='top-left'] {
          &::before {
            transform: rotate(180deg);
          }

          span {
            margin-top: 47px;
          }
        }

        &[data-position='bottom-left'] {
          &::before {
            transform: rotate(180deg) scaleY(-1);
          }
        }
      }

      .ftrs__empathy_map_text {
        display: flex;
        flex-direction: column;

        h5 {
          font-size: 21px;
          line-height: 24px;
          font-weight: 600;
          color: black;
          margin: 0;
        }

        h2 {
          @include ioradFont;
        }
      }

      .ftrs__empathy_map_think {
        position: relative;
        padding-top: 34px;
      }

      .ftrs__empathy_map_block {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 221px;
      }

      .ftrs__empathy_map_say {
        position: relative;

        .ftrs__empathy_map_text {
          transform: rotate(-90deg) translateY(-35px) translateX(-73px);
        }
      }

      .ftrs__empathy_map_user {
        margin-left: 90px;
      }

      .ftrs__empathy_map_feel {
        position: relative;

        .ftrs__empathy_map_text {
          transform: rotate(90deg) translateY(-72px) translateX(69px);
        }
      }

      .ftrs__empathy_map_do {
        position: relative;
        margin-top: 221px;
        margin-left: 82px;
      }
    }
  }

  .ftrs__user_journey {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 139px;
    padding: 0 64px;

    h4 {
      font-size: 32px;
      line-height: 44px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 49px;
    }

    h5 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 34px;
    }

    .ftrs__user_journey_titles {
      display: flex;
      column-gap: 6px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 43px;
        background: #f4f4f4;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;

        &:nth-child(3) {
          min-width: 464px;
        }
      }
    }

    .ftrs__user_journey_chart {
      padding-top: 98px;
      position: relative;

      img {
        width: 100%;
      }

      .ftrs__user_journey_chart_item {
        position: absolute;

        &.break2 {
          &::before {
            top: 40px;
            height: calc(100% - 53px);
          }
        }

        &.break3 {
          &::before {
            top: 60px;
            height: calc(100% - 73px);
          }
        }

        span {
          font-size: 15px;
          line-height: 20px;
          color: black;
        }

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 20px;
          height: calc(100% - 33px);
          width: 1.6px;
          background: var(--site-theme-color);
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          width: 13px;
          height: 13px;
          border: 4px solid var(--site-theme-color);
        }
      }
    }
  }

  .ftrs__action {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 22px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 16px;
    }

    .ftrs__action_titles {
      display: flex;
      column-gap: 6px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 43px;
        background: #f4f4f4;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;

        &:nth-child(3) {
          min-width: 464px;
        }
      }
    }
  }

  .ftrs__journey {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 56px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 16px;
    }

    .ftrs__journey_items {
      display: flex;
      column-gap: 6px;

      div {
        display: flex;
        flex: 1;
        background: #f4f4f4;
        padding: 12px 16px;

        &:nth-child(3) {
          min-width: 464px;
        }

        span {
          font-size: 15px;
          line-height: 20px;
          color: black;
        }

        ul {
          margin: 0;
          padding: 0;
          padding-left: 14px;

          li {
            font-size: 15px;
            line-height: 20px;
            color: black;
          }
        }
      }
    }
  }

  .ftrs__opportunities {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 56px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 16px;
    }

    .ftrs__opportunities_items {
      display: flex;
      column-gap: 6px;

      div {
        display: flex;
        flex: 1;
        background: #f4f4f4;
        padding: 12px 16px;

        &:nth-child(3) {
          min-width: 464px;
        }

        span {
          font-size: 15px;
          line-height: 20px;
          color: black;
        }

        ul {
          margin: 0;
          padding: 0;
          padding-left: 14px;

          li {
            font-size: 15px;
            line-height: 20px;
            color: black;
          }
        }
      }
    }
  }

  .ftrs__info_architecture {
    margin-top: 152px;
    background: #f4f4f4;

    .ftrs__info_architecture_inner {
      display: flex;
      flex-direction: column;
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 56px 64px 70px;

      h4 {
        font-size: 21px;
        line-height: 24px;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-bottom: 28px;
      }

      p {
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin: 0;
        max-width: 810px;
      }
    }

    .ftrs__info_architecture_chart {
      margin-top: 67px;
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        flex-shrink: 0;
      }

      .main_label {
        border: 10.8px solid white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 457px;
        padding-bottom: 4px;

        span {
          font-size: 21px;
          line-height: 24px;
          font-weight: 600;
          color: black;
        }
      }

      .tutorial_process_steps {
        display: flex;
        width: 457px;

        label {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 39px;
          flex: 1;
          margin: 0;

          span {
            font-size: 21px;
            line-height: 24px;
            font-weight: 600;
            color: var(--site-theme-color);
          }
        }
      }

      .tutorial_label {
        width: 252px;
        height: 51px;
        background: white;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        column-gap: 10px;
        padding-bottom: 11px;

        svg path {
          &[fill] {
            fill: var(--site-theme-color);
          }
          &[stroke] {
            stroke: var(--site-theme-color);
          }
        }

        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: var(--site-theme-color);
        }
      }

      .process_overviews_steps {
        display: flex;
        column-gap: 7px;

        label {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0;
          width: 149px;

          &:first-child {
            svg {
              margin-left: 18px;
            }
          }

          &:last-child {
            svg {
              margin-right: 18px;
            }
          }

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 43px;
            background: white;
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;
            color: black;
          }
        }
      }

      .last_steps {
        border: 10.8px solid white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 19px;
        width: 100%;
        max-width: 1225px;
        padding-bottom: 4px;

        span {
          font-size: 21px;
          line-height: 24px;
          font-weight: 600;
          color: black;
        }
      }
    }
  }

  .ftrs__user_flow {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 144px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 28px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      max-width: 810px;
      margin-bottom: 49px;
    }

    .ftrs__user_flow_chart {
      display: flex;
      align-items: center;

      .main_label {
        border: 10.8px solid black;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 21px;

        span {
          font-size: 21px;
          line-height: 24px;
          font-weight: 600;
          color: black;
          white-space: nowrap;
        }
      }

      .case_label {
        width: 92px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f4f4f4;
        column-gap: 10px;

        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: var(--site-theme-color);
        }

        svg path {
          &[fill] {
            fill: var(--site-theme-color);
          }
          &[stroke] {
            stroke: var(--site-theme-color);
          }
        }
      }

      .first_step {
        display: flex;

        .first_step_arrows {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 6px;
          padding-right: 4px;

          svg {
            &:first-child {
              margin-top: -2px;
            }
            &:last-child {
              margin-bottom: -2px;
            }
          }
        }
      }

      .second_step {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        flex-grow: 1;
        position: relative;

        &:before {
          content: '';
          width: calc(100% - 63px);
          height: 1.6px;
          position: absolute;
          right: -51px;
          top: -81px;
          background: black;
        }

        &:after {
          content: '';
          width: 1.6px;
          height: 57.5px;
          position: absolute;
          right: 0;
          top: 26.5px;
          background: black;
        }

        .second_step_block {
          display: flex;
          align-items: center;

          .main_label {
            min-width: 228px;
          }

          .second_step_block_item {
            position: relative;

            .second_step_block_item_arrow {
              display: flex;
              position: absolute;
              bottom: 100%;
              left: 50%;
              transform: translateX(-50%);

              &::before {
                content: '';
                position: absolute;
                width: 1.6px;
                height: 65px;
                background: black;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }

          .second_step_block_arrow {
            padding-left: 6px;
            padding-right: 4px;
            transform: translateY(11px);
          }
        }

        .second_step_line {
          height: 1.6px;
          flex-grow: 1;
          background: black;
        }

        .second_step_arrow {
          display: flex;
          position: absolute;
          top: 50%;
          transform: translate(100%, -50%);
          right: -1.6px;
        }
      }

      .third_step {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: -10px;
      }
    }
  }

  .ftrs__takeaways {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    margin-top: 33px;
    padding: 0 64px;

    h4 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 19px;
    }

    .ftrs__takeaways_list {
      display: flex;
      flex-wrap: wrap;
      row-gap: 118px;

      .ftrs__takeaways_item {
        width: 50%;
        display: flex;
        align-items: flex-end;
        column-gap: 60px;

        h3 {
          @include ioradFont;
          min-width: 99px;
        }

        p {
          font-size: 15px;
          line-height: 20px;
          color: black;
          max-width: 390px;
        }
      }
    }
  }
}

@include breakpoint-sm {
  .ftrs {
    .ftrs__problems_objectives {
      flex-direction: column;
      padding: 0 24px;
      row-gap: 31px;

      .ftrs__problems_objectives_block {
        margin-top: 0;

        h4 {
          margin-bottom: 20px;
        }

        h6 {
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 16px;
        }

        p {
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 18px;
        }

        ul li {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
    .ftrs__measurements {
      margin-top: 30px;
      padding: 0 24px;

      h4 {
        order: -2;
      }

      .ftrs__measurements_bg {
        order: -1;
        margin-bottom: 31px;
      }

      h6 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 16px;
      }

      ul li {
        font-size: 13px;
        line-height: 18px;
      }
    }
    .ftrs__competitive {
      margin-top: 30px;
      padding: 0 24px;

      h4 {
        margin-bottom: 22px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 7px;
      }

      .ftrs__competitive_list {
        display: flex;
        flex-direction: column;

        h6 {
          font-size: 13px;
          line-height: 18px;
          font-weight: 600;
          color: black;
          margin-bottom: 14px;
          margin-top: 15px;
        }

        .ftrs__competitive_list_item {
          display: flex;
          flex-direction: column;
          row-gap: 3px;

          .ftrs__competitive_list_item_block {
            display: flex;
            flex-direction: column;
            padding: 15px 0 18px 26px;
            background: #f4f4f4;
            font-size: 13px;
            line-height: 18px;
            color: black;

            &.iorad {
              background: #ffe5fb;
            }

            b {
              font-weight: 600;
            }

            ul {
              margin: 0;
              padding-left: 14px;
            }
          }
        }
      }
    }
    .ftrs__reasoning {
      padding: 0 24px;
      margin-top: 34px;

      h4 {
        margin-bottom: 22px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
      }
    }
    .ftrs__my_role {
      margin-top: 126px;
      padding-bottom: 38px;

      .ftrs__my_role_title {
        padding-top: 43px;
        padding-bottom: 60px;

        img {
          width: 100%;
        }
      }

      .ftrs__my_role_inner {
        padding: 0 24px;
      }

      .ftrs__my_role_list {
        flex-direction: column;
        row-gap: 32px;
      }

      .ftrs__my_role_item {
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 5;
        }
        &:nth-child(4) {
          order: 2;
        }
        &:nth-child(5) {
          order: 4;
        }
        &:nth-child(6) {
          order: 6;
        }

        .ftrs__my_role_item_img {
          width: 80px;
        }
        .ftrs__my_role_item_info {
          max-width: 100%;

          h6 {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 16px;
          }

          p {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .ftrs_value_add_proposition {
      margin-top: 78px;

      .ftrs_value_add_proposition_inner {
        padding: 0 24px;

        h4 {
          font-size: 21px;
          line-height: 24px;
          margin-bottom: 21px;
        }

        p {
          font-size: 13px;
          line-height: 18px;
        }
      }

      .ftrs_value_add_proposition_list {
        margin-top: 64px;
        padding: 0 24px;
        row-gap: 39px;
      }

      .ftrs_value_add_proposition_item {
        flex-direction: column;

        .ftrs_value_add_proposition_item_img {
          width: 100%;
        }
        .ftrs_value_add_proposition_item_info {
          margin-left: 0;
          margin-top: 26px;

          h6 {
            font-size: 21px;
            line-height: 24px;
            margin-bottom: 21px;
          }

          p {
            margin: 0;
          }

          ul li {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .ftrs_design_process {
      padding: 0 24px;
      margin-top: 31px;

      h4 {
        margin-bottom: 19px;
      }

      .ftrs_design_process_list {
        flex-direction: column;
        row-gap: 15px;
      }
      .ftrs_design_process_item {
        position: relative;
        padding-bottom: 32px;

        p {
          font-size: 13px;
          line-height: 18px;
          margin-top: 14px;
          max-width: 100%;
        }

        svg {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) rotate(90deg);
        }
      }
    }
    .ftrs__secondary_research {
      padding: 0 24px;
      margin-top: 0;

      h4 {
        margin-bottom: 21px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
      }

      .ftrs__secondary_research_comparison {
        flex-direction: column;
        row-gap: 43px;
        margin-top: 39px;
      }

      .ftrs__secondary_research_comparison_block {
        row-gap: 3px;
        width: 100%;

        &.iorad {
          h6 {
            background: #f4f4f4;
          }
        }

        p {
          padding: 15px 26px 18px;
          font-size: 13px;
          line-height: 18px;
          min-height: unset;
        }

        div {
          column-gap: 3px;

          span {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .ftrs_user_interviews {
      padding: 0 24px;
      margin-top: 36px;

      h4 {
        margin-bottom: 21px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
      }

      .ftrs_user_interviews_list {
        flex-direction: column;
        row-gap: 41px;
        margin-top: 101px;
      }
      .ftrs_user_interviews_item {
        width: 100%;

        .ftrs_user_interviews_item_info {
          image {
            width: 56px;
          }

          div {
            margin-left: 18px;

            span {
              font-size: 13px;
              line-height: 18px;
              transform: translateY(-3px);
            }

            h6 {
              font-size: 13px;
              line-height: 18px;
              transform: translateY(4px);
            }
          }
        }

        p {
          font-size: 13px;
          line-height: 18px;
          margin-top: 16px;
        }
      }
    }
    .ftrs__user_persona {
      padding: 0 24px;
      margin-top: 108px;

      h4 {
        margin-bottom: 27px;
      }

      .ftrs__user_persona_main {
        flex-direction: column;
      }

      .ftrs__user_persona_main_info {
        display: flex;
        column-gap: 18px;
        margin-bottom: 31px;

        .ftrs__user_persona_img {
          width: 153.5px;
          height: 128px;
        }

        .ftrs__user_persona_info_detail {
          div {
            font-size: 13px;
            line-height: 18px;

            label {
              margin: 0;
              font-weight: 600;
            }
          }
        }
      }
      .ftrs__user_persona_info {
        margin-left: 0;

        span {
          font-size: 13px;
          line-height: 18px;
          font-weight: 600;
        }

        h5 {
          font-size: 13px;
          line-height: 18px;
          font-weight: 400;
          margin-bottom: 20px;
          margin-top: 15px;
        }

        .ftrs__user_persona_info_about {
          margin-top: 0;

          label {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 15px;
          }

          p {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
      .ftrs__user_persona_more {
        flex-direction: column;
        margin-top: 20px;
        row-gap: 18px;

        .ftrs__user_persona_more_block {
          width: 100%;

          label {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 15px;
          }

          ul li {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .ftrs__empathy_map {
      margin-top: 32px;

      .ftrs__empathy_map_inner {
        padding: 0 24px;

        h4 {
          margin-bottom: 21px;
        }

        p {
          font-size: 13px;
          line-height: 18px;
        }
      }

      .ftrs__empathy_map_content_mobile {
        background: #f4f4f4;

        .ftrs__empathy_map_content_mobile_inner {
          padding: 181px 24px 245px;
        }
      }

      .ftrs__empathy_map_text {
        display: flex;
        flex-direction: column;

        h5 {
          font-size: 13px;
          line-height: 18px;
          font-weight: 600;
          color: black;
          margin: 0;
          margin-bottom: 8px;
        }

        .ftrs__empathy_map_text_img {
          display: flex;
          column-gap: 5px;
        }
      }

      .ftrs__empathy_map_bubble {
        position: absolute;
        width: 135px;
        height: 127px;

        span {
          font-size: 13px;
          line-height: 15px;
          color: black;
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          height: 86px;
          padding: 0 12px;
        }

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
          top: 0;
          left: 0;
          background-image: url('~assets/img/redesign2021/customerpage/stories/bubble_mobile.svg');
        }

        &[data-position='top-right'] {
          &::before {
            transform: scaleY(-1);
          }

          span {
            margin-top: 41px;
          }
        }

        &[data-position='top-left'] {
          &::before {
            transform: rotate(180deg);
          }

          span {
            margin-top: 41px;
          }
        }

        &[data-position='bottom-left'] {
          &::before {
            transform: rotate(180deg) scaleY(-1);
          }
        }
      }

      .ftrs__empathy_map_think {
        position: relative;
      }

      .ftrs__empathy_map_user_1 {
        margin-top: 277px;
        position: relative;
        display: flex;
        justify-content: center;
      }

      .ftrs__empathy_map_say {
        margin-top: 148px;
        position: relative;
        display: flex;
        justify-content: flex-end;
        padding-right: 13px;
      }

      .ftrs__empathy_map_feel {
        margin-top: 396px;
        position: relative;
      }

      .ftrs__empathy_map_user_2 {
        margin-top: 242px;
        display: flex;
        justify-content: flex-end;
      }

      .ftrs__empathy_map_do {
        margin-top: 117px;
        position: relative;
        display: flex;
        justify-content: center;
      }
    }
    .ftrs__user_journey {
      padding: 0;
      margin-top: 50px;
      overflow-x: scroll;

      h4 {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 19px;
        padding: 0 24px;
        position: sticky;
        left: 0;
      }

      h5 {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 21px;
        padding: 0 24px;
        position: sticky;
        left: 0;
      }

      .ftrs__user_journey_titles {
        width: 1238px;

        span {
          height: 41px;
          font-size: 13px;
          line-height: 18px;
        }
      }
      .ftrs__user_journey_chart {
        width: 1238px;
      }
    }
    .ftrs__action_mobile {
      display: flex;
      flex-direction: column;
      row-gap: 26px;
      margin-top: 58px;
      padding: 0 24px;

      .ftrs__action_mobile_block {
        display: flex;
        flex-direction: column;
        row-gap: 3px;

        .ftrs__action_mobile_block_row {
          display: flex;
          column-gap: 53px;
          padding: 15px 0 18px 12px;
          background: #f4f4f4;
          font-size: 13px;
          line-height: 18px;
          color: black;

          label {
            font-weight: 600;
            margin: 0;
            flex: 1;
            max-width: 89px;
          }

          span {
            flex: 1;
          }
        }
      }
    }
    .ftrs__info_architecture {
      margin-top: 61px;

      .ftrs__info_architecture_inner {
        padding: 60px 24px 49px;

        h4 {
          margin-bottom: 21px;
        }

        p {
          font-size: 13px;
          line-height: 18px;
        }

        .ftrs__info_architecture_chart {
          margin-top: 45px;

          .main_label {
            width: 100%;
          }

          .process_overviews_steps {
            column-gap: 3px;

            label {
              width: auto;
              padding: 0 3px;

              span {
                font-size: 13px;
                line-height: 18px;
                text-align: center;
              }
            }
          }

          .tutorial_process_steps {
            width: 100%;

            label:first-child {
              justify-content: flex-start;
            }
            label:last-child {
              justify-content: flex-end;
            }
          }

          .last_steps {
            flex-direction: column;
          }
        }
      }
    }
    .ftrs__user_flow {
      margin-top: 78px;
      padding: 0 24px;

      h4 {
        margin-bottom: 21px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 44px;
      }

      .ftrs__user_flow_chart_mobile {
        display: flex;
        flex-direction: column;

        .main_label {
          border: 10.8px solid black;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 2px;
          width: 100%;

          span {
            font-size: 21px;
            line-height: 24px;
            font-weight: 600;
            color: black;
            text-align: center;
          }
        }

        .first_step {
          display: flex;
          flex-grow: 1;
        }

        .second_step {
          display: flex;
          flex-direction: column;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 78px;
            width: 1.6px;
            height: calc(100% - 93px);
            background: black;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 78px;
            height: 1.6px;
            width: calc(100% - 201px);
            background: black;
          }

          .second_step_inner {
            display: flex;
            justify-content: space-between;
            column-gap: 16px;
          }

          .second_step_block {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 153px;
            position: relative;

            svg {
              display: flex;
              margin-top: 2px;
              margin-bottom: 2px;
            }

            .second_step_block_arrow {
              position: absolute;
              top: 100%;
              left: 8px;

              &::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                width: 1.6px;
                height: 382px;
                background: black;
              }

              svg {
                transform: rotate(180deg);
                margin-bottom: 0;
              }
            }
          }

          .completes_tutorial {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 46px;
            max-width: 153px;
            position: relative;

            svg {
              display: flex;
              margin-top: 2px;
              margin-bottom: 2px;
            }

            .completes_tutorial_line {
              margin-top: 2px;
              height: 66px;
              width: 1.6px;
              background: black;
            }
          }
        }

        .third_step {
          display: flex;
          flex-direction: column;
          align-items: center;

          .main_label {
            width: 236px;
          }

          svg {
            margin-bottom: 2px;
          }
        }

        .four_step {
          display: flex;
          justify-content: space-between;
          padding: 0 31px;

          .four_step_block {
            display: flex;
            flex-direction: column;
            align-items: center;

            .case_label {
              width: 92px;
              height: 51px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #f4f4f4;
              column-gap: 10px;

              span {
                font-size: 15px;
                line-height: 20px;
                font-weight: 600;
                color: var(--site-theme-color);
              }

              svg path {
                &[fill] {
                  fill: var(--site-theme-color);
                }
                &[stroke] {
                  stroke: var(--site-theme-color);
                }
              }
            }
          }
        }

        .last_step {
          display: flex;
          justify-content: space-between;
          column-gap: 16px;
          padding-bottom: 22px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 14px;
            bottom: 0;
            width: 74px;
            height: 1.6px;
            background: black;
          }

          .main_label {
            min-width: 137px;
            max-width: 153px;
            height: 100%;
          }

          .repeat {
            position: relative;
            margin-left: 20px;

            &::before {
              content: '';
              position: absolute;
              left: 50%;
              top: 100%;
              transform: translate(-50%, 2px);
              width: 1.6px;
              height: 20px;
              background: black;
            }
          }
        }
      }
    }
    .ftrs__takeaways {
      margin-top: 36px;
      padding: 0 24px;

      h4 {
        margin-bottom: 39px;
      }

      .ftrs__takeaways_list {
        flex-direction: column;
        row-gap: 23px;

        .ftrs__takeaways_item {
          width: 100%;
          column-gap: 35px;

          div {
            height: 68px;
            min-width: 56px;
            display: flex;
            justify-content: center;

            img {
              height: 100%;
              width: auto;
            }
          }

          p {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: -4px;
          }
        }
      }
    }
  }
}
