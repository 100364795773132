@import '../../theme/modules/variables';
@import '../../theme/guidelines';
@import '../../theme/form';
@import '~theme/mixins';


.link_tab {  
  width: 450px;

  @include breakpoint-sm {
    width: 100%;
  }

  &.loading {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
      background: #fff;
      opacity: 0.15;
      cursor: progress;
      z-index: 1;
    }
  }
}

.simple_select :global(.react-selectize.dropdown-menu) {
  max-height: none;
}

.desc {
  display: block;
  opacity: 0.6;
  padding-left: 20px;
  font-size: 15px;
  line-height: 136%;
  font-family: "Libre Franklin", sans-serif;
  width: 100%;
  overflow: hidden;
  position: relative;
}