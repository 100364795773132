@import '~theme/modules/variables';
@import '~theme/mixins';
@import '../base';

.hidden {
  visibility: hidden;
}

.customDomain {
  font-weight: 300;
  text-align: left;
  font-family: $fontBaseV2;
  display: flex;
  padding-top: 40px;

  @include breakpoint-sm {
    flex-direction: column;
    padding-top: 24px;
  }

  .title {
    margin-bottom: 57px;
    font-size: 21px;
    font-weight: 600;
    color: black;
  }

  .addDomainForm {
    width: 500px;
    padding-right: 88px;

    @include breakpoint-sm {
      width: 100%;
      padding-right: 0;
    }

    .error {
      font-family: "Libre Franklin", sans-serif;
      font-weight: 300;
      font-size: 12px;
      line-height: 24px;
      height: 24px;
      color: var(--site-theme-color);
    }

    .formGroup {
      display: flex;
      align-items: flex-end;
      margin-bottom: 12px;

      .inputWrapper {
        display: flex;
        flex-direction: column;
        margin-right: 25px;

        @include breakpoint-sm {
          margin-right: 0;
          width: 100%;
        }

        .label {
          font-family: "Libre Franklin", sans-serif;
          font-weight: 300;
          line-height: 18px;
          font-size: 13px;
          margin-bottom: 8px;
          color: rgba(0, 0, 0, 0.4);
        }

        .labelSelect {
          margin-bottom: 0;
        }

        .input {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          padding: 0;
          font-family: "Libre Franklin", sans-serif;
          height: 26px;
          outline: none;
          background-color: #fff;

          @include breakpoint-sm {
            width: 100%;
          }
        }

        .select {
          height: 40px;
          width: 360px;

          @include breakpoint-sm {
            width: 100%;
          }
        }
      }

      .button {
        width: 200px;
        height: 48px;
        font-size: 15px;
        color: #fff;
        background: #000;
        font-weight: 600;
        border-radius: 0;
        border: none;
        outline: none;
        transition: background .3s ease;
        margin-bottom: 9px;

        @include breakpoint-sm {
          width: 100%;
        }

        &:disabled {
          opacity: 0.4;
          pointer-events: none;
        }

        &:hover {
          background: var(--site-theme-color);
        }

      }
    }
  }

  &:has(.notTeamAdmin) {
    flex-direction: column;

    .titleListOnly {
      margin-bottom: 10px;
    }

    .titleWarning {
      margin-bottom: 30px;
      font-style: italic;
    }
  }

  .domainList {
    display: flex;
    flex-direction: column;
    width: 427px;
    height: calc(100vh - 250px);
    overflow: auto;

    @include breakpoint-sm {
      height: 100%;
      overflow: unset;
      width: 100%;
    }

    .domainListItem {
      display: block;
      padding: 25px;
      position: relative;
      background: #fcfcfc;

      &:nth-child(odd) {
        background: #f5f5f5;
      }

      &:hover {
        .deleteIcon {
          display: block;
        }
      }

      .integrationName {
      }

      .domainName {
        font-size: 19px;
        line-height: 200%;
      }
    }
  }

  .deleteIcon {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
    display: none;

    .deleteIconImg {
      width: 12px;
      height: 12px;
    }
  }
}
