.colorPicker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: black;
}

.colorPicker_header {
  width: 24px;
  height: 24px;
  border: 1px solid black;
}

.colorPicker_content {
  position: absolute;
  transform: translateX(-50%);
  background-color: white;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  z-index: 1000;
  width: 455px;
  display: flex;
  flex-direction: column;

  .colorPicker_content_arrow {
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='12' viewBox='0 0 23 12' fill='none'%3E%3Cpath d='M11.5003 11.3135L0.186549 -0.000233721L22.814 -0.000231743L11.5003 11.3135Z' fill='white'/%3E%3C/svg%3E");
    width: 24px;
    height: 12px;
    z-index: -1;
  }
}

.colorPicker_content_picker {
  :global(.chrome-picker) {
    width: 100% !important;
    box-shadow: none !important;

    > div:nth-child(1) {
      padding-bottom: 200px !important;
      border-radius: 0 !important;
    }

    > div:nth-child(2) {
      padding: 0 !important;

      > :global(.flexbox-fix):nth-child(1) {
        padding: 12px 0;

        > div:nth-child(1) {
          display: none;
        }

        > div:nth-child(2) {
          > div:nth-child(1) {
            height: 8px !important;
            margin-bottom: 0 !important;
          }

          > div:nth-child(2) {
            display: none;
          }
        }

        :global(.hue-horizontal) > div > div {
          width: 8px !important;
          height: 24px !important;
          border-radius: 0 !important;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
          margin-top: -8px;
        }
      }

      > :global(.flexbox-fix):nth-child(2) {
        padding-top: 0 !important;

        > div:nth-child(2) {
          display: none;
        }

        input {
          height: 56px !important;
          box-shadow: none !important;
          border-radius: 0 !important;
          color: black !important;
          font-size: 18px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
          padding: 0;
        }

        label {
          display: none !important;
        }
      }
    }
  }
}

.colorPicker_content_buttons {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 16px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    height: 48px;
    width: 100%;
    transition: all 0.3s ease;

    &[data-type='submit'] {
      color: white;
      background: black;

      &:hover {
        background: var(--site-theme-color);
      }
    }
    &[data-type='cancel'] {
      color: black;
      border: 1px solid rgba(0, 0, 0, 0.2);

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }
}
