@import '~theme/mixins';
@import '~theme/modules/variables';
@import '~theme/guidelines';

.container {
  display: block;
  padding: 50px 45px;
  background-color: white;
  height: 100%;
  overflow: hidden;
}

.content {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.leftColumn {
  min-width: 235px;
  width: 235px;
  position: relative;
  display: block;
  padding-right: 0;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 30px 0 53px 38px;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    overflow-y: scroll;
  }

  .leftColumn_item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    column-gap: 20px;
    padding-left: 5px;
    padding-right: 16px;
    height: 36px;
    color: black;

    svg path {
      &[fill] {
        fill: currentColor;
      }
      &[stroke] {
        stroke: currentColor;
      }
    }

    &:hover {
      color: var(--site-theme-color)
    }
  }
}

.rightColumn {
  position: relative;
  flex-grow: 1;
  margin-top: 30px;
  margin-bottom: 53px;
  padding-left: 88px;

  &.isDashboard {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    background: #f4f4f4;
  }
}

.spacer {
  flex-shrink: 0;
  height: 36px;
}

.webTabOptions {
  min-height: 80px;

  &.invisible {
    visibility: hidden;
  }
  &.hide {
    display: none;
  }
}

.helpCentersDiff {
  background: #f2f2f2;
  margin-top: 24px;
  padding: 10px 8px;

  label {
    font-size: 15px;
    line-height: 136%;
    font-weight: 400;
    color: black;
    margin: 0;

    a {
      text-decoration: underline;
    }
  }
}

// Borrowed from old Tutorials.scss to prevent popup regressions
.newTutorials {
  height: 100%;
  overflow: hidden;
  background: white;


  :global(.text-highlighted) {
    color: #000;
  }

  :global(.inner-popup) {
    :global(.popup-content) {
      display: flex;
      align-items: center;
      text-align: center;

      & > div {
        margin: auto;
        width: 100%;
      }
    }
  }

  :global(.download_popup) {
    .download_container {
      margin: 0 auto;
      width: $gl-popup-inner-md;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  :global(.delete_modal) {
    .delete_modal_container {
      min-height: calc(100vh - 96px);
    }
  }

  :global(.restore_modal) {
    .restore_modal_container {
      min-height: calc(100vh - 96px);
    }
  }

  :global(.near-bottom) {
    #action_popover {
      transform: translateY(-310px);

      :global(.arrow) {
        transform: translateY(262px) rotateZ(180deg);
      }

      .action_list {
        margin-top: -10px;
      }

      .sub_menu_list {
        &::before {
          top: 250px;
          transform: rotateZ(180deg);
          display: block;
        }
      }

      @media only screen and (max-height: 640px) {
        transform: translateY(-240px);

        :global(.arrow) {
          transform: translateY(192px) rotateZ(180deg);
        }

        .sub_menu_list {
          &::before {
            top: 180px;
          }
        }
      }
    }

    &.draft #action_popover {
      transform: translateY(-149px);

      :global(.arrow) {
        transform: translateY(101px) rotateZ(180deg);
      }

      .sub_menu_list {
        &::before {
          top: 90px;
          transform: rotateZ(180deg);
          display: block;
        }
      }
    }
  }

  :global(.text-danger) {
    color: #ff0000 !important;
  }

  :global(.strong) {
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    :global(body.easy-popup-open) {
      :global(.contentClassName) {
        top: 0 !important;
      }
    }
  }
}

.newTutorials.newLayout {
  .rightColumn {
    margin-top: 24px;
    margin-bottom: 10px;
    padding-left: 24px;
  }
}

.overlay {
  &.showOverlay {
    display: block;
  }

  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 21; // larger than top menu
  cursor: alias;
  background: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 1080px) {
  .rightColumn {
    padding-left: 38px;
  }
}

.shareForEmbedInfo {

  &.content {
    flex-direction: column;
    padding-top: 80px;
    justify-content: flex-start;

    @media screen and (max-width: 1260px) {
      padding: 80px 40px 10px;
    }

    .tutorialForEmbedMenu {
      padding-bottom: 40px;
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;
      display: flex;
      gap: 20px;

      .menuItem {
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
      }

      .active {
        color: var(--site-theme-color);
      }
    }

    .tutorialForEmbed {
      flex-grow: 1;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
}
