@import '../../theme/modules/variables';
@import '../../theme/guidelines';
@import '../../theme/mixins';

.tabHeader {
  position: sticky;
  top: 98px;
  width: 100%;
  z-index: 2;
  background: white;

  .searchInputWrapper {
    margin-bottom: 40px;
    width: 100%;
    max-width: 600px;
  }
}

.listContainer {
  display: flex;
  gap: 40px;
  display: flex;
  flex-wrap: wrap;

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  @include breakpoint-sm {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    margin-top: 91px;
  }

  &.creatorContainer {
    @media screen and (min-width: 768px) {
      margin-top: 34px;
    }

    .listTitle.sticky {
      top: 68px;

      @media screen and (min-width: 768px) {
        top: 131px;
      }
    }
  }
}

.list {
  flex-grow: 0;
  min-width: 600px;
  max-width: 600px;

  &.short {
    flex-grow: 1;
    min-width: 360px;
  }

  @include breakpoint-sm {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &.short {
      width: 100%;
    }
  }
}

.listTitle {
  font-size: 21px;
  font-weight: 600;
  font-family: $fontBaseV2;
  color: #000000;
  padding-top: 24px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.sticky {
    position: sticky;
    top: 48px;
    background: white;
    z-index: 1;

    @media screen and (min-width: 768px) {
      top: 180px;
    }
  }

  .btnDeleteAll {
    width: fit-content;
    height: auto;
    padding: 0;
    border: 0 none;
    gap: 10px;
  }
}

.listItem {
  display: flex;
  font-family: 'Libre Franklin';
  font-size: 15px;
  border: 1px solid transparent;
  margin-top: -1px;

  &.header {
    color: rgba($color: #000000, $alpha: 0.4);
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    padding: 16px;
  }

  &:not(.header) {
    border-color: rgba($color: #000000, $alpha: 0.1);
    border-top-color: transparent;
  }

  .email,
  .author {
    display: flex;
    align-items: center;
    padding-right: 5px;
  }

  .author {
    width: 30%;
  }
  .email {
    width: 35%;
  }
  .action {
    width: 35%;
    justify-content: flex-end;
    text-align: right;
    display: flex;
    gap: 16px;
  }

  &:hover {
    &:not(.header) {
      border-color: rgba($color: #000000, $alpha: 0.25);
      border-top-color: rgba($color: #000000, $alpha: 0.25);
    }
  }
}

.empty_text {
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: black;

  span {
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}
