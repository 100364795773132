@use "sass:math";
@import 'guidelines_variables';
@import "mixins";

.gl-header {
  font-weight: $semibold;
  line-height: 40px;
  font-size: 32px;
  margin-top: 5 * $gl_gap;
  margin-bottom: 4 * $gl_gap;
  color: #000000;
  font-family: $header_font;
  text-align: center;
  /*@include breakpoint-lg {
    line-height: 32px;
  }*/

  &.first {
    margin-top: math.div($gl_gap_padding, 3);
  }

  &.gl-mb-sm {
    margin-bottom: 2 * $gl_gap;
  }
}

.gl-heading {
  font-family: $header_font;
  font-style: normal;
  font-weight: $semibold;
  line-height: 32px;
  font-size: 24px;
  color: #000;
  margin-top: 0;
  margin-bottom: 2 * $gl_gap;

  &.gl-mt {
    margin-top: 5 * $gl_gap;
  }
}

.gl-subheader {
  font-family: $header_font;
  color: #828282;
  font-weight: normal;
  line-height: 24px;
  font-size: 18px;
  margin-bottom: 2 * $gl_gap_padding;
  text-align: center;
}

.gl-content {
  font-family: $content_font;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  font-size: 16px;
  color: #333333;
  width: 100%;

  &.gl-mb {
    margin-bottom: 7 * $gl_gap;
  }
}

.gl-content-wrapper {
  width: $gl-wrapper-md;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $gl-wrapper-padding;

  @media (max-width: 767px) {
    width: $gl-wrapper-xs;
  }
}
