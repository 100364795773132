@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/guidelines.scss';
@import '~theme/modules/variables';

.switchHelpCenter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 2;
  display: flex;
  flex-direction: column;

  // &.extensionEmbed {
  //   height: calc(100% - 48px);
  // }

  .switchHelpCenter_body {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .switchHelpCenter_picker {
    padding: 24px;
  }

  .switchHelpCenter__form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .switchHelpCenter__form_group {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .switchHelpCenter__form_label {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 10px;
    }

    .switchHelpCenter__form_input {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 15px;
      line-height: 20px;
      color: black;
      height: 56px;
      column-gap: 10px;
      caret-color: var(--site-theme-color);

      &:focus-within {
        border-bottom-color: black;
      }

      input {
        padding: 0;
        margin: 0;
        outline: none;
        border: none;
        flex-grow: 1;
        height: 100%;
      }

      button {
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        outline: none;
        font-weight: 600;
      }
    }
  }

  .switchHelpCenter_footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .switchHelpCenter_footer_content {
      display: flex;
      flex-direction: column;
    }

    .switchHelpCenter_footer_content_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      height: 56px;
      padding: 0 24px;

      label {
        display: flex;
        align-items: center;
        column-gap: 20px;
        margin: 0;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: black;
        margin: 0;
        flex-grow: 1;
      }

      button {
        outline: none;
        border: none;
        padding: 0;
        background: transparent;
        color: black;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        margin-left: 20px;

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }

    .switchHelpCenter_footer_content_button {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding: 24px;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      button {
        margin: 0 auto;
        max-width: 320px;
        height: 56px;
      }
    }
  }
}