@import '~theme/modules/variables';
@import '../includes';

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 20px;
  padding: 3px 0;

  .removeDirectly {
    cursor: pointer;
    display: none;
    font-size: 11px;
    font-weight: bold;
  }

  &.blur {
    opacity: 0.2;
    pointer-events: none;
  }

  &:hover {
    .notifyText {
      display: none;
    }
  }

  .buttonIcon {
    margin-right: 20px;
    margin-top: 4px;
    cursor: pointer;
    opacity: .4;

    &:last-child {
      margin-left: 15px;
      margin-right: 0;
    }
    &:hover {
      opacity: 1;
    }
  }

  .buttonIcon img {
    width: 16px;
    margin: 0 15px;
  }

  .name {
    font-size: 15px;
    font-family: $fontBaseV2;
    word-break: break-word;
    max-width: 151px;

    &.highlight {
      color: var(--site-theme-color);
    }

    &.trash {
      color: #999999;
    }
  }

  .itemCount {
    color: #999999;
    font-size: 10px;
    font-family: $fontBaseV2;
    text-align: right;
  }

  .notifyText {
    display: none;
    font-size: 10px;
    font-family: $fontBaseV2;
    text-align: right;
    margin-left: auto;
    min-width: 34px;
    max-width: 34px;
    margin-top: 1px;
  }

  .addButton {
    display: none;
  }

  &.entering {
    @include addingAnimation();
  }
}
