@import '~theme/mixins';
@import '~theme/modules/variables';

.account_tab {
  display: flex;
  padding: 0 40px;
  @include nice-scrollbar;

  .sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    width: 214px;
    padding-top: 40px;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      cursor: pointer;

      &:hover,
      &.active {
        color: var(--site-theme-color);
      }
    }
  }

  .content {
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@include breakpoint-sm {
  .account_tab {
    padding-left: 24px;
    padding-right: 13px;
    overflow-y: scroll;
    flex-direction: column;

    .sidebar {
      padding: 24px 0;
      z-index: 2;
      width: 100%;
    }

    .tabs {
      flex-direction: row;
      column-gap: 24px;
    }

    .content {
      padding-left: 0;
    }
  }
}
