@import '../../theme/mixins';
@import '~theme/link_underline';

.checkoutPage {
  margin-left: 38px;
  margin-top: 40px;
  padding-bottom: 70px;
  font-family: 'Libre Franklin', sans-serif;
}
.splitBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 800px;
  & > * {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
.paymentInfo {
  margin-right: 90px;
}
.planInfo {
  margin-bottom: 70px;
}
.title {
  margin-bottom: 57px;
  font-size: 21px;
  font-weight: 600;
}
.creditCardBox {
  display: flex;
  margin-bottom: 40px;
  .firstCard {
    width: 100%;
    display: flex;
  }
  .brandLogo {
    background-size: 14px;
    width: 50px;
    height: 40px;
    margin-right: 70px;
    max-height: 40px;
    background: url('~assets/img/visa.svg') no-repeat 0 center;

    &:global(.MasterCard) {
      background: url('~assets/img/mastercard.svg') no-repeat 0 center;
    }
  }
}

.checkoutFormWrapper {
  display: flex;
  flex-direction: column;
}

.groupLabel {
  font-size: 15px;
  margin-bottom: 18px;
}
.planSelect {
  width: 100%;
}
.planSelectGroup, .frequencyGroup {
  margin-bottom: 65px;
}
.nextPlanInfo {
  font-size: 15px;
  margin-bottom: 20px;
  .row {
    white-space: nowrap;
  }
  :global(.new-cost) {
    line-height: inherit;
  }
  .amount {
    font-size: inherit;
    font-weight: 600;
    display: inline;
  }
  .trialDays {
    font-weight: 600;
    color: #0047ff;
  }
}
.nextInvoice {
  font-size: 15px;
}
.checkoutBtn {
  margin-bottom: 20px;
  &.checkoutBtn { // increase rule precedence
    margin-top: 0;
  }
}
.planTitle {
  font-size: 35px;
  padding-bottom: 30px;
}
.agreement {
  margin-bottom: 45px;
  font-weight: inherit;
  display: flex;
  align-items: flex-start;
  max-width: 400px;

  input[type="checkbox"] {
    margin-top: 0;
    margin-right: 5px;
  }

  &.error {
    input[type="checkbox"]:before {
      border-color: var(--site-theme-color);
    }
  }

  a {
    @extend .link-underline;
  }
}

@include breakpoint-sm {
  .splitBox {
    width: 100%;
    flex-direction: column;
    max-height: none;
    flex-wrap: nowrap;
    .paymentInfo {
      width: 100%;
      order: 1;
      margin-right: 0;
      margin-bottom: 30px;
    }
    .planInfo {
      width: 100%;
      order: 0;
      margin-bottom: 0;
    }
    .agreement {
      order: 2;
    }
    .checkoutBox {
      order: 3;
    }
  }
  .firstCard {
    width: 100%;
  }
  .checkoutPage {
    margin: 0;
    padding: 80px 22px;
    width: 100%;
  }
  .checkoutFormWrapper {
    width: 100%;
  }
  .title {
    margin-bottom: 28px;
  }
  .planSelectGroup {
    margin-bottom: 50px;
  }
  .paymentInfo {
    margin-top: 54px;
  }
  .creditCardBox {
    .brandLogo {
      flex-shrink: 0;
      margin-right: 22px;
    }
  }
  .contactInfo {
    .cols2 {
      & > * {
        flex-shrink: 1;
        min-width: 1px;
      }
    }
  }
  .checkoutBtn {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    height: 48px;
    margin: 0;
    width: 100% !important;
  }
}
