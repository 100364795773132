.unsupported_browser {
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  line-height: 35px;
  vertical-align: middle;
  text-align: center;
  color: #a94442;
  background: #f2dede;
  margin-bottom: 20px;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  z-index: 99999;
  font-weight: 600;
}