@import '~theme/mixins';
@import '~theme/modules/variables';

.question_filter_sort {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background: white;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      background: white;
      height: 100%;
    }

    &:hover {
      &::after {
        content: 'Close';
        background: #f4f4f4;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include fontHelpCenter;
      }
    }

    &_close {
      width: 48px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      flex-grow: 1;
      text-align: center;

      span {
        @include fontHelpCenter;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    // padding: 0 0 16px;
    overflow: hidden;

    &_tab_header {
      display: flex;
      font-size: 15px;
      margin-bottom: 24px;

      &_item {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.4);
        height: 56px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 600;

        &:last-child {
          border-right: 0 none;
        }

        &.active,
        &:hover {
          color: #000;
        }

        &.active {
          border-bottom-color: transparent;
        }
      }
    }

    &_tab_content {
      overflow-y: auto;

      &:not(.active) {
        display: none;
      }
    }
  }

  &__sorting,
  &__filtering {
    li {
      padding: 0 24px;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &__sorting {
    h3 {
      @include fontHelpCenter;
      font-weight: 600;
      margin: 0;
      padding: 16px 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: flex;
      align-items: center;
      column-gap: 16px;
      height: 56px;
      cursor: pointer;

      &.active {
        svg {
          display: flex;
        }
      }

      span {
        @include fontHelpCenter;
        text-transform: capitalize;
      }

      svg {
        flex-shrink: 0;
        display: none;
        &:last-child {
          margin-left: auto;
        }
      }
    }
  }

  &__filtering {
    h3 {
      @include fontHelpCenter;
      font-weight: 600;
      margin: 0;
      padding: 16px 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: flex;
      align-items: center;
      column-gap: 16px;
      height: 56px;
      cursor: pointer;

      span {
        @include fontHelpCenter;
        text-transform: capitalize;
        flex-grow: 1;

        &.filters_count {
          flex-grow: 0;
        }
      }

      svg {
        flex-shrink: 0;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    gap: 16px;

    .btnGroup {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
    }

    &_applyBtn {
      flex-shrink: 0;
      height: 56px;
      margin: 16px 24px 0;
      width: auto;
      @include fontHelpCenter;
      font-weight: 600;
      align-self: center;
      width: 100%;
      max-width: 320px;
      background: #000;
      color: white;

      &:hover {
        background: #3b3b3b;
      }

      &.savePresetBtn {
        background: #f4f4f4;
        color: #000;

        &:hover {
          background: #ffe7fb;
        }
      }

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  &__save_as_preset,
  &__my_presets {
    padding: 0 24px;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  &__save_as_preset {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    @include fontHelpCenter;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &__my_presets {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 56px;
    cursor: pointer;

    span {
      @include fontHelpCenter;
      flex-grow: 1;

      &.filters_count {
        flex-grow: 0;
      }
    }

    svg {
      flex-shrink: 0;
    }
  }

  &__tagsGroup,
  &__tags {
    // margin-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    flex-grow: 1;
  }

  &__tagsGroup {
    display: flex;
    height: 56px;
    width: 100%;

    .text {
      @include fontHelpCenter;
      flex-grow: 1;
      height: 56px;
      display: flex;
      align-items: center;

      span {
        margin-right: 10px;
      }
    }

    &_reset {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include fontHelpCenter;
      cursor: pointer;
      font-weight: 600;

      &:hover {
        color: var(--marker-color);
      }
    }
  }

  &__tags {
    display: flex;
    // flex-wrap: wrap;
    gap: 10px;

    &_item {
      display: flex;
      align-items: center;
      height: 56px;
      background: #f4f4f4;
      padding-left: 16px;
      overflow: hidden;
      min-width: 120px;

      span {
        @include fontHelpCenter;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &_close {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        cursor: pointer;
        width: 56px;
        height: 100%;
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @include breakpoint-sm {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 13;
  }
}

.filter_option_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    padding: 0 16px;
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      background: white;
      height: 100%;
    }

    span {
      @include fontHelpCenter;
    }
  }

  &_search {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: none;
      outline: none;
      width: 100%;
      @include fontHelpCenter;
      caret-color: var(--marker-color);
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px 0;
  }

  &_list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;

    &_item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 56px;
      cursor: pointer;
      padding: 0 16px;

      span {
        @include fontHelpCenter;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        margin-right: 16px;
      }

      svg {
        flex-shrink: 0;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    &_empty {
      padding: 16px;
      @include fontHelpCenter;
    }
  }

  &_loading {
    padding: 16px;
    @include fontHelpCenter;
  }

  &_footer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    row-gap: 24px;

    &_doneBtn {
      flex-shrink: 0;
      height: 56px;
      margin: 0 24px;
      width: auto;
      @include fontHelpCenter;
      color: white;

      &:hover {
        background: var(--marker-color);
      }
    }
  }
}

.save_preset_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;

  &_header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    padding: 0 16px;
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      background: white;
      height: 100%;
    }

    span {
      @include fontHelpCenter;
      text-align: center;
      flex-grow: 1;
    }

    svg {
      position: absolute;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
  }

  &_input {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: none;
      outline: none;
      width: 100%;
      @include fontHelpCenter;
      caret-color: var(--marker-color);
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    row-gap: 8px;

    &_saveBtn {
      flex-shrink: 0;
      height: 56px;
      max-width: 312px;
      @include fontHelpCenter;
      color: white;

      &:hover {
        background: var(--marker-color);
      }
    }

    &_cancelBtn {
      flex-shrink: 0;
      height: 56px;
      max-width: 312px;
      border: none !important;
      @include fontHelpCenter;

      &:hover {
        color: var(--marker-color) !important;
      }
    }
  }
}

.my_presets_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    padding: 0 16px;
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      background: white;
      height: 100%;
    }

    span {
      @include fontHelpCenter;
    }
  }

  &_search {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: none;
      outline: none;
      width: 100%;
      @include fontHelpCenter;
      caret-color: var(--marker-color);
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px 0;
  }

  &_list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .preset_item {
      display: flex;
      flex-shrink: 0;
      height: 56px;

      &_name {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 0 16px;
        width: 100%;
        cursor: pointer;
        border-bottom: 1px solid transparent;

        &:hover {
          .preset_item_name_btn {
            display: flex;
          }
        }

        span {
          flex-grow: 1;
          @include fontHelpCenter;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }

        &_btn {
          display: none;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 24px;
          height: 100%;
          opacity: 0.4;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }

      &_input {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 0 16px;
        width: 100%;
        border-bottom: 1px solid black;

        input {
          flex-grow: 1;
          border: none;
          outline: none;
          padding: 0;
          margin: 0;
          caret-color: var(--marker-color);
          @include fontHelpCenter;
          background: transparent;
        }

        &_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 24px;
          height: 100%;
          cursor: pointer;
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    row-gap: 24px;

    &_doneBtn {
      flex-shrink: 0;
      height: 56px;
      margin: 0 24px;
      width: auto;
      @include fontHelpCenter;
      color: white;

      &:hover {
        background: var(--marker-color);
      }
    }
  }
}
