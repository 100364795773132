@import '~theme/modules/variables';
@import '~theme/mixins';

$header-height: 76px;

.easyPopup {
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-width: 100vw;
  max-height: 100vh;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    height: $header-height;
    padding: 10px 20px;

    .headerContent {
      flex-grow: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .button {
      font-size: 16px;
      width: 240px;
      height: 100%;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;

      &.buttonCancel {
        border: 1px solid #F4F4F4;
        background: #F4F4F4;
        color: #000000;

        &:hover {
          background: #FFFFFF;
        }
      }
    }
  }

  .content {
    flex-grow: 1;
    overflow: hidden;
    max-height: 100%;
  }
}
