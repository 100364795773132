@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  background: #f4f4f4;
  padding: 71px 0 74px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleContent {
    @include ioradFont;
    padding-bottom: 101px;
  }

  .applyBtn {
    width: 276px;
    height: 48px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    transition: color 0.3s ease;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}

.supportSection {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
  background-position: bottom center;
  background-repeat: no-repeat;

  .supportContent {
    padding: 81px 64px 148px;
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .titleContent {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    margin: 0;
    margin-bottom: 26px;
  }

  .descWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    max-width: 616px;

    p {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }
  }
}

.benefitsSection {
  padding-top: 46px;

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 41px;
  }

  .benefitList {
    max-width: $maxWidthContent;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 64px;
  }

  .benefitItem {
    display: flex;
  }

  .benefitImg {
    background: #77c082;

    img {
      width: 635px;
      height: 334px;
    }
  }

  .benefitContent {
    display: flex;
    flex-direction: column;
    margin-top: 39px;
    margin-left: 58px;
    max-width: 518px;
  }

  .benefitTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 22px;
  }

  .benefitDesc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }
}

.whoShouldApplySection {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
  background-position: bottom center;
  background-repeat: no-repeat;

  &__content {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 153px 64px 167px;
  }

  &__title {
    font-size: 32px;
    line-height: 44px;
    font-weight: 600;
    color: black;
    margin: 0;
    margin-bottom: 47px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 27px 48px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    max-width: 380px;
    display: flex;
    flex-direction: column;

    h5 {
      margin: 0;
      margin-bottom: 5px;
      font-size: 15px;
      line-height: 20px;
      color: black;
      font-weight: 600;
    }

    p {
      margin: 0;
      font-size: 15px;
      line-height: 20px;
      color: black;
      min-height: 86px;
    }
  }
}

.howItWorksSection {
  background: #f4f4f4;

  &__content {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 61px 64px 75px;
  }

  &__title {
    font-size: 32px;
    line-height: 44px;
    font-weight: 600;
    color: black;
    margin: 0;
    margin-bottom: 29px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 88px 102px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    width: 519px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &_number {
      color: #000;
      font-family: 'iorad';
      font-size: 204px;
      font-style: normal;
      font-weight: 400;
      line-height: 67%;
      letter-spacing: -1.224px;
      text-transform: uppercase;
    }

    &_content {
      display: flex;
      flex-direction: column;
      max-width: 382px;
      margin-bottom: 11px;

      h5 {
        margin: 0;
        margin-bottom: 5px;
        font-size: 15px;
        line-height: 20px;
        color: black;
        font-weight: 600;
      }

      p {
        margin: 0;
        font-size: 15px;
        line-height: 20px;
        color: black;
      }
    }
  }
}

.ioradSupportSection {
  &__content {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 106px 64px 223px;
  }

  &__title {
    font-size: 32px;
    line-height: 44px;
    font-weight: 600;
    color: black;
    margin: 0;
    margin-bottom: 47px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 27px 48px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__item {
    max-width: 380px;
    display: flex;
    flex-direction: column;

    h5 {
      margin: 0;
      margin-bottom: 5px;
      font-size: 15px;
      line-height: 20px;
      color: black;
      font-weight: 600;
    }

    p {
      margin: 0;
      font-size: 15px;
      line-height: 20px;
      color: black;
      min-height: 86px;
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 206px 146px 167px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .footerBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;
    padding: 67px 0 83px;

    &.isLogged {
      margin-top: 46px;
    }

    .titleWrapper {
      padding: 0;

      img {
        width: 100%;
      }
    }

    .applyBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
      margin-top: 78px;
    }
  }

  .supportSection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_small.png');

    .supportContent {
      padding: 119px 24px 125px;
    }

    .titleContent {
      font-size: 21px;
      line-height: 136%;
      margin-bottom: 18px;
    }

    .descWrapper {
      max-width: 100%;

      p {
        font-size: 13px;
      }
    }
  }

  .benefitsSection {
    padding-top: 29px;

    .titleWrapper {
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      line-height: 116%;
      margin-bottom: 28px;
    }

    .benefitList {
      padding: 0 24px;
      row-gap: 43px;
    }

    .benefitItem {
      flex-direction: column;
    }

    .benefitImg {
      img {
        width: 100%;
        height: 163px;
        object-fit: cover;
      }
    }

    .benefitContent {
      max-width: 100%;
      margin-top: 24px;
      margin-left: 0;
    }

    .benefitTitle {
      font-size: 21px;
      line-height: 116%;
    }

    .benefitDesc {
      font-size: 13px;
    }
  }

  .whoShouldApplySection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_small.png');

    &__content {
      max-width: 100%;
      padding: 120px 24px 122px;
    }

    &__title {
      font-size: 21px;
      line-height: 28px;
      margin-bottom: 21px;
    }

    &__list {
      gap: 25px 0;
    }

    &__item {
      max-width: 100%;

      h5 {
        margin-bottom: 9px;
        font-size: 13px;
        line-height: 18px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        min-height: auto;
      }
    }
  }

  .howItWorksSection {
    &__content {
      max-width: 100%;
      padding: 60px 24px 75px;
    }

    &__title {
      font-size: 21px;
      line-height: 28px;
      margin-bottom: 72px;
    }

    &__list {
      gap: 35px 0;
    }

    &__item {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;

      &_number {
        font-size: 105px;
      }

      &_content {
        max-width: 100%;
        margin-bottom: 0;
        margin-top: 23px;

        h5 {
          margin-bottom: 7px;
          font-size: 13px;
          line-height: 18px;
        }

        p {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }

  .ioradSupportSection {
    &__content {
      max-width: 100%;
      padding: 121px 24px 72px;
    }

    &__title {
      font-size: 21px;
      line-height: 28px;
      margin-bottom: 21px;
    }

    &__list {
      gap: 20px 0;
    }

    &__item {
      max-width: 100%;

      h5 {
        margin-bottom: 9px;
        font-size: 13px;
        line-height: 18px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        min-height: auto;
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .footerBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
