.layout {
  display: flex;
  column-gap: 8px;
  background: #f4f4f4;
  height: 100vh;
  overflow: hidden;

  &.extensionEmbed {
    column-gap: 0;

    .menu {
      display: none;
    }

    .content {
      margin: 0;
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background: white;
    width: 240px;
    transition: width 0.3s;
    overflow: hidden;

    .menu__header {
      display: flex;
      position: relative;

      &:not(:hover) {
        .menu__header_options {
          display: none;
        }
      }

      .menu__header_button {
        height: 48px;
        background: black;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 16px;
        flex-grow: 1;
        color: white;
        transition: background 0.3s ease;
        padding: 0;

        .menu__header_button_icon {
          display: flex;
        }

        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          white-space: nowrap;
          transition: opacity 0.2s ease;
        }

        &:hover {
          background: var(--site-theme-color);
        }
      }

      .menu__header_options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #f4f4f4;
        display: flex;
        column-gap: 1px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);

        .menu__header_option {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;
          row-gap: 16px;
          padding: 24px;
          min-height: 40px;
          background: white;
          color: black;
          cursor: pointer;
          z-index: 1;

          span {
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;
          }

          svg path {
            fill: currentColor;
          }

          &:hover {
            color: var(--site-theme-color);
          }
        }
      }
    }

    .menu__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .menu__get_started {
      padding: 0 24px;
    }

    .menu__main {
      display: flex;
      flex-direction: column;

      .menu__main_list {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 16px 0;

        .menu__main_list_item {
          display: flex;
          flex-direction: column;
          color: inherit;

          &.active {
            background: #{'rgba(var(--site-theme-color-rgb), 0.05)'};
          }

          &.isLink {
            &:hover {
              background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
            }
          }
        }

        .menu__main_list_item_title {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          column-gap: 8px;
          height: 40px;
          cursor: pointer;
          padding: 0 24px;
        }

        .menu__main_list_item_icon {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: 24px;
        }

        .menu__main_list_item_label {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
        }

        .menu__main_list_item_sublist {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          max-height: 0;

          &.expanded {
            max-height: 500px;
            transition: max-height 0.3s ease;
          }
        }

        .menu__main_list_item_subitem {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          height: 40px;
          cursor: pointer;
          padding-left: 56px;
          padding-right: 24px;

          &.active {
            background: #{'rgba(var(--site-theme-color-rgb), 0.05)'};
          }

          &:not(.active):hover {
            background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
          }

          a {
            display: flex;
            align-items: center;
            height: 100%;
            flex-grow: 1;
            font-size: 15px;
            line-height: 20px;
            white-space: nowrap;
            color: inherit;
          }

          .menu__main_list_item_subitem_badge {
            display: flex;
            align-items: center;
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .menu__sub {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .menu__sub_item {
        display: flex;
        align-items: center;
        column-gap: 8px;
        flex-shrink: 0;
        height: 40px;
        padding: 0 24px;
        cursor: pointer;
        color: inherit;

        .menu__sub_item_icon {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: 24px;

          svg path,
          svg rect {
            &[fill='black'] {
              fill: currentColor;
            }
            &[stroke='black'] {
              stroke: currentColor;
            }
          }
        }

        .menu__sub_item_label {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          white-space: nowrap;
        }

        &.active {
          background: #{'rgba(var(--site-theme-color-rgb), 0.05)'};
        }

        &:not(.active):hover {
          background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
        }
      }
    }

    .menu__profile {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;

      .menu__profile_title {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        column-gap: 16px;
        height: 40px;
        cursor: pointer;
        padding: 0 24px;

        .menu__profile_title_avatar {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: 16px;
          height: 16px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .menu__profile_title_label {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .menu__profile_list {
        display: flex;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;

        &.expanded {
          max-height: 300px;
          transition: max-height 0.3s ease;
        }

        .menu__profile_item {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          height: 40px;
          padding-left: 56px;
          padding-right: 24px;
          cursor: pointer;
          font-size: 15px;
          line-height: 20px;
          color: inherit;

          &.active {
            background: #{'rgba(var(--site-theme-color-rgb), 0.05)'};
          }

          &:not(.active):hover {
            background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
          }
        }
      }
    }

    .menu__divider {
      height: 1px;
      background: rgba(0, 0, 0, 0.2);
      margin: 12px 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: white;
    overflow-x: hidden;
    margin: 8px;
    margin-left: 0;

    &.isDashboard {
      margin: 0;
    }
  }
}
