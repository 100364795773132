@import '~theme/modules/variables';
.premium_feature {
  position: relative;
  display: flex;
  align-items: center;

  .premium_feature__icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
  }
}

.premium_feature__popover {
  border-radius: 0;
  background: white;
  border: 1px solid var(--site-theme-color);
  padding: 22px 24px;
  margin-top: -20px !important;
  // position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(var(--site-theme-color-rgb), 0.05);
    z-index: -1;
  }

  &:global(.bottom) {
    margin-top: 10px !important;

    :global(.arrow) {
      top: -11px;
      border-bottom-color: var(--site-theme-color);
      border-bottom-width: 11px;
      border-top-width: 0;

      &:after {
        top: 1px;
        border-bottom-color: #fdf2fc;
        border-bottom-width: 10px;
        border-top-width: 0;
      }
    }

    .premium_feature__extraHoverArea {
      bottom: auto;
      top: 0;
      transform: translateY(-100%);
    }
  }

  :global(.arrow) {
    border-top-color: var(--site-theme-color) !important;

    &::after {
      border-top-color: #fdf2fc !important;
    }
  }

  :global(.popover-title) {
    background-color: transparent;
    border-radius: 0;
    border-bottom: none;
    padding: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
    font-weight: 600;
  }

  :global(.popover-content) {
    padding: 0;
  }

  .premium_feature__plan {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    margin-top: 7px;
  }

  .premium_feature__desc {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: black;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .premium_feature__link {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: black;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  .premium_feature__button {
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 21px;
    cursor: pointer;
  }

  .premium_feature__extraHoverArea {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 100%;
    transform: translateY(100%);
  }
}
