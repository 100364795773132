@import '~theme/modules/variables';
@import '~theme/mixins';

.shareSection {
  background-color: #93c4ff;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px 52px;
  }

  .titleContent {
    @include ioradFont;
    line-height: 69%;
    font-size: 377px;
    padding-top: 19px;
    padding-bottom: 73px;

    @media screen and (max-width: 1336px) {
      font-size: 27vw;
    }
  }

  .content {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    width: 870px;
    margin: 0;

    @media screen and (max-width: 950px) {
      width: 100%;
    }

    .breakContent {
      height: 19px;
      display: block;
      background: transparent;
    }

    a {
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.integrationsWrapper {
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 66px 64px 173px;

  .integrationsList {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, 119px);
    justify-content: center;

    .integrationItem {
      height: 119px;
      background: #f4f4f4;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }

  .seeMoreLink {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    text-align: right;
    margin: 0;
    margin-top: 24px;

    a {
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@include breakpoint-sm {
  .shareSection {
    .contentWrapper {
      padding: 44px 24px 37px;
    }

    .titleContent {
      font-size: 175px;
      line-height: 58%;
      word-break: break-all;
      padding-top: 13px;
      padding-bottom: 39px;
    }

    .content {
      font-size: 13px;
      width: 100%;
      margin-top: 5px;
    }
  }

  .integrationsWrapper {
    padding: 57px 24px 89px;

    .integrationsList {
      grid-gap: 2px;
      grid-template-columns: repeat(auto-fill, 63px);

      .integrationItem {
        height: 63px;
      }
    }

    .seeMoreLink {
      font-size: 13px;
    }
  }
}
