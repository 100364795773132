@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/guidelines.scss';
@import '~theme/modules/variables';

$mainColor: #fff;
$activeColor: rgba(0, 0, 0, 0.05);

.notifications_popup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  top: 0;
  left: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;

  &__header {
    $size: 48px;
    width: 100%;
    height: $size;
    display: flex;
    flex-direction: row;
    background: #f4f4f4;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;

    &:hover {
      .notifications_popup__header_title::after {
        content: 'Back';
        background: #f4f4f4;
        font-weight: 400;
        font-size: 16px;
        line-height: 136%;
        color: black;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &_back {
      width: $size;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 136%;
      color: black;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
}

.sidebar {
  width: 400px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  padding: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  &.loading {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba($color: #FFF, $alpha: 0.4);
    }
  }
}

.notificationsView {
  display: flex;
  flex-grow: 1;
  height: 100%;

  .sidebar {
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar_category_list {
    display: flex;
    flex-direction: column;
  }

  .sidebar_category_item {
    display: flex;
    align-items: center;
    position: relative;
    height: 56px;
    padding-left: 24px;
    padding-right: 24px;

    &:not(.enabled) {
      .sidebar_category_item_label span {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &.active {
      .sidebar_category_item_label svg {
        display: block;
      }
    }

    &.active,
    &:hover {
      background: $activeColor;
    }
  }

  .sidebar_category_item_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-inline: 10px;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      svg {
        [fill] {
          fill: var(--site-theme-color);
        }

        [stroke] {
          stroke: var(--site-theme-color);
        }
      }
    }
  }

  .sidebar_category_item_label {
    display: flex;
    align-items: center;
    column-gap: 16px;
    flex-grow: 1;

    span {
      @include fontHelpCenter;
    }

    svg {
      display: none;
    }
  }

  .sidebar_category_item_newIndicator {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: var(--site-theme-color);
    position: absolute;
    right: -14px;
  }

  .sidebar_group_button {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  .sidebar_settings_button,
  .sidebar_clear_notis_button {
    border: 0;
    padding: 0;
    // margin: 0;
    width: 100%;
    height: 56px;
    background: transparent;
    display: flex;
    align-items: center;
    @include fontHelpCenter;

    padding-left: 24px;
    padding-right: 24px;

    svg {
      color: inherit;
      margin-right: 24px;

      [fill] {
        fill: currentColor;
        color: inherit;
      }

      [stroke] {
        stroke: currentColor;
        color: inherit;
      }
    }

    &:focus {
      outline: none;
    }

    &:hover {
      // color: var(--site-theme-color);
      background: $activeColor;
    }
  }

  .content_empty {
    display: flex;
    justify-content: center;
    align-items: center;
    @include fontHelpCenter;
    padding: 24px 0;
  }

  .content_notification_list {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
  }

  .content_notification_time {
    @include fontHelpCenter;
    color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    padding-bottom: 8px;
  }

  .content_notification_item {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 20px;
    border: 1px solid $activeColor;
    margin-top: 16px;
    cursor: pointer;

    &:hover {
      background: $activeColor;
    }
  }

  .content_notification_item_userAvatar {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content_notification_item_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 24px;
  }

  .content_notification_item_text {
    @include fontHelpCenter;
    word-break: break-word;

    a:hover,
    a:focus {
      color: #000;
      text-decoration: none;
    }
  }

  .content_notification_item_product {
    @include fontHelpCenter;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.4);
  }

  .content_notification_item_newIndicator {
    position: absolute;
    left: -24px;
    top: 50%;
    transform: translate(100%, -50%);
    width: 6px;
    height: 6px;
    background: var(--site-theme-color);
  }
}

.settingsView {
  display: flex;
  flex-grow: 1;
  height: 100%;

  .sidebar_category_list {
    display: flex;
    flex-direction: column;
  }

  .sidebar_category_item {
    display: flex;
    align-items: center;
    position: relative;
    height: 56px;

    &.active {
      .sidebar_category_item_label svg {
        display: block;
      }
    }
  }

  .sidebar_category_item_label {
    display: flex;
    align-items: center;
    column-gap: 16px;
    flex-grow: 1;

    span {
      @include fontHelpCenter;
    }

    svg {
      display: none;
    }
  }

  .sidebar_close_button {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 56px;
    margin-top: auto;
    background: #f4f4f4;
    @include fontHelpCenter;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
    }

    &:hover {
      color: var(--site-theme-color);
    }
  }

  .content_empty {
    display: flex;
    justify-content: center;
    align-items: center;
    @include fontHelpCenter;
    padding: 24px 0;
  }

  .content_settings_table_container {
    width: 100%;
    max-width: 900px;
    padding: 24px 0;
    margin: 0 auto;
    overflow-x: hidden;

    .content_settings_table {
      display: flex;
      flex-direction: column;
      padding: 10px 24px;
    }

    .content_settings_table__header {
      display: flex;
      column-gap: 24px;
      flex-shrink: 0;
      height: 56px;

      .content_settings_table__header_item {
        flex-grow: 1;
        span {
          @include fontHelpCenter;
          color: rgba(0, 0, 0, 0.4);
        }
      }

      .content_settings_table__header_checkbox {
        display: flex;
        justify-content: flex-end;
        flex-shrink: 0;
        width: 172px;

        input {
          display: none;
        }

        label {
          @include fontHelpCenter;
          margin: 0;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }

    .content_settings_table__body {
      display: flex;
      flex-direction: column;
      row-gap: 11px;
    }

    .content_settings_table__row {
      display: flex;
      align-items: center;
      column-gap: 24px;
      min-height: 56px;
    }

    .content_settings_table__label {
      flex-grow: 1;

      span {
        @include fontHelpCenter;
      }
    }

    .content_settings_table__checkbox {
      display: flex;
      justify-content: flex-end;
      flex-shrink: 0;
      width: 172px;

      input {
        display: none;
      }
      label {
        visibility: hidden;
        opacity: 0.4;
        cursor: pointer;
        margin: 0;
      }

      &.checked {
        label {
          visibility: visible;
          opacity: 1;
        }
      }

      &:hover {
        label {
          visibility: visible;
        }
      }
    }
  }
}
