@import '~theme/modules/variables';
@import '~theme/mixins';

.footerSection {
  background: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 180px;
  padding-bottom: 100px;
  margin-top: -2px;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding-left: 64px;
    padding-right: 158px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .authWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .signUpBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-bottom: 22px;

    &:hover {
      color: #fff;
      background-color: var(--site-theme-color);
    }
  }

  .seePurchase {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;

    a {
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@include breakpoint-sm {
  .footerSection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;
    padding-top: 166px;
    padding-bottom: 198px;

    .content {
      flex-direction: column;
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      margin-bottom: 71px;
    }

    .authWrapper {
      padding: 0 16px;
    }

    .signUpBtn {
      width: 295px;
      font-size: 13px;
    }

    .seePurchase {
      font-size: 13px;
    }
  }
}
