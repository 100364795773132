.new_cost {
  text-align: left;
  line-height: 40px;

  .amount {
    font-weight: 600;
    // font-size: 21px;
    line-height: 116%;
  }

  .frequency {
    font-size: 15px;
  }
}
