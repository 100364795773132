.button {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  flex-shrink: 0;
  border: none;
  padding: 16px 24px;
  outline: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  min-width: 240px;
  cursor: pointer;
  transition: all .3s ease;

  svg {
    flex-shrink: 0;

    path, rect, line, circle {
      &[stroke='black'] {
        stroke: currentColor;
      }
      &[fill='black'] {
        fill: currentColor;
      }
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.solid {
    background: black;
    color: white;

    &:hover {
      background: var(--site-theme-color);
    }
  }

  &.outlined {
    background: white;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.2);

    &:hover {
      background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
    }
  }

  &.filled {
    background: #f4f4f4;
    color: black;

    &:hover {
      background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
    }
  }

  &.text {
    background: transparent;
    color: black;
    padding: 0;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  &.small {
    height: 40px;
  }

  &.medium {
    height: 56px;
  }
}