:root {
  --marker-color: var(--site-theme-color);

  @-moz-document url-prefix() {
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    scrollbar-width: thin;
  }
}

$header_font: 'Libre Franklin', sans-serif;
$content_font: 'Libre Franklin', sans-serif;
$lighter: 100;
$normal: 400;
$semibold: 600;

$gl_gap_padding: 15px;
$gl_gap: 8px;
$button_offset: 2 * $gl_gap;
$light_black: #333333;
$black: #000000;
$grey: #BDBDBD;
$dark_grey: #828282;
$light_grey: #F2F2F2;
$regularFontWeight: 300;
$blue: #0047FF;
$blue-hover: #4594FF;
$yellow: #FFC000;
$blueHighlight: rgba(0, 144, 226, 0.05);
$blue2: #0047FF;
// responsive wrapper
$gl-wrapper-md: 458px;
$gl-wrapper-xs: 270px;
$gl-wrapper-padding: 15px;

// popup inner wrapper
$gl-popup-inner-md: 428px;
$gl-popup-inner-xs: 320px;
$gl-popup-padding: 15px;
$gl-tab-md-width: 142px;
$gl-tab-xs-width: 96px;
