@import '~theme/modules/variables';
@import '~theme/mixins';
@import 'base';

.billing {
  font-weight: 300;
  text-align: left;
  font-family: $fontBaseV2;
  display: flex;
  padding-top: 40px;

  .billing_tab_disabled {
    color: black;
  }

  .billing_block {
    display: flex;
    flex-direction: column;
    margin-right: 88px;
  }

  .splitBox {
    display: flex;
  }
  .title {
    margin-bottom: 57px;
    font-size: 21px;
    font-weight: 600;
    color: black;
  }
  .invoiceHistory {
    .title {
      flex: 0;
      min-height: auto;
    }
  }
  .billingInfo {
    margin: 0;
    width: 427px;
  }
  .contactInfoBox {
    width: 427px;
  }

  .cardInfoWrapper {
    border-right: none;
    margin-bottom: 68px;
    display: flex;
    padding-right: 0;

    @include breakpoint-sm {
      padding-bottom: 30px;
      padding-right: 0;
      border-right: none;
      width: 100%;

      :global(.form-group) {
        width: 100%;
      }
    }
  }

  .history {
    height: 350px;
    width: 450px;
    padding-left: 0;
    padding-top: 0;
    @include nice-scrollbar;
  }

  @include breakpoint-sm {
    padding-top: 24px;
    flex-direction: column;

    .billing_block {
      margin-right: 0;
    }

    .invoiceHistory {
      order: 3;
      width: 100%;
      min-height: 300px;
      padding-top: 59px;
    }
    .billingInfo {
      margin: 0;
      width: 100%;
    }
    .paymentMethod {
      width: 100%;
    }
    .title {
      margin-bottom: 24px;
    }
    .paymentBox {
      margin-right: 0;
    }
    .splitBox {
      flex-direction: column;
      width: 100%;
    }
    .contactInfoBox {
      width: auto;
    }
    .cardInfoWrapper {
      margin-bottom: 54px;
      padding-bottom: 0;
    }
    .history {
      height: auto;
      padding: 0;
      padding-top: 0;
      width: 100%;
      @include inheritHeight;
    }
  }
}
