@import '~theme/mixins';
@import '~theme/modules/variables';

.wrapMessage {
  :global(.notification-message) {
    word-break: break-word;
  }
}

.error {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  opacity: 0.95;
}

.avatar_video {
  position: relative;
  display: flex;
  flex-grow: 1;
  height: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;

    .avatar_video_preview {
      border-left: none;
    }
  }
}

.loading {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: progress;
  background: rgba(255, 255, 255, 0.3);
}

.avatar_video_options {
  display: flex;
  flex-direction: column;
  width: 470px;
  padding: 24px;
}

.avatar_video_options_title {
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  color: black;
  margin: 0;
  margin-bottom: 18px;
}

.avatar_video_options_content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.avatar_video_options_generator {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.avatar_video_option_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }
}

.avatar_video_option_item_title {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
  width: 140px;
}

.avatar_video_option_item_input {
  display: flex;
  align-items: center;
  height: 40px;
  width: 274px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    flex-grow: 1;
    margin-right: 20px;
    text-transform: capitalize;

    &.placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.avatar_video_generate_btn {
  margin-top: 18px;
  height: 64px;
  font-size: 18px;
}

.avatar_video_preview {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
  border-left: 1px solid #fff;
}

.avatar_video_preview_title {
  font-weight: 600;
  font-size: 21px;
  line-height: 24px;
  color: black;
  margin: 0;
  margin-bottom: 18px;
}

.avatar_video_preview_content {
  background: #ffffff;
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .avatar_video_option_item_input {
    width: 100%;
    cursor: pointer;
  }
}

.avatar_video_preview_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 333px;
  width: 100%;
  background: radial-gradient(
    57.1% 57.1% at 50% 49.83%,
    rgba(255, 255, 255, 0) 71.02%,
    #ffffff 85.92%
  );
  padding: 15px;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.isVoicePicker {
  .dropdown_popup {
    padding: 24px;

    .dropdown_popup_search {
      width: 100%;
    }

    .dropdown_popup_content {
      padding-right: 0;
    }
  }
  .avatar_video_options {
    width: 100%;
  }
  .avatar_video_options_title, .dropdown_popup .dropdown_popup_title span {
    font-size: 17px;
  }

  .avatar_video_option_item {
    flex-direction: column;

    align-items: start;
  }

  .avatar_video_options_generator {
    gap: 24px;
  }

  .avatar_video_option_item_title {
    font-weight: 600;
  }

  .avatar_video_options_content {
    gap: 24px;
  }
}

.dropdown_popup {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  padding-bottom: 24px;

  .dropdown_popup_title {
    display: flex;
    align-items: center;
    padding: 24px 24px 18px;
    cursor: pointer;

    span {
      font-weight: 600;
      font-size: 21px;
      line-height: 24px;
      color: black;
    }
  }

  .dropdown_popup_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    padding-right: 11px;

    &.center {
      justify-content: center;
    }

    &:hover {
      overflow-y: scroll;
      padding-right: 0;
    }
  }

  .dropdown_popup_search {
    position: sticky;
    top: 0;
    z-index: 1;
    padding-bottom: 2px;

    .dropdown_popup_search_input {
      width: 500px;
    }

    :global(.input_search-icon) {
      left: 10px !important;
      display: flex;
    }

    input {
      height: 48px !important;
    }
  }

  .dropdown_popup_list {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    max-width: 500px;
    width: 100%;
  }

  .dropdown_popup_item {
    background: white;
    display: flex;
    padding: 14px;
    color: black;
    cursor: pointer;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: inherit;
      flex-grow: 1;
    }

    .preview {
      flex-grow: 0;
      margin-right: 16px;
      display: none;
    }

    svg {
      display: none;
    }

    &:hover,
    &.playing {
      color: var(--site-theme-color);

      .preview {
        display: block;
      }
    }

    &.active {
      color: var(--site-theme-color);
      order: -1;
      svg {
        display: block;
      }
    }
  }

  .dropdown_popup_list_avatar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 28px 18px;
    max-width: 591px;
    width: 100%;
  }

  .dropdown_popup_item_avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &.active,
    &:hover {
      .dropdown_popup_item_avatar_content {
        background: rgba(var(--site-theme-color-rgb), 0.05);
      }
      .dropdown_popup_item_avatar_name {
        color: var(--site-theme-color);
      }
    }
  }

  .dropdown_popup_item_avatar_content {
    width: 185px;
    height: 185px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .dropdown_popup_item_avatar_name {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    margin-top: 6px;
  }
}

.checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  margin: 25px 0 0;

  input[type='checkbox'] {
    margin: 0;
    margin-right: 10px;
    position: relative;
    top: 1px;
  }

  &.disabled {
    pointer-events: none;
  }
}

.avatar_video_or {
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.4);
  margin: 10px 0;
  text-align: center;
}

.avatar_video_customize_btn {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000;
  cursor: pointer;
  text-align: center;

  b {
    font-weight: 600;
  }

  &:hover {
    color: var(--site-theme-color);
  }
}

.step__close_btn {
  position: absolute;
  top: 0;
  right: -10px;
  transform: translateX(24px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: #000;
  z-index: 2;

  &:hover {
    background: var(--site-theme-color);
  }

  &.hide {
    display: none;
  }
}
