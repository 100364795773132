@import '../../variables';

.features {
  display: flex;
  flex-direction: column;
  height: 100%;

  .features__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;

    .features__content_inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: $defaultWidthContent;
    }
  }

  .features__list {
    display: flex;
    flex-direction: column;

    .features__sub_list {
      display: flex;
      flex-direction: column;

      .features__list_item {
        padding-left: 48px;
      }

      .features__sub_list {
        .features__list_item {
          padding-left: 80px;
        }
      }
    }

    .features__list_group {
      display: flex;
      flex-direction: column;
    }

    .features__list_item {
      display: flex;
      align-items: center;
      column-gap: 16px;
      flex-shrink: 0;
      height: $defaultHeightBtn;
      padding: 0 16px;
      background: white;
      overflow: hidden;

      &:hover {
        .features__list_item_setAsDefault {
          visibility: visible;
        }
      }

      .features__list_item_label {
        @include defaultFont;
        margin: 0;
        white-space: nowrap;

        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }

      svg {
        flex-shrink: 0;
      }

      .features__list_item_default {
        @include defaultFont;
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.4);
        margin-left: 16px;
      }

      .features__list_item_setAsDefault {
        @include defaultFont;
        font-weight: 600;
        white-space: nowrap;
        color: black;
        margin: 0;
        margin-left: 16px;
        visibility: hidden;
      }

      .features__list_item_checkbox {
        margin-left: auto;
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .features {
    padding-left: 24px;
    padding-right: 13px;

    .features__content {
      padding-bottom: 24px;
    }

    .features__list {
      .features__list_item {
        padding: 0 12px;

        .features__list_item_label {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
