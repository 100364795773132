.learn_more_btn {
  background: rgba(0, 0, 0, 0.05) !important;
  border: none !important;
  font-weight: 400;
}

.add_btn {
  span {
    text-transform: capitalize;
  }
}
