@import '~theme/modules/variables';
@import '~theme/mixins';

@mixin defaultIcon {
  width: 9px;
  height: 9px;
  position: absolute;
  bottom: 5px;
  background: #ffffff;

  @include breakpoint-sm {
    width: 6px;
    height: 6px;
  }
}

@mixin clickAbleIcon {
  width: 18px;
  height: 18px;
  position: absolute;
  bottom: 3px;
  border: 5px solid currentColor;

  @include breakpoint-sm {
    width: 12px;
    height: 12px;
    border-width: 3px;
  }
}

@mixin after {
  &:after {
    content: '';
    left: 100%;
    transform: translateX(9px);
  }
}

@mixin before {
  &:before {
    content: '';
    right: 100%;
    transform: translateX(-9px);
  }
}

.teamAndDepartmentsSection {
  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 138px 64px 83px;

    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 22px;
    }

    .desc {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      width: 100%;
      max-width: 618px;
      margin: 0;
    }
  }

  .contentWrapper {
    height: 457px;
    background-image: url('~assets/img/redesign2021/homepage/bg_affect_section.png');
    background-repeat: no-repeat;
    background-size: 1150px 348px;
    background-position: bottom center;
    background-color: #2891be;
    position: relative;
    margin-bottom: 115px;

    &:after {
      content: '';
      position: absolute;
      bottom: -115px;
      left: 0;
      width: 100%;
      height: 115px;
      background-image: url('~assets/img/redesign2021/global/gradient_blue_small.png');
      background-repeat: no-repeat;
      background-position: top center;
    }

    .content {
      position: relative;
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 0 64px;

      .useCaseLink,
      .useCaseItem {
        position: absolute;
        font-weight: 600;
        font-size: 15px;
        line-height: 136%;
        color: #ffffff;

        &[data-type='accounting'] {
          top: 59px;
          left: 143px;
          @include after;
        }
        &[data-type='education'] {
          top: 119px;
          left: 92px;
          @include after;
        }
        &[data-type='rd'] {
          top: 177px;
          left: 147px;
          @include after;
        }
        &[data-type='quality'] {
          top: 244px;
          left: 200px;
          @include after;
        }
        &[data-type='administrative'] {
          top: 290px;
          left: 52px;
          @include after;
        }
        &[data-type='finance'] {
          top: 355px;
          left: 182px;
          @include after;
        }
        &[data-type='product_management'] {
          top: 409px;
          left: 203px;
          @include after;
        }
        &[data-type='sales_enablement'] {
          top: 89px;
          left: 265px;
          @include after;
        }
        &[data-type='learning_and_development'] {
          top: 155px;
          left: 334px;
          @include after;
        }
        &[data-type='production'] {
          top: 173px;
          right: 659px;
          @include before;
        }
        &[data-type='it'] {
          top: 127px;
          right: 598px;
          @include before;
        }
        &[data-type='distribution'] {
          top: 66px;
          right: 439px;
          @include before;
        }
        &[data-type='marketing'] {
          top: 129px;
          right: 409px;
          @include before;
        }
        &[data-type='purchasing'] {
          top: 188px;
          right: 340px;
          @include before;
        }
        &[data-type='customer_training'] {
          top: 202px;
          right: 140px;
          @include before;
        }
        &[data-type='legal'] {
          top: 254px;
          right: 163px;
          @include before;
        }
        &[data-type='operations'] {
          top: 313px;
          right: 44px;
          @include before;
        }
        &[data-type='hr'] {
          top: 377px;
          right: 200px;
          @include before;
        }
      }

      .useCaseItem {
        &::before,
        &::after {
          @include defaultIcon;
        }
      }

      .useCaseLink {
        text-decoration: underline;
        &:hover {
          color: black;
        }

        &::before,
        &::after {
          @include clickAbleIcon;
        }
      }
    }
  }
}

@include breakpoint-sm {
  .teamAndDepartmentsSection {
    .titleWrapper {
      max-width: 100%;
      padding: 79px 24px 37px;

      .title {
        font-size: 21px;
        margin-bottom: 18px;
      }

      .desc {
        font-size: 13px;
        max-width: 100%;
      }
    }

    .contentWrapper {
      height: 649px;
      background-image: url('~assets/img/redesign2021/homepage/mobile/bg_affect_section.png');
      background-position: center center;
      background-size: cover;
      margin-bottom: 100px;

      &:after {
        background-image: url('~assets/img/redesign2021/global/mobile/gradient_blue_small.png');
        background-size: cover;
      }

      .content {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 24px;

        .useCaseLink,
        .useCaseItem {
          font-size: 13px;

          &[data-type='accounting'] {
            top: 140px;
            left: 65px;
          }
          &[data-type='education'] {
            top: 195px;
            left: 39px;
          }
          &[data-type='rd'] {
            top: 346px;
            left: 26px;
          }
          &[data-type='quality'] {
            top: 438px;
            left: 24px;
          }
          &[data-type='administrative'] {
            top: 521px;
            left: 21px;
          }
          &[data-type='finance'] {
            top: 270px;
            left: 26px;
          }
          &[data-type='product_management'] {
            top: 583px;
            left: 29px;
            max-width: 83px;
          }
          &[data-type='sales_enablement'] {
            top: 88px;
            left: 58px;
          }
          &[data-type='learning_and_development'] {
            top: 36px;
            left: 82px;
          }
          &[data-type='production'] {
            top: 69px;
            right: 27px;
          }
          &[data-type='it'] {
            top: 133px;
            right: 21px;
          }
          &[data-type='distribution'] {
            top: 570px;
            right: 105px;
          }
          &[data-type='marketing'] {
            top: 361px;
            right: 20px;
          }
          &[data-type='purchasing'] {
            top: 418px;
            right: 30px;
          }
          &[data-type='customer_training'] {
            top: 523px;
            right: 30px;
          }
          &[data-type='legal'] {
            top: 290px;
            right: 23px;
          }
          &[data-type='operations'] {
            top: 469px;
            right: 40px;
          }
          &[data-type='hr'] {
            top: 214px;
            right: 26px;
          }
        }
      }
    }
  }
}
