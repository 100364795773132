@import '~theme/form';
@import '~theme/modules/variables';
@import '~theme/mixins';


.container {
  display: flex;
  flex-direction: column;
  font-family: 'Libre Franklin';

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  label {
    font-weight: 400;
    font-size: 15px;
    margin: 0;
    display: flex;
    align-items: center;
    line-height: 16px;

    input[type="checkbox"] {
      margin: 0;
      margin-right: 10px;
    }
  }
}

.content {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  padding: 12px 0;
  background-color: #FFF;

  &.sticky {
    position: sticky;
    top: 120px;

    @include breakpoint-sm {
      top: 80px;
      gap: 24px;
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
  min-width: 320px;
  flex-grow: 1;

  h5 {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
  }
}

.header.sticky {
  position: sticky;
  top: 150px;
  background-color: #FFF;

  @include breakpoint-sm {
    top: 165px;
  }
}

h5.sticky {
  top: 180px;
}

.list {
  width: 100%;
}

.item {
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba($color: #000000, $alpha: 0.1);
  border-top-color: transparent;
  margin-top: -1px;
  color: rgba($color: #000000, $alpha: 0.4);

  &:first-child {
    border-top-color: rgba($color: #000000, $alpha: 0.1);
  }

  &:hover {
    border-color: rgba($color: #000000, $alpha: 0.25);
    border-top-color: rgba($color: #000000, $alpha: 0.25);
    color: #000000;
  }

  .black {
    color: #000;
  }

  button {
    background-color: transparent;
    border: 0 none;
    padding: 0;
    display: flex;
    align-items: center;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}

.name {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 16px;
  color: rgba($color: #000000, $alpha: 0.4);
}

.btnGroup {
  display: flex;
  flex-direction: column;
}

.btnUploadCsv {
  color: #000000;
  font-size: 15px;
  align-items: center;
  display: flex;
  padding: 16px;

  input {
    display: none;
  }

  label {
    cursor: pointer;
    font-weight: 600;
    line-height: 24px;
  }

  &:hover {
    color: var(--site-theme-color);
  }
}

.btnSendInvite {
  width: 216px;
  height: 48px;
  border-radius: 0;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.emailDomainForm {
  display: flex;
  flex-direction: row;
  position: relative;

  input {
    margin: 0;
    padding: 16px;
    padding-right: 90px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    border: 0;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    flex-grow: 1;

    &:focus, &:hover {
      border-bottom-color: #000;
    }
  }

  button {
    border: 0;
    padding: 0;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;

    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
    }

    &:hover {
      color: var(--site-theme-color)
    }
  }
}
