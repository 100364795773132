.link-underline {
  text-decoration: underline;
  color: #000;
  font-size: 15px;
  position: relative;
  transition: color 0.3s ease;

  &:hover {
    color: var(--site-theme-color);
    text-decoration: underline;
  }
}
