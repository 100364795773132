@import '~theme/mixins';
@import '~theme/modules/variables';

$disabledOpacity: 0.4;

.audioEditor {
  &.withFocusedStep :global(.popup-header-wrapper) {
    opacity: $disabledOpacity;
    pointer-events: none;
  }
}

.hide {
  display: none !important;
}

.checkbox_btn {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  padding-left: 25px;
  margin: 0;

  &.disabled {
    opacity: $disabledOpacity;
    pointer-events: none;
  }
  &.disabledButClickable {
    opacity: $disabledOpacity;
  }

  input[type='checkbox']:checked ~ span::after {
    content: '';
    background-color: transparent;
    background-image: url('../../assets/img/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    border: none;
  }

  input[type='checkbox'] {
    position: absolute;
    cursor: pointer;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    bottom: 3px;
    left: 0;
    margin: 0;
    opacity: 0;
    top: 2px;
    z-index: 1;
  }

  span:after {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 2px;
    width: 16px;
    height: 16px;
    content: '';
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.step {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f4f4f4;
  color: black;
  position: relative;
  z-index: 1;
  height: 171px;
  transition: height 0.3s ease;

  &.opaque {
    opacity: $disabledOpacity;
    pointer-events: none;
    z-index: 0;
  }

  &.active {
    height: 198px;
    z-index: 1;
  }

  &.hasScreenshot {
    height: auto;
    flex-direction: column;
    position: relative;

    .step_screenshot {
      display: block;
    }

    .step_content {
      min-height: 198px;
    }

    &.active {
      height: auto;
    }
  }

  .step_screenshot {
    width: 100%;
    height: 300px;
    display: none;
  }
}

.step_content {
  display: flex;
  width: 100%;
  position: relative;
}

.step_block__left {
  display: flex;
  flex-grow: 1;
}

.step_block__right {
  display: flex;
  flex-shrink: 0;
  width: 420px;
}

.step__empty_content {
  display: flex;
  flex-grow: 1;
  color: black;

  .iconGroup {
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover {
      .add_icon_overlay {
        display: none;
      }
    }
  }

  .add_icon, .add_icon_overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 173px;
    flex-direction: row;
    gap: 10px;

    &:hover {
      background: white;
      color: var(--site-theme-color);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(var(--site-theme-color-rgb), 0.05);
      }
    }

    svg path {
      stroke: currentColor;
    }
  }

  .add_icon_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f4f4f4;
  }
}

.step_info_wrapper {
  display: flex;
  flex-grow: 1;
  padding: 24px;

  .step_icon {
    margin-top: -10px;
  }

  .step_info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 16px;

    .step_preview_image {
      cursor: pointer;
      margin-left: 5px;
      svg {
        path[fill] {
          fill: #c2c3c2;
        }
        path[stroke] {
          stroke: #c2c3c2;
        }
      }
      &:hover,
      &.active {
        svg {
          path[fill] {
            fill: var(--site-theme-color);
          }
          path[stroke] {
            stroke: var(--site-theme-color);
          }
        }
      }
    }

    .step_title {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: inherit;
      margin: 0;
      margin-bottom: 10px;
    }

    .step_description {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: inherit;
      flex-grow: 1;
      word-break: break-word;
      max-width: 526px;
      height: 100%;
      overflow: hidden;
      padding-right: 11px;

      &:hover {
        overflow-y: scroll;
        padding-right: 0px;
      }

      b {
        font-weight: 400;
      }

      a {
        color: inherit;
        transition: none;
      }
    }
    .step_description_type {
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }
}

.step__select_tabs {
  display: flex;
  flex-grow: 1;
  column-gap: 1px;
  background: #fff;

  .step__select_tab_btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    flex-grow: 1;
    background: #f4f4f4;
    height: 100%;

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: black;
    }

    input[type='file'] {
      width: 0;
      height: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    &:hover {
      background: white;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(var(--site-theme-color-rgb), 0.05);
      }

      span {
        color: var(--site-theme-color);
      }

      svg {
        path,
        circle {
          &[stroke] {
            stroke: var(--site-theme-color);
          }
          &[fill] {
            fill: var(--site-theme-color);
          }
        }
      }
    }
  }
}

.processing_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  @keyframes dots {
    to {
      width: 1em;
    }
  }

  span {
    color: black;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;

    &.loading_dot {
      position: relative;

      &:after {
        position: absolute;
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        content: '\2026';
        width: 0px;
        animation: dots 1s steps(4, end) infinite;
      }
    }
  }
}

.step__retrying {
  .step__retrying_message {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000;
    }
  }
  .step__retrying_controls {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }

  .step__retrying_control_btn {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: black;
    cursor: pointer;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}

.step__recorder {
  .step__recorder_recording {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-left: 1px solid #fff;

    .visualizer {
      width: 100%;
      height: calc(100% - 64px);
    }

    .step__recorder_saving {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    canvas {
      width: 100%;
      height: 100%;
    }

    .step__recorder_btn {
      width: 100%;
      height: 64px;
      font-size: 18px;
    }
  }
}

.step__audio_editor {
  .step__audio_editor_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    border-left: 1px solid #fff;
  }
  .step__audio_editor_layer {
    width: 100%;
    height: calc(100% - 64px);
  }
  .step__audio_editor_saving {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .step__audio_editor_canvas {
    width: 100%;
    height: 100%;
  }
  .step__audio_editor_controls {
    display: flex;
    flex-shrink: 0;
    height: 64px;
    border-top: 1px solid #fff;
  }
  .step__audio_editor_control_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-right: 1px solid #fff;

    &:last-child {
      border-right: none;
    }

    button {
      min-width: 0;
      height: 100%;
      border: none;

      &.transcript_btn {
        background: #f4f4f4;
      }

      &:disabled {
        opacity: $disabledOpacity;
      }

      &:hover {
        svg path {
          &[stroke='black'] {
            stroke: var(--site-theme-color);
          }
          &[fill='black'] {
            fill: var(--site-theme-color);
          }
        }
      }
    }
  }
  .step__audio_editor_expand_btn {
    width: 173px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-left: 1px solid #fff;
    cursor: pointer;

    &:hover {
      svg path {
        &[stroke='black'] {
          stroke: var(--site-theme-color);
        }
        &[fill='black'] {
          fill: var(--site-theme-color);
        }
      }
    }
  }
}

.step__generator {
  display: flex;
  flex-direction: column;
  position: relative;

  .step__generator_content {
    display: flex;
    align-items: center;
    column-gap: 40px;
    flex-grow: 1;
    padding: 0 40px;
  }
  .step__generator_dropdown {
    display: flex;
    flex-direction: column;
    flex: 1;

    .step__generator_dropdown_title {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: 25px;
    }
  }
  .step__generator_btn {
    flex: 1;
    font-size: 18px;
    height: 56px;
    margin-top: auto;
    margin-bottom: 28px;
  }
  .step__generator_footer {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    column-gap: 1px;
    height: 64px;
    border-top: 1px solid #fff;
    background: white;
  }
  .step__generator_footer_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000;
    height: 100%;
    background: #f4f4f4;

    &:hover {
      color: var(--site-theme-color);
      svg {
        path {
          &[stroke] {
            stroke: var(--site-theme-color);
          }
          &[fill] {
            fill: var(--site-theme-color);
          }
        }
      }
    }
  }

  :global(.react-selectize.root-node.default) {
    :global(.react-selectize-control) {
      :global(.react-selectize-search-field-and-selected-values) {
        flex-wrap: nowrap;
        width: 100%;
        overflow: hidden;
        padding-right: 7px;

        :global(.value-wrapper) {
          width: 100%;
          position: relative;
          padding-left: 2px;
          max-width: 235px;

          &:after {
            content: '';
            height: 100%;
            width: 33px;
            position: absolute;
            right: 0;
            top: 0;
            background: linear-gradient(
              to left,
              #f4f4f4 13.81%,
              rgba(255, 255, 255, 0) 99.61%
            );
          }
        }

        :global(.resizable-input) {
          position: absolute;
          left: 0;
        }

        :global(.simple-value) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }
  }

  &.loading {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 1;
    }
  }
}

.step__empty_audio {
  .step_no_audio {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 100%;
    width: 173px;
    border-left: 1px solid #fff;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #000;
    }
  }
}

.step__close_btn {
  position: absolute;
  top: 0;
  right: -10px;
  transform: translateX(24px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: #000;

  &:hover {
    background: var(--site-theme-color);
  }
}


:global(.audio-editor-tooltip) {
  :global(.tooltip-arrow) {
    display: none;
  }

  :global(.tooltip-inner) {
    border-radius: 0;
    padding: 5px 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: black;
    background: white;
    text-align: left;
  }

  &:global(#tooltip-audio-editor-0) { // set background of 1st step to grey
    :global(.tooltip-inner) {
      background: #f4f4f4;
    }
  }
}