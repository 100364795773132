@import "~theme/guidelines_variables";

.Button {
  width: 100%;
  min-width: 130px;
  height: 48px;
  line-height: 100%;
  border: none;
  border-radius: 0;
  padding: 0;
  background-color: #000000;
  color: #fff;
  transition: background-color .3s ease;
  font-weight: 600;
  font-size: 15px;
  font-family: 'Libre Franklin', sans-serif;
  text-align: center;
  cursor: pointer;
  outline: 0;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: inherit;
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &[disabled], &:disabled, &.Button_loading {
    opacity: 1;
    pointer-events: none;
    user-select: none;

    &:not(.Button_loading):not(.Button_alternate) {
      background: #ccc !important;
    }
  }
  &:active, &:focus, &:focus:active {
    outline: none;
    color: #ffffff;
  }
  @media (hover: hover) {
    &:hover {
      color: #ffffff;
      background-color: var(--site-theme-color);
      text-decoration: none;
    }
  }
  a & { // when inside an anchor
    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &.Button_loading {
    @keyframes generating {
      from {
        background-position-x: 0;
      }
      to {
        background-position-x: 45px;
      }
    }

    background: url("~assets/img/tutorial/loading.svg") repeat center;
    background-size: 45px;
    animation: generating .5s linear infinite;
    border: 1px solid #f2f2f2;
    color: #000;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }

  &.Button_alternate {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: transparent;
    color: #000;

    &:hover {
      color: var(--site-theme-color);
    }

    &[disabled], &:disabled {
      color: #d2d2d2;
    }
  }

  &.variant_grey {
    background-color: #F4F4F4;
    color: #000;
    font-weight: normal;

    &:active, &:focus, &:focus:active, &:hover {
      background-color: #000000;
      color: #fff;
    }

    &.Button_alternate {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.4);
      border: none;

      &:active, &:focus, &:focus:active, &:hover {
        color: black;
      }
    }
  }
}
