@import '../../variables';

.buttons {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  .buttons__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 32px 0;

    svg {
      color: inherit;

      [fill] {
        fill: currentColor;
        color: inherit;
      }

      [stroke] {
        stroke: currentColor;
        color: inherit;
      }
    }

    h4 {
      font-size: 21px;
      line-height: 24px;
      margin: 0;
      margin-bottom: 24px;
    }

    p {
      @include defaultFont;
      margin: 0;
    }

    .buttons__header_close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: $defaultHeightBtn;
      width: $defaultHeightBtn;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .buttons__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 192px 80px $defaultHeightBtn;
    position: relative;
    overflow: hidden;
  }

  .buttons__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 80px;
    background-color: white;
    padding: 0 40px;

    label {
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 24px;

      span {
        @include defaultFont;
        color: black;

        b {
          font-weight: 600;
        }
      }

      &.back {
        span {
          font-weight: 600;
        }
        svg {
          transform: rotate(90deg);
        }
      }

      &.next {
        svg {
          transform: rotate(270deg);
        }
      }
    }
  }
}

.hc__logo {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  img {
    height: 32px;
    width: auto;
  }

  &.invisible {
    visibility: hidden;
  }
}

.hc__title {
  font-size: 35px;
  line-height: 40px;
  margin: 0;
  text-align: center;
  padding: 16px 0;

  &.invisible {
    visibility: hidden;
  }
}

.hc__intro {
  @include defaultFont;
  margin: 0;
  text-align: center;
  padding: 16px 0;

  &.invisible {
    visibility: hidden;
  }
}

.hc_buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  max-width: 940px;
  padding: 48px 0;

  .hc_buttons__button {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    padding: 10px 16px;
    width: auto;

    .hc_buttons__button_text {
      @include defaultFont;
    }

    .hc_buttons__button_label {
      @include defaultFont;
      font-weight: 600;
      margin: 0;
      cursor: pointer;
    }

    svg {
      [fill] {
        fill: currentColor;
      }

      [stroke] {
        stroke: currentColor;
      }
    }
  }
}

.hc_button_modal {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: white;
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  flex-direction: column;

  .hc_button_modal__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    height: $defaultHeightHeader;
    background: #f4f4f4;
    cursor: pointer;

    .hc_button_modal__header_text {
      @include defaultFont;
    }

    .hc_button_modal__header_close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $defaultHeightHeader;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .hc_button_modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 56px;
    overflow-y: scroll;
  }

  .hc_button_modal__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    padding: 24px 0;

    button {
      max-width: 320px;
    }
  }
}

.customize_buttons {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
  max-width: $defaultWidthContent;
  min-height: 300px;

  .customize_buttons__group {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .customize_buttons__group_label {
      @include defaultFont;
      font-weight: 600;
    }

    .customize_buttons__group_list {
      display: flex;
      flex-direction: column;

      &.dragging {
        .customize_buttons__dropzone.dragging:not(.after),
        .customize_buttons__item.dragging {
          display: none;
        }
      }
    }

    .customize_buttons__item {
      display: flex;
      align-items: center;
      column-gap: 16px;
      height: $defaultHeightBtn;
      padding-left: 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);

      &.hidden {
        visibility: hidden;
      }

      &.dragging {
        background: var(--background-color);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }

      &.dropped {
        animation: blink 0.3s linear infinite;
      }

      &.invisible {
        > * {
          opacity: 0.4;
        }
      }

      &.disabled {
        pointer-events: none;
        > * {
          opacity: 0.4;
        }
      }

      .customize_buttons__item_checkbox {
      }

      .customize_buttons__item_label {
        @include defaultFont;
        flex-grow: 1;
      }

      .customize_buttons__item_edit {
        @include defaultFont;
        font-weight: 600;
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }

      .customize_buttons__item_sort {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $defaultHeightBtn;
        height: 100%;
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .customize_buttons__dropzone {
    $marginSize: calc($defaultHeightBtn / 2);

    z-index: 3;
    position: relative;
    width: 100%;
    margin-top: -$marginSize;
    margin-bottom: -$marginSize;

    &.active {
      padding-top: $marginSize;
      padding-bottom: $marginSize;
      .customize_buttons__dropzone_inner {
        height: 100%;
        visibility: visible;
      }
    }

    .customize_buttons__dropzone_inner {
      visibility: hidden;
      height: $defaultHeightBtn;
      border: 1px solid var(--site-theme-color);
      pointer-events: none;
    }
  }
}

.create_edit_form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  max-width: $defaultWidthContent;

  .create_edit_form__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 24px;
    height: $defaultHeightBtn;
    margin: 0;
    font-weight: normal;

    span {
      @include defaultFont;
      color: rgba(0, 0, 0, 0.4);
    }

    input {
      max-width: 400px;
    }
  }

  .create_edit_form__checkbox {
    height: $defaultHeightBtn;
  }
}

@media (max-width: $mobileBreakpoint) {
  .buttons {
    .buttons__inner {
      padding: 24px 13px 24px 24px;
      overflow-y: scroll;
      position: unset;
    }
  }

  .hc_title {
    input {
      font-size: 21px;
    }
  }
}
