.searchAndSort {
  display: flex;
  flex: 1;
  height: 34px;
  min-height: 34px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  padding-right: 12px;
  align-items: center;
  flex-shrink: 0;

  .sortFilters {
    display: none;
  }

  &:focus-within {
    border-color: black;
  }

  &.defaultMode {
    .searchContainer {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    .searchInputWrapper {
      width: 100%;
    }

    .searchIcon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .searchInput {
      background: transparent;
      border: 0;
      padding-left: 36px;
      padding-right: 0;
    }

    .sortOptionActived {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-transform: capitalize;
    }

    .sortIconContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40px;
      cursor: pointer;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }

  &.sortModeActive {
    justify-content: flex-start;
    user-select: none;

    .searchInputWrapper {
      display: none;
    }

    .sortActive {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .sortOptionActived {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: capitalize;
      }

      .sortIconContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 40px;
        cursor: pointer;
        margin-right: 9px;
      }
    }

    .sortFilters {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 34px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-top: none;
      padding: 4px 12px;
      z-index: 2;

      .sortOptionItem {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 32px;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.searchFocused {
    .sortIconContainer,
    .sortOptionActived,
    .sortFilters {
      display: none;
    }

    .searchInput {
      padding-right: 10px;
    }
  }
}
