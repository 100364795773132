@import '~theme/guidelines_variables';
@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';

$breakpoint: 768px;
$navbarHeightMobile: 200px;
$fontBaseFAQ: 'Libre Franklin';

.masthead {
  display: flex;
  flex-direction: column;

  .headerWrapper {
    background: #2891be;
    margin-top: 128px;

    &.isLogged {
      margin-top: 172px;
    }

    @include breakpoint-sm {
      margin-top: 36px;

      &.isLogged {
        margin-top: 46px;
      }

      img {
        padding: 52px 0 59px 13px;
      }
    }
  }

  .titleContent {
    padding: 52px 64px;
    @include ioradFont;
    max-width: $maxWidthContent;
    margin: 0 auto;

    @media screen and (max-width: 1010px) {
      font-size: 19vw;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    background-image: url('~assets/img/redesign2021/global/gradient_blue.png');
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 90px;
    margin-top: -2px;

    @include breakpoint-sm {
      background-image: url('~assets/img/redesign2021/global/mobile/gradient_blue.png');
      background-size: 100% 331px;
      padding-top: 57px;
    }
  }

  .content {
    width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;

    @media screen and (max-width: 1382px) {
      width: 100%;
    }

    @include breakpoint-sm {
      padding: 0 24px;
      width: 100%;
    }
  }

  .categories {
    width: 804px;
    margin-bottom: 51px;
    margin-top: 193px;

    @media screen and (max-width: 1010px) {
      width: 100%;
    }

    @include breakpoint-sm {
      width: 100%;
      margin-bottom: 38px;
      margin-top: 121px;
    }
  }

  .navbarContainer {
    width: 430px;
    margin: 0 auto;

    @include breakpoint-sm {
      width: 100%;
    }
  }

  .noResult {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    color: #000000;
    text-align: center;
    padding: 233px 0 71px;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    @include breakpoint-sm {
      font-size: 13px;
      line-height: 136%;
      text-align: left;
      padding-top: 165px;
    }
  }
}

.category {
  text-align: left;
  color: #000;

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    margin-top: 75px;
    margin-bottom: 25px;

    @include breakpoint-sm {
      font-size: 21px;
      margin-top: 64px;
      margin-bottom: 22px;
    }
  }

  .qa {
    &:not(:last-child) {
      margin-bottom: 24px;

      @include breakpoint-sm {
        margin-bottom: 21px;
      }
    }

    &:last-child {
      &::after {
        content: '';
        left: 0;
        margin-top: 31px;
        position: absolute;
        width: 100%;

        @media (min-width: $breakpoint) {
          width: calc(50% + 336px);
        }
      }
    }

    .question_line {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000;
      margin-bottom: 5px;

      @include breakpoint-sm {
        font-size: 13px;
        margin-bottom: 10px;
      }
    }

    .answer_line {
      font-size: 15px;
      line-height: 136%;
      color: #000;

      @include breakpoint-sm {
        font-size: 13px;
      }

      a {
        @extend .link-underline;

        @include breakpoint-sm {
          font-size: 13px;
        }
      }
    }
  }
}

.navbar {
  display: flex;
  flex-direction: column;

  .search {
    z-index: 1;
    margin-top: 0;
    text-align: left;
    width: 100%;
    position: relative;

    .inputIcon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;

      &:hover {
        .closeIcon path {
          stroke: var(--site-theme-color);
        }
      }
    }

    .closeIcon {
      cursor: pointer;
    }

    .search_field {
      width: 100%;
      height: 32px;
      padding-left: 45px;
      padding-right: 15px;
      font-size: 15px;
      color: #000;
      border: none;
      outline: none;
      caret-color: var(--site-theme-color);

      &::placeholder {
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .title {
    color: #000;
    font-weight: 600;
    line-height: 96px;
    font-size: 24px;
    text-align: left;
  }

  .category {
    font-weight: 600;
    line-height: normal;
    font-size: 15px;
    color: #0047ff;
    border: none;
    text-align: left;
    padding: 0;
    margin-bottom: 45px;
    cursor: pointer;
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  @include breakpoint-sm {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;
  }

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 206px 158px 167px 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint-sm {
      padding: 164px 24px 237px;
      flex-direction: column;
    }
  }

  .titleFooter {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000;
    margin: 0;

    @include breakpoint-sm {
      font-size: 21px;
      margin-bottom: 70px;
    }
  }

  .contactBtn {
    width: 276px;
    height: 48px;
    background-color: #000000;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #ffffff;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }

    @include breakpoint-sm {
      width: 295px;
      font-size: 13px;
    }
  }
}

