@import '~theme/guidelines_variables';
@import '~theme/mixins';
@import '~theme/modules/variables';
@import '~theme/link_underline';

.transaction {
  position: relative;
  font-family: $fontBaseV2;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  align-items: center;
  &:first-child {
    margin-top: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint-sm {
    margin: 0;
    padding-right: 15px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.info {
  display: flex;
  align-items: center;
}

.refunded {
  text-decoration: line-through;
}

.refundedLabel {
  margin-left: 10px;
}

.date {
  color: #000;
  font-size: 13px;
  margin: 0;
  @include breakpoint-sm {
    font-size: 10px;
    margin-bottom: 8px;
  }
}

.links {
  align-self: flex-start;

  a {
    @extend .link-underline;
    color: #000000;
    font-size: 15px;
    display: inline-block;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include breakpoint-sm {
    align-self: flex-end;
    padding-bottom: 3px;
  }
}

.amount {
  font-size: 21px;
  color: #000000;
  font-weight: 600;
  display: none;
}
