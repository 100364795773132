@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/AccountV2/partials';

.automations {
  display: flex;
  padding-bottom: 40px;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  .contentWrapper {
    flex-grow: 1;
  }

  .content {
    position: relative;
    display: flex;
    width: 100%;
  }

  .loading {
    padding: 40px 0;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;

    .formHeader {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
      padding: 40px 0;
    }

    .formContent {
      display: flex;
      flex-direction: column;
      row-gap: 40px;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    .checkboxWrapper {
      display: flex;
      flex-direction: column;

      .checkbox_label {
        display: flex;
        align-items: center;
        column-gap: 20px;
        font-weight: 400;
        font-size: 15px;
        color: black;
        margin: 0;

        span {
          flex-grow: 1;
        }

        input[type='checkbox'] {
          margin: 0;
          position: relative;
          width: 16px;
          height: 16px;

          &::before,
          &::after {
            cursor: pointer;
            content: '';
            position: absolute;
          }

          &::before {
            border: 1px solid rgba($color: #000000, $alpha: 0.2);
            border-radius: 0;
            background-color: #fff;
            width: 100%;
            height: 100%;
          }

          &::after {
            height: 100%;
            width: 100%;
            background-image: url('~assets/img/icons/checkmark.svg');
            background-repeat: no-repeat;
            background-position: center;
            transition: background-size 0.1s;
            background-size: 0;
          }

          &:checked {
            &::after {
              background-size: 76%;
            }
          }
        }
      }

      .checkbox_help {
        width: 100%;
        padding: 10px;
        background: #f4f4f4;
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin-top: 24px;
      }
    }

    .formGroup {
      display: flex;
      column-gap: 64px;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &.header {
        label {
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
          margin: 0;
          flex-grow: 1;

          &:last-child {
            max-width: 256px;
          }
        }
      }

      .formLabel {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        label {
          display: flex;
          align-items: center;
          flex-grow: 1;
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: black;
          margin: 0;
        }

        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.4);
        }
      }

      :global(.react-selectize) {
        width: 100%;
        max-width: 256px;
        min-width: 150px;

        :global(.dropdown-menu) {
          max-width: calc(100% + 40px);
        }
      }

      :global(.react-selectize-search-field-and-selected-values) {
        min-height: 40px !important;
      }

      :global(.react-selectize-toggle-button-container) {
        width: 40px !important;
        height: 40px !important;
      }

      :global(.resizable-input) {
        width: 0 !important;
      }
    }
  }

  &.extensionEmbed {
    .content {
      width: 100%;
      max-width: none;
      padding: 16px;
      margin-top: 58px;

      .body {
        .formHeader {
          padding: 0;
          position: relative;

          .formGroup.header {
            display: none;
          }
        }

        .formContent {
          margin-top: 24px;

          .formGroup {
            display: flex;
            flex-direction: column;
            gap: 8px;
            column-gap: 0;

            .formLabel {
              label {
                font-weight: 600;
              }

              span {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint-sm {
  .automations:not(.extensionEmbed) {
    .loading {
      padding: 24px 0;
    }

    .body {
      .formHeader {
        row-gap: 24px;
        position: static;
        padding: 0;
      }
      .formContent {
        padding-top: 24px;
        row-gap: 24px;
      }
    }
  }
}
