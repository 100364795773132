@import '~theme/mixins';

.slidesSection {
  position: relative;
}

.slidesWrapper {
  width: 100%;
  box-sizing: content-box;
  overflow: hidden;
}

.slidesContent {
  display: flex;
  height: 70px;
  align-items: center;

  &.isShowContent,
  &:hover {
    .slides {
      animation-play-state: paused;
    }
  }
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

.slides {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 2008px;
  animation: animate 180s linear infinite;
  animation-delay: -180s; // NOTE: keep as separate property as CssMinimizerPlugin throws on production when it is in 'animation' shorthand
  will-change: transform;

  &:nth-child(2) {
    animation: animate2 180s linear infinite;
    animation-delay: -90s; // NOTE: keep as separate property as CssMinimizerPlugin throws on production when it is in 'animation' shorthand
  }
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &.isFadeOut {
    opacity: 0.4;
  }

  .logoWrapper {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.contentWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_for_slider.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 284px;
  padding-top: 30px;
  position: absolute;
  left: 0;
  top: 70px;
  z-index: 2;
  width: 100%;
  background-color: #fff;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #fff;
  }
}

.descWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 422px;
  margin: 0 auto;
}

.infoWrapper {
  display: flex;
  margin-bottom: 50px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: #000;
  margin-bottom: -3px;
}

.position {
  font-size: 15px;
  line-height: 100%;
  color: #000;
}

.desc {
  width: 100%;
  font-size: 15px;
  line-height: 136%;
  color: #000;
  margin: 0;
}

.avatar {
  margin-right: 32px;
  min-width: 42px;
  max-width: 42px;
  height: 42px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@include breakpoint-sm {
  .slidesContent {
    height: 40px;
  }

  .slides {
    min-width: 1179px;
  }

  .contentWrapper {
    top: 40px;
    padding-top: 20px;
    height: 250px;
  }

  .descWrapper {
    width: 100%;
    padding: 0 24px;
    margin-bottom: 0;
  }

  .infoWrapper {
    margin-bottom: 15px;
  }

  .name {
    font-size: 13px;
  }

  .position {
    font-size: 13px;
  }

  .desc {
    font-size: 13px;
  }

  .avatar {
    margin-right: 15px;
  }
}
