@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    background: #2891be;
    min-height: 371px;

    &.beta {
      background: #bed67d;
    }
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 56px 64px 30px;
    position: relative;
  }

  .titleContent {
    @include ioradFont;
    max-width: 1165px;
  }

  .titleBeta {
    position: absolute;
    right: 284px;
    bottom: 127px;
  }
}

.feature_container {
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 15px 64px 35px;

  .feature_content {
    max-width: 809px;
    width: 100%;

    h5 {
      font-weight: 600;
      font-size: 21px;
      line-height: 24px;
      color: black;
      margin-top: 70px;
      margin-bottom: 0;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin-top: 7px;
      margin-bottom: 0;

      a {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      b {
        font-weight: 600;
      }
    }

    iframe {
      width: 100%;
      height: 506px;
      margin-top: 30px;
      border: none;
    }
  }

  .navigations {
    display: flex;
    justify-content: space-between;
    margin-top: 27px;
    max-width: 809px;

    .navigation_btn {
      background: none;
      border: none;
      outline: none;
      padding: 0;
      display: flex;
      align-items: center;
      column-gap: 19px;

      &:hover {
        svg path {
          stroke: var(--site-theme-color);
        }

        span {
          color: var(--site-theme-color);
        }
      }

      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: black;
      }
    }
  }

  .more_features {
    margin-top: 65px;

    h4 {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: black;
      margin: 0;
      margin-bottom: 60px;
    }

    .more_features__list {
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
    }

    .more_features__item {
      display: flex;
      flex-direction: column;
      width: 294px;
      cursor: pointer;

      &:hover {
        .more_features__item_desc {
          color: var(--site-theme-color);
        }
      }
    }

    .more_features__item_name {
      height: 147px;
      width: 100%;
      padding: 12px;
      background: #f4f4f4;

      span {
        font-family: 'iorad';
        text-transform: uppercase;
        font-size: 48px;
        line-height: 32px;
        color: black;
        font-weight: 400;
      }
    }

    .more_features__item_desc {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
      padding: 15px 0;
      margin: 0;
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 170px 158px 140px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .contactBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .titleWrapper {
      height: auto;
      min-height: 261px;
    }

    .title {
      padding: 68px 24px 46px;

      img {
        width: 100%;
      }
    }

    .titleBeta {
      right: 24px;
      bottom: 96px;

      svg {
        width: 68px;
        height: 36px;
      }
    }
  }

  .feature_container {
    max-width: 100%;
    padding: 29px 24px 110px;

    .feature_content {
      max-width: 100%;

      h5 {
        margin-top: 64px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        margin-top: 21px;
      }

      iframe {
        height: 584px;
        margin-top: 21px;
      }
    }

    .navigations {
      margin-top: 20px;

      .navigation_btn span {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .more_features {
      margin-top: 64px;

      h4 {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 38px;
      }

      .more_features__list {
        flex-direction: column;
        gap: 21px;
      }

      .more_features__item {
        width: 100%;

        &:hover {
          .more_features__item_desc {
            color: var(--site-theme-color);
          }
        }
      }

      .more_features__item_name {
        width: 100%;
        padding: 12px 21px;
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .contactBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
