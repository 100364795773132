@import '~theme/mixins';
@import '~theme/modules/variables';

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .inner {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 24px;
    flex-direction: column;
    max-width: 900px;
    padding: 30px 0;
    justify-content: center;
    min-height: calc(100vh - 80px);
    font-size: 18px;
    font-weight: 400;
    line-height: 24.48px;
    align-items: center;

    button {
      font-size: 18px;
      font-weight: 400;
      line-height: 24.48px;
      max-width: 320px;

      &.noBorder {
        border: 0 none;
      }
    }

    p {
      margin-top: 16px;
      padding: 16px;
      background: #F4F4F4;
      text-align: left;
    }
  }
  
}