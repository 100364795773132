@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/guidelines.scss';
@import '~theme/modules/variables';
@import '~components/Integrations/general';
@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,300;1,200;1,300&display=swap');

$pageWidth: 1176px;
$secondCoumnWidth: 274px; // match with player sidebar

$defaultColor: #9d9d9d;
$activeColor: #000000;

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001; // needed to place above the google translate loading icon (see https://github.com/iorad/iorad/issues/12909#issuecomment-1416097142)

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  [role='button'] {
    cursor: pointer;
  }

  &.extensionEmbed {
    padding-top: 48px;
    background: #f4f4f4;
  }

  &.insideHelpCenter {
    .qa {
      .qa__container {
        margin-top: 48px;
      }
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.top_tab_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $fontBaseV2;
  padding-top: 0;
  background: white;
  @include inheritHeight;

  &[data-tab='about'],
  &[data-tab='quick-start'] {
    .top_tab_contents {
      overflow-y: scroll;

      :global(.tab-pane.active) {
        padding: 0;
        height: auto;
      }
    }
  }

  .top_tabsHeadSection {
    flex: 0;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    height: 48px;
    padding-left: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background: white;

    .logo_tab_headers {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .close_menu_button {
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .left_tab_headers {
      display: flex;
      margin-right: auto;
      margin-left: 28px;
      column-gap: 28px;

      &::before,
      &::after {
        content: unset;
      }

      :global(li) {
        &.tab_nav {
          display: inline-block;
          color: #000000;

          &:last-child {
            margin-right: 0;
          }

          a {
            color: inherit;
            cursor: pointer;
            padding: 10px 0;
            padding-bottom: $gl_gap;
            font-size: 15px;
            font-family: $fontBaseV2;
            font-weight: 600;
            line-height: normal;
            line-height: 20px;

            @media screen and (max-width: 767px) {
              font-size: 13px;
            }

            &:focus {
              outline: none;
              background: none;
            }
            &:hover {
              background-color: transparent; // override
            }
          }

          &.moreLink {
            &:hover {
              color: black;
            }
            > a:hover {
              color: var(--site-theme-color);
            }

            .additionalNavMenu {
              position: absolute;
              width: 211px;
              border: 1px solid rgba(0, 0, 0, 0.2);
              border-top: none;
              background: #ffffff;
              padding: 20px 24px;
              top: 42px;
              right: -24px;
              z-index: #{$socialOverlayZIndex + 5};
              display: none;

              &.isShow {
                display: block;
              }

              &:hover {
                color: black;
              }

              .menuItem {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-bottom: 21px;

                &:last-child {
                  margin-bottom: 0;
                }

                * {
                  width: 100%;
                }

                * + * {
                  padding-top: 15px;
                }

                .titleItem {
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 136%;
                  color: #000;
                  text-align: end;

                  &.active {
                    color: var(--site-theme-color);
                  }
                }

                .link {
                  font-weight: normal;
                  text-align: end;

                  a {
                    font-weight: inherit;
                  }

                  &:hover,
                  &.active {
                    color: $activeColor;
                  }
                }
              }
            }
          }
        }

        &:hover,
        &:global(.active) {
          > a {
            color: var(--site-theme-color);
          }

          a {
            cursor: pointer;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }

    .right_tab_headers {
      display: flex;
      align-items: center;
      height: 100%;
      padding-right: 4px;

      .modal_user_menu {
        position: absolute;
        height: auto;
        background: white;
        display: flex;
        flex-direction: column;
        z-index: #{$socialOverlayZIndex + 4};
        padding: 2px 24px 2px 36px;
        right: 0;
        top: 47px;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      .modal_user_menu__links_list {
        display: flex;
        flex-direction: column;
      }
      .modal_user_menu__link {
        padding: 8px 0;
        display: flex;
        justify-content: flex-end;

        a {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 136%;
          color: black;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .currentLanguage {
        display: flex;
        width: 40px;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: black;

        &:hover {
          color: var(--site-theme-color);
        }
      }

      .languageDropdown {
        width: 384px;
        background: white;
      }
      .languageDropdown_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 47px;
        color: black;
        padding-top: 5px;

        &:hover {
          .languageDropdown_header_close {
            color: var(--site-theme-color);
          }
        }
      }
      .languageDropdown_header_text {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #000000;
        padding-left: 44px;
      }
      .languageDropdown_header_close {
        margin-left: auto;
        margin-right: 16px;
        color: inherit;

        svg {
          color: inherit;

          [fill] {
            color: inherit;
            fill: currentColor;
          }
          [stroke] {
            color: inherit;
            stroke: currentColor;
          }
        }
      }
      .languageDropdown_content {
        position: fixed;
        top: 47px;
        z-index: $socialOverlayZIndex + 5;
        width: 384px;
        max-height: calc(100vh - 117px);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        display: flex;
        flex-direction: column;
        background: white;
      }
      .languageDropdown_content_filter {
        flex-shrink: 0;
        padding: 22px 40px 40px;
      }
      .languageDropdown_content_filter_searchInput {
        width: 100%;
      }
      .languageDropdown_content_filter_searchInput_searchIcon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .languageDropdown_content_list_wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
      .languageDropdown_content_list {
        flex-grow: 1;
        @include nice-scrollbar;
      }
      .languageDropdown_content_list_item {
        padding-left: 44px;
        display: flex;
        flex-direction: row;

        &:hover {
          .languageDropdown_content_list_item_text:not(.selected) {
            text-decoration: underline;
          }
        }
      }
      .languageDropdown_content_list_item_text {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 31px;
        color: black;

        &.selected {
          color: var(--site-theme-color);
        }
      }
      .languageDropdown_content_list_item_selected {
        margin-left: auto;
      }
      .languageDropdown_content_poweredByGoogleTranslate {
        height: 48px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.4);
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }

      .accountNotiButton {
        width: 40px;
        height: 100%;
        margin-right: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        position: relative;

        &:hover,
        &.active {
          svg path {
            fill: var(--site-theme-color);
          }
        }

        .newNoti {
          display: none;
          position: absolute;
          bottom: 9px;
          right: 7px;
        }

        &.hasNoti {
          .newNoti {
            display: block;
          }
        }
      }
      .notificationsQuick {
        border: 1px solid rgba(0, 0, 0, 0.4);
        right: 100px;
        z-index: #{$socialOverlayZIndex + 6};

        @include breakpoint-sm {
          right: 0;
        }
      }

      .avatar {
        height: 40px;
        width: 40px;
      }

      .closeButton {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: black;
        position: absolute;
        right: 4px;
        top: 4px;
        z-index: #{$socialOverlayZIndex + 6};
      }

      &:not(.asking) {
        .closeButton {
          display: none;
        }
      }

      .tryItFree {
        width: $secondCoumnWidth;
        margin-right: 54px;
      }
      .signIn {
        border: none;
        display: inline;
        width: auto;
        min-width: 0;
        margin-right: 40px;
      }
    }

    &.extensionEmbed {
      &:not(.asking) {
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        pointer-events: none;

        .logo_tab_headers {
          display: none;
        }
        .left_tab_headers {
          display: none;
        }
        .right_tab_headers {
          > *:not(.askQuestion) {
            display: none;
          }

          > .askQuestion {
            pointer-events: all;
          }
        }
      }
    }
  }

  .top_tab_contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: none;
    padding: 0;
    overflow: hidden;

    :global(.tab-pane.active) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;

      & > * {
        width: 100%;
      }
    }
  }

  @include breakpoint-sm {
    padding-left: 0;

    .top_tabsHeadSection {
      margin-top: 0;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      position: fixed;
      z-index: $socialOverlayZIndex + 3;
      top: 0;
      transition: top 0.3s ease-in-out;
      border-bottom: none;
      padding-left: 23px;
      padding-right: 8px;

      &.asking {
        z-index: $socialOverlayZIndex + 4;
      }

      .left_tab_headers {
        margin: 0;
        column-gap: 20px;
        display: none;

        &::before,
        &::after {
          content: unset;
        }

        li.tab_nav {
          a {
            font-weight: 600;
            font-size: 13px;
            line-height: 150%;
            padding: 0;
          }
        }
      }

      .right_tab_headers {
        margin-left: auto;
        padding-right: 0;

        &.isMenuOpened {
          .currentLanguage,
          .accountNotiButton,
          .avatar {
            display: none;
          }
        }

        .currentLanguage {
          font-size: 13px;
          line-height: 150%;
        }

        .languageDropdown {
          position: fixed;
          left: 0;
          top: 0;
          z-index: $socialOverlayZIndex + 3;
          width: 100%;
        }

        .languageDropdown_header {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }

        .languageDropdown_content {
          width: 100vw;
          max-height: calc(100vh - 47px);
          border: none;
        }

        .iconMenu {
          display: block;
          background: url('~assets/img/redesign2021/global/mobile/menu.svg')
            no-repeat center;
          width: 40px;
          height: 40px;
          cursor: pointer;
          margin-top: 2px;

          &.active {
            background: url('~assets/img/redesign2021/global/mobile/close_menu.svg')
              no-repeat center;
          }
        }

        .guestNavMobile {
          display: flex;
          flex-direction: column;
          overflow: auto;
          position: fixed;
          width: 100vw;
          height: calc(100vh - 48px);
          left: 0;
          top: 48px;
          background: #fff;
          z-index: 14;
          padding: 26px 24px 72px;

          .guestNavMobile__menuItem_title {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: black;
            padding-block: 11px;
            cursor: pointer;

            &:hover,
            &.active {
              color: var(--site-theme-color);
            }

            &.alignRight {
              text-align: right;
            }
          }

          .guestNavMobile__menuItem {
            display: flex;
            flex-direction: column;
          }

          .guestNavMobile__menuItem_childs {
            display: none;
            flex-direction: column;
            padding-left: 20px;
            padding-top: 3px;
            padding-bottom: 4px;
            row-gap: 7px;

            &.isShow {
              display: flex;
            }
          }

          .guestNavMobile__menuItem_childs_content {
            display: flex;
            flex-direction: column;
          }

          .guestNavMobile__menuItem_childs_title {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: black;
            padding-bottom: 9px;

            &.active {
              color: var(--site-theme-color);
            }
          }

          .guestNavMobile__menuItem_childs_link {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: black;
            padding-block: 7px;

            &:hover,
            &.active {
              color: var(--site-theme-color);
            }
          }
        }

        .modal_user_menu {
          position: fixed;
          top: 0;
          width: 100vw;
          height: 100vh;
          z-index: $socialOverlayZIndex + 5;
          padding: 0;
        }

        .modal_user_menu__close_banner {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          background: $backgroundTiles;

          span {
            font-weight: 600;
            font-size: 13px;
            line-height: 150%;
            color: black;

            &.hover_text {
              display: none;
            }
          }

          svg {
            position: absolute;
            right: 17px;
          }

          &:hover {
            span {
              &.default_text {
                display: none;
              }

              &.hover_text {
                display: block;
              }
            }
          }
        }

        .modal_user_menu__header {
          display: flex;
          padding: 42px 22px 64px 24px;
          align-items: flex-end;
          justify-content: space-between;
        }

        .modal_user_menu__user_info {
          display: flex;
          align-items: flex-end;
          overflow: hidden;

          img {
            width: 56px;
            height: 56px;
            margin-bottom: 5px;
          }

          span {
            font-weight: 600;
            font-size: 15px;
            line-height: 136%;
            color: black;
            margin-left: 23px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .modal_user_menu__signout_btn {
          font-weight: 400;
          font-size: 15px;
          line-height: 136%;
          color: black;
          min-width: 70px;
          text-align: end;
        }

        .modal_user_menu__links_list {
          padding: 0 22px;
          flex-grow: 1;
        }

        .modal_user_menu__link {
          padding: 11px 0;
          justify-content: flex-start;

          a {
            font-weight: 600;
          }
        }

        .notification {
          margin-right: 7px;
        }

        .avatar {
          width: 32px;
          height: 32px;
          margin-left: 0;
        }

        .closeButton {
          display: none;
        }

        .tryItFree,
        .signIn {
          background: transparent;
          color: black;
          height: auto;
          min-width: auto;
          width: auto;
          margin: 0;
          font-weight: 600;
          font-size: 13px;
          line-height: 150%;
        }

        .signIn {
          margin-left: 0;
          margin-right: 15px;
        }
      }
    }

    .top_tab_contents {
      overflow: unset;

      :global(.tab-pane.active) {
        width: 100%;
        padding: 0;
      }
    }
  }
}

.qa {
  position: relative;
  top: 0;
  display: flex;
  height: 100%;

  &.belowLanguagePopup {
    z-index: 1;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: width 0.1s ease;
    white-space: nowrap;

    &.isFiltering {
      width: $socialSidebarWidth;

      @media screen and (max-width: 1300px) {
        width: 300px;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &.isFiltering {
      margin-top: 48px;
    }
  }

  &__header {
    display: flex;
    max-width: $maxWidthSocialContent;
    width: 100%;
    margin: 90px auto 0;
    padding-bottom: 24px;
    position: sticky;
    z-index: 2;
    background: white;
    // transition: top 0.5s ease, max-width 0.3s ease;
    transition: top 0.5s ease;
    top: -90px;

    &.sticky {
      top: 0;
      // max-width: 100%;
    }

    &_search {
      display: flex;
      flex-shrink: 0;
      flex-grow: 1;
      height: 56px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &:focus-within {
        border-bottom-color: black;
      }

      input {
        margin: 0;
        padding: 0 16px;
        border: none;
        outline: none;
        width: 100%;
        @include fontHelpCenter;
        caret-color: var(--marker-color);
      }

      &_filterBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 56px;
        height: 100%;
        cursor: pointer;
      }

      &_active_filters {
        display: flex;
        align-items: center;
        column-gap: 24px;
        margin-right: 24px;

        span {
          @include fontHelpCenter;
          white-space: nowrap;

          &[role='button'] {
            font-weight: 600;
          }
        }
      }
    }

    &_askBtn {
      margin-left: 24px;
      height: 56px;
      width: 320px;
      flex-shrink: 0;
      @include fontHelpCenter;
      color: white;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }

  .qa__questions_list {
    margin: 0;
    padding: 0;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    height: 100%;
  }

  .qa__fetch_error,
  .qa__fetching,
  .qa__fetch_empty {
    padding: 24px;
    @include fontHelpCenter;
    text-align: center;
  }

  .qa__initialLoading {
    &.qa__initialLoading-extension {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 14; // above "Ask a question" header button (actually displayed on the footer)
      background: white;
    }
  }

  .qa__extension_empty {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 103px 14px 10px;
    z-index: 14; // above "Ask a question" header button (actually displayed on the footer)
    background: white;

    &.qa__extension_empty-initialLoad {
    }

    &.qa__extension_empty-product {
      .qa__extension_empty-product__title {
        @include fontExtension;
        color: black;
        word-break: break-word;
      }

      .qa__extension_empty-product__ask_a_question {
        width: 100%;
        height: 56px;
        margin: 72px auto 0;
        background: #f4f4f4;
        border: none;
        @include fontExtension;
      }
    }

    &.qa__extension_empty-product_wait {
      .qa__extension_empty-product_wait__title {
        @include fontExtension;
        color: black;
        word-break: break-word;
      }

      .qa__extension_empty-product_wait__vote,
      .qa__extension_empty-product_wait__remove {
        margin: 72px auto 0;
        width: 100%;
        height: 56px;
        background: #f4f4f4;
        border: none;
        @include fontExtension;

        .hover_text {
          display: none;
        }

        &.hoverable:hover {
          .default_text {
            display: none;
          }
          .hover_text {
            display: inline;
          }
        }
      }
    }

    &.qa__extension_empty-new {
      .qa__extension_empty-product_wait__title {
        @include fontExtension;
        color: #000000;
        word-break: break-word;
      }

      .qa__extension_empty-product_wait__create {
        width: 100%;
        height: 56px;
        margin: 72px auto 0;
        background: #f4f4f4;
        border: none;
        @include fontExtension;
      }
    }
  }

  @include breakpoint-sm {
    top: 48px;
    z-index: 14;

    &.insideHelpCenter {
      top: 0;
    }

    &.extensionEmbed {
      top: 0;

      &.withExtensionEmpty {
        z-index: 13; // hide "Ask a question" button
      }

      .qa__header {
        top: 0;
      }
    }

    .qa__header {
      padding-bottom: 0;
      margin-top: 0;
      flex-direction: column;

      &.hasFilters {
        padding-bottom: 56px;
      }
    }

    .qa__header_search_active_filters {
      position: absolute;
      z-index: 1;
      bottom: 0;
      margin-right: 0;
      height: 56px;
      justify-content: space-between;
      width: 100%;
      padding: 0 16px;

      span {
        @include fontExtension;

        &[role='button'] {
          font-weight: 600;
        }
      }
    }

    .qa__header_content {
      flex-direction: column;
      align-items: flex-start;
    }

    .qa__header_askBtn {
      display: none;
    }

    .qa__sidebar {
      display: none;
      transition: none;

      &.isFiltering {
        display: flex;
        width: 100%;
      }
    }

    .qa__questions_list {
      display: flex;
      flex-direction: column;
      row-gap: 0px;
      overflow-x: hidden;
    }
  }
}

.products {
  $maxWidthProductsContent: 1497px;
  position: relative;
  top: 0;
  display: flex;
  height: 100%;

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: width 0.1s ease;
    white-space: nowrap;

    &.isShowFilterSort {
      width: $socialSidebarWidth;

      @media screen and (max-width: 1300px) {
        width: 300px;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__header {
    display: flex;
    margin: 40px 0;
    width: 100%;
    position: sticky;
    z-index: 2;
    background: white;
    transition: top 0.5s ease;
    top: -136px;

    &_inner {
      display: flex;
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
    }

    &.sticky {
      top: 0;
    }

    &_search {
      display: flex;
      flex-shrink: 0;
      flex-grow: 1;
      height: 56px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &:focus-within {
        border-bottom-color: black;
      }

      input {
        margin: 0;
        padding: 0 16px;
        border: none;
        outline: none;
        width: 100%;
        @include fontHelpCenter;
        caret-color: var(--marker-color);
      }

      &_filterBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 56px;
        height: 100%;
        cursor: pointer;
      }

      &_active_filters {
        display: flex;
        align-items: center;
        column-gap: 24px;
        margin-right: 24px;

        span {
          @include fontHelpCenter;
          white-space: nowrap;

          &[role='button'] {
            font-weight: 600;
          }
        }
      }
    }
  }

  .products__toggle_view_mode {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // margin: 0 40px;
    margin-left: 40px;

    .products__toggle_view_icon {
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 56px;
      height: 100%;

      &__disabled {
        opacity: 0.4;
      }
    }
  }

  .products_filter_sort {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background: white;
    position: relative;

    &__header {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 48px;
      background: #f4f4f4;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        background: white;
        height: 100%;
      }

      &:hover {
        &::after {
          content: 'Close';
          background: #f4f4f4;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include fontHelpCenter;
        }
      }

      &_close {
        width: 48px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_title {
        flex-grow: 1;
        text-align: center;

        span {
          @include fontHelpCenter;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 0 0 16px;
      overflow: hidden;

      &_tab_header {
        display: flex;
        font-size: 15px;
        margin-bottom: 24px;

        &_item {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          color: rgba(0, 0, 0, 0.4);
          height: 56px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:last-child {
            border-right: 0 none;
          }

          &.active,
          &:hover {
            color: #000;
          }

          &.active {
            border-bottom-color: transparent;
          }
        }
      }

      &_tab_content {
        overflow-y: auto;

        &:not(.active) {
          display: none;
        }
      }
    }

    &__sorting,
    &__filtering {
      li {
        padding: 0 16px;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }

    &__sorting {
      h3 {
        @include fontHelpCenter;
        font-weight: 600;
        margin: 0;
        padding: 16px 0;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      li {
        display: flex;
        align-items: center;
        column-gap: 16px;
        height: 56px;
        cursor: pointer;

        &.active {
          svg {
            display: flex;
          }
        }

        span {
          @include fontHelpCenter;
          text-transform: capitalize;
        }

        svg {
          flex-shrink: 0;
          display: none;
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }

    &__filtering {
      h3 {
        @include fontHelpCenter;
        font-weight: 600;
        margin: 0;
        padding: 16px 0;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      li {
        display: flex;
        align-items: center;
        column-gap: 16px;
        height: 56px;
        cursor: pointer;

        &.active {
          svg {
            display: flex;
          }
        }

        span {
          @include fontHelpCenter;
          text-transform: capitalize;
          flex-grow: 1;
        }

        svg {
          display: none;
          flex-shrink: 0;
        }
      }
    }

    &__footer {
      display: flex;
      flex-direction: column;
      padding: 16px;

      &_applyBtn {
        flex-shrink: 0;
        height: 56px;
        margin: 16px 24px 0;
        width: auto;
        @include fontHelpCenter;
      }
    }
  }

  .products__fetch_error,
  .products__nothing_found {
    @include fontHelpCenter;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
  }

  .products__table {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 18px;
    height: 100%;
    // overflow-y: scroll;
    align-items: center;

    > div {
      max-width: $maxWidthProductsContent;
      width: 100%;
    }
  }

  .products__table_body {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    align-items: center;

    > div {
      width: 100%;
    }

    @include breakpoint-sm {
      column-gap: 0;
    }

    .products__product__header__icon {
      width: 24px;
      height: 24px;
      margin: 16px;
    }

    .products__product__header__name {
      margin-left: 24px;
    }
  }

  .products__table_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.05);
    max-width: 900px;
    margin: 0 auto;
    cursor: pointer;

    > div {
      font-size: 15px;
      color: black;
      display: flex;
      justify-content: center;
      flex: 1;
    }

    .products__product__follow {
      margin-top: 0;
    }
    .products__row_product_info {
      order: 1;
      flex: 2;
    }
    .products__product_summary_list {
      align-items: center;
      order: 2;
      flex: 2;
    }
    .products__product__top_creators {
      align-items: flex-end;
      flex-direction: column;
      order: 3;
      flex: 1;
    }
    .products__row_product_follow {
      order: 4;
      justify-content: flex-end;
      flex: 1;
    }
    .products__product__top_creators__header {
      margin-bottom: 16px;
    }
    &:hover {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  .products__row_product_info_content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .products__products_list {
    height: 100%;
    // overflow-y: scroll;
    // padding-left: 8px;
  }

  .products__products_list_body {
    display: flex;
    padding: 0 12px 40px;
    flex-wrap: wrap;
    justify-content: center;
    max-width: $maxWidthProductsContent;
    margin: 0 auto;
    gap: 24px;

    .products__product__header__label {
      margin-top: 40px;
    }

    .products__product_summary_list {
      margin-top: 24px;
    }

    .products__product__top_creators {
      margin-top: 24px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .products__products_list_item_wrapper {
    display: flex;
    flex-basis: calc(33.33% - 24px);
    max-width: 350px;

    @media screen and (max-width: 900px) {
      flex-basis: calc(50% - 24px);
    }

    @media screen and (max-width: 767px) {
      min-width: 100%;
      max-width: 100%;
    }
  }

  .products__products_list_item {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fff;
    padding: 12px;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }

  .products__product__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .products__product__header__icon {
    width: 40px;
    height: 40px;
    margin-right: 56px;
    object-fit: contain;
  }

  .products__product__header__name {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-grow: 1;
  }
  .products__product__header__label {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
  }

  .products__product__top_categories {
    margin-top: 15px;
  }
  .products__product__no_categories {
    margin-top: 6px;
    height: 86px;
    padding-top: 2px;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .products__product__top_categories__header {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: black;
  }
  .products__product__top_categories__list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 7px 5px;
    margin-top: 6px;
    margin-left: -9px;
    height: 86px;
    overflow: hidden;
  }
  .products__product__top_categories__item {
    background: rgba(0, 0, 0, 0.05);
    padding: 2px 9px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .products__product__top_categories__item__text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
  }
  .products__product__top_categories__item__count {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.4);
    margin-left: 7px;
    padding-top: 3px;
  }
  .products__product_summary_list {
    display: flex;
    flex-direction: column;
  }
  .products__product__summary_count {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
    margin-left: 12px;
    list-style-type: none;
    line-height: 200%;
  }

  .products__product__top_creators {
    font-size: 15px;
    display: flex;
    cursor: pointer;

    &:hover {
      .products__product__top_creators__header {
        color: #000;
      }
    }
  }
  .products__product__no_creators {
    span {
      font-weight: 400;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .products__product__top_creators__header {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.4);
  }
  .products__product__top_creators__list {
    display: flex;
    column-gap: 10px;
  }
  .products__product__top_creators__item {
    display: flex;
    align-items: center;
  }
  .products__product__top_creators__item__avatar {
    width: 26px;
    height: 26px;
    object-fit: cover;
    flex-shrink: 0;
  }
  .products__row_product_follow {
    justify-content: flex-end;
  }
  .products__product__follow {
    width: 86px;
    min-width: 86px;
    height: 40px;
    font-size: 15px;

    .hover_text {
      display: none;
    }

    &.is_followed:hover {
      .default_text {
        display: none;
      }
      .hover_text {
        display: block;
      }
    }
  }

  @include breakpoint-sm {
    top: 48px;

    &.extensionEmbed {
      top: 0;

      .products__header {
        top: 0;
      }
    }

    .products__sidebar {
      display: none;

      &.isShowFilterSort {
        display: flex;
      }
    }

    .products_filter_sort {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 13;
    }

    .products__table_body {
      row-gap: 0;

      .products__table_row {
        padding: 20px;
        height: 192px;
        flex-wrap: wrap;
        border-left: none;
        border-right: none;

        .products__product__header__name {
          margin-left: 8px;
        }
        .products__row_product_info {
          order: 1;
          flex: 65%;
          justify-content: flex-start;
        }
        .products__product_summary_list {
          order: 3;
          flex: 65%;
          justify-content: flex-start;
          align-items: flex-start;
        }
        .products__product__top_creators {
          order: 4;
          flex: 35%;
          justify-content: flex-end;
        }
        .products__row_product_follow {
          order: 2;
          flex: 35%;
          justify-content: flex-end;
        }
        .products__product__top_creators__header {
          margin-bottom: 12px;
        }
      }

      .products__product__header__icon {
        margin-right: 24px;
        margin-left: 0;
      }

      .products__product__header__label {
        font-size: 15px;
      }
    }

    .products__header {
      width: 100%;
      // transition: top 0.3s ease-in-out;
      // top: 48px;
      margin-top: 24px;
      margin-bottom: 0;
      padding-bottom: 24px;
    }

    .products__toggle_view_mode {
      display: none;
    }

    .products__products_list_body {
      flex-direction: column;
      row-gap: 24px;
      padding: 24px;
      background: $backgroundTiles;
      margin: 0;
      align-items: center;
    }

    .products__product__top_categories {
      margin-top: 21px;
    }

    .products__product__top_categories__list {
      height: auto;
      margin-left: -5px;
    }

    .products__product__top_categories__item {
      padding: 0 5px;
    }

    .products__product__top_categories__item__text {
      font-size: 13px;
    }

    .products__product_summary_list {
      margin-top: 0px;
    }

    .products__product__summary_count {
      font-size: 16px;
      margin-left: 0;

      &::before {
        margin-left: 8px;
      }
    }
  }
}

.similarPopupOpened {
  .modal_ask_question__ask_question {
    @include breakpoint-sm {
      height: 100%;
    }
    .modal_ask_question__name {
      margin-bottom: 0;
      z-index: 2;
      position: relative;

      @include breakpoint-sm {
        .content_editable_component_content[contentEditable] {
          max-height: 177px;
          height: 177px;
          margin-bottom: 25.5px;

          &.is_focus {
            margin-bottom: 0;
          }
        }
      }
    }

    .modal_ask_question__description {
      display: none;
    }
  }

  .modal_ask_question__button_group {
    z-index: 2;

    @include breakpoint-sm {
      // flex-grow: 0;
      // margin-bottom: 0;
      display: none;
    }
  }
}

.underlay {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: alias;
}

.shareForEmbedInfo {
  padding-top: 80px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1260px) {
    padding: 80px 40px 10px;
  }

  .productHelpCenterForEmbedMenu {
    padding-bottom: 40px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    gap: 20px;

    .menuItem {
      cursor: pointer;
      font-size: 15px;
      font-weight: 600;

      &:global(.active) {
        color: var(--site-theme-color);
      }
    }
  }

  .products__sidebar {
    display: none;
  }

  .products__header {
    top: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 24px;
    position: relative;
  }

  .products__header_inner {
    align-items: center;
    max-width: 1200px;
  }

  :focus-visible {
    outline: none;
  }

  .products__table_body,
  .products__table_row,
  .products__products_list_body {
    max-width: 1200px;
  }

  .products__table_body {
    padding-bottom: 30px;
  }

  .products__products_list_body {
    justify-content: flex-start;
    padding: 0 0 30px;
  }

  .productHelpCenterForIntegration {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    flex-grow: 1;
    font-family: Libre Franklin, serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.4px;
    text-align: left;

    .integrationLogo {
      width: 24px;
      height: 24px;
      min-width: 16px;
      min-height: 16px;
      @include integrationIcon;
    }

    .integrationName {
      text-transform: capitalize;
    }
  }

  .qa__header_search {
    flex-grow: 0;

    :global(input) {
      font-size: 15px;
      font-weight: normal;
    }
  }

  .products__products_list_item_wrapper {
    display: flex;
    flex-basis: calc(33.33% - 16px);
    max-width: 400px;
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }
}
