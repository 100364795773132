.teamTab {
  display: flex;
  flex-direction: column;

  .teamTab__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  .teamTab__search {
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 24px;
    padding: 0 24px;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    svg {
      flex-shrink: 0;
      margin-right: 16px;
    }

    input {
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;
      outline: none;
      font-size: 15px;
      line-height: 20px;
    }
  }

  .teamTab__inviteButton {
    min-width: auto;
    padding: 0 24px;
  }

  .teamTab__content {
  }

  .teamTab__table {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
  }

  .teamTab__tableHeader {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 16px;
    padding: 18px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.5);
  }

  .teamTab__tableRow {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 16px;
    padding: 18px 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    align-items: center;
  }

  .teamTab__creator {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .teamTab__avatar {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .teamTab__tableCell {
    &:last-child {
      text-align: right;
    }
  }
}

.inviteCreators {
  .inviteCreators__header {
    display: flex;
  }

  .addEmailsInput {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 1;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    >div:first-child {
      width: 100%;
      height: 100%;
    }

    .addEmailsInput_autoCompleteInput{
      width: 100%;
      height: 100%;
      padding: 0;
      border: none;
      outline: none;
      background: transparent;
      font-size: 15px;
      line-height: 20px;
      padding: 0 24px;
    }

    .addEmailsInput_autoCompleteItem {
      padding: 0 24px;
      font-size: 15px;
      line-height: 56px;
      cursor: pointer;

      &:hover {
        background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
      }
    }

    .addEmailsInput_addButton {
      min-width: auto;
      padding: 0 24px;
    }

    .addEmailsInput_divider {
      width: 1px;
      height: 24px;
      background: rgba(0, 0, 0, 0.2);
    }

    .addEmailsInput_uploadCsv {
      display: flex;
      align-items: center;
      column-gap: 16px;
      padding: 0 24px;
      height: 100%;
      cursor: pointer;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      margin: 0;
      white-space: nowrap;
      transition: color 0.3s ease;

      svg {
        flex-shrink: 0;

        path {
          fill: currentColor;
        }
      }

      input {
        display: none;
      }

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .inviteCreators__list {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .inviteCreators__list_item {
      display: flex;
      align-items: center;
      gap: 16px;
      padding-left: 24px;
      height: 56px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-top: none;
      font-size: 15px;
      line-height: 20px;

      &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      svg {
        flex-shrink: 0;
      }

      .inviteCreators__list_item_email {
        flex-grow: 1;
      }

      .inviteCreators__list_item_permissionSelect {
        flex-shrink: 0;
        width: auto !important;
      }

      .inviteCreators__list_item_deleteBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s ease;

        svg path {
          &[stroke] {
            stroke: currentColor;
          }
          &[fill] {
            fill: currentColor;
          }
        }

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .inviteCreators__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    padding-top: 8px;
    padding-bottom: 56px;
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 1;
    margin-top: 24px;
  }
}

.sharedCreators__list {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  .sharedCreators__list_item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 24px;
    height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    font-size: 15px;
    line-height: 20px;

    &:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    svg {
      flex-shrink: 0;
    }

    .sharedCreators__list_item_email {
      flex-grow: 1;
    }

    .sharedCreators__list_item_actions {
      display: flex;
      align-items: center;
    }

    .sharedCreators__list_item_resendBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: color 0.3s ease;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;

      &:hover {
        color: var(--site-theme-color);
      }
    }

    .sharedCreators__list_item_deleteBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 24px;
      cursor: pointer;
      transition: color 0.3s ease;

      svg path {
        &[stroke] {
          stroke: currentColor;
        }
        &[fill] {
          fill: currentColor;
        }
      }

      &:hover {
        color: var(--site-theme-color);
      }
    }

    .sharedCreators__list_item_resent {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.requestCreators__list {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  .requestCreators__list_item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 24px;
    height: 56px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: none;
    font-size: 15px;
    line-height: 20px;

    &:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    svg {
      flex-shrink: 0;
    }

    .requestCreators__list_item_email {
      flex-grow: 1;
    }

    .requestCreators__list_item_actions {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .requestCreators__list_item_action {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 56px;
      height: 100%;
      cursor: pointer;
      transition: color 0.3s ease;

      svg path {
        &[stroke] {
          stroke: currentColor;
        }
        &[fill] {
          fill: currentColor;
        }
      }

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }
}
