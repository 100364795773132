@import '~theme/modules/variables';
@import '~theme/mixins';

.beyondTodayLearning {
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;
    height: 32px;
    flex-shrink: 0;
  }

  &__tabs {
    display: flex;
    column-gap: 43px;
    position: absolute;
    background: white;
    width: 100%;

    span {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      cursor: pointer;

      &.active,
      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  &__content {
    p {
      max-width: 697px;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin-bottom: 40px;
    }

    iframe {
      width: 100%;
      max-width: 784px;
      height: 440px;
      border: none;
      outline: none;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 15px;
        line-height: 20px;
        color: black;
      }
    }
  }
}

.rockhillTechSolutions {
  display: flex;
  flex-direction: column;

  &__images {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    margin-bottom: 36px;

    img {
      max-width: 359px;
      width: 100%;
      height: auto;
      border: 1px solid #f4f4f4;
    }
  }

  &__header {
    position: relative;
    height: 32px;
    flex-shrink: 0;
  }

  &__tabs {
    display: flex;
    column-gap: 43px;
    position: absolute;
    background: white;
    width: 100%;

    span {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      cursor: pointer;

      &.active,
      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  &__content {
    p {
      max-width: 697px;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin-bottom: 40px;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 15px;
        line-height: 20px;
        color: black;
      }
    }
  }
}

.preview_images {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: calc(100vw - 20%);
  height: calc(100vh - 20%);
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: none;
  &.show {
    display: flex;
  }

  :global(.slick-slider) {
    width: 100%;
  }

  :global(.slick-list),
  :global(.slick-track) {
    height: 100%;
  }

  :global(.slick-slide) > div {
    display: flex;
    height: 100%;
  }

  :global(.slick-prev) {
    left: -50px !important;
    &::before {
      content: none;
    }
  }
  :global(.slick-next) {
    right: -50px !important;
    &::before {
      content: none;
    }
  }

  .preview_images_img {
    display: flex !important;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }
}

@include breakpoint-sm {
  .beyondTodayLearning {
    display: flex;
    flex-direction: column;

    &__header {
      height: 52px;
    }

    &__tabs {
      flex-direction: column;
      row-gap: 10px;

      &.isShowDropdown {
        span {
          display: block;

          &.actived svg {
            transform: rotate(180deg);
          }
        }
      }

      span {
        display: none;

        &.active {
          display: block;
          color: black;
          order: -1;

          svg {
            margin-left: 19px;
          }
        }
      }
    }

    &__content {
      p {
        max-width: 100%;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 36px;
      }

      iframe {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }

  .rockhillTechSolutions {
    display: flex;
    flex-direction: column;

    img {
      max-width: 100%;
    }

    &__header {
      height: 52px;
    }

    &__tabs {
      flex-direction: column;
      row-gap: 10px;

      &.isShowDropdown {
        span {
          display: block;

          &.actived svg {
            transform: rotate(180deg);
          }
        }
      }

      span {
        display: none;

        &.active {
          display: block;
          color: black;
          order: -1;

          svg {
            margin-left: 19px;
          }
        }
      }
    }

    &__content {
      p {
        max-width: 100%;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 36px;
      }

      iframe {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}
