@import '~theme/modules/variables';
@import '~theme/mixins';

.root {
  font-size: 15px;
  font-family: $fontBaseV2;

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }
}

.progressWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;

  &:hover {
    .progress {
      background-color: var(--site-theme-color);
    }
    .bar {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  h4 {
    font-size: 15px;
    color: #000000;
    font-weight: 600;
  }

  h4, p {
    margin: 0;
  }
}

.bar, .progress {
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}

.progress {
  background-color: #000000;
}

.popup {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 15px;
  font-family: $fontBaseV2;
  
  :global(.arrow) {
    visibility: hidden;
  }
  :global(.popover-content) {
    padding: 16px;
  }

  .progressWrapper {
    gap: 16px;
  }

  ol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    list-style: none;

    li {
      a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
      }

      &:not(.done) {
        opacity: 0.4;
      }

      &.done {
        a {
          pointer-events: none;
        }
      }

      &.disabled {
        a {
          pointer-events: none;
          opacity: 0.4;
        }
      }

      &:hover {
        color: var(--site-theme-color);
        opacity: 1;
      }
    }
  }

  button {
    margin-top: 16px;
  }
}
