@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/Account/partials';

.admin_tab {
  margin: 0 auto;
  display: flex;
  flex-flow: wrap;
  font-family: $fontBaseV2;
  font-size: 15px;
  color: black;
  padding-top: 40px;
  row-gap: 54px;

  @include breakpoint-sm {
    padding-top: 24px;
    row-gap: 30px;
  }

  .section {
    width: 430px;
    margin-right: 88px;

    h4 {
      font-weight: 600;
      font-size: 21px;
      margin-bottom: 16px;
      margin: 0;
      margin-bottom: 57px;
    }

    p {
      margin: 0;
      margin-bottom: 24px;
    }

    label {
      font-weight: 400;
      gap: 20px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: normal;
      max-width: none;
      margin: 0;

      a {
        text-transform: uppercase;
      }
    }

    .box {
      padding: 24px;
      background: #f4f4f4;
      margin-bottom: 24px;
    }

    @include breakpoint-sm {
      width: 100%;
      margin-right: 0;
      padding-bottom: 24px;

      label {
        justify-content: space-between;
      }
    }
  }

  .label {
    //letter-spacing: 1px;
    font-family: $fontBaseV2;
    font-weight: 300;
    line-height: 24px;
    font-size: 10px;
    color: #000000;
    opacity: 0.4;
  }

  :global(.text-field) {
    @extend .text_field;
  }

  .input {
    width: 100%;
    background-color: #fff;
    color: #000000;
    border-left: none;
    border-radius: 0;
    padding: 0;
    font-family: $fontBaseV2;
    height: 34px;
    outline: none;
    border: none;
    border-bottom: 1px solid #e0e0e0;

    &:focus {
      border-bottom: 1px solid var(--site-theme-color);
    }
  }

  .formGroup {
    margin-bottom: 24px;

    @include breakpoint-sm {
      margin-bottom: 17px;
    }
  }

  .button {
    text-align: left;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .blue_link {
      width: 100%;
      height: 100%;
      display: inline;
      text-align: left;
      color: #000000;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
      transition: color 0.3s ease;
      position: relative;
      padding-left: 38px;

      @include breakpoint-sm {
        font-size: 10px;
        font-weight: 300;
      }

      svg {
        position: absolute;
        margin: 0;
        left: 0;
        top: 0;

        > {
          path,
          circle {
            transition: all 0.3s ease;
          }
        }
      }

      &:hover {
        text-decoration: none;
        color: var(--site-theme-color);

        &.link_eye_closed {
          svg > {
            path {
              stroke: var(--site-theme-color);
            }
            circle {
              fill: var(--site-theme-color);
            }
          }
        }
      }
    }
  }

  .boolean_label {
    display: flex;
    justify-content: space-between;
    user-select: none;
    padding: 0;
    align-items: center;
    max-width: 200px;

    span {
      text-align: left;
      flex-grow: 0;
      padding: 0;
      // font-style: normal;
      // font-weight: normal;
      // font-size: 16px;
      line-height: 20px;
    }

    a {
      display: inline-block;
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      text-decoration: none;
      animation: color 0.3s ease;
      color: #ccc;

      &:hover {
        color: var(--site-theme-color);
      }
    }

    &.checked {
      a:not(:hover) {
        color: black;
      }
    }
  }

  .reloading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: #{$topNavBarZIndex + 1};
    background: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 15px;
  }
}
