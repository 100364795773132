@import '~theme/modules/variables';
@import '~theme/form';

.container {
  width: 100%;
  height: calc(100% - 60px);
  padding-right: 134px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 10px;

  &.halt {
    overflow: hidden;
    padding-right: 145px;

    @include breakpoint-sm {
      padding-right: 0;
    }
  }

  &.hidden {
    display: none;
  }

  &.noTutorials {
    padding-right: 0;
    height: 100%;
    overflow: hidden;

    .contentWrapper {
      width: 100%;
      height: 100%;
      padding: 20px 50px 50px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 145px;

  .restore {
    width: 100px;
  }

  &.blur {
    opacity: 0.2;
    pointer-events: none;
  }
}

.blur {
  opacity: 0.2;
  pointer-events: none;
}

.contentWrapper {
  height: 100%;
}

.sidebarFilterCount {
  margin-bottom: 10px;

  .reset {
    cursor: pointer;
    font-weight: 600;
    margin-left: 5px;
    &:hover {
      color: var(--site-theme-color);
    }
  }
}

.content {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  align-items: center;

  .selectAllCheckbox {
    width: 168px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 11px;
  }

  .title {
    font-family: $fontBaseV2;
    font-size: 15px;
  }

  .checkboxWrapper {
    height: 24px;
    margin-right: 4px;
  }
}

.noticeText,
.emptyText {
  font-family: $fontBaseV2;
  font-weight: bold;
  font-size: 15px;
  color: black;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  max-width: 1000px;
}

.container:not(.withFilterBar) {
  .emptyText {
    margin-top: 54px;
  }
}

.createTutorialButton {
  width: 259px;
  height: 48px;
}

.getStartIframe {
  width: 100%;
  height: 100%;
  min-height: 400px;
  border: none;

  &.hidden {
    display: none;
  }
}

@include breakpoint-sm {
  .container {
    padding-right: 14px;
  }

  .content {
    display: none;
  }

  .noticeText {
    font-size: 13px;
    font-weight: normal;
  }

  .content .title {
    font-size: 13px;
  }

  .contentHeader {
    margin-top: 0;
  }

  .emptyText {
    font-size: 13px;
    text-align: center;
    width: 100%;
    top: 226px;
    margin-top: 15px;

    &.trashEmpty,
    &.draftEmpty,
    &.archivedEmpty,
    &.deactivatedEmpty {
      margin-top: 0;
    }
  }

  .getStartIframe {
    height: 500px;
    margin-left: 0;
  }

  .createTutorialButton {
    margin-top: 50px;
    margin-left: 0;
    position: absolute;
    top: 290px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
  }
}

@media screen and (max-width: 1080px) {
  .container,
  .header {
    padding-right: 38px;
  }
}

.shareForEmbedInfo {
  &.noTutorial {
    text-align: center;
    padding: 20px;
    font-style: italic;
  }

  &.container {
    width: 100%;
    height: 100%;
    padding: 0;
    scrollbar-gutter: stable both-edges;

    .contentWrapper {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
    }
  }
}
