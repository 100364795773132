@import "~theme/modules/variables";
@import '~theme/mixins';
@import '~theme/guidelines';
@import '~theme/custom_react_google_flight_datepicker';

:global(.app-loading) .adminPage {
  cursor: progress;
}
.adminPage {
  height: calc(100vh - 48px);

  @include breakpoint-sm {
    overflow-x: hidden;
  }

  > div, > form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  :global(.easy-dialog) {
    height: auto;
  }

  .sortable {
    cursor: pointer;
  }
  :global(.sticky-table) thead td.sortable.active {
    font-weight: bold;
    color: black;
  }

  .subscribers {
    .topMenu {
      padding-left: 30px;

      .general {
        padding-left: 30px;
      }
    }
  }

  .checkbox_label {
    cursor: pointer;

    input {
      margin: 0;
      margin-right: 10px;
    }
  }

  .integrations, .tutorialsPage {
    .topMenu {
      .additional {
        .item {
          text-align: left;

          &:last-child {
            text-align: left;
          }
        }
      }
    }

    .bulk_actions {
      padding-bottom: 20px;
      padding-left: 60px;
    }
  }

  .tutorialsPage.tutorialsPrivacyPage {
    label {
      margin-right: 50px;
    }

    select {
      margin-left: 10px;
      padding: 5px 10px;
    }
  }

  .tutorialsPage.tutorialsWhitelistPage {
    .bulk_actions {
      padding-left: 0;
      padding-top: 15px;
      padding-bottom: 0;

      button {
        margin-right: 30px;
      }
    }
    :global(.sticky-table) {
      height: 100%;
    }
  }
  .tutorialsPage.tutorialsReportPage {
    .bulk_actions {
      display: flex;
      flex-direction: row;
      gap: 30px;
    }
  }

  .productsPage {
    .topMenu {
      .type {
        width: 100px !important;
        display: inline-block;
        margin-left: 15px;
      }

      :global {
        .easy-textfield.search {
          margin-left: 15px;
          margin-right: 0;
          height: 35px;
        }
      }
    }

    .add_product_modal {
      :global(.popup-content) {
        display: flex;
        flex-direction: column;
        padding-top: 30px;

        [role="button"] {
          cursor: pointer;
        }
        input[type="file"] {
          display: none;
        }

        .formSection {
          align-self: flex-start;

          span {
            display: inline-block;
            width: 150px;
          }
          input[type="text"] {

          }
        }

        .formSection.formSection_logo {
          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }

        .createButton {
          width: 305px;
          margin-top: 20px;
        }
      }
    }

    .edit_product_modal {
      :global(.popup-content) {
        display: flex;
        flex-direction: column;
        padding-top: 30px;

        [role="button"] {
          cursor: pointer;
        }
        input[type="file"] {
          display: none;
        }

        .formSection {
          align-self: flex-start;

          span {
            display: inline-block;
            width: 275px;
          }
          input[type="text"] {

          }
        }

        .aliasUrls {

        }
        .aliasUrls__title {
          font-weight: 700;
        }
        .aliasUrls__list {
          margin-left: 275px;
        }
        .aliasUrls__list__item {
          margin-top: 5px;

          :not(:first-child) {
            margin-left: 10px;
          }
        }
        .aliasUrls__list__item__input {

          &.aliasUrls__list__item__input-addNewAlias {

          }
        }
        .aliasUrls__list__item__remove {
          margin-left: 10px;
        }

        .formSection.formSection_logo {
          margin-top: 15px;

          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }

        .updateButton {
          width: 305px;
          margin-top: 20px;
        }
        .removeButton {
          width: 305px;
          margin-top: 20px;

          &:not(:hover) {
            background: red;
          }
        }
      }
    }

    .product_wait_list_modal {
      :global(.popup-content) {
        display: flex;
        flex-direction: column;
        padding-top: 30px;

        [role="button"] {
          cursor: pointer;
        }
      }

      .topMenu {
        padding-left: 0;
      }

      :global(.sticky-table) {
        height: calc(100vh - 200px);
      }
    }

    .product_wait_details_modal {
      :global(.popup-content) {
        display: flex;
        flex-direction: column;
        padding-top: 30px;

        [role="button"] {
          cursor: pointer;
        }
      }

      .removeButton {
        width: 305px;
        margin-top: 20px;
      }
    }
  }

  .ioradicalsPage {
    .date_section {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint-sm {
        flex-direction: column;
      }

      .date_range {
        font-weight: normal;
        font-size: 15px;
        line-height: 136%;
        font-family: $fontBaseV2;
        display: flex;
        color: #000;
        flex-shrink: 0;

        label {
          font-weight: normal;
          margin-bottom: 0;
          display: flex;
          align-items: center;
        }

        @include breakpoint-sm {
          .label {
            margin: 0;
          }
        }
      }

      .datepicker_preset_group {
        width: 100%;
        margin-top: 0;
        margin-left: 30px;
        display: flex;

        @include breakpoint-sm {
          margin-top: 10px;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      .datepicker_preset {
        font-weight: normal;
        font-size: 15px;
        font-family: $fontBaseV2;
        position: relative;
        padding: 0;
        padding-left: 28px;
        margin-right: 40px;
        border-left: none;
        cursor: pointer;

        @include breakpoint-sm {
          margin-right: 0;
          margin-bottom: 10px;
          width: 50%;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: -2px;
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background-color: #E5E5E5;
        }

        &.datepicker_preset_applied {
          font-weight: 600;
          color: #000;

          &::after {
            content: '';
            position: absolute;
            left: 4px;
            top: 2px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: #000000;
          }
        }

        &.disabled {
          opacity: 0.4;
          cursor: default;
        }
      }
    }

    .ambassador_alert_list_modal,
    .ioradical_questions_modal,
    .ioradical_answers_modal
    {
      :global(.popup-content) {
        display: flex;
        flex-direction: column;
        padding-top: 30px;

        [role="button"] {
          cursor: pointer;
        }
      }

      .topMenu {
        padding-left: 0;
      }

      :global(.sticky-table) {
        height: calc(100vh - 200px);

        :global(.body table) {
          table-layout: fixed;
          word-wrap: break-word;
        }
      }
    }

    .add_ambassador_alert_modal {
      :global(.popup-content) {
        display: flex;
        flex-direction: column;
        padding-top: 30px;

        [role="button"] {
          cursor: pointer;
        }

        .formSection {
          align-self: flex-start;

          span {
            display: inline-block;
            width: 150px;
          }

          input[type="text"] {}
        }

        .createButton {
          width: 305px;
          margin-top: 20px;
        }
      }
    }
  }

  .notyAnalyticsDetails {
    .bulk_actions {
      padding-bottom: 20px;
    }
  }

  .plans {
    .topMenu {
      .general {
        .left {
          display: flex;

          :global(.react-selectize) {
            margin-right: 20px;
          }

          :global(.react-selectize-control) {
            height: 40px;

            :global(.react-selectize-search-field-and-selected-values) {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }

  .featureAnalyticsPage {
    .topMenu {
      .general {
        .left {
          display: flex;
          flex-direction: row;
          gap: 15px;

          .filterKeyContainer {

          }

          .bySwitcher {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;

            label {
              margin-left: 10px;
              margin-bottom: 0;
              &:first-child {
                margin-left: 0;
              }

              span {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  .featureAnalyticsDetails {
    .bulk_actions {
      padding-bottom: 20px;
    }
  }

  .tutorial_audit,
  .tutorial_access_audit,
  .creator_audit,
  .tutorials_embed_statistics,
  .tutorial_stats,
  .subscriber_stats {
    .topMenu {
      .general .dateFilter {
        width: calc(100% - 510px - 50px - 240px);
      }
    }
  }
  .tutorial_audit {
    .bulk_actions {
      a:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
  .tutorial_access_audit {
    .bulk_actions {
      a:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
  .creator_audit {
    .bulk_actions {
      a:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
  .secondary_emails {
    .topMenu {
      .general .checkbox_label {
        padding: 0 20px;
      }
    }
  }
  .subscriber_stats {
    .result_totals {
      margin-bottom: 30px;

      > span {
        display: inline-block;
        margin-left: 25px;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 17px;
      }
    }
  }
  .tutorialsEmbedStatistics {
    .searchingModal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: #{$topNavBarZIndex + 1};
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(2px);
      display: flex;
      align-items: center;
      justify-content: center;

      &_inner {
        max-width: 80%;
        max-height: 80%;
        padding: 20px;
        background: white;
        color: black;
        font-size: 18px;
      }
    }
  }
  .referralsPage {
    .exportCSV {
      margin-left: 5px;
      position: relative;
      top: -20px;
    }

    .general > div:not(:first-child) {
      margin-top: 10px;
    }

    .topMenu {
      .loadReportContainer {
        display: inline-block;

        .loadReportFile {
          position: absolute;
          opacity: 0;
          width: 80px;
          z-index: -1;
        }

        .loadReportLink {

        }
      }

      .generateReportLink {
        margin-left: 30px;
      }

      .dashboardLink {
        margin-left: 30px;
      }

      .exportCSV {
        margin-left: 30px;
        position: static;
        top: auto;
      }
    }
  }
  .tutorial_audio {
    margin: 0 40px;
    display: flex;
    flex-direction: column;
    max-width: 800px;

    input {
      margin-bottom: 10px;

      @include breakpoint-sm {
        max-width: calc(100vw - 80px);
      }
    }

    .tutorial_id {
      margin: 10px 0;
    }

    label {
      font-weight: normal;
      margin: 10px 0;

      input {
        top: 1px;
        margin-right: 6px;
        position: relative;
      }
    }

    .replace_audio {
      div {
        display: inline-block;
        white-space: nowrap;
        margin-left: 5px;
      }
    }

    .done_btn {
      width: 150px;
    }

  }
  .tutorial_video {
    margin: 0 40px;
    display: flex;
    flex-direction: column;
    max-width: 800px;

    input {
      margin-bottom: 10px;

      @include breakpoint-sm {
        max-width: calc(100vw - 80px);
      }
    }

    .tutorial_id {
      margin: 10px 0;
    }

    label {
      font-weight: normal;
      margin: 10px 0;

      input {
        top: 1px;
        margin-right: 6px;
        position: relative;
      }
    }

    .replace_video {
      div {
        display: inline-block;
        white-space: nowrap;
        margin-left: 5px;
      }
    }

    .done_btn {
      width: 150px;
    }

  }
  .topMenu {
    padding: 20px 20px 30px 60px;
    :global(.btn-fancy) {
      margin-left: 20px;
    }
    .label {
      margin-right: 30px;
    }
    :global {
      .easy-select {
        margin-right: 30px;
        flex-shrink: 0;
        flex-basis: 150px;

        .text {
          line-height: 40px;
        }
      }
      .easy-textfield.search {
        margin-right: 30px;
        width: 230px;
      }
    }
    .sortable {
      cursor: pointer;
    }
    .general {
      margin-bottom: 30px;

      .left {
        float: left;
      }
      .right {
        float: right;
      }
      .links {
        display: inline-block;
        margin-left: 15px;
        a {
          margin: 10px 10px;
          display: inline-block;
        }
      }
      .dateFilter {
        width: calc(100% - 510px - 50px);
        padding-right: 50px;
        height: 40px;
        float: left;
        white-space: nowrap;
        > div {
          display: inline-block;
          width: 14.2%;
          vertical-align: middle;
          color: #777;
          font-weight: 600;
          cursor: pointer;
          text-align: center;
          margin-top: 7px;
          margin-right: 4px;
          margin-left: 4px;
          > span {
            border-bottom: 2px solid transparent;
            padding: 5px 0;
          }
          &:hover {
            > span {
              border-color: #777;
            }
          }
          &.active {
            color: #000;
            cursor: default;
            > span {
              border-color: #000;
            }
          }
        }
      }
    }
    .additional {
      .item {
        width: 20%;
        display: inline-flex;
        vertical-align: top;
        height: 40px;
        text-align: center;
        align-items: center;
        .number {
          color: $blue;
          display: inline-block;
          vertical-align: top;
          font-size: 28px;
          line-height: 30px;
          font-weight: 600;
          margin-right: 15px;
        }
        .text {
          display: inline-block;
          line-height: 20px;
          text-align: left;
        }
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
        &.active .number {
          border-bottom: 2px solid $blue;
        }
      }
      :global(.easy-select) {
        max-width: calc((100% - 300px) / 5);
      }
      :global(.options-container) {
        width: calc(100% + 120px);
      }
      .monthly {
        .label {
          display: inline-block;
          width: 120px;
          white-space: nowrap;
        }
      }
      .annual {
        padding-top: 5px;
        .label {
          display: inline-block;
          width: 120px + 250px;
          max-width: calc(120px + 30px + ((100% - 300px) / 5));
        }
      }
      .planFilter {
        margin-top: 30px;
        .item {
          width: 200px;
        }
      }
    }
    &.allUsers {
      .general {
        margin: 0;
      }
    }
  }
  :global(.sticky-table) {
    height: calc(100% - 160px);
    @include breakpoint-sm {
      min-height: 400px;
    }

    &.planTable {
      height: calc(100% - 215px);
    }
    &.allUsers {
      height: calc(100% - 90px);
    }
    tbody {
      td {
        &.status {
          color: #e59900;
          &.active {
            color: #4ece54;
          }
        }
        &.lastCharge, &.nextCharge, &.status {
          white-space: pre-wrap;
        }
      }
    }
  }

  .planTable {
    @include breakpoint-sm {
      max-width: 100vw;
    }
    tbody {
      td {
        &.subscribers {
          color: $blue;
          &.hasSubs:hover {
            background-color: rgba(107, 107, 107, .1);
          }
        }
        &.creators {
          &.hasSubs {
            color: $blue;
            &:hover {
              background-color: rgba(107, 107, 107, .1);
            }
          }
        }
        &.name {
          .planNameInput {
            opacity: 0;
            pointer-events: none;
            position: absolute;
          }
          &.canCopy {
            &:hover {
              background-color: rgba(107, 107, 107, .1);
            }
          }
        }
        &.id {
          &:hover {
            background-color: rgba(107, 107, 107, .1);
          }
          input {
            width: 0;
            padding: 0;
            border: 0;
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
    td {
      &.name {
        padding-left: 1%;
      }
      &.id {
        width: 2%;
        text-align: center;
      }
      &.price {
        width: 9%;
      }
      &.perUserPrice {
        width: 13%;
        padding-right: 5%;
        text-align: right;
      }
      &.planType {
        width: 11%;
      }
      &.status {
        width: 6%;
      }
      &.subscribers {
        width: 13%;
        padding-right: 4%;
        text-align: right;
      }
      &.creators {
        width: 9%;
      }
      &.modules {
        width: 7%;
      }
      &.themes {
        text-align: right;
        width: 7%;
      }
    }
  }
  .subscriberTable {
    thead {
      td {
        &.status {
          text-align: center;
        }
      }
    }
    tbody {
      td {
        &.creators,
        &.roles,
        &.tutorials {
          color: $blue;
          &.hasRoles:hover {
            background-color: rgba(107, 107, 107, .1);
          }
          &.roles {
            text-align: right;
            padding-right: 1.5%;
          }
          &.hasTutorials:hover {
            background-color: rgba(107, 107, 107, .1);
          }
          &.tutorials {
            text-align: right;
            padding-right: 1.5%;
          }
          &.creators {
            text-align: right;
            padding-right: 1.5%;
            &:hover {
              background-color: rgba(107, 107, 107, .1);
            }
          }
          &.creatorsAllowed {
            text-align: right;
            padding-right: 1.5%;
          }
        }
        &.status {
          text-align: center;
        }
      }
    }
    tr.edited {
      td.name {
        position: relative;
        &::after {
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          right: 0;
          top: calc(50% - 8px);
          opacity: .5;
          background-image: url("../../theme/icons/edit.svg");
          background-position: center;
          background-size: auto 16px;
          background-repeat: no-repeat;
        }
      }
    }
    td {
      &.name {
        width: 17%;
        min-width: 200px;
        word-break: break-word;
      }
      &.id {
        width: 2%;
      }
      &.email {
        width: 15%;
      }
      &.planName {
        cursor: pointer;
        min-width: 150px;
      }
      &.permission {
        cursor: text;
      }
      &.sortable {
        cursor: pointer;
      }
      &.term {
        // width: 8%;
      }
      &.premiumAmount {
        // width: 12%;
      }
      &.lastCharge {
        // width: 10%;
      }
      &.nextCharge {
        // width: 10%;
      }
      &.status {
        // width: 11%;
      }
      &.clickable {
        &:hover {
          background-color: rgba(107, 107, 107, .1);
        }
      }
      &:global(.action) {
        min-width: 30px;

        .context_menu{
          width: 190px;
        }
      }
    }
  }
  .tutorialAuditTable,
  .tutorialAccessAuditTable,
  .creatorAuditTable,
  .adminAuditTable {
    thead td {
      cursor: pointer;
    }
  }
  .creatorAuditTable {
    table {
      table-layout: fixed;
    }
    tbody td.json {
      overflow: auto;
    }
  }
  .adminAuditTable {
    table {
      table-layout: fixed;
    }
    tbody td.json {
      overflow: auto;
    }
  }

  .tutorialsEmbedStatisticsTable {
    tbody tr {
      cursor: default;

      &:not(.visible) {
        display: none;
      }

      &.expandable td.embed_domain {
        cursor: pointer;
      }

      a {
        cursor: pointer;
      }
    }
  }

  .tutorialsEmbedStatisticsDetails,
  .tutorialsEmbedStatisticsTop {
    :global(.popup-content) {
      flex-grow: 1;
    }
  }
  .tutorialsEmbedStatisticsTop {
    .bulk_actions {
      padding-bottom: 20px;
      padding-left: 0;
    }
  }

  .tutorialsEmbedStatisticsDetails {
    .tutorialsEmbedStatisticsDetails_contentInner {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .sticky-table {
      height: auto;
      flex-grow: 1;
    }
  }

  .tutorialTranslationDetails {
    :global(.popup-content) {
      flex-grow: 1;
    }

    .sticky-table {
      height: 100%;
    }

    :global(.btn-success) {
      margin-right: 15px;
    }
  }

  .tutorialKeywordsDetails {
    :global(.popup-content) {
      flex-grow: 1;
    }

    .sticky-table {
      height: 100%;
    }

    :global(.btn-success) {
      margin-right: 15px;
    }
  }

  .secondaryEmailsDetails {
    :global(.popup-content) {
      flex-grow: 1;
    }

    .sticky-table {
      height: 100%;
    }

    :global(.btn-success) {
      margin-right: 15px;
    }
  }

  .notyAnalyticsDetails {
    :global(.popup-content) {
      flex-grow: 1;
    }

    .sticky-table {
      height: 100%;
    }
  }
  .featureAnalyticsDetails {
    :global(.popup-content) {
      flex-grow: 1;
    }

    .sticky-table-wrapper,
    .sticky-table {
      height: 100%;
    }
  }

  .tutorialStatsTable {
    thead tr {
      td.sortable {
        cursor: pointer;
      }
    }

    tbody tr {
      cursor: default;

      &:not(.visible) {
        display: none;
      }

      &.expandable td.live_domain {
        cursor: pointer;
      }

      a {
        cursor: pointer;
      }

      .exportDetailsCSV {
        margin-left: 20px;
      }
      &:not(:hover) {
        .exportDetailsCSV {
          visibility: hidden;
          pointer-events: none;
        }
      }
    }
  }

  .tutorialStatsDetails {
    :global(.popup-content) {
      flex-grow: 1;
    }
    .topMenu {
      .general {
        display: flex;
        flex-direction: column;

        .privacyFilter {
          @extend .dateFilter;
        }

        .dateFilter,
        .privacyFilter {
          > span {
            position: relative;
            top: 7px;
            display: inline-block;
            width: 35px;
          }
        }

        .exportCSVContainer {
          margin-top: 15px;
        }
      }
    }

    .bulk_actions {
      padding-bottom: 20px;
      padding-left: 60px;
    }

    .sticky-table-wrapper,
    .sticky-table {
      height: 100%;
    }
  }

  .userTutorials {
    .exportCSVContainer {
      padding: 20px;
    }
  }

  .defaultThemes {
    .topMenu .additional {
      color: $red;
      font-weight: 600;
      &.saving {
        .savingSpinner {
          display: block;
        }
      }
      .savingSpinner {
        display: none;
        top: 70px;
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: $blue;

        border-radius: 100%;
        animation: sk-scaleout .6s infinite ease-in-out;
        @keyframes sk-scaleout {
          0% {
            transform: scale(0);
          } 100% {
            transform: scale(1.0);
            opacity: 0;
          }
        }
      }
    }

    :global(.sticky-table) {
      tbody {
        td {
          &.name:hover, &.color.editable:hover, &.id:hover {
            background-color: rgba(107, 107, 107, .1);
            cursor: pointer;
          }
          &.color {
            .valueEdit {
              & > div { // color picker
                width: 120px;
                display: inline-block;
              }
            }
          }
          .imageDarkLabel {
            display: block;
            font-weight: normal;
            padding-top: 5px;
            input {
              margin-right: 5px;
              vertical-align: top;

            }
          }
          :global(.btn) {
            margin-left: 10px;
          }
          .img {
            max-height: 50px;
            padding: 5px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .subscribers {
      .topMenu {
        .additional {
          display: flex;
        }
        .general {
          padding-left: 0;
        }
      }
    }

    .subscribers {
      td {
        &.name {
          word-break: normal;
        }
      }
    }

    .tutorial_audit,
    .tutorial_access_audit,
    .creator_audit,
    .tutorials_embed_statistics,
    .tutorial_stats,
    .referralsPage {
      .topMenu {
        .general .dateFilter {
          width: unset;
        }
        .checkbox_label {
          padding: 0 20px;
        }
      }
    }
    .topMenu {
      padding: 20px 24px;

      :global {
        .btn-fancy {
          margin-right: 0 !important;
          margin-left: 0;
          margin-top: 10px;
        }
        .easy-select {
          margin-bottom: 10px;
          width: 100%;
        }
      }

      .additional {
        :global(.easy-select) {
          max-width: unset;
          width: 100%;
          margin-bottom: 2px;

          :global(.options-container) {
            width: calc(100% + 120px);
            left: -20px;
            max-width: 90vw;
          }
        }

        .item {
          width: unset;
          height: unset;
          margin: 0 9px;
          .text {
            font-size: 12px;
          }
        }
      }

      .general {
        display: flex;
        flex-direction: column;
        align-items: center;

        .dateFilter {
          float: unset;
          white-space: unset;
          height: unset;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding-right: 0;
          margin-top: 20px;

          > div {
            width: 25%;
            white-space: nowrap;
            height: 26px;
            text-align: left;
            font-size: 12px;
          }
        }

        .right, .left {
          float: unset;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .left {
          flex-direction: column;
          justify-content: center;

          label, input {
            margin: 0 0 10px;
            width: 100%;
          }

          input[type="checkbox"] {
            width: auto;
            margin-right: 10px;
          }
        }
      }
    }

    :global(.sticky-table) {
      tbody {
        td {
          white-space: nowrap;
        }
      }
    }
  }
}


.whitelistWidget {
  display: flex;
  flex-direction: column;

  .topMenu {
    height: auto;

    .general {
      margin-bottom: 0;
    }
  }

  .content {
    flex-grow: 1;
    padding: 0 20px 30px 60px;
  }

  ul {
    list-style-type: none;
  }

  button {
    display: inline-block;
    background: transparent;
    border: 0;
    opacity: 0.4;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 1;
    }
  }

  label {
    margin-right: 30px;
    margin-bottom: 10px;
  }
  label span {
    margin-right: 10px;
  }

  h3 {
    font-size: 20;
    font-weight: normal;
    margin-bottom: 24px;
  }

  input[type="text"] {
    width: 300px;
    padding: 5px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  input[type="checkbox"] {
    margin-top: 0;
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }

  .website {
  }
}

.siteThemes {
  [role="button"] {
    cursor: pointer;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  &_edit {
  }
}

.test_reWriteCapturedMedia {
  .mainPage {
    height: 100%;
    display: flex;
    flex-direction: column;

    .topMenu {
      padding: 10px;
      flex-shrink: 0;
    }

    .content {
      flex-grow: 1;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .header {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;

        .runBtn {
          height: 40px;
          width: 150px;
          align-items: center;
        }

        .iterations {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;

          label {
            margin-bottom: 0;
            margin-right: 10px;
          }

          input[type="range"] {
            -webkit-appearance: none;
            width: 100%;
            height: 5px;
            background: #ddd;
            border-radius: 5px;
            outline: none;
            opacity: 0.7;
            transition: opacity .2s;

            &:hover {
              opacity: 1;
            }
          }
        }

        .showManageTestDataBtn {
          margin-left: auto;
          height: 40px;
          width: 150px;
          align-items: center;
        }
      }

      .improveJustification {
        label {

        }

        .value {

        }
      }

      .configuration {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .aiProvider {
          flex-grow: 0;
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 200px;

          span {

          }

          textarea {

          }
        }

        .systemMessage {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;

          span {
            flex-grow: 0;
          }

          textarea {
            flex-grow: 1;
          }
        }
      }
    }
  }

  .manageTestData {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: white;
    padding: 20px;

    .manageTestData_main {
      height: 100%;
      display: flex;
      flex-direction: column;

      .header {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }

      .testDataList {
        margin-top: 30px;
        border: 1px solid #ccc;

        .testDataListItem {
          display: flex;
          flex-direction: column;

          .header {
            display: flex;
            flex-direction: row;
            gap: 20px;
            align-items: center;
            padding: 10px;

            .actions {
              margin-left: auto;
              display: flex;
              flex-direction: row;
              gap: 15px;
            }
          }

          .body {
            padding: 10px;
          }
        }
      }
    }

    .manageTestDataItem {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      background: white;

      .testDataItem {
        .transcript {

        }

        .stepsList {
          padding: 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .addedStep {
            background: green;
            opacity: 0.2;
            padding: 20px;

            .justification {
              margin-left: 15px;
            }

            &.enabled {
              opacity: 1;
            }
          }

          .step {
            background: rgba(0, 0, 0, 0.2);
            padding: 20px;

            .inputIndex {
              display: flex;
              flex-direction: row;
              gap: 10px;

              .name {
                font-weight: 600;
              }

              .value {
                font-weight: 400;
              }
            }

            .description {
              display: flex;
              flex-direction: row;
              gap: 10px;

              .name {
                font-weight: 600;
              }

              .value {
                font-weight: 400;
              }
            }

            .moved {
              display: flex;
              flex-direction: row;
              gap: 10px;

              .name {
                font-weight: 600;
              }

              .value {
                font-weight: 400;
              }

              .justification {
                margin-left: 15px;
              }
            }

            .removed {
              display: flex;
              flex-direction: row;
              gap: 10px;

              .name {
                font-weight: 600;
              }

              .value {
                font-weight: 400;
              }

              .justification {
                margin-left: 15px;
              }
            }

            &.removedEnabled {
              background: red;
            }
          }
        }
      }
    }
  }

}