.teamSettings {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  padding-bottom: 24px;
  width: 100%;
  height: 100%;
  color: black;

  &.extensionEmbed {
    padding-top: 24px;

    .teamSettings_header {
      padding: 0 24px;
    }

    .teamSettings_title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    .teamSettings_tabs {
      .teamSettings_tab {
        height: 40px;
      }
    }
  }

  .teamSettings_header {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }

  .teamSettings_title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .teamSettings_tabs {
    display: flex;

    .teamSettings_tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 1;
      height: 56px;
      font-size: 15px;
      line-height: 20px;
      border: 1px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;

      &.active {
        border-color: rgba(0, 0, 0, 0.2);
        border-bottom-color: transparent;
        cursor: default;
      }
      

      &:not(.active):hover {
        background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
      }
    }
  }

  .teamSettings_content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}