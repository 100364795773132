.customization {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background: white;
  z-index: 24; // above category list

  .customization__sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 240px;
    height: 100%;
    overflow-y: auto;

    .customization__sidebar_footer {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      padding: 16px 24px;
      position: sticky;
      bottom: 0;
      z-index: 1;
      background: white;

      button {
        min-width: 100%;
      }
    }
  }

  .customization__list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .customization__item {
      display: flex;
      align-items: center;
      column-gap: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      height: 56px;
      padding: 0 16px;

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }

      .customization__item_checkbox {
        white-space: nowrap;
        position: relative;
        cursor: pointer;
        user-select: none;
        color: black;
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        padding-left: 32px;

        input[type='checkbox']:checked ~ span::after {
          background-image: url('~assets/img/icons/checkmark.svg');
          background-size: 76%;
        }

        input[type='checkbox'] {
          display: none;
        }

        span:after {
          position: absolute;
          cursor: pointer;
          left: 0;
          top: 2px;
          width: 16px;
          height: 16px;
          content: '';
          background-color: white;
          background-repeat: no-repeat;
          background-position: center;
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .customization__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: #f4f4f4;
  }

  .customization__grid {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 8px;
    overflow: hidden;
    padding: 8px;
  }

  .customization__grid_row {
    display: flex;
    gap: 8px;
    flex: 1;
  }

  .customization__grid_item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    cursor: grab;
    flex: 1;
    border: 1px solid transparent;

    h3 {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: inherit;
      margin: 0;
    }

    &.dragging {
      border-color: var(--site-theme-color);
      cursor: grabbing;
      box-shadow: none;

      h3 {
        color: var(--site-theme-color);
      }
    }

    &.drag-target {
      background-color: #{'rgba(var(--site-theme-color-rgb), 0.05)'};

      h3 {
        display: none;
      }
    }
  }

  .customization__content_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    column-gap: 16px;
    height: 56px;

    button {
      height: 40px;
      padding: 0 24px;
      width: auto;
      min-width: auto;
    }
  }
}
