@import '~theme/modules/variables';
@import '~theme/mixins';

.videoSection {
  background: url('~assets/img/redesign2021/global/gradient_grey_bottom.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  height: calc(100vh - #{$topNavBarHeight});
  min-height: 600px;

  &.isLogged {
    height: calc(100vh - #{$topNavBarHeightLogged});
    padding-top: 44px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    height: 100%;
    margin: 0 auto;
  }

  .titleWrapper {
    width: 614px;
    margin-top: 102px;
    margin-left: 64px;
    margin-bottom: 30px;
    display: flex;
    flex-grow: 1;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000;
    margin: 0;
  }

  .content {
    padding-bottom: 78px;
    padding-right: 65px;
    display: flex;
    align-items: flex-end;
  }

  .leftContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .buildTutorialBtn {
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-bottom: 77px;

    &:hover {
      color: #fff;
      background-color: var(--site-theme-color);
    }
  }

  .arrowNext {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-bottom: 33px;

    p {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin-bottom: 12px;
    }

    &:hover {
      p {
        color: var(--site-theme-color);
      }

      svg path {
        stroke: var(--site-theme-color);
      }
    }
  }

  .videoContainer {
    width: 674px;
    height: 379px;
    position: relative;
    box-sizing: content-box;
  }

  .videoPlaceholder {
    width: 100%;
    height: 100%;
    cursor: pointer;

    .playIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;

      svg path {
        transition: fill 0.3s ease;
      }
    }

    img {
      width: 100%;
      height: 100%;
    }

    .duration {
      position: absolute;
      right: 16px;
      bottom: 16px;
      background: rgba($color: #000000, $alpha: 0.3);
      font-weight: 600;
      font-size: 21px;
      line-height: 116%;
      color: #ffffff;
      padding: 0 4px;
    }

    &:hover {
      .playIcon svg path {
        fill: var(--site-theme-color);
      }
    }
  }

  .videoPopup {
    display: none;
    position: fixed;
    z-index: 40;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 86px 0;
    background-color: #000;
    z-index: #{$embedButtonZIndex + 1};

    &.isShowVideo {
      display: block;
    }

    .videoContent {
      height: 100%;
    }

    .closeBtn {
      position: absolute;
      right: 64px;
      top: 24px;
      cursor: pointer;
    }
  }
}

@include breakpoint-sm {
  .videoSection {
    background: unset;
    height: calc(100vh - #{$topNavBarHeightMobile});

    .titleWrapper {
      background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_bottom.png');
      background-position: bottom center;
      background-repeat: no-repeat;
      width: 100%;
      height: 331px;
      margin: 0;
      padding: 150px 24px 87px;
      flex-grow: unset;
    }

    .title {
      font-size: 21px;
    }

    .content {
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    .buildTutorialBtn {
      display: none;
    }

    .arrowNext {
      margin-top: 19px;
      margin-bottom: 21px;

      p {
        font-size: 13px;
        margin-bottom: 10px;
      }
    }

    .videoContainer {
      width: 100%;
      height: 210px;
      order: -1;
    }

    .playIcon svg {
      width: 40px;
      height: 40px;
    }

    .videoPlaceholder .duration {
      right: 9px;
      bottom: 9px;
      font-size: 11.684px;
      padding: 0 2px;
    }

    .videoPopup {
      padding: 0;

      .closeBtn {
        right: 24px;
      }
    }

    .videoContent {
      display: flex;

      iframe {
        max-height: 211px;
        margin: auto 0;
      }
    }
  }
}

@media only screen and (min-width: 3000px) {
  .videoSection {
    .contentWrapper {
      max-width: 100%;
    }

    .titleWrapper {
      width: 1726px;
      margin-top: 448px;
      margin-left: 185px;
    }

    .title {
      font-size: 90px;
    }

    .content {
      padding-bottom: 210px;
      padding-right: 190px;
    }

    .videoContainer {
      width: 1895px;
      height: 1065px;
    }
  }
}
