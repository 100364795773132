@import '~theme/guidelines_variables';
@import '~components/HelpCenterSettings/HelpCenterSettings';
@import '~theme/mixins';

.connect_container {
  width: 100%;
  max-width: none;
  font-family: $fontBaseV2;
}

.helperText {
  font-family: $fontBaseV2;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  padding: 10px;
  background-color: #F2F2F2;
  margin-top: 10px;
}

.error {
  color: var(--site-theme-color);
  font-size: 15px;
  line-height: 20px;
  margin-top: 8px;
}

.submit_section {
  margin: 30px 0;
  width: 240px;

  button {
    width: auto;
    min-width: 240px;
  }
}