@import '~theme/modules/variables';
@import '~theme/mixins';

.footerSection {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 195px;
  padding-bottom: 142px;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    width: 556px;
    margin: 0;
  }

  .authWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 64px;
    padding-right: 160px;
  }

  .signUpBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-bottom: 22px;

    &:hover {
      color: #fff;
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .footerSection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100%;
    padding: 162px 24px 237px;

    .title {
      font-size: 21px;
      width: 100%;
    }

    .authWrapper {
      flex-direction: column;
      padding: 0;
    }

    .signUpBtn {
      width: 295px;
      font-size: 13px;
      margin-top: 58px;
      margin-bottom: 0;
    }
  }
}
