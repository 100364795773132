@import '~theme/mixins';
@import '~theme/modules/variables';

.leaderboard_product_modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: white;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    padding: 0 16px;
    cursor: pointer;
    position: relative;

    span {
      @include fontExtension;
    }

    svg {
      position: absolute;
      right: 21px;
    }
  }

  &__search {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: none;
      outline: none;
      width: 100%;
      @include fontHelpCenter;
      caret-color: var(--marker-color);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;

    &_header {
      display: flex;
      flex-shrink: 0;
      height: 80px;

      span {
        display: flex;
        align-items: center;
        flex: 1;
        @include fontHelpCenter;
        opacity: 0.4;
      }
    }

    &_body {
      display: flex;
      flex-direction: column;
    }

    &_row {
      display: flex;
      flex-shrink: 0;
      height: 80px;

      > div {
        display: flex;
        align-items: center;
        flex: 1;
        @include fontHelpCenter;

        &.username {
          span {
            min-width: 100px;
            margin-left: 24px;
          }
        }
      }
    }
  }

  &__warning_text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    @include fontHelpCenter;
  }
}
