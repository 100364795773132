.account {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  overflow: hidden;

  .account__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: white;
    overflow-y: auto;

    &.disabledScroll {
      overflow-y: hidden;
    }
  }
}
