@import '~theme/modules/variables';
@import '~theme/mixins';
@import 'base';

.tabHead {
  display: flex;
  gap: 30px;
  padding: 16px 0;
  background-color: #FFF;
  position: sticky;
  top: 80px;
  z-index: 1;
  
  button {
    background-color: transparent;
    border: 0 none;
    padding: 0;
    font-size: 15px;
    font-weight: 600;

    &:hover, &.selected {
      color: var(--site-theme-color);
    }
  }
}

.info {
  position: fixed;
  top: 175px;
  left: 0;
  width: 100%;
  max-width: 1500px;
  z-index: 4;

  .inner {
    width: 300px;
    position: absolute;
    top: 0;
    right: 40px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    button {
      max-width: 300px;
      z-index: 1;
    }
  }

  @include breakpoint-sm {
    position: sticky;
    right: auto;
    left: 0;
    width: 100%;
    top: 155px;
    padding-bottom: 24px;
    background: white;

    .inner {
      position: static;
      width: 100%;
    }

    .note {
      order: -1;
    }
  }
}

.template {
  display: flex;
  margin-top: 30px;
  padding-bottom: 65px;
  min-height: unset !important;
  font-family: $fontBaseV2;

  @include breakpoint-sm {
    margin-top: 24px;
  }

  .contentWrapper {
    flex-grow: 1;
  }

  .header {
    display: flex;
    justify-content: space-between;
    background-color: #FFF;
    position: sticky;
    top: 0;
    z-index: 3;
    padding: 10px 0;

    @include breakpoint-sm {
      flex-direction: column;
    }
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
    width: 305px;
  
    &:focus .label {
      color: #000000;
    }

    .label {
      font-weight: normal;
      color: rgba(0, 0, 0, 0.4);
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 8px;
    }

    @include breakpoint-sm {
      width: 100%;
    }
  }

  .content {
    margin-top: 32px;
    position: relative;
  }

  .groupLabel {
    color: #000000;
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    margin: 0;
  }


  .groupTable {
    border: none;
    width: 100%;
    max-width: 960px;
    margin-top: 11px;

    thead  {
      tr {
        height: 55px;
      }

      td {
        font-weight: 600;
        font-size: 21px;
        line-height: 136%;
        color: #000000;
        vertical-align: top;
      }
    }

    tbody {
      tr {
        height: 65px;

        &.isFocus {
          td {
            position: relative;
            z-index: 2;
          }
        }
      }

      td {
        width: 50%;
        height: 27px;
        padding-bottom: 7px;
        vertical-align: top;
        font-size: 15px;
        line-height: 136%;
        color: #000;

        @include breakpoint-sm {
          height: 100%;
          padding-bottom: 15px;
        }
      }
    }
  }

  .valueEditor {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    white-space: pre-wrap;
    flex-wrap: wrap;

    .editable {
      cursor: text;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
      padding-bottom: 7px;
      font-size: 15px;
      line-height: 136%;
      color: #000;

      &:focus, &:hover {
        outline: none;
        border-color: #000000;
      }

      &:last-child {
        flex-grow: 1;
      }
    }

    .readOnly {
      color: var(--site-theme-color);
      cursor: default;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 11px;

    @include breakpoint-sm {
      padding-bottom: 40px;
    }
  }

  .saveButton {
    width: 301px;
    height: 48px;

    @include breakpoint-sm {
      width: 100%;
    }
  }

  .note {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.05);
    padding: 16px;
    color: #000000;
    font-size: 15px;
    line-height: 136%;

    &.hidden {
      display: none;
    }

    span {
      color: var(--site-theme-color);
    }

    a {
      font-weight: 600;
      cursor: pointer;
    }

    // @include breakpoint-sm {
    //   display: none;
    // }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
    display: none;

    &.block {
      display: block;
    }
  }
}
