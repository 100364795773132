.root {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;

  svg {
    color: inherit;

    [stroke] {
      color: inherit;
      stroke: currentColor;
    }
    [fill] {
      color: inherit;
      fill: currentColor;
    }
  }

  video {
    object-fit: fill;
    max-width: 100%;
    max-height: 100%;
  }

  &.isHideTimeframe {
    .timeFrame, .timeTool, .duration {
      display: none;
    }
  }

  &:not(:hover) {
    .controls {
      display: none;;
    }
  }
}
// #30F
.timeFrame, .timeTool{
  position: absolute;
  left: 0;
  bottom: 0;
  height: 56px;
  width: 100%;
  background-color: #f4f4f4;
  z-index: 1;

  &.hide {
    display: none;
  }
}

.timeFrame {
  overflow: hidden;
}

.timeTool {
  z-index: 2;
  background-color: transparent;
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(244, 244, 244, 0.45);
  color: #000;
  z-index: 3;
}

.timeSelect {
  background-color: rgba(246, 184, 222, 0.4);

  .inner {
    width: 100%;
    height: 100%;
    border: 2px solid var(--site-theme-color);
    position: relative;
    z-index: -1;

    .start, .end {
      position: absolute;
      top: -17px;
      font-size: 8px;
      background-color: #dedede;
      color: #000;
      padding: 2px;
    }

    .start {
      left: 0;
    }

    .end {
      right: 0;
      text-align: right;
    }
  }

  &:not(:hover) .inner {
    .start, .end {
      display: none;
    }
  }
}

.controls {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.playPauseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f4f4f4;
  border: 0 none;

  &:hover {
    color: var(--site-theme-color);
  }
}

.duration {
  position: absolute;
  right: 0;
  margin-right: 15px;
  bottom: 68px;
  background-color: #000;
  color: #FFF;
}
