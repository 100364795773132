@import '~theme/modules/variables';
@import '~theme/mixins';

.makeEditsButton {
  display: flex;
  height: 100vh;
  min-height: 600px;

  .makeEditsButton__block {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .makeEditsButton__header {
    .makeEditsButton__logo {
      padding: 24px 24px 40px;
    }
  }

  .makeEditsButton__content {
    flex-grow: 1;
    padding-top: 40px;
    padding-bottom: 24px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 40px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-bottom: 64px;

      a {
        text-decoration: underline;
      }
    }

    button {
      width: 320px;
      height: 56px;
      display: flex;
      align-items: center;
      column-gap: 16px;
      margin: 0 auto 64px;

      svg path {
        &[fill] {
          fill: currentColor;
        }
        &[stroke] {
          stroke: currentColor;
        }
      }
    }
  }

  .makeEditsButton__footer {
    display: flex;
    justify-content: center;
    padding: 40px 24px;
  }

  .makeEditsButton__iframe {
    width: 100%;
    height: 100%;
    background: #f4f4f4;

    iframe {
      height: calc(100% - 5px);
    }
  }

  .makeEditsButton__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 40px;
    padding-bottom: 24px;
    overflow-y: auto;
  }

  .makeEditsButton__form_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 40px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-bottom: 64px;

      a {
        text-decoration: underline;
      }
    }

    .makeEditsButton__form_fields {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    .makeEditsButton__form_group {
      display: flex;
      align-items: center;
      gap: 15px;

      .makeEditsButton__form_label {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: black;
        margin: 0;
        flex-basis: 190px;
      }

      .makeEditsButton__form_input {
        width: 100%;
        height: 56px;
        padding: 0;
        font-size: 15px;
        line-height: 20px;
        color: black;
        background: white;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: none;
        margin: 0;
      }

      :global(.react-selectize.root-node.default) {
        width: 100%;
        max-width: 280px;

        :global(.react-selectize-control) {
          :global(.react-selectize-toggle-button-container) {
            width: 56px;
            height: 56px;
          }

          :global(.react-selectize-placeholder) {
            font-size: 15px;
            line-height: 56px;
            color: rgba(0, 0, 0, 0.4);
          }

          :global(.react-selectize-search-field-and-selected-values) {
            flex-wrap: nowrap;
            width: 100%;
            overflow: hidden;
            min-height: 56px;

            :global(.value-wrapper) {
              width: 100%;
              position: relative;
            }

            :global(.resizable-input) {
              position: absolute;
              left: 0;
              height: 56px;
              font-size: 15px;
              line-height: 20px;
              color: black;
            }

            :global(.simple-value) {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              font-weight: 400;
            }
          }
        }

        &:global(.open) :global(.dropdown-menu) {
          width: calc(100% + 24px);
          margin-left: -12px;
          max-height: 250px;

          :global(.simple-option) {
            padding: 10px 11px;
            word-wrap: break-word;
            position: relative;

            &:global(.not-selectable) {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }
      }

      .makeEditsButton__form_color_picker {
        display: flex;
        align-items: center;
        height: 56px;

        .makeEditsButton__form_color_picker_edit {
          border: none;
        }

        .makeEditsButton__form_color_picker_swatch {
          border: 1px solid black;

          > div {
            padding: 0;
          }
        }

        .makeEditsButton__form_color_picker_popover {
          margin-top: 0;
          width: 400px;
          height: 220px;
          background: white;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          left: 50%;
          bottom: 100%;
          top: unset;
          transform: translate(-50%, -10px);
        }

        .makeEditsButton__form_color_picker_buttons {
          padding: 16px;
          top: unset;
          bottom: 0;

          :global(.btn-fancy-filled):hover {
            background: var(--site-theme-color) !important;
          }

          :global(.btn-fancy-cancel):hover {
            background: white !important;
            color: var(--site-theme-color) !important;
          }
        }
      }
    }

    .makeEditsButton__form_submit {
      width: 320px;
      height: 56px;
      margin: 0 auto;
    }

    .makeEditsButton__form_note {
      font-size: 15px;
      line-height: 20px;
      color: black;
      text-align: center;
      margin: 0;
      margin-top: 24px;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.group {
  margin-top: 24px;
}

:global(#onboarding_theme_tooltip) {
  max-width: max-content;
}
