@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/Account/partials';

.oidcSettingContainer {
  display: flex;
  flex-direction: column;

  padding: 40px 40px 40px 0;

  .header {
    font-weight: 600;
    line-height: 24px;
    font-size: 20px;
    color: black;
    margin: 0 0 40px;
  }

  .oidcSettingContent {
    display: flex;
    gap: 10px;

    .buttonContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      margin-top: 56px;

      button {
        width: 320px;
        font-size: 18px;
      }
    }
  }

  .tabHeaders {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: black;
    display: flex;
    gap: 30px;
    padding: 16px 0;

    &:before, &:after {
      content: none;
    }

    .tab {
      margin: 0;
      padding: 0;

      &> a {
        margin: 0;
        padding: 0;

        &:hover, &:visited, &:active, &:focus, &:focus-visible, &:focus-within {
          background: none;
          outline: none;
        }
      }
    }

    .active {
      &> a {
        color: var(--site-theme-color);
      }
    }
  }

  .tabContainer {
    max-width: 900px;
    flex: 1;
  }

  .tabContentContaner {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 32px;

    @include breakpoint-sm {
      width: 100%;
      margin-right: 0;
      margin-top: 24px;
    }

    .formGroup {
      display: flex;

      @include breakpoint-sm {
        margin-bottom: 17px;
        flex-direction: column;
      }
    }

    .inputWrapper {
      flex-grow: 1;
      flex-shrink: 0;
      width: 100%;
      max-width: 430px;
      margin-right: 40px;

      @include breakpoint-sm {
        max-width: 100%;
        margin-right: 0;
      }

      .rowInputWrapper {
        display: flex;
        gap: 20px;

        .selectRow {
          width: 300px;
          &:global(> .react-selectize-control) {
            height: 100%;
          }
        }
      }

    }

    .label {
      font-family: $fontBaseV2;
      font-weight: 400;
      line-height: 18px;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.4);
      margin: 0;

      > i {
        color: var(--site-theme-color);
        font-style: normal;
        margin-left: 4px;
      }
    }

    .input {
      width: 100%;
      background-color: #fff;
      color: black;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
      border-left: none;
      border-radius: 0;
      padding: 0;
      font-family: $fontBaseV2;
      height: 40px;
      caret-color: var(--site-theme-color);

      &:focus {
        border-bottom: 1px solid #000;
      }
    }

    .easyCheckboxWrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      min-height: 34px;
      color: black;

      input[type='checkbox'] {
        margin: 0 10px 0 0;

        &::before {
          background: white;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 0;
        }
      }

      span {
        font-size: 15px;
      }
    }

    .helpblock {
      font-family: $fontBaseV2;
      line-height: 20px;
      font-size: 15px;
      color: #000000;
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      align-self: flex-start;
      width: 100%;

      p {
        margin: 0;
      }

      @include breakpoint-sm {
        display: none;
      }
    }

    .copyContainer {
      display: flex;
      align-items: center;
      position: relative;

      .input {
        padding-right: 40px;
      }

      &.generateConatiner {
        .input {
          padding-right: 60px;
        }
      }
    }

    .linkContainer {
      position: absolute;
      right: 0;
      bottom: 4px;
      width: 62px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .fadeOut {
        background: linear-gradient(to left, #ffffff 13.81%, rgba(255, 255, 255, 0) 99.61%);
        width: 31px;
        height: 100%;
      }

      .link {
        margin-right: 10px;
        color: #999999;
        font-size: 13px;
        line-height: 18px;
        transition: color 0.3s ease;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          color: #000;
        }
      }
    }

    .ruleContentWrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .ruleHeader {
        display: flex;
        justify-content: space-between;

        .addNewButton {
          width: 200px;
        }
      }

      .ruleFormGroup {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;

        .closeButton {
          border: 2px solid rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          position: absolute;
          right: -14px;
          width: 28px;
          height: 28px;
          top: -14px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .ruleContentContainer {
    margin-top: 16px;
    margin-bottom: 32px;
  }
}
