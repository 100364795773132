@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/Account/partials';

.personal {
  display: flex;
  flex-direction: row;
  padding-top: 40px;

  @include breakpoint-sm {
    flex-direction: column;
  }

  .flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include breakpoint-sm {
      align-items: center;
    }
  }

  .avatarContainer {
    flex-direction: column;
    margin-right: 60px;
    align-items: center;

    .removeAvatar {
      margin-top: 5px;
    }

    @include breakpoint-sm {
      margin-right: 0;
    }
  }

  @include breakpoint-sm {
    width: 100%;
    padding: 0;
  }

  .linksWrapper {
    padding: 100px 0;
    display: flex;

    > a {
      font-size: 13px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #000000;
      margin-right: 10px;
    }
  }

  .label {
    //letter-spacing: 1px;
    font-family: $fontBaseV2;
    font-weight: 300;
    line-height: 18px;
    font-size: 13px;
    color: #000000;
    opacity: 0.4;
    margin-bottom: 8px;
  }

  :global(.text-field) {
    @extend .text_field;
  }

  .input {
    width: 100%;
    background-color: #fff;
    color: #000000;
    border-left: none;
    border-radius: 0;
    padding: 0;
    font-family: $fontBaseV2;
    height: 34px;
    outline: none;
    border: none;
    border-bottom: 1px solid #e0e0e0;

    &:focus {
      border-bottom: 1px solid var(--site-theme-color);
    }
  }

  .formGroup {
    margin-bottom: 24px;

    &.checkboxWrapper {
      padding-top: 14px;
      margin-bottom: 30px;
    }

    @include breakpoint-sm {
      margin-bottom: 17px;
    }

    &:has(.inlineGroup) {
      display: flex;
      flex-direction: column;
    }

    .inlineGroup {
      display: flex;
      gap: 20px;
      align-items: flex-end;
    }
  }

  @include breakpoint-sm {
    .linksWrapper {
      margin-top: 28px;

      a:first-child:not(:last-child) {
        margin-bottom: 20px;
      }

      a:last-child {
        margin-bottom: 40px;
      }
    }
  }

  .export {
    text-align: left;
    margin-bottom: 20px;
    width: max-content;

    &:last-child {
      margin-bottom: 0;
    }

    .blue_link {
      width: 100%;
      height: 100%;
      display: inline;
      text-align: left;
      color: #000000;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
      transition: color 0.3s ease;
      position: relative;
      padding-left: 38px;

      svg {
        position: absolute;
        margin: 0;
        left: 0;
        top: 0;

        > {
          path,
          circle {
            transition: all 0.3s ease;
          }
        }
      }

      &:hover {
        text-decoration: none;
        color: var(--site-theme-color);

        &.link_export {
          svg > {
            path {
              fill: var(--site-theme-color);
            }
          }
        }
        &.link_eye_closed {
          svg > {
            path {
              stroke: var(--site-theme-color);
            }
            circle {
              fill: var(--site-theme-color);
            }
          }
        }
        &.link_change_password {
          svg > {
            path {
              fill: var(--site-theme-color);
            }
          }
        }
        &.link_manage_2FA {
          svg > {
            path {
              fill: var(--site-theme-color);
            }
          }
        }
        &.link_delete_account {
          svg > {
            circle {
              fill: var(--site-theme-color);
            }
            path:nth-child(2) {
              stroke: var(--site-theme-color);
            }
            path:nth-child(3) {
              fill: var(--site-theme-color);
            }
          }
        }
      }
    }
  }
}

.account_tab {
  flex-direction: column;
}

.exportLinkGroup {
  display: none;

  &.account_setting {
    @include breakpoint-sm {
      display: block;
      margin-top: 50px;

      .export {
        margin-bottom: 10px;
      }
    }
  }

  @include breakpoint-sm {
    &.mobile {
      display: block;
      margin-top: 24px;
    }
  }

  @include breakpoint-md-plus {
    &.desktop {
      display: block;
      margin-top: 40px;
    }
  }
}

.formContainer {
  display: block;
  width: 500px;
  padding-top: 10px;
  margin-right: 100px;

  @include breakpoint-sm {
    width: 100%;
    margin-right: 0;
  }
}

.isActive {
  padding: 0 0 10px 0;
  font-size: 16px;
  float: left;
  text-align: left;
  width: 100%;
  font-weight: 300;
  margin-bottom: 10px;

  .resend {
    @extend .link-underline;
    float: right;
    color: #000;
    cursor: pointer;
    text-decoration: none;
    font-weight: normal;
    position: relative;
    font-size: 14px;
  }
}

@include breakpoint-sm {
  :global(body.easy-popup-open) {
    .formGroup {
      display: none;
    }

    .linksWrapper {
      margin-top: 0;

      a {
        display: none;
      }
    }
  }
}

.overlay {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 1em;
  font-weight: 300;
  line-height: 74px;
  visibility: hidden;
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  height: 72px;
  width: 72px;
}

.avatar {
  display: inline-block;

  > div {
    padding: 0;
  }

  img {
    cursor: pointer;
    height: 72px;
    width: 72px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &:hover .overlay {
    visibility: visible;
  }

  &:hover + .label {
    color: $labelhover;
  }
}

.checkbox_label {
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type='checkbox'] {
    margin: 0;
    margin-right: 15px;
    position: relative;
    width: 16px;
    height: 16px;
    &::before,
    &::after {
      cursor: pointer;
      content: '';
      position: absolute;
    }
    &::before {
      border: 1px solid rgba($color: #000000, $alpha: 0.2);
      border-radius: 0;
      background-color: #fff;
      width: 100%;
      height: 100%;
    }
    &::after {
      height: 100%;
      width: 100%;
      background-image: url('~assets/img/icons/checkmark.svg');
      background-repeat: no-repeat;
      background-position: center;
      transition: background-size 0.1s;
      background-size: 0;
    }

    &:checked {
      &::after {
        background-size: 76%;
      }
    }
  }

  span {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    font-weight: normal;
  }
}

.downloadProgress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: #{$topNavBarZIndex + 1};
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  .downloadProgress_inner {
    width: 430px;
    background: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
  }

  .downloadProgress_inner__message {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    color: black;
  }
}
