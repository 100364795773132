@import '~theme/mixins';
@import '~theme/link_underline';
@import '~theme/modules/variables';
@import '~theme/modules/spinnerAnimation';
@import '~theme/guidelines.scss';
@import '~components/AccountV2/partials';

.learnerSettings {
  display: flex;
  padding-bottom: 40px;

  .contentWrapper {
    flex-grow: 1;
  }

  .content {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 900px;
  }

  .loading {
    padding: 40px 0;
  }

  .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding: 40px 0;

    .formGroup {
      display: flex;
      gap: 40px;

      .formInput {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 460px;

        label {
          display: inline-block;
          max-width: 100%;
          font-size: 13px;
          line-height: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 0;
        }

        .select {

        }
      }
    }
  }
}