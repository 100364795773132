.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;

  .wrapper {
    position: relative;
    top: 40%;
    transform: translateY(-50%);
  }

  .spinner {
    position: relative;
    width: 29px;
    height: 29px;
    margin: 0 auto;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('~assets/img/animations/iorad_animated_logo.gif');
  }

  span {
    font-family: Libre Franklin;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
