@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  overflow-x: hidden;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    background: #2891be;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 56px 64px 45px;
  }

  .titleContent {
    @include ioradFont;
  }

  .empower {
    max-width: $maxWidthContent;
    margin: 77px auto 0;
    padding: 0 64px;
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: black;
      margin: 0;
      margin-bottom: 23px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      max-width: 622px;
      margin: 0;
    }
  }

  .chapters {
    margin-bottom: 58px;
    padding-top: 69px;
    padding-left: 64px;
    padding-right: 143px;
    display: flex;

    @media screen and (min-width: 1366px) {
      margin-left: calc((100% - 1366px) / 2);
    }
  }

  .chapterTabs {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
  }

  .chapterTab {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
    transition: color 0.3s ease;
    cursor: pointer;
    flex-shrink: 0;

    &:hover,
    &.actived {
      color: var(--site-theme-color);
    }
  }

  .chapterContentWrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 22px 0 17px 20px;
    margin-left: 78px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background: $backgroundTiles;
      z-index: -1;
    }
  }

  .chapterContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    iframe {
      width: 827px;
      border: none;
    }

    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }

    p {
      max-width: 729px;
      margin: 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;

      a {
        font-weight: 600;

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .cstl {
    .cstl_desc {
      margin-top: 44px;
    }

    .cstl_link {
      margin-top: 8px;
    }
  }

  .sd {
    .sd_desc {
      margin-top: 45px;
    }

    .sd_tryLiveMode {
      margin-top: 67px;
      margin-bottom: 17px;
    }

    .sd_iframe {
      height: 464px;
    }
  }

  .sfaQA {
    .sfaQA_desc {
      margin-top: 45px;
    }

    .sfaQA_example_title {
      margin-top: 67px;
      margin-bottom: 17px;
    }

    .sfaQA_example {
      display: flex;
      flex-direction: column;
      row-gap: 45px;
      max-width: 100%;
    }

    .sfaQA_iframe {
      height: 464px;
    }
  }

  .se {
    .se_desc {
      margin-top: 45px;
    }
  }

  .fsthc {
    .fsthc_iframe {
      height: 464px;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .fsthc_desc {
      margin-top: 45px;
    }
  }

  .chapterNavigations {
    display: flex;
    justify-content: space-between;
    margin-top: 83px;
  }

  .chapterNavigation {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: 19px;

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.4;
    }

    &:hover {
      svg path {
        stroke: var(--site-theme-color);
      }

      span {
        color: var(--site-theme-color);
      }
    }

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 170px 158px 140px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .title {
      padding: 70px 24px 49px;
    }

    .empower {
      max-width: 100%;
      margin-top: 93px;
      padding: 0 24px;

      h3 {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        max-width: 100%;
      }
    }

    .chapters {
      margin-bottom: 43px;
      padding: 0;
      padding-top: 38px;
      flex-direction: column;
      position: relative;
    }

    .chapterTabs {
      align-items: flex-end;
      padding: 0 24px 25px;
      position: absolute;
      right: 0;
      top: 38px;
      z-index: 2;
      width: 100%;
      background: white;

      &.isShowDropdown {
        .chapterTab {
          display: block;

          &.actived svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    .chapterTab {
      display: none;

      &.actived {
        display: block;
        color: black;
        order: -1;

        svg {
          margin-left: 19px;
        }
      }
    }

    .chapterContentWrapper {
      margin: 0;
      margin-top: 45px;
      padding: 53px 24px 25px;
      background: $backgroundTiles;

      &:after {
        content: unset;
      }
    }

    .chapterContent {
      p {
        font-size: 13px;
        line-height: 18px;
        max-width: 100%;
      }

      iframe {
        width: 100%;
      }

      .cstl {
        iframe {
          height: 164px;
        }
        .cstl_link {
          margin-top: 17px;
        }
      }

      .sd {
        iframe {
          height: 164px;
        }
        .sd_tryLiveMode {
          margin-top: 56px;
          margin-bottom: 21px;
        }
        .sd_iframe {
          height: 584px;
        }
      }

      .sfaQA {
        iframe {
          height: 164px;
        }
        .sfaQA_example_title {
          margin-top: 56px;
          margin-bottom: 21px;
        }
        .sfaQA_example {
          row-gap: 68px;
        }
        .sfaQA_iframe {
          height: 584px;
        }
      }

      .fsthc {
        .fsthc_iframe {
          height: 584px;
        }
      }

      .se {
        iframe {
          height: 164px;
        }
      }
    }

    .chapterNavigations {
      margin-top: 60px;
    }

    .chapterNavigation {
      span {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .learnMoreBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
