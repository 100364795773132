@import '../../variables';

.aiassistant {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .aiassistant__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    position: relative;
    // background-color: rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.2);

    h4 {
      font-size: 21px;
      line-height: 24px;
      margin: 0;
      margin-bottom: 24px;
    }

    p {
      @include defaultFont;
      margin: 0;
    }

    .aiassistant__header_close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: $defaultHeightBtn;
      width: $defaultHeightBtn;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .aiassistant__content {
    display: flex;
    flex-direction: column;
    background: $defaultColor;
    height: 100%;
    overflow: hidden;
    padding: 16px;
  }

  .aiassistant__content_tabs {
    > div:first-child {
      height: $defaultHeightBtn;
      border: 1px solid white;

      > div > span {
        font-weight: 400 !important;
        order: 1;
      }
    }
  }
}

.settings {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .settings__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    overflow-y: scroll;
    @include defaultFont;
  }

  .settings__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .settings__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow-y: scroll;
    background: white;

    .settings__content_header {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      padding: 24px 0;
      width: 100%;
      max-width: 600px;
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;

      .actived_text {
        padding: 16px;
        padding-right: 115px;
        background: #f4f4f4;
        position: relative;

        span {
          @include defaultFont;

          a {
            text-decoration: underline;

            &.configure_link {
              position: absolute;
              top: 16px;
              right: 16px;
              text-decoration: none;
            }
          }
        }
      }

      .toggle_enable {
        display: flex;
        align-items: center;
        column-gap: 24px;
        padding: 16px 0;
      }
    }

    .settings__content_body {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 600px;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }

    .settings__content_checkbox {
      height: 56px;
      flex-shrink: 0;
    }
  }
}

.identity {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .identity__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .identity__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow-y: scroll;
    background: white;
  }

  .identity__content_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    @include defaultFont;
  }

  .identity__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: $defaultWidthContent;
    row-gap: 16px;
    padding: 24px 0;

    .identity__form_group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 24px;
      height: $defaultHeightBtn;
      margin: 0;
      font-weight: normal;

      span {
        @include defaultFont;
        color: rgba(0, 0, 0, 0.4);
      }

      .identity__form_group_input {
        max-width: 300px;
        padding: 10px 16px;
      }

      .identity__form__group__avatar {
        display: flex;
        flex-direction: row;
        align-items: center;

        .identity__form_avatar_remove {
          width: $defaultHeightBtn;
          padding: 0;
          outline: none;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          background: transparent;
          opacity: 0.4;

          &:hover {
            opacity: 1;
          }
        }

        .identity__form_avatar_image {
          width: 24px;
          height: 24px;
          object-fit: cover;
          flex-shrink: 0;
        }

        .identity__form_avatar_upload {
          @include defaultFont;
          font-weight: 600;
          margin: 0;

          &.disabled {
            pointer-events: none;
            opacity: 0.4;
          }
        }

        input[type='file'] {
          display: none;
        }
      }
    }

    .identity__form_buttons {
      margin-top: 40px;
      display: flex;
      align-items: center;
      column-gap: 24px;

      button {
        max-width: 320px;
        min-width: auto;
      }
    }
  }
}

.reference_docs {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .reference_docs__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow-y: scroll;
    background: white;

    .reference_docs__content_header {
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 0;
      z-index: 1;
      background: white;
      width: 100%;
      max-width: 600px;
      padding: 24px 0;
    }

    .reference_docs__search_input {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;

      input {
        padding: 0 16px;
      }
    }

    .reference_docs__uploads {
      display: flex;
      align-items: center;
      column-gap: 16px;
      height: 100%;

      label {
        display: flex;
        align-items: center;
        height: $defaultHeightBtn;
        column-gap: 24px;
        padding: 0 16px;
        margin: 0;
        @include defaultFont;
        font-weight: 600;

        input {
          display: none;
        }
      }
    }

    .reference_docs__content_list {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: $defaultWidthContent;

      .reference_docs__content_list_empty {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        @include defaultFont;
      }

      .reference_docs__content_list_item {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding: 0 16px;
        height: $defaultHeightBtn;

        svg {
          flex-shrink: 0;
        }

        .reference_docs__content_list_item_input {
          @include defaultFont;
          margin: 0 20px;
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border: 0;
          border-bottom: 1px solid transparent;

          &:focus {
            border-bottom-color: black;
          }
        }
        span {
          @include defaultFont;
        }

        button {
          outline: none;
          border: none;
          background: transparent;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: $defaultHeightBtn;
          opacity: 0.4;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .reference_docs_content_crawler {
      width: 100%;
      max-width: 600px;

      .reference_docs_content_crawler__header {
        display: flex;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 1;
        background: white;
        width: 100%;
        padding: 24px 0;
      }

      .reference_docs_content_crawler__search_input {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;

        input {
          padding-left: 16px;
          padding-right: 135px;
        }

        .reference_docs_content_crawler__submit {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          height: 100%;

          label {
            display: flex;
            align-items: center;
            column-gap: 24px;
            padding: 0 24px;
            margin: 0;
            @include defaultFont;
            font-weight: 600;

            input {
              display: none;
            }
          }
        }
      }

      .reference_docs_content_crawler__list {
        display: flex;
        flex-direction: column;
        width: 100%;

        .reference_docs_content_crawler__list_empty {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          @include defaultFont;
        }

        .reference_docs_content_crawler__list_item {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          padding: 0 16px;
          height: 56px;

          .reference_docs_content_crawler__list_item_url {
            font-size: var(--default-font-size);
            line-height: 136%;
            font-weight: 400;
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .reference_docs_content_crawler__list_item_date {
            font-size: var(--default-font-size);
            line-height: 136%;
            font-weight: 400;
            flex-shrink: 0;
            margin-left: 30px;
          }
        }
      }
    }
  }

  .reference_docs__uploading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;

    .reference_docs__uploading_inner {
      width: 320px;
      background: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      span {
        @include defaultFont;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding: 0 16px;
        height: $defaultHeightBtn;
      }

      label {
        display: flex;
        align-items: center;
        margin: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.2);

        span {
          flex-grow: 1;
        }
      }
    }
  }
}

.index_tab {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .index_tab__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  .index_tab__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow-y: scroll;
    background: white;
  }
}

@media (max-width: $mobileBreakpoint) {
  .settings {
    .settings__content {
      padding-left: 24px;
      padding-right: 13px;

      .settings__content_header {
        .actived_text span {
          a.configure_link {
            right: 16px;
          }
        }
      }
    }
  }

  .identity {
    .identity__content {
      padding-right: 13px;
      padding-left: 24px;
    }

    .identity__form {
      .identity__form_group {
        .identity__form_group_input {
          max-width: 210px;
        }
      }
    }
  }

  .reference_docs {
    .reference_docs__content {
      padding-right: 13px;
      padding-left: 24px;

      .reference_docs__search_input {
        flex-direction: column;

        input {
          padding-right: 16px;
        }
      }

      .reference_docs__uploads {
        position: unset;
        flex-direction: column;
        width: 100%;

        .vertical_line {
          display: none;
        }

        label {
          padding: 0 16px;
          width: 100%;
          height: $defaultHeightBtn;
        }
      }

      .reference_docs_content_crawler {
        .reference_docs_content_crawler__search_input {
          input {
            padding-right: 128px;
          }
        }
      }
    }

    .reference_docs__uploading {
      .reference_docs__uploading_inner {
        width: 100%;
      }
    }
  }

  .index_tab {
    .index_tab__content {
      padding-left: 24px;
      padding-right: 13px;
    }
  }
}
