@import '~theme/modules/variables';
@import '~theme/mixins';

.homepage {
  margin-top: 131px;

  &.isLogged {
    margin-top: 175px;
  }

  .sectionTitle {
    background: #f4f4f4;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 263px;
      z-index: -1;
      width: 100%;
      height: 357px;
      background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
      background-position: top center;
      background-repeat: no-repeat;
    }

    .sectionTitle__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 52px 64px 27px;
      min-height: 353px;

      h2 {
        @include ioradFont;
      }
    }
  }

  .sectionContent {
    padding-top: 62px;

    .sectionContent__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 0 64px;
      display: flex;
      flex-direction: column;
    }

    .sectionContent__desc {
      font-size: 15px;
      line-height: 20px;
      color: black;
      width: 100%;
      max-width: 622px;
      margin-bottom: 92px;
    }
  }

  @include breakpoint-sm {
    margin-top: 24px;

    &.isLogged {
      margin-top: 24px;
    }

    .sectionTitle {
      &::after {
        top: 444px;
        height: 255px;
        background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
      }
      .sectionTitle__content {
        padding: 0;
        min-height: auto;

        img {
          width: 100%;
        }
      }
    }

    .sectionContent {
      padding-top: 45px;

      .sectionContent__content {
        padding: 0 24px;
      }

      .sectionContent__desc {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 92px;
      }
    }
  }
}

.detailpage {
  margin-top: 131px;
  display: flex;
  flex-direction: column;

  &.isLogged {
    margin-top: 175px;
  }

  .sectionTitle {
    background: #f4f4f4;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 263px;
      z-index: -1;
      width: 100%;
      height: 357px;
      background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
      background-position: top center;
      background-repeat: no-repeat;
    }

    .sectionTitle__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 52px 64px 27px;
      min-height: 353px;

      h2 {
        @include ioradFont;
      }
    }
  }

  .sectionPlanInfo {
    margin-top: 11px;
    height: 110px;
    flex-shrink: 0;
    background: #2891be;

    .sectionPlanInfo__content {
      max-width: $maxWidthContent;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 0 64px 24px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      h3 {
        font-size: 32px;
        line-height: 136%;
        font-weight: 600;
        color: white;
        white-space: nowrap;
        margin: 0;
        margin-bottom: 10px;
        min-width: 357px;
      }

      label {
        font-size: 21px;
        line-height: 136%;
        font-weight: 600;
        color: white;
        white-space: nowrap;
        margin: 0;
        margin-bottom: 14px;
        margin-left: 46px;
        margin-right: auto;
      }

      span {
        font-size: 15px;
        line-height: 136%;
        font-weight: 400;
        white-space: nowrap;
        color: white;
        margin-bottom: 16px;
        padding-right: 40px;
        padding-left: 21px;
      }

      a {
        width: 276px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        font-size: 15px;
        line-height: 136%;
        font-weight: 600;
        color: black;
        cursor: pointer;
        background: white;
        margin-right: 93px;

        &:hover {
          color: var(--site-theme-color);
        }

        &.disabled {
          pointer-events: none;
          cursor: default;
        }
      }
    }
  }

  .sectionFeatures {
    margin-top: 60px;

    .sectionFeatures__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 0 64px;
      display: flex;
      flex-direction: column;
    }
  }

  .sectionComparePlans {
    display: flex;
    flex-direction: column;

    .sectionComparePlans__title {
      background: #2891be;

      .sectionComparePlans__title_content {
        max-width: $maxWidthContent;
        width: 100%;
        margin: 0 auto;
        padding: 54px 64px 42px;

        h2 {
          @include ioradFont;
          color: white;
          max-width: 810px;
        }
      }
    }

    .sectionComparePlans__content {
      display: flex;
      flex-direction: column;

      .sectionComparePlans__content_content {
        max-width: $maxWidthContent;
        width: 100%;
        margin: 0 auto;
        padding: 31px 64px 157px;
      }

      .sectionComparePlans__content_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        margin-right: 78px;

        h3 {
          font-size: 21px;
          line-height: 24px;
          font-weight: 600;
          color: black;
          margin: 0;
        }

        p {
          width: 100%;
          max-width: 810px;
          font-size: 15px;
          line-height: 20px;
          color: black;
          margin: 0;
          margin-top: 5px;
        }
      }
    }
  }

  @include breakpoint-sm {
    margin-top: 24px;

    &.isLogged {
      margin-top: 24px;
    }

    .sectionTitle {
      &::after {
        top: 469px;
        height: 255px;
        background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
      }
      .sectionTitle__content {
        padding: 0;
        min-height: 341px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        &[data-id='free'] {
          background-image: url('~assets/img/features_page/mobile/free_plan.png');
        }
        &[data-id='premium0'] {
          background-image: url('~assets/img/features_page/mobile/individual_plan.png');
        }
        &[data-id='premium1'] {
          background-image: url('~assets/img/features_page/mobile/team_plan.png');
        }
        &[data-id='premium2'] {
          background-image: url('~assets/img/features_page/mobile/enterprise_plan.png');
        }
        &[data-id='educator'] {
          background-image: url('~assets/img/features_page/mobile/educator_plan.png');
        }
        &[data-id='districts'] {
          background-image: url('~assets/img/features_page/mobile/institutions_plan.png');
        }

        h2 {
          display: none;
        }
      }
    }

    .sectionPlanInfo {
      margin-top: 0;
      height: 232px;

      .sectionPlanInfo__content {
        max-width: 100%;
        padding: 23px 24px 50px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        h3 {
          font-size: 21px;
          margin-bottom: 2px;
          min-width: 100%;
        }

        label {
          font-size: 13px;
          margin-bottom: 5px;
          margin-left: 0;
          margin-right: unset;
        }

        span {
          font-size: 13px;
          margin-bottom: 0;
          padding-right: 0;
          padding-left: 0;
        }

        a {
          width: 295px;
          font-size: 13px;
          margin-right: 0px;
          margin: auto auto 0;
        }
      }
    }

    .sectionFeatures {
      margin-top: 80px;

      .sectionFeatures__content {
        max-width: 100%;
        padding: 0 24px;
      }
    }

    .sectionComparePlans {
      .sectionComparePlans__title {
        .sectionComparePlans__title_content {
          max-width: 100%;
          padding: 0;

          img {
            width: 100%;
          }
        }
      }

      .sectionComparePlans__content {
        .sectionComparePlans__content_content {
          max-width: 100%;
          padding: 110px 0 182px;
        }

        .sectionComparePlans__content_header {
          flex-direction: column;
          margin-bottom: 17px;
          margin-right: 0;
          padding: 0 24px;

          h3 {
            font-size: 15px;
            line-height: 20px;
          }

          p {
            max-width: 100%;
            font-size: 13px;
            line-height: 18px;
            margin-top: 24px;
          }
        }
      }
    }
  }
}

.featuresSection {
  display: flex;
  flex-direction: column;

  .featuresSection__filters {
    max-width: 574px;
    position: relative;

    .featuresSection__filters_search {
      width: 100%;
      position: relative;

      .featuresSection__filters_search_input {
        width: 100%;

        input {
          background-color: transparent !important;
        }
      }

      .featuresSection__filters_search_filterIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
        width: 34px;
        height: 34px;
        cursor: pointer;
      }
    }

    .featuresSection__filters_list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      position: absolute;
      top: 100%;
      transform: translateY(-1px);
      z-index: 1;
      width: 100%;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 8px 26px;
    }

    .featuresSection__filters_item {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 6px 0;
      font-size: 15px;
      line-height: 20px;
      color: black;

      &:hover,
      &.active {
        color: var(--site-theme-color);
      }
    }
  }

  .featuresSection__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px 20px;
    margin-top: 95px;
    margin-bottom: 31px;

    .featuresSection__item {
      display: flex;
      flex-direction: column;
      width: 294px;

      .featuresSection__item_img {
        width: 100%;
        height: 147px;
        margin-bottom: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        &[data-id='unlimited-capture'] {
          background-image: url('~assets/img/features_page/covers/unlimited_capture.png');
        }
        &[data-id='free-public-tutorials'] {
          background-image: url('~assets/img/features_page/covers/free_public_tutorials.png');
        }
        &[data-id='learning-modes'] {
          background-image: url('~assets/img/features_page/covers/learning_modes.png');
        }
        &[data-id='integrations-and-apps'] {
          background-image: url('~assets/img/features_page/covers/integrations_&_apps.png');
        }
        &[data-id='tutorial-privacy'] {
          background-image: url('~assets/img/features_page/covers/tutorial_privacy.png');
        }
        &[data-id='mask-data'] {
          background-image: url('~assets/img/features_page/covers/mask_data.png');
        }
        &[data-id='draft-and-revision'] {
          background-image: url('~assets/img/features_page/covers/draft_&_revision.png');
        }
        &[data-id='team-collab'] {
          background-image: url('~assets/img/features_page/covers/team_collaboration.png');
        }
        &[data-id='basic-audio'] {
          background-image: url('~assets/img/features_page/covers/basic_audio.png');
        }
        &[data-id='premium-audio'] {
          background-image: url('~assets/img/features_page/covers/premium_audio.png');
        }
        &[data-id='video-recording'] {
          background-image: url('~assets/img/features_page/covers/video_recording.png');
        }
        &[data-id='custom-branding'] {
          background-image: url('~assets/img/features_page/covers/custom_branding.png');
        }
        &[data-id='basic-exports-and-step-lists'] {
          background-image: url('~assets/img/features_page/covers/exports_&_step_list.png');
        }
        &[data-id='analytics'] {
          background-image: url('~assets/img/features_page/covers/tutorial_analytics.png');
        }
        &[data-id='auto-domain-sharing'] {
          background-image: url('~assets/img/features_page/covers/auto_domain_sharing.png');
        }
        &[data-id='single-sign-on'] {
          background-image: url('~assets/img/features_page/covers/single_sign_in.png');
        }
        &[data-id='salesforce-integration'] {
          background-image: url('~assets/img/features_page/covers/salesforce_integration.png');
        }
        &[data-id='translations'] {
          background-image: url('~assets/img/features_page/covers/language_translations.png');
        }
        &[data-id='team-accounts'] {
          background-image: url('~assets/img/features_page/covers/team_accounts.png');
        }
        &[data-id='advanced-exports'] {
          background-image: url('~assets/img/features_page/covers/advanced_exports.png');
        }
        &[data-id='encrypt-and-anti-track'] {
          background-image: url('~assets/img/features_page/covers/encrypt_&_antitrack.png');
        }
      }

      .featuresSection__item_title {
        font-weight: 600;
        font-size: 32px;
        line-height: 136%;
        color: #000000;
        margin: 0;
        margin-bottom: 19px;
        min-height: 88px;
      }

      .featuresSection__item_desc {
        font-size: 15px;
        line-height: 136%;
        color: #000000;
        margin: 0;
      }
    }

    .featuresSection__list_empty {
      display: block;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }
  }

  @include breakpoint-sm {
    .featuresSection__list {
      gap: 39px 0;
      margin-top: 99px;
      margin-bottom: 39px;

      .featuresSection__item {
        width: 100%;

        .featuresSection__item_img {
          width: 100%;
          height: 164px;
          margin-bottom: 19px;
        }

        .featuresSection__item_title {
          font-size: 21px;
          margin-bottom: 17px;
          min-height: auto;
        }

        .featuresSection__item_desc {
          font-size: 13px;
        }
      }

      .featuresSection__list_empty {
        font-size: 13px;
      }
    }
  }
}

.comparePlans {
  width: 100%;

  .features_table {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .features_sticky_container {
    position: sticky;
    top: -1px;
    z-index: 1;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    transition: top 0.32s;
  }

  .features_expand_all_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 33px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  .features_table_header {
    display: flex;
    align-items: flex-end;
    column-gap: 22px;
    padding-top: 24px;
  }

  .features_what_you_get {
    height: 48px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 382px;
    padding-left: 32px;
  }

  .features_thead {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 32px;
  }

  .features_thead_text {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: black;
    margin: 0;
    margin-bottom: 4px;
  }

  .features_thead_price {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    margin: 0;
  }

  .features_thead_button {
    width: 229px;
    height: 48px;
    margin-top: 27px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      font-size: 15px;
      line-height: 136%;
      font-weight: 600;
      color: black;
      cursor: pointer;
      background: white;
      width: 100%;
      height: 100%;
      border: 1px solid rgba(0, 0, 0, 0.2);

      &:hover {
        color: var(--site-theme-color);
      }

      &[href='javascript:void(0)'] {
        pointer-events: none;
        cursor: default;
      }
    }
  }

  .features_table_body {
    display: flex;
    flex-direction: column;
  }

  .feature_item {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-inline: 1px solid transparent;
    position: relative;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &.is_expanded {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-top: none;
    }

    .feature_item__content {
      display: flex;
      align-items: center;
      height: 52px;
      column-gap: 22px;
    }

    .feature_item__title {
      padding-left: 32px;
      display: flex;
      flex-grow: 1;
      max-width: 382px;

      h6 {
        margin: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        width: max-content;
      }
    }

    .feature_item__col {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 293px;
      font-size: 15px;
      color: black;
    }

    .feature_item__desc {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      max-width: 576px;
      margin-left: 109px;
      margin-bottom: 27px;
      margin-top: 18px;
    }

    .feature_item__discover {
      position: absolute;
      right: 26px;
      bottom: 20px;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
      width: 240px;
      height: 40px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: var(--site-theme-color);
      }

      &.is_expanded_all {
        right: 19px;
      }
    }
  }

  @include breakpoint-sm {
    .features_table {
      border-top: none;
    }

    .features_sticky_container {
      border-top: none;
      display: flex;
      flex-direction: column;
    }

    .features_expand_all_btn {
      order: -1;
      font-size: 13px;
      margin-top: 8px;
      margin-right: 24px;
      margin-bottom: 12px;
    }

    .features_table_header {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 0;
      height: 40px;
      align-items: center;
      padding-inline: 24px;
      column-gap: 0;
    }

    .features_what_you_get {
      font-size: 13px;
      line-height: 18px;
      max-width: 100%;
      padding-left: 0;
      height: auto;
      flex: 1;
    }

    .features_thead {
      padding-inline: 0;
      flex-shrink: 0;
      width: 60px;
    }

    .features_thead_text {
      font-size: 13px;
      line-height: 18px;
      margin: 0;
    }

    .features_thead_price {
      display: none;
    }

    .features_thead_button {
      display: none;
    }

    .feature_item {
      padding: 9px 24px 10px;
      border-inline: none;

      &.is_expanded {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-inline: none;
      }

      .feature_item__content {
        height: auto;
        column-gap: 0;
      }

      .feature_item__title {
        padding-left: 0;
        max-width: 100%;
        flex: 1;

        h6 {
          font-size: 13px;
          line-height: 18px;
        }
      }

      .feature_item__col {
        flex-shrink: 0;
        font-size: 13px;
        width: 60px;
      }

      .feature_item__desc {
        font-size: 10px;
        line-height: 15px;
        margin-top: 21px;
        margin-left: 28px;
        margin-bottom: 0;

        &.has_slug {
          margin-bottom: 35px;
        }
      }

      .feature_item__discover {
        width: 70px;
        height: 30px;
        font-size: 10px;
        line-height: 15px;
        bottom: 10px;
        right: 24px;
      }
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: 31px;

  .footer {
    max-width: $maxWidthContent;
    width: 100%;
    margin: 0 auto;
    padding: 126px 158px 167px 64px;
    display: flex;
    justify-content: space-between;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    color: #000000;
    margin: 0;
  }

  .contactBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }

  @include breakpoint-sm {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;
    margin-top: 47px;

    .footer {
      padding: 164px 24px 237px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      line-height: 136%;
      margin-bottom: 70px;
    }

    .contactBtn {
      width: 295px;
      background: #000;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
