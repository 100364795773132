@use "sass:math";
@import '../../theme/guidelines_variables';
@import '../../theme/mixins';
$black7: #777777;
$black: #000000;
$black02: #020202;
$mobileWidth: 970px;

.tabs {
  &.mediumNav {
    .nav {
      .tab {
        margin-right: 0;
        width: auto;
        flex-grow: 1;
      }
      a {
        font-weight: 300;
        text-align: center;
        padding-bottom: 8px;
        font-size: 20px;
        line-height: 32px;

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}

.titleTab {
  text-align: left;
  font-family: "Fahkwang", sans-serif;
  font-weight: 500;
  font-size: 48px;
  line-height: 123%;
  margin-top: 35px;
  margin-bottom: 30px;

  @include breakpoint-sm {
    font-size: 27px;
    line-height: 116%;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.tabMoreWrapper {
  position: absolute;
  top: 20px;
  display: none;
  flex-direction: column;
  align-items: flex-end;
  left: 290px;
  padding-bottom: 16px;
  background: #fff;

  &.isOpenMore {
    display: flex;
  }

  .tab {
    margin-top: 12px;
  }

  @include breakpoint-sm {
    width: 100%;
    left: 0;
    padding-right: calc(100% - 320px);

    .tab:last-child {
      margin-right: 15px !important;
    }
  }
}

.nav_container {
}

.has_dropdown {
  .dropdown {
    display: none;
  }
}

@media screen and (max-width: $mobileWidth) {
  .has_dropdown {
    .nav_container {
      display: none;
    }

    .dropdown {
      display: block;
    }
  }
}

.nav {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  align-items: flex-end;
  flex-wrap: wrap;

  @include breakpoint-sm {
    flex-wrap: nowrap;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100vw;
    bottom: 0;
    margin-left: -50vw;
    left: 50%;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }
}

.tab {
  position: relative;
  width: auto;
  vertical-align: top;
  margin-right: 40px;

  @include breakpoint-sm {
    margin-right: 4%;
  }


  &:nth-child(1) {
    width: 98px;
  }
  &:nth-child(2) {
    width: 118px;
  }
  &:nth-child(3) {
    width: 104px;
  }
  &:nth-child(4) {
    width: 208px;
  }
}

:global(.pricing) {
  a.tab-link {
    // @media screen and (max-width: $mobileWidth) {
    //   padding-bottom: math.div($gl_gap_padding * 2, 3);
    //   font-weight: normal;
    //   width: calc(100% + 8px);
    //   text-align: center;
    // }
  }

  @media screen and (max-width: $mobileWidth) {
    .tab {
      margin-right: 5%;
    }
    .nav .tab:last-child {
      margin-right: 2%;
    }

    .tab-mobile {
      margin-right: 0;
      width: 50%;
      text-align: center;

      &.learners {
          .tab-link {
            &.active {
              color: $yellow;
              border-bottom-color: $yellow;
            }
          }
      }

      .tab-link {
        color: $grey;
        padding-bottom: 10px;

        &.active {
          color: $light_black;
          border-bottom-color: $light_black;
        }

        &:hover {
          color: $light_black;
          text-decoration: none;
        }
      }
    }
  }
}

a.tab-link {
  text-align: left;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: $grey;
  font-size: 24px;
  line-height: 35px;
  padding: 0 0 20px;
  border-bottom: 1px solid transparent;

  &:hover {
    color: #FF291C;
    border-bottom: 1px solid #FF291C;
    text-decoration: none;
  }

  &.active {
    position: relative;
    font-weight: 600;
    color: $light_black;
    border-bottom: 1px solid #000;
  }

  &.work {
  }

  @include breakpoint-sm {
    font-size: 14px;
    padding: 0;
  }

  @include breakpoint-md {
    font-size: 16px;
    padding: 0 0 6px;
  }

  // @media screen and (max-width: 768px) {
  //   padding-bottom: math.div($gl_gap_padding * 2, 3);
  //   font-weight: normal;
  //   width: calc(100% + 8px);
  //   text-align: center;
  // }
}

@media screen and (max-width: 768px) {
  .tab {
    margin-right: 5%;
  }
  .nav .tab:last-child {
    margin-right: 0;
  }

  .tab-mobile {
    margin-right: 0;
    width: 50%;
    text-align: center;

    &.learners {
        .tab-link {
          &.active {
            color: $yellow;
            border-bottom-color: $yellow;
          }
        }
    }

    .tab-link {
      color: $grey;
      padding-bottom: 10px;

      &.active {
        color: var(--site-theme-color);
        border-bottom-color: var(--site-theme-color);
      }
    }
  }
}

.content {
  margin-top: 30px;
}
