:root {
  --default-font-size: 18px;
  @media (max-width: 1050px) {
    --default-font-size: 16px;
  }
}

$defaultColor: #f4f4f4;
$defaultFontSize: var(--default-font-size);
$defaultHeightBtn: 56px;
$defaultHeightHeader: 48px;
$defaultWidthContent: 600px;
$mobileBreakpoint: 1050px;

@mixin defaultFont {
  font-size: $defaultFontSize;
  line-height: 136%;
  font-weight: 400;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
