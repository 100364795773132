@import '~theme/modules/variables';
@import '~theme/mixins';

.trainual {
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  .trainual__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    color: black;

    h1 {
      font-size: 35px;
      font-weight: 500;
      line-height: 40px;
      margin: 0;
      margin-top: 64px;
    }

    h3 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      margin: 0;
      margin-top: 64px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      margin-top: 24px;
    }

    ul {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-top: 24px;

      li {
        display: flex;
        align-items: center;
        column-gap: 24px;

        svg {
          width: 24px;
        }

        span {
          font-size: 15px;
          line-height: 20px;
        }
      }
    }
  }

  .trainual__button {
    width: 320px;
    height: 56px;
    color: white;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
    margin: 40px auto 0;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;

    &:hover {
      background: var(--site-theme-color);
    }
  }

  .trainual__logo {
    display: flex;
    align-items: center;
    column-gap: 24px;
  }

  .trainual__iframe {
    display: flex;
    background: #f4f4f4;
    padding: 24px 0;
    height: 567px;
    margin-top: 40px;

    iframe {
      width: 100%;
      height: 100%;
      max-width: 1024px;
      margin: 0 auto;
      border: none;
      outline: none;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .trainual__pricing {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
    margin-top: 56px;

    .trainual__pricing_item {
      display: flex;
      flex-direction: column;
      padding: 40px;
      background: #f4f4f4;
      flex: 1;

      svg {
        height: 41px;
      }

      h3 {
        margin-top: 40px;
      }

      span {
        font-size: 35px;
        font-weight: 500;
        line-height: 40px;
        margin-top: 16px;
      }

      s {
        font-size: 21px;
        line-height: 24px;
        color: var(--site-theme-color);
        margin-top: 8px;
      }

      .trainual__pricing_item_line {
        margin-top: 40px;
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.2);
      }

      ul {
        margin-top: 40px;
        padding-left: 14px;
        list-style-type: disc;
        row-gap: 16px;
        flex-grow: 1;

        li {
          display: list-item;
        }
      }
    }
  }

  .trainual__howItWorks {
    display: flex;
    flex-wrap: wrap;
    gap: 56px;
    margin-top: 56px;

    .trainual__howItWorks_item {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      min-width: 484px;

      span {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 24px;
      }

      img {
        width: 100%;
      }
    }
  }

  .trainual__footer {
    display: flex;
    column-gap: 1px;
    background: white;
    margin-top: 80px;

    .trainual__footer_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      padding: 56px 40px;
      background: #f4f4f4;

      .trainual__footer_item_content {
        display: flex;
        align-items: flex-start;
        width: 320px;
      }

      .trainual__footer_item_text {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        margin-left: 40px;

        span {
          font-size: 15px;
          line-height: 20px;
        }

        label {
          font-size: 21px;
          line-height: 24px;
          font-weight: normal;
          margin: 0;
        }
      }
    }
  }
}
