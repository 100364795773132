@import '~theme/form';
@import '~theme/mixins';
@import '~theme/modules/variables';

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.mobile {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .filterList {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: calc(100% - 220px);
      width: 100%;
      padding-left: 15px;
    }

    .dropdownWrapper {
      margin-bottom: 40px;
      width: 260px;
      margin-right: 0;
    }
  }
}

.filterList {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dropdownWrapper {
  margin-right: 28px;
  width: 169px;

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.sortOptionContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include breakpoint-sm {
    padding: 10px 14px;
  }
}

.optionSelected {
  padding: 0;
}

.optionListFilter {
  padding-bottom: 9px !important;
  padding-top: 6px !important;
}

.optionWrapperFilter:not(:last-child) {
  margin-bottom: 4px;
}

.optionSelected {
  padding-top: 2px;
}

.directionIcon {
  margin-right: 20px;
  width: 16px;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: -12px;
  }
}

.sortLabel {
  font-family: $fontBaseV2;
  font-size: 15px;
  color: #000000;
  text-transform: capitalize;
  padding-bottom: 2px;
}

.checkboxList {
  flex-grow: 1;
  width: 100%;
  overflow-y: scroll;
  margin-right: 28px;
}

.checkboxWrapper {
  margin-bottom: 39px;

  &:last-child {
    margin-bottom: 0;
  }
}

.applyButtonContainer {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, 0);

  button {
    width: 216px;
    height: 48px;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.resetButton {
  background-color: #cccccc;
}

.checkbox {
  padding-left: 34px;
}

.checkbox span {
  word-break: keep-all;
  white-space: nowrap;
}
