@import '~theme/modules/variables';
@import '~theme/mixins';

.dashboard {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  .dashboard__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .dashboard__loading {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 100%;

    span {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .dashboard__grid {
    display: flex;
    flex-direction: column;
    background: #f4f4f4;
    row-gap: 8px;
    height: 100%;

    .dashboard__grid_row {
      display: flex;
      flex: 1 1 0;
      column-gap: 8px;
      min-height: 0;
    }

    .dashboard__grid_item {
      background: white;
      flex: 1;
    }
  }
}
