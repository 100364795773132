@import '~theme/mixins';
@import '~theme/modules/variables';
@import './common';
$mobileBreakpoint: 1050px;
$categoryFontSize: 21px;
$tagFontSize: 21px;
$headerHeight: 48px;
$itemHeight: 56px;

@mixin fontEditHC {
  font-size: 18px;
  line-height: 136%;
  font-weight: 400;
}

.helpCenter {
  position: relative; // needed for z-index
  z-index: 1001; // needed to place above the google translate loading icon (see https://github.com/iorad/iorad/issues/12909#issuecomment-1416097142)
  width: 100%;
  height: 100%; // NOTE: 100vh doesn't work on ipad (also requires position != fixed)
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  font-family: 'Libre Franklin', sans-serif;
  //max-width: 1200px;
  margin: 0;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;

  html[data-extensionEmbed='true'] & {
    $extensionHeaderHeight: 48px;
    $extensionFooterHeight: 48px;

    position: fixed;
    top: 0;
    left: 0;
    margin-top: $extensionHeaderHeight;
    margin-bottom: $extensionFooterHeight;
    height: calc(100% - #{$extensionHeaderHeight + $extensionFooterHeight});

    &.tutorialSelected,
    &.embedSelected,
    &.aiAssistantExpanded {
      // fix for safari (see https://github.com/iorad/iorad-extension/issues/2608(
      margin-top: 0;
      margin-bottom: 0;
      height: 100%;
    }
  }

  &:not(.fontsLoaded) .tutorialsView {
    visibility: hidden;
  }

  &.tutorialSelected,
  &.embedSelected {
    .themeHeader {
      z-index: 1;
    }
  }

  &.withSearchResults.tutorialSelected {
    .selectedTutorial,
    .themeHeader {
      z-index: 2; // above overlay
    }
  }
  &.withSearchResults.embedSelected {
    .selectedEmbed,
    .themeHeader {
      z-index: 2; // above overlay
    }
  }

  &:not(.tutorialSelected):not(.embedSelected) {
    .mainSide {
      @media (max-width: $mobileBreakpoint) {
        width: 100%;
        min-width: auto;
      }
    }

    .mainContent {
      @media (max-width: $mobileBreakpoint) {
        display: none;

        &.setting_content {
          display: flex;
          flex-direction: column;
          border-left: none;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3; // above sidebar
          width: 100%;
          height: calc(100% - $headerHeight);
          background: white;

          &.full_height {
            height: 100%;
          }
        }
      }
    }
  }

  &.tutorialSelected,
  &.embedSelected {
    .mainSide {
      @media (max-width: $mobileBreakpoint) {
        display: none;
      }
    }
  }

  &.themeSettings {
    .tutorialRow, .searchResultItem {
      // opacity: 0.5;
      // pointer-events: none;
    }
  }

  :global(.scrollbars>div+div+div) {
    // put the vertical scrollbar above the content
    z-index: 3;
  }
}

.tutorialsView {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: stretch; // For safari, see https://stackoverflow.com/a/33644245

  &.iframeBorder {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mainSide,
  .mainContent {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto; // DON'T USE PERCENTAGE HEIGHT FOR SAFARI ("align-items: stretch" on the parent is enough)
    align-items: stretch;
    position: relative;
  }

  .mainSide {
    @include sideMenu;

    .overlay {
      z-index: 3;
      pointer-events: none;
      opacity: 0;
      width: 100%;
      background: transparent;

      &:after {
        content: '';
        opacity: 0;
        position: absolute;
        transition: opacity 0.4s ease 0s;
        background: rgba(255, 255, 255, 0.4);
        top: $headerHeight;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &.overlayEnabled, &:after {
        opacity: 1;
      }

    }

    &_header {
      display: flex;
      flex-shrink: 0;
      flex-direction: row;
      height: $headerHeight;
      gap: 1px;
      @media (max-width: $mobileBreakpoint) {
        order: 1;
      }

      &_item {
        height: 100%;
        flex-grow: 1;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #f4f4f4;

        svg {
          opacity: 0.4;
        }

        &.active,
        &:hover {
          svg {
            opacity: 1;
          }
        }

        &.active {
          background: #ffffff;
        }
      }
    }

    &_body {
      flex-grow: 1;
      flex-shrink: 0;
      overflow: hidden;
      max-height: calc(100% - $headerHeight);
      &--withoutHeader {
        max-height: 100%;
      }

      &_item {
        display: flex;
        flex-direction: column;
        height: 100%;

        &:not(.active) {
          display: none;
        }
      }
    }

    &_resizer {
      position: absolute;
      top: 0;
      right: 0;
      width: 6px;
      height: 100%;
      cursor: col-resize;
      background: transparent;
      z-index: 4;
      @media (max-width: $mobileBreakpoint) {
        display: none;
      }

      &:hover {
        background: #f4f4f4;
      }
    }

    &_collapsedButton, &_screenfullButton {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // border-left: 1px solid #fff;
      border-right: 1px solid #fff;
      margin-right: -1px;

      svg {
        [stroke] {
          stroke: currentColor;
        }
      }

      &:hover {
        svg path {
          stroke-opacity: 1;
        }
      }

      &.hasSelected {
        border-right-color: #fff;
      }
    }

    &_collapsedButton {
      @media (max-width: $mobileBreakpoint) {
        display: none;
      }
    }

    &.isCollapsed {
      width: 0 !important;
      min-width: 0 !important;
      z-index: 4;

      .mainSide_header_item {
        width: 0;
        height: 0;
      }
      .mainSide_collapsedButton {
        width: $headerHeight;
        height: $headerHeight;
        background: none;
        border: 0 none;
        margin: 0;

        svg {
          transform: rotate(180deg);
        }

        &.hasSelected {
          border-right: 1px solid #fff;
        }
      }

      .mainSide_screenfullButton {
        display: none;
      }

      .hc_filter_sort {
        display: none;
      }
    }

    &.isEmbedFullWidth {
      display: none;
    }
  }

  .mainContent {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    width: auto;
    flex-grow: 1;
    background-color: #f4f4f4;
  }

  &.sideResizing {
    pointer-events: none;
    user-select: none;

    .mainSide_resizer {
      pointer-events: all;
    }
  }
}

.mainView {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .bar,
  .sortBar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.disabled {
      opacity: 0.2;
    }

    @include breakpoint-sm {
      margin-top: 0;
    }
  }

  .bar {
    &.hidden {
      display: none;
    }
    .buttonAllFolders {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      .buttonFolder {
        margin: 0 22px;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        text-transform: uppercase;
        color: var(--marker-color);
      }
    }

    .searchIconContainer {
      display: flex;
      flex-grow: 1;
      justify-content: flex-start;
      align-items: center;
      width: 56px;
      height: 56px;
      padding-left: 16px;

      .searchText {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.4);
      }

      &.hide {
        visibility: hidden;
      }

      &:hover {
        cursor: text;
      }

      @include breakpoint-sm {
        height: 40px;
      }
    }

    &:hover {
      border-bottom: 1px solid #000;
    }
  }

  .filters_applied {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 56px;
    flex-shrink: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    span {
      font-size: 18px;
      line-height: 24px;
      color: black;
      flex-grow: 1;
    }

    a {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 24px;
      color: black;
      height: 100%;
      padding-left: 10px;
      cursor: pointer;

      &:hover {
        color: var(--marker-color);
      }
    }
  }

  .content {
    flex-grow: 1;
    height: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 0;
    overflow: hidden;

    @media screen and (min-width: 1050px) {
      :global(.simplebar-scrollbar)::before {
        left: 1px !important;
        right: 9px !important;
      }
    }

    .closeIconContainer {
      position: absolute;
      right: 11px;
      top: 8px;
      z-index: 20;
      padding: 0;
    }

    :global(.iorad-progress-rect) {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      top: 0;
      left: 0;
    }
  }
}

.categoryList,
.tagList,
.allTutorials {
  // context
  height: auto; // DON'T USE PERCENTAGE HEIGHT FOR SAFARI ("align-items: stretch" on the parent is enough)
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > div > div {
    // Scrollbars list
    display: flex;
    flex-direction: column;
  }
}
.selectedTutorial {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: auto; // DON'T USE PERCENTAGE HEIGHT FOR SAFARI ("align-items: stretch" on the parent is enough)
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #f4f4f4;
  z-index: 1; // above "Send" button of AIAssistant

  @media (max-width: $mobileBreakpoint) {
    padding: 0;
  }

  .controls {
    height: 42px;
    width: auto;
    font-weight: 500;
    line-height: 32px;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    border: 0;
    background: transparent;
    position: absolute;

    @media (max-width: $mobileBreakpoint) {
      border-radius: 0px;
    }

    .try {
      width: 75px;
      color: var(--marker-color);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 22px;
      @media (max-width: $mobileBreakpoint) {
        padding-right: 15px;
      }

      &:last-child {
        background: var(--marker-color);
        color: #ffffff;
        border-top-right-radius: 8px;
        @media (max-width: $mobileBreakpoint) {
          border-top-right-radius: 0;
        }
      }
    }

    .tutorialTitle {
      word-wrap: break-word;
      display: -webkit-box;
      text-align: left;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin: 4px 4px 4px 22px;
      width: 100%;
      overflow: hidden;
      display: none;
      @media (max-width: $mobileBreakpoint) {
        display: block;
      }

      &:hover {
        ~ .tooltip {
          display: block;
        }
      }
    }
    .tooltip {
      display: none;
      width: 100%;
      position: absolute;
      bottom: 39px;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      left: -2px;
      padding: 0 5px;
    }

    .back {
      margin-right: auto;
      padding: 0 10px;
      align-items: center;
      cursor: pointer;
      display: none;
      @media (max-width: $mobileBreakpoint) {
        display: flex;
      }

      svg {
        height: 21px;
        width: 11px;
      }
    }

    .viewLive {
      background: var(--marker-color);
      width: 137px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 107px;
      border-top-right-radius: 8px;
      @media (max-width: $mobileBreakpoint) {
        border-top-right-radius: 0;
      }
    }
  }

  iframe {
    width: 100%;
    flex-grow: 1;
    border: 0;
    border-radius: 0;
    background: #fff;
    margin-top: 48px;
    @media (max-width: $mobileBreakpoint) {
      border-radius: 0px;
      border: 0;
    }
  }

  &.empty {
    display: flex;
    font-size: 24px;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    font-size: 20px;
    text-align: center;
    color: #000000;
    padding: 45px 42px;
  }

  &:not(.empty) {
    @media (max-width: $mobileBreakpoint) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  &.tryMode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &.isFullSize {
    .selectedTutorial_embed_header,
    .selectedTutorial_header,
    .controls {
      display: none;
    }

    iframe {
      margin-top: 0;
    }
  }

  .selectedTutorial__tutorial_info_container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 48px;
  }
  .selectedTutorial__tutorial_name {
    font-size: 16px;
    line-height: 136%;
    font-weight: 400;
    color: black;
    white-space: nowrap;
    position: relative;
    width: 100%;
    text-align: center;

    &.is_overflow::after {
      content: '';
      height: 100%;
      width: 20%;
      position: absolute;
      right: 0;
      top: 0;
      background: linear-gradient(
        to left,
        #f4f4f4 1%,
        rgba(255, 255, 255, 0) 99.61%
      );
    }
  }

  &_embed_header {
    $size: 48px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: $size;
    width: 100%;
    background: #f4f4f4;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;

    &:hover {
      &::after {
        content: 'Back';
        background: #f4f4f4;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 136%;
        font-weight: 400;
        color: black;
      }
    }

    &_back {
      width: $size;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      flex-grow: 1;
      text-align: center;

      span {
        font-size: 16px;
        line-height: 136%;
        font-weight: 400;
        color: black;
      }
    }

    .selectedTutorial__tutorial_info_container {
      padding: 0;
    }

    .selectedTutorial__tutorial_name {
      padding-left: 48px;
      padding-right: 48px;
      font-size: 16px;
    }
  }

  &_header {
    $size: 48px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $size;
    display: flex;
    flex-direction: row;
    background: #f4f4f4;
    color: black;
    font-weight: normal;
    font-size: 16px;
    line-height: 136%;
    align-items: center;
    flex-shrink: 0;

    .textHover,
    .text {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: $size;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      cursor: pointer;
      z-index: 1;
    }

    .textHover {
      opacity: 0;
      z-index: 2;
    }

    .text {
      opacity: 1;
    }

    &:hover {
      .selectedTutorial_header_closeBtn {
        opacity: 1;
      }

      .textHover,
      .text {
        background-color: #f4f4f4;
      }
      .textHover {
        opacity: 1;
      }

      .text {
        opacity: 0;
      }
    }

    &_dragBtn,
    &_closeBtn {
      width: $size;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit;
      flex-shrink: 0;
      z-index: 2;
      opacity: 0.4;

      svg {
        color: inherit;

        [fill] {
          color: inherit;
          fill: currentColor;
        }

        [stroke] {
          color: inherit;
          stroke: currentColor;
        }
      }
    }

    &_closeBtn {
      margin-left: auto;
    }
  }

  &_header_mobile {
    $size: 48px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $size;
    display: flex;
    flex-direction: row;
    background: #f4f4f4;
    color: black;
    font-weight: normal;
    font-size: 16px;
    line-height: 136%;
    align-items: center;
    flex-shrink: 0;

    .text {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: $size;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      cursor: pointer;
      z-index: 1;
    }

    &_closeBtn {
      width: $size;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: inherit;
      flex-shrink: 0;
      z-index: 2;
      margin-left: auto;

      svg {
        color: inherit;

        [fill] {
          color: inherit;
          fill: currentColor;
        }

        [stroke] {
          color: inherit;
          stroke: currentColor;
        }
      }
    }
  }
}
.selectedEmbed {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: auto; // DON'T USE PERCENTAGE HEIGHT FOR SAFARI ("align-items: stretch" on the parent is enough)
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #f4f4f4;
  z-index: 1; // above "Send" button of AIAssistant

  html[data-extensionEmbed='true'] & {
    background: white;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 0;
  }

  iframe {
    width: 100%;
    flex-grow: 1;
    border: 0;
    border-radius: 0;
    background: #fff;
    // margin-top: 48px;
    margin-top: 0;

    @media (max-width: $mobileBreakpoint) {
      border-radius: 0px;
      border: 0;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    .selectedEmbed_header {
      transition: none;
    }
  }

  &_header {
    $size: 48px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $size;
    display: flex;
    flex-direction: row;
    background: #f4f4f4;
    flex-shrink: 0;
    cursor: pointer;
    transition: left 0.1s ease, width 0.1s ease;

    &:hover {
      .selectedEmbed_header_title::after {
        content: 'Back';
        background: #f4f4f4;
        font-weight: 400;
        font-size: 16px;
        line-height: 136%;
        color: black;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &_back {
      width: $size;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 136%;
      color: black;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &--socialIsFullSize &_header {
    display: none;
  }
  &--socialIsFullSize iframe {
    // margin-top: 0;
  }
  &--socialLoading iframe {
    visibility: hidden;
  }
  &--socialFiltering {
    .selectedEmbed_header {
      left: $socialSidebarWidth;
      width: calc(100% - #{$socialSidebarWidth});
    }

    @media screen and (max-width: 1300px) {
      .selectedEmbed_header {
        left: 300px;
        width: calc(100% - 300px);
      }
    }

    @include breakpoint-sm {
      .selectedEmbed_header {
        display: none;
      }
    }

    .selectedEmbed_header_back {
      display: none;
    }

    iframe {
      // margin-top: 0;
    }
  }
}

.clear {
  font-weight: 500;
}

.searchView {
  width: 100%;
  height: auto; // DON'T USE PERCENTAGE HEIGHT FOR SAFARI ("align-items: stretch" on the parent is enough)
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;
  flex-grow: 1;
  overflow: hidden;

  :global(.iorad-progress-rect) {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
  }

  .bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;

    &:focus-within {
      border-bottom-color: #000;
    }
  }

  .searchView__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 56px;
    flex-shrink: 0;

    span {
      font-size: 18px;
      line-height: 24px;
      color: black;
      flex-grow: 1;

      &.searchView__noResults {
        opacity: 0.4;
      }
    }

    a {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 24px;
      color: black;
      height: 100%;
      padding-left: 10px;
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .searchView__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden; // #14131 fix height
  }

  .loading,
  .begin,
  .emptyTutorialList {
    padding: 16px;
    @include fontEditHC;
  }

  @include breakpoint-sm {
    padding: 0;

    .bar {
      margin-top: 0;
    }
  }
}

.emptyTutorialList {
  padding: 16px;
  @include fontEditHC;

  &.hasMask {
    opacity: 0.2;
    pointer-events: none;
  }
}

.loading {
}

.loadMore {
  width: 1px;
  min-width: 1px;
  height: 1px;
  min-height: 1px;
  position: relative;
  top: 1px; // prevent overlap
  left: 1px;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  z-index: 1;
}

.manageIconContainer,
.closeIconContainer {
  @include barLeftIcon;
}

.overflowVisible {
  overflow: visible;
}

.progress {
  color: var(--marker-color);
  margin-top: 10px;
  margin-inline: 12px;
  line-height: 15px;
  font-size: 10px;
  font-weight: 400;

  label {
    display: block;
    margin-bottom: 0;
    font-weight: 400;
  }

  .progressContainer {
    display: flex;
    flex-wrap: nowrap;
  }

  .progressWrapper {
    display: block;
    position: relative;
    width: 100%;
    height: 2px;
    margin-right: 4px;
    margin-top: 5px;
    background: rgba(0, 0, 0, 0.2);

    .progressValue {
      background: var(--marker-color);
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: -1px;
        width: 4px;
        height: 4px;
        background: var(--marker-color);
        border-radius: 100%;
      }
    }
  }

  .progressPercent {
    position: relative;
    bottom: 4px;
  }

  &.plain {
    .progressWrapper {
      margin-right: 0;
    }
  }

  .progressText {
    display: flex;
    color: black;

    label {
      flex-grow: 1;
    }
  }

  .progressNumber {
    font-weight: 600;
  }
}

.progressOrderFirst {
  .progress {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .bar {
    margin-bottom: 10px;
  }
}

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 56px;
  height: 56px;

  svg {
    flex-shrink: 0;

    circle {
      fill: var(--marker-color);
    }
  }
}

.hc_filter_sort {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;

  &:not(.isDisplay) {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      background: white;
      height: 100%;
    }

    &:hover {
      &::after {
        content: 'Back';
        background: #f4f4f4;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include fontEditHC;
      }
    }

    &_back {
      width: 48px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      flex-grow: 1;
      text-align: center;

      span {
        @include fontEditHC;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    overflow-y: auto;

    &_tab_close {
      height: $itemHeight;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      cursor: pointer;
      flex-shrink: 0;

      svg {
        width: $itemHeight;
      }
    }

    &_tab_header {
      display: flex;
      font-size: 18px;
      margin-bottom: 16px;
      font-weight: 600;

      &_item {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        // border-top: 1px solid transparent;
        color: rgba(0, 0, 0, 0.4);
        height: $itemHeight;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:last-child {
          border-right: 0 none;
        }

        &.active,
        &:hover {
          color: #000;
        }

        &.active {
          border-bottom-color: transparent;
          // border-top-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    &_tab_content {
      overflow-y: auto;

      &:not(.active) {
        display: none;
      }
    }
  }

  &__sorting,
  &__filtering {
    li {
      padding: 0 24px;

      &:hover {
        background: var(--background-color);
      }
    }
  }

  &__sorting {
    h3 {
      @include fontEditHC;
      font-weight: 600;
      margin: 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: flex;
      align-items: center;
      column-gap: 16px;
      height: $itemHeight;
      cursor: pointer;

      &.active {
        svg {
          display: flex;
        }
      }

      span {
        @include fontEditHC;
        text-transform: capitalize;
      }

      svg {
        flex-shrink: 0;
        display: none;
        &:last-child {
          margin-left: auto;
        }
      }
    }
  }

  &__filtering {
    h3 {
      @include fontEditHC;
      font-weight: 600;
      margin: 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: flex;
      align-items: center;
      column-gap: 16px;
      height: $itemHeight;
      cursor: pointer;

      span {
        @include fontEditHC;
        text-transform: capitalize;
        flex-grow: 1;

        &.filters_count {
          flex-grow: 0;
        }
      }

      svg {
        flex-shrink: 0;
      }

      &:hover {
        background: var(--background-color);
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    gap: 16px;

    .btnGroup {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
    }

    &_applyBtn {
      flex-shrink: 0;
      height: $itemHeight;
      width: auto;
      @include fontEditHC;
      color: white;
      font-weight: 600;
      max-width: 320px;
      align-self: center;
      width: 100%;

      &:hover {
        background: #3b3b3b;
      }

      &.savePresetBtn {
        background: #F4F4F4;
        color: #000;

        &:hover {
          background: var(--background-color);
        }
      }

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  &__save_as_preset,
  &__my_presets {
    padding: 0 24px;

    &:hover {
      background: var(--background-color);
    }
  }

  &__save_as_preset {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    @include fontEditHC;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  &__my_presets {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 56px;
    cursor: pointer;

    span {
      @include fontEditHC;
      flex-grow: 1;

      &.filters_count {
        flex-grow: 0;
      }
    }

    svg {
      flex-shrink: 0;
    }
  }

  &__tagsGroup,
  &__tags {
    // margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    flex-grow: 1;
  }

  &__tagsGroup {
    display: flex;
    // align-items: center;
    // height: 56px;
    width: 100%;
    @include fontEditHC;

    span, &_reset {
      height: $itemHeight;
      display: flex;
      align-items: center;
    }

    span {
      flex-grow: 1;
    }

    &_reset {
      cursor: pointer;
      font-weight: 600;

      &:hover {
        color: var(--marker-color);
      }
    }
  }

  &__tags {
    display: flex;
    // flex-wrap: wrap;
    gap: 10px;

    &_item {
      display: flex;
      align-items: center;
      height: 56px;
      background: #f4f4f4;
      padding-left: 16px;
      overflow: hidden;

      span {
        @include fontEditHC;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &_close {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        cursor: pointer;
        width: 56px;
        height: 100%;
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.filter_option_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    padding: 0 16px;
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      background: white;
      height: 100%;
    }

    span {
      @include fontEditHC;
    }
  }

  &_search {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: none;
      outline: none;
      width: 100%;
      @include fontEditHC;
      caret-color: var(--marker-color);
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px 0;
  }

  &_list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;

    &_item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 56px;
      cursor: pointer;
      padding: 0 16px;

      span {
        @include fontEditHC;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        margin-right: 16px;
      }

      svg {
        flex-shrink: 0;
      }

      &:hover {
        background: var(--background-color);
      }
    }

    &_empty {
      padding: 16px 0;
      @include fontEditHC;
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    row-gap: 24px;

    &_doneBtn {
      flex-shrink: 0;
      height: 56px;
      margin: 0 24px;
      @include fontEditHC;
      color: white;
      max-width: 320px;
      width: 100%;
      align-self: center;

      &:hover {
        // background: var(--marker-color);
        background: #3b3b3b;
      }
    }
  }
}

.save_preset_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;

  &_header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    padding: 0 16px;
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      background: white;
      height: 100%;
    }

    span {
      @include fontEditHC;
      text-align: center;
      flex-grow: 1;
    }

    svg {
      position: absolute;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
  }

  &_input {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: none;
      outline: none;
      width: 100%;
      @include fontEditHC;
      caret-color: var(--marker-color);
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    row-gap: 8px;

    &_saveBtn {
      flex-shrink: 0;
      height: 56px;
      max-width: 312px;
      @include fontEditHC;
      color: white;

      &:hover {
        background: var(--marker-color);
      }
    }

    &_cancelBtn {
      flex-shrink: 0;
      height: 56px;
      max-width: 312px;
      border: none !important;
      @include fontEditHC;

      &:hover {
        color: var(--marker-color) !important;
      }
    }
  }
}

.my_presets_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    padding: 0 16px;
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      background: white;
      height: 100%;
    }

    span {
      @include fontEditHC;
    }
  }

  &_search {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: none;
      outline: none;
      width: 100%;
      @include fontEditHC;
      caret-color: var(--marker-color);
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px 0;
  }

  &_list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .preset_item {
      display: flex;
      flex-shrink: 0;
      height: 56px;

      &_name {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 0 16px;
        width: 100%;
        cursor: pointer;
        border-bottom: 1px solid transparent;

        &:hover {
          .preset_item_name_btn {
            display: flex;
          }
        }

        span {
          flex-grow: 1;
          @include fontEditHC;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }

        &_btn {
          display: none;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 24px;
          height: 100%;
          opacity: 0.4;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }

      &_input {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 0 16px;
        width: 100%;
        border-bottom: 1px solid black;

        input {
          flex-grow: 1;
          border: none;
          outline: none;
          padding: 0;
          margin: 0;
          caret-color: var(--marker-color);
          @include fontEditHC;
          background: transparent;
        }

        &_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 24px;
          height: 100%;
          cursor: pointer;
        }
      }

      &:hover {
        background: var(--background-color);
      }
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    row-gap: 24px;

    &_doneBtn {
      flex-shrink: 0;
      height: 56px;
      margin: 0 24px;
      width: auto;
      @include fontEditHC;
      color: white;
      font-weight: 600;
      max-width: 320px;
      align-self: center;
      width: 100%;

      &:hover {
        // background: var(--marker-color);
        background: #3b3b3b;
      }
    }
  }
}

.shareView {
  @include fontEditHC;

  .item {
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 56px;

    svg {
      width: 56px;
    }

    &:hover {
      background: var(--background-color);
    }
  }

  &.isMobileBreakpoint {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 44px;
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }
}

.edit_popup {
  overflow: hidden;

  :global(.popup-content) {
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 1px;
  }
}
