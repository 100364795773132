.talkToUs {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  padding-bottom: 24px;
  width: 100%;
  height: 100%;
  color: black;

  .talkToUs__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 960px;
    width: 100%;
    margin: 0 auto 40px;

    h1 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      margin: 0;
    }

    .talkToUs__open_ticket {
      display: flex;
      align-items: center;
      column-gap: 16px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      cursor: pointer;
      transition: color 0.3s ease;
      svg path {
        fill: currentColor;
      }

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .talkToUs__tabs {
    display: flex;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;

    .talkToUs__tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 1;
      height: 56px;
      font-size: 15px;
      line-height: 20px;
      border: 1px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;

      &.active {
        border-color: rgba(0, 0, 0, 0.2);
        border-bottom-color: transparent;
        cursor: default;
      }
      

      &:not(.active):hover {
        background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
      }
    }
  }

  .talkToUs__tab_content {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-top: 24px;
    padding-left: 11px; //scroll bar width

    iframe {
      min-height: 690px;
    }
  }
}

.talkToUs__support_ticket_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .talkToUs__support_ticket_modal_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .talkToUs__support_ticket_modal_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    min-height: 600px;
    max-width: 100vh;
    overflow-y: auto;
    background-color: white;
    position: relative;
    padding: 24px 56px;

    :global(#support-ticket-form) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      width: 100%;
      height: 100%;
    }

    .talkToUs__support_ticket_modal_close {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;

      svg path {
        stroke: currentColor;
      }

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }
  
}

