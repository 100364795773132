@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 75px;

  &.isLogged {
    margin-top: 119px;
  }

  .sectionBreadcrumb {
    display: flex;
    max-width: $maxWidthContent;
    width: 100%;
    margin: 0 auto 33px;
    padding: 0 64px;

    .sectionBreadcrumb__button {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-size: 15px;
        line-height: 20px;
        text-decoration: underline;
        margin-left: 15px;
        color: black;
      }

      &:hover {
        svg path {
          fill: var(--site-theme-color);
        }
        span {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .titleWrapper {
    background: #f4f4f4;

    .titleContent {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 52px 64px 78px;

      .title {
        h2 {
          @include ioradFont;
        }
      }
    }
  }

  .descWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 64px 64px 86px;

    h3 {
      font-size: 32px;
      line-height: 44px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 23px;
    }

    p {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      width: 100%;
      max-width: 618px;
    }
  }

  .performanceSection {
    background-color: #77c082;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -115px;
      width: 100%;
      height: 115px;
      background-image: url('~assets/img/redesign2021/customerpage/bg_counter_section.png');
      background-repeat: no-repeat;
      background-position: top center;
    }

    .performanceSection_content {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 60px 64px 38px;
    }

    .performanceSection_title {
      font-size: 32px;
      line-height: 44px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 17px;
    }

    .performanceSection_desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      width: 100%;
      max-width: 618px;
    }

    .performanceSection_list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 32px;
      margin-top: 38px;

      .performanceSection_list_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 390px;
      }

      .item_header {
        display: flex;
        align-items: flex-end;

        .item_value {
          color: white;
          font-family: iorad;
          font-size: 204px;
          font-weight: 400;
          line-height: 67%;
        }

        .item_unit {
          margin-left: 6px;
          color: white;
          font-size: 32px;
          font-weight: 600;
          line-height: 37px;
          margin-bottom: 7px;
        }

        .item_title {
          margin-left: 15px;
          color: black;
          font-size: 21px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 10px;
        }
      }

      .item_content {
        color: black;
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        margin-top: 2px;
      }
    }
  }

  .timeSection {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 137px 64px 50px;

    .timeSection_title {
      h2 {
        @include ioradFont;
        margin-bottom: 81px;
      }
    }

    .timeSection_desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      width: 100%;
      max-width: 618px;
      margin-bottom: 31px;
    }

    .timeSection_content {
      display: flex;
      justify-content: space-between;
      padding-right: 125px;

      .timeSection_content_block {
        display: flex;
        flex-direction: column;

        h4 {
          color: black;
          font-size: 21px;
          font-weight: 600;
          line-height: 24px;
          margin: 0;
          margin-bottom: 129px;
        }

        .block {
          display: flex;
          align-items: flex-end;
          column-gap: 54px;
          height: 130px;
        }

        .column {
          display: flex;
          flex-direction: column;

          .column_header {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 15px;
            line-height: 20px;
            color: black;
            margin-bottom: 10px;

            label {
              margin: 0;
              font-weight: 600;
            }
          }

          .column_content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 198px;

            &.with_iorad {
              background: #2891be;
              span {
                color: white;
              }
            }
            &.without_iorad {
              background: #f2f2f2;
              span {
                color: black;
              }
            }

            span {
              font-size: 15px;
              line-height: 20px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .timeSection_footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 18px;

      p {
        font-size: 15px;
        line-height: 136%;
        color: black;
        margin: 0;
        text-align: end;
      }
    }
  }

  .statisticsSection {
    .statisticsSection_titleWrapper {
      background: #77c082;

      .statisticsSection_titleContent {
        max-width: $maxWidthContent;
        margin: 0 auto;
        padding: 45px 64px 23px;
      }

      .statisticsSection_title {
        font-size: 32px;
        font-weight: 600;
        line-height: 44px;
        color: black;
        margin: 0;
        margin-bottom: 22px;
      }

      .statisticsSection_desc {
        font-size: 15px;
        line-height: 136%;
        color: black;
        margin: 0;
        width: 100%;
        max-width: 618px;
      }
    }

    .statisticsSection_liveCountWrapper {
      background: #f2f2f2;

      .statisticsSection_liveCountContent {
        max-width: $maxWidthContent;
        margin: 0 auto;
        padding: 6px 64px 16px;
        display: flex;
        flex-direction: column;
        row-gap: 17px;
      }

      .statisticsSection_liveCountBlock {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        span {
          font-family: iorad;
          font-size: 204px;
          font-weight: 400;
          line-height: 67%;
          color: black;
        }

        .statisticsSection_liveCountBlock_info {
          display: flex;
          flex-direction: column;
          max-width: 361px;
          margin-bottom: 9px;

          h5 {
            color: black;
            font-size: 21px;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            margin-bottom: 6px;
          }

          p {
            font-size: 15px;
            line-height: 136%;
            color: black;
            margin: 0;
          }
        }
      }
    }

    .statisticsSection_tutorialsCountWrapper {
      background: #77c082;

      .statisticsSection_tutorialsCountContent {
        max-width: $maxWidthContent;
        margin: 0 auto;
        padding: 5px 64px 49px;
      }

      .statisticsSection_tutorialsCountBlock {
        display: flex;
        align-items: flex-end;

        .statisticsSection_tutorialsCountBlock_info {
          display: flex;
          flex-direction: column;
          max-width: 221px;
          margin-bottom: 18px;

          h5 {
            color: black;
            font-size: 21px;
            font-weight: 600;
            line-height: 24px;
            margin: 0;
            margin-bottom: 6px;
          }

          p {
            font-size: 15px;
            line-height: 136%;
            color: black;
            margin: 0;
          }
        }

        span {
          font-family: iorad;
          font-size: 340px;
          font-weight: 400;
          line-height: 67%;
          color: white;
        }
      }

      .statisticsSection_tutorialsToday {
        display: flex;
        justify-content: space-between;

        .statisticsSection_tutorialsTodayBlock {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          .statisticsSection_tutorialsCountBlock_info {
            display: flex;
            flex-direction: column;
            max-width: 193px;
            margin-bottom: 9px;

            h5 {
              color: black;
              font-size: 21px;
              font-weight: 600;
              line-height: 24px;
              margin: 0;
              margin-bottom: 6px;
            }

            p {
              font-size: 15px;
              line-height: 136%;
              color: black;
              margin: 0;
            }
          }

          span {
            font-family: iorad;
            font-size: 204px;
            font-weight: 400;
            line-height: 67%;
            color: black;
          }
        }
      }
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 170px 158px 140px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

.videoPopup {
  position: fixed;
  z-index: 40;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 86px 0;
  background-color: #000;
  z-index: #{$embedButtonZIndex + 1};

  .videoContent {
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    right: 64px;
    top: 24px;
    cursor: pointer;
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 3px;

    &.isLogged {
      margin-top: 3px;
    }

    .sectionBreadcrumb {
      max-width: 100%;
      margin-bottom: 13px;
      padding: 0 24px;

      .sectionBreadcrumb__button {
        opacity: 0.4;
        span {
          margin-left: 26px;
          text-decoration: none;
        }
      }
    }

    .titleWrapper {
      .titleContent {
        max-width: 100%;
        padding: 67px 0 47px;

        .title {
          img {
            width: 100%;
          }
        }
      }
    }

    .descWrapper {
      max-width: 100%;
      margin: 0 auto;
      padding: 93px 24px 59px;

      h3 {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      p {
        font-size: 13px;
        max-width: 100%;
      }
    }

    .performanceSection {
      &:after {
        background-image: url('~assets/img/redesign2021/global/mobile/gradient_green.png');
        background-size: cover;
      }

      .performanceSection_content {
        max-width: 100%;
        margin: 0 auto;
        padding: 57px 24px 28px;
      }

      .performanceSection_title {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      .performanceSection_desc {
        font-size: 13px;
        max-width: 100%;
      }

      .performanceSection_list {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 50px 0;
        margin-top: 57px;

        .performanceSection_list_item {
          max-width: 100%;
        }

        .item_header {
          flex-wrap: wrap;

          .item_value_img {
            height: 145px;
            width: auto;
          }

          .item_unit {
            margin-left: 27px;
            font-size: 21px;
            line-height: 24px;
            margin-bottom: 6px;
          }

          .item_title {
            flex-grow: 1;
            margin-left: 0;
            margin-bottom: 0;
            margin-top: 11px;
          }
        }

        .item_content {
          font-size: 13px;
          line-height: 18px;
          margin-top: 21px;
        }
      }
    }

    .timeSection {
      max-width: 100%;
      margin: 0 auto;
      padding: 98px 0 60px;

      .timeSection_title {
        img {
          width: 100%;
        }
      }

      .timeSection_desc {
        font-size: 13px;
        max-width: 100%;
        margin-bottom: 54px;
        margin-top: 38px;
        padding: 0 24px;
      }

      .timeSection_content {
        flex-direction: column;
        justify-content: flex-start;
        padding: 0 24px;
        row-gap: 68px;

        .timeSection_content_block {
          display: flex;
          flex-direction: column;

          h4 {
            margin-bottom: 30px;
          }

          .block {
            justify-content: space-between;
            height: auto;
          }

          .column {
            .column_header {
              font-size: 13px;
              line-height: 18px;
              margin-bottom: 8px;
            }

            .column_content {
              width: 143px;
              span {
                font-size: 13px;
                line-height: 18px;
              }
            }
          }
        }
      }

      .timeSection_footer {
        margin-top: 30px;

        p {
          font-size: 13px;
        }
      }
    }

    .statisticsSection {
      .statisticsSection_titleWrapper {
        .statisticsSection_titleContent {
          max-width: 100%;
          padding: 54px 24px 27px;
        }

        .statisticsSection_title {
          font-size: 21px;
          line-height: 24px;
          margin-bottom: 18px;
        }

        .statisticsSection_desc {
          font-size: 13px;
          max-width: 100%;
        }
      }

      .statisticsSection_liveCountWrapper {
        .statisticsSection_liveCountContent {
          max-width: 100%;
          padding: 38px 24px 28px;
          row-gap: 33px;
        }

        .statisticsSection_liveCountBlock {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          span {
            font-size: 113.421px;
            line-height: 64%;
            letter-spacing: -0.681px;
          }

          .statisticsSection_liveCountBlock_info {
            max-width: 100%;
            margin-bottom: 0px;
            margin-top: 17px;

            h5 {
              font-size: 13px;
              line-height: 18px;
              margin-bottom: 9px;
            }

            p {
              font-size: 13px;
            }
          }
        }
      }

      .statisticsSection_tutorialsCountWrapper {
        .statisticsSection_tutorialsCountContent {
          max-width: 100%;
          padding: 38px 24px 29px;
        }

        .statisticsSection_tutorialsCountBlock {
          flex-direction: column;
          align-items: flex-start;

          .statisticsSection_tutorialsCountBlock_info {
            flex-direction: row;
            justify-content: space-between;
            column-gap: 18px;
            max-width: 100%;
            margin-bottom: 0;
            margin-top: 20px;

            h5 {
              font-size: 13px;
              line-height: 18px;
              margin-bottom: 0px;
              max-width: 116px;
            }

            p {
              font-size: 13px;
              line-height: 18px;
              flex-grow: 1;
            }
          }

          span {
            font-size: 113.421px;
            line-height: 64%;
            letter-spacing: -0.681px;
            order: -1;
          }
        }

        .statisticsSection_tutorialsToday {
          flex-direction: column;
          justify-content: flex-start;
          row-gap: 30px;
          margin: 30px 0;

          .statisticsSection_tutorialsTodayBlock {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            span {
              font-size: 113.421px;
              line-height: 64%;
              letter-spacing: -0.681px;
              order: -1;
            }

            .statisticsSection_tutorialsCountBlock_info {
              flex-direction: row;
              justify-content: space-between;
              column-gap: 18px;
              max-width: 100%;
              margin-bottom: 0;
              margin-top: 20px;

              h5 {
                font-size: 13px;
                line-height: 18px;
                margin-bottom: 9px;
              }

              p {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .learnMoreBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }

  .videoPopup {
    padding: 0;

    .closeBtn {
      right: 24px;
    }

    .videoContent {
      display: flex;

      iframe {
        max-height: 211px;
        margin: auto 0;
      }
    }
  }
}
