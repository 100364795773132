@import '~theme/modules/variables';
@import '~theme/mixins';
@import '../includes';
@import '../_leftBarShares';

.container {
  @include listBlock();

  // z-index: 1;
  position: relative;
  background-color: #FFF;

  .header {
    &:hover {
      .categoryIcon {
        path {
          fill: var(--site-theme-color);
        }
      }

      .headerText {
        color: var(--site-theme-color);
      }

      .addIcon {
        path {
          stroke: var(--site-theme-color);
        }
      }

      .editIcon {
        path {
          stroke: var(--site-theme-color);
        }

        path:first-child {
          stroke: none;
          fill: var(--site-theme-color);
        }
      }
    }
  }
}

.animationContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.scrollContainer {
  margin: 3px 0 15px 2px;
  padding-right: 11px;
  overflow: hidden;

  @-moz-document url-prefix() {
    padding-right: 15px;
  }

  &:hover {
    overflow-y: scroll;
    padding-right: 0;
  }
}

.blur {
  opacity: 0.2;
}

.isEditing {
  position: relative;
  z-index: 22;
}

.header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 10;
  height: 78px;
  padding-right: 12px;
  padding-left: 5px;
  margin-top: -2px;

  &.multiRow {
    display: block;
    height: auto;
  }
}

.headerIcon {
  visibility: hidden;
  pointer-events: none;
  margin-right: 16px;
  opacity: 0.4;
  cursor: pointer;

  &:hover {
    opacity: 1;
    &[stroke] {
      stroke: var(--site-theme-color);
    }
    &[fill] {
      fill: var(--site-theme-color);
    }
  }

  &.categoryIcon {
    padding-bottom: 2px;
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }

  &.isTagChangeActive {
    opacity: 0.2;
    pointer-events: none;
  }
}

.solidHeaderIcon {
  @extend .headerIcon;
  visibility: visible;
  pointer-events: all;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }

}

.highlightIcon path {
  fill: var(--site-theme-color)
}

.highlight {
  opacity: 1;

  svg {
    path[stroke] {
      stroke: var(--site-theme-color);
    }
    path[fill] {
      fill: var(--site-theme-color);
    }
  }
}

.headerText {
  font-size: 15px;
  margin-right: 20px;
  font-family: $fontBaseV2;
  font-weight: bold;
  flex-grow: 1;
  color: #000000;
  cursor: pointer;
  font-weight: bold;

  &.highlightText {
    color: var(--site-theme-color);
  }
}

.tagInput {
  max-height: 33px;
  margin-bottom: 7px;
}

.tagItem {
  display: flex;
  align-items: center;
  padding: 3px 9px 3px 2px;
  cursor: pointer;
  &.noTags {
    align-items: flex-start;
    .name {
      @include tagName();
    }
    .itemCount {
      color: #999999;
      font-size: 10px;
      font-family: $fontBaseV2;
      text-align: right;
      margin-top: 4px;
      margin-left: auto;
    }
    &:hover, &.highlight {
      .name {
        color: var(--site-theme-color);
      }
    }
  }

  &.isRename {
    @include ontopOfOverlay();
  }

  &.isDisabled {
    opacity: 0.2;
    pointer-events: none;
  }
}

.subCategories {
  margin-top: 15px;
  padding-left: 20px;
}

.searchbox {
  width: 193px;
  margin-left: 2px;

  input {
    height: 33px;
    padding-left: 35px !important;
    padding-right: 2px;
  }

  img {
    left: 0 !important;
  }
}

.showOverlay {
  .overlayItem {
    z-index: 22;

    &:not(.header) {
      position: relative;
    }
  }

  &.overlayingOnTutorial, &.overlayingOnCategory, &.overlayingOnSearch {
    .overlayItem, .header {
      z-index: 19 !important;
    }
  }
}

.tagChange {
  @include ontopOfOverlay();

  .tagItem.noTags {
    color: rgba(0, 0, 0, .2);
    pointer-events: none;
  }
}

.container:not(.isMinimize) .header {
  @include ontopOfOverlay();
  position: sticky;
}

.container:not(.isExpanding) .searchbox{
  display:none;
}

