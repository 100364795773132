@import '~theme/mixins';
@import '~theme/modules/variables';

.automations_ai_tab {
  display: flex;
  padding: 0 40px;
  @include nice-scrollbar;

  .sidebar {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    width: 214px;
    padding-top: 40px;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      cursor: pointer;

      &:hover,
      &.active {
        color: var(--site-theme-color);
      }
    }
  }

  .content {
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &.extensionEmbed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;
    padding: 0;
    display: flex;
    flex-direction: column;

    .sidebar {
      padding: 0;
      height: 56px;
      background: #F4F4F4;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 2;

      .tabs {
        display: flex;
        flex-direction: row;
        gap: 24px;
        height: 100%;
        width: 100%;
        align-items: center;
        position: relative;

        .backBtn {
          width: 56px;
          height: 24px;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    >.content {
      padding: 0;
    }
  }
}

@include breakpoint-sm {
  .automations_ai_tab:not(.extensionEmbed) {
    padding-left: 24px;
    padding-right: 13px;
    flex-direction: column;

    .sidebar {
      padding: 24px 0;
      z-index: 2;
      width: 100%;
    }

    .tabs {
      flex-direction: row;
      column-gap: 24px;
    }

    .content {
      padding-left: 0;
    }
  }
}
