.downloadSelect {
  padding: 35px 0 0;
  :global(.react-selectize), :global(.btn.btn-fancy) {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  :global(.btn-fancy) {
    max-width: 100%;
  }
}

.linkLeft {
  float: left;
}

.linkRight {
  float: right;
}

.tabContainer {

}

.item_container {
  min-height: 323px;
}

.export_tutorial_popup {
  :global(.popup-content) {
    min-height: auto;
  }
}

.select_download {
  $fontBase: 'Libre Franklin', sans-serif;
  width: 300px;

  .export_btn {
    margin-top: 50px !important;
  }

  .label {
    font-family: $fontBase;
    font-weight: normal;
    font-size: 10px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.4);
  }

  :global(.easy-select) {
    height: 40px;

    :global(.text) {
      font-size: 15px;
      line-height: 40px;
      height: 40px;
      max-width: 100%;
      font-family: $fontBase;
      color: #000;
      margin: 0;
    }

    :global(.cursor) {
      border: none;
      right: 0;
      background-image: url("~assets/img/icons/arrow-down.svg");
      width: 40px;
      height: 40px;
    }
  }
}
