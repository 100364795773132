.personal {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  padding-bottom: 24px;
  width: 100%;
  max-width: 960px;
  color: black;

  .personalTitle {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .personalContent {
    display: flex;
    justify-content: space-between;
    column-gap: 56px;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    img {
      height: 72px;
      width: 72px;
      object-fit: cover;
    }

    label {
      margin: 0;
      cursor: pointer;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      width: 100%;
      max-width: 320px;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .formGroup {
      display: flex;
    }

    .formGroupLabel {
      display: flex;
      align-items: center;
      flex-grow: 1;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 0;
      height: 56px;
      opacity: 0.5;
    }

    .formGroupStatus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 320px;
      height: 56px;

      span {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;

        &.resend {
          font-weight: 600;
          cursor: pointer;

          &:hover {
            color: var(--site-theme-color);
          }
        }
      }
    }

    .formGroupInput {
      width: 100%;
      max-width: 320px;
      height: 56px;
      background-color: transparent;
      padding: 0;
      outline: none;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 15px;
      line-height: 20px;

      &:focus {
        border-bottom: 1px solid var(--site-theme-color);
      }
    }

    .formGroupCheckbox {
      white-space: nowrap;
      position: relative;
      cursor: pointer;
      user-select: none;
      color: black;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      margin: 0;
      padding-left: 32px;
      height: 56px;

      input[type='checkbox']:checked ~ span::after {
        background-image: url('~assets/img/icons/checkmark.svg');
        background-size: 76%;
        border: none;
      }

      input[type='checkbox'] {
        display: none;
      }

      span:after {
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        content: '';
        background-color: white;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    .formGroupSelect {
      :global(.react-selectize-search-field-and-selected-values) {
        min-height: 56px !important;
      }

      :global(.react-selectize-toggle-button-container) {
        width: 56px !important;
        height: 56px !important;
      }

      :global(.resizable-input) {
        width: 0 !important;
      }
    }

    .vatGroup {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 320px;
    }
  }

  .personalActions {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 264px;

    .personalActions_item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      column-gap: 16px;
      height: 40px;
      padding: 0 16px;
      font-size: 15px;
      font-weight: 600;
      cursor: pointer;
      color: inherit;

      .personalActions_item_icon {
        display: flex;
        align-items: center;
        width: 16px;
        flex-shrink: 0;
      }

      &:hover {
        background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
      }
    }
  }
}
