@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  max-width: $maxWidthContent;
  margin: 0 auto;
}

.welcome {
  display: flex;
  column-gap: 11px;

  .welcome__left {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #905c99;
    padding: 79px 32px 50px 30px;
    position: relative;

    .iorad_icon {
      position: absolute;
    }
  }

  .welcome__right {
    display: flex;
    flex-direction: column;
    padding-top: 217px;
    padding-left: 64px;
    padding-right: 106px;
    flex-grow: 1;
    background: #63d0ff;

    h5 {
      margin: 0;
      font-size: 32px;
      font-weight: 400;
      line-height: 44px;
      color: black;

      span {
        color: #907c99;
      }
    }

    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-top: 34px;
    }
  }
}

.certificate_levels {
  padding: 84px 64px 92px;
  display: flex;
  flex-direction: column;

  .certificate_levels__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    color: black;
    margin: 0;
  }

  .certificate_levels__desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: black;
    margin: 0;
    margin-top: 21px;
    max-width: 626px;
  }

  .certificate_levels__levels {
    display: flex;
    column-gap: 80px;
    margin-top: 62px;

    .level__item {
      display: flex;
      flex-direction: column;
      max-width: 370px;

      h4 {
        margin: 0;
        margin-bottom: 8px;
        margin-left: 14px;
        color: #000;
        font-size: 32px;
        font-weight: 600;
        line-height: 44px;
        white-space: nowrap;
      }

      p {
        color: #000;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        margin-top: 40px;
      }
    }

    .level__item_header {
      display: flex;
      align-items: flex-end;
    }

    .level__item_icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .iorad_icon {
        position: absolute;
      }
    }
  }
}

.advance {
  display: flex;
  column-gap: 11px;

  .advance__left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 177px 84px 104px 64px;
    background: #907c99;

    h4 {
      color: #fff;
      font-size: 32px;
      font-weight: 400;
      line-height: 44px;
      margin: 0;
      margin-bottom: 29px;
    }

    p {
      color: #fff;
      font-size: 21px;
      font-weight: 400;
      line-height: 136%;
      margin: 0;
      margin-bottom: 43px;
    }

    a {
      width: 276px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: black;
      background: white;
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .advance__right {
    padding: 67px 54px 44px 38px;
    background: #63d0ff;
  }
}

.courses {
  display: flex;
  flex-direction: column;
  padding: 84px 64px 0;

  .courses__header {
    color: #000;
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin: 0;
    margin-bottom: 60px;
  }

  .courses__list {
    display: flex;
    column-gap: 35px;
    flex-wrap: wrap;
  }

  .courses__item {
    display: flex;
    flex-direction: column;
    border: 5px solid #63d0ff;
    padding: 24px 35px 35px 26px;
    width: 388px;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      width: 22px;
      height: 22px;
      background: white;
      border: 5px solid #63d0ff;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-13.5px, -13.5px);
    }

    &:hover {
      .courses__item_name h4 {
        color: var(--site-theme-color);
      }
    }

    .courses__item_name {
      display: flex;
      margin-bottom: 26px;

      .courses__item_icon {
        width: 86px;
        flex-shrink: 0;
        margin-top: 5px;
      }
      h4 {
        color: #000;
        font-size: 32px;
        font-weight: 600;
        line-height: 117%;
        letter-spacing: -0.8px;
        margin: 0;
        margin-left: 43px;
      }
    }
    .courses__item_liked {
      display: flex;
      align-items: center;

      .courses__item_liked_icon {
        display: flex;
        align-items: center;
        width: 86px;

        label {
          color: #000;
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
          margin: 0;
          margin-left: 16px;
        }
      }

      span {
        color: #000;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 43px;
      }
    }
    .courses__item_desc {
      color: #000;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 22px;
      margin-bottom: 31px;
      flex-grow: 1;
    }

    .courses__item_time_steps {
      color: #000;
      font-size: 15px;
      line-height: 20px;

      label {
        font-weight: 600;
        margin: 0;
        margin-right: 8px;
      }
    }

    .courses__item_category {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 7px;
      margin-top: 11px;

      label {
        color: #000;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        margin-right: 8px;
      }
    }

    .courses__item_tag {
      padding: 2px 8px;
      background: rgba(0, 0, 0, 0.05);
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: black;
      margin-right: 5px;
    }
    .courses__item_footer {
      display: flex;
      align-items: center;
      margin-top: 38px;

      span {
        color: #000;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin-left: 57px;
        margin-bottom: -4px;
      }
    }
  }
}

.coming_soon {
  padding: 114px 64px 92px;

  .coming_soon__tile {
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    color: black;
    letter-spacing: -0.8px;
    margin: 0;
    margin-bottom: 26px;
  }

  .coming_soon__desc {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: black;
    display: flex;
    justify-content: space-between;

    p {
      max-width: 616px;
      margin: 0;
    }
    .coming_soon__link {
      display: flex;
      align-items: flex-end;
      align-self: flex-end;
      margin-right: 54px;

      a {
        text-decoration: underline;
      }

      span {
        margin-left: 59px;
      }
    }
  }
}

.learn_more {
  display: flex;
  column-gap: 11px;
  margin-bottom: 63px;

  .learn_more__left {
    padding: 58px 91px 53px 94px;
    background: #907c99;
  }

  .learn_more__right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    padding: 181px 86px 0;
    background: #907c99;

    h4 {
      color: #fff;
      font-size: 32px;
      font-weight: 400;
      line-height: 44px;
      margin-bottom: 55px;
      margin-top: 0;
    }
    .learn_more__btn {
      width: 276px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: black;
      background: white;
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }
}

/** courses page */
.search {
  display: flex;
  column-gap: 11px;

  .search__left {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #905c99;
    padding: 13px 7px;
  }

  .search__right {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-left: 27px;
    flex-grow: 1;
    background: #63d0ff;

    h5 {
      margin: 0;
      font-size: 21px;
      font-weight: 400;
      line-height: 136%;
      color: #907c99;
    }
  }
}

.community {
  display: flex;
  flex-direction: column;
  margin-bottom: 182px;

  .community__header {
    color: #000;
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin: 0;
    margin-bottom: 37px;
    padding: 0 64px;
  }

  .community__silder {
    position: relative;
    padding: 0 64px;

    :global(.slick-prev),
    :global(.slick-next) {
      top: 0;
      transform: none;
      width: 64px;
      height: 100%;
      display: flex !important;
      align-items: center;
      justify-content: center;

      &::before {
        content: none;
      }
    }

    :global(.slick-list) {
      max-height: 296px;
    }

    :global(.slick-slide) {
      margin-right: 94px;
    }
  }

  .card {
    height: 296px;
    position: relative;
  }

  .headerCard {
    display: flex;

    img {
      width: 41px;
      height: 41px;
      flex-shrink: 0;
      filter: grayscale(1);
      object-fit: cover;
      object-position: top;
      margin-top: 4px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #000000;
    width: 253px;
    margin-left: 32px;
  }

  .position {
    font-size: 15px;
    line-height: 136%;
    font-weight: normal;
  }

  .name {
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    margin: 0;
  }

  .contentCard {
    width: 350px;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    position: absolute;
    left: 0;
    top: 108px;
  }
}

.course_detail {
  display: flex;
  margin-top: 12px;

  .course_detail__left {
    position: relative;
    background: #907c99;
    padding: 87px 88px 88px 91px;
  }
  .course_detail__left_academy_icon {
    position: absolute;
    left: 0;
    top: 0;
    background: #63d0ff;
    border-right: 10px solid white;
    border-bottom: 10px solid white;
    padding: 13px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .course_detail__right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 75px;
    margin-top: 48px;
  }
}

.course_detail__info {
  display: flex;
  flex-direction: column;
  max-width: 608px;

  .course_detail__info_main {
    display: flex;
  }
  .course_detail__info_icon {
    width: 86px;
    flex-shrink: 0;
    margin-top: 5px;
  }
  .course_detail__info_name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 43px;

    h4 {
      color: #000;
      font-size: 32px;
      font-weight: 600;
      line-height: 117%;
      letter-spacing: -0.8px;
      margin: 0;
    }
  }

  .course_detail__info_liked {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .course_detail__info_liked_icon {
      display: flex;
      align-items: center;

      label {
        color: #000;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
        margin-left: 16px;
      }
    }

    span {
      color: #000;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 37px;
    }
  }
  .course_detail__info_desc {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 73px;
    margin-bottom: 43px;
    flex-grow: 1;
  }

  .course_detail__info_time_steps {
    color: #000;
    font-size: 15px;
    line-height: 20px;

    label {
      font-weight: 600;
      margin: 0;
      margin-right: 8px;
    }
  }

  .course_detail__info_category {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 7px;
    margin-top: 11px;

    label {
      color: #000;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      margin: 0;
      margin-right: 8px;
    }
  }
  .course_detail__info_tag {
    padding: 2px 8px;
    background: rgba(0, 0, 0, 0.05);
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: black;
    margin-right: 5px;
  }
  .course_detail__info_footer {
    display: flex;
    align-items: center;
    margin-top: 52px;

    span {
      color: #000;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 57px;
      margin-bottom: -4px;
    }
  }
  .course_detail__launch_btn {
    width: 274px;
    height: 56px;
    margin-top: 63px;
  }
}

.expect {
  display: flex;
  flex-direction: column;
  padding: 0 64px;

  .expect__sticky_content {
    padding-top: 62px;
    padding-bottom: 20px;
    background: white;
    display: flex;
    position: sticky;
    top: 0;

    .course_detail__launch_btn {
      margin-top: 34px;
    }
    .course_detail__info {
      visibility: hidden;
      transition: visibility 0.1s ease;
    }

    &.is_sticky {
      .course_detail__info {
        visibility: visible;
      }
    }
  }

  .expect__title {
    color: #000;
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    max-width: 535px;
    width: 100%;
    margin-right: 74px;
  }
  .expect__tabs {
    display: flex;
    margin-bottom: 84px;

    .expect__tabs_list {
      display: flex;
      flex-direction: column;
      row-gap: 14px;
      width: 207px;
      flex-shrink: 0;
      margin-right: 91px;

      span {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        color: black;
        cursor: pointer;
        &.active,
        &:hover {
          color: var(--site-theme-color);
        }
      }
    }
    .expect__tab_content {
      background: #f3f3f3;
      padding: 48px 64px 32px 19px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: black;
    }
    .course_desc {
      margin-bottom: 27px;
      max-width: 727px;
    }
    .chapter_preview {
      display: flex;
      flex-direction: column;

      .chapter_preview__title {
        margin-top: 0;
        margin-bottom: 11px;
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
      }
      .chapter_preview_list {
        display: flex;
        column-gap: 21px;
        ul {
          list-style-type: none;
          list-style-position: inside;
          max-width: 450px;
          display: flex;
          flex-direction: column;
          row-gap: 21px;
          padding: 0;

          li {
            b {
              font-weight: 600;
            }
            p {
              margin: 0;
            }
          }
        }
      }
    }
    .expect__launch_btn {
      width: 274px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: white;
      background: black;
      transition: background 0.3s ease;
      cursor: pointer;
      margin-top: 43px;
      &:hover {
        background: var(--site-theme-color);
      }
    }
    .expect__complete_quiz {
      margin-top: 23px;
      margin-bottom: 0;

      a {
        text-decoration: underline;
      }
    }
    .expect__navigations {
      display: flex;
      justify-content: space-between;
      margin-top: 28px;

      .expect__navigation {
        background: none;
        border: none;
        outline: none;
        padding: 0;
        display: flex;
        align-items: center;
        column-gap: 19px;

        &:disabled {
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.4;
        }

        &:hover {
          svg path {
            stroke: var(--site-theme-color);
          }

          span {
            color: var(--site-theme-color);
          }
        }

        span {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          color: black;
        }
      }
    }
  }
}

/** course player page */
.course_player {
  position: fixed;
  top: 0;
  z-index: 21;
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
  width: 100%;
  height: 100vh;
  min-height: 600px;

  .course_player__header {
    display: flex;
    height: 48px;
    cursor: pointer;
  }
  .course_player__header_icon {
    flex-shrink: 0;
    margin-right: 11px;
  }
  .course_player__header_content {
    display: flex;
    align-items: center;
    background: #907c99;
    flex-grow: 1;
    padding-left: 20px;
  }
  .course_player__header_info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 15px;
    line-height: 20px;
    color: white;

    label {
      font-weight: 600;
      margin: 0;
    }
  }
  .course_player__header_close {
    width: 48px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  .course_player__content {
    display: flex;
    flex-grow: 1;
    padding-top: 8px;
  }
  .course_player__sidebar {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 317px;
    padding: 0 12px 12px;
    height: 100%;
    overflow: auto;
  }
  .course_player__category {
    display: flex;
    flex-direction: column;
    padding: 8px;
    background: white;
    position: relative;
  }
  .course_player__category_name {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: black;
    max-width: 241px;
    margin: 0;
    cursor: pointer;
  }
  .course_player__category_tutorials {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    margin: 10px 0;
    padding-left: 13px;
    max-width: 228px;
  }
  .course_player__category_tutorial {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: black;
    cursor: pointer;

    &:hover,
    &.active {
      color: var(--site-theme-color);
    }
  }
  .course_player__category_done {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 7px;
  }
  .course_player__category_progress {
    height: 3px;
    background: #63d0ff;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .course_player__player {
    flex-grow: 1;
    background: white;
  }
  .course_player__player_iframe {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
  }
}

@include breakpoint-sm {
  .content {
    max-width: 100%;
  }
}
