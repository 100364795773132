@import '~theme/modules/variables';
@import '~theme/mixins';

.learnSection {
  background-color: #e8d492;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px 50px;
  }

  .titleContent {
    @include ioradFont;
    line-height: 69%;
    font-size: 377px;
    color: #0b380a;
    padding-top: 17px;
    padding-bottom: 73px;

    @media screen and (max-width: 1336px) {
      font-size: 27vw;
    }
  }

  .content {
    font-size: 15px;
    line-height: 136%;
    color: #0b4160;
    width: 803px;
    margin: 0;

    @media screen and (max-width: 950px) {
      width: 100%;
    }

    .breakContent {
      height: 19px;
      display: block;
      background: transparent;
    }

    a {
      font-weight: 600;
    }
  }
}

.modeWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
  background-position: bottom center;
  background-repeat: no-repeat;
}

.iFrameWrapper {
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding-left: 177px;
  padding-right: 64px;
  padding-bottom: 213px;
}

.mode {
  padding-top: 30px;
  padding-bottom: 15px;

  span {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    font-weight: bold;
    cursor: pointer;

    &.active {
      color: var(--site-theme-color);
    }
  }

  span + span {
    margin-left: 42px;
  }
}

.iframe {
  display: none;
  justify-content: center;

  &.isVisible {
    display: flex;
  }
}

@include breakpoint-sm {
  .learnSection {
    .contentWrapper {
      padding: 44px 24px 37px;
    }

    .titleContent {
      font-size: 175px;
      line-height: 58%;
      word-break: break-all;
      padding-top: 13px;
      padding-bottom: 39px;
    }

    .content {
      font-size: 13px;
      width: 100%;
      margin: 0;
    }
  }

  .modeWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_bottom.png');
    background-size: contain;
  }

  .iFrameWrapper {
    padding: 57px 24px 130px;
  }

  .mode {
    display: none;
  }
}
