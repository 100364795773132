@import '~theme/mixins';

:global(.react-google-flight-datepicker) {
  :global(.selected-date), :global(.date-input-separator) {
    font-size: 15px;
  }
  :global(.calendar-wrapper) {
    margin: 0px 30px 8px;
  }
  :global(.dialog-date-picker) {
    width: 650px;
  }
  :global(.dialog-content) {
    border-top: none;
    border-bottom: none;
  }
  :global(.date-picker) {
    padding: 0;
  }
  :global(.icon-calendar) {
    display: none !important;
  }
  :global(.date-picker-input) {
    border: none;
    height: 28px;
  }
  :global(.change-date-group) {
    display: none;
  }
  :global(.date-picker-input) {
    justify-content: flex-start;
  }
  :global(.date-picker-date-group) {
    justify-content: flex-start;
  }
  :global(.date) {
    width: auto;
    flex-grow: 0;
    outline: none;
    color: #000000;
    text-decoration: underline;
    padding: 0;
    background: transparent;
    cursor: pointer;
    &:focus {
      background: transparent;
      border: none;
      color: var(--site-theme-color);
      text-decoration: none;
      &::after {
        display: none;
      }
    }

    @include breakpoint-sm {
      border: none;
    }
  }
  :global(.date.is-focus) {
    height: 100%;
    background-color: transparent;
    color: var(--site-theme-color);
    &:after {
      content: unset;
    }
  }
  :global(.date-input-separator) {
    padding: 0 6px;
    color: #000000;
    line-height: 28px;
    display: flex;
    align-items: center;
  }
  :global(#start-date-input-button::before) {
    display: none;
  }
  :global(.dialog-date-picker.open) {
    top: 30px;
    box-shadow: none;
    border-radius: 0;
    background-color: #f2f2f2;
  }
  :global(.calendar-flippers) {
    align-items: flex-start;
  }
  :global(.flipper-button) {
    margin: 0;
    margin-top: 17px;
    box-shadow: none;
    border: none;
    background: transparent;
    border-radius: 0;
    outline: none;
    &:last-child {
      margin-right: 10px;
    }
    svg {
      width: 32px;
      height: 32px;
    }
  }
  :global(.month-calendar) {
    padding-bottom: 13px;
    margin-right: 30px;
    width: 273px;
    &:global(.isAnimating) {
      position: absolute;
      left: -303px;
    }
  }
  :global(.month-name) {
    font-size: 21px;
    padding: 26px 10px 20px!important;
    text-align: left;
    color: #000000;
    font-weight: bold;
    margin-bottom: 0;
  }
  :global(.weekday) {
    font-weight: bold;
    color: #000000;
    font-size: 15px;
  }
  :global(.day) {
    width: 39px;
    height: 38px;
    &:global(.disabled) :global(.text-day) {
      color: rgba(0, 0, 0, 0.26);
    }
  }
  :global(.day.selected.hovered::after) {
    border-radius: 0;
    background-color: var(--site-theme-color);
  }
  :global(.day.hovered) {
    background-color: #f3daef;
  }
  :global(.text-day) {
    color: #000000;
    font-size: 15px;
  }
  :global(.day) {
    outline: none;
  }
  :global(.day:hover) {
    :global(.text-day) {
      color: var(--site-theme-color);
    }
  }
  :global(.day.hovered:hover) {
    background: #f3daef;
    :global(.text-day) {
      color: var(--site-theme-color);
    }
  }
  :global(.day::after) {
    display: none!important;
  }
  :global(.day.selected) {
    color: #000000;
    background: var(--site-theme-color);
    border-radius: unset;
  }
  :global(.day.selected:hover) {
    :global(.text-day) {
      color: #fff;
    }
  }
  :global(.day.selected.hovered) {
    color: #000000;
    background: var(--site-theme-color);
  }
  :global(.day.selected.hovered:hover) {
    :global(.text-day) {
      color: #fff;
    }
  }
  :global(.day.selected.hovered.end) {
    background: var(--site-theme-color);
  }
  :global(.day.hovered.end) {
    background: #f3daef;
  }
  :global(.day.selected.hovered::after) {
    display: none;
  }
  :global(.day.hovered:hover::after) {
    display: none
  }
  :global(.background-day.last-day),
  :global(.background-day.first-day) {
    display: none;
  }
  :global(.dialog-footer .submit-button) {
    font-size: 13px;
    background: transparent;
    color: #000;
    font-weight: 600;
    text-transform: initial;
  }
  :global(.dialog-footer .reset-button) {
    display: none;
  }

  @include breakpoint-sm {
    :global(.dialog-date-picker.open) {
      top: 100%;
    }
    :global(.dialog-date-picker) {
      width: 100%;
    }
    :global(.calendar-wrapper) {
      margin: 0;
    }
    :global(.month-calendar) {
      padding-bottom: 0;
      margin-right: 0;
      width: 100%;
    }
    :global(.month-name) {
      padding: 0 !important;
    }
    :global(.day) {
      width: 14.28571%;
      height: 44px;
    }
  }
}