@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/modules/variables';

.ask_question_modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: #{$socialOverlayZIndex + 6};
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  &.isSmallScreen {
    .ask_question_modal__header_title span {
      @include fontExtension;
    }
    .ask_question_modal__form {
      margin-top: 0;
    }

    .ask_question_modal__form_input {
      @include fontExtension;
    }

    .similar_questions__list h6 {
      @include fontExtension;
    }

    .ask_question_modal__form_group {
      flex-direction: column;
      row-gap: 16px;
    }

    .ask_question_modal__form_dropdown span {
      @include fontExtension;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .ask_question_modal__form_submitBtn {
      margin: 0 16px;
      width: auto;
    }

    .ioradicals__header span {
      @include fontExtension;
    }

    .ioradicals__table_row > div {
      @include fontExtension;
    }

    .ioradicals_popup__header span {
      @include fontExtension;
    }

    .ioradicals_popup__search input {
      @include fontExtension;
    }

    .dropdown_popup__header span {
      @include fontExtension;
    }

    .dropdown_popup__search input {
      @include fontExtension;
    }

    .dropdown_popup__content {
      padding: 24px 0;
    }

    .dropdown_popup__list_item span {
      @include fontExtension;
    }

    @media screen and (max-height: 390px) {
      .ioradicals {
        height: 56px;
        overflow: hidden;
      }
    }
  }

  &__header {
    display: flex;
    flex-shrink: 0;
    height: 48px;
    position: relative;
    cursor: pointer;

    &:hover {
      .ask_question_modal__header_close {
        opacity: 1;
      }
      &:after {
        content: 'Close';
        background: #f4f4f4;
        @include fontExtension;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &_title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      background: $backgroundTiles;

      span {
        @include fontExtension;
        color: black;
      }
    }

    &_close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 48px;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
      opacity: 0.4;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    max-width: 600px;
    margin-top: 132px;

    &_row {
      position: relative;
    }

    &_group {
      display: flex;
      column-gap: 16px;
    }

    &_dropdown {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 56px;
      flex-grow: 1;
      flex-basis: auto;
      flex-shrink: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;

      img {
        width: 24px;
        flex-shrink: 0;
        margin-right: 24px;
      }

      span {
        flex-grow: 1;
        @include fontHelpCenter;
        margin-right: 24px;

        &.placeholder {
          color: rgba(0, 0, 0, 0.4);
        }
      }

      svg {
        flex-shrink: 0;
      }
    }

    &_submitBtn {
      margin: 0 auto;
      width: 320px;
      height: 56px;
      @include fontHelpCenter;
      color: white;

      &:disabled {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.dropdown_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    padding: 0 16px;
    cursor: pointer;
    position: relative;

    span {
      @include fontExtension;
    }

    svg {
      position: absolute;
      right: 21px;
    }
  }

  &__search {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: none;
      outline: none;
      width: 100%;
      @include fontHelpCenter;
      caret-color: var(--marker-color);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 40px 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 0 16px;

    &_item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 56px;
      cursor: pointer;
      width: 100%;
      max-width: 400px;
      column-gap: 24px;

      &.active {
        order: -1;

        svg {
          display: flex;
        }
      }

      img {
        width: 24px;
        height: auto;
        flex-shrink: 0;
      }

      span {
        @include fontHelpCenter;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      svg {
        display: none;
        flex-shrink: 0;
      }
    }

    &_empty {
      padding: 16px 0;
      @include fontHelpCenter;
    }
  }
}

.similar_questions {
  display: flex;
  flex-direction: column;
  background: white;
  position: absolute;
  z-index: 2;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;

  .highlight {
    color: var(--marker-color);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 56px;
    padding: 0 16px;

    span {
      @include fontHelpCenter;
      color: rgba(0, 0, 0, 0.4);

      &[role='button'] {
        color: black;
        font-weight: 600;
      }
    }
  }

  &__content {
    height: 100%;
    max-height: 340px;
  }

  &__list {
    display: flex;
    flex-direction: column;

    &_item {
      padding: 16px;
      @include fontHelpCenter;
      word-break: break-word;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      &:hover,
      &:focus {
        background: rgba(0, 0, 0, 0.05);
        color: inherit;
      }
    }

    &:hover {
      .similar_questions__loadmore {
        display: block;
      }
    }
  }

  &__loadmore {
    display: none;
  }
}

.ioradicals {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
  max-width: 900px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: none;

  &__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    background: white;
    padding: 0 16px;
    cursor: pointer;

    span {
      @include fontHelpCenter;
      margin-right: 24px;
    }
  }

  &__table {
    display: flex;
    flex-direction: column;

    &_header {
      display: flex;
      flex-shrink: 0;
      height: 48px;
      position: sticky;
      top: -40px;
      background: white;
      z-index: 1;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        @include fontHelpCenter;
        color: rgba(0, 0, 0, 0.4);
        text-align: center;
        padding: 0 5px;
      }

      @media (max-width: 1050px) {
        span {
          font-size: 13px;

          &:first-child {
            max-width: 35px;
          }
        }
      }
    }

    &_body {
      display: flex;
      flex-direction: column;
      row-gap: 2px;
    }

    &_row {
      display: flex;
      flex-shrink: 0;
      min-height: 48px;
      cursor: pointer;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        @include fontHelpCenter;

        .ioradical_avatar {
          position: relative;
          width: 24px;
          height: 24px;

          img {
            width: 100%;
          }

          svg {
            position: absolute;
            right: -15px;
            top: -14px;
          }
        }

        @include breakpoint-sm {
          font-size: 13px;
  
          &:first-child {
            max-width: 35px;
          }
        }
      }

      .ioradical_info {
        justify-content: start;

        &__name {
          margin-left: 24px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        @media (max-width: 1050px) {
          justify-content: center;

          .ioradical_info__name {
            display: none;
          }
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.ioradicals_popup {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 2;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 48px;
    background: #f4f4f4;
    padding: 0 16px;
    cursor: pointer;
    position: relative;

    span {
      @include fontExtension;
    }

    svg {
      position: absolute;
      right: 21px;
    }
  }

  &__search {
    display: flex;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &:focus-within {
      border-bottom-color: black;
    }

    input {
      margin: 0;
      padding: 0 16px;
      border: none;
      outline: none;
      width: 100%;
      @include fontHelpCenter;
      caret-color: var(--marker-color);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 40px 0;
  }
}
