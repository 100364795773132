@import '../../theme/guidelines';
@import '../../theme/form';

.share {
  padding: 0;

  &.isLoading {
    visibility: hidden;
  }
  &.asEditorSharePopup {
    z-index: 1000; // above Editor iframe
  }

  .share_container {
    margin: 0 auto;
    padding: 89px 0 0 0;

    :global(.tab-content) {
      width: 100%;
      margin: 0 auto;

      @media screen and (min-width: 768px) {
        margin-top: 64px;
      }
    }

    :global(.nav-tabs) {
      display: flex;
      padding: 0;
      border: none;
      padding-bottom: 20px;

      @media screen and (min-width: 768px) {
        position: fixed;
        z-index: 3;
        top: 48px;
        left: 0;
        padding-top: 34px;
        padding-left: 40px;
        background-color: #fff;
        width: 100%;
      }

      @media screen and (max-width: 380px) {
        column-gap: 10px;
        justify-content: space-between;

        &::before,
        &::after {
          content: unset;
        }

        li {
          margin-right: 0 !important;
        }
      }

      :global(li) {
        display: inline-block;
        text-align: left;
        margin-right: 30px;
        border: none;

        &:last-child {
          margin-left: 0;
        }

        :global(a) {
          cursor: pointer;
          padding: 0;
          font-size: 15px;
          font-weight: 600;
          line-height: 24px;
          border: none;
          font-family: $fontBaseV2;
          background-color: #ffffff;
          color: #000000;
          white-space: nowrap;
          margin: 0;

          &:focus {
            outline: none;
            background: none;
          }

          &:hover {
            color: var(--site-theme-color);
          }
        }

        &:global(.active) {
          :global(a) {
            color: var(--site-theme-color);
            cursor: default;

            &:focus {
              outline: none;
            }
          }
        }

        &:global(.disabled) {
          :global(a) {
            color: #777777;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  :global(.tab-pane) {
    width: 450px;

    @include breakpoint-sm {
      width: 100%;
    }
  }

  :global(#tab-share-tutorial-pane-invite) {
    width: 100%;
  }

  :global(#tab-share-tutorial-pane-assign) {
    width: 100%;
  }

  .player_mode_select :global(.react-selectize.dropdown-menu) {
    max-height: none;
  }
}
