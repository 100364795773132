@import '../../theme/guidelines_variables';
@import '../Auth/page';
@import '~theme/mixins';

.questionnaireUpgradePage {
  padding-top: 40px;
  height: 100%;

  @include breakpoint-sm {
    padding: 40px 25px;
  }

  & > div, .auth, .questionnaire_upgrade {
    height: 100%;
  }
}

.auth {
  .questionnaire_upgrade {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 142px;
    @include breakpoint-sm {
      padding-top: 115px;
    }

    .formSpace {
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    .contactInfo {
      font-size: 15px;
      text-align: left;
      padding: 43px 64px;
      margin-top: auto;
      a:hover {
        color: var(--site-theme-color);
      }

      @include breakpoint-sm {
        padding: 0;
        font-size: 10px;
        margin-bottom: 20px;

        .link {
          text-decoration: none;
          color: var(--site-theme-color);
        }
      }
    }
  }

  .questionnaireUpgradeForm {
    margin: 0 auto;;
    width: 350px;
    display: flex;
    flex-direction: column;

    * {
      text-align: left;
    }

    .checkbox.hideControl,
    .radio.hideControl {
      padding-left: 0;
    }

    input[type="checkbox"],
    input[type="radio"] {
      margin-right: 10px;
    }

    .form_group_title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    @include breakpoint-sm {
      width: 100%;
      height: 100%;

      .submit_wrapper {
        margin-top: auto;
        margin-bottom: 25px;
      }

      .signInButton {
        width: 240px;
        height: 48px;
        margin: 0 auto;
      }
    }
  }
}
