@import '~theme/modules/variables';
@import '~theme/form';
@import './includes';

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include ontopOfOverlay();

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -7px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:focus-within:after {
    background-color: var(--site-theme-color);
  }
}

.button {
  border: none;
  background: transparent;
  padding: 0;
  margin-left: 10px;
  font-family: $fontBaseV2;
  font-size: 10px;
  color: #000000;
  outline: none;

  &.addButton {
    font-weight: bold;
  }

  &:hover {
    color: var(--site-theme-color);
  }

  &.disabled {
    color: #868686;
    cursor: not-allowed;
  }
}

.nameInput {
  position: relative;
  padding-right: 40px;
  height: 20px;
  caret-color: var(--site-theme-color);
  border-bottom: none;
  padding-top: 1px;

  &:hover, &:focus {
    border-bottom: none;
  }
}

.buttonContainer {
  position: absolute;
  right: 0;
  bottom: 2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
