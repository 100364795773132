@import '~theme/mixins';
@import '~theme/modules/variables';
@import '~theme/custom_react_google_flight_datepicker';

.summary_content {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  margin-top: 40px;

  .summary_content__filters {
    display: flex;
    column-gap: 40px;
    align-items: center;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;
  }

  .summary_content__table_container {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px;
    background: #f4f4f4;
  }

  .summary_content__table {
    display: flex;
    flex-direction: column;
  }

  .summary_content__table_header {
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: 56px;
    padding: 0 24px;
    background: #f4f4f4;
    position: sticky;
    top: 56px;
    z-index: 1;

    & > span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:not(:first-child) {
        justify-content: flex-end;
        max-width: 128px;
      }

      @include breakpoint-sm {
        &:last-child {
          display: none;
        }
      }

      .text {
        cursor: pointer;
      }

      .sortIconContainer {
        margin-left: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
}
