@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/guidelines.scss';
@import '~theme/modules/variables';

.extensionTab {
  width: 100%;
  max-width: none;
  font-family: $fontBaseV2;
  padding: 28px 45px 30px 40px;

  &.insideTutorials {
    .domainListColumn {
      margin: 0;
    }
  }

  @include breakpoint-sm {
    padding: 0 24px;
  }
}

.container {
  display: flex;
  width: 100%;
  margin-top: 126px;
  overflow: hidden;

  @include breakpoint-sm {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
}

.searchColumn {
  position: fixed;
  top: 0;
  z-index: 1;
  left: 400px;
  padding-top: 170px;
  padding-bottom: 38px;
  background: white;
  width: 349px;

  @include breakpoint-sm {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 0;
    padding-bottom: 30px;
    background: white;
    width: 100%;
    margin: 0 auto;
  }
}

.searchInputWrapper {
  position: relative;
}

.authRequiredColumn {
  margin-bottom: 30px;

  label {
    display: flex;
    align-items: center;
  }

  input {
    margin-right: 10px;
    margin-top: 0;
    vertical-align: middle;
  }

  span {
    &.parenthesized {
      color: #ccc;
    }
  }
}

.domainListColumn {
  margin: 40px 0 30px 360px;

  @include breakpoint-sm {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.domainList {
  padding: 0;
  margin: 0;
  width: 349px;

  @include breakpoint-sm {
    width: 100%;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 12px;
    font-size: 15px;
    font-family: $fontBaseV2;
    color: #000000;
    font-weight: 300;

    .domainText {
      position: relative;
      flex-grow: 1;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 33px;
        height: 100%;
        background: linear-gradient(to left, #FFFFFF 13.81%, rgba(255, 255, 255, 0) 99.61%);
      }
    }

    a {
      font-size: inherit;
      font-family: inherit;
      color: inherit;
      font-weight: inherit;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-left: 12px;

  a:last-child {
    margin-left: 14px;
  }

  .actionButton {
    color: #999999;
    font-size: 10px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: #000000;
    }
  }
}

.searchField {
  width: 100%;
  padding-bottom: 8px;
  padding-right: 40px;

  &:focus {
    border-bottom: 1px solid #000000;
  }
}

.searchIcon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.3;
}

.listItem {
  display: flex;
  justify-content: space-between;
  color: #999999;
  margin-bottom: 12px;
  font-family: $fontBaseV2;
}

.listContent {
  width: 250px;
  margin-top: 0;
  margin-bottom: 45px;
}

.emptyTutorial {
  margin-left: 325px;
  margin-top: 80px;
  font-size: 15px;
  font-weight: bold;

  @include breakpoint-sm {
    margin-left: 0;
    margin-top: 30px;
  }
}
