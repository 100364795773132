@import '~theme/modules/variables';
@import '~theme/mixins';

.trustCenterPage {
  display: flex;
  flex-direction: column;
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }
}

.sectionTitle {
  display: flex;
  flex-direction: column;
  background: #2891be;

  .sectionTitle_content {
    width: 100%;
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 55px 64px 82px;
  }

  .sectionTitle_content_title {
    h2 {
      @include ioradFont;
    }

    img {
      width: 100%;
    }
  }

  .sectionTitle_content_search {
    margin-top: 84px;
    margin-left: auto;
    margin-right: 270px;
    text-align: left;
    width: 432px;
    position: relative;

    .inputIcon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;

      &:hover {
        .closeIcon path {
          stroke: var(--site-theme-color);
        }
      }
    }

    .closeIcon {
      cursor: pointer;
    }

    .searchInput {
      width: 100%;
      height: 32px;
      padding-left: 45px;
      padding-right: 15px;
      font-size: 15px;
      color: #000;
      border: none;
      outline: none;
      caret-color: var(--site-theme-color);

      &::placeholder {
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.sectionContent {
  display: flex;
  flex-direction: column;

  .sectionContent_content {
    width: 100%;
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 88px 0 0 64px;
    display: flex;
  }

  .sectionContent_content_tabs {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    row-gap: 17px;
    width: 252px;

    a {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;

      &:hover,
      &.active {
        color: var(--site-theme-color);
      }
    }
  }

  .sectionContent_content_body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.footerSection {
  background: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 180px;
  padding-bottom: 100px;
  margin-top: -2px;

  .footerSection_wrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .footerSection_content {
    display: flex;
    justify-content: space-between;
    padding-left: 64px;
    padding-right: 158px;
  }

  .footerSection_title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .authWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .signUpBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-bottom: 22px;

    &:hover {
      color: #fff;
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .trustCenterPage {
    margin-top: 36px;

    &.isLogged {
      margin-top: 36px;
    }
  }

  .sectionTitle {
    .sectionTitle_content {
      max-width: 100%;
      padding: 0;
    }

    .sectionTitle_content_search {
      display: none;
    }
  }

  .sectionContent {
    .sectionContent_content {
      max-width: 100%;
      flex-direction: column;
      padding: 0;
    }

    .sectionContent_content_tabsMobile {
      position: relative;
      margin-top: 21px;
      margin-bottom: 15px;

      &.showDropdown {
        .sectionContent_content_tabsMobile_activeTab {
          svg {
            transform: rotate(180deg);
          }
        }
        .sectionContent_content_tabsMobile_dropdown {
          display: flex;
        }
      }

      .sectionContent_content_tabsMobile_activeTab {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 11px;

        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: black;
        }
      }

      .sectionContent_content_tabsMobile_dropdown {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        background: white;
        flex-direction: column;
        align-items: flex-end;
        row-gap: 17px;
        width: 100%;
        padding: 17px 51px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }

      .sectionContent_content_tabsMobile_dropdown_item {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;

        &:hover,
        &.active {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .footerSection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;
    padding-top: 166px;
    padding-bottom: 198px;

    .footerSection_content {
      flex-direction: column;
      padding: 0 24px;
    }

    .footerSection_title {
      font-size: 21px;
      margin-bottom: 71px;
    }

    .authWrapper {
      padding: 0 16px;
    }

    .signUpBtn {
      width: 295px;
      font-size: 13px;
    }
  }
}
