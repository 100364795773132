$overlayZIndex: 21;
@mixin ontopOfOverlay {
  z-index: $overlayZIndex + 1;
  position: relative;
}

@keyframes blink-animation-keyframe {
  0% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

$halfBlinkDuration: 300ms;

@mixin blinkAnimation {
  animation: blink-animation-keyframe $halfBlinkDuration linear infinite alternate;
}

@mixin addingAnimation {
  color: var(--site-theme-color);
  .notifyText {
    display: block;
    @include blinkAnimation();
  }
}

$bottomBarHeight: 50px;
