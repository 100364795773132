@import '~theme/modules/variables';
@import '~theme/mixins';

.selectApps {
  display: flex;
  height: 100vh;
  min-height: 600px;

  .selectApps__block {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .selectApps__header {
    .selectApps__logo {
      padding: 24px 24px 40px;
    }
  }

  .selectApps__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 40px;
    padding-bottom: 24px;
    overflow-y: auto;
  }

  .selectApps__content_inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 40px;
    }
  }

  .selectApps__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-size: 15px;
    line-height: 20px;
    color: black;
  }

  .selectApps__list {
    display: flex;
    flex-direction: column;

    .selectApps__item {
      display: flex;
      align-items: center;
      column-gap: 16px;
      height: 56px;
      padding: 0 16px 0 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-top: none;
      cursor: pointer;
      color: black;

      &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      .selectApps__item_icon {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        background-image: url('~assets/img/devices/website-icon.svg');

        &[data-id='thesquare'] {
          background-image: url('~assets/img/devices/the-square-icon.svg');
        }
        &[data-id='googleAnalytics'] {
          background-image: url('~assets/img/devices/google-analytics-icon.png');
        }
        &[data-id='slack'] {
          background-image: url('~assets/img/devices/slack-icon.png');
        }
        &[data-id='zendesk'] {
          background-image: url('~assets/img/devices/zendesk-icon.svg');
        }
        &[data-id='helpdocs'] {
          background-image: url('~assets/img/devices/helpdocs-icon.png');
        }
        &[data-id='helpscout'] {
          background-image: url('~assets/img/devices/helpscout-icon.svg');
        }
        &[data-id='servicenow'] {
          background-image: url('~assets/img/devices/servicenow-icon.svg');
        }
        &[data-id='salesforce'] {
          background-image: url('~assets/img/devices/salesforce-icon.svg');
        }
        &[data-id='getguru'] {
          background-image: url('~assets/img/devices/getguru-icon.svg');
        }
        &[data-id='freshservice'] {
          background-image: url('~assets/img/devices/freshservice-icon.svg');
        }
        &[data-id='confluence'] {
          background-image: url('~assets/img/devices/confluence-icon.svg');
        }
        &[data-id='dynamics'] {
          background-image: url('~assets/img/devices/dynamics-icon.svg');
        }
        &[data-id='canvaslms'] {
          background-image: url('~assets/img/devices/canvaslms-icon.png');
        }
        &[data-id='hubspot'] {
          background-image: url('~assets/img/devices/hubspot-icon.svg');
        }
        &[data-id='youtube'] {
          background-image: url('~assets/img/devices/youtube-icon.svg');
        }
        &[data-id='gdrive'] {
          background-image: url('~assets/img/devices/google-drive-logo.svg');
        }
        &[data-id='wordpress'] {
          background-image: url('~assets/img/devices/wordpress-logo.svg');
        }
        &[data-id='intercom'] {
          background-image: url('~assets/img/devices/intercom-icon.svg');
        }
        &[data-id='confluenceServer'],
        &[data-id='confluenceCloud'],
        &[data-id='jiraServer'] {
          background-image: url('~assets/img/devices/atlassian-logo.svg');
        }
        &[data-id='saml'] {
          background-image: url('~assets/img/devices/sso.png');
        }
        &[data-id='oidc'] {
          background-image: url('~assets/img/devices/oidc-icon.png');
        }
        &[data-id='360learning'] {
          background-image: url('~assets/img/devices/360learning-icon.png');
        }
        &[data-id='coassemble'] {
          background-image: url('~assets/img/devices/coassemble-icon.png');
        }
        &[data-id='docebo'] {
          background-image: url('~assets/img/devices/docebo-icon.png');
        }
        &[data-id='easygenerator'] {
          background-image: url('~assets/img/devices/easygenerator-icon.png');
        }
        &[data-id='freshdesk'] {
          background-image: url('~assets/img/devices/freshdesk-icon.png');
        }
        &[data-id='groovehq'] {
          background-image: url('~assets/img/devices/groovehq-icon.png');
        }
        &[data-id='lessonly'] {
          background-image: url('~assets/img/devices/seismicLearning-icon.png');
        }
        &[data-id='litmos'] {
          background-image: url('~assets/img/devices/litmos-icon.png');
        }
        &[data-id='rise'] {
          background-image: url('~assets/img/devices/rise-icon.png');
        }
        &[data-id='skilljar'] {
          background-image: url('~assets/img/devices/skilljar-icon.png');
        }
        &[data-id='slab'] {
          background-image: url('~assets/img/devices/slab-icon.png');
        }
        &[data-id='talentlms'] {
          background-image: url('~assets/img/devices/talentlms-icon.png');
        }
        &[data-id='trainual'] {
          background-image: url('~assets/img/devices/trainual-icon.png');
        }
        &[data-id='workramp'] {
          background-image: url('~assets/img/devices/workramp-icon.png');
        }
        &[data-id='absorblms'] {
          background-image: url('~assets/img/devices/absorblms-icon.png');
        }
        &[data-id='notion'] {
          background-image: url('~assets/img/devices/notion-icon.png');
        }
        &[data-id='hownow'] {
          background-image: url('~assets/img/devices/hownow-icon.png');
        }
        &[data-id='bigtincan'] {
          background-image: url('~assets/img/devices/bigtincan-icon.png');
        }
        &[data-id='northpass'] {
          background-image: url('~assets/img/devices/gainsightCE-icon.png');
        }
        &[data-id='seismic'] {
          background-image: url('~assets/img/devices/seismic-icon.png');
        }
        &[data-id='dokeos'] {
          background-image: url('~assets/img/devices/dokeos-icon.png');
        }
        &[data-id='learnermobile'] {
          background-image: url('~assets/img/devices/learnermobile-icon.png');
        }
        &[data-id='thoughtindustries'] {
          background-image: url('~assets/img/devices/thoughtindustries-icon.png');
        }
        &[data-id='tovuti'] {
          background-image: url('~assets/img/devices/tovuti-icon.png');
        }
        &[data-id='ewebinar'] {
          background-image: url('~assets/img/devices/ewebinar-icon.png');
        }
        &[data-id='ceros'] {
          background-image: url('~assets/img/devices/ceros-icon.png');
        }
        &[data-id='thinkific'] {
          background-image: url('~assets/img/devices/thinkific-icon.png');
        }
        &[data-id='elucidat'] {
          background-image: url('~assets/img/devices/elucidat-icon.png');
        }
        &[data-id='brightspace'] {
          background-image: url('~assets/img/devices/brightspace-icon.png');
        }
        &[data-id='prezi'] {
          background-image: url('~assets/img/devices/prezi-icon.png');
        }
        &[data-id='schoology'] {
          background-image: url('~assets/img/devices/schoology-icon.png');
        }
        &[data-id='matrixlms'] {
          background-image: url('~assets/img/devices/matrixlms-icon.png');
        }
        &[data-id='clickup'] {
          background-image: url('~assets/img/devices/clickup-icon.png');
        }
        &[data-id='continu'] {
          background-image: url('~assets/img/devices/continu-icon.png');
        }
        &[data-id='ihgskillsacademy'] {
          background-image: url('~assets/img/devices/ihgskillsacademy-icon.png');
        }
        &[data-id='learnupon'] {
          background-image: url('~assets/img/devices/learnupon-icon.png');
        }
        &[data-id='usewhale'] {
          background-image: url('~assets/img/devices/usewhale-icon.png');
        }
        &[data-id='questionmark'] {
          background-image: url('~assets/img/devices/questionmark-icon.png');
        }
        &[data-id='ispringlearn'] {
          background-image: url('~assets/img/devices/ispringlearn-icon.png');
        }
        &[data-id='gcubelms'] {
          background-image: url('~assets/img/devices/gcubelms-icon.png');
        }
        &[data-id='moodle'] {
          background-image: url('~assets/img/devices/moodle-icon.png');
        }
        &[data-id='tiled'] {
          background-image: url('~assets/img/devices/tiled-icon.png');
        }
        &[data-id='learntowin'] {
          background-image: url('~assets/img/devices/learntowin-icon.png');
        }
        &[data-id='supered'] {
          background-image: url('~assets/img/devices/supered-icon.svg');
        }
        &[data-id='canva'] {
          background-image: url('~assets/img/devices/canva-icon.svg');
        }
        &[data-id='flowla'] {
          background-image: url('~assets/img/devices/flowla-icon.svg');
        }
        &[data-id='trumpet'] {
          background-image: url('~assets/img/devices/trumpet-icon.svg');
        }
        &[data-id='cd2learning'] {
          background-image: url('~assets/img/devices/cd2learning-icon.png');
        }
        &[data-id='skyprep'] {
          background-image: url('~assets/img/devices/skyprep-icon.svg');
        }
        &[data-id='partnerportal'] {
          background-image: url('~assets/img/devices/partnerportal-icon.svg');
        }
        &[data-id='eloomi'] {
          background-image: url('~assets/img/devices/eloomi-icon.png');
        }
        &[data-id='evolveauthoring'] {
          background-image: url('~assets/img/devices/evolveauthoring-icon.svg');
        }
        &[data-id='document360'] {
          background-image: url('~assets/img/devices/document360-icon.svg');
        }
        &[data-id='zoho'] {
          background-image: url('~assets/img/devices/zoho-icon.svg');
        }
        &[data-id='gainsight'] {
          background-image: url('~assets/img/devices/gainsight-icon.svg');
        }
        &[data-id='googlemail'] {
          background-image: url('~assets/img/devices/googlemail-icon.svg');
        }
        &[data-id='googleclassroom'] {
          background-image: url('~assets/img/devices/googleclassroom-icon.svg');
        }
        &[data-id='monday'] {
          background-image: url('~assets/img/devices/monday-icon.svg');
        }
        &[data-id='gracehill'] {
          background-image: url('~assets/img/devices/gracehill-icon.png');
        }
        &[data-id='gainsightcloud'] {
          background-image: url('~assets/img/devices/gainsightcloud-icon.svg');
        }
      }

      span {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        flex-grow: 1;
      }

      svg path {
        stroke: var(--site-theme-color);
      }

      &.selected,
      &:hover {
        color: var(--site-theme-color);
        border-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .selectApps__footer {
    display: flex;
    justify-content: center;
    padding: 40px 24px;
  }

  .selectApps__image {
    width: 100%;
    height: 100%;
    background: #bababa;
    padding: 56px;

    img {
      max-width: 950px;
    }
  }
}
