@import '~theme/modules/variables';
@import '~theme/mixins';
@import 'base';

.referrals {
  display: flex;
  padding-top: 40px;
  padding-right: 40px;
  padding-bottom: 65px;

  @include breakpoint-sm {
    padding-top: 24px;
    padding-bottom: 40px;
  }

  table {
    border: none;
    width: 100%;
    margin: 0;

    td {
      &.sortable {
        cursor: pointer;
      }
    }

    thead {
      border: 1px solid #dddddd;
      border-left: none;
      border-right: none;

      td {
        height: 34px;
        padding: 2px 10px;
        color: #aaa;
        font-size: 13px;
        line-height: 18px;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        box-sizing: border-box;
      }
    }

    tbody {
      td {
        padding: 15px 10px;
        font-size: 14px;
        line-height: 24px;
        height: 80px;
      }
    }
  }

  .contentWrapper {
    flex-grow: 1;
  }

  .header {
    display: flex;
    justify-content: space-between;

    @include breakpoint-sm {
      flex-direction: column;
    }

    .refresh_button {
      width: 120px;
      height: 40px;
      min-width: auto;
      margin-left: auto;
    }
  }

  .content {
    margin-top: 32px;
    position: relative;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 11px;
  }

  :global(.tutorial_popup).popup {
    :global(.popup-content) {
      padding-top: 40px;
    }
  }
}
