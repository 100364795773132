@import '~theme/guidelines_variables';
@import '~theme/mixins';

.pricing_table {
  width: 1236px;
  margin: 104px auto 42px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 63px;

  .feature_list__sticky_container {
    position: sticky;
    top: 158px;
    z-index: 1;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    transition: top 0.32s;

    &.is_logged {
      top: 113px;
    }

    &.hide_menu_bar {
      top: 91px;
    }

    &.is_relative {
      top: -200px;
    }

    &.using_new_layout {
      top: 184px;

      &.hide_guest_menu {
        top: 92px;
      }
    }
  }

  .feature_list__table {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .feature_list__expand_all_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 33px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;

    &:hover {
      color: var(--site-theme-color);
    }
  }

  .feature_list__table_header {
    display: flex;
    align-items: flex-end;
    column-gap: 22px;
    padding-top: 24px;
  }

  .feature_list__what_you_get {
    height: 48px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 382px;
    padding-left: 32px;
  }

  .feature_list__thead {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 32px;
  }

  .feature_list__thead_text {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: black;
    margin: 0;
    margin-bottom: 4px;
  }

  .feature_list__thead_price {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    margin: 0;
  }

  .feature_list__thead_button {
    width: 229px;
    height: 48px;
    margin-top: 27px;

    &:disabled {
      background-color: #e5e5e5;
      cursor: not-allowed;
    }
  }
}

.feature_list {
  display: flex;
  flex-direction: column;
  margin-top: 69px;

  &.first_el {
    margin-top: 0;

    .feature_list__title_container {
      position: absolute;
      top: 0;
      padding-bottom: 19px;
    }

    .feature_list__table {
      border-top: none;
    }
  }

  .feature_list__title_container {
    display: flex;
    padding-bottom: 79px;
  }

  .feature_list__title {
    margin: 0;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: black;
    width: 252px;
  }

  .feature_list__desc {
    margin: 0;
    margin-top: 4px;
    margin-left: 97px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    max-width: 810px;
  }
}

.feature_item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-inline: 1px solid transparent;
  position: relative;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &.is_expanded {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
  }

  .feature_item__content {
    display: flex;
    align-items: center;
    height: 52px;
    column-gap: 22px;
  }

  .feature_item__title {
    padding-left: 32px;
    display: flex;
    flex-grow: 1;
    max-width: 382px;

    h6 {
      margin: 0;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      width: max-content;
    }
  }

  .feature_item__beta {
    display: flex;
    position: relative;
    margin-left: 4px;

    &:hover {
      .feature_item__beta_icon {
        svg path {
          fill: var(--site-theme-color);
        }
      }
      .feature_item__beta_content {
        display: flex;
      }
    }

    .feature_item__beta_icon {
      display: flex;
    }
    .feature_item__beta_content {
      display: none;
      position: absolute;
      top: 23px;
      left: 50%;
      transform: translateX(-24%);
      z-index: 1;
      padding: 9px 29px 11px;
      background: #f2f2f2;
      min-width: 260px;

      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: black;
      }
    }
  }

  .feature_item__col {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 293px;
    font-size: 15px;
    color: black;
  }

  .feature_item__desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    max-width: 576px;
    margin-left: 109px;
    margin-bottom: 27px;
    margin-top: 18px;
  }

  .feature_item__discover {
    position: absolute;
    right: 26px;
    bottom: 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
    width: 240px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: var(--site-theme-color);
    }

    &.is_expanded_all {
      right: 19px;
    }
  }
}

@include breakpoint-sm {
  .pricing_table {
    width: 100%;
    padding-top: 122px;

    .feature_list__sticky_container {
      border-top: none;
      display: flex;
      flex-direction: column;
      top: 89px;

      &.is_logged {
        top: 79px;
      }

      &.hide_menu_bar {
        top: 60px;
      }
    }

    .feature_list__expand_all_btn {
      order: -1;
      font-size: 13px;
      margin-top: 9px;
      margin-right: 24px;
      margin-bottom: 12px;
    }

    .feature_list__table_header {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 0;
      height: 40px;
      align-items: center;
      padding-inline: 24px;
      column-gap: 0;
    }

    .feature_list__what_you_get {
      font-size: 13px;
      line-height: 18px;
      max-width: 100%;
      padding-left: 0;
      height: auto;
      flex: 1;
    }

    .feature_list__thead {
      padding-inline: 0;
      flex-shrink: 0;

      &[data-columns='3'] {
        width: 60px;
      }
      &[data-columns='2'] {
        width: 90px;
      }
      &[data-columns='1'] {
        flex: 1;
      }
    }

    .feature_list__thead_text {
      font-size: 13px;
      line-height: 18px;
      margin: 0;
    }

    .feature_list__thead_price {
      display: none;
    }

    .feature_list__thead_button {
      display: none;
    }
  }

  .feature_list {
    margin-top: 54px;

    .feature_list__title_container {
      flex-direction: column;
      padding-inline: 24px;
      padding-bottom: 16px;
    }

    .feature_list__title {
      font-size: 15px;
      line-height: 20px;
    }

    .feature_list__desc {
      font-size: 13px;
      line-height: 18px;
      max-width: 100%;
      margin-top: 26px;
      margin-left: 0;
    }
  }

  .feature_item {
    padding: 9px 24px 10px;
    border-inline: none;

    &.is_expanded {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      border-inline: none;
    }

    .feature_item__content {
      height: auto;
      column-gap: 0;
    }

    .feature_item__title {
      padding-left: 0;
      max-width: 150px;
      width: 100%;
      flex: 1;

      h6 {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .feature_item__col {
      flex-shrink: 0;
      font-size: 13px;

      &[data-columns='3'] {
        width: 60px;
      }
      &[data-columns='2'] {
        width: 90px;
      }
      &[data-columns='1'] {
        flex: 1;
      }
    }

    .feature_item__desc {
      font-size: 10px;
      line-height: 15px;
      margin-top: 21px;
      margin-left: 28px;
      margin-bottom: 0;

      &.has_slug {
        margin-bottom: 35px;
      }
    }

    .feature_item__discover {
      width: 70px;
      height: 30px;
      font-size: 10px;
      line-height: 15px;
      bottom: 10px;
      right: 24px;
    }
  }
}
