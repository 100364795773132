@import '~theme/modules/variables';
@import '~theme/mixins';

.academy__search_sort {
  display: flex;
  height: 33px;
  width: 100%;
  max-width: 583px;
  position: relative;
  padding: 0 12px;
  background: white;
  margin-top: 38px;

  .sortFilters {
    display: none;
  }

  &.defaultMode {
    .searchContainer {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    .searchInputWrapper {
      width: 100%;
    }

    .searchIcon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .searchInput {
      background: transparent;
      border: 0;
    }

    .sortOptionActived {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-transform: capitalize;
    }

    .sortIconContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40px;
      cursor: pointer;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }

  &.sortModeActive {
    justify-content: flex-start;
    user-select: none;

    .searchInputWrapper {
      display: none;
    }

    .sortActive {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      .sortOptionActived {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: capitalize;
      }

      .sortIconContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 40px;
        cursor: pointer;
        margin-right: 9px;
      }
    }

    .sortFilters {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 33px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px;
      z-index: 2;

      .sortOptionItem {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 32px;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.searchFocused {
    .sortIconContainer,
    .sortOptionActived,
    .sortFilters {
      display: none;
    }

    .searchInput {
      padding-right: 10px;
    }
  }
}
