@import '~theme/guidelines_variables';
@import '~components/HelpCenterSettings/HelpCenterSettings';
@import '~theme/mixins';

$topNavBarHeight: 48px;

.update_container {
  width: 100%;
  max-width: none;
  font-family: $fontBaseV2;

  .updateAliases {
    .icon_button {
      background: none;
      border: 0;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      &:disabled {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    .title {
      font-family: Libre Franklin;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: rgba(#000000, 0.4);
    }

    .addAlias {
      display: flex;
      flex-direction: row;

      .addAliasBtn {
        margin-left: auto;
      }
    }

    .aliasesListContainer {
      margin-left: 28px;
      margin-top: 10px;

      .aliasesListHeader {
        display: flex;
        flex-direction: row;
      }

      .activeAliases {
        font-family: Libre Franklin;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: var(--site-theme-color);
      }

      .connectAliases {
        background: transparent;
        border: 0;
        margin: 0;
        margin-left: auto;
        padding: 0;

        &:focus {
          outline: none;
        }

        &:hover {
          color: var(--site-theme-color);
        }
      }

      .aliasesList {
        padding-left: 0;
        margin-top: 6px;

        .aliasesListItem {
          display: flex;
          flex-direction: row;
          margin-bottom: 5px;

          .aliasInput {
            font-family: Libre Franklin;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            border: none;
            margin-right: auto;

            &:focus {
              outline: none;
            }

            &:disabled {
              background: transparent;
            }
          }

          .editAliasBtn {
          }

          .removeAliasBtn {
            margin-left: 20px;
          }

          &:hover {
            .aliasInput {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .checkboxGroup {
    display: flex;
    flex-direction: row;
  }

  .submit_section {
    text-align: center;
    margin: 10px 0;
    width: 240px;

    button {
      min-width: 240px;
      width: auto;
    }
  }

  .widgetPseudoContainer {
    display: block;
    width: $widgetPseudoContainerWidth;
    background: #000000;
    opacity: 0.75;
    position: fixed;
    top: $topNavBarHeight;
    height: calc(100% - #{$topNavBarHeight});
    right: 0;

    @include breakpoint-sm {
      display: none;
    }
  }
}

html[data-widget-preview-position="bl"],
html[data-widget-preview-position="tl"] {
  .update_container .widgetPseudoContainer {
    right: auto;
    left: 0;
  }
}

.fieldContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-direction: row;
}

.checkboxGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 0;
  flex-direction: row;

  .checkbox {
    width: 50%;
  }
}

.disconnectBtn {
  font-weight: bold;
  color: #000000;
  transition: color .3s ease;

  &:hover {
    color: var(--site-theme-color);
  }
}
