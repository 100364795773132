@import '~theme/modules/variables';
@import '~theme/mixins';

.captureSection {
  background-color: #c3c5f9;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px 51px;
  }

  .titleContent {
    @include ioradFont;
    line-height: 69%;
    font-size: 377px;
    color: #0b380a;
    padding-top: 17px;
    padding-bottom: 70px;

    @media screen and (max-width: 1336px) {
      font-size: 27vw;
    }
  } 

  .content {
    font-size: 15px;
    line-height: 136%;
    color: #0b380a;
    width: 803px;
    margin: 0;

    @media screen and (max-width: 950px) {
      width: 100%;
    }

    .breakContent {
      height: 19px;
      display: block;
      background: transparent;
    }

    a {
      color: #0b380a;
      font-weight: 600;
      text-decoration: underline;

      &:hover {
        color: var(--site-theme-color);
        text-decoration: none;
      }
    }
  }
}

.captureStep {
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 56px 64px 215px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .item {
    .title {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 5px;
    }

    .desc {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 24px;
      width: 340px;

      @media screen and (max-width: 1140px) {
        width: 100%;
      }
    }

    img {
      width: 368px;

      @media screen and (max-width: 1223px) {
        width: 100%;
      }
    }
  }
}

@include breakpoint-sm {
  .captureSection {
    .contentWrapper {
      padding: 44px 24px 38px;
    }

    .titleContent {
      font-size: 173px;
      line-height: 58%;
      word-break: break-all;
      padding-top: 13px;
      padding-bottom: 39px;
    }

    .content {
      font-size: 13px;
      width: 100%;
      margin: 0;
    }
  }

  .captureStep {
    padding: 50px 24px 130px;
    flex-direction: column;
    gap: 0;

    .item {
      margin-bottom: 62px;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        font-size: 13px;
        margin-bottom: 10px;
      }

      .desc {
        font-size: 13px;
        margin-bottom: 39px;
        width: 100%;
      }

      img {
        width: 329px;
      }
    }
  }
}
