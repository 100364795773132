@import '~theme/modules/variables';
@import '~theme/mixins';

.tutorialCounterSection {
  background-color: #77c082;

  .contentWrapper {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    padding: 5px 64px 13px 57px;
    margin: 0 auto;
  }

  .counter {
    display: flex;
    justify-content: space-between;
  }

  .iorad_font {
    font-family: iorad;
    font-size: 204px;
    line-height: 131px;
    letter-spacing: -1.224px;
    color: white;
    text-transform: uppercase;
  }

  .counter_number {
    display: flex;
    flex-direction: column;

    .tutorialViews {
      margin-top: 10px;
    }
  }

  .counter_image {
    width: 384px;
    flex-shrink: 0;
    margin-top: -27px;

    img {
      width: 100%;
    }
  }

  .desc_block {
    display: flex;
    margin-top: -52px;

    p {
      width: 100%;
      max-width: 437px;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-right: 35px;
      margin-top: 64px;
    }
  }
}
@include breakpoint-sm {
  .tutorialCounterSection {
    .contentWrapper {
      max-width: 100%;
      padding: 61px 0px 35px;
    }

    .counter {
      justify-content: flex-start;
    }

    .counter_title_mobile {
      display: flex;
      flex-direction: column;

      .align_end {
        text-align: end;
      }
    }

    .iorad_font {
      font-size: 113.421px;
      line-height: 64%;
      letter-spacing: -0.681px;
      word-break: break-word;
    }

    .counter_number {
      flex-grow: 1;
      padding: 0 20px;
      position: relative;
      z-index: 2;

      .tutorialViews {
        margin-top: 10px;
      }
    }

    .counter_image {
      margin-top: 0;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .desc_block {
      flex-direction: column;
      margin-top: 0;

      .counter_title_mobile {
        order: -1;
        padding: 0 20px;
      }

      p {
        max-width: 100%;
        font-size: 13px;
        line-height: 18px;
        margin-right: 0;
        margin-top: 35px;
        padding: 0 24px;
      }
    }
  }
}
