.dropdown_session_views {
  display: flex;
  flex-direction: column;

  .dropdown_session_views__item {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 56px;
    padding: 0 16px;
    font-size: 15px;
    line-height: 20px;
    color: black;
    cursor: pointer;

    .dropdown_session_views__item_hover {
      display: flex;
      align-items: center;
      column-gap: 16px;
      visibility: hidden;
      font-weight: 600;
      margin-left: auto;

      svg {
        transform: rotate(270deg);
      }
    }

    &.locked {
      cursor: default;

      &:hover {
        .dropdown_session_views__item_hover {
          visibility: visible;
        }
      }
    }
  }
}
.dropdown_date {
  display: flex;
  flex-direction: column;

  .dropdown_date__item {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 56px;
    padding: 0 16px;
    font-size: 15px;
    line-height: 20px;
    color: black;
    cursor: pointer;

    .dropdown_date__item_hover {
      display: flex;
      align-items: center;
      column-gap: 16px;
      visibility: hidden;
      font-weight: 600;
      margin-left: auto;

      svg {
        transform: rotate(270deg);
      }
    }

    &.locked {
      cursor: default;

      &:hover {
        .dropdown_date__item_hover {
          visibility: visible;
        }
      }
    }
  }
}

.chart {
  display: flex;
  flex-direction: column;
  height: 100%;

  .chart__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 24px;
  }

  .chart__body {
    display: flex;
    flex-grow: 1;
    height: calc(100% - 56px);
    width: 100%;
  }

  .line {
    width: 1px;
    height: 35px;
    background: rgba(0, 0, 0, 0.1);
    margin: 0 24px;
  }

  .session_views__total {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: black;

    .selected_date {
      color: var(--site-theme-color);
      text-decoration-line: underline;
      text-decoration-style: dotted;
    }
  }

  .chart__switch {
    display: flex;
    align-items: center;
    column-gap: 24px;

    .chart__switch__item {
      display: flex;
      align-items: center;
      column-gap: 16px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      cursor: pointer;
      opacity: 0.5;

      &:hover,
      &.active {
        opacity: 1;
      }
    }
  }

  .chart__open {
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-left: auto;

    span {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
    }

    svg {
      transform: rotate(270deg);
    }
  }

  .chart__chart {
    padding: 16px 24px;
    width: 100%;
    height: 100%;
  }

  .tutorials_table {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .tutorials_table__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      height: 56px;
      padding: 0 24px;

      span {
        font-size: 15px;
        line-height: 20px;
        color: black;
        opacity: 0.5;
      }
    }

    .tutorials_table__body {
      display: flex;
      flex-direction: column;
    }

    .tutorials_table__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 56px;
      padding: 0 24px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      span {
        font-size: 15px;
        line-height: 20px;
        color: black;
      }
    }
  }
}

.summary {
  display: flex;
  flex-direction: column;
  height: 100%;

  .summary__header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 24px;

    h3 {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      margin: 0;
    }
  }

  .summary__content {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 1px;
    background: rgba(0, 0, 0, 0.1);
    flex: 1;
  }

  .summary__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    background: white;

    span {
      font-size: 15px;
      line-height: 20px;
      color: black;
    }

    label {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      margin: 0;
    }
  }
}

.learner_engagement {
  display: flex;
  flex-direction: column;
  height: 100%;

  .learner_engagement__header {
    display: flex;
    align-items: center;
    column-gap: 16px;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 24px;

    h3 {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      margin: 0;
    }
  }

  .learner_engagement__content {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 1px;
    background: rgba(0, 0, 0, 0.1);
    flex: 1;
  }

  .learner_engagement__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    background: white;
    grid-column: span 2;

    // &:first-child {
    //   grid-column: span 2;
    // }

    span {
      font-size: 15px;
      line-height: 20px;
      color: black;
    }

    label {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      margin: 0;
    }

    .learner_engagement__item_hint {
      padding: 4px 8px;
      background: #f4f4f4;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }
  }
}

.summary, .learner_engagement, .chart, .listWrapper {
  position: relative;
}

.loading {
  &::after {
    content: '';
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}

.listWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  img {
    width: 100%;
    height: 100%;
    pointer-events: all;
    cursor: pointer;
  }

  svg, img {
    max-width: 24px;
    max-height: 24px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 56px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 24px;

    h3 {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 6px;
    }

    svg {
      cursor: pointer;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background: white;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    background-color: #FFF;
    padding: 16px;
    gap: 10px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border-bottom: none;
    }

    .inner {
      grid-column: span 3;
      display: flex;
      align-items: center;
      font-weight: 400;
      margin: 0;
      gap: 16px;
    }

    .note {
      font-size: 15px;
      line-height: 20px;
      color: black;
      opacity: 0.4;
      justify-content: center;
    }

    .title {
      grid-column: span 2;
      display: flex;
      align-items: center;
      font-weight: 400;
      margin: 0;
      gap: 16px;

      span, a {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-column: span 1;
    }

    p {
      margin: 0;
    }
  }

  .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--site-theme-color);
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.tool {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 600;
}

.percentItem {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 4px 8px;
  color: black;

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  span {
    font-weight: 600;
  }

  &[data-type='up'] {
    color: #009514;
  }
  &[data-type='down'] {
    color: #950002;

    svg {
      transform: rotate(180deg);
    }
  }

  &:not(.noBg) {
    background: #f2fdf2;
    &[data-type='up'] {
      background: #f2fdf2;
    }
    &[data-type='down'] {
      background: #fdf2f2;
    }
  }
}