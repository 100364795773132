.cardInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cardInfo_content {
  display: flex;
  align-items: center;
  column-gap: 24px;

  .cardInfo_content_logo {
    display: flex;
    flex-shrink: 0;
    width: 40px;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .cardInfo_content_number {
    font-size: 15px;
    line-height: 20px;
    margin-left: auto;

    label {
      font-weight: 600;
      margin: 0;
    }
  }

  .cardInfo_content_update {
    border: none;
    outline: none;
    background: none;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    padding: 16px 24px;
    background: #f4f4f4;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #{'rgba(var(--site-theme-color-rgb), 0.05)'};
    }
    
  }
}

.updateCardModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .updateCardModal_content {
    background: white;
    width: 752px;
    color: black;
    padding: 56px 56px 0;

    .updateCardModal_content_title {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 56px;
    }

    .updateCardModal_content_form {
      display: flex;
      flex-direction: column;
      background: #f4f4f4;
      border-radius: 24px;
      padding: 40px;
      height: 325px;
      width: 514px;
      margin: 0 auto;
    }

    .updateCardModal_content_btnGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 16px;
      margin-top: 56px;
      padding-top: 16px;
      padding-bottom: 56px;
    }
  }
}
