@import '~theme/modules/variables';
@import '~theme/mixins';

.teamRequest {
  display: flex;
  height: 100vh;
  min-height: 600px;

  .teamRequest__block {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  .teamRequest__header {
    .teamRequest__logo {
      padding: 24px 24px 40px;
    }
  }

  .teamRequest__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 40px;
    padding-bottom: 24px;
    overflow-y: auto;
    gap: 40px;
  }

  .teamRequest__content_inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    gap: 40px;

    h3 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
    }
  }

  .teamRequest__company {
    display: flex;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    column-gap: 24px;

    .teamRequest__company_logo {
      display: flex;
      align-items: flex-start;

      img {
        width: 37px;
        height: 37px;
      }
    }

    .teamRequest__company_info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;

      h4 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        color: black;
        margin: 0;
      }

      .teamRequest__company_members {
        display: flex;
        align-items: center;
        margin-top: 24px;

        span {
          font-size: 15px;
          line-height: 20px;
          color: black;
        }
      }

      .teamRequest__company_members_list {
        display: flex;
        align-items: center;
        column-gap: 4px;
        margin-right: 24px;

        .teamRequest__company_member {
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .teamRequest__company_join {
      background: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-start;
      padding: 16px 24px;
      outline: none;
      border: none;
      min-width: 242px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      color: black;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  .teamRequest__footer {
    display: flex;
    justify-content: center;
    padding: 40px 24px;
  }

  .teamRequest__iframe {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;

    .teamRequest__iframe_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 40px;

      img {
        width: 100%;
      }
    }
  }
}
