.frequency {
  display: flex;
  align-items: center;
  column-gap: 24px;

  .frequency_switch {
    flex-shrink: 0;
    position: relative;
    width: 32px;
    height: 17px;
    background: #bababa;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &.active {
      background: var(--site-theme-color);
    }

    .frequency_switchKnod {
      position: absolute;
      left: 1px;
      top: 1px;
      width: 15px;
      height: 15px;
      background: #ffffff;
      transition: transform 0.2s ease;
    }

    &.active .frequency_switchKnod {
      transform: translateX(15px);
    }
  }

  .frequency_switchLabel {
    font-size: 15px;
    line-height: 20px;

    .frequency_discount {
      color: var(--site-theme-color);
    }
  }
}
