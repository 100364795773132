/* #region common styles */
$green: #55ff52;

@mixin default_btn {
  width: 276px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: black;
  background: white;
  outline: none;
  border: none;
  padding: 0;

  &:hover {
    color: var(--site-theme-color);
  }

  @media only screen and (max-width: 768px) {
    width: 295px;
    font-size: 13px;
    line-height: 18px;
  }
}

@mixin typing_text {
  font-size: 204px;
  font-style: normal;
  font-weight: 400;
  line-height: 67%;
  color: #55ff52;
  font-family: iorad;
  letter-spacing: -1.224px;
  position: relative;

  h1 {
    text-transform: uppercase;
    margin: 0;
    font: inherit;
    display: inline;
  }

  :global(.typed-cursor) {
    display: inline-block;
    background-image: url('~assets/img/librarian_page/svgs/cursor.svg');
    height: 157px;
    width: 7px;
    position: absolute;
    transform: translateY(-10px);
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1366px;
  padding-left: 63px;
  padding-right: 62px;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@keyframes moveBg {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes fall {
  0% {
    top: -100vh;
  }
  100% {
    top: calc(130vh);
  }
}

/* #endregion common styles */

/* #region common layout */
.layout {
  display: flex;
  flex-direction: column;
  background: black;
  height: 100%;
  min-height: 100vh;
  position: relative;

  > * {
    position: relative;
    z-index: 2;
  }

  header {
    flex-shrink: 0;
    height: 92px;
    padding-left: 63px;
    padding-right: 62px;

    .header_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .logo {
        display: flex;
        align-items: center;
      }

      .join_buttons {
        display: flex;
        align-items: center;
        column-gap: 43px;

        .join_beta_btn {
          @include default_btn;
        }

        .sign_in_btn {
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: white;

          &:hover {
            color: var(--site-theme-color);
          }
        }
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  footer {
    display: flex;
    flex-direction: column;
    // background-image: url('~assets/img/librarian_page/librarian_bg.png');
    // background-repeat: no-repeat;
    // background-position: top center;
    // background-size: cover;

    .footer_content {
      display: flex;
      justify-content: space-between;
      margin-top: 113px;

      .footer_title {
        display: flex;
        flex-direction: column;
        max-width: 618px;

        h3 {
          font-size: 32px;
          font-weight: 600;
          line-height: 44px;
          margin: 0;
          margin-bottom: 25px;
          color: $green;
        }

        p {
          font-size: 21px;
          line-height: 28px;
          font-weight: 600;
          color: white;
          margin: 0;
        }
      }

      .footer_btn {
        @include default_btn;
        margin-top: 58px;
        margin-right: 96px;
      }
    }

    .footer_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 283px;
      margin-bottom: 53px;
      padding-left: 63px;
      padding-right: 62px;

      .copywrite {
        font-size: 15px;
        line-height: 20px;
        color: white;
      }

      .trust_center_link {
        font-size: 15px;
        line-height: 20px;
        color: white;
        text-decoration: underline;
      }
    }
  }
}
.layout__background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  overflow: hidden;

  p {
    min-width: 15px;
    margin: 0;
    position: relative;
    top: -100vh;
  }
}
@media only screen and (max-width: 768px) {
  .layout {
    header {
      height: 58px;
      padding-left: 24px;
      padding-right: 8px;
      z-index: 3;

      .header_content {
        .logo {
          img {
            width: 53px;
          }
        }

        .menu_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          outline: none;
          border: none;
          padding: 0;
          width: 40px;
          height: 40px;
        }
      }

      .menu_mobile {
        display: none;
        flex-direction: column;
        row-gap: 22px;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100vw;
        height: calc(100vh - 58px);
        background: black;
        padding: 27px 24px 20px;
        z-index: 3;

        &.show_menu {
          display: flex;
        }

        a {
          font-size: 15px;
          font-weight: 600;
          line-height: 20px;
          color: white;
        }
      }
    }

    main {
    }

    footer {
      .footer_content {
        flex-direction: column;
        margin-top: 230px;

        .footer_title {
          max-width: 100%;

          h3 {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 15px;
          }
        }

        .footer_btn {
          @include default_btn;
          margin: 75px auto 0;
        }
      }

      .footer_footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 141px;
        margin-bottom: 21px;
        padding-left: 24px;
        padding-right: 24px;
        position: relative;

        &::after {
          content: url('~assets/img/librarian_page/svgs/rabbit_mobile.svg');
          position: absolute;
          width: 56px;
          height: 73px;
          right: 12px;
          bottom: -2px;
        }

        .copywrite {
          font-size: 10px;
          line-height: 15px;
          margin-bottom: 0;
        }

        .trust_center_link {
          font-size: 13px;
          line-height: 18px;
          text-decoration: none;
          margin: 0 auto 56px;
          order: -1;
        }
      }
    }
  }
  .layout__background {
    p {
      min-width: 10px;
    }
  }
}
/* #endregion common layout */

/* #region home page */
.home {
  display: flex;
  flex-direction: column;

  .home_headline {
    display: flex;
    flex-direction: column;
    min-height: 600px;
    height: 100vh;
    max-height: 1080px;
    padding-top: 181px;
    position: relative;

    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-image: url('~assets/img/librarian_page/librarian_bg.png');
    //   background-repeat: no-repeat;
    //   background-position: top center;
    //   background-size: cover;
    //   animation-name: moveBg;
    //   animation-duration: 10s;
    //   animation-timing-function: linear;
    //   animation-iteration-count: infinite;
    // }

    .home_headline__title {
      @include typing_text;
      margin-bottom: 60px;
      min-height: 157px;
    }

    .home_headline__subtitle {
      font-size: 32px;
      line-height: 38px;
      font-weight: 600;
      color: white;
      margin: 0;
      margin-bottom: 52px;
    }

    .home_headline__join_btn {
      @include default_btn;
    }
  }

  .home_usecases {
    display: flex;
    flex-direction: column;
    padding-top: 43px;
    padding-bottom: 87px;

    .home_usecases__title {
      font-size: 32px;
      line-height: 38px;
      font-weight: 600;
      color: $green;
      margin: 0;
      margin-bottom: 18px;
    }

    .home_usecases__desc {
      font-size: 21px;
      line-height: 28px;
      color: white;
      margin: 0;
      margin-bottom: 82px;
      max-width: 698px;
    }

    .home_usecases__list {
      display: flex;
      column-gap: 120px;

      .home_usecases__list_item {
        display: flex;
        flex-direction: column;
        width: 332px;

        .home_usecases__list_item_icon {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding-bottom: 20px;
          width: 100%;
          height: 161px;
          background-image: url('~assets/img/librarian_page/folder_small.png');
          background-repeat: no-repeat;
          margin-bottom: 9px;

          img {
            margin-left: 0;
          }
        }

        h4 {
          font-size: 32px;
          line-height: 44px;
          font-weight: 600;
          color: white;
          margin: 0;
          margin-bottom: 20px;
          max-width: 296px;
        }

        p {
          font-size: 15px;
          line-height: 20px;
          color: white;
          margin: 0;
          max-width: 296px;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .home {
    .home_headline {
      min-height: auto;
      height: auto;
      max-height: unset;
      padding-top: 58px;

      img {
        width: 100vw;
        margin-left: -24px;
      }

      .home_headline__subtitle {
        font-size: 21px;
        line-height: 28px;
        margin: 0;
        margin-top: 8px;
        margin-bottom: 111px;
      }

      .home_headline__join_btn {
        margin: 0 auto;
      }
    }

    .home_usecases {
      display: flex;
      flex-direction: column;
      padding-top: 119px;
      padding-bottom: 0;

      .home_usecases__title {
        font-size: 21px;
        line-height: 28px;
      }

      .home_usecases__desc {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 66px;
      }

      .home_usecases__list {
        display: flex;
        flex-direction: column;
        row-gap: 66px;

        .home_usecases__list_item {
          width: 100%;

          .home_usecases__list_item_icon {
            padding-bottom: 18px;
            width: 100%;
            height: 158px;
            background-image: url('~assets/img/librarian_page/folder_small.png');
            margin-bottom: 30px;

            img {
              margin-left: 30px;
            }
          }

          h4 {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 21px;
            max-width: 100%;
          }

          p {
            font-size: 13px;
            line-height: 18px;
            max-width: 100%;
          }
        }
      }
    }
  }
}
/* #endregion home page */

/* #region detail page */
.detail {
  display: flex;
  flex-direction: column;

  .detail_headline {
    display: flex;
    flex-direction: column;
    padding-top: 122px;
    padding-bottom: 44px;

    .detail_headline__title {
      @include typing_text;
      margin-bottom: 50px;
      min-height: 274px;
    }

    .detail_headline__subtitle {
      font-size: 32px;
      line-height: 44px;
      font-weight: 600;
      color: white;
      margin: 0;
      margin-bottom: 18px;
    }

    .detail_headline__desc {
      font-size: 15px;
      line-height: 20px;
      color: white;
      margin: 0;
      max-width: 618px;
    }
  }

  .detail_content {
    display: flex;
    flex-direction: column;
    padding-left: 63px;
    padding-right: 62px;
  }

  .detail_section {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    position: sticky;
    transition: opacity 0.3s ease;

    .detail_section__container {
      background-image: url('~assets/img/librarian_page/svgs/folder_large.svg');
      background-size: 100%;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      min-height: 1060px;
      padding: 119px 35px 43px 116px;
      position: relative;
      display: flex;
      flex-direction: column;

      &.hide_content {
        > * {
          opacity: 0;
        }
      }
    }

    .detail_section__title {
      font-family: iorad;
      font-size: 204px;
      line-height: 67%;
      letter-spacing: -1.224px;
      text-transform: uppercase;
      color: black;
      margin: 0;
      max-width: 570px;
    }

    .detail_section__index {
      position: absolute;
      right: 35px;
      top: 159px;
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      span {
        font-size: 32px;
        line-height: 44px;
        font-weight: 600;
        color: white;

        &.active {
          color: black;
        }
      }
    }
  }

  .tldr {
    top: 0;
    z-index: 1;

    .tldr__content {
      margin-top: 63px;
      display: flex;
      justify-content: space-between;
      column-gap: 50px;
    }

    .tldr__list {
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: 401px;
      margin-top: 6px;

      li {
        list-style-type: none;
        display: flex;
        column-gap: 14px;

        span {
          font-size: 21px;
          line-height: 28px;
          font-weight: 600;
          color: black;
        }
      }
    }

    .tldr__iframe {
      width: 100%;
      max-width: 1137px;
      height: 673px;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }

  .how_it_works {
    top: 20px;
    z-index: 2;

    .how_it_works__content {
      display: flex;
      column-gap: 50px;
    }

    .how_it_works__title {
      .how_it_works__desc {
        font-size: 21px;
        line-height: 28px;
        font-weight: 600;
        color: black;
        margin: 0;
        margin-top: 57px;
        width: 100%;
        max-width: 437px;
      }
    }

    .how_it_works__list {
      display: flex;
      flex-direction: column;
      row-gap: 53px;
      margin-top: 42px;
    }

    .how_it_works__item {
      background: white;
      display: flex;
      column-gap: 45px;
      width: 100%;
      max-width: 760px;
      padding: 40px 55px 50px;
      box-shadow: 0px 0px 15px 15px white;

      .how_it_works__item_index {
        color: black;
        font-family: iorad;
        font-size: 204px;
        line-height: 67%;
        letter-spacing: -1.224px;
        text-transform: uppercase;
      }

      .how_it_works__item_content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 438px;

        label {
          font-size: 32px;
          line-height: 44px;
          font-weight: 600;
          color: black;
          margin-bottom: 15px;
        }

        span {
          font-size: 15px;
          line-height: 20px;
          color: black;
        }
      }
    }

    .detail_section__index {
      top: 139px;
    }
  }

  .faqs {
    top: 40px;
    z-index: 3;

    .faqs__list {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      width: 100%;
      max-width: 1245px;
      margin: 51px auto 0;
    }

    .faqs__item {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .faqs__item_question {
        font-size: 32px;
        line-height: 44px;
        font-weight: 600;
        color: black;
        margin: 0;
      }

      .faqs__item_answer {
        padding: 4px 11px;
        background: white;
        box-shadow: 0px 0px 15px 15px white;
        width: 100%;
        max-width: 716px;
        margin-left: auto;

        span {
          display: block;
          font-size: 15px;
          line-height: 20px;
          color: black;
          width: 100%;
          max-width: 520px;
        }
      }
    }
  }

  .detail_usecases {
    display: flex;
    flex-direction: column;
    padding-top: 199px;
    padding-bottom: 89px;

    .detail_usecases__title {
      font-size: 32px;
      line-height: 38px;
      font-weight: 600;
      color: $green;
      margin: 0;
      margin-bottom: 18px;
    }

    .detail_usecases__desc {
      font-size: 21px;
      line-height: 28px;
      color: white;
      margin: 0;
      margin-bottom: 82px;
      max-width: 698px;
    }

    .detail_usecases__list {
      display: flex;
      column-gap: 120px;

      .detail_usecases__list_item {
        display: flex;
        flex-direction: column;
        width: 332px;

        .detail_usecases__list_item_icon {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding-bottom: 20px;
          width: 100%;
          height: 161px;
          background-image: url('~assets/img/librarian_page/folder_small.png');
          background-repeat: no-repeat;
          margin-bottom: 9px;

          img {
            margin-left: 30px;
          }
        }

        h4 {
          font-size: 32px;
          line-height: 44px;
          font-weight: 600;
          color: white;
          margin: 0;
          margin-bottom: 20px;
          max-width: 296px;
        }

        p {
          font-size: 15px;
          line-height: 20px;
          color: white;
          margin: 0;
          max-width: 296px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .detail {
    .detail_content {
      padding-left: 32px;
      padding-right: 38px;
    }

    .detail_section {
      .detail_section__container {
        padding: 69px 30px 36px;
        min-height: 754px;
      }

      .detail_section__index {
        top: 109px;
        right: 30px;
      }
    }

    .tldr {
      .tldr__iframe {
        max-width: 746px;
        height: 443px;
      }
    }

    .how_it_works {
      .how_it_works__item {
        padding: 10px 40px 20px;
        column-gap: 5px;
        max-width: 576px;

        .how_it_works__item_index {
          font-family: inherit;
          font-size: 32px;
          line-height: 44px;
          font-weight: 600;
          width: 28px;
        }

        .how_it_works__item_content span {
          margin-left: -28px;
        }
      }
    }

    .faqs {
      .faqs__list {
        max-width: 100%;
      }

      .faqs__item {
        row-gap: 10px;

        .faqs__item_answer {
          margin-left: 0;
          max-width: 100%;

          span {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .detail {
    .detail_headline {
      padding-top: 103px;
      padding-bottom: 20px;

      img {
        width: 100vw;
        margin-left: -24px;
      }

      .detail_headline__subtitle {
        font-size: 21px;
        line-height: 28px;
        margin-top: 42px;
      }

      .detail_headline__desc {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .detail_content {
      padding-left: 11px;
      padding-right: 13px;
    }

    .detail_section {
      height: 100vh;
      padding-top: 20px;

      .detail_section__container {
        background-image: url('~assets/img/librarian_page/svgs/folder_large_mobile.svg');
        min-height: 100%;
        padding: 45px 11px 22px 13px;
      }

      .detail_section__title {
        font-size: 88px;
        line-height: 67px;
        letter-spacing: -0.468px;
      }

      .detail_section__index {
        display: none;
      }
    }

    .tldr {
      .tldr__content {
        margin-top: 19px;
        flex-direction: column;
        flex-grow: 1;
      }

      .tldr__list {
        margin-top: 0;

        li {
          column-gap: 10px;

          span {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }

      .tldr__iframe {
        width: 100%;
        max-width: 100%;
        height: 193px;
      }
    }

    .how_it_works {
      .how_it_works__content {
        flex-direction: column;
        row-gap: 18px;
      }

      .how_it_works__title {
        .how_it_works__desc {
          font-size: 13px;
          line-height: 18px;
          margin-top: 20px;
        }
      }

      .how_it_works__list {
        row-gap: 12px;
        margin-top: 18px;
      }

      .how_it_works__item {
        column-gap: 5px;
        max-width: 100%;
        padding: 7px 12px 4px;
        box-shadow: 0px 0px 6px 6px white;

        .how_it_works__item_index {
          color: black;
          font-family: inherit;
          font-size: 13px;
          line-height: 18px;
          font-weight: 600;
          letter-spacing: unset;
          width: 8px;
        }

        .how_it_works__item_content span {
          margin-left: -15px;
        }

        .how_it_works__item_content {
          max-width: 100%;

          label {
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 6px;
          }

          span {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }

    .faqs {
      .faqs__list {
        row-gap: 20px;
        max-width: 100%;
        margin: 18px auto 0;
      }

      .faqs__item {
        row-gap: 20px;

        .faqs__item_question {
          font-size: 13px;
          line-height: 18px;
        }

        .faqs__item_answer {
          padding: 0px 7px 4px;
          background: white;
          box-shadow: 0px 0px 6px 6px white;
          max-width: 100%;
          margin-left: 0;

          span {
            font-size: 13px;
            line-height: 18px;
            max-width: 100%;
          }
        }
      }
    }

    .detail_usecases {
      padding-top: 119px;
      padding-bottom: 0;

      .detail_usecases__title {
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 18px;
      }

      .detail_usecases__desc {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 66px;
        max-width: 100%;
      }

      .detail_usecases__list {
        flex-direction: column;

        .detail_usecases__list_item {
          width: 100%;

          .detail_usecases__list_item_icon {
            padding-bottom: 20px;
            width: 100%;
            height: 158px;
            margin-bottom: 29px;
          }

          h4 {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 21px;
            max-width: 100%;
          }

          p {
            font-size: 13px;
            line-height: 18px;
            max-width: 100%;
          }
        }
      }
    }
  }
}
/* #endregion detail page */
