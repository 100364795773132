@import "./Login";

.login2FA {
  * {
    text-align: left;
  }

  .title {
    font-family: "Fahkwang", sans-serif;
    font-weight: 500;
    font-size: 35px;
    line-height: 116%;
    color: #000000;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 20px;

    @include breakpoint-sm {
      font-size: 21px;
    }
  }

  .explanation {
    text-align: left;
  }

  :global(.checkbox).rememberMeLabel {
    font-weight: normal;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;

    input[type='checkbox'] {
      position: static;
      margin-left: 0;
      margin-right: 7px;
      margin-top: 0;
      cursor: pointer;
    }
  }

  .resendCode {

  }
}
