@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    background: #2891be;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 56px 64px 45px;
  }

  .titleContent {
    @include ioradFont;
  }

  .desc {
    max-width: $maxWidthContent;
    margin: 77px auto 0;
    padding: 0 64px;
    display: flex;
    flex-direction: column;

    h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 44px;
      color: black;
      margin: 0;
      margin-bottom: 23px;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      max-width: 622px;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: 14px;
    }

    li {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      max-width: 622px;
      margin: 0;

      b {
        font-weight: 600;
      }
    }
  }

  .iframe {
    max-width: $maxWidthContent;
    padding: 0 64px;
    margin: 60px auto 155px;

    iframe {
      width: 100%;
      height: 750px;
      border: 1px solid #e3e9ff;
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .title {
      padding: 70px 24px 49px;

      img {
        width: 100%;
      }
    }

    .desc {
      max-width: 100%;
      margin-top: 93px;
      padding: 0 24px;

      h3 {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      p {
        font-size: 13px;
        line-height: 18px;
        max-width: 100%;
      }
    }

    .iframe {
      padding: 0 24px;
      margin: 20px auto 229px;

      iframe {
        height: 320px;
      }
    }
  }
}
