@import "../../../../../containers/Tutorials/Tutorials.scss";

.seismicContainer {

  .folderPath {
    display: flex;
    margin: 15px 0;
    font-size: 12px;
    height: 18px;
    vertical-align: middle;

    a {
      cursor: pointer;
      color: var(--site-theme-color);
    }
  }

  .pathSeparator {
    padding: 0 7px;
  }
}
