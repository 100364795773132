@import '~theme/modules/variables';
@import '~theme/mixins';

.pendingText {
  color: rgba($color: #000000, $alpha: 0.4);
}

.container {
  display: flex;
  width: 100%;
  padding: 16px;

  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }

  .permissionWrapper, .button {
    color: rgba(0, 0, 0, 0.4);
  }

  .hoverText {
    display: none;
  }

  &:hover {
    .permissionWrapper, .button {
      color: #000;

      &:hover {
        color: var(--site-theme-color);;
      }
    }
    .hoverText {
      display: block;
    }
    .displayText {
      display: none;
    }
  }
}

.imageWrapper {
  margin-right: 16px;
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }

  @include breakpoint-sm {
    margin-right: 10px;
  }
}

.highlightImage {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  width: 29px;
  height: 29px;
}

.info {
  position: relative;
  padding-top: 3px;
  font-family: $fontBaseV2;
  width: 148px;
  overflow: hidden;
  margin-right: 10px;

  &::after {
    content: '';
    height: 100%;
    width: 20px;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(to left, #FFFFFF 13.81%, rgba(255, 255, 255, 0) 99.61%);
  }
}

.email {
  // font-size: 10px;
  // color: #999999;
  // margin-bottom: 5px;
}

.name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.permissionWrapper {
  justify-self: flex-end;
  display: flex;
  align-items: center;
  height: 24px;
  min-width: 140px;

  &.hasSelect {
    min-width: 200px;
  }

  .inner {
    justify-content: flex-end;
    display: flex;
    width: 100%;
  }
  // .pendingText, .resendText {
  //   width: 100%;
  // }

  // &:hover {
  //   .pendingText {
  //     display: none;
  //   }

  //   .resendText {
  //     display: block;
  //   }
  // }

  // @include breakpoint-sm {
  //   .pendingText {
  //     display: none;
  //   }

  //   .resendText {
  //     display: block;
  //   }
  // }
}

// .pendingText {
//   display: block;
//   color: #999999
// }

// .resendText {
//   display: none;
//   color: #000000;
//   cursor: pointer;
// }

.button {
  background: transparent;
  border: 0 none;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  font-weight: 600;

  &.border {
    border-right: 1px solid rgba($color: #000000, $alpha: 0.25);
    padding-right: 16px;
  }
}

.removePendingInvite {
  width: 12px;
  height: 12px;
  cursor: pointer;
  // margin-left: auto;
  justify-self: flex-end;
  align-self: center;
}