@import '~theme/mixins';
@import '~theme/modules/variables';

$filterPopupOverlayZIndex: 2;

.filter_popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;

  .filter_popup__container {
    height: 100%;
    width: 100%;
    max-width: 1207px;
    background: white;
    display: flex;
    flex-direction: column;
  }

  .filter_popup__content {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
  }

  .filter_popup__overlay_bg {
    background: rgba(242, 242, 242, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $filterPopupOverlayZIndex;
    top: 0;
    left: 0;
  }

  .filter_popup__left_content {
    display: flex;
    flex-grow: 1;
    padding-top: 32px;
    padding-left: 47px;
  }

  .filter_popup__tabs_list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 89px;
  }

  .filter_popup__tab_item {
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
    padding: 5px 0;

    &.active {
      color: var(--site-theme-color);
    }
  }

  .filter_popup__filter_content {
    width: 441px;
    display: flex;
    flex-direction: column;
  }

  .filter_popup__filter_title {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: black;
    margin: 0;
    margin-bottom: 63px;
  }

  .filter_popup__search_input {
    width: 100%;
    padding-right: 37px;
  }

  .filter_popup__filters_list {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    flex-grow: 1;
    height: 100%;
    padding-top: 23px;
    padding-bottom: 40px;
    @include nice-scrollbar;
  }

  .filter_popup__filter_item {
    display: flex;
    align-items: center;
    padding-right: 37px;
    min-height: 30px;

    * {
      cursor: pointer;
    }

    label {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      flex-grow: 1;
    }
  }

  .filter_popup__status_bg {
    display: flex;
    margin-left: 5px;
    margin-right: 24px;

    span {
      width: 22.63px;
      height: 22.63px;
      transform: rotate(45deg);

      &[data-type='verified'] {
        background: #bde568;
      }
      &[data-type='open'] {
        background: #ffe3e3;
      }
      &[data-type='pending'] {
        background: #fce699;
      }
      &[data-type='boost2x'] {
        background: #bfd1ff;
      }
      &[data-type='veryPopular'],
      &[data-type='popular'] {
        background: #d9a9ff;
      }
      &[data-type='live'] {
        border: 1.6px solid var(--site-theme-color);
      }
    }
  }

  .filter_popup__product_img {
    height: auto;
    width: 30px;
    margin-right: 40px;
  }

  .filter_popup__ioradical_avatar {
    margin-right: 24px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .filter_popup__right_content {
    padding: 32px 36px 88px;
    display: flex;
    flex-direction: column;
    background: $backgroundTiles;
    width: 310px;
  }

  .filter_popup__applied_title {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: black;
    padding-left: 4px;
    margin: 0;

    &.is_saving {
      position: relative;
      z-index: $filterPopupOverlayZIndex + 1;
    }
  }

  .filter_popup__save_filter {
    padding-left: 4px;
    margin-top: 63px;
    flex-grow: 1;
  }

  .filter_popup__save_filter_content {
    position: relative;
    z-index: $filterPopupOverlayZIndex + 1;

    input {
      background: transparent;
      padding: 0;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      caret-color: var(--site-theme-color);
      font-weight: 400;
      font-size: 15px;
      color: black;
      width: 100%;
      height: 34px;
      padding-right: 70px;

      &:focus {
        border-color: var(--site-theme-color);
      }
    }
  }

  .filter_popup__save_filter_actions {
    display: flex;
    column-gap: 12px;
    position: absolute;
    right: 0;
    top: 11px;

    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: black;
      cursor: pointer;

      &.isDisabled {
        color: rgba(0, 0, 0, 0.4);
        pointer-events: none;
        cursor: not-allowed;
      }

      &:hover {
        color: var(--site-theme-color);
      }

      &:first-child {
        font-weight: 600;
      }
    }
  }

  .filter_popup__tags {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 4px;
    margin-top: 84px;
    overflow: auto;
    width: calc(100% + 36px);
    padding-right: 36px;
    padding-bottom: 20px;
  }

  .filter_popup__no_tags {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
  }

  .filter_popup__tags_list_wrapper {
    display: flex;
    flex-direction: column;
  }

  .filter_popup__tags_list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .filter_popup__tag_item {
    background: rgba(0, 0, 0, 0.05);
    color: black;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    overflow: hidden;

    span {
      overflow: hidden;
    }

    &[data-type='verified'] {
      background: #bde568;
    }
    &[data-type='open'] {
      background: #ffe3e3;
    }
    &[data-type='pending'] {
      background: #fce699;
    }
    &[data-type='boost2x'] {
      background: #bfd1ff;
    }
    &[data-type='veryPopular'],
    &[data-type='popular'] {
      background: #d9a9ff;
    }
    &[data-type='live'] {
      background: #fff;
      color: var(--site-theme-color);
      text-transform: uppercase;
      border: 1px solid var(--site-theme-color);
    }
  }

  .filter_popup__remove_tag_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 9px;
    flex-shrink: 0;
  }

  .filter_popup__tags_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }

  .filter_popup__remove_all_btn {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: black;
  }

  .filter_popup__right_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .filter_popup__results_count {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    margin-bottom: 20px;
  }

  .filter_popup__save_filter_btn {
    margin-top: 15px;

    &:disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .filter_popup__saved_filters {
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    margin-top: 60px;
    flex-grow: 1;
    overflow: auto;
    width: calc(100% + 36px);
    padding-right: 36px;
    padding-bottom: 20px;
  }

  .filter_popup__saved_filter_item {
    display: flex;
    align-items: center;
    height: 34px;
    flex-shrink: 0;

    .filter_popup__saved_filter_item_actions {
      display: none;
    }

    &.selected,
    &:hover {
      .filter_popup__saved_filter_item_actions {
        display: flex;
      }

      .filter_popup__saved_filter_item_name {
        color: var(--site-theme-color);
      }
    }
  }

  .filter_popup__saved_filter_item_name {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .filter_popup__saved_filter_item_actions {
    display: flex;
    align-items: center;
    column-gap: 20px;

    span {
      display: flex;
      align-items: center;

      svg {
        opacity: 0.4;
      }

      &:hover {
        svg {
          opacity: 1;
          path {
            &[stroke] {
              stroke: var(--site-theme-color);
            }
            &[fill] {
              fill: var(--site-theme-color);
            }
          }
        }
      }
    }
  }

  .filter_popup__saved_filter_input {
    position: relative;
    width: 100%;

    input {
      background: transparent;
      padding: 0;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      caret-color: var(--site-theme-color);
      font-weight: 400;
      font-size: 15px;
      color: black;
      width: 100%;
      height: 34px;
      padding-right: 70px;

      &:focus {
        border-color: var(--site-theme-color);
      }
    }
  }

  .filter_popup__saved_filter_input_actions {
    display: flex;
    column-gap: 12px;
    position: absolute;
    right: 0;
    top: 11px;

    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: black;
      cursor: pointer;

      &.isDisabled {
        color: rgba(0, 0, 0, 0.4);
        pointer-events: none;
        cursor: not-allowed;
      }

      &:hover {
        color: var(--site-theme-color);
      }

      &:first-child {
        font-weight: 600;
      }
    }
  }

  .filter_popup__close_btn {
    position: absolute;
    top: 4px;
    right: 4px;
    background: black;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    &:hover {
      background: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .filter_popup {
    height: 100%;

    .filter_popup__container {
      max-width: 100%;
    }

    .filter_popup__content {
      flex-direction: column;
      height: calc(100% - 48px);
    }

    .filter_popup__left_content {
      padding: 0;
      flex-direction: column;
      height: 100%;
      position: relative;
      overflow: hidden;
    }

    .filter_popup__tabs_list {
      padding: 15px 24px;
      flex-grow: 0;
      row-gap: 15px;

      &.is_opened {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        position: absolute;
        z-index: 1;
        width: 100%;
        background-color: white;
        top: 0;
        left: 0;

        .filter_popup__tab_item {
          display: flex;
          justify-content: space-between;

          &.active {
            order: -1;
            svg {
              transform: rotateX(180deg);
            }
          }
        }
      }
    }

    .filter_popup__tabs_list_spacer {
      min-height: 48px;
    }

    .filter_popup__tab_item {
      font-size: 13px;
      line-height: 18px;
      display: none;
      align-items: center;
      column-gap: 11px;
      padding: 0;

      &.active {
        color: black;
        display: flex;
      }
    }

    .filter_popup__filter_content {
      width: 100%;
      height: calc(100% - 48px);
      padding: 0;
    }

    .filter_popup__filter_title {
      display: none;
    }

    .filter_popup__search_input {
      padding-right: 0;

      input {
        font-size: 13px;
        padding-left: 67px !important;
        padding-right: 24px;
      }

      :global(.input_search-icon) {
        left: 24px;
      }
    }

    .filter_popup__filters_list {
      padding: 24px;
      row-gap: 15px;
    }

    .filter_popup__filter_item {
      padding-right: 0;

      label {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .filter_popup__product_img,
    .filter_popup__ioradical_avatar {
      margin-right: 21px;
    }

    .filter_popup__right_content {
      width: 100%;
      padding: 0;

      &.is_expanded_preset {
        position: relative;
        z-index: $filterPopupOverlayZIndex + 2;

        .filter_popup__applied_title {
          justify-content: space-between;

          svg {
            transform: rotateX(180deg);
          }
        }
        .filter_popup__saved_filters {
          display: flex;
        }
      }
    }

    .filter_popup__applied_title {
      font-size: 13px;
      line-height: 18px;
      padding: 15px 24px;
      display: flex;
      align-items: center;
      column-gap: 11px;

      &.is_hidden {
        display: none;
      }
    }

    .filter_popup__saved_filters {
      margin: 0;
      padding: 0 24px 15px;
      width: 100%;
      max-height: 229px;
      display: none;
    }

    .filter_popup__tags {
      margin: 0;
      padding: 15px 24px;
      width: 100%;
      overflow-x: hidden;
    }

    .filter_popup__no_tags {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    }

    .filter_popup__right_footer {
      flex-direction: row;

      button {
        flex: 1 0 50%;
      }
    }

    .filter_popup__save_filter_btn {
      margin-top: 0;

      &.hidden {
        display: none;
      }
    }

    .filter_popup__tags_actions {
      order: -1;
      margin-top: 0;
      margin-bottom: 15px;
      padding-right: 20px;
    }

    .filter_popup__remove_all_btn {
      font-size: 13px;
      line-height: 18px;
      margin: 0;
    }

    .filter_popup__tags_list_wrapper {
      max-height: 125px;
      overflow: hidden;
      width: calc(100% + 24px);

      &.is_expanded {
        max-height: 229px;

        .filter_popup__tags_list {
          @include nice-scrollbar;
        }

        .filter_popup__expand_tags_btn {
          transform: rotateX(180deg);
          cursor: pointer;
        }
      }
    }

    .filter_popup__tag_item {
      font-size: 13px;
      line-height: 18px;
    }

    .filter_popup__remove_tag_btn {
      width: 12px;
      height: 12px;
    }

    .filter_popup__saved_filter_item_name {
      font-size: 13px;
      line-height: 18px;
    }

    .filter_popup__saved_filter_input input {
      font-size: 13px;
    }

    .filter_popup__save_filter {
      margin: 0;
      padding: 0;
      margin-bottom: 15px;
    }

    .filter_popup__save_filter_content input {
      padding-left: 24px;
      padding-right: 100px;
      font-size: 13px;
    }

    .filter_popup__save_filter_actions {
      right: 24px;
    }

    .filter_popup__close_banner {
      order: -1;
      height: 48px;
      width: 100%;
      background: $backgroundTiles;
      display: flex;
      justify-content: center;
      align-items: center;
      position: sticky;
      z-index: $socialOverlayZIndex + 3;
      top: 0;
      flex-shrink: 0;

      .filter_popup__close_title {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding-left: 11px;

        h4 {
          margin: 0;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: #000000;
          margin-left: 8px;
        }
      }

      span {
        display: none;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: black;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      > svg {
        position: absolute;
        right: 10px;
      }

      &:hover {
        cursor: pointer;

        .filter_popup__close_title {
          display: none;
        }

        span {
          display: block;
        }
      }
    }
  }
}
