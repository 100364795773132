@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  padding-top: 8px;

  .header {
    padding-left: 61px;
    width: 100%;
  }

  .titleHeader {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    max-width: 810px;
    margin: 0;
    margin-bottom: 23px;
  }

  .backgroundBlock {
    background-image: url('~assets/img/redesign2021/trustcenterpage/trust_matters.png');
    background-color: #f4f4f4;
    background-position: top center;
    background-size: 100% 530px;
    background-repeat: no-repeat;
    height: 818px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
  }

  .content {
    padding-left: 61px;
    width: 100%;
  }

  .titlePartners {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    margin-bottom: 62px;
  }

  .partnerList {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 121px;
    padding-right: 64px;
  }

  .partnerItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 119px;
    height: 119px;
    background: #f4f4f4;

    img {
      width: 100%;
    }
  }

  .item {
    max-width: 810px;
    margin-bottom: 71px;

    &.security {
      margin-bottom: 35px;
    }

    &.infra {
      max-width: 617px;
    }

    &.application {
      margin-bottom: 32px;
    }

    &.applicationMobile {
      display: none;
    }

    .titleItem {
      font-weight: 600;
      font-size: 21px;
      line-height: 116%;
      color: #000000;
      margin: 0;
    }

    .subTitleItem {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-top: 6px;
    }

    .descItem {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-top: 23px;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

@include breakpoint-sm {
  .container {
    padding-top: 0;

    .header {
      background-color: #f4f4f4;
      padding: 45px 24px 24px;
    }

    .content {
      padding: 0 24px;
    }

    .titleHeader {
      font-size: 21px;
      max-width: 100%;
      margin-bottom: 65px;
    }

    .backgroundBlock {
      background-image: url('~assets/img/redesign2021/trustcenterpage/mobile/trust_matters.png');
      background-color: #f4f4f4;
      background-size: contain;
      height: 546px;
      margin-top: -45px;
      margin-bottom: 23px;
    }

    .titlePartners {
      font-size: 21px;
      margin-bottom: 28px;
    }

    .partnerList {
      column-gap: 3px;
      row-gap: 3px;
      flex-wrap: wrap;
      margin-bottom: 235px;
      padding-right: 0;
    }

    .partnerItem {
      width: 63px;
      height: 63px;
    }

    .item {
      max-width: 100%;
      margin-bottom: 59px;

      &.security {
        margin-bottom: 0;
      }

      &.infra {
        max-width: 100%;
        margin-bottom: 24px;
      }

      &.application {
        display: none;
      }

      &.applicationMobile {
        display: block;
      }

      .subTitleItem {
        margin-top: 21px;
      }

      .descItem {
        font-size: 13px;
        margin-top: 8px;
      }
    }
  }
}
