@import '../../theme/modules/variables';
@import '../../theme/mixins';

.topNavBar {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: $topNavBarZIndex;
  transition: transform 0.12s;
  font-family: $fontBaseV2;
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.moreNavShown, &.accountNavMobileShown {
    z-index: $topNavBarMoreShownZIndex;
  }

  &.hideMenuBar {
    transform: translateY(-100%);
  }

  .logo {
    margin: 80px;

    img {
      height: 40px;
      width: 40px;
    }
  }

  .link_container {
    display: flex;
    user-select: none;
    align-items: center;
    margin-top: 12px;
  }

  .ioradLogo {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }

  .primary,
  .secondary {
    display: flex;
    width: 100%;
    height: 64px;
    align-items: center;
  }

  .mainNavBlock {
    display: flex;
    align-items: center;
    column-gap: 28px;

    &.noCaptureButton {
      padding-left: 20px;
    }
  }

  .link {
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    transition: color 0.1s linear;
    margin-right: 40px;
    color: #000;
    cursor: pointer;

    &.active,
    &:hover {
      color: var(--site-theme-color);
      text-decoration: none;
      &.extensionLink :global(.ext-link) {
        color: var(--site-theme-color) !important;
      }
    }
    &:focus {
      text-decoration: none;
    }
    &:last-child {
      margin-right: 0;
    }

    &.hidden {
      visibility: hidden;
    }

    button.capture {
      background: rgba(0, 0, 0, 0.8);

      &:hover {
        background: rgba(0, 0, 0, 0.84);
      }
    }
  }

  .login_buttons {
    margin: 0 80px 0 auto;
    position: absolute;
    right: 0;
    top: 80px;
    display: flex;
  }
  .extensionLink :global(.ext-link) {
    height: auto;
    padding-top: 25px;
    padding-bottom: 10px;
    button {
      height: 40px;
      line-height: 100%;
      width: 140px;
      font-family: $fontBaseV2;
    }
    &:hover {
      text-decoration: none !important;
    }
  }
  .moreLink {
    position: relative;
    .moreLinkIcon {
      display: none;
    }
    &.itemsShown,
    &:hover {
      text-decoration: none !important;
      color: var(--site-theme-color) !important;
      &.itemsShown .additionalNavMenu {
        display: flex;
        top: calc(100% + 16px);
        max-height: calc(100vh - 48px);
        overflow: auto;
      }
    }
  }
  .additionalNavMenu {
    display: none;
    flex-direction: column;
    flex-wrap: wrap;
    position: absolute;
    top: 38px;
    right: -64px;
    background-color: #fff;
    text-align: right;
    padding-top: 2px;
    padding-right: 64px;
    padding-bottom: 13px;
    width: 100vw;

    .link {
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
      margin-right: 0;
      margin-bottom: 7px;

      &.mainNavLink {
        display: none;
      }
    }
  }

  .getIoradBtn {
    margin-top: 13px;
    width: 105px;
    height: 46px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    line-height: 136%;
    display: none;
    align-items: center;
    justify-content: center;
    align-self: flex-end;

    @include breakpoint-sm {
      display: flex;
    }
  }

  @media (max-height: 760px) {
    flex-wrap: nowrap;
    .visibleBlockContent {
      padding-top: 30px;
    }
  }
}

.topNavBar.guestUser {
  background-color: #fff;
  width: 100%;

  @include breakpoint-sm {
    width: 100%;

    .visibleBlock {
      width: 100%;
    }
  }

  .visibleBlock {
    width: 100%;
  }

  .visibleBlockContent {
    background-color: #fff;
    padding: 54px 64px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: padding-top 300ms;

    &.scheduleDemoBackground {
      background-color: $scheduleDemoBackground;
    }
    &.blogNewBackground {
      background-color: $blogNewBackground;
    }

    &.collapsedMenu {
      padding-top: 0;
      height: 56px;

      .link_container {
        margin-top: 0;
      }
    }

    .ioradLogoBar {
      margin-bottom: 2px;
    }

    @include breakpoint-sm {
      padding: 17px 24px 10px;

      .ioradLogoBar {
        margin-bottom: 0;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .transitionBlock {
    // https://github.com/iorad/iorad/issues/8516
    display: none;
    height: 40px;
    background: linear-gradient(180deg, #fff, transparent);

    @include breakpoint-sm {
      background: #fff;
    }
  }
  .moreLink {
    width: 45px;
    text-align: right;
  }
}

.topNavBar.userAuthorized {
  height: 48px;
  flex-wrap: nowrap;
  padding-right: 0;
  padding-left: 0;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  .extensionLink :global(.ext-link) {
    padding: 0;
    // margin-right: 18px !important;
    position: relative;
    color: inherit !important;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 32px;
      width: 2px;
      height: 16px;
      top: 50%;
      transform: translate(0, -50%);
      background-color: white;
      z-index: 1;
    }
    &:after {
      transform: translate(0, -50%) rotate(90deg);
    }
    button {
      height: 100%;
      width: auto;
      font-size: 15px;
      background-color: black;
      color: white;
      padding-left: 61px;
      padding-right: 19px;
      position: relative;
      min-width: auto;
      transition: background-color 0.1s linear;
      &:before {
        content: '';
        position: absolute;
        right: 1px;
        top: 50%;
        transform: translate(0, -50%);
        width: 1px;
        height: 24px;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
    &:hover,
    &.active {
      // button {
      //   background-color: var(--site-theme-color);
      // }
    }
  }
  .link {
    height: auto;
    line-height: 100%;
    margin-right: 0;
  }
  .additionalNavMenu {
    top: calc(100% + 3px);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    width: 211px;
    left: -12px;
    text-align: left;
    padding: 24px 24px;
    cursor: default;

    .menuItem {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 21px;
      gap: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      * {
        width: 100%;
      }

      *+* {
        padding-top: 15px;
      }
    }

    .titleItem {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000;
      text-align: end;

      &.active {
        color: var(--site-theme-color);
      }
    }

    .link {
      line-height: 21px;
      margin-right: 0;
      margin-bottom: 0;
      padding: 0;
      font-weight: normal;
      text-align: end;
      // padding-top: 15px;
    }
    &.accountPages {
      left: auto;
      right: -4px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-top: none;
      border-right: none;
      width: auto;
      text-align: center;
      font-weight: normal;
      .link {
        height: 39px;
        line-height: 39px;
        padding: 0 24px;
        &:hover,
        &.active {
          color: var(--site-theme-color);
        }
        &[href="/logout"] {
          font-weight: 600;
        }
      }
      .link.cancel {
        display: none;
      }
    }
  }
  .closeMenuBtn {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
  }
  .rightBlock {
    margin-left: auto;
    margin-right: 4px;
    display: flex;
    align-items: center;

    .rightBlockContent {
      display: flex;
      align-items: center;
      &.hidden {
        display: none;
      }
    }

    .link:last-child {
      margin-right: 0;
    }
  }
  .avatar {
    height: 40px;
    width: 40px;
  }
  .accountButton {
    cursor: pointer;
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 18px;

    .iconOverlay {
      display: none;
    }
    &.itemsShown, &:hover {
      .iconOverlay {
        content: '';
        background-color: transparent;
        opacity: 0.4;
        position: absolute;
        top: 2px;
        left: 0;
        width: 40px;
        height: 40px;
        display: block;

        &:hover {
          background-color: var(--site-theme-color);
        }
      }
      .additionalNavMenu {
        display: flex;
        top: 100%;
        margin-top: -1px;
      }
    }

    &.accountButtonStatic {
      cursor: default;
    }
  }
}

@include breakpoint-sm {
  .topNavBar.guestUser {
    flex-wrap: wrap;

    .link_container {
      margin-top: 7px;
    }
    .link {
      margin-right: 34px;
      font-weight: 600;
      font-size: 13px;
      line-height: 136%;
      height: auto;

      &:last-child {
        margin-right: 0;
      }

      &[href="/demo"] {
        display: none;
      }
    }
    .moreLink {
      width: auto;
      min-width: 33px;
    }
    .additionalNavMenu {
      left: auto;
      right: -24px;
      top: 20px;
      padding-right: 24px;
      padding-bottom: 30px;
      padding-top: 0;
      width: 100vw;
      margin-top: 0;

      .link {
        text-align: right;
        margin-right: 0;
        margin-top: 10px;
      }
    }
  }
  .topNavBar.userAuthorized {
    &.blur {
      opacity: 0.2;
      pointer-events: none;
    }
    padding: 0;
    .extensionLink {
      display: none;
    }
    .mainNavBlock {
      display: none;
    }
    .rightBlock {
      margin-right: 8px;
    }
    .avatar,
    .accountButton.itemsShown .iconOverlay {
      height: 32px;
      width: 32px;
    }
    .accountButton.itemsShown .iconOverlay {
      top: 0px;
    }
    .avatar {
      margin-top: 0;
    }
    .moreLink {
      margin-left: 24px;
      .moreLinkLabel {
        display: none;
      }
      .moreLinkIcon {
        display: inline-block;
        width: 14px;
        height: 10px;
      }
    }
    .additionalNavMenu {
      position: fixed;
      top: 48px !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0;
      padding: 49px 38px 0;
      border: none !important;
      width: auto;

      .menuItem {
        align-items: flex-start;
      }

      .titleItem {
        padding-bottom: 8px;
      }

      .titleItem,
      .link {
        text-align: left;
      }

      .link {
        height: 38px;
        line-height: 38px;
        padding: 0;
        margin: 0;
        &.mainNavLink {
          display: inline;
        }
      }
      &.accountPages {
        padding-left: 0;
        padding-right: 0;
        text-align: right;
        .link.cancel {
          display: inline-block;
        }
      }
    }
  }
}

.burgerIcon {
  position: relative;
  .line1,
  .line2,
  .line3 {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #000;
    transition-property: transform, top, left, width;
    transition-duration: 0.1s;
  }
  .line1 {
    top: 0;
  }
  .line2 {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .line3 {
    top: calc(100% - 2px);
  }
  &.closeIcon {
    .line1,
    .line3 {
      top: 50%;
    }
    .line1 {
      transform: translateY(-50%) rotate(45deg);
    }
    .line3 {
      transform: translateY(-50%) rotate(-45deg);
    }
    .line2 {
      left: 50%;
      width: 0;
    }
  }
}

.accountNotiButton {
  height: 16px;
  display: block;
  float: left;
  position: relative;

  &:hover,
  &.active {
    svg path {
      fill: var(--site-theme-color);
    }
  }

  // &.active.hasNoti {
  //   .newNoti {
  //     display: none;
  //   }
  // }

  .newNoti {
    display: none;
    position: absolute;
    bottom: -3px;
    right: -2px;
  }

  &.hasNoti {
    .newNoti {
      display: block;
    }
  }
}

.onboardingUrl {
  cursor: pointer;
  margin-right: 15px;
  display: flex;
  svg {
    width: 20px;
    height: 16px;
  }
  &:hover {
    svg path {
      &:first-child {
        fill: var(--site-theme-color);
      }
      &:not(:first-child) {
        stroke: var(--site-theme-color);
      }
    }
  }
}

// :not(:global(body.easy-popup-open)) {
//   .topNavBar.userAuthorized {
//     z-index: $topNavBarZIndex;

//     & + :global(.root) {
//       z-index: $topNavBarZIndex - 1;
//     }
//   }
// }
