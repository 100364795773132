@import '~theme/guidelines_variables';
@import '~theme/mixins';
@import '~theme/modules/variables';

.root {
  position: relative;
}

.popup {
  padding: 0;

  :global(.form-group .text-field.tf-v1) {
    @extend .input;

    &:focus {
      color: #000 !important;
    }

    &:global(.error) {
      padding-bottom: 4px;
      border: none !important;
      border-bottom: 1px solid var(--site-theme-color) !important;
      color: #000 !important;
    }
  }

  button {
    width: 230px;
    height: 40px;
    margin: auto;
    margin-top: 38px;
  }

  :global(.gl-content-wrapper) {
    margin-top: 148px;
    width: 350px;
  }

  :global(.popup-close) {
    top: 12px;
  }

  :global(.form-group) {
    margin-bottom: 12px;

    .generatePassword {
      position: absolute;
      right: 0;
      top: 28px;
      border: none;
    }

    :global(.error-container) {
      font-size: 14px;
      font-family: $fontBaseV2;
      text-align: left;
    }
  }
}

.input {
  border: 0 none;
  background: #fff;
  border-radius: 0;
  color: #999;
  font-size: 15px;
  line-height: 28px;
  caret-color: var(--site-theme-color);
  padding: 26px 2px 4px 2px;
  height: 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 100%;
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 300;
}

.delete_account_content {
  width: 300px;

  .tooltip {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 136%;
    text-align: center;
    font-family: Libre Franklin;

    a {
      text-decoration: underline;
    }
  }

  .delete_button {
    margin-top: 50px;
  }
}

.post_removal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .iorad_logo {
    margin-bottom: 20px;
  }

  span {
    font-family: Libre Franklin;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
  }
}

.subscription_info {
  :global(.easy-popup-textfield) {
    width: 100%;
  }

  .foot_info {
    text-align: left;
    color: #000;
    font-family: $fontBaseV2;
    font-weight: 300;
    font-size: 15px;
    line-height: 136%;
    margin-bottom: 10px;
    position: relative;
  }
}

.manage_2fa_modal {
  width: 322px;
  padding-left: 29px;
  margin-top: 176px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  .section_channel {
    &:not(:first-child) {
      margin-top: 35px;
    }

    &:not(.active):not(:hover) {
      opacity: 0.2;

      .channel_label span {
        font-weight: normal;
      }
    }

    &.waitingCode {
      .channel_to {
        pointer-events: none;

        &.channel_to_sms {
          position: relative;

          .resetPhoneNumber {
            position: absolute;
            right: 2px;
            top: 9px;
            z-index: 1;
            cursor: pointer;
            pointer-events: auto;

            &:hover {
              svg path {
                stroke: var(--site-theme-color);
              }
            }
          }
        }
      }
    }
  }

  .channel_label {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;

    input[type="radio"] {
      position: absolute;
      transform: translateX(-100%);
      left: -12px;
      margin-top: 0;
      width: 16px;
      height: 16px;
    }

    span {
      font-family: Libre Franklin;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      display: inline-block;
      height: 20px;
    }
  }

  .channel_description {
    font-family: Libre Franklin;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 136%;
    margin-top: 12px;
  }

  .channel_to {
    margin-top: 25px;
    height: 27px;

    > input {
      width: 100%;
      height: 100%;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-family: Libre Franklin;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 136%;
    }
  }

  .channel_code {
    position: relative;
    margin-top: 40px;
    height: 27px;

    input {
      width: 100%;
      height: 100%;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-family: Libre Franklin;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 136%;

      &::placeholder {
        opacity: 0.4;
      }

      &:focus {
        border-bottom-color: black;
      }
    }

    .status_confirmed {
      display: inline;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-family: Libre Franklin;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 150%;
      color: var(--site-theme-color);
      background: white;
    }
  }
}