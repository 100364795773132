@import './variables';

.setting_sidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;

  .setting_sidebar__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    border-top: 1px solid white;
  }

  .setting_sidebar__list {
    display: flex;
    flex-direction: column;
  }

  .setting_sidebar__item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: $defaultHeightBtn;
    padding-right: 16px;

    &.disabled {
      pointer-events: none;
      opacity: 0.4;

      &.disabledIsFake {
        pointer-events: all;
      }
    }

    &:hover,
    &.active {
      background: var(--background-color);

      .setting_sidebar__check_icon {
        opacity: 1;
      }
    }

    .setting_sidebar__item_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $defaultHeightBtn;
      height: 100%;
    }

    .setting_sidebar__check_icon {
      opacity: 0.4; 
    }

    h4 {
      @include defaultFont;
      flex-grow: 1;
      margin: 0;
    }

    span {
      @include defaultFont;
      opacity: 0.4;
    }
  }

  .setting_sidebar__footer {
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    button {
      max-width: 320px;
    }
  }
}
