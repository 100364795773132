@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    background: #2891be;
  }

  .title {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 56px 64px 45px;
  }

  .titleContent {
    @include ioradFont;
  }

  .compareSection {
    max-width: $maxWidthContent;
    margin: 77px auto 0;
    padding: 0 64px;

    &.yourOptions {
      margin-top: 148px;
    }

    &__title {
      font-size: 21px;
      font-weight: 600;
      line-height: 29px;
      color: black;
      margin: 0;
    }

    &__desc {
      font-size: 15px;
      line-height: 20px;
      color: black;
      max-width: 810px;
    }

    &__table {
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      row-gap: 4px;

      &_tr {
        display: flex;
        column-gap: 6px;

        &.ioradRow {
          background: #ffe6fb;

          .compareSection__table_td {
            background: #ffe6fb;
          }
        }
      }

      &_th {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        background: #f4f4f4;
        font-size: 21px;
        line-height: 29px;
        color: black;
        margin: 0;
        padding-top: 28px;
        padding-bottom: 31px;

        h3 {
          font-weight: 600;
          margin: 0;
        }

        img {
          width: 174px;
        }

        &.ioradCol {
          background: white;
        }
      }

      &_td {
        display: flex;
        flex-direction: column;
        flex: 1;
        background: #f4f4f4;
        font-size: 15px;
        line-height: 20px;
        color: black;
        padding: 15px 28px 32px 43px;

        h6 {
          font-size: inherit;
          font-weight: 600;
          margin: 0;
          margin-bottom: 24px;
        }

        ul {
          margin: 0;
          padding: 0;
        }

        a {
          text-decoration: underline;
          text-align: end;
          margin-top: 29px;
        }

        &.ioradCol {
          background: white;
        }
      }
    }
  }

  .iframeSection {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 142px 64px 100px;

    &__title {
      display: flex;
      align-items: center;

      h5 {
        color: black;
        font-size: 32px;
        font-weight: 600;
        line-height: 44px;
        margin: 0;
        margin-right: 25px;
      }

      :global(.simple-select) {
        max-width: 240px;
      }

      :global(.react-selectize-search-field-and-selected-values) {
        min-height: 40px !important;
      }

      :global(.react-selectize-toggle-button-container) {
        width: 40px !important;
        height: 40px !important;
      }

      :global(.resizable-input) {
        width: 0 !important;
      }

      :global(.dropdown-menu) {
        margin-left: 0 !important;
        margin-top: -4px !important;
      }
    }

    &__iframe {
      width: 100%;
      height: 694px;
      margin-top: 30px;

      iframe {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
      }
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .title {
      padding: 0;
    }

    .compareSection {
      margin-top: 90px;
      padding: 0 24px;

      &__desc {
        font-size: 13px;
        line-height: 18px;
        max-width: 100%;
        margin-top: 18px;
      }

      &__table {
        row-gap: 0;

        &.ioradTable {
          margin-top: 38px;
          padding: 0 24px;

          .compareSection__table_th {
            background: white;
            padding-bottom: 0;
          }
        }

        &_tr {
          column-gap: 3px;

          &.ioradRow {
            background: white;
          }

          &_title {
            margin: 17px 11px;

            h6 {
              font-size: 13px;
              line-height: 18px;
              color: black;
              margin: 0;
            }
          }
        }

        &_th {
          font-size: 13px;
          line-height: 18px;
          padding: 15px 20px 18px;
          text-align: center;

          h3 {
            font-size: inherit;
          }

          &.ioradCol {
            display: none;
          }
        }

        &_td {
          font-size: 13px;
          line-height: 18px;
          padding: 15px 5px 18px;

          &.ioradCol {
            display: none;
          }

          h6 {
            display: none;
          }

          ul {
            padding-left: 15px;
          }
        }
      }
    }

    .iframeSection {
      padding: 67px 24px 236px;

      &__title {
        justify-content: space-between;

        h5 {
          color: black;
          font-size: 21px;
          line-height: 29px;
        }
      }

      &__iframe {
        height: 583px;
        margin-top: 27px;
      }
    }
  }
}
