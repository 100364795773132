@import '~theme/modules/variables';

.container {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 2147483005;
}

.message {
	height: 130px;
	text-align: center;
	font-size: 13px;
  font-family: $fontBaseV2;
	color: #000000;
	padding-top: 50px;
	border-bottom: 2px solid white;
	background-color: #f2f2f2;
}

.closeIcon {
	position: absolute;
	right: 20px;
	top: 14px;
}

.actions {
	display: flex;
	height: 47px;
	align-items: center;
	background-color: #f2f2f2;
}

.actionItem {
	font-size: 13px;
  font-family: $fontBaseV2;
	color: #000000;
	font-weight: bold;
	text-align: center;
	width: 50%;
	padding-left: 50px;

	&:last-child {
		color: var(--site-theme-color);
		padding-left: 0px;
		padding-right: 50px;
	}
}