@import '~theme/modules/variables';

.container {
  display: flex;
  background: white;
  padding-top: 30px;
  height: 100%;

  &.isOpenOptionMenu {
    .content {
      padding: 0 24px;
      overflow: hidden;
      width: 100%;
    }
  }
}

:global(body.easy-popup-open) {
  // #8892 IOS: popup-header doesn't show because of position:fixed inside an element with padding
  .container {
    padding-top: 0;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  overflow: hidden;
}

.menuItem {
  width: 60px;
  padding: 17px 23px;
  opacity: 0.5;

  &:first-child {
    padding-top: 0;
  }

  &.active {
    opacity: 1;
  }
}

.menuItemImage {
  width: 17px;
  height: 17px;
  margin: 0 auto;
}

.content {
  flex-grow: 1;
  width: calc(100vw - 60px);
  position: relative;
  overflow-y: scroll;
}

.navigation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: #fff;
}

.navigationItem {
  margin-right: 30px;
  font-family: $fontBaseV2;
  font-size: 13px;
  color: #000000;
  font-weight: 600;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    color: var(--site-theme-color);
    cursor: pointer;
  }
}

.searchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 14px;

  input {
    font-size: 13px;
  }
}

.closeIcon {
  margin-left: 20px;
  width: 16px;
  height: 16px;
}

.tutorialListWrapper {
  position: relative;
  height: 100%;

  &.blur {
    opacity: 0.2;
  }
}

.trashInformation {
  font-size: 10px;
  font-family: $fontBaseV2;
  color: #000000;
  margin-top: 17px;
  margin-bottom: 28px;
}
