@import '~theme/mixins';
@import '~theme/modules/variables';
@import '~theme/guidelines';
@import '~theme/modules/spinnerAnimation';
@import '~theme/custom_react_google_flight_datepicker';

$fontTitle: "Fahkwang", sans-serif;

.privacy_popup {
  .optionDisabled {
    opacity: 0.4;
  }
}

.common_modal {
  .modal_header {
    @include roboto_32;
    margin-bottom: 16px;
    word-wrap: break-word;
  }

  .modal_footer {
    @media (max-width: 767px) {
      & {
        position: absolute;
        left: calc(50% - #{$gl-popup-inner-xs} / 2 + 15px);
        right: calc(50% - #{$gl-popup-inner-xs} / 2 + 15px);
        bottom: 0;
      }
    }
  }

  .modal_cancel_button {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    height: 64px;
    color: #0047FF;

    &:hover {
      color: #589dfa;
    }

    @include breakpoint-sm {
      height: 40px;
    }
  }
}

.modal_loading {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.multiple_selection_editor {
  &.isLoading {
    visibility: hidden;
  }

  &.isApplying {
  }

  .autocomplete_container {
    // width: $gl-popup-inner-md;
    border: 0 none !important;
    padding-bottom: 14px;
    position: relative;

    & > div {
      width: 100%;
    }

    :global(.highlighted) {
      background-color: $blueHighlight;
      color: #495c68;
      cursor: pointer;
    }

    input {
      font-size: 16px;
      font-weight: lighter;
      background-color: #F2F2F2;
      padding: 20px 24px;
      height: 100%;
      text-align: left;
      width: 100%;
      border: none;

      color: #000000;
      border: none;
      border-radius: 0;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);
      background: white;
      padding-bottom: 8px;

      &:focus {
        outline: 0;
        background-color: transparent;
        border-bottom: 2px solid #000000;
      }
    }
  }

  .tab_contents {

    .panel_content {
      border: none;
      & > div:not(:global(.form-group)) {
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        margin-bottom: 14px;
      }
    }
  }

  .formContainer {
    margin-top: 70px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;

    &:focus .label {
      color: #000000;
    }
  }

  .label {
    font-weight: normal;
    font-size: 15px;
    color: #999999;
    font-size: 10px;
    margin-bottom: 3px;
  }

  .input {
    width: 100%;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    padding: 0;
    height: 35px;
    font-size: 15px;
    font-weight: normal;

    &:hover {
      color: #000000;
    }
  }

  .domain_label {
    font-family: 'Libre Franklin';
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #828282;
    display: block;
    line-height: 24px;
    margin-bottom: 15px;

    input {
      width: 100%;
      height: 64px;
      border: none;
      border-radius: 5px;
      outline: none;
      padding: 16px;
      font-size: 16px;
      margin-top: 5px;
      font-weight: lighter;
      background-color: #F2F2F2;
    }
  }
}

.tab_contents {
  height: 100%;
  margin-top: 4 * $gl_gap;

  .panel {
    width: 426px;
  }

  .panel_content {
    border: 1px solid #E0E0E0;
    border-radius: 5px;

    & > div > div {
      padding: 8px 0;
    }

    &.description {
      border: none;
    }
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    .panel,
    .panel_content {
      width: 100%;
    }
  }
}

.tutorials_modal {
  :global(.popup-content) {
    padding: 0;
    overflow: hidden;
    height: 100%;
  }
}

.editor_modal {
  cursor: auto !important;

  &.isLoading {
    visibility: hidden;
  }

  .editor_modal_container {
    max-width: 980px !important;
    margin: 0 auto !important;
    width: auto !important;
    position: relative !important;

    :global(#legacy-app) {
      position: fixed;
      top: 48px;
      left: 0;
      height: calc(100% - 48px);
      width: 100%;
    }

    &.noPopup {
      :global(#legacy-app) {
        top: 0;
        height: 100%;
      }
    }
  }

  &.saving, &.saving:global(.bodyInteracted) {
    :global(.popup-header-wrapper) {
      pointer-events: none;
    }
  }
}

.player_modal {
  cursor: auto !important;

  &.isLoading {
    visibility: hidden;
  }

  .player_modal_container {
    max-width: 980px !important;
    margin: 0 auto !important;
    width: auto !important;
    position: relative !important;

    :global(#legacy-app) {
      position: fixed;
      top: 48px;
      left: 0;
      height: calc(100% - 48px);
      width: 100%;

      &:global(.is-player-popup) {
        top: 0;
        height: 100%;
        z-index: 11;
      }
    }
  }
}

.share_multiple {
  padding: 0;
}

.export_tutorial_popup {
  .item_container {
    padding-top: 40px;
  }

  .tab_nav {
    &::before {
      content: unset;
    }
  }

  .tab_item {
    width: max-content !important;
    margin-right: 30px;

    a {
      font-family: $fontBaseV2;
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      padding: 0;
      color: #000;
      border: none;

      &:hover {
        border: none;
        color: var(--site-theme-color);
      }

      &.tab_item_active {
        color: var(--site-theme-color)
      }
    }

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .tab_contents {
    margin-top: 65px;
  }

  .item {
    display: flex;

    @include breakpoint-sm {
      flex-direction: column;
    }
  }

  .docxBlock, .googleSlides {
    $itemWidth: 300px;
    flex-direction: column;
    width: auto;

    > * {
      width: $itemWidth;

      @include breakpoint-sm {
        width: 100%;
      }
    }

    .form_inline {
      display: flex;
      justify-content: space-between;

      &> .form_group {
        width: 48%;
      }

      input {
        width: 100%;
        line-height: 40px;
        padding: 0;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    .form_group {
      margin-bottom: 12px;

      .label {
        font-family: $fontBaseV2;
        font-weight: normal;
        font-size: 10px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.4);
      }

      :global(.easy-select) {
        font-family: $fontBaseV2;
        font-size: 15px;
        height: 40px;

        :global(.text) {
          margin: 0;
        }

        :global(.cursor) {
          border: none;
          right: 0;
          background-image: url("~assets/img/icons/arrow-down.svg");
          width: 40px;
          height: 40px;
        }
      }

      :global(.easy-autocomplete) {
        input {
          font-family: $fontBaseV2;
          font-size: 15px;
          height: 40px;
          padding: 0;
          margin: 0;

          &:hover, &:focus {
            border-color: rgba(0, 0, 0, 0.2);
          }
        }
      }

      :global(.error-msg) {
        display: none;
      }
    }

    .export_btn_container {
      display: flex;
      flex-direction: row;
      width: auto;
      margin-top: 50px;

      .export_btn {
        width: $itemWidth;
        margin-top: 0;

        @include breakpoint-sm {
          width: 100%;
        }
      }

      .export_view_links {
        margin-left: 35px;
      }
    }

    .export_settings {
      margin-top: 15px;
    }

    .last_element {
      margin-bottom: 15px;
    }

    .previewInfo {
      color: $warningColor;
      margin-bottom: 30px;
      cursor: default;

      &:after {
        display: none;
      }
    }

    :global(.input-error) {
      :global(.error-msg) {
        display: block;
      }
    }

    .buttonOpenSettings {
      margin: -22px 0 0 0;
      display: block;
      padding-left: 0;
    }
  }
  :global(.btn) {
    margin: 13px 0 0;
  }

  label {
    display: block;
  }

  .generate_video {
    width: 300px;
    margin-left: 80px;

    @include breakpoint-sm {
      width: 100%;
      margin-left: 0;
      margin-top: 50px;

      .export_btn {
        margin-top: 0;
      }
    }

    .tooltip {
      width: 100%;
      margin-top: 15px;
      padding: 10px 30px 20px;
      font-size: 15px;
      line-height: 136%;
      background-color: #f2f2f2;
      font-family: $fontBaseV2;
    }

    .checkbox {
      margin-top: 15px;
      display: inline-block;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .export_btn {
    @include breakpoint-sm {
      margin-top: 50px;
    }

    &.export_btn_again {
      margin-top: 50px;
    }
  }

  .buttonOpenGdoc {
    text-align: center;
    height: 20px;
    line-height: 20px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }

  .checkbox {
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    user-select: none;
    color: #000000;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 300;
    font-family: $fontBaseV2;
    padding-left: 35px;
    padding-top: 1px;

    input[type='checkbox']:checked~span::after {
      content: '';
      background-image: url('../../assets/img/icons/checkmark.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 76%;
      border: none;
    }

    input[type='checkbox'] {
      position: absolute;
      cursor: pointer;
      vertical-align: middle;
      width: 16px;
      height: 16px;
      bottom: 3px;
      left: 0;
      margin: 0;
      opacity: 0;
      top: 3px;
      z-index: 1;
    }

    span:after {
      position: absolute;
      cursor: pointer;
      left: 0;
      top: 4px;
      width: 16px;
      height: 16px;
      content: '';
      background-color: white;
      background-repeat: no-repeat;
      background-position: center center;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 1px;
    }
  }

  .export_settings {
    text-align: left;
    min-height: 64px;
    width: 300px;

    @include breakpoint-sm {
      width: 100%;
    }

    .select_container, .colorpicker_container {
      display: flex;
      flex-direction: column;
      margin-bottom: 17px;
      width: 305px;

      label {
        display: inline-block;
        max-width: 100%;
        font-weight: normal;
        color: #999999;
        font-size: 10px;
        margin-bottom: 3px;
      }
    }

    label {
      max-width: 100%;
      font-family: $fontBaseV2;
      font-size: 15px;
      font-weight: normal;
      text-align: left;
      color: #000;
      display: block;
      line-height: 24px;
      margin-bottom: 8px;
    }

    input[type="text"], input[type="number"], textarea {
      font-family: $fontBaseV2;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      display: inline-block;
      line-height: 20px;
      text-align: left;
      background: transparent;
      color: $light_black;
      padding: 22px $gl_gap * 3 22px $gl_gap * 3;
      margin-bottom: 24px;
      width: 100%;
      height: 100%;
      @media (max-width: 768px) {
        padding: 10px 0 10px $gl_gap * 2;
        margin-top: 18px;
      }
    }
    input[type="number"] {
      padding-top: 11px;
      padding-bottom: 11px;
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .export_view_links {
    margin-left: 35px;
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;

    @include breakpoint-sm {
      margin-left: 0;
    }

    .link_btn {
      font-size: 15px;
      font-family: $fontBaseV2;
      text-decoration: underline;
      color: #000;
      cursor: pointer;
      margin-right: 20px;

      &:hover {
        color: var(--site-theme-color);
        text-decoration: none;
      }
    }

    &.gdriver_link {
      margin-left: 0;
    }
  }
}

.unlock_tutorial_popup {
  font-family: Libre Franklin;
  font-style: normal;

  .item_container {
    max-width: 300px;
    margin: 128px auto 0;
  }


  .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin-bottom: 53px;
  }

  .or {
    font-size: 15px;
    line-height: 136%;
    text-align: center;
    color: #000000;
    opacity: 0.4;
    width: 100%;
    margin: 13px 0;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    margin: 0 0 28px;
  }

  .confirm_view {
    :global(.text-field.tf-v1) {
      width: 100%;
    }
    :global(.error-container) {
      margin-bottom: 22px;
      min-height: 20px;
    }
    .upgrade_btn {
      &:hover {
        background-color: transparent;
        color: var(--site-theme-color);
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.revision_popup {
  .revisions_container {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 128px;
    font-family: 'Libre Franklin';

    @include breakpoint-sm {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .revision_table {
      display: flex;
      flex-direction: column;
    }

    .revision_table__header {
      display: flex;
      padding: 16px;

      span {
        flex-shrink: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.4);

        &.datetime {
          flex-grow: 1;
        }

        &.author {
          width: 200px;
        }

        &.action {
          width: 84px;
        }
      }

      @include breakpoint-sm {
        column-gap: 20px;

        span.author {
          width: 100px;
        }

        span.action {
          width: 50px;
        }
      }
    }

    .revision_table__body {
      display: flex;
      flex-direction: column;
    }

    .revision_item {
      display: flex;
      // column-gap: 88px;
      // padding-block: 6px;
      padding: 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: -1px;

      svg:not(.keepColor) {
        color: inherit;

        [fill] {
          fill: currentColor;
          color: inherit;
        }

        [stroke] {
          stroke: currentColor;
          color: inherit;
        }
      }

      .datetime {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        flex-grow: 1;
      }

      .user_info {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 200px;

        .user_avatar {
          display: flex;
          position: relative;
          width: 24px;
          height: 24px;
          flex-shrink: 0;

          img {
            width: 100%;
          }
        }

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          margin-left: 20px;
          max-width: calc(100% - 50px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .action {
        display: flex;
        column-gap: 20px;
        align-items: center;
        flex-shrink: 0;
        // width: 84px;
        padding: 0;
        background: transparent;
        border: 0 none;
        color: rgba(0, 0, 0, 0.4);

        span {
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;

          &.hover_label {
            display: none;
          }
        }

        &.is_current {
          span.default_label {
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
          }
        }
      }

      &:hover {
        border: 1px solid rgba(0, 0, 0, 0.25);

        .user_info {
          .user_avatar:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(var(--site-theme-color-rgb), 0.2), rgba(var(--site-theme-color-rgb), 0.2));
          }
        }

        .action {
          color: #000;
          span {
            &.default_label {
              display: none;
            }
            &.hover_label {
              display: block;
            }
          }

          &:hover {
            color: var(--site-theme-color);
          }
        }
      }

      @include breakpoint-sm {
        column-gap: 20px;

        .datetime {
          font-size: 13px;
          line-height: 18px;
        }
        .user_info {
          width: 100px;

          .user_avatar {
            width: 16px;
            height: 16px;
          }

          span {
            font-size: 13px;
            line-height: 18px;
            max-width: calc(100% - 40px);
          }
        }
        .action {
          column-gap: 10px;
          // width: 50px;

          span {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }

  &.isLoading {
    visibility: hidden;
  }

  .emptyLearner {
    font-size: 15px;
    font-weight: 500;
    font-family: Libre Franklin;
  }
}

.deactivated_popup {
  .deactivated_container {
    margin-top: 158px;
    margin-bottom: 128px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .deactivated_toggler {
      display: flex;
      flex-shrink: 0;
      align-self: flex-start;

      &_label {
        color: #ccc;
      }

      &_value {
        color: inherit;
        font-weight: bold;
        margin-left: 7px;
      }

      &:hover {
        color: var(--site-theme-color);
      }
    }

    .deactivated_table {
      flex-grow: 1;
      border: 0;
      table-layout: fixed;
      margin-left: 145px;
      margin-top: 0;
      max-width: 700px;

      &_header {


        tr {
          width: 100%;
        }

        td {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.4);
          width: 33%;
          padding-block: 6px;

          &:hover {
            color: var(--site-theme-color);
          }
        }
      }

      &_body {
        &_row {
          td {
            padding-block: 6px;
          }

          .datetime {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
          }

          .user_info {
            display: flex;
            align-items: center;
            margin-left: 20px;
            margin-right: 20px;

            .user_avatar {
              display: flex;
              position: relative;
              width: 16px;
              height: 16px;
              flex-shrink: 0;

              img {
                width: 100%;
              }
            }

            span {
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              margin-left: 20px;
              max-width: 150px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .deactivated {

          }
        }
      }
    }

    .exportCSV {
      margin-left: 100px;

      &:hover {
        color: var(--site-theme-color);
      }
    }
  }

  &.isLoading {
    visibility: hidden;
  }
}

.shareForEmbedInfoSimpleHeader {
  :global(.popup-content) {
    flex-grow: 1;
    padding: 0;
  }

  :global(.popup-header-wrapper .popup-close) {
    display: none;
  }

  :global(.popup-header-wrapper .popup-header-wrapper-main) {
    transition: none;
    opacity: 1 !important;

    :global(.popup-subject) {
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }

  :global(.popup-header-wrapper .close-button-tutorial) {
    transition: none;

    &:before {
      background-color: transparent;
      transform: none;
      transition: none;
    }

    :global(.popup-header-returnTo) {
      display: none;
    }
  }
}
