@import '~theme/modules/variables';
@import '~theme/mixins';

.howItWorksSection {
  display: flex;
  flex-direction: column;
  max-width: $maxWidthContent;
  width: 100%;
  margin: 0 auto;
  padding: 131px 64px 80px;

  h3 {
    font-size: 32px;
    line-height: 136%;
    font-weight: 600;
    color: black;
    margin: 0;
    margin-bottom: 21px;
  }

  p {
    width: 100%;
    max-width: 618px;
    font-size: 15px;
    line-height: 136%;
    font-weight: 400;
    color: black;
    margin: 0;
    margin-bottom: 82px;
  }

  .wrapper {
    width: 100%;
    height: 702px;
  }

  iframe {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0.02);
  }
}

@include breakpoint-sm {
  .howItWorksSection {
    max-width: 100%;
    padding: 119px 24px 120px;

    h3 {
      font-size: 21px;
      margin-bottom: 18px;
    }

    p {
      max-width: 100%;
      font-size: 13px;
      margin-bottom: 40px;
    }

    .wrapper {
      height: 585px;
    }
  }
}
