@import '~theme/modules/variables';
@import '~theme/mixins';

.titleWrapper {
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 102px 64px 75px;

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .desc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-top: 23px;
    width: 622px;
  }
}

.contentWrapper {
  width: 100%;
  overflow-x: hidden;

  .content {
    max-width: $maxWidthContent;
    margin: 0 auto 57px;
    padding: 0 62px;
    display: flex;
    column-gap: 93px;
    min-height: 676px;
  }

  .leftContent {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 207px;
    row-gap: 14px;

    .chapterTitle {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      cursor: pointer;

      &.active {
        color: var(--site-theme-color);
      }
    }
  }

  .rightContent {
    background: #f4f4f4;
    flex-grow: 1;
    position: relative;
    padding: 22px 0 17px 20px;
    display: flex;
    flex-direction: column;

    &::after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      width: 1000vw;
      background: #f4f4f4;
    }
  }

  .chapterContent {
    width: 100%;
    flex-grow: 1;
  }

  .chapterItemsTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin-top: 105px;
    margin-bottom: 59px;
  }

  .chapterList {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 70px;
  }

  .chapterItem {
    width: 294px;

    img {
      width: 100%;
      height: 147px;
    }

    h5 {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-top: 20px;
    }

    p {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-top: 4px;
    }
  }

  .paginations {
    display: flex;
    justify-content: space-between;
    margin-top: 82px;

    .paginationBtn {
      display: flex;
      align-items: center;
      column-gap: 19px;
      cursor: pointer;

      span {
        margin: 0;
        font-weight: 600;
        font-size: 15px;
        line-height: 136%;
        color: #000000;
      }

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 170px 158px 140px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .contactBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .titleWrapper {
    max-width: 100%;
    padding: 16px 24px 38px;

    .title {
      font-size: 21px;
      line-height: 116%;
    }

    .desc {
      font-size: 13px;
      margin-top: 21px;
      width: 100%;
    }
  }

  .contentWrapper {
    .content {
      max-width: 100%;
      min-height: 100%;
      flex-direction: column;
      padding: 0;
    }

    .leftContent {
      width: 100%;
      row-gap: 22px;

      .chapterTitle {
        height: 40px;
        display: flex;
        align-items: center;

        &:not(.active) {
          display: none;
        }

        &.active {
          position: relative;
          color: #000000;
          justify-content: flex-end;
          padding-right: 56px;

          &::after {
            content: url('~assets/img/icons/arrow-down.svg');
            position: absolute;
            right: 11px;
            top: 0;
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .rightContent {
      padding: 53px 24px 26px;

      &::after {
        content: unset;
      }
    }

    .chapterItemsTitle {
      font-size: 21px;
      line-height: 116%;
      margin-top: 118px;
      margin-bottom: 39px;
    }

    .chapterList {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0;
      row-gap: 66px;
    }

    .chapterItem {
      width: 100%;

      img {
        width: 100%;
        height: 164px;
      }

      h5 {
        font-size: 13px;
        margin-top: 18px;
      }

      p {
        font-size: 13px;
        margin-top: 9px;
      }
    }

    .paginations {
      margin-top: 67px;

      .paginationBtn {
        span {
          font-size: 13px;
        }

        &.disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
  }

  .menuOnMobile {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100vw;
    height: calc(100vh - #{$topNavBarHeightMobile});
    padding: 16px 24px;

    .title {
      font-weight: 600;
      font-size: 21px;
      line-height: 116%;
      color: #000000;
      margin: 0;
      margin-bottom: 11px;
    }

    .menuList {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      row-gap: 2px;
    }

    .menuItem {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &.active {
        order: -1;
        position: relative;
        padding-right: 32px;
        margin-bottom: 18px;

        &::after {
          content: url('~assets/img/icons/arrow-down.svg');
          transform: rotateX(180deg);
          position: absolute;
          right: -13px;
          top: 0;
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .contactBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
