@import '~theme/modules/variables';
@import '~theme/mixins';

.selectUseCase {
  display: flex;
  height: 100vh;
  min-height: 600px;

  .selectUseCase__block {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
  }

  .selectUseCase__header {
    .selectUseCase__logo {
      padding: 24px 24px 40px;
    }
  }

  .selectUseCase__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 40px;
    padding-bottom: 24px;
    overflow-y: auto;
  }

  .selectUseCase__content_inner {
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 64px;
    }
  }

  .selectUseCase__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;

    .selectUseCase__item {
      display: flex;
      align-items: center;
      column-gap: 16px;
      height: 56px;
      padding: 0 24px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;
      color: black;

      span {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
      }

      svg path,
      svg rect,
      svg circle {
        &[fill] {
          fill: currentColor;
        }
        &[stroke] {
          stroke: currentColor;
        }
      }

      &.selected,
      &:hover {
        color: var(--site-theme-color);
        border-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .selectUseCase__tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: max-content;
    background: #f4f4f4;
    padding: 0 24px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: black;
    margin: 8px auto 0;
    visibility: hidden;

    &.show {
      visibility: visible;
    }
  }

  .selectUseCase__number_employees {
    display: flex;
    flex-direction: column;
    margin: 40px 0;

    .selectUseCase__number_employees_label {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-bottom: 40px;

      label {
        margin: 0;
        font-size: 21px;
        line-height: 24px;
        font-weight: 600;
        color: black;
        flex-grow: 1;
      }

      span {
        font-size: 21px;
        line-height: 24px;
        font-weight: 400;
        color: black;
      }
    }
  }

  .selectUseCase__footer {
    display: flex;
    justify-content: center;
    padding: 40px 24px;
  }

  .selectUseCase__iframe {
    width: 100%;
    height: 100%;
    background: #f4f4f4;
    overflow: hidden;

    image {
      width: 100%;
    }
  }
}
