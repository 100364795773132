@import '~theme/mixins';
@import '~theme/guidelines_variables';
@import '~theme/guidelines.scss';
@import '~theme/modules/variables';

$assistantAvatarMaxWidth: 17px;

.loading_container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
}
.loading_container_inner {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  padding: 20px;
}
.loading_item {
  display: flex;
  align-items: center;
  column-gap: 10px;

  &:not(:first-child) {
    margin-top: 10px;
  }
}
.loading_item_message {
}
.loading_item_elapsed {
}

.popup {
  @media (min-width: 768px) {
    width: 100%;
  }

  :global(.popup-content) {
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .popup_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: white;
    height: 100%;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 72px;
    overflow: hidden;
  }
}

.side {
}

.temperatureContainer {
  margin-top: 10px;
  margin-right: 24px;
  padding: 24px;
}

.modelContainer {
  margin-right: 24px;
  padding: 24px;
  padding-top: 0;

  label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  input[type='checkbox'] {
    margin-right: 15px;
    margin-top: 0;
  }
}

.searchIndex {
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-right: 24px;
  min-width: 305px;

  .searchIndex_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 12px;
  }
  .searchIndex_content_item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: black;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
    }

    label {
      color: black;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      margin: 0;
    }
  }
  .searchIndex_buildButton {
    max-width: 295px;
    margin-top: 40px;

    &[disabled] {
      opacity: 0.4;
    }
  }

  &.insideNewRoleSettings {
    border: none;
    margin-right: 0;
    width: 100%;
    max-width: 600px;
    padding: 24px 0;

    .searchIndex_content {
      row-gap: 0;
    }

    .searchIndex_content_item {
      height: 56px;
      flex-shrink: 0;

      span,
      label {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .searchIndex_buildButton {
      max-width: 320px;
      height: 56px;
      margin: 40px auto 0;
      font-size: 18px;
    }
  }
}

.questionnaire {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 5;
  display: flex;
  flex-direction: column;

  .red {
    color: red;
  }

  &.extensionEmbed {
    height: calc(100% - 48px);
  }

  &.uploading {
    button {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .questionnaire_header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    height: 56px;
    cursor: pointer;
    background: #f4f4f4;

    &::after {
      content: url('~assets/img/icons/close.svg');
      width: 56px;
      height: 56px;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      font-size: 15px;
      line-height: 20px;
      color: black;
    }
  }

  .questionnaire_body {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }

  .questionnaire_sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .questionnaire_preview {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    .questionnaire_preview_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      flex-direction: column;
      gap: 7px;

      div {
        font-size: 18px;
        line-height: 24px;
        color: white;
      }
    }
  }

  .questionnaire_select {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;

    .questionnaire_select_title {
      font-size: 18px;
      line-height: 24px;
      color: black;
      margin-bottom: 24px;
      text-align: center;
    }

    .questionnaire_select_buttons {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .fileWrapper {
        display: none;
      }
    }

    .questionnaire_select_submitBtn {
      width: 100%;
      height: 56px;
      font-size: 18px;
      background: #f4f4f4;
      border: none;
    }
    .questionnaire_select_closeBtn {
      width: 100%;
      height: 56px;
      font-size: 18px;
      background: white;
      border: none;
    }
  }

  .questionnaire_scan {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;

    .questionnaire_scan_submitBtn {
      width: 100%;
      height: 56px;
      font-size: 18px;
    }
  }

  .questionnaire_questions {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    width: 100%;

    .questionnaire_questions_header {
      display: flex;
      flex-direction: column;
      padding: 24px 24px 16px;
      position: relative;

      .questionnaire_questions_header_searchInput {
        width: 100%;

        input {
          height: 56px;
          font-size: 18px;
          padding-right: 232px;
        }
      }

      .questionnaire_questions_header_createQuestionBtn {
        position: absolute;
        right: 24px;
        transform: translateY(-1px);
        display: flex;
        min-height: 56px;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          background: white;
          padding: 0;
          outline: none;
          font-size: 18px;
          color: black;
          font-weight: 600;
          width: 56px;
          height: 56px;

          &:hover {
            svg {
              [fill] {
                fill: var(--site-theme-color);
              }
              [stroke='black'] {
                stroke: var(--site-theme-color);
              }
            }
          }
        }
      }
    }

    .questionnaire_questions_content {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      flex: 1;
      overflow-y: scroll;
      padding-left: 24px;
      padding-right: 13px;
    }

    .questionnaire_questions_footer {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      padding: 24px;

      button {
        max-width: 320px;
        height: 56px;
        font-size: 18px;

        &.alternate {
          border: none;
          background: #f4f4f4;
        }
      }
    }
  }

  .questionnaire_create_question {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.2);

    .questionnaire_create_question_input {
      display: flex;
      align-items: center;
      column-gap: 16px;
      padding: 16px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      &:focus-within {
        border-bottom-color: black;
      }

      span {
        font-size: 18px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.4);
      }

      input {
        font-size: 18px;
        line-height: 24px;
        color: black;
        padding: 0;
        border: none;
        outline: none;
        width: 100%;
      }
    }

    .questionnaire_create_question_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

      button {
        max-width: 320px;
        height: 56px;
        font-size: 18px;

        &.cancelBtn {
          background: #f4f4f4;
          border: none;
        }
      }
    }
  }

  .questionnaire_questions_item {
    display: flex;
    flex-direction: column;

    .questionnaire_questions_item_header {
      display: flex;
      position: relative;
      z-index: 2;

      span {
        display: flex;
        align-items: center;
        height: 56px;
        padding: 0 24px;
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.4);
        border: 1px solid transparent;
        border-bottom: none;
        cursor: pointer;

        &.active,
        &:hover {
          color: black;
        }

        &.active {
          border-color: rgba(0, 0, 0, 0.2);
          background: white;
          cursor: default;
        }

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
          opacity: 0.4;
        }
      }
    }

    .questionnaire_questions_item_content {
      position: relative;
      z-index: 1;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-top: -1px;
      padding: 24px;

      &.editing {
        border-bottom-color: black;
      }
    }

    .qa {
      display: flex;
      flex-direction: column;
      min-height: 146px;

      .qa_content {
        display: flex;
        flex-direction: column;
        gap: 16px;

        p {
          display: flex;
          flex-direction: column;
          gap: 16px;
          font-size: 18px;
          line-height: 24px;
          color: black;
          margin: 0;
          position: relative;

          .toolItem {
            position: absolute;
            bottom: -22px;
            height: 24px;
          }

          &.grey {
            color: grey;
          }

          label {
            color: rgba(0, 0, 0, 0.4);
            font-weight: normal;
            margin: 0;
          }

          input {
            width: 100%;
            font-size: 18px;
            padding: 0;
            border: none;
            outline: none;
          }

          b {
            font-weight: 600;
          }
        }
      }

      .qa_footer {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;

        svg {
          color: inherit;

          [fill] {
            color: inherit;
            fill: currentColor;
          }

          [stroke] {
            color: inherit;
            stroke: currentColor;
          }
        }

        .qa_footer_tool {
          display: flex;
          align-items: center;
          column-gap: 24px;

          .qa_footer_tool_item {
            display: flex;
            align-items: center;
            opacity: 0.4;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }

            &.active {
              color: var(--site-theme-color);
              opacity: 1;
            }

            &.disabled {
              pointer-events: none;
              cursor: not-allowed;
              opacity: 0.2;
            }
          }

          .qa_footer_tool_item_btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .settings {
      display: flex;
      flex-direction: column;
      min-height: 146px;

      .symbolWrapper {
        display: flex;
      }

      .symbol {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        cursor: pointer;

        &.active,
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }

      .settings_content {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }

      .settings_content_answer_type_wrapper {
        display: flex;
        gap: 16px;
        min-height: 60px;
      }

      .settings_content_answer_type {
        display: flex;
        align-items: center;
        column-gap: 16px;
        // padding: 16px 0 0 0;

        label {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: black;
          margin: 0;
        }

        b {
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          color: black;
          cursor: pointer;

          &.disabled {
            pointer-events: none;
            opacity: 0.8;
          }
        }
      }

      .settings_content_answer_format {
        display: flex;
        align-items: center;
        column-gap: 16px;
        // padding: 16px 0;
        min-height: 26px;

        label {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: black;
          margin: 0;
        }

        span {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: black;
        }
      }

      .settings_content_answer_format_values {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }

      .checkbox_input,
      .radio_input {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        margin: 0;
        // text-transform: capitalize;
        cursor: pointer;

        input {
          margin-right: 16px;
        }

        &.nocursor {
          cursor: auto;
        }
      }
    }

    .alternative_answers {
      display: flex;
      flex-direction: column;
      min-height: 146px;

      &_suggesting {
        padding: 8px 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }

      &_noResults {
        padding: 8px 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        text-align: center;
      }

      p {
        padding: 8px 0;
        margin: 0;

        input[type='radio']:checked + label::after {
          background: black;
        }

        input[type='radio'] {
          display: none;
        }

        label {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: black;
          margin: 0;
          padding-left: 40px;
          position: relative;
          cursor: pointer;
        }

        label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.2);
        }

        label:after {
          content: '';
          position: absolute;
          cursor: pointer;
          left: 4px;
          top: 8px;
          width: 8px;
          height: 8px;
          background: transparent;
          border-radius: 50%;
        }
      }
    }
    .justify_answer {
      display: flex;
      flex-direction: column;
      min-height: 146px;

      &_justifying {
        padding: 8px 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }

      &_noResults {
        padding: 8px 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        text-align: center;
      }

      p {
        padding: 8px 0;
        margin: 0;
      }

      &_label {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        margin: 0;
      }

      &_copyBtn {
        margin-top: 7px;

        &:hover {
          svg {
            [fill] {
              fill: var(--site-theme-color);
            }
          }
        }
      }
    }
    .shorten_answer {
      display: flex;
      flex-direction: column;
      min-height: 146px;

      &_shortening {
        padding: 8px 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }

      &_noResults {
        padding: 8px 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        text-align: center;
      }

      p {
        padding: 8px 0;
        margin: 0;

        input[type='radio']:checked + label::after {
          background: black;
        }

        input[type='radio'] {
          display: none;
        }

        label {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          color: black;
          margin: 0;
          padding-left: 40px;
          position: relative;
          cursor: pointer;
        }

        label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.2);
        }

        label:after {
          content: '';
          position: absolute;
          cursor: pointer;
          left: 4px;
          top: 8px;
          width: 8px;
          height: 8px;
          background: transparent;
          border-radius: 50%;
        }
      }
    }
    .explain_question {
      display: flex;
      flex-direction: column;
      min-height: 146px;

      &_explaining {
        padding: 8px 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }

      &_noResults {
        padding: 8px 0;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        text-align: center;
      }

      p {
        padding: 8px 0;
        margin: 0;
      }

      &_label {
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        margin: 0;
      }

      &_copyBtn {
        margin-top: 7px;

        &:hover {
          svg {
            [fill] {
              fill: var(--site-theme-color);
            }
          }
        }
      }
    }

    &:last-child {
      &::after {
        content: '';
        visibility: hidden;
        display: block;
        height: 100%; // prevent jump on last item
        min-height: 100%;
      }
    }
  }

  .questionnaire_footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .questionnaire_footer_content {
      display: flex;
      flex-direction: column;
    }

    .questionnaire_footer_content_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      height: 56px;
      padding: 0 24px;

      label {
        display: flex;
        align-items: center;
        column-gap: 20px;
        margin: 0;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: black;
        margin: 0;
        flex-grow: 1;
      }

      button {
        outline: none;
        border: none;
        padding: 0;
        background: transparent;
        color: black;
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
        margin-left: 20px;

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }

    .questionnaire_footer_content_button {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding: 24px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: wrap;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      button {
        margin: 0 auto;
        max-width: 320px;
        height: 56px;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50px;

        &.full {
          flex-basis: 100%;
        }
      }

      .fileWrapper {
        display: none;
      }
    }
  }

  .questionnaire_smartParsePopup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 2;
    display: flex;
    flex-direction: column;

    &_questions {
      flex-grow: 1;
      overflow: auto;

      &_item {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &_footer {
      margin-top: auto;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      max-height: 100%;

      &_checkboxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        padding: 24px;
      }

      &_images {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 24px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        overflow: auto;

        img {
          flex-grow: 1;
          height: auto;
          max-height: 100px;
          // min-width: 170px;
          object-fit: contain;
        }
      }

      &_buttons {
        display: flex;
        column-gap: 20px;
        padding: 24px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }

      &_startBtn {
        .hoverTextBtn {
          display: none;
        }

        &:hover {
          .defaultTextBtn {
            display: none;
          }
          .hoverTextBtn {
            display: block;
          }
        }
      }

      &_closeBtn {
        margin: 0 24px 24px;
        width: calc(100% - 48px);
      }

      &_uploadImage {
        .fileWrapper {
          display: none;
        }
      }
    }
  }

  .questionnaire_privateAnswersUnlockPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    z-index: 4;
    display: flex;
    flex-direction: column;

    .questionnaire_privateAnswersUnlockPopup_header {
      display: flex;
      align-items: center;
      height: 56px;
    }

    .questionnaire_privateAnswersUnlockPopup_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      overflow-y: auto;
    }

    .questionnaire_privateAnswersUnlockPopup_form {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      width: 100%;
      max-width: 600px;
      padding: 40px 0;

      .questionnaire_privateAnswersUnlockPopup_form_title {
        font-size: 21px;
        line-height: 24px;
        font-weight: 600;
        color: black;
        text-align: center;
      }

      .questionnaire_privateAnswersUnlockPopup_form_group {
        display: flex;
        flex-direction: column;
      }

      .questionnaire_privateAnswersUnlockPopup_form_label {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        margin-bottom: 10px;
      }

      .questionnaire_privateAnswersUnlockPopup_form_input {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 56px;
        column-gap: 10px;
        caret-color: var(--site-theme-color);

        &:focus-within {
          border-bottom-color: black;
        }

        input {
          padding: 0;
          margin: 0;
          outline: none;
          border: none;
          flex-grow: 1;
          height: 100%;
        }
      }
    }

    .questionnaire_privateAnswersUnlockPopup_form_buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

      button {
        max-width: 226px;
        height: 56px;
      }
    }

    .questionnaire_privateAnswersUnlockPopup_form_closeBtn {
      border: none;
      background: #f4f4f4;
    }
  }
}

.insideHelpCenter,
.insideWebQuestionnaire {
  height: 100%;

  .conversation_wrapper {
    max-width: 100%;
    position: relative;
  }

  .conversation {
    background: white;
  }

  .conversation_message {
    padding: 16px;
    // max-width: 280px;
    min-width: 280px;
    max-width: max-content;

    @include breakpoint-sm {
      min-width: auto;
      max-width: 80%;
    }

    &:not(:first-child) {
      margin-top: 30px;
    }

    &[data-role='assistant'] {
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
    &[data-role='user'] {
      background: #f4f4f4;
    }

    &.hide {
      display: none !important;
    }
  }

  .conversation_message_content_text {
    font-size: 18px;
    line-height: 24px;
    max-width: 648px;

    @include breakpoint-sm {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .conversation_message_content_actions_item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    opacity: 0.4;
    cursor: pointer;
    height: 100%;

    &:hover {
      opacity: 1;
    }

    > div[role='button'] {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: inherit;

      [fill] {
        color: inherit;
        fill: currentColor;
      }

      [stroke] {
        color: inherit;
        stroke: currentColor;
      }
    }
  }

  .conversation_message_content_actions {
    margin-top: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    column-gap: 24px;
  }

  &.isExpandable {
    width: 100%;

    .conversation {
      display: none;
    }

    .collapseHeader {
      display: none;
    }

    &.isChatExpanded {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: white;
      z-index: 3;

      &.isWidgetEmbed {
        .collapseHeader {
          display: flex;
        }
      }

      .conversation_wrapper {
        // margin: 0 auto;
      }

      .collapseHeader {
        $size: 48px;
        height: $size;
        display: flex;
        flex-direction: row;
        background: #f4f4f4;
        flex-shrink: 0;
        cursor: pointer;
        position: relative;

        &:hover {
          .collapseHeader_title::after {
            content: 'Back';
            background: #f4f4f4;
            font-weight: 400;
            font-size: 16px;
            line-height: 136%;
            color: black;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        &_back {
          width: $size;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &_title {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          line-height: 136%;
          color: black;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .expandBtn {
        display: none;
      }

      .conversation {
        display: flex;
        color: #000;
      }
    }
  }
}

.insideRoleSettings {
  height: 100%;

  .searchIndex {
    border: 0;
    min-width: 0;
    margin: 0;
  }
}

.conversation_wrapper {
  max-width: 900px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.isInputFocused {
    height: var(--visual-viewport-height, 100%);
  }
}

.conversation {
  background: #f4f4f4;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
.conversation_content {
  display: flex;
  flex-direction: column;
  padding: 24px;
  font-size: 18px;
  line-height: 20px;
  height: 100%;
  overflow-y: hidden;

  &:hover {
    overflow-y: scroll;
    padding-right: 13px;
  }

  @include breakpoint-sm {
    font-size: 16px;
    line-height: 22px;
    padding: 12px;

    &:hover {
      padding-right: 1px;
    }
  }

  // show messages from the bottom (#14101)
  .conversation_message {
    &:first-child {
      margin-top: auto;
    }
  }
}
.conversation_message {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  width: 100%;
  max-width: 480px;
  position: relative;

  &:not(:first-child) {
    margin-top: 24px;
  }

  &[data-role='user'] {
    background: rgba(0, 0, 0, 0.05);
    margin-left: auto;
    margin-right: 0;

    .conversation_message_content {
      align-items: flex-end;
    }
  }
  &[data-role='assistant'] {
    background: white;
    margin-left: 0;
    margin-right: auto;
    flex-direction: column;
    align-items: stretch;
    max-width: 100%;
  }
  &[data-role='error'] {
    background: red;
    color: white;
    margin-left: 0;
    margin-right: auto;
  }

  &[data-command='GROUPED_SEARCH_TUTORIALS'] {
    max-width: 648px; // for the iframe
  }

  &[data-command='REPLY'] {
    .conversation_message_stats {
      margin-left: 41px;
    }
  }

  &__tooltip {
    position: absolute;
    right: 3px;
    bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: help;
    opacity: 0.4;
  }
}

.conversation_message_content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
}

.conversation_message_content_user_avatar {
  margin-left: 0;
  margin-bottom: 12px;

  img {
    width: 24px;
    height: 24px;
  }
}
.conversation_message_content_assistant_avatar {
  margin-right: 0;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  img,
  svg {
    max-width: $assistantAvatarMaxWidth;
  }
}
.conversation_message_content_text {
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  white-space: pre-line;
  word-break: break-word;
  flex-grow: 1;

  a {
    font-weight: 600;
  }

  @include breakpoint-sm {
    font-size: 16px;
    line-height: 22px;
  }
}

.conversation_message_loading {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: #ccc;
  color: #ccc;
  animation: dot-elastic 1s infinite linear;
  margin-left: 12px;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -10px;
    width: 5px;
    height: 5px;
    border-radius: 3px;
    background-color: #ccc;
    color: #ccc;
    animation: dot-elastic-before 1s infinite linear;
  }

  &::after {
    left: 10px;
    width: 5px;
    height: 5px;
    border-radius: 3px;
    background-color: #ccc;
    color: #ccc;
    animation: dot-elastic-after 1s infinite linear;
  }
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.conversation_message_searchTutorialsReplyEmbedSlideshow {
  &_preamble {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;

    @include breakpoint-sm {
      font-size: 15px;
      line-height: 20px;
    }

    &_assistant_avatar {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      img,
      svg {
        max-width: $assistantAvatarMaxWidth;
      }
    }
  }

  &_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin-top: 24px;

    @include breakpoint-sm {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &_explanation {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin-top: 24px;

    @include breakpoint-sm {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &_iframe {
    margin-top: 24px;
    min-height: 400px; // for while it is loading
  }

  &_nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    &_button {
      background: #f4f4f4;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
      width: auto;
      min-width: 175px;
      height: 40px;

      span {
        color: inherit;
      }

      svg {
        color: inherit;

        [fill] {
          color: inherit;
          fill: currentColor;
        }

        [stroke] {
          color: inherit;
          stroke: currentColor;
        }
      }

      &:focus {
        color: black;
      }

      &:hover:not(:disabled) {
        background: #f4f4f4;
        color: var(--site-theme-color);
      }

      &._prev {
        svg {
          transform: rotate(180deg);
          margin-right: 23px;
        }
      }

      &._next {
        margin-left: auto;

        svg {
          margin-left: 23px;
        }
      }
    }
  }
}

.conversation_message_searchTutorialsReplyLinkList {
  &_preamble {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;

    @include breakpoint-sm {
      font-size: 16px;
      line-height: 22px;
    }

    &_assistant_avatar {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      img,
      svg {
        max-width: $assistantAvatarMaxWidth;
      }
    }
  }

  &_listItem {
    word-break: break-word;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &[role='button']:hover {
      .conversation_message_searchTutorialsReplyLinkList_listItem_title {
        color: var(--site-theme-color);
      }
    }

    &_title {
      color: inherit;
      transition: none;
      font-weight: 600;
    }

    &_explanation {
    }
  }

  &_actions {
    margin-top: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    column-gap: 24px;

    &_item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      opacity: 0.4;
      cursor: pointer;
      height: 100%;

      &:hover {
        opacity: 1;
      }

      > div[role='button'] {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        color: inherit;

        [fill] {
          color: inherit;
          fill: currentColor;
        }

        [stroke] {
          color: inherit;
          stroke: currentColor;
        }
      }
    }
  }
}

.conversation_message_searchDatasourceReplyLinkList {
  &_preamble {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;

    @include breakpoint-sm {
      font-size: 16px;
      line-height: 22px;
    }

    &_assistant_avatar {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      img,
      svg {
        max-width: $assistantAvatarMaxWidth;
      }
    }
  }

  &_listItem {
    word-break: break-word;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &_explanation {
    }
  }

  &_actions {
    margin-top: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    column-gap: 24px;

    &_item {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      opacity: 0.4;
      cursor: pointer;
      height: 100%;

      &:hover {
        opacity: 1;
      }

      > div[role='button'] {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        color: inherit;

        [fill] {
          color: inherit;
          fill: currentColor;
        }

        [stroke] {
          color: inherit;
          stroke: currentColor;
        }
      }
    }
  }
}

.conversation_message_search {
  margin: 20px 24px;
  margin-left: 41px;

  &.expanded {
    .conversation_message_search_expandButton {
      svg {
        transform: rotate(180deg);
      }
    }
    .conversation_message_search_content {
      display: flex;
    }
  }
}

.conversation_message_search_expandButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  padding: 0;
  background: transparent;
  color: black;
  margin-left: -12px;

  span {
    color: inherit;
    margin-left: 12px;
  }
  svg {
    color: inherit;

    [fill] {
      color: inherit;
      fill: currentColor;
    }
    [stroke] {
      color: inherit;
      stroke: currentColor;
    }
  }

  &:hover:not(:disabled) {
    color: var(--site-theme-color);
  }
}

.conversation_message_search_content {
  display: none;
  flex-direction: column;
  background: #f4f4f4;
  padding: 24px;
  margin-top: 26px;
}

.conversation_message_searchItem {
}

.conversation_message_context {
  margin-top: 8px;
}
.conversation_message_context_item {
  width: 100%;
  position: relative;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: black;
  margin-bottom: 0;

  &:not(:first-child) {
    margin-top: 18px;
  }
}
.conversation_message_context_item_similarity {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background: rgba(0, 0, 0, 0.1);
}
.conversation_message_context_item_anchor {
  text-decoration: underline;
}

.conversation_message_stats {
  margin-top: 15px;
  padding-top: 5px;
  color: black;
  opacity: 0.4;

  &.inside_search {
    margin-top: 0;
    padding-top: 7px;
    padding-bottom: 8px;
    border-top: none;
    opacity: 1;

    .conversation_message_stats_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include breakpoint-sm {
  .popup {
    .popup_container {
      flex-direction: column;
      justify-content: unset;
      margin: 0;
    }
  }
  .side {
    display: none;
  }
  .searchIndex {
    padding: 12px;
    margin-right: 0;
    width: 100%;
  }
  .searchIndex_content {
    font-size: 13px;
    line-height: 18px;
    row-gap: 10px;
  }
  .searchIndex_buildButton {
    margin-top: 12px;
    max-width: 100%;
  }
  .conversation_message {
    padding: 12px;
    max-width: 80%;

    &:not(:first-child) {
      margin-top: 12px;
    }

    &[data-command='GROUPED_SEARCH_TUTORIALS'] {
      max-width: 100%;
    }
  }
  .conversation_message_content_text {
    font-size: 13px;
    line-height: 18px;
  }
  .conversation_message_stats {
    display: none;
  }
  .conversation_message_search {
    margin-left: 28px;
    margin-top: 12px;
  }
  .conversation_message_context_item {
    padding: 10px 12px;
    font-size: 13px;
    line-height: 18px;
  }
}

:global(#ai_assistant_tooltip_searchTutorials),
:global(#ai_assistant_tooltip_searchDatasource) {
  :global(.tooltip-arrow) {
    display: none;
  }

  :global(.tooltip-inner) {
    border-radius: 0;
    padding: 5px 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: black;
    background: #f4f4f4;
    text-align: left;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.checkbox {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  user-select: none;
  color: #000000;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000000;
  font-weight: 300;
  font-family: $fontBaseV2;
  padding-left: 25px;
  padding-top: 2px;
  margin: 0;

  input[type='checkbox']:checked ~ span::after {
    content: '';
    background-image: url('../../assets/img/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 76%;
    border: none;
  }

  input[type='checkbox'] {
    position: absolute;
    cursor: pointer;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    bottom: 3px;
    left: 0;
    margin: 0;
    opacity: 0;
    top: 3px;
    z-index: 1;
  }

  span:after {
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    content: '';
    background-color: white;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1px;
  }
}

.createQuestionBtn {
  width: fit-content;
  margin: 25px 12px;
  height: fit-content;
  padding: 12px;
}

.editQuestionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 12px;
  height: 100%;

  label {
    font-weight: 600;
    margin-right: 10px;
    line-height: 23px;
    input {
      margin-right: 5px;
    }
  }

  textarea {
    width: 100% !important;
    min-height: 100px;
    border: 0 none;
    resize: vertical;
  }

  .editQuestionFormGroup {
    flex-grow: 0;
    overflow: auto;
  }

  .buttonGroup {
    flex-grow: 1;
    display: flex;
    gap: 15px;
    align-items: flex-end;
  }
}

:global(#ai_assistant_qa_footer_tool_item_tooltip),
:global(#ai_assistant_conversation_message_content_actions_item_tooltip),
:global(
    #ai_assistant_conversation_message_searchTutorialsReplyLinkList_actions_item_tooltip
  ),
:global(
    #ai_assistant_conversation_message_searchDatasourceReplyLinkList_actions_item_tooltip
  ),
:global(#ai_assistant_questionnaire_justify_answer_copyBtn_tooltip),
:global(#ai_assistant_questionnaire_shorten_answer_copyBtn_tooltip),
:global(#ai_assistant_questionnaire_explain_question_copyBtn_tooltip) {
  opacity: 1;
  transition: none !important;

  :global(.tooltip-arrow) {
    display: none;
  }

  :global(.tooltip-inner) {
    border-radius: 0;
    padding: 5px 10px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: black;
    background: #f4f4f4;
    text-align: left;
    word-break: break-word;
    max-width: 250px;
  }
}
:global(#ai_assistant_alternative_answer_tooltip) {
  opacity: 1;
  transition: none !important;

  :global(.tooltip-arrow) {
    display: none;
  }

  :global(.tooltip-inner) {
    border-radius: 0;
    padding: 10px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: black;
    background: #f4f4f4;
    text-align: left;
    word-break: break-word;
    max-width: 500px;
  }
}

.hoverTextWrapper {
  &:not(:hover) {
    .hoverText {
      display: none;
    }
    &.fakeDisabled {
      opacity: 0.4;
    }
  }
  &:hover {
    .text {
      display: none;
    }
  }
}

.downloadTool {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    position: relative;
    min-width: 56px;

    &:hover {
      color: inherit !important;
    }
  }

  .smallBtn {
    width: 56px;
  }

  .btn {
    min-width: 200px;
  }

  .btnText {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.downloadToolPopup {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  min-width: 307px;
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 2;

  label {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    font-weight: 500;
  }

  input {
    margin: 0;
  }
}

.toolItem {
  color: grey;
  cursor: pointer;

  &.flexEnd {
    align-self: flex-end;
  }

  svg {
    color: inherit;

    [fill] {
      color: inherit;
      fill: currentColor;
    }

    [stroke] {
      color: inherit;
      stroke: currentColor;
    }
  }

  &:hover {
    color: #000;
  }
}

.rangeInput {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  min-width: 340px;

  input {
    width: calc(100% - 40px);
    flex-grow: 0;
    height: 8px;
    background: #f2f2f2;
    outline: none;
    opacity: 0.65;
    -webkit-appearance: none;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 16px;
      background: #000;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 12px;
      height: 16px;
      background: #000;
      cursor: pointer;
    }
  }
}

.textInput {
  input {
    border: 1px solid #d0d0d0;
    padding: 2px 5px;
    opacity: 0.65;
    transition: opacity 0.2s;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.dateInput {
  input {
    border: 1px solid #d0d0d0;
    padding: 2px 5px;
    position: relative;
    color: white;
    width: 170px;
    height: 30px;
    opacity: 0.65;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 1;
    }

    &::before {
      position: absolute;
      top: 3px;
      left: 3px;
      content: attr(data-display);
      display: inline-block;
      color: black;
    }

    &::-webkit-datetime-edit {
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 3px;
      right: 0;
      color: black;
      opacity: 1;
    }
  }
}

.note {
  background: #f2f2f2;
  margin: 16px 0;
  padding: 10px 8px;
  font-size: 14px;
}
