.credit_card_popup {
  .wrapper {
    .btn_update {
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .btn_cancel {
      margin-top: 17px;
    }
  }
}
