@import '~theme/mixins';
@import '~theme/modules/variables';

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.detail_table {
  display: flex;
  flex-direction: column;

  .detail_table__header {
    padding: 18px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border-bottom: 1px solid #f4f4f4;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .detail_table__row {
    padding: 18px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    border-bottom: 1px solid #f4f4f4;

    &:last-child {
      border-bottom: none;
    }

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }
  }
}

.table {
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  width: 100%;
}

.table_header {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 0 24px;
  height: 56px;
  position: sticky;
  background: white;
  z-index: 2;
  top: 0;

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
    flex: 1;
    text-align: left;

    &:first-child {
      min-width: 172px;
    }

    .text {
      cursor: pointer;
    }
  }

  @include breakpoint-sm {
    > span:last-child {
      display: none;
    }
  }
}

.table_body {
  display: flex;
  flex-direction: column;
}

.table_row {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #fff;
}

.table_row_content {
  padding: 0 24px 24px;
  background: #f4f4f4;
}

.table_row_cols {
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 56px;
  padding: 0 24px;
  background: #f4f4f4;
  position: sticky;
  top: 56px;
  z-index: 1;
  cursor: pointer;

  &.table_row_cols_alt {
    justify-content: space-between;
  }

  svg {
    flex-shrink: 0;
  }

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: flex-start;

    &:first-child {
      min-width: 172px;
    }
  }

  @include breakpoint-sm {
    > span:last-child {
      display: none;
    }
  }

  &:hover,
  &.active {
    span {
      color: var(--site-theme-color);
    }

    svg {
      path {
        &[stroke] {
          stroke: var(--site-theme-color);
        }
        &[fill] {
          fill: var(--site-theme-color);
        }
      }
      rect {
        &[stroke] {
          stroke: var(--site-theme-color);
        }
        &[fill='black'] {
          fill: var(--site-theme-color);
        }
      }
      line {
        &[stroke] {
          stroke: var(--site-theme-color);
        }
      }
    }
  }
}

.table_row_hint {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.summaryTab {
  display: flex;
  flex-direction: column;
  position: relative;
}

.main_filters {
  display: flex;
  align-items: center;
}

.dateInputWrapper {
  display: flex;
  align-items: center;
  margin-right: 40px;

  .datepicker_preset_group {
    width: 100%;
    display: flex;
    column-gap: 24px;

    @include breakpoint-sm {
      justify-content: space-between;
    }
  }

  .datepicker_preset {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    font-family: $fontBaseV2;
    position: relative;
    padding: 0;
    padding-left: 24px;
    border-left: none;
    cursor: pointer;
    color: #000;

    @include breakpoint-sm {
      margin-right: 0;
      margin-bottom: 10px;
      width: 50%;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &.selected {
      &::after {
        content: '';
        position: absolute;
        left: 4px;
        top: 6px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #000000;
      }
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
}

.dateInput {
  display: flex;
  align-items: center;
  margin-left: 40px;

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
  }

  :global(.react-google-flight-datepicker .date-picker) {
    background: transparent;
  }
}

.summaryList {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 24px 24px;
}

.summaryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;

  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
  }
}

.summaryActions {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-top: 1px solid #fff;

  .summaryAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    line-height: 20px;
    color: black;
    font-weight: 400;
    height: 40px;
    cursor: pointer;

    &:hover {
      color: var(--site-theme-color);

      svg path {
        &[stroke] {
          stroke: var(--site-theme-color);
        }
        &[fill] {
          fill: var(--site-theme-color);
        }
      }
    }

    &.disabled {
      pointer-events: none;
    }
  }

  .summaryAction_label {
    display: flex;
    column-gap: 20px;
    font-weight: 600;
  }
}

.exportBtn {
  display: flex;
  align-items: center;
  background: #f4f4f4;
  margin-top: 1px;
  font-size: 15px;
  line-height: 20px;
  color: black;
  font-weight: 600;
  cursor: pointer;
  padding: 24px;

  span {
    margin-left: 20px;
  }

  &:hover {
    color: var(--site-theme-color);

    svg path {
      &[stroke] {
        stroke: var(--site-theme-color);
      }

      &[fill] {
        fill: var(--site-theme-color);
      }
    }
  }
}

.select_url_group {
  display: flex;
  flex-direction: row;

  > label {
    font-size: 15px;
    font-weight: normal;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin: 0;
    color: black;
  }

  > :global(.react-selectize) {
    &:global(.root-node) :global(.react-selectize-control) {
      border-bottom: none !important;
      font-weight: 600 !important;

      :global(.react-selectize-search-field-and-selected-values) {
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        justify-content: flex-start;
        min-height: 32px;
        max-width: 198px;
      }
    }

    &:global(.root-node):global(.default) :global(.dropdown-menu) {
      width: 200px !important;
      margin-left: 0 !important;

      :global(.simple-option) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &:global(.root-node):global(.default) :global(.resizable-input) {
      width: 0 !important;
    }
  }

  > a {
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

@include breakpoint-sm {
  .main_filters {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .dateInputWrapper {
      flex-direction: column;
      align-items: flex-start;
      margin-right: 0;
      width: 100%;
    }

    .select_date_range_group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      label {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: black;
        margin: 0;
      }

      .select_date_range_group_input {
        &:global(.root-node) {
          max-width: 200px !important;
        }
      }
    }
  }

  .dateInput {
    margin-left: 0;
    margin-top: 12px;

    &.hide {
      display: none;
    }
  }

  .select_url_group_mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;

    label {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: black;
      margin: 0;
    }

    .select_url_group_input {
      &:global(.root-node) {
        max-width: 200px !important;
      }
    }
  }
}

// ----------------------------------------

.learnerListContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - 60px);
  margin-top: 40px;

  .learnerListHeader {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
  }

  .learnerListContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }

  .loading {
    margin: 15px;
  }

  .avatar {
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
    min-width: 32px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .name {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-right: 10px;
    width: calc(100% - 64px);

    // .nameInner {
    //   white-space: nowrap;
    //   font-size: 13px;
    //   font-weight: 400;
    // }

    .nameInput {
      // display: none;
      border: 0 none;
      outline: 0;
      width: 100%;
      background: none;
      cursor: pointer;
    }

    &.overflow {
      position: relative;

      // .nameInput {
      //   display: block;
      //   border: 0 none;
      //   outline: 0;
      //   width: 100%;
      // }
      // .nameInner {
      //   display: none;
      // }

      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 10px;
        height: 100%;
        background: linear-gradient(
          to left,
          #ffffff 13.81%,
          rgba(255, 255, 255, 0) 99.61%
        );
      }
    }
  }

  .learnerList {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .inviteButton {
      position: fixed;
      z-index: 1;
      bottom: 4vh;
      left: 52px;

      @include breakpoint-sm {
        left: 50%;
        margin-left: -108px;
      }

      transition: bottom 0.3s ease;

      &.hide {
        bottom: -300px;
      }
    }
  }

  .list {
    list-style: none;
    padding: 0 24px;
    overflow: hidden;

    &:hover {
      @include nice-scrollbar;
    }
  }

  .listItem {
    display: flex;
    height: 48px;
    // background: #fff;
    padding: 8px;
    margin-bottom: 11px;
    box-sizing: border-box;
    font-family: Libre Franklin;
    font-size: 15px;
    line-height: 19.5px;
    font-weight: 400;
    cursor: pointer;
    position: relative;

    .avatar,
    .name {
      z-index: 1;
    }

    :global(.iorad-progress-rect) {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      top: 0;
      left: 0;
    }

    .progress {
      width: 32px;
      height: 32px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .actions {
      position: relative;
      display: flex;
      align-items: center;
      right: -8px;

      .underlay {
        display: none;
        content: '';
        z-index: 0;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: alias;
        z-index: 2;
        background: #fff;
        opacity: 0.2;
      }

      .menu {
        float: right;
        cursor: pointer;
        width: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 3;
      }

      .menuItems {
        display: none;
        position: absolute;
        right: 30px;
        top: -7px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #fff;
        padding: 0;
        z-index: 3;
        min-width: 190px;

        li {
          display: flex;
          align-items: center;
          padding: 10px 20px;
          cursor: pointer;
          font-size: 15px;
          font-weight: 600;
          font-family: Libre Franklin;

          &:hover {
            color: var(--marker-color);
            svg {
              circle,
              path {
                fill: var(--marker-color);
                stroke: var(--marker-color);
              }
            }
          }

          svg {
            margin-right: 15px;
          }
        }
      }

      &.open {
        .menuItems,
        .underlay {
          display: block;
        }
      }
    }

    &:not(:hover) {
      .actions:not(.open) {
        .menu {
          visibility: hidden;
        }
      }
    }

    &:hover,
    &.active {
      :global(.iorad-progress-rect-text),
      .name {
        color: var(--site-theme-color);
      }

      .name.overflow {
        &:after {
          display: none;
        }
      }
    }
  }

  .inviteButton {
    padding: 10px;
    width: 100%;
    max-width: 216px;
    height: 40px;
    background: #000;
    border: 0 none;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    margin: 0 auto;
  }

  .searchAndSort {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 10px;
    margin-bottom: 15px;
    height: 44px;
    background: #f4f4f4;
    position: relative;

    @include breakpoint-sm {
      height: 40px;
      padding-top: 6px;
    }

    .sortFilters {
      display: none;
    }

    &:focus-within {
      border-color: black;
    }

    &.defaultMode {
      .searchContainer {
        flex-grow: 1;
      }

      .searchInputWrapper {
        width: 100%;
      }

      .searchIcon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .searchInput {
        background: transparent;
        border: 0;
      }

      .sortOptionActived {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: black;
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: capitalize;
      }

      .sortIconContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 40px;
        cursor: pointer;
        margin-right: 4px;
        flex-shrink: 0;
      }
    }

    &.sortModeActive {
      justify-content: flex-start;
      user-select: none;

      .searchInputWrapper {
        display: none;
      }

      .sortActive {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .sortOptionActived {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: black;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          text-transform: capitalize;
        }

        .sortIconContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 40px;
          cursor: pointer;
          margin-right: 9px;
        }
      }

      .sortFilters {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 44px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top: none;
        padding-top: 7px;
        padding-right: 15px;
        padding-bottom: 13px;
        z-index: 2;

        .sortOptionItem {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: black;
          padding: 7px 0;
          text-align: right;
          text-transform: capitalize;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.searchFocused {
      .sortIconContainer,
      .sortOptionActived,
      .sortFilters {
        display: none;
      }

      .searchInput {
        padding-right: 10px;
      }
    }
  }

  .emptyLearner {
    width: 100%;
    text-align: center;
    padding: 30px 20px;
    background: #f4f4f4;
    color: black;

    @include breakpoint-sm {
      display: none;
    }
  }
}

.translations_content {
  width: 510px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .translations_content__header {
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 30px 0;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 1;

    svg {
      flex-shrink: 0;
    }

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }
  }

  .translations_content__content {
    padding-left: 36px;
    padding-bottom: 30px;
    max-height: calc(100% - 80px);
  }
  .translations_content__list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-bottom: 40px;
  }
  .translations_content__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }
  }
}

.pdfdownloads_content {
  width: 510px;

  .pdfdownloads_content__header {
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 30px 0;

    svg {
      flex-shrink: 0;
    }

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: black;
    }
  }

  .pdfdownloads_content__content {
    padding-left: 36px;
    padding-bottom: 30px;
  }
  .pdfdownloads_content__list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  .pdfdownloads_content__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      &:first-child {
        text-transform: capitalize;
      }
    }
  }
}

.summary_popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;

  .underlay {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .summary_popup__container {
    height: 100%;
    width: 100%;
    max-width: 1207px;
    background: white;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  .summary_popup__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include nice-scrollbar;
  }

  .summary_popup__close_btn {
    position: absolute;
    top: 4px;
    right: 4px;
    background: black;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    &:hover {
      background: var(--site-theme-color);
    }
  }
}

.loading_dot {
  position: relative;
  width: 11px;
  color: black;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  @keyframes dots {
    to {
      width: 1em;
    }
  }

  &:after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    content: '\2026';
    width: 0px;
    animation: dots 1s steps(4, end) infinite;
  }
}

.optionalTooltip {
  &:global(.tooltip.right) {
    :global(.tooltip-arrow) {
      display: none;
    }

    :global(.tooltip-inner) {
      border-radius: 0;
      padding: 5px 10px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: black;
      background: white;
      text-align: left;
    }
  }
}
