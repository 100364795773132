@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  background: #f4f4f4;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleContent {
    @include ioradFont;
    padding-top: 59px;
    padding-bottom: 90px;
  }

  .descWrapper {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    max-width: 618px;
    padding-bottom: 51px;
  }
}

.formWrapper {
  max-width: $maxWidthContent;
  margin: 0 auto;
  padding: 78px 64px 56px;

  .formContent {
    max-width: 575px;
  }

  .titleForm {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 24px;
  }

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .label {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.4);
    font-size: 10px;
    margin-bottom: 6px;
  }

  :global(.react-selectize-control) {
    height: 35px;
  }

  .input {
    width: 100%;
    background-color: #fff;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    padding: 0;
    height: 35px;
    font-size: 15px;
    font-weight: normal;
    outline: none;

    &::placeholder {
      color: var(--site-theme-color);
      opacity: 0.4;
    }

    &:active,
    &:focus {
      color: #000000;
      border-bottom: 1px solid var(--site-theme-color);
    }

    &.isError {
      border-color: var(--site-theme-color);
    }
  }

  .textarea {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
    resize: none;
    width: 100%;
    background-color: #fff;
    font-size: 15px;
    padding: 5px 0;
    overflow-y: auto;

    &:active,
    &:focus {
      color: #000000;
      border-bottom: 1px solid var(--site-theme-color);
    }

    &.isError {
      border-color: var(--site-theme-color);
    }
  }

  .resume {
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin-top: 29px;
    margin-bottom: 20px;

    &.isRequired {
      color: var(--site-theme-color);
    }
  }

  .fileInput {
    display: flex;
    align-items: center;
  }

  .fileName {
    font-size: 10px;
    line-height: 150%;
    color: #000000;

    &.noFile {
      opacity: 0.4;
    }
  }

  .fileLabel {
    position: relative;
    overflow: hidden;
    width: 240px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-right: 88px;

    &:hover {
      color: var(--site-theme-color);
    }

    input[type='file'] {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      outline: none;
    }
  }

  .applyBtn {
    width: 276px;
  }

  .formGroup {
    display: flex;
    flex-direction: column;

    &:focus .label {
      color: #000000;
    }

    &.hasError {
      .label {
        color: var(--site-theme-color);
      }

      .input {
        border-bottom: 1px solid var(--site-theme-color);
      }
    }

    .required {
      font-weight: normal;
      font-size: 10px;
      line-height: 150%;
      color: #000000;
      opacity: 0.4;
      margin: 0;
      margin-top: 16px;
    }
  }

  .formTextArea {
    height: 91px;
    position: relative;

    .textarea {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  .formGroupInline {
    display: flex;
    column-gap: 29px;
    justify-content: space-between;

    .formGroup {
      flex-grow: 1;
      flex-basis: 1;
    }
  }

  .buttonGroup {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: -2px;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 260px 158px 160px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;

    &.isLogged {
      margin-top: 46px;
    }

    .titleWrapper {
      padding: 0 24px;

      img {
        padding-top: 69px;
        padding-bottom: 45px;
      }
    }

    .descWrapper {
      font-size: 13px;
      line-height: 18px;
      max-width: 100%;
      padding-bottom: 36px;
    }
  }

  .formWrapper {
    max-width: 100%;
    margin: 0 auto;
    padding: 121px 24px 42px;

    .formContent {
      max-width: 100%;
    }

    .titleForm {
      font-size: 21px;
      line-height: 116%;
    }

    .form {
      display: flex;
      flex-direction: column;
      row-gap: 17px;
    }

    .resume {
      font-size: 13px;
      margin-top: 27px;
      margin-bottom: 45px;
    }

    .fileInput {
      flex-direction: column;
    }

    .fileName {
      font-size: 10px;
      line-height: 150%;
      color: #000000;

      &.noFile {
        opacity: 0.4;
      }
    }

    .fileLabel {
      width: 295px;
      height: 48px;
      margin-right: 0;
      font-size: 13px;
      margin-bottom: 13px;
    }

    .applyBtn {
      width: 295px;
      font-size: 13px;
    }

    .formGroup {
      display: flex;
      flex-direction: column;

      &:focus .label {
        color: #000000;
      }
    }

    .formTextArea {
      height: 110px;
    }

    .buttonGroup {
      margin-top: 46px;
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .learnMoreBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
