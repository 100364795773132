@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 102px;

  &.isLogged {
    margin-top: 146px;
  }

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 22px;
  }

  .desc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    max-width: 617px;
  }

  .cultureList {
    max-width: $maxWidthContent;
    margin: 80px auto 58px;
    display: flex;
    flex-direction: column;
    row-gap: 64px;
  }

  .cultureItem {
    display: flex;
  }

  .cultureImg {
    img {
      width: 635px;
      height: 334px;
    }
  }

  .cultureContent {
    display: flex;
    flex-direction: column;
    margin-top: 39px;
    margin-left: 58px;
    max-width: 518px;
  }

  .cultureTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 22px;
  }

  .cultureDesc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;

    a {
      text-decoration: underline;
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 207px 158px 160px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 14px;

    .titleWrapper {
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      margin-bottom: 21px;
    }

    .desc {
      font-size: 13px;
      max-width: 100%;
    }

    .cultureList {
      margin: 69px 24px 38px;
      row-gap: 39px;
    }

    .cultureItem {
      flex-direction: column;
    }

    .cultureImg {
      img {
        width: 100%;
        height: auto;
      }
    }

    .cultureContent {
      margin-top: 24px;
      margin-left: 0;
      max-width: 100%;
    }

    .cultureTitle {
      font-size: 21px;
      margin-bottom: 21px;
    }

    .cultureDesc {
      font-size: 13px;
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .learnMoreBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }
}
