.priceBtnExt {
  .current_text {
    display: none;
  };

  &.current {
    cursor: default;

    &:hover {
      cursor: default;
      .current_text {
        display: inline;
      }

      .text {
        display: none;
      }
    }
  }
}

.creditCardWrapper {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 16px;
  height: 226px;
  width: 352px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 24px;

  @include breakpoint-sm {
    width: 100%;
    height: 200px;
  }
}
