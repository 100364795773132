@import '~theme/modules/variables';
@import '~theme/mixins';

.root, .popup {
  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }
}

.root {
  font-size: 15px;
  font-family: $fontBaseV2;

  &.show {
    .arrow {
      transform: rotate(180deg);
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.toggleBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  background: transparent;
  gap: 10px;
  border: 0 none;
  font-weight: 600;

  &:hover {
    color: var(--site-theme-color);
  }
}

.popup {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #FFF;
  font-size: 15px;
  font-family: $fontBaseV2;
  border-radius: 0;
  width: 100%;
  max-width: 320px;

  :global(.popover-content) {
    padding: 0;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 16px;
  color: black;
  cursor: pointer;
  justify-content: space-between;

  &.selected, &:hover {
    color: var(--site-theme-color);
  }

  &:hover {
    background-color: rgba(var(--site-theme-color-rgb), 0.03);
  }

  button {
    background-color: transparent;
    border: 0 none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30px;
    height: 100%;
  }
}
