@import '~theme/form';
@import '~theme/modules/variables';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f2f2f2;
  z-index: 2147483004;
}

.actionContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 36px;
}

.actionItem {
  font-size: 13px;
  font-family: $fontBaseV2;
  color: #000000;
  font-weight: bold;
  margin-right: 28px;

  &:last-child {
    margin-right: 0;
  }
}

.selectedContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 17.5px;
}

.selectedCount {
  font-size: 13px;
  font-family: $fontBaseV2;
  color: #000000;
  margin-right: 17px;
}

.checkbox {
  height: 18px;
}