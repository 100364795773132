@import '~theme/mixins';
@import '~theme/modules/variables';

.container {
  margin-top: auto;
  flex-shrink: 0;
  border: 1px solid #f4f4f4;
  position: relative;

  &:focus-within {
    border-bottom-color: black;
  }

  &.insideHelpCenter,
  &.insideWebQuestionnaire {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border: none;
    min-height: 160px;
    &.extensionEmbed {
      $extensionFooterHeight: 48px;
      margin-bottom: $extensionFooterHeight;
    }

    .inputWrapper {
      padding: 0 24px 0 5px;
      border-bottom: 1px solid black;
    }

    .input {
      min-height: 56px;
      font-size: 18px;
      line-height: 24px;

      @include breakpoint-sm {
        font-size: 16px;
      }
    }

    .charCount {
      display: none;
    }

    .sendBtn {
      position: static;
      width: calc(100% - 48px);
      max-width: 320px;
      min-width: 53px;
      height: 56px;
      margin: 23px;
      text-transform: capitalize;
      font-size: 18px;
      line-height: 24px;

      &:hover {
        background-color: #3b3b3b;
      }
    }

    &.isExpandable:not(.isChatExpanded) {
      margin-top: 0;
      min-height: auto;

      .input {
        border-bottom-color: rgba(0, 0, 0, 0.4);
      }

      .sendBtn {
        display: none;
      }
    }

    &.disabled {
      background: white;
    }
  }

  &.disabled {
    background: rgba(239, 239, 239, 0.3);
  }
  &.insideWebQuestionnaire.extensionEmbed {
    margin-bottom: 0;
  }
}
.inputContainer {
  position: relative;
  width: 100%;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: text;
}
.inputWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  padding-right: 131px;
}
.inputIcon {
  display: flex;
  align-items: center;
  opacity: 0.4;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
.input {
  width: 100%;
  padding: 0;
  padding-right: 24px;
  border: none;
  border-radius: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: black;
  caret-color: var(--site-theme-color);
}
.charCount {
  display: flex;
  justify-content: flex-end;
  margin-block: 10px;
  margin-right: 24px;
  font-size: 10px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.4);
}
.sendBtn {
  position: absolute;
  right: 24px;
  top: 16px;
  z-index: 1;
  min-width: 83px;
  width: 83px;
  height: 40px;
}

.recordInput {
  &.isReady {
    opacity: 1;
  }
}

.recordIcon {
  cursor: pointer;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  border: 0 none;
  background: transparent;
  padding: 0;
  height: 100%;
  width: auto;

  .inner {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    background: #FFF;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }

  &.isMouseDown {
    opacity: 1;

    .inner {
      background: var(--site-theme-color);
      color: #FFF;
      border-radius: 50%;
    }
  }

  &.isRecording {
    opacity: 0.5;
    cursor: wait;

    &:hover {
      opacity: 0.5;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    color: inherit;

    [fill] {
      color: inherit;
      fill: currentColor;
    }

    [stroke] {
      color: inherit;
      stroke: currentColor;
    }
  }

  &:hover {
    opacity: 1;
  }
}

.slashPopup {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background: white;

  .slashPopup__list {
    display: flex;
    flex-direction: column;
  }

  .slashPopup__item {
    padding: 16px 24px;
    font-size: 18px;
    color: black;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;

    span {
      opacity: 0.2;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
