.revert {
  transform: rotate(180deg);
}
.text {
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  z-index: 1;
  margin-left: 10px;
  flex-shrink: 0;
  align-self: flex-start;

  svg {
    display: none;
  }

  .dot {
    content: '';
    position: absolute;
    right: 10px;
    top: 24px;
    display: none;
    width: 4px;
    height: 4px;
    background-color: var(--marker-color);
  }

  &.disabled, &.completed {
    span {
      display: none;
    }
  }

  &.disabled {
    .disabledIcon {
      // display: initial; // not used
    }
  }

  &.completed {
    .checkedIcon {
      display: initial;
      opacity: 0.4;
    }
  }

  &.hideText {
    span {
      display: none;
    }

    .dot {
      display: block;
    }
  }

  &.hideDot {
    .dot {
      display: none;
    }
  }

  &.active {
    // .checkedIcon {
    //   path {
    //     stroke: var(--marker-color);
    //   }
    // }
  }
}
.progress {
  font-size: 15px;
  z-index: 0;

  .progressInner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .bar, .overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background: #FFF;
  }

  .overlay {
    background: none;
    width: 100%;
  }

  &.active {
    color: var(--marker-color);

    // .bar {
    //   background: var(--marker-color);
    //   opacity: 0.1;
    // }
  }
}