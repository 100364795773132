.container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.viewer {
  width: 100%;
  height: 100%;
}
