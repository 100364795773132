.passwordStrength {
  position: absolute;
  background: #F2F2F2;
  top: 48px;
  width: 100%;
  border-radius: 0;
  z-index: 10;
  height: auto;
  min-height: 59px;
  padding-top: 9px;
  padding-left: 29px;

  &.disabled {
    display: none;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    width: 50%;
    text-align: left;
    font-size: 15px;
    line-height: 136%;
    color: #000;
    list-style: none;

    &:nth-child(even) {
      padding-left: 6px;
    }
    &.check {
      color: rgba(0, 0, 0, 0.4);;
    }
  }
}
