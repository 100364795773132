@import '~theme/modules/variables';
@import '~theme/mixins';

.root {
  transition: none !important;
}
.body {
  width: auto;
  height: 100vh;
  overflow: auto;
  margin: 0;

  &.usingNewLayout {
    height: calc(100vh - 16px); // 16px (margin)
  }
}
.root,
.body {
  margin: 0;
}

.section {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 10;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;

  @include breakpoint-sm {
    // height: calc(100vh - 48px);
  }
}

.sectionBox,
.sectionFadeIn,
.sectionFromLeft,
.sectionFromRight {
  font-weight: 400;
  // opacity: 1 !important;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  background: url('~assets/img/redesign2021/global/gradient_grey_bottom.png');
  background-position: bottom center;
  background-repeat: no-repeat;

  @include breakpoint-sm {
    background: none;
  }
}

.box {
  width: 100%;
  z-index: 20;
  position: relative;
  background: #fff;
  overflow: hidden;
}

.maxViewHeight {
  min-height: 100vh;
}

.inner {
  width: 100%;
  height: 100%;
}

:global(.scrollbar-track-y) {
  width: 15px !important;
  background: transparent !important;

  :global(.scrollbar-thumb) {
    width: 5px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0;
    left: 5px;
  }
}
