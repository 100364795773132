@import '~theme/modules/variables';
@import '../includes';

.container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  cursor: pointer;

  &.highlight {
    align-items: center;

    .buttonIcon {
      margin-top: -2px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  // &.isSelecting:hover {
  //   .itemCount {
  //     display: block;
  //   }
  // }

  &.isRename {
    .itemCount {
      display: none;
    }
  }

  &.categoryChange {
    .name:not(.highlight) {
      text-decoration: none !important;
    }
    .name.highlight {
      color: rgba(0, 0, 0, .4);
    }
  }

  &.trash {
    .name {
      color: #999999 !important;
    }
  }

  &:hover {

    // .itemCount {
    //   display: none;
    // }

    &.isSubCategory {
      .itemCount {
        display: block;
      }
    }

    .addButton {
      display: block;
      color: var(--site-theme-color);

      path {
        stroke: var(--site-theme-color);
      }
    }

    .name {
      color: var(--site-theme-color);
      text-decoration: none;
    }

    &.isEditing:hover {
      .buttonIcon {
        path {
          stroke: var(--site-theme-color);
        }
      }

      .itemCount {
        display: block;
      }

      .itemWrapper:hover {

        .itemCount {
          display: none;
        }
      }
    }

    &.isRename:hover {
      .itemCount {
        display: none;
      }
    }
  }

  .itemWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    &:hover {
      .name {
        color: var(--site-theme-color);
        text-decoration: none;
      }
      .itemCount {
        display: none;
      }
      .editIcon {
        display: block;

        path {
          fill: var(--site-theme-color);
        }
      }
    }
  }
}

.blur {
  opacity: 0.2;
  pointer-events: none;
}

.buttonIcon {
  margin-right: 21px;
  margin-top: 2px;
  opacity: 0.4;
  flex-shrink: 0;

  &.undoIcon {
    opacity: 1;
    margin-right: 22px;
  }

  &:hover {
    opacity: 1;

    &.undoIcon {
      path {
        fill: var(--site-theme-color);
      }
    }
  }

  &:last-child {
    margin-left: 15px;
    margin-right: 0;
  }
}

.name {
  font-size: 15px;
  font-family: $fontBaseV2;
  flex-grow: 1;
  word-break: break-word;
  line-height: 136%;
  max-width: 188px;
  color: black;

  &.highlight {
    color: var(--site-theme-color);
  }

  &.isEditing {
    max-width: 155px;

    .itemCount {
      display: none;
    }
  }
}

$categoryCountWidth: 34px;

.itemCount {
  color: #999999;
  font-size: 10px;
  font-family: $fontBaseV2;
  text-align: right;
  margin-top: 4px;
  line-height: 150%;
  // fixed widths to prevent width jumps that happen for categories with long names on hover
  width: $categoryCountWidth;
  text-align: right;
}

.notifyText {
  color: var(--site-theme-color);
  font-size: 10px;
  font-family: $fontBaseV2;
  text-align: right;
  margin-top: 4px;
  @include blinkAnimation();
}

.addButton {
  display: none;
  opacity: 0.4;
  margin-left: 18px;
  text-align: right;
  margin-right: -4px;

  &:hover {
    opacity: 1;
  }
}

.editIcon {
  margin-left: 21px;
  display: none;
  flex-shrink: 0;
}
