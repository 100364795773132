.emailList {
  display: inline-block;
}

.invite_email {
  :global(.invite-icon) {
    height: 26px;
    width: 34px;
    margin-right: 15px;
  }
}

.item {
  background: #cce9f9;
  border: 0;
  border-radius: 99px;
  box-shadow: none;
  color: #117099;
  display: inline-block;
  line-height: 35px;
  margin: 0 5px 8px;
  padding: 0 35px 2px 20px;
  position: relative;
  text-shadow: none;

  span {
    background: #cce9f9;
    border: 0;
    border-radius: 99px;
    box-shadow: none;
    color: #117099;
    font-size: 22px;
    line-height: 35px;
    margin: 0;
    margin-left: 0;
    padding: 0;
    text-shadow: none;
    vertical-align: baseline;
  }
}

.delete {
  transition: .2s;
  border: 0;
  font-size: 25px;
  font-weight: 100;
  width: 32px;
  border-top-right-radius: 99px;
  border-bottom-right-radius: 99px;
  color: inherit;
  background-color: inherit;
  position: absolute;
  right: 0;
  outline: 0;

  &:hover {
    background: rgba(0, 0, 0, .05);
  }
}

.emailInput {
  display: inline-block;
  min-width: 160px;
  margin: 0 5px;

  > div {
    display: inline-block;
    vertical-align: top;

    > div {
      position: relative;
    }

    :global(.tooltip) {
      :global(.tooltip-inner) {
        font-size: 15px;
        max-width: 285px;
      }

      &.tooltip_error {
        left: -145px !important;
        margin: 0;
        top: 30px !important;

        :global(.tooltip-arrow) {
          left: 10%;
        }
      }

      &.tooltip_enter {
        margin-left: 15px;
        top: 3px !important;
      }
    }
  }
}

.newEmail {
  color: #000;

  input {
    padding: 0;
  }
}

.list {
  border: 1px solid #d0d0d0;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
  overflow: auto;
  margin-top: 5px;

  .option {
    color: #444;
    padding: 3px 45px;
    font-size: 16px;
    &:hover {
      background-color: #f5fafd;
      color: #495c68;
    }
    &.selected {
      background-color: #bcd4ec;
      color: #495c68;
    }
  }
}
