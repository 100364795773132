.container {
  height: 100vh;
  padding-top: 41px;

  .thanks_container {
    text-align: center;
    background-color: #fff;
    margin: 0 auto;
    margin-top: calc(15vh - 40px);
    min-width: 660px;
    width: 50%;
    font-family: 'Libre Franklin';

    &::after {
      content: "";
      display: table;
      clear: both;
    }

    .thanks_title {
      color: #000;
      font-size: 40px;
      margin-bottom: 13px;
    }

    .thanks_description {
      font-size: 18px;
      color: #777;
    }

    .options_container {
      margin: auto;
      width: 70%;

      .hidden_option {
        display: none;
      }

      .option {
        float: left;
        width: 100%;
        margin: 30px 0px 30px 0px;

        &:nth-child(2) {
          margin-top: 35px;
        }

        .action {
          width: calc(100% - 165px);
          text-align: left;
          float: left;
          margin-left: 45px;

          .custom_link {
            font-size: 14px;
            text-decoration: none;
            color: #0047FF;
            padding-right: 20px;
            &:hover {
              color: #FF291C;
              text-decoration: underline;
            }
          }

          .title {
            display: block;
            width: 100%;
            color: #000;
            font-size: 16px;
            margin-bottom: 20px;
          }

          .new_tutorial_button {
            width: 186px;
            margin: 0;
          }
        }

        .image {
          margin: 0;
          float: left;
          width: 115px;
          height: 115px;
        }

        .img_make_private {
          background: transparent url("./images/make-private.png") no-repeat;
        }

        .img_add_privacy {
          background: transparent url("./images/add-privacy.png") no-repeat;
        }

        .img_schedule_calendar {
          background: transparent url("./images/schedule-calendar.png") no-repeat;
        }

        .img_globe {
          background: transparent url("./images/globe.png") no-repeat;
        }
      }
    }
  }
}


@media (min-width: 300px) and (max-width: 768px) {
  .container_large {
    height: auto;
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .container {
    padding-top: 25px;

    .thanks_container {
      margin-top: calc(10vh - 40px);
      padding: 0px 60px!important;
      width: 100%;
      min-width: 300px;
      padding: 0;
      box-shadow: none;

      .options_container {
        width: 100%;

        .option {
          width: 100%;
          margin-bottom: 20px;

          .image {
            margin: 0 auto;
            float: none;
          }

          .img_make_private + .action .title {
            text-align: center;
          }

          .action {
            width: 100%!important;
            float: left;
            margin-left: 0;
            text-align: center;

            .title {
              width: 100%;
              text-align: left;
              margin-top: 30px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
