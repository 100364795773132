// @import '~pdfjs-dist/web/pdf_viewer.css';

$toolbarHeight: 56px;

.container {
  position: absolute;
  width: 100%;
  height: calc(100% - $toolbarHeight);
  top: calc($toolbarHeight);
  padding-top: 15px;
  padding-bottom: 15px;
  overflow-y: auto;
  background-color: #707070;

  &:not(.isSelectingQuestion) {
    .selectorLayer,
    .viewerUnderlay {
      display: none;
    }
  }

  &.isSelectingQuestion {
    :global(.annotationLayer) {
      display: none;
    }
  }
}

.viewer {
  /* Define this variable here and not in :root to avoid to reflow all the UI
     when scaling (see #15929). */
  --scale-factor: 1;

  padding-bottom: var(--pdfViewer-padding-bottom);

  --hcm-highlight-filter: none;
  --hcm-highlight-selected-filter: none;

  @media screen and (forced-colors: active) {
    --hcm-highlight-filter: invert(100%);
  }

  &:global(.copyAll) {
    cursor: wait;
  }

  :global(.canvasWrapper) {
    overflow: hidden;
    width: 100%;
    height: 100%;

    canvas {
      margin: 0;
      display: block;

      &[hidden] {
        display: none;
      }

      &[zooming] {
        width: 100%;
        height: 100%;
      }

      :global(.structTree) {
        contain: strict;
      }
    }
  }

  :global(.page) {
    direction: ltr;
    width: 816px;
    height: 1056px;
    margin: var(--page-margin);
    position: relative;
    overflow: visible;
    border: var(--page-border);
    background-clip: content-box;
    background-color: rgb(255 255 255);
    z-index: 2;
  }

  :global(.dummyPage) {
    position: relative;
    width: 0;
    height: var(--viewer-container-height);
  }

  &:global(.noUserSelect) {
    user-select: none;
  }

  /*#if GENERIC*/
  &:global(.removePageBorders) :global(.page) {
    margin: 0 auto 10px;
    border: none;
  }
  /*#endif*/

  /*#if COMPONENTS*/
  &:global(.singlePageView) {
    display: inline-block;
  }

  &:global(.singlePageView) :global(.page) {
    margin: 0;
    border: none;
  }
  /*#endif*/

  &:is(:global(.scrollHorizontal), :global(.scrollWrapped)),
  :global(.spread ){
    margin-inline: 3.5px;
    text-align: center;
  }

  &:global(.scrollHorizontal),
  :global(.spread) {
    white-space: nowrap;
  }

  /*#if GENERIC*/
  &:global(.removePageBorders),
  /*#endif*/
  &:is(:global(.scrollHorizontal), :global(.scrollWrapped)) :global(.spread) {
    margin-inline: 0;
  }

  
  /*#if GENERIC*/
  &:global(.removePageBorders) :global(.spread .page),
  &:global(.removePageBorders):is(:global(.scrollHorizontal), :global(.scrollWrapped)) :global(.page) {
    margin-inline: 5px;
  }
  /*#endif*/

  :global(.page) :global(.loadingIcon)::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    // background: url("images/loading-icon.gif") center no-repeat;
    display: none;
    /* Using a delay with background-image doesn't work,
       consequently we use the display. */
    transition-property: display;
    transition-delay: var(--loading-icon-delay);
    z-index: 5;
    contain: strict;
  }

  :global(.page):global(.loading)::after {
    display: block;
  }

  :global(.page):not(:global(.loading))::after {
    transition-property: none;
    display: none;
  }
}

:global(.spread) :is(:global(.page), :global(.dummyPage)),
.viewer:is(:global(.scrollHorizontal), :global(.scrollWrapped)) :is(:global(.page), :global(.spread)) {
  display: inline-block;
  vertical-align: middle;
}

:global(.spread .page),
.viewer:is(:global(.scrollHorizontal), :global(.scrollWrapped)) :global(.page) {
  margin-inline: var(--spreadHorizontalWrapped-margin-LR);
}

.viewer {
  :global(.textLayer), .selectorLayer {
    
    position: absolute;
    text-align: initial;
    inset: 0;
    overflow: clip;
    opacity: 1;
    line-height: 1;
    text-size-adjust: none;
    forced-color-adjust: none;
    transform-origin: 0 0;
    caret-color: CanvasText;
    z-index: 0;

    &:global(.highlighting) {
      touch-action: none;
    }

    :is(span, br) {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
    }

    > :not(:global(.markedContent)),
    .markedContent span:not(.markedContent) {
      z-index: 1;
    }

    /* Only necessary in Google Chrome, see issue 14205, and most unfortunately
     * the problem doesn't show up in "text" reference tests. */
    /*#if !MOZCENTRAL*/
    :global(span.markedContent) {
      top: 0;
      height: 0;
    }
    /*#endif*/

    :global(.highlight) {
      --highlight-bg-color: rgb(180 0 170 / 0.25);
      --highlight-selected-bg-color: rgb(0 100 0 / 0.25);
      --highlight-backdrop-filter: none;
      --highlight-selected-backdrop-filter: none;

      @media screen and (forced-colors: active) {
        --highlight-bg-color: transparent;
        --highlight-selected-bg-color: transparent;
        --highlight-backdrop-filter: var(--hcm-highlight-filter);
        --highlight-selected-backdrop-filter: var(
          --hcm-highlight-selected-filter
        );
      }

      margin: -1px;
      padding: 1px;
      background-color: var(--highlight-bg-color);
      backdrop-filter: var(--highlight-backdrop-filter);
      border-radius: 4px;

      &:global(.appended) {
        position: initial;
      }

      &:global(.begin) {
        border-radius: 4px 0 0 4px;
      }

      &:global(.end) {
        border-radius: 0 4px 4px 0;
      }

      &:global(.middle) {
        border-radius: 0;
      }

      &:global(.selected) {
        background-color: var(--highlight-selected-bg-color);
        backdrop-filter: var(--highlight-selected-backdrop-filter);
      }
    }

    ::selection {
      /* stylelint-disable declaration-block-no-duplicate-properties */
      /*#if !MOZCENTRAL*/
      background: rgba(0 0 255 / 0.25);
      /*#endif*/
      /* stylelint-enable declaration-block-no-duplicate-properties */
      background: color-mix(in srgb, AccentColor, transparent 75%);
    }

    /* Avoids https://github.com/mozilla/pdf.js/issues/13840 in Chrome */
    /*#if !MOZCENTRAL*/
    br::selection {
      background: transparent;
    }
    /*#endif*/

    :global(.endOfContent) {
      display: block;
      position: absolute;
      inset: 100% 0 0;
      z-index: 0;
      cursor: default;
      user-select: none;
    }

    &:global(.selecting) :global(.endOfContent) {
      top: 0;
    }
  }

  .viewerUnderlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(var(--scale-factor) * var(--document-height));
    z-index: 1;
    cursor: alias;
  }

  .selectorLayer {
    z-index: 100;
    overflow: visible;

    .selectorRow {
      // display: flex;
      // flex-direction: column;
      // flex-grow: 1;
      // width: 100%;
      // position: absolute;
      // background-color: rgba(255, 255, 255, 0.2);

      &:hover {
        background-color: transparent;
        z-index: 2;
        .overlayRow {
          display: flex;
        }
      }

      .overlayRow {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(82, 86, 89, 0.9);
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 600;
        font-size: 10px;
        cursor: pointer;
      }

      // &:before {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   width: 0;
      //   height: 0;
      //   top: 50%;
      //   margin-top: -6px;
      //   border: 6px solid transparent;
      //   box-sizing: inherit;
      //   border-left-color: rgba(255, 46, 46, 0.7);
      //   background: transparent;
      // }

      &:not(.isSelecting) {
        .selectorCell {
          background-color: rgba(255, 46, 46, 0.15);

          &:hover {
            // background-color: rgba(3, 173, 79, 0.25);
            background-color: #FFF;
            color: #000;
          }

          &.nobg {
            background-color: transparent;
          }

          &.hidden {
            display: none;
          }
        }

        .hoverText {
          .toolItem {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
            &:first-child {
              border-left: 1px solid #000;
            }
            &:last-child {
              border-right: 1px solid #000;
            }
          }
        }
      }

      &.isSelecting {
        .hoverText {
          .toolItem {
            border: 1px solid #000;
          }
        }
        .selectorCell {
          background-color: rgba(3, 173, 79, 0.25);

          &.isCellSelected {
            border: 1px solid #0da600;
            background-color: rgba(208, 255, 229, 0.9);

            .toolItem {
              // background-color: #94e3b7;
              background-color: rgba(148, 227, 183, 0.75);
            }
          }
        }

        // &:before {
        //   border-left-color: rgba(3, 173, 79, 0.7);
        // }
      }
    }

    .selectorCell {
      position: absolute;

      .dragTool {
        background-color: rgba(0, 0, 0, 0.25);
      }

      .hoverText {
        height: calc(100% - 15px);
      }

      .text {
        position: absolute;
        left: 6px;
        top: 6px;
        width: calc(100% - 12px);
        height: calc(100% - 12px);
        font-size: 11pt;
        line-height: 140%;
        z-index: -1;
        overflow: hidden;
        display: flex;
        // align-items: center;
      }
    }
  }
}

.viewer {
  :global(.annotationLayer) {
    --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
    --input-focus-border-color: Highlight;
    --input-focus-outline: 1px solid Canvas;
    --input-unfocused-border-color: transparent;
    --input-disabled-border-color: transparent;
    --input-hover-border-color: black;
    --link-outline: none;

    @media screen and (forced-colors: active) {
      --input-focus-border-color: CanvasText;
      --input-unfocused-border-color: ActiveText;
      --input-disabled-border-color: GrayText;
      --input-hover-border-color: Highlight;
      --link-outline: 1.5px solid LinkText;

      :global(.textWidgetAnnotation) :is(input, textarea):required,
      :global(.choiceWidgetAnnotation) select:required,
      :global(.buttonWidgetAnnotation):is(:global(.checkBox), :global(.radioButton)) input:required {
        outline: 1.5px solid selectedItem;
      }

      :global(.linkAnnotation) {
        outline: var(--link-outline);

        &:hover {
          backdrop-filter: var(--hcm-highlight-filter);
        }

        & > a:hover {
          opacity: 0 !important;
          background: none !important;
          box-shadow: none;
        }
      }

      :global(.popupAnnotation .popup) {
        outline: calc(1.5px * var(--scale-factor)) solid CanvasText !important;
        background-color: ButtonFace !important;
        color: ButtonText !important;
      }

      :global(.highlightArea):hover::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: var(--hcm-highlight-filter);
        content: "";
        pointer-events: none;
      }

      :global(.popupAnnotation.focused .popup) {
        outline: calc(3px * var(--scale-factor)) solid Highlight !important;
      }
    }

    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transform-origin: 0 0;

    &[data-main-rotation="90"] :global(.norotate) {
      transform: rotate(270deg) translateX(-100%);
    }
    &[data-main-rotation="180"] :global(.norotate) {
      transform: rotate(180deg) translate(-100%, -100%);
    }
    &[data-main-rotation="270"] :global(.norotate) {
      transform: rotate(90deg) translateY(-100%);
    }

    &:global(.disabled) {
      section,
      .popup {
        pointer-events: none;
      }
    }

    :global(.annotationContent) {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;

      &:global(.freetext) {
        background: transparent;
        border: none;
        inset: 0;
        overflow: visible;
        white-space: nowrap;
        font: 10px sans-serif;
        line-height: 1.35;
        user-select: none;
      }
    }

    section {
      position: absolute;
      text-align: initial;
      pointer-events: auto;
      box-sizing: border-box;
      transform-origin: 0 0;

      &:has(div:global(.annotationContent)) {
        canvas:global(.annotationContent) {
          display: none;
        }
      }
    }

    :global(.textLayer.selecting) ~ & section {
      pointer-events: none;
    }

    :is(:global(.linkAnnotation), :global(.buttonWidgetAnnotation.pushButton)) > a {
      position: absolute;
      font-size: 1em;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    :is(:global(.linkAnnotation), :global(.buttonWidgetAnnotation.pushButton)):not(:global(.hasBorder))
      > a:hover {
      opacity: 0.2;
      background-color: rgb(255 255 0);
      box-shadow: 0 2px 10px rgb(255 255 0);
    }

    :global(.linkAnnotation.hasBorder):hover {
      background-color: rgb(255 255 0 / 0.2);
    }

    :global(.hasBorder) {
      background-size: 100% 100%;
    }

    :global(.textAnnotation) img {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    :global(.textWidgetAnnotation) :is(input, textarea),
    :global(.choiceWidgetAnnotation) select,
    :global(.buttonWidgetAnnotation):is(:global(.checkBox), :global(.radioButton)) input {
      background-image: var(--annotation-unfocused-field-background);
      border: 2px solid var(--input-unfocused-border-color);
      box-sizing: border-box;
      font: calc(9px * var(--scale-factor)) sans-serif;
      height: 100%;
      margin: 0;
      vertical-align: top;
      width: 100%;
    }

    :global(.textWidgetAnnotation) :is(input, textarea):required,
    :global(.choiceWidgetAnnotation) select:required,
    :global(.buttonWidgetAnnotation):is(:global(.checkBox), :global(.radioButton)) input:required {
      outline: 1.5px solid red;
    }

    :global(.choiceWidgetAnnotation) select option {
      padding: 0;
    }

    :global(.buttonWidgetAnnotation.radioButton) input {
      border-radius: 50%;
    }

    :global(.textWidgetAnnotation) textarea {
      resize: none;
    }

    :global(.textWidgetAnnotation) :is(input, textarea)[disabled],
    :global(.choiceWidgetAnnotation) select[disabled],
    :global(.buttonWidgetAnnotation):is(:global(.checkBox), :global(.radioButton)) input[disabled] {
      background: none;
      border: 2px solid var(--input-disabled-border-color);
      cursor: not-allowed;
    }

    :global(.textWidgetAnnotation) :is(input, textarea):hover,
    :global(.choiceWidgetAnnotation) select:hover,
    :global(.buttonWidgetAnnotation):is(:global(.checkBox), :global(.radioButton)) input:hover {
      border: 2px solid var(--input-hover-border-color);
    }
    :global(.textWidgetAnnotation) :is(input, textarea):hover,
    :global(.choiceWidgetAnnotation) select:hover,
    :global(.buttonWidgetAnnotation.checkBox) input:hover {
      border-radius: 2px;
    }

    :global(.textWidgetAnnotation) :is(input, textarea):focus,
    :global(.choiceWidgetAnnotation) select:focus {
      background: none;
      border: 2px solid var(--input-focus-border-color);
      border-radius: 2px;
      outline: var(--input-focus-outline);
    }

    :global(.buttonWidgetAnnotation):is(:global(.checkBox), :global(.radioButton)) :focus {
      background-image: none;
      background-color: transparent;
    }

    :global(.buttonWidgetAnnotation.checkBox) :focus {
      border: 2px solid var(--input-focus-border-color);
      border-radius: 2px;
      outline: var(--input-focus-outline);
    }

    :global(.buttonWidgetAnnotation.radioButton) :focus {
      border: 2px solid var(--input-focus-border-color);
      outline: var(--input-focus-outline);
    }

    :global(.buttonWidgetAnnotation.checkBox) input:checked::before,
    :global(.buttonWidgetAnnotation.checkBox) input:checked::after,
    :global(.buttonWidgetAnnotation.radioButton) input:checked::before {
      background-color: CanvasText;
      content: "";
      display: block;
      position: absolute;
    }

    :global(.buttonWidgetAnnotation.checkBox) input:checked::before,
    :global(.buttonWidgetAnnotation.checkBox) input:checked::after {
      height: 80%;
      left: 45%;
      width: 1px;
    }

    :global(.buttonWidgetAnnotation.checkBox) input:checked::before {
      transform: rotate(45deg);
    }

    :global(.buttonWidgetAnnotation.checkBox) input:checked::after {
      transform: rotate(-45deg);
    }

    :global(.buttonWidgetAnnotation.radioButton) input:checked::before {
      border-radius: 50%;
      height: 50%;
      left: 25%;
      top: 25%;
      width: 50%;
    }

    :global(.textWidgetAnnotation) input.comb {
      font-family: monospace;
      padding-left: 2px;
      padding-right: 0;
    }

    :global(.textWidgetAnnotation) input.comb:focus {
      /*
       * Letter spacing is placed on the right side of each character. Hence, the
       * letter spacing of the last character may be placed outside the visible
       * area, causing horizontal scrolling. We avoid this by extending the width
       * when the element has focus and revert this when it loses focus.
       */
      width: 103%;
    }

    :global(.buttonWidgetAnnotation):is(:global(.checkBox), :global(.radioButton)) input {
      appearance: none;
    }

    :global(.fileAttachmentAnnotation .popupTriggerArea) {
      height: 100%;
      width: 100%;
    }

    :global(.popupAnnotation) {
      position: absolute;
      font-size: calc(9px * var(--scale-factor));
      pointer-events: none;
      width: max-content;
      max-width: 45%;
      height: auto;
    }

    :global(.popup) {
      background-color: rgb(255 255 153);
      box-shadow: 0 calc(2px * var(--scale-factor))
        calc(5px * var(--scale-factor)) rgb(136 136 136);
      border-radius: calc(2px * var(--scale-factor));
      outline: 1.5px solid rgb(255 255 74);
      padding: calc(6px * var(--scale-factor));
      cursor: pointer;
      font: message-box;
      white-space: normal;
      word-wrap: break-word;
      pointer-events: auto;
    }

    :global(.popupAnnotation.focused .popup) {
      outline-width: 3px;
    }

    :global(.popup) * {
      font-size: calc(9px * var(--scale-factor));
    }

    :global(.popup) > :global(.header) {
      display: inline-block;
    }

    :global(.popup) > :global(.header) h1 {
      display: inline;
    }

    :global(.popup) > :global(.header) .popupDate {
      display: inline-block;
      margin-left: calc(5px * var(--scale-factor));
      width: fit-content;
    }

    :global(.popupContent) {
      border-top: 1px solid rgb(51 51 51);
      margin-top: calc(2px * var(--scale-factor));
      padding-top: calc(2px * var(--scale-factor));
    }

    :global(.richText) > * {
      white-space: pre-wrap;
      font-size: calc(9px * var(--scale-factor));
    }

    :global(.popupTriggerArea) {
      cursor: pointer;
    }

    section svg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    :global(.annotationTextContent) {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      color: transparent;
      user-select: none;
      pointer-events: none;

      span {
        width: 100%;
        display: inline-block;
      }
    }

    svg:global(.quadrilateralsContainer) {
      contain: strict;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
}

.viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.textBox {
  transform-origin: 0 0;

  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
  }

  li {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  label {
    display: flex;
    align-items: center;
    height: fit-content;
    margin: 0;
    gap: 10px;
    font-weight: 400;

    input {
      margin: 0;
    }
  }
}

.checkBox {
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;

  &.checked {
    border-color: transparent;
  }

  &:not(.checked ){
    svg {
      display: none;
    }
  }
}

.dragTool {
  width: 100%;
  font-size: 9px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;

  .dragText {
    background: #000;
    color: #FFF;
    width: 65px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.toolItem {
  position: static !important;
  color: #000 !important;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  &:hover {
    color: rgba(3, 173, 79, 1) !important;
  }

  &.unhoverBtn {
    display: flex;
  }

  &.hoverBtn {
    display: none;
  }

  &.removeBtn {
    color: rgba(255, 46, 46, 0.6) !important;

    &:hover {
      color: rgba(255, 46, 46, 1) !important;
    }
  }
}

.hoverTextWrapper {
  &:hover {
    .hoverText {
      display: flex;
      .toolItem {
        &.hoverBtn {
          display: flex;
        }

        &.unhoverBtn {
          display: none;
        }
      }
      
    }
  }
}

.hoverText {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
  background: rgba(255, 255, 255, 0.5);
  color: #000000;
  white-space: normal;
  position: relative;

  &:not(.show) {
    display: none;
  }

  &.nobg {
    .toolItem {
      background-color: transparent !important;
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    width: 0;
    height: 0;
    top: 0;
    margin-left: -6px;
    border: 6px solid transparent;
    box-sizing: inherit;
    border-top-color: #000000;
    background: transparent;
    z-index: 2;
  }
}

.toolBox {
  display: flex;
  align-items: center;
  gap: 10px;
  height: $toolbarHeight;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  width:100%;
  color: #FFF;
  background-color: #707070;
  padding: 0 24px;

  .toolBoxFileName {
    display: flex;
    align-items: center;
    column-gap: 20px;
    flex: 1;

    span {
      font-size: 18px;
      line-height: 24px;
      color: white;
    }
  }

  .toolBoxItem {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
  }

  button {
    width: fit-content;
    padding: 6px 12px;
    height: fit-content;
    min-width: auto;
    background: #525659;
    font-size: 12px;

    &:hover {
      background-color: rgba(3, 173, 79, 0.5);
    }
  }

  svg {
    color: inherit;
    cursor: pointer;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }
}

.contextMenu {
  background-color: #f1f1f1;
  border: 1px solid #e3e3e3;
  color: #000;
  padding: 0;
  margin: 0;
  position: fixed;
  list-style: none;
  display: none;
  font-size: 10px;
  font-weight: 600;

  &.show {
    display: flex;
  }

  li {
    padding: 10px;
    cursor: pointer;
  }
}

.selectorContainer {
  display: none;
}

.isDrawing {
  .canvas {
    display: block;
    z-index: 10 !important;
  }
}

.canvas {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  cursor: crosshair;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  text-transform: uppercase;
}

