@import "../../theme/mixins";
@import '~theme/modules/variables';

@mixin sideMenu {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 400px;
  z-index: 2;

  @media screen and (max-width: 1300px) {
    width: 300px;
  }
}
@mixin searchBar {
  min-height: 48px;
  max-height: 48px;
  padding: 15px 24px 0px;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

@mixin barLeftIcon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    svg path {
      &[fill] {
        fill: var(--marker-color);
      }
      &[stroke] {
        stroke: var(--marker-color);
      }
    }
  }
}

.searchResultList {
  display: flex;
  flex-direction: column;

  .searchResultItem {
    padding: 16px;
    position: relative;
    cursor: pointer;
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .searchResultItemInner {
      flex-grow: 1;
      z-index: 1;
    }

    :global(.iorad-progress-rect-bar) {
      // background: rgba(0, 0, 0, 0.05);
      background: none;
    }

    &:global(.iorad-progress-completed .iorad-progress-rect-bar) {
      // background: #FFF;
    }

    :global(.iorad-progress-rect-text) {
    }

    &.plain {
      padding: 13.3px 16px;
      // min-height: 48px;
      display: flex;

      .tutorialHeadBar,
      .tutorialFooterBar,
      .tutorialDescription,
      .dot {
        display: none;
      }

      .tutorialTitle {
        margin-bottom: 0;
        font-size: 15px;
        line-height: 20.4px;
        flex-grow: 1;
      }
    }

    &:hover {
      :global(.iorad-progress-rect .iorad-progress-rect-bar) {
        // background: var(--marker-color);
        // opacity: 0.1;
      }

      :global(.iorad-progress-rect-text) {
        color: var(--marker-color);
      }

      :global(.iorad-progress-rect-checked) {
        path {
          stroke: var(--marker-color);
        }
      }
    }

    .dot {
      content: '';
      position: absolute;
      right: 10px;
      top: 20px;
      display: none;
      width: 6px;
      height: 6px;
      background-color: var(--marker-color);
      border-radius: 50%;
    }

    .tutorialHeadBar {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .tutorialFooterBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;

      :global(.iorad-progress-square) {
        margin-left: 10px;
      }

      :global(.iorad-progress-rect-text) {
        width: 100%;
      }

      & > * + :global(.iorad-progress-rect-text) {
        width: auto;
      }
    }

    .tutorialCategory,
    .tutorialTag {
      padding: 0 8px;
      background: rgba(0, 0, 0, 0.05);
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      color: black;
      cursor: pointer;
      border: 1px solid transparent;
    }

    .liveTag {
      padding: 0 8px;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      background: white;
      border: 1px solid var(--site-theme-color);
      color: var(--site-theme-color);
      display: block;
      width: max-content;
    }

    .tutorialTitle, .tutorialHeadBar, .tutorialFooterBar {
      position: relative;
      z-index: 1;
    }

    .tutorialTitle {
      display: inline;
      @include fontHelpCenter;
      font-size: 18px;
      word-break: break-word;
      cursor: pointer;

      :global(.search-match) {
        background: var(--background-color);
      }
    }

    .tutorialDescription {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      word-break: break-word;
      margin-bottom: 10px;
      margin-top: 10px;
      color: black;
      cursor: pointer;

      :global(.search-match) {
        color: var(--marker-color);
        background: var(--background-color);
      }
    }

    .tutorialHashTag {
      display: flex;
      margin-top: 7px;

      .tutorialHashTagItem {
        position: relative;
        display: flex;
        @include fontHelpCenter;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.4);

        &::before {
          content: ' ';
          white-space: pre;
        }

        :global(.search-match) {
          background: var(--background-color);
        }

        &:hover {
          color: black;
        }
      }
    }

    .tutorialViewsCount {
      color: rgba(0, 0, 0, 0.4);
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      display: flex;
      justify-content: flex-end;
      text-align: end;
      flex-grow: 1;
      position: relative;
      z-index: 1;
    }

    .tutorialStepText {
      line-height: 24px;
      padding: 7.7px 0 0 0;
      font-size: 15px;
    }

    &.new {
      .dot {
        display: block;
      }

      .tutorialTitle {
        padding-right: 4px;
      }
    }

    &:hover,
    &.selected {
      background: var(--background-color);

      .tutorialHeadBar {
        color: black;
      }

      .tutorialTitle {
        // color: var(--marker-color);
      }
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;

      .searchResultItemInner {
        pointer-events: none;
      }
    }

    &.tutorialsLimitExceeded {
      .tutorialTitle {
        opacity: 0.4;
      }
    }
  }

  &.shouldShowProgress {
    .searchResultItem {
      &.disabled {
        pointer-events: auto;
        &:hover {
          .tutorialTitle,
          .tutorialDescription {
            pointer-events: none;
            color: inherit;
          }
        }
      }
    }
  }

  .filterTagWrapper {
    display: flex;
    margin: 10px 0;

    .filterTag {
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.05);
      padding: 0 8px;

      label {
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: black;
        margin: 0;
      }

      span {
        margin-left: 10px;
        display: flex;
      }
    }
  }

  &.hasMask {
    opacity: 0.2;
    pointer-events: none;
  }
}

.plain {
  .searchResultList {
    row-gap: 5px;

    &.shouldShowProgress {
      .searchResultItem {
        background: none;
        &.disabled {
          opacity: 1;
          pointer-events: auto;
        }

        :global(.iorad-progress-rect-bar) {
          background: #fff;
        }

        &:hover {
          :global(.iorad-progress-rect-bar) {
            // background: var(--marker-color);
            // opacity: 0.1;
          }
        }
      }

      :global(.iorad-progress-rect-inner) {
        // background: white;
      }
    }
  }
  .categoryList {
    row-gap: 5px;

    &.shouldShowProgress {
      .categoryListItem {
        .categoryNameBar, .subcategoryNameBar {
          &.disabled {
            .categoryNameText {
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
      }

      :global(.iorad-progress-rect-inner) {
        // background: white;
      }
    }
  }

  .tagList {
    row-gap: 5px;

    &.shouldShowProgress {
      .tagNameBar {
        &.disabled {
          opacity: 1;
          pointer-events: auto;
        }
      }

      :global(.iorad-progress-rect-inner) {
        // background: white;
      }
    }
  }

  :global(.iorad-progress-rect-text) {
    :global(.iorad-progress-rect-disabled) {
      display: none;
    }

    &:global(.iorad-progress-rect-text-disabled) {
      &:not(:global(.iorad-progress-rect-text-completed)):not(:global(.iorad-progress-rect-text-hide)) {
        :global(.iorad-progress-rect-percent-text) {
          display: initial;
        }
      }
    }

    // :global(.iorad-progress-rect-percent-text) {
    //   display: initial;
    // }

    :global(.iorad-progress-rect-percent-dot) {
      // display: none !important;
      opacity: 1 !important;
      margin-right: 5px;
    }
  }
}

.hideCompleted {
  &:not(.plain) {
    :global(.iorad-progress-completed) {
      display: none;
    }
  }
}

.showHideCompletedButton {
  font-family: Libre Franklin;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    color: var(--marker-color);
  }

  & + .shouldShowProgress {}
}

.categoryList {
  display: flex;
  flex-direction: column;

  &.shouldShowProgress {
    :global(.iorad-progress-rect-bar) {
      background: none;
    }

    .categoryListItem {
      background: none;

      &.expanded {
        .categoryNameBar {
          :global(.iorad-progress-rect-bar) {
            // background: rgba(0, 0, 0, 0.05);
            // background: #fff;
            // opacity: 1;
          }
        }
        .categoryNameText {
          &.selected:not(:hover) {
            color: #000;
          }
        }
      }

      .categoryNameBar {
        &.disabled {
          .categoryNameText {
            opacity: 0.4;
            pointer-events: none;
          }

          :global(.iorad-progress-rect-percent-dot) {
            opacity: 0.3;
          }
        }
      }

      .subcategoryNameBar {
        &.disabled {
          .subcategoryNameText {
            opacity: 0.4;
            pointer-events: none;
          }

          :global(.iorad-progress-rect-percent-dot) {
            opacity: 0.3;
          }
        }
      }

      .categoryNameBar, .subcategoryNameBar {
        &:hover {
          :global(.iorad-progress-rect .iorad-progress-rect-bar) {
            // background: var(--marker-color);
            // opacity: 0.1;
          }

          :global(.iorad-progress-rect-text) {
            color: var(--marker-color);
          }

          :global(.iorad-progress-rect-checked) {
            path {
              stroke: var(--marker-color);
            }
          }
        }
      }

      .subcategoryNameBar {
        // padding-left: 35px;
      }

      &.allUnsubcategorized {
        .tutorialRow {
          .tutorialNameBar {
            // padding-left: 35px;
          }
        }
      }
    }

    .subcategoryListItem {
      &.subCategoryExpanded {
        .subcategoryNameBar {
          :global(.iorad-progress-rect-bar) {
            // background: rgba(0, 0, 0, 0.05);
            // background: #fff;
            // opacity: 1;
          }
        }
        .subcategoryNameText {
          &.selected:not(:hover) {
            color: #000;
          }
        }
      }
    }

    .categoryListItem {
      &:not(.allUnsubcategorized) {
        .subcategoryListItem {
          .tutorialRow {
            &:first-child {
              // margin-top: 5px;
            }
          }
        }
      }
    }

    .tutorialRow {
      // margin-top: 5px;
      margin-left: 0;

      &:first-child {
        margin-top: 0;
      }

      &.disabled {
        &:hover {
          color: inherit;
        }

        .tutorialNameBar {
          opacity: 0.4;

          &:hover {
            color: inherit;
          }

          .tutorialNameText {
            pointer-events: none;
          }
        }
      }

      .tutorialNameBar {
        min-height: 48px;
        // background: #FFF;
        // padding-left: 50px;
      }
    }

    .categoryNameBar {
      &.disabled {
        pointer-events: auto;
      }
    }
  }

  .categoryListItem {
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.allUnsubcategorized {
      .subcategoryNameBar {
        display: none;
      }
    }

    &.hasMask {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .subcategoryList {

    .tutorialRow {
      padding-left: 32px;
    }
  }

  .subcategoryListItem {
    position: relative;
    margin-top: 0;

    &.subCategoryExpanded {
      border-bottom: none;
    }
  }

  .categoryNameBar {
    padding: 16px;
    // padding-right: 0;
    position: sticky;
    top: 0;
    z-index: 3;
    background: white;
    font-size: 18px;
    line-height: 24px;
    border-bottom: none;

    &.disabled {
      .categoryNameText {
        opacity: 0.2;
      }

    }

    svg {
      transition: 1s path;
    }

    &:hover {
      .categoryNameText {
        // color: var(--marker-color);
      }
    }
  }

  .subcategoryNameBar {
    position: relative;

    .tutorialsCount {
      font-size: 15px;
      line-height: 136%;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .categoryNameBar, .subcategoryNameBar {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    :global(.iorad-progress-rect-bar) {
      // background: rgba(0, 0, 0, 0.05);
    }

    .buttonAllFolders {
      width: 40px;
      min-width: 40px;
      height: 42px;
      display: flex;
      display: none;
      align-items: center;
      justify-content: center;

      .buttonFolder {
        path {
          stroke: rgba(0, 0, 0, 0.2);
        }
      }
    }

    &:hover {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--background-color);
        z-index: -1;
      }
    }
  }

  .subcategoryNameBar {
    padding: 16px;
    padding-left: 32px;
    position: sticky;
    z-index: 2;
    background: white;
    top: 56px;

    &:hover {
      .subcategoryNameText {
        // color: var(--marker-color);
      }
    }
  }

  .categoryNameText, .subcategoryNameText {
    display: flex;
    align-items: center;
    color: #000;
    word-break: break-word;
    flex-grow: 1;
    z-index: 1;

    &.selected, &:hover {
      // color: var(--marker-color);
    }
  }

  .categoryNameText {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .subcategoryNameText {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .tutorialsCount {
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.4);
  }
}

.tagList {

  display: flex;
  flex-direction: column;

  .tutorialRow {
    padding-left: 32px;
  }

  &.shouldShowProgress {
    // margin-top: 15px;

    .tutorialRow {
      margin-bottom: 0;
      // padding-left: 0;
      margin-left: 0;

      .tutorialNameBar {
        min-height: 48px;
        // background: #FFF;
      }

      &.disabled {
        &:hover {
          color: inherit;
        }

        .tutorialNameBar {
          opacity: 0.4;

          &:hover {
            color: inherit;
          }

          .tutorialNameText {
            pointer-events: none;
          }
        }
      }
    }

    .tagListItem {
      background: none;

      .tagNameBar {
        :global(.iorad-progress-rect-bar) {
          background: none;
        }
      }

      &.expanded {
        .tagNameBar {
          &:not(:hover) :global(.iorad-progress-rect-bar) {
            // background: rgba(0, 0, 0, 0.05);
            // background: #fff;
            // opacity: 1;
          }
        }
        .tagNameText {
          &.selected:not(:hover) {
            color: #000;
          }
        }
      }
    }

    .tagNameBar {
      &.disabled {
        pointer-events: auto;
      }
    }


  }

  .tagListItem {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.hasMask {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  .tagNameBar {
    cursor: pointer;
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 3;
    background: white;
    padding: 16px;
    border-bottom: none;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    .buttonAllFolders {
      width: 40px;
      min-width: 40px;
      height: 42px;
      display: flex;
      display: none;
      align-items: center;
      justify-content: center;

      .buttonFolder {
        path {
          stroke: rgba(0, 0, 0, 0.2);
        }
      }
    }

    &:hover {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--background-color);
        z-index: -1;
      }

      :global(.iorad-progress-rect .iorad-progress-rect-bar) {
        // background: var(--marker-color);
        // opacity: 0.1;
      }

      :global(.iorad-progress-rect-text) {
        color: var(--marker-color);
      }

      :global(.iorad-progress-rect-checked) {
        path {
          stroke: var(--marker-color);
        }
      }

      .tagNameText {
        // color: var(--marker-color);
      }
    }
  }

  .tutorialsCount {
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.4);
  }

  .tagNameText {
    display: flex;
    align-items: center;
    color: #000;
    word-break: break-word;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    z-index: 1;

    &.selected, &:hover {
      // color: var(--marker-color);
    }
  }
}

.allTutorials {
  .tutorialRow {
    margin-left: 0;
    width: auto;
    position: relative;

    &:first-child {
      margin-top: 1px; // for the top border
    }
  }
}

.tutorialRowWrapper {
  position: relative;
}

.tutorialRow {
  cursor: pointer;

  .tutorialNameBar {
    position: relative;
    font-size: 18px;
    line-height: 24px;
    word-break: break-word;
    display: flex;
    align-items: center;
    padding: 16px 0;
    padding-right: 16px;

    :global(.iorad-progress-rect-bar) {
      // background: rgba(0, 0, 0, 0.05);
      background: none;
    }

    .tutorialNameText {
      z-index: 1;
      flex-grow: 1;
    }

    .tutorialProgress {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 0;
    }

    .dot {
      content: '';
      position: absolute;
      right: 10px;
      top: 15px;
      display: block;
      width: 5px;
      height: 5px;
      background-color: var(--marker-color);
      border-radius: 50%;
      display: none;
    }

    &:hover {
      // color: var(--marker-color);
    }

    .tutorialDescTooltip {
      position: fixed;
      display: block;
      visibility: hidden;
      color: black;
      font-size: 16px;
      line-height: 22px;
      z-index: 9999;
      background-color: white;
      padding: 24px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 225px;

      &.show {
        visibility: visible;
      }
    }
  }

  &.tutorialsLimitExceeded {
    .tutorialNameBar {
      opacity: 0.4;
    }
  }

  &.new {
    .tutorialNameBar {
      .dot {
        display: block;
      }
    }
  }

  &.selected, &:hover {
    // color: var(--marker-color);
    background: var(--background-color);

    :global(.iorad-progress-rect .iorad-progress-rect-bar) {
      // background: var(--marker-color);
      // opacity: 0.1;
    }

    :global(.iorad-progress-rect-checked) {
      path {
        stroke: var(--marker-color);
      }
    }
  }
}

.shouldShowProgress {
  .tutorialRow {
    .tutorialNameBar {}
  }
}

.searchField {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  height: 56px;
  padding-left: 16px;
  margin: 0;

  .searchInput {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    caret-color: var(--marker-color);
    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
    &:focus {
      outline: none;
    }
  }
}

.closeIconContainer {
  margin-right: -13px;
}
