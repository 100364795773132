@import "../../../../../containers/Tutorials/Tutorials.scss";

.gainsightContainer {

  .formGroup {

    &.checkboxGroup {
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;
      flex-direction: row;

      & > .label {
        width: 50%;
        margin-bottom: 13px;
        color: #000;
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 136%;
        font-weight: 300;
        cursor: pointer;

        &.disabled {
          opacity: 0.4;
        }

        & > .checkbox {
          width: 16px;
          height: 16px;
          display: inline-block;
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.2);
          cursor: inherit;
          outline: none;
          margin: 0 15px 0 0;
          border-radius: 1px;
          appearance: none;

          &:checked {
            background-image: url(../../../../../assets/img/icons/checkmark.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 70% 70%;
          }
        }
      }
    }
  }
}
