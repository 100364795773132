@import '../../theme/modules/variables';

.container {
  position: fixed;
  width: 100%;
  // background: $scheduleDemoBackground;
  overflow-y: auto;
  background: #FFF;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .tabs {
    display: flex;
    align-items: center;
    column-gap: 30px;
    flex-shrink: 0;
    height: 48px;
    width: 100%;
    max-width: 850px;
    margin: 0 40px;

    span {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      cursor: pointer;

      &:hover,
      &.active {
        color: var(--site-theme-color);
      }
    }
  }

  iframe {
    //max-height: calc(100% - 88px);
    height: 690px !important;
    background: #FFF;
    padding-left: 16px;
    padding-right: 16px;
  }
}
