.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;

  .message {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 35px;
    max-width: 600px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  span {
    color: #BDBDBD;
  }

  .buttons {
    display: flex;
    box-sizing: border-box;

    button {
      margin: 4px;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 5px;
      height: 64px;
      width: 210px;
      font-size: 16px;
      font-weight: lighter;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    height: 100%;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 15px;

    .message {
      display: flex;
      align-self: center;
      flex: 5;
      justify-content: center;
      align-items: center;
    }

    span {
      display: none;
    }

    .buttons {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      flex: 1 1 auto;

      button {
        width: 100%;
        height: 100%;
        margin-left: 0;
        flex: 1 1 auto;
        line-height: 18px;
      }
    }
  }
}
