.tab {
  margin: 0 auto;
  font-weight: 300;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }

  @media screen and (min-width: 769px) {
    width: 100%;
    padding: 0;
  }
}
