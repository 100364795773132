.teamManagement {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  padding-bottom: 24px;
  width: 100%;
  max-width: 960px;
  color: black;

  .teamManagement_title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .teamManagement_tabs {
    display: flex;

    .teamManagement_tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 1;
      height: 56px;
      font-size: 15px;
      line-height: 20px;
      border: 1px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;

      &.active {
        border-color: rgba(0, 0, 0, 0.2);
        border-bottom-color: transparent;
        cursor: default;
      }

      &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        * {
          opacity: 0.5;
        }
      }

      label {
        margin: 0;
        font-weight: 400;
      }

      span {
        margin-left: 5px;
        color: var(--site-theme-color);
      }
      

      &:not(.active):hover {
        background: #{'rgba(var(--site-theme-color-rgb), 0.03)'};
      }
    }
  }

  .teamManagement_content {
    display: flex;
    flex-direction: column;

    .teamManagement_content_desc {
      font-size: 15px;
      line-height: 20px;
      padding: 16px;
      background: #f4f4f4;
      margin: 24px 0;
    }

    .teamManagement_content_list {
      display: flex;
      flex-direction: column;
      padding: 0 24px;

      .account_settings_item {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        height: 40px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        margin: 0;
        padding-left: 32px;

        &:not(.checked) {
          opacity: 0.5;
        }

        input[type='checkbox']:checked ~ span::after {
          background-image: url('~assets/img/icons/checkmark.svg');
          background-size: 76%;
          border: none;
        }

        input[type='checkbox'] {
          display: none;
        }

        span:after {
          position: absolute;
          cursor: pointer;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          content: '';
          background-color: white;
          background-repeat: no-repeat;
          background-position: center;
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
      }

      .tutorial_settings_item {
        display: flex;
        align-items: center;
        column-gap: 16px;
        flex-shrink: 0;
        height: 40px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        cursor: pointer;
        
        &.locked {
          opacity: 0.5;
        }

        .tutorial_settings_item_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 24px;
        }

        .tutorial_settings_item_label {
          flex-grow: 1;
        }
      }

      .teamManagement_content_divider {
        height: 1px;
        background: rgba(0, 0, 0, 0.2);
        margin: 12px 0;
      }
    }
  }
}