@import '../../../theme/modules/variables';
@import '../../../theme/guidelines';

.completed {
  margin-top: 48px;
  padding-left: 25px;

  @include breakpoint-sm {
    margin-top: 32px;
    padding: 0 10px;
  }

  .ioradLogo {
    width: 16px;
    height: 16px;
  }

  :global(.form-control) {
    margin-right: 0;
    margin-left: 0;
  }

  .confirmation {
    margin: 82px auto 0;
    display: flex;
    flex-direction: column;
    max-width: 512px;
    width: 100%;

    .title {
      font-weight: 600;
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 40px;
      color: black;
    }

    .desc {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: black;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 40px;
    }

    .reinstall_btn {
      margin-top: 30px;
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 40px;
      cursor: pointer;

      &:hover {
        svg path {
          fill: var(--site-theme-color);
        }
        span {
          color: var(--site-theme-color);
        }
      }

      span {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: black;
        margin-left: 25px;
      }
    }
  }
}
