@import '../../theme/modules/variables';
@import '../../theme/guidelines';
@import '../../theme/mixins';

:global(.popup-content) {
  .creators {
    padding: 0;
  }
}

.loading {
  z-index: 100;
  position: fixed;
  background-color: rgba($color: #ffffff, $alpha: 0.4);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.creators {
  width: 100%;
  padding: 0px 40px;
 
  @include breakpoint-sm {
    padding: 0px 24px;
  }

  :global(.tab-content) {
    width: 100%;
    margin: 0 auto;
  }

  :global(.nav-tabs) {
    display: flex;
    border: none;
    padding-top: 27px;
    padding-bottom: 32px;
    position: sticky;
    top: 0;
    z-index: 3;
    background: white;

    :global(li) {
      display: inline-block;
      text-align: left;
      margin-right: 30px;
      border: none;

      &:last-child {
        margin-left: 0;
      }

      :global(a) {
        cursor: pointer;
        padding: 0;
        font-size: 15px;
        font-weight: 600;
        line-height: 24px;
        border: none !important;
        font-family: $fontBaseV2;
        background-color: #ffffff;
        color: #000000;
        height: 40px;
        display: flex;
        align-items: center;

        &:focus {
          outline: none;
          background: none;
          border: none;
        }

        &:hover {
          color: var(--site-theme-color);
        }
      }

      &:global(.active) {
        a {
          color: var(--site-theme-color);
          cursor: default;
        }
      }

      &:focus,
      &:hover {
        outline: none;
        border: none;
        border-radius: 0;
      }
    }
  }

  .tooltip {
    display: flex;
    align-items: center;
    height: 100%;

    span {
      font: inherit;
    }

    &:hover {
      .tooltip_content {
        opacity: 1;
        transition-delay: 1s;
      }
    }

    .tooltip_content {
      opacity: 0;
      position: absolute;
      z-index: 2;
      top: 100%;
      left: 100%;
      transform: translate(-5px, -5px);
      background: #f4f4f4;
      padding: 5px 10px;
      width: 210px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: black;
      margin: 0;

      a {
        font: inherit !important;
        color: black !important;
        cursor: pointer !important;
        display: inline;
        background: transparent;
        text-decoration: underline;

        &:hover {
          color: var(--site-theme-color) !important;
        }
      }
    }
  }
}
