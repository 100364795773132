@import '~theme/modules/variables';
@import '~theme/mixins';
@import '../includes';
@import '../leftBarShares';

.container {
  @include listBlock();

  &:global(.height-aimation-virtual-el > *) {
    visibility: hidden !important;
    opacity: 0 !important;
  }

  &.autoHeight {
    height: auto !important;
  }

  &.categoryChange {
    .categoryItem {
      &:hover {
        background-color: var(--site-theme-color)0c;
        color: var(--site-theme-color);
        &:not(.activeCategoryItem) .categoryItemText {
          text-decoration: none;
        }
      }
    }
    .activeCategoryItem {
      .categoryItemText {
        color: rgba(0, 0, 0, .4);
      }
    }
  }

  .header {
    &:hover {
      .categoryIcon {
        path {
          fill: var(--site-theme-color);
        }
      }

      .headerText {
        color: var(--site-theme-color);
      }

      .categoryIcon {
        path {
          stroke: var(--site-theme-color);
        }
      }
      .addIcon {
        path {
          stroke: var(--site-theme-color);
        }
      }

      .editIcon {
        path {
          stroke: var(--site-theme-color);
        }

        path:first-child {
          stroke: none;
          fill: var(--site-theme-color);
        }
      }
    }
  }
}

.animationContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.scrollContainer {
  margin: 11px 0 13px 2px;
  padding-right: 11px;
  overflow: hidden;

  @-moz-document url-prefix() {
    padding-right: 15px;
  }

  &:hover {
    overflow-y: scroll;
    padding-right: 0;
  }
}

.header {
  position: sticky !important;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  height: auto;
  padding-right: 12px;
  padding-left: 4px;

  &.multiRow {
    display: block;
  }
}

.headerIcon {
  visibility: hidden;
  pointer-events: none;
  margin-right: 16px;
  cursor: pointer;
  opacity: 0.4;
  width: 16px;

  &:hover {
    opacity: 1;
    path {
      stroke: var(--site-theme-color);
    }
  }

  &.solidIcon {
    opacity: 1;
  }

  &.categoryIcon {
    padding-bottom: 1px;
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }
}

.solidHeaderIcon {
  @extend .headerIcon;
  opacity: 0.4;
  visibility: visible;
  pointer-events: all;

  &:hover, &.categoryIcon {
    opacity: 1;
  }
}

.headerText {
  font-size: 15px;
  margin-right: 10px;
  font-family: $fontBaseV2;
  font-weight: bold;
  flex-grow: 1;
  color: #000000;
  cursor: pointer;
  font-weight: bold;
}

.blur {
  opacity: 0.2;
}

.filterBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.closeIcon {
  margin-left: 15px;

  svg {
    width: 12px;
    height: 12px;
    fill: #acacac;
  }
}

.categoryList {
  display: block;
  svg {
    color: inherit;

    [fill] {
      fill: currentColor;
      color: inherit;
    }

    [stroke] {
      stroke: currentColor;
      color: inherit;
    }
  }
}

.categoryItem {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 9px 6px 2px;

  &.activeCategoryItem .categoryItemText {
    color: var(--site-theme-color);
  }
  &.highlightCategoryItem {
    .categoryItemText {
      color: var(--site-theme-color);
    }
  }
  &:hover .categoryItemText {
    color: var(--site-theme-color);
  }

  &.isRename {
    @include ontopOfOverlay();
  }

  &.isDisabled {
    opacity: 0.2;
    pointer-events: none;
  }
}

.categoryItemText {
  font-size: 15px;
  font-family: $fontBaseV2;
  line-height: 136%;
  color: black;
}

.subCategories {
  padding-left: 20px;
  height: 0;
  overflow: hidden;

  &:empty {
    margin-top: 0;
    padding-left: 0;
  }

  &.expanded {
    height: auto;
  }
}

.highlight {
  opacity: 1;

  svg {
    path[stroke] {
      stroke: var(--site-theme-color);
    }
    path[fill] {
      fill: var(--site-theme-color);
    }
  }
}

.hightlightText {
  color: var(--site-theme-color);
}

.searchbox {
  width: 193px;
  margin-left: 2px;
  margin-top: -3px;

  input {
    height: 34px;
    padding-left: 35px !important;
    padding-right: 2px;
  }

  img {
    left: 0 !important;
  }
}

.categoryInput {
  margin-top: 8px;
  margin-bottom: 7px;
}

.showOverlay {
  .overlayItem {
    z-index: 22;
    position: relative;
  }

  &.overlayingOnTutorial, &.overlayingOnTag {
    .overlayItem {
      z-index: 19;
    }
  }

  &.overlayingOnTutorial, &.overlayingOnTag, &.overlayingOnSearch {
    &:not(.categoryCreation) {
      .header {
        z-index: 19 !important;
      }
    }
  }
}

.container:not(.isMinimize) {
  .header {
    z-index: 23;
  }
}

.categoryCreation {
  .searchRow {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0; top: 0; right: 0; bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      z-index: 1;
    }
  }

  // .scrollContainer {
  //   margin-top: 18px;
  // }
}

.categoryEdit {
  @include ontopOfOverlay();
}

.categoryChange:not(.categoryCreation) {
  @include ontopOfOverlay();
}

.blinkText {
  color: var(--site-theme-color);
  font-size: 10px;
  font-family: $fontBaseV2;
  text-align: right;
}

.count {
  color: #999999;
  font-size: 10px;
  font-family: $fontBaseV2;
  text-align: right;
}

.addBtn, .expandBtn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0 none;
  padding: 0;
  gap: 18px;
  color: rgba($color: #000000, $alpha: 0.4);

  &:hover {
    color: var(--site-theme-color);
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}

.expandBtn {
  color: black;
  font-weight: 600;
  font-size: 15px;
  padding: 6px 0 6px 4px;
  margin-top: 11px;
}