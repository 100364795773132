@import '~theme/modules/variables';
@import '~theme/mixins';

.container {
  position: relative;
  width: 100%;
}

.selectedOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
  font-size: 15px;
  line-height: 136%;
  font-family: $fontBaseV2;
  font-weight: 300;
  padding: 4px 5px 2px 2px;
  cursor: pointer;
  height: 36px;
}

.label {
  font-size: 15px;
  font-family: $fontBaseV2;
  font-weight: 300;
}

.toggleIcon {
  margin-right: 8px;
  margin-top: 3px;
  
  &.flipped {
    transform: scale(-1);
  }
}

.optionList {
  margin-top: -1px;
  position: absolute;
  left: 0;
  top: 100%;
  padding: 0;
  min-width: 192px;
  margin-left: -22px;
  border: 1px solid #b8b8b8;
  background: white;
  z-index: 10;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
  padding-top: 8px;
  padding-bottom: 12px;

  @include breakpoint-sm {
    min-width: 192px;
    margin-left: -12px;
  }
}

.optionWrapper {
  padding: 5px 23px;
  cursor: pointer;
  margin-bottom: 1px;

  label {
    pointer-events: none;
  }

  &:hover {
    text-decoration: underline;
  }

  @include breakpoint-sm {
    padding: 10px 12px;
  }
}

.optionsWrapper {
  padding: 0 23px;

  .labelTitle {
    display: block;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .radio {
    display: flex;
    align-items: center;
    padding: 5px;

    input {
      margin: 0;
      margin-right: 10px;

      &:checked + .radioText {
        color: var(--site-theme-color);
        font-weight: 600;
      }
    }
  }

  .radioText {
    font-weight: 400;
  }
}
