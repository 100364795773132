.onboarding__progress {
  height: 4px;
  width: 100%;
  background: white;
  position: relative;

  .onboarding__progress_bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: black;
  }
}

.onboarding__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;

  .onboarding__nav_back,
  .onboarding__nav_next {
    display: flex;
    align-items: center;
    column-gap: 16px;
    color: black;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  .onboarding__nav_continue {
    width: 320px;
    height: 56px;
  }
}

.onboarding__file_input {
  display: flex;
  align-items: center;
  height: 56px;

  .fileWrapper {
    display: none;
  }

  label {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: black;
    margin: 0;
    cursor: pointer;
  }

  .onboarding__file_input_preview {
    height: 44px;
    background: #f4f4f4;
    padding: 10px;
    margin: 0 24px;

    img {
      height: 100%;
      width: auto;
    }
  }
}

.onboarding__slide_input {
  display: flex;
  flex-direction: column;

  .onboarding__slide_input_labels {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;

    span {
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: black;
    }
  }

  input {
    flex-grow: 1;
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #f4f4f4;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 8px;
      height: 24px;
      background: #000;
      cursor: pointer;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    &::-moz-range-thumb {
      width: 8px;
      height: 24px;
      background: #000;
      cursor: pointer;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
}


.fakeFull {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}