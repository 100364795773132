@import '~theme/modules/variables';
@import '~theme/mixins';
@import './general';

.breadcrumb {
  display: flex;
  max-width: $maxWidthContent;
  width: 100%;
  margin: 60px auto 33px;
  padding: 0 64px;

  .breadcrumbButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      font-size: 15px;
      line-height: 20px;
      text-decoration: underline;
      margin-left: 15px;
      color: black;
    }

    &:hover {
      svg path {
        fill: var(--site-theme-color);
      }
      span {
        color: var(--site-theme-color);
      }
    }
  }
}

.integrationsDetail {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  &__header {
    height: 286px;
    background: #f4f4f4;
    display: flex;
    flex-shrink: 0;

    &_content {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 54px 156px 0 64px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &_left {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      &_right {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
        flex-shrink: 0;
        width: 276px;

        button {
          width: 100%;
        }
      }
    }

    &_info {
      display: flex;
      align-items: center;

      &_icon {
        width: 64px;
        height: 64px;
        flex-shrink: 0;
        margin-right: 33px;
        @include integrationIcon;
      }

      h6 {
        margin: 0;
        font-size: 21px;
        font-weight: 600;
        line-height: 24px;
        color: black;
      }
    }

    &_row {
      display: flex;
      column-gap: 30px;
      padding-left: 97px;
      padding-right: 168px;
    }

    &_moreInfo {
      display: flex;
      flex-direction: column;

      label {
        margin: 0;
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: black;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
          font-weight: 600;
        }

        a {
          text-decoration: underline;
          color: inherit;

          &:hover {
            color: var(--site-theme-color);
          }
        }
      }
    }

    &_stat {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: black;

      .number {
        margin-left: 5px;
        cursor: pointer;

        &:hover {
          font-weight: 600;
        }
      }

      .tutorials_published_tooltip {
        &:global(.tooltip.top) {
          :global(.tooltip-arrow) {
            display: none;
          }
        
          :global(.tooltip-inner) {
            border-radius: 0;
            padding: 5px 10px;
            font-size: 15px;
            line-height: 20px;
            font-weight: 400;
            color: black;
            background: #f4f4f4;
            text-align: left;
          }
        }
      }
    }

    &_reviews {
      display: flex;
      flex-direction: column;
    }

    &_rating {
      display: flex;
      align-items: center;

      > div {
        display: flex;
        margin-right: 15px;
        align-items: center;
        height: 22.5px;
      }

      a {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: black;
        cursor: pointer;

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }
  }

  &__content {
    max-width: $maxWidthContent;
    width: 100%;
    margin: 0 auto;
    margin-top: 37px;
    padding: 0 64px;

    &_connectForm {
      display: flex;
      flex-direction: column;
      padding-top: 60px;
    }

    &_tutorials {
      display: flex;
      flex-direction: column;
      row-gap: 40px;

      &_item {
        width: 784px;
        height: 440px;

        iframe {
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
        }
      }
    }
  }
}

.reviewSection {
  margin-top: 67px;
  display: none;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin-bottom: 36px;
    padding: 0 64px;
  }

  .sliderWrapper {
    position: relative;
    padding: 0 64px;

    :global(.slick-prev),
    :global(.slick-next) {
      top: 0;
      transform: none;
      width: 64px;
      height: 100%;
      padding-top: 65px;

      &::before {
        content: none;
      }
    }

    :global(.slick-prev) {
      text-align: right;
      padding-right: 10px;
      left: 0;
    }

    :global(.slick-next) {
      text-align: left;
      padding-left: 10px;
      right: 0;
    }

    :global(.slick-list) {
      max-height: 296px;
    }

    :global(.slick-slide) {
      margin-right: 94px;
    }
  }

  .card {
    height: 296px;
    position: relative;
  }

  .headerCard {
    display: flex;

    img {
      width: 41px;
      height: 41px;
      flex-shrink: 0;
      filter: grayscale(1);
      object-fit: cover;
      object-position: top;
      margin-top: 4px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: 253px;
    margin-left: 32px;
  }

  .position {
    font-size: 15px;
    line-height: 136%;
    margin-bottom: 0;
    font-weight: normal;
  }

  .name {
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    margin: 0;
  }

  .contentCard {
    width: 350px;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    position: absolute;
    left: 0;
    top: 108px;
  }
}

.footerWrapper {
  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 150px 158px 160px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .leaveReviewBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

@include breakpoint-sm {
  @include breakpoint-sm {
    .breadcrumb {
      max-width: 100%;
      margin: 24px 0 45px;
      padding: 0 24px;
    }

    .integrationsDetail {
      padding: 0 24px;
      margin-bottom: 30px;

      &__header {
        height: 450px;

        &_content {
          flex-direction: column;
          max-width: 100%;
          padding: 30px 28px 29px;

          &_right {
            align-items: center;
            width: 100%;

            button {
              width: 100%;
              max-width: 230px;
            }
          }
        }

        &_info {
          align-items: flex-end;
          &_icon {
            width: 32px;
            height: 32px;
            margin-right: 16px;

            img {
              width: 100%;
            }
          }

          h6 {
            font-size: 15px;
            line-height: 20px;
            margin-bottom: -4px;
          }
        }

        &_row {
          flex-direction: column;
          flex-grow: 1;
          padding: 0;
          margin-top: 42px;
        }

        &_moreInfo {
          display: flex;
          flex-direction: column;

          label {
            margin: 0;
            margin-bottom: 5px;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            color: black;

            span {
              font-weight: 600;
            }

            a {
              text-decoration: underline;
              color: inherit;

              &:hover {
                color: var(--site-theme-color);
              }
            }
          }
        }

        &_reviews {
          margin-top: auto;
          margin-bottom: 39px;
        }

        &_rating {
          display: flex;
          align-items: center;

          > div {
            display: flex;
            margin-right: 15px;
          }

          a {
            cursor: pointer;
          }
        }
      }

      &__content {
        max-width: 100%;
        min-height: auto;
        margin-top: 25px;
        padding: 0;

        &_tutorials {
          row-gap: 25px;

          &_item {
            width: 100%;
            height: 584px;
          }
        }
      }
    }

    .footerWrapper {
      .footer {
        padding: 164px 24px 236px;
        flex-direction: column;
        align-items: flex-start;
      }

      .footerTitle {
        font-size: 21px;
        margin-bottom: 72px;
      }

      .leaveReviewBtn {
        width: 295px;
        font-size: 13px;
        margin: 0 auto;
      }
    }
  }

  .reviewSection {
    .contentWrapper {
      padding-top: 65px;
      padding-bottom: 36px;
    }

    .title {
      font-size: 21px;
      line-height: 116%;
      padding: 0 24px;
      margin-top: 46px;
      margin-bottom: 37px;
    }

    .sliderWrapper {
      position: relative;
      padding: 0 24px;

      :global(.slick-prev),
      :global(.slick-next) {
        width: 30px;
        height: 30px;
        top: unset;
        bottom: -76px;
        padding-top: 0;
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: transparent;
      }

      :global(.slick-prev) {
        padding-right: 0;
        left: 15px;
      }

      :global(.slick-next) {
        padding-left: 0;
        right: 15px;
      }

      :global(.slick-list) {
        margin-right: -24px;
        max-height: 100%;
      }

      :global(.slick-slide) {
        margin-right: 24px;
      }
    }

    .cardWrapper {
      .card + .card {
        margin-top: 37px;
      }
    }

    .card {
      height: auto;
    }

    .headerCard {
      img {
        width: 56px;
        height: 56px;
        margin-top: 0;
      }
    }

    .info {
      width: 255px;
      margin-left: 16px;
      justify-content: space-between;
    }

    .position {
      font-size: 13px;
      margin-top: -3px;
    }

    .name {
      font-size: 13px;
      line-height: 13px;
    }

    .contentCard {
      width: 100%;
      font-size: 13px;
      margin-top: 16px;
      position: unset;
    }
  }
}
