@import '~theme/modules/variables';
@import '~theme/mixins';

.intro {
  display: flex;
  height: 100vh;
  min-height: 600px;

  .intro__block {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .intro__container {
    padding: 24px;
  }

  .intro__logo {
    margin-bottom: 44px;
  }

  .intro__content {
    padding-top: 40px;
    padding-bottom: 24px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    h3 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 40px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: black;
      margin: 0;
      margin-bottom: 64px;
    }
  }

  .intro__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;

    button {
      height: 56px;
      width: 320px;
      display: flex;
      align-items: center;
      column-gap: 16px;

      svg path {
        &[fill] {
          fill: currentColor;
        }
        &[stroke] {
          stroke: currentColor;
        }
      }
    }
  }

  .intro__iframe {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;

    .intro__iframe_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 56px;
      column-gap: 7px;

      img {
        width: 100%;
      }

      label {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        color: black;
        margin: 0;
        margin-top: 24px;
      }
    }
  }
}
