@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 128px;
  background: #f4f4f4;
  padding: 71px 0 74px;

  &.isLogged {
    margin-top: 172px;
  }

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleContent {
    @include ioradFont;
    padding-bottom: 101px;
  }

  .applyBtn {
    width: 276px;
    height: 48px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    transition: color 0.3s ease;

    &:hover {
      color: var(--site-theme-color);
    }
  }
}

.supportSection {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
  background-position: bottom center;
  background-repeat: no-repeat;

  .supportContent {
    padding: 81px 64px 148px;
    max-width: $maxWidthContent;
    margin: 0 auto;
  }

  .titleContent {
    font-weight: 600;
    font-size: 32px;
    line-height: 116%;
    color: #000000;
    margin: 0;
    margin-bottom: 26px;
  }

  .descWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 19px;
    max-width: 616px;

    p {
      font-size: 15px;
      line-height: 136%;
      color: #000000;
      margin: 0;
    }

    b {
      font-weight: 600;
    }
  }
}

.benefitsSection {
  padding-top: 49px;

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 41px;
  }

  .benefitList {
    max-width: $maxWidthContent;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 64px;
  }

  .benefitItem {
    display: flex;
  }

  .benefitImg {
    background: #77c082;

    img {
      width: 635px;
      height: 334px;
    }
  }

  .benefitContent {
    display: flex;
    flex-direction: column;
    margin-top: 39px;
    margin-left: 58px;
    max-width: 518px;
  }

  .benefitTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    margin-bottom: 22px;
  }

  .benefitDesc {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;

    b {
      font-weight: 600;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.theSquareSection {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_small.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  padding-top: 400px;
  padding-bottom: 295px;

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleWrapper {
    position: relative;
    display: flex;
    width: fit-content;

    h3 {
      font-family: 'iorad';
      font-size: 204px;
      line-height: 67%;
      color: black;
      margin: 0;
      min-width: 878px;
    }
  }

  .fillIcon {
    position: absolute;
    right: 126px;
    top: -193px;
  }

  .openQuestion {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    position: relative;
    top: -86px;
    margin-right: 62px;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      padding: 2px 8px;
      color: black;
      background: #ffe3e3;
      margin-bottom: 4px;
      align-self: flex-start;
    }
  }

  .pendingQuestion {
    display: flex;
    flex-direction: column;
    position: relative;
    top: -112px;
    margin-left: 24px;

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      padding: 2px 8px;
      color: black;
      background: rgba(246, 192, 0, 0.4);
      margin-bottom: 8px;
      align-self: flex-start;
    }
  }

  .descWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 78px;
  }

  .descContent {
    width: 100%;
    max-width: 618px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: black;

    p {
      margin-bottom: 24px;
    }
  }

  .thesquareBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-right: 94px;
    margin-top: 7px;

    &:hover {
      color: #fff;
      background-color: var(--site-theme-color);
    }
  }
}

.applicationSection {
  background: #f4f4f4;

  .titleWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 0 64px;
  }

  .titleContent {
    @include ioradFont;
    padding-top: 59px;
    padding-bottom: 90px;

    @media screen and (max-width: 1044px) {
      font-size: 19vw;
    }
  }

  .headerDesc {
    font-weight: 600;
    font-size: 21px;
    line-height: 116%;
    color: #000000;
    margin: 0;
    margin-bottom: 14px;
  }

  .descWrapper {
    font-size: 15px;
    line-height: 136%;
    color: #000000;
    margin: 0;
    max-width: 618px;
    padding-left: 16px;
    padding-bottom: 51px;
    list-style-type: decimal;

    li + li {
      margin-top: 17px;
    }

    b {
      font-weight: 600;
    }
  }
}

.formWrapper {
  max-width: 100%;
  margin: 0 auto;
  padding: 64px;
  background: #f4f8fa;

  .formContent {
    width: 100%;
    height: 814px;
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 36px;
    padding: 70px 0 13px;

    &.isLogged {
      margin-top: 46px;
    }

    .titleWrapper {
      padding: 0;

      img {
        padding-bottom: 63px;
        width: 100%;
      }
    }

    .applyBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }

  .supportSection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_small.png');

    .supportContent {
      padding: 118px 24px 93px;
    }

    .titleContent {
      font-size: 21px;
      line-height: 136%;
      margin-bottom: 18px;
    }

    .descWrapper {
      max-width: 100%;

      p {
        font-size: 13px;
      }
    }
  }

  .benefitsSection {
    padding-top: 29px;
    padding-bottom: 103px;

    .titleWrapper {
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      line-height: 116%;
      margin-bottom: 28px;
    }

    .benefitList {
      padding: 0 24px;
      row-gap: 43px;
    }

    .benefitItem {
      flex-direction: column;
    }

    .benefitImg {
      img {
        width: 100%;
        height: 163px;
        object-fit: cover;
      }
    }

    .benefitContent {
      max-width: 100%;
      margin-top: 24px;
      margin-left: 0;
    }

    .benefitTitle {
      font-size: 21px;
      line-height: 116%;
    }

    .benefitDesc {
      font-size: 13px;
    }
  }

  .theSquareSection {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_small.png');
    padding: 109px 0 126px;

    .contentWrapper {
      padding: 0;
    }

    .titleWrapper {
      img {
        max-width: 100%;
      }
    }

    .fillIcon {
      width: 36px;
      height: 34px;
      top: 80px;
      right: 54px;
    }

    .openQuestion {
      position: absolute;
      top: -112px;
      left: 24px;
      margin-right: 0;

      span {
        padding: 0 5px;
        font-size: 13px;
      }

      svg {
        width: 89px;
        height: 85px;
      }
    }

    .pendingQuestion {
      position: absolute;
      right: 24px;
      top: -81px;
      margin-left: 0;

      span {
        padding: 0 5px;
        font-size: 13px;
      }

      svg {
        width: 53px;
        height: 50px;
      }
    }

    .descWrapper {
      padding: 0 24px;
      margin-top: 57px;
      flex-direction: column;
    }

    .descContent {
    }

    .thesquareBtn {
      width: 295px;
      font-size: 13px;
      margin: 72px auto 0;
    }
  }

  .applicationSection {
    .titleWrapper {
      padding: 0 24px;

      img {
        padding-top: 66px;
        padding-bottom: 36px;
        margin-left: -4px;
      }
    }

    .headerDesc {
      font-size: 13px;
      line-height: 136%;
      margin-bottom: 9px;
    }

    .descWrapper {
      font-size: 13px;
      max-width: 100%;
      padding-bottom: 38px;
    }
  }

  .formWrapper {
    padding: 24px;

    .formContent {
      height: 900px;
    }
  }
}
