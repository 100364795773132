@import '~theme/mixins';
@import '~theme/modules/variables';

@mixin btn_contained {
  border: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 136%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  background: black;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover:not(.is_disabled) {
    background: var(--site-theme-color);
  }
}

@mixin btn_outlined {
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 600;
  font-size: 15px;
  line-height: 136%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: black;
  background: white;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover:not(.is_disabled) {
    color: var(--site-theme-color);

    svg {
      path[stroke] {
        stroke: var(--site-theme-color);
      }
      path[fill] {
        fill: var(--site-theme-color);
      }
    }
  }
}

.qa_question {
  background: #fff;
  flex-direction: column;
  position: relative;
  display: flex;
  max-width: $maxWidthSocialContent;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }

  &.fixed {
    position: fixed;
    z-index: $socialOverlayZIndex + 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0;
    max-width: 100%;
    border: none;

    .qa_question__header {
      cursor: default;
    }

    .qa_question__actions {
      top: 20px;
    }

    .qa_question_menu {
      margin-top: 0;
    }

    .qa_question_content_wrapper {
      padding: 20px 50px 46px;
      background: white;
      cursor: default;
      overflow-y: auto;
    }

    .qa_question_right_content {
      padding-top: 13px;
      padding-bottom: 0;
      flex-basis: 377px;

      &.bottom {
        padding-top: 0;
        margin-left: 0;
        flex-basis: 276px;
      }
    }

    .qa_question__actions {
      visibility: visible;
    }

    @include breakpoint-sm {
      .qa_question__info.hide {
        display: none;
      }
    }
  }

  &__close_banner {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 48px;
    width: 100%;
    background: #f4f4f4;
    position: relative;
    cursor: pointer;

    &:hover {
      &::after {
        content: 'Back';
        background: #f4f4f4;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include fontHelpCenter;
      }
    }

    &_back {
      width: 48px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_title {
      flex-grow: 1;
      text-align: center;

      span {
        @include fontHelpCenter;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
    background: white;
    border-top: 1px solid white;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    cursor: pointer;

    &:hover {
      .qa_question__actions {
        visibility: visible;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 24px 0;
  }

  &__iframe {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &_loading {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
    }

    &_practice {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: auto;
      margin: 0;
      z-index: 99999999;
      background: white;
    }
  }

  &__header_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &__date {
    @include fontHelpCenter;
    margin: 0;
    margin-left: 24px;
    color: rgba(0, 0, 0, 0.4);
    white-space: nowrap;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 24px;
    margin-left: 48px;

    &_title {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      span {
        @include fontHelpCenter;
        word-break: break-word;
      }
    }

    &_tag {
      @include fontHelpCenter;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.4);
      &::before {
        content: ' ';
        white-space: pre;
      }

      &_progress {
        position: absolute;
        z-index: 0;
        background: rgba(0, 0, 0, 0.05);
        top: 0;
        left: 0;
        width: 1px;
        height: 100%;
      }

      &[data-type='verified'] {
        color: #039e00;
      }
      &[data-type='open'] {
        color: #f00;
      }
      &[data-type='pending'] {
        color: #ffb800;
      }
      &[data-type='live'] {
        // color: var(--site-theme-color);
      }
      &[data-type='veryPopular'],
      &[data-type='popular'] {
        color: #d9a9ff;
      }
      &[data-type='boost_time'] {
        color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        height: 40px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        padding: 0 10px;
        margin-top: 24px;

        &::before {
          content: unset;
        }

        label {
          margin: 0;
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          padding: 0 10px;
          background: #f4f4f4;
          font-weight: 400;
          align-items: center;
          @include fontHelpCenter;
        }

        &:hover {
          label {
            display: flex;
          }
        }
      }
    }
  }

  &__actions {
    position: absolute;
    right: 0;
    top: 12px;
    z-index: 1;
    display: flex;
    justify-content: center;
    visibility: hidden;
  }

  &__submitAnswer,
  &__verifyAnswer {
    max-width: 240px;
    margin-right: 20px;
    height: 56px;
    flex-shrink: 0;
    font-size: 15px;
  }

  .adding_answer {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
      display: flex;
      flex-direction: column;
      margin-top: 56px;
      width: 100%;
      max-width: 900px;
      border: 1px solid rgba(0, 0, 0, 0.05);
    }

    &__question {
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      &_user {
        display: flex;
        align-items: center;
        height: 56px;
        flex-shrink: 0;

        p {
          margin: 0;
          margin-left: 24px;
          flex-grow: 1;
          @include fontHelpCenter;
        }
      }

      &_info {
        margin: 0;
      }
    }

    &__answer {
      display: flex;
      flex-direction: column;
      padding: 24px;

      h4 {
        margin: 0;
        @include fontHelpCenter;
        padding: 16px 0;
      }

      &_form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &_url {
        display: flex;
        width: 100%;
        height: 56px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        &:focus-within {
          border-bottom-color: black;
        }

        &_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 100%;
        }

        input {
          margin: 0;
          padding: 0;
          outline: none;
          border: none;
          @include fontHelpCenter;
          width: 100%;
          height: 100%;
        }
      }

      &_or {
        @include fontHelpCenter;
        color: rgba(0, 0, 0, 0.4);
        margin: 24px 0;
      }

      &_captureBtn {
        max-width: 320px;
        @include fontHelpCenter;
        color: white;
      }
    }
  }

  .submit_answer_group {
    display: flex;
    column-gap: 16px;

    button {
      width: 320px;
      height: 56px;
      @include fontHelpCenter;
    }

    &__submitBtn {
      color: white !important;
    }

    &__cancelBtn {
      border: none;
    }
  }

  .qa_question_content_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 1207px;
    width: 100%;
    position: relative;
  }

  .qa_question_content {
    display: flex;
    justify-content: space-between;

    &.iframe {
      flex-grow: 1;
      margin-top: 18px;
      min-height: 616px;

      &.is_adding_other_answer {
        display: none;
      }
    }

    &.add_other_answer {
      margin-top: 18px;
    }

    &.adding_other_answer {
      flex-direction: column;

      .qa_question_right_content {
        margin-left: 0;
        padding-top: 55px;
        max-width: 574px;
      }

      .qa_question__add_answer_url {
        margin-top: 36px;
      }

      .qa_question__add_answer_or {
        height: 50px;
      }

      .qa_question__add_answer_btn_group {
        column-gap: 22px;
        button {
          height: 48px;
        }
      }
    }
  }

  .qa_question_left_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .qa_question_right_content {
    display: flex;
    flex-direction: column;
    flex: 0 0 409px;
    margin-left: 88px;
    padding-top: 13px;

    &.is_profile_layout {
      flex-basis: 321px;
    }
  }

  .qa_question_user_wapper {
    display: flex;
    column-gap: 109px;
  }

  .qa_question_user {
    display: flex;
    align-items: center;
    width: 100%;

    &.answered {
      margin-bottom: 9px;
      flex-grow: 1;
      align-items: flex-end;

      .qa_question_user_content {
        display: flex;
      }

      .qa_question_user_info {
        margin-bottom: 0;
      }
    }
  }

  .qa_question_user_info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    height: 32px;

    .qa_question_time {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      color: rgba(0, 0, 0, 0.4);
    }

    .qa_question_user_name {
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      color: black;

      a {
        color: black;
        text-decoration: underline;

        &:hover {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .qa_question_name_and_desc_wrapper {
    display: flex;
    position: relative;
  }

  .qa_question_name_and_desc {
    display: flex;
    flex-direction: column;
    max-width: 580px;
    margin-top: 7px;
    margin-bottom: 19px;
    min-height: 50px;

    &.is_collapsed {
      max-height: 72px;
      overflow: hidden;
      margin-bottom: 0;
    }

    &.is_default {
      margin-bottom: 0;
    }
  }

  .qa_question_name {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: black;
    word-break: break-word;
  }

  .qa_question_desc {
    margin-top: 7px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    color: black;
    word-break: break-word;
  }

  .qa_question_highlight {
    font-weight: 600;
  }

  .qa_question_collapsed_desc_btn {
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
    color: black;
    cursor: pointer;
    position: absolute;
    right: -16px;
    bottom: 20px;

    &.is_collapsed {
      bottom: 2px;
    }

    &:hover {
      color: var(--site-theme-color);
    }
  }

  .qa_question_follow_group {
    display: flex;
    align-items: flex-end;
    min-height: 32px;
  }

  .qa_question_follow_users {
    display: flex;
    align-items: flex-end;
  }

  .qa_question_follow_user_avatars {
    display: flex;
    column-gap: 5px;
  }

  .qa_question_follow_waiting_label {
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #000000;
    margin-left: 15px;
  }

  .qa_question_follow_btn {
    @include btn_outlined;
    width: 216px;
    height: 40px;
    margin-right: 27px;

    .hover_text {
      display: none;
    }

    &.hoverable:hover {
      .default_text {
        display: none;
      }
      .hover_text {
        display: block;
      }
    }

    &:disabled {
      > * {
        opacity: 0.4;
      }
    }
  }

  .qa_question_tag_list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .qa_question_tag_item {
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
    color: black;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 9px;
    background: rgba(0, 0, 0, 0.05);
    position: relative;

    .progressBar {
      position: absolute;
      z-index: 0;
      background: rgba(0, 71, 255, 0.15);
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
    }

    &:hover {
      .progressBar {
        height: 44px;
      }
    }

    &[data-type='verified'] {
      background: #bde568;
    }
    &[data-type='open'] {
      background: #ffe3e3;
    }
    &[data-type='pending'] {
      background: #fce699;
    }
    &[data-type='boost2x'] {
      background: #bfd1ff;
    }
    &[data-type='veryPopular'],
    &[data-type='popular'] {
      background: #d9a9ff;
    }
    &[data-type='live'] {
      background: white;
      color: var(--site-theme-color);
      border: 1px solid currentColor;
    }
    &[data-type='boost_time'] {
      background: rgba(0, 71, 255, 0.25);
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      position: relative;

      span {
        font-style: italic;
      }

      .qa_question_tag_item_boost_time {
        font-style: normal;
        font-weight: 600;
      }

      label {
        margin: 0;
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0 8px;
        background: rgba(0, 71, 255, 0.25);
        font-weight: 400;
        color: black;
      }

      &:hover {
        label {
          display: block;
        }
      }
    }
  }

  .qa_question_tag_item_spacer {
    height: 24px;
  }

  .qa_question_capture_group {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .qa_question_capture_btn {
    @include btn_contained;
    width: 216px;
    height: 40px;
  }

  .qa_question__add_answer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .qa_question__add_answer_header {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: black;
  }

  .qa_question__add_answer_url {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: relative;
    margin-top: 15px;

    &:focus-within {
      border-color: black;
    }
  }

  .qa_question__add_answer_url_icon {
    width: 20px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
  }

  .qa_question__add_answer_url_input {
    width: 100%;
    padding-left: 37px;
    height: 34px;
    border: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    caret-color: var(--site-theme-color);
    background: transparent;

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .qa_question__add_answer_or {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 136%;
  }

  .qa_question__add_answer_btn_group {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }

  .qa_question__submit_answer_btn_group {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    margin-top: auto;
  }

  .qa_question__add_answer_capture_btn,
  .qa_question__add_answer_submit_btn {
    @include btn_contained;
    flex: 1 1 0px;
    height: 40px;
  }

  .qa_question__add_answer_cancel_btn {
    @include btn_outlined;
    flex: 1 1 0px;
    height: 40px;
  }

  .qa_question__answer_iframe {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &.qa_question__answer_iframe_loading {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
    }

    &.qa_question__answer_iframe_practice {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: auto;
      margin: 0;
      z-index: 99999999;
      background: white;
    }
  }

  .qa_question_answers_navigation {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      outline: none;
      border: none;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;

      &:hover {
        svg path {
          stroke: var(--site-theme-color);
        }
      }

      &:disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.4;
      }
    }

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 136%;
      color: #000000;
    }
  }

  .qa_question_add_other_answer_btn {
    @include btn_outlined;
    width: 276px;
    height: 48px;
    gap: 21px;
    margin-left: auto;
  }

  .qa_question__selected_answer_controls {
    margin-top: 56px;
  }

  .qa_question__selected_answer_controls_choose_as_verified {
    @include btn_outlined;
    height: 48px;
    width: 276px;

    .hover_label,
    .default_label {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px;
    }

    &.is_verified {
      color: var(--site-theme-color);
      background: white;
      border-color: rgba(var(--site-theme-color-rgb), 0.05);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(var(--site-theme-color-rgb), 0.05);
      }

      .default_label svg {
        margin-top: -3px;
      }

      .hover_label {
        display: none;
      }

      &:hover {
        .hover_label {
          display: flex;
        }
        .default_label {
          display: none;
        }
      }
    }
  }

  .qa_question__selected_answer_feedback_none_buttons {
    display: flex;
    flex-direction: row;
    column-gap: 16px;

    button {
      @include btn_outlined;
      width: 130px;
      height: 48px;
      gap: 14px;
      background: rgba(0, 0, 0, 0.05);
      border-color: transparent;

      &.is_enabled {
        color: var(--site-theme-color);
        background: white;
        border-color: rgba(var(--site-theme-color-rgb), 0.05);
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(var(--site-theme-color-rgb), 0.05);
        }

        svg {
          path[stroke] {
            stroke: var(--site-theme-color);
          }
          path[fill] {
            fill: var(--site-theme-color);
          }
        }
      }

      &.is_disabled {
        > * {
          opacity: 0.4;
        }
      }
    }
  }

  .qa_question_menu_group {
    position: absolute;
    right: 10px;
    top: 23px;
    display: flex;
    z-index: 3;
  }

  .qa_question_menu {
    position: relative;
    margin-top: 3px;

    .qa_question_menu_icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;

      &:hover {
        svg path {
          fill: var(--site-theme-color);
        }
      }
    }

    &.is_hidden {
      visibility: hidden;
    }

    &:hover,
    &.active {
      > svg {
        path[stroke] {
          stroke: var(--site-theme-color);
        }
        path[fill] {
          fill: var(--site-theme-color);
        }
      }
    }
  }

  .qa_question_menu_list {
    display: none;
    position: absolute;
    right: 100%;
    top: 0;
    flex-direction: column;
    width: 185px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0;

    &.is_show {
      display: flex;
    }
  }

  .qa_question_menu_item {
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 7px 0 7px 20px;

    svg {
      min-width: 18px;
    }

    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 136%;
      color: black;
    }

    &:hover {
      span {
        color: var(--site-theme-color);
      }

      svg {
        path[fill] {
          fill: var(--site-theme-color);
        }
        path[stroke] {
          stroke: var(--site-theme-color);
        }
      }
    }
  }

  .qa_question__copy_answer_url_btn {
    &:hover {
      svg path {
        fill: var(--site-theme-color);
      }
    }

    input {
      display: none;
    }
  }
}

@include breakpoint-sm {
  .qa_question {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &.fixed {
      .qa_question_content_wrapper {
        padding: 0;
        overflow-y: hidden;
      }

      .qa_question_user_wapper,
      .qa_question_name_and_desc_wrapper,
      .qa_question__copy_answer_url_btn {
        display: none;
      }

      .qa_question_user.answered {
        margin-bottom: 0;
        height: 48px;
        background: $backgroundTiles;
        align-items: center;
        padding: 0 14px;
        cursor: pointer;
        position: relative;

        .qa_question_user_content {
          overflow: hidden;
          display: flex;
          justify-content: flex-end;
          flex-grow: 1;
        }

        .qa_question_user_close {
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: none;
          align-items: center;
          justify-content: center;
          background: $backgroundTiles;

          span {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: var(--site-theme-color);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        .qa_question_user_close_icon {
          position: relative;
          z-index: 2;
          display: flex;
        }

        &:hover {
          .qa_question_user_close_icon {
            svg path {
              stroke: var(--site-theme-color);
            }
          }
          .qa_question_user_close {
            display: flex;
            background: white;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(var(--site-theme-color-rgb), 0.05);
            }
          }
        }

        .qa_question_user_info {
          justify-content: flex-start;
          order: -1;
          margin-left: 21px;
          margin-right: 21px;
          overflow: hidden;

          .qa_question_time {
            margin-left: auto;
          }

          .qa_question_user_name {
            line-height: 20px;
            white-space: nowrap;
            margin-left: auto;
            width: 100%;

            &.is_overflow {
              display: flex;

              span {
                position: relative;
                display: block;
                overflow: hidden;

                &:after {
                  content: '';
                  position: absolute;
                  right: 0;
                  width: 33px;
                  height: 100%;
                  background: linear-gradient(
                    to left,
                    #f4f4f4 13.81%,
                    rgba(255, 255, 255, 0) 99.61%
                  );
                }
              }
            }
          }
        }
      }

      .qa_question_right_content {
        flex-basis: auto;
        padding-top: 0;
        height: 48px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        &.bottom {
          margin-left: -6px;
          flex-basis: auto;
          flex-direction: row;
          border-bottom: none;
          height: auto;
        }
      }

      .qa_question_content {
        flex-direction: column;
        max-width: 100%;
        position: relative;
        z-index: 3;
        background: white;

        &.add_other_answer {
          flex-direction: row;
          order: 1;
          margin-top: 11px;
          position: absolute;
          left: 2px;
          top: 48px;
          gap: 4px;
        }
        &.iframe {
          order: 2;
          margin-top: -1px;
          z-index: 1;
          min-height: auto;

          &.is_pending_answer {
            position: absolute;
            width: 100%;
            height: calc(100vh - 88px);
            z-index: $socialOverlayZIndex + 5;
            bottom: 0;
            left: 0;
            padding-top: 0;

            &.is_player_loaded {
              height: calc(100vh - 96px);
            }
          }
        }
      }

      .qa_question__selected_answer_controls {
        position: absolute;
        right: 32px;
        margin-top: 11px;
        top: 48px;
      }

      .qa_question__selected_answer_controls_choose_as_verified {
        height: 32px;
        width: auto;
        border-color: transparent;
        background: transparent;
        margin-right: 6px;

        &.is_verified {
          color: var(--site-theme-color);
          margin-left: 0;

          .default_label {
            display: flex;
            column-gap: 20px;

            svg {
              margin-top: 0;
            }
          }
          .hover_label {
            display: none;
          }
        }
      }

      .qa_question_answers_navigation {
        &.hidden {
          display: none;
        }
        button {
          width: 32px;
          height: 32px;
          padding: 0;

          &:disabled {
            opacity: 1;

            svg {
              opacity: 0.4;
            }
          }
        }

        span {
          display: none;
        }
      }

      .qa_question__selected_answer_feedback_none_buttons {
        gap: 4px;

        button {
          width: 75px;
          height: 32px;
          background: transparent;
          border-color: transparent;
          gap: 10px;
          font-size: 13px;
          line-height: 150%;

          span {
            flex-grow: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }

      .qa_question_menu_group {
        right: 6px;
        top: 55px;

        .qa_question_menu_icon {
          position: relative;
          z-index: 2;
        }

        .qa_question_menu_list {
          width: 100vw;
          top: -8px;
          right: -6px;
          z-index: 1;
          padding-top: 7px;
          padding-bottom: 9px;
        }

        .qa_question_menu_item {
          padding-block: 6px;

          span {
            font-size: 13px;
            line-height: 136%;
          }
        }
      }

      .qa_question_menu_icon {
        width: 20px;
      }

      .qa_question_add_other_answer_btn {
        width: 32px;
        height: 32px;
        margin-left: 0;
        border-color: transparent;
        padding: 0;
        display: none;

        &.isDisabled {
          opacity: 0.4;

          &:hover {
            background: black;
          }
        }
      }
    }

    &__actions {
      right: 10px;
    }

    .qa_question__header {
      padding: 16px;
      flex-shrink: 0;
      flex-direction: column;
    }

    .qa_question__submitAnswer,
    .qa_question__verifyAnswer {
      margin-right: 0;
      margin-top: 20px;
    }

    .qa_question__date {
      font-size: 15px;
      line-height: 20px;
      margin-left: 12px;
    }

    .qa_question__info {
      margin-left: 0;
      margin-top: 16px;
    }

    .qa_question__info_title > span {
      @include fontExtension;
      display: block;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .qa_question__info_tag {
      @include fontExtension;
    }

    .adding_answer__container {
      margin-top: 0;
      height: 100%;
    }

    .adding_answer__question {
      padding: 20px;
    }

    .adding_answer__question_user p {
      @include fontExtension;
    }

    .adding_answer__answer {
      padding: 0;

      h4 {
        @include fontExtension;
        line-height: 24px;
        padding: 16px 20px;
      }
    }

    .adding_answer__answer_url input {
      @include fontExtension;
    }

    .adding_answer__answer_or {
      @include fontExtension;
      margin: 16px 0;
    }

    .adding_answer__answer_captureBtn {
      @include fontExtension;
      max-width: 242px;
      margin: 0 auto;
    }

    .submit_answer_group button {
      @include fontExtension;
      width: auto;
    }

    .qa_question__add_answer {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: white;
      z-index: $socialOverlayZIndex + 4;
    }

    .qa_question__add_answer_header {
      height: 48px;
      background: $backgroundTiles;
      font-size: 13px;
      line-height: 20px;
      color: black;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 14px;

      .qa_question__add_answer_header_icon {
        margin-right: 17px;
      }

      .qa_question__add_answer_header_close {
        display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .qa_question__add_answer_header_close_icon {
        position: absolute;
        right: 0;
      }

      &:hover {
        .qa_question__add_answer_header_icon {
          display: none;
        }
        span {
          display: none;
          &.qa_question__add_answer_header_close {
            display: block;
          }
        }
      }
    }

    .qa_question__add_answer_url {
      margin-top: 1px;
    }

    .qa_question__add_answer_url_input {
      padding-left: 50px;
      padding-right: 14px;
      height: 40px;
    }

    .qa_question__add_answer_url_icon {
      width: 40px;
      height: 40px;
    }

    .qa_question__add_answer_or {
      color: rgba(0, 0, 0, 0.4);
      height: 56px;
      font-size: 13px;
      line-height: 20px;
    }

    .qa_question__add_answer_btn_group {
      flex-direction: column;
      align-items: center;
      row-gap: 10px;

      button {
        flex-basis: 48px;
        width: 240px;
        gap: 21px;
      }
    }

    .qa_question__submit_answer_btn_group {
      margin-top: 0;

      .qa_question__add_answer_cancel_btn {
        display: none;
      }
    }

    .qa_question__add_answer_submit_btn {
      height: 48px;
    }

    .qa_question_content {
      flex-direction: column;
    }

    .qa_question_user_wapper {
      position: relative;
    }

    .qa_question_user_info {
      margin-left: 17px;
      margin-bottom: 0;
      overflow: hidden;

      .qa_question_user_name {
        font-size: 13px;
        line-height: 13px;
        width: 100%;
        white-space: nowrap;

        &.is_overflow {
          display: flex;

          span {
            position: relative;
            display: block;
            overflow: hidden;

            &:after {
              content: '';
              position: absolute;
              right: 0;
              width: 33px;
              height: 100%;
              background: linear-gradient(
                to left,
                #fff 13.81%,
                rgba(255, 255, 255, 0) 99.61%
              );
            }
          }
        }
      }
    }

    .qa_question_name_and_desc_wrapper {
      flex-direction: column;
    }

    .qa_question_collapsed_desc_btn {
      position: unset;
      margin-top: 7px;

      &.isPhone:hover {
        color: black;
      }
    }

    .qa_question_name_and_desc {
      min-height: auto;
      margin-top: 11px;
      margin-bottom: 0;

      &.is_collapsed {
        max-height: 95px;
        text-overflow: ellipsis;
        display: -webkit-box;
        // line-clamp: 4;
        // -webkit-line-clamp: 4;
      }
    }

    .qa_question_desc {
      margin-top: 5.6px;
      margin-bottom: 0;
    }

    .qa_question_follow_group {
      position: absolute;
      bottom: 0;
      width: 100%;
      gap: 10px;
      justify-content: center;
      min-height: auto;

      .qa_question_follow_btn {
        width: 240px;
        height: 48px;
        background: $backgroundTiles;
        border-color: $backgroundTiles;
        margin-right: 0;

        &.hoverable {
          background: white;
          color: var(--site-theme-color);
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(var(--site-theme-color-rgb), 0.05);
          }
        }
      }
    }

    .qa_question_follow_users {
      display: none;
    }

    .qa_question__copy_answer_url_btn {
      margin-top: -2px;
      margin-right: 5px;
    }

    .qa_question_menu {
      .qa_question_menu_icon {
        width: 20px;
        height: 20px;
        position: relative;
        z-index: 2;
      }

      .qa_question_menu_list {
        top: -10px;
        right: -4px;
        width: calc(100vw - 24px);
        z-index: 1;
        padding: 7px 0;
      }

      .qa_question_menu_item {
        padding-left: 11px;

        span {
          font-size: 13px;
        }
      }
    }

    .qa_question_right_content {
      flex-basis: auto;
      margin-left: 0;
      padding: 0;

      &.is_profile_layout {
        flex-basis: auto;
      }

      &.is_expanded {
        padding-bottom: 73px;

        &.no_padding {
          padding-bottom: 0;
        }
      }
    }

    .qa_question_capture_group {
      margin-top: 30px;
      align-items: center;
    }

    .qa_question_capture_btn {
      width: 240px;
      height: 48px;

      &.isDisabled {
        opacity: 0.4;

        &:hover {
          background: black;
        }
      }
    }

    .qa_question_tag_item {
      height: 20px;
      font-size: 13px;

      &:hover {
        .progressBar {
          height: 38px;
        }
      }
    }

    .qa_question_tag_item_spacer {
      flex-grow: 1;
      height: 0;
    }
  }
}

.qa_question__copy_answer_url {
  background: white;
  padding: 4px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(var(--site-theme-color-rgb), 0.05);
  }

  * {
    color: var(--site-theme-color);
    font-size: 10px;
    line-height: 12px;
  }
}

.qa_question_close_overlay_btn {
  @include btn_contained;
  width: 40px;
  height: 40px;
  position: fixed;
  top: 4px;
  right: 4px;
  z-index: $socialOverlayZIndex + 1;
}
