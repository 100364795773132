@import '~theme/modules/variables';
@import '~theme/mixins';

.customersSection {
  padding-top: 52px;
  padding-bottom: 98px;
  background: #f4f4f4;

  .customersSection__wrapper {
    display: flex;
    flex-direction: column;
    max-width: $maxWidthContent;
    width: 100%;
    margin: 0 auto;
    padding: 0 64px;
  }

  .customersSection__title {
    font-family: 'iorad';
    font-size: 204px;
    line-height: 67%;
    color: black;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 83px;
  }

  .customersSection__desc {
    font-size: 15px;
    line-height: 136%;
    color: black;
    margin: 0;
    margin-bottom: 32px;
    width: 100%;
    max-width: 618px;

    b {
      font-weight: 600;
    }
  }

  .customersSection__content {
    display: flex;
    justify-content: space-between;
  }

  .customersSection__testimonials {
    display: flex;
    flex-direction: column;

    .customersSection__testimonials_title {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      margin-bottom: 10px;
    }

    .customersSection__testimonials_content {
      display: flex;
      column-gap: 45px;
    }

    .customersSection__logos {
      display: grid;
      grid-template-columns: 198px 198px;
      grid-template-rows: 115px 115px 115px;
      gap: 5px;
    }

    .customersSection__logos_item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;

      &:hover,
      &.active {
        background: transparent;
      }
    }

    .customersSection__testimonials_active_item {
      width: 100%;
      max-width: 344px;

      .card {
        display: flex;
        flex-direction: column;

        .card__header {
          display: flex;
          min-height: 90px;
        }

        .card__image {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          filter: grayscale(1);
          object-fit: cover;
          object-position: top;
          margin-top: 4px;
        }

        .card__info {
          display: flex;
          flex-direction: column;
          color: #000000;
          width: 100%;
          margin-left: 24px;
        }

        .card__position {
          font-size: 15px;
          line-height: 136%;
          margin-bottom: 0;
          font-weight: normal;
        }

        .card__name {
          font-weight: 600;
          font-size: 15px;
          line-height: 136%;
          margin: 0;
        }

        .card__content {
          font-size: 15px;
          line-height: 136%;
          color: black;
          margin: 0;
          margin-top: 24px;
        }
      }
    }
  }

  .customersSection__stories {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 342px;

    .customersSection__stories_title {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;
      margin-bottom: 10px;
    }

    .customersSection__stories_content {
      display: flex;
      flex-direction: column;
    }

    .customersSection__stories_list {
      display: flex;
      flex-direction: column;
      row-gap: 13px;
    }

    .customersSection__stories_item {
      height: 170px;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .customersSection__stories_more {
      font-size: 15px;
      font-weight: 600;
      line-height: 136%;
      color: black;
      margin-top: 23px;
      text-decoration: underline;
      text-align: end;
    }
  }
}

@include breakpoint-sm {
  .customersSection {
    padding-top: 92px;
    padding-bottom: 25px;

    .customersSection__wrapper {
      max-width: 100%;
      padding: 0 24px;
    }

    .customersSection__title_img {
      width: 100%;
    }

    .customersSection__desc {
      font-size: 13px;
      margin-bottom: 65px;
      max-width: 100%;
      margin-top: 20px;
    }

    .customersSection__content {
      flex-direction: column;
      justify-content: flex-start;
    }

    .customersSection__testimonials {
      .customersSection__testimonials_title {
        font-size: 13px;
        margin-bottom: 20px;
      }

      .customersSection__testimonials_content {
        flex-direction: column;
      }

      .customersSection__logos {
        grid-template-columns: 162px 162px;
        grid-template-rows: 94px 94px 94px;
      }

      .customersSection__testimonials_active_item {
        max-width: 100%;
        margin-top: 48px;

        .card {
          .card__header {
            min-height: auto;
          }

          .card__image {
            width: 56px;
            height: 56px;
          }

          .card__info {
            margin-left: 16px;
            justify-content: space-between;
          }

          .card__position {
            font-size: 13px;
          }

          .card__name {
            font-size: 13px;
            line-height: 13px;
          }

          .card__content {
            font-size: 13px;
            margin-top: 16px;
          }
        }
      }
    }

    .customersSection__stories {
      max-width: 100%;

      .customersSection__stories_title {
        font-size: 13px;
        margin-bottom: 20px;
      }

      .customersSection__stories_list {
        row-gap: 11px;
      }

      .customersSection__stories_item {
        height: 163px;
      }

      .customersSection__stories_more {
        font-size: 13px;
        margin-top: 20px;
      }
    }
  }
}
