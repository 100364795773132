@import '../../variables';

.homescreen {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  .homescreen__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 32px 0;

    svg {
      color: inherit;

      [fill] {
        fill: currentColor;
        color: inherit;
      }

      [stroke] {
        stroke: currentColor;
        color: inherit;
      }
    }

    h4 {
      font-size: 21px;
      line-height: 24px;
      margin: 0;
      margin-bottom: 24px;
    }

    p {
      @include defaultFont;
      margin: 0;
    }

    .homescreen__header_close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: $defaultHeightBtn;
      width: $defaultHeightBtn;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .homescreen__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 192px 80px $defaultHeightBtn;
    position: relative;
    overflow: hidden;
  }

  .homescreen__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    img {
      height: 32px;
      width: auto;
    }
  }

  .homescreen__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 80px;
    background-color: white;
    padding: 0 40px;

    label {
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 24px;

      span {
        @include defaultFont;
        color: black;

        b {
          font-weight: 600;
        }
      }

      &.next {
        svg {
          transform: rotate(270deg);
        }
      }
    }
  }
}

.hc_title {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 940px;
  padding: 16px 40px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentColor;
    opacity: 0;
  }

  &:focus-within {
    &::after {
      opacity: 1;
    }

    .hc_title__icon {
      visibility: visible;
    }
  }

  input {
    width: 100%;
    outline: none;
    border: none;
    padding: 0;
    background: transparent;
    font-size: 35px;
    line-height: 40px;
    font-weight: 500;
    text-align: center;
  }

  .hc_title__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    visibility: hidden;

    svg path {
      &[fill] {
        fill: currentColor;
      }
      &[stroke] {
        stroke: currentColor;
      }
    }
  }
}

.hc_intro {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 940px;
  padding: 16px 40px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: currentColor;
    opacity: 0;
  }

  &:focus-within {
    &::after {
      opacity: 1;
    }

    .hc_intro__icon {
      visibility: visible;
    }
  }

  input {
    width: 100%;
    outline: none;
    border: none;
    padding: 0;
    background: transparent;
    @include defaultFont;
    font-weight: 400;
    text-align: center;
  }

  .hc_intro__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    visibility: hidden;

    svg path {
      &[fill] {
        fill: currentColor;
      }
      &[stroke] {
        stroke: currentColor;
      }
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .homescreen {
    .homescreen__inner {
      padding: 24px 13px 24px 24px;
      overflow-y: scroll;
      position: unset;
    }
  }

  .hc_title {
    input {
      font-size: 21px;
    }
  }
}
