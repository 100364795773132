@import '~theme/mixins';
@import '~theme/modules/variables';

.content_editable_component {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  @include fontHelpCenter;
  caret-color: var(--site-theme-color);
  position: relative;
  padding: 16px;
  cursor: text;

  &:focus-within {
    border-color: black;

    .content_editable_component__charactor_count {
      color: black;
    }
  }

  .content_editable_component_content[contentEditable] {
    display: flex;
    align-items: center;
    border: 0;
    resize: none;
    flex-grow: 1;
    word-break: break-word;

    &[placeholder]:empty::before {
      content: attr(placeholder);
      color: rgba(0, 0, 0, 0.4);
    }

    &:focus-visible {
      outline: none;
    }
  }

  .content_editable_component__charactor_count {
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    line-height: 136%;
    color: rgba(0, 0, 0, 0.4);
  }
}
