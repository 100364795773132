@import '~theme/modules/variables';
@import '~theme/mixins';

.videoSection {
  background: url('~assets/img/redesign2021/global/gradient_grey_bottom.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  height: calc(100vh - #{$topNavBarHeight});
  min-height: 600px;
  max-height: 768px;
  padding-top: $topNavBarHeight;

  &.isLogged {
    height: calc(100vh - #{$topNavBarHeightLogged});
    padding-top: 44px;
  }

  .contentWrapper {
    display: flex;
    column-gap: 75px;
    max-width: $maxWidthContent;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 115px 64px 100px;

    @media screen and (max-height: 700px) {
      padding: 64px;
    }
  }

  .titleWrapper {
    width: 100%;
    max-width: 495px;
    display: flex;
    flex-direction: column;
  }

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000;
    margin: 0;

    span {
      color: var(--site-theme-color);
    }
  }

  .content {
    display: flex;
    flex-grow: 1;
    margin-top: 11px;

    iframe {
      outline: none;
      border: none;
      background: rgba(0, 0, 0, 0.02);
    }
  }
}

@include breakpoint-sm {
  .videoSection {
    background: unset;
    height: 100%;
    max-height: 100%;
    padding-top: 58px;

    &.isLogged {
      padding-top: 0;
    }

    .contentWrapper {
      flex-direction: column;
      column-gap: 0;
      max-width: 100%;
      padding: 130px 0 0;
    }

    .titleWrapper {
      max-width: 100%;
      padding: 0 24px;
    }

    .title {
      font-size: 21px;
      margin-bottom: 4px;
    }

    .content {
      margin-top: auto;
      padding: 24px;
      height: 633px;
    }
  }
}
