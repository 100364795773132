@import '../../variables';

.libraries {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .libraries__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #f4f4f4;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    @include defaultFont;
  }

  .libraries__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
    position: relative;
    // background-color: rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.2);

    h4 {
      font-size: 21px;
      line-height: 24px;
      margin: 0;
      margin-bottom: 24px;
    }

    p {
      @include defaultFont;
      margin: 0;
    }

    .libraries__header_close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: $defaultHeightBtn;
      width: $defaultHeightBtn;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .libraries__content {
    display: flex;
    column-gap: 1px;
    background: white;
    border-top: 1px solid white;
    height: 100%;
    overflow: hidden;

    .libraries__content_left {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: #f4f4f4;
    }

    .libraries__content_right {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: #f4f4f4;
    }
  }

  .libraries__tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    .libraries__tabs_header {
      display: flex;
      flex-shrink: 0;
      column-gap: 1px;
      height: $defaultHeightBtn;
      margin: 16px 16px 0;
      border: 1px solid white;
      background: white;

      .libraries__tabs_header_item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 100%;
        background: #f4f4f4;
        cursor: pointer;

        > * {
          opacity: 0.4;
        }

        span {
          @include defaultFont;
        }

        &.active {
          background: white;

          > * {
            opacity: 1;
          }
        }

        &:hover {
          > * {
            opacity: 1;
          }
        }
      }
    }

    .libraries__tabs_content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      position: relative; // for adding/updating the external tutorial popup
    }
  }

  .libraries__learner_view_options {
    height: $defaultHeightHeader;
    border-bottom: none;
    max-width: max-content;
  }

  .libraries__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 80px;
    background-color: white;
    padding: 0 40px;

    label {
      margin: 0;
      display: flex;
      align-items: center;
      column-gap: 24px;

      span {
        @include defaultFont;
        color: black;

        b {
          font-weight: 600;
        }
      }

      &.back {
        span {
          font-weight: 600;
        }
        svg {
          transform: rotate(90deg);
        }
      }

      &.next {
        svg {
          transform: rotate(270deg);
        }
      }
    }
  }
}

.available_tutorials {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .available_tutorials__header {
    display: flex;
    flex-direction: column;
    margin: 0 16px;

    input {
      padding: 0 16px;
    }

    .available_tutorials__expand_all {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: $defaultHeightBtn;
      margin: 0;
      @include defaultFont;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      padding-left: 16px;
    }

    &_inner {
      display: flex;
      align-items: center;
      position: relative;

      input {
        padding-right: 75px;
      }
    }

    &_buttons {
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .filter_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      margin: 0;
    }
  }

  .available_tutorials__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 5px;
  }

  .available_tutorials__footer {
    display: flex;
  }

  .available_tutorials__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    @include defaultFont;
    background: white;
    height: 100%;
  }

  .available_tutorials__list {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: white;

    .available_tutorials__list_item {
      display: flex;
      align-items: center;
      padding: 16px;
      background: white;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      &:hover {
        background: var(--background-color);

        .available_tutorials__list_item_open,
        .available_tutorials__list_item_add {
          visibility: visible;
        }
      }

      &:first-child {
        border-top: none;
      }

      label {
        margin: 0;

        span {
          @include defaultFont;
          word-break: break-word;
          margin-right: 16px;
        }

        .available_tutorials__list_item_open {
          visibility: hidden;
          opacity: 0.4;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }

      .available_tutorials__list_item_add {
        @include defaultFont;
        font-weight: 600;
        padding-left: 16px;
        margin-left: auto;
        visibility: hidden;
        align-self: flex-end;
      }
    }
  }
}
