@import '~theme/modules/variables';
@import '~theme/mixins';

.content {
  margin-top: 75px;

  &.isLogged {
    margin-top: 119px;
  }

  .sectionBreadcrumb {
    display: flex;
    max-width: $maxWidthContent;
    width: 100%;
    margin: 0 auto 33px;
    padding: 0 64px;

    .sectionBreadcrumb__button {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-size: 15px;
        line-height: 20px;
        text-decoration: underline;
        margin-left: 15px;
        color: black;
      }

      &:hover {
        svg path {
          fill: var(--site-theme-color);
        }
        span {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .titleWrapper {
    background: #f4f4f4;

    .titleContent {
      max-width: $maxWidthContent;
      margin: 0 auto;
      padding: 52px 64px 78px;

      .title {
        h2 {
          @include ioradFont;
        }
      }

      .desc {
        font-size: 15px;
        line-height: 20px;
        color: black;
        width: 100%;
        max-width: 618px;
        margin: 0;
        margin-top: 87px;
      }
    }
  }

  .contentWrapper {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 95px 64px 68px;

    .stories_list {
      display: flex;
      flex-wrap: wrap;
      gap: 60px 20px;

      .stories_item {
        display: flex;
        flex-direction: column;
        width: 294px;

        img {
          width: 100%;
          height: 147px;
        }

        h5 {
          margin: 0;
          font-size: 21px;
          line-height: 24px;
          color: black;
          font-weight: 600;
          margin-top: 30px;
          min-height: 48px;
        }

        p {
          font-size: 15px;
          line-height: 136%;
          color: black;
          margin: 0;
          margin-top: 5px;
        }

        button {
          border: none;
          outline: none;
          padding: 0;
          background-color: transparent;
          display: flex;
          align-items: center;
          column-gap: 24px;
          margin-top: 13px;

          &:hover {
            svg path {
              fill: var(--site-theme-color);
            }

            span {
              color: var(--site-theme-color);
            }
          }

          span {
            font-size: 15px;
            font-weight: 600;
            color: black;
          }
        }

        a {
          display: flex;
          align-items: center;
          column-gap: 15px;
          margin-top: 13px;

          &:hover {
            svg {
              [fill] {
                fill: var(--site-theme-color);
              }
              [stroke] {
                stroke: var(--site-theme-color);
              }
            }

            span {
              color: var(--site-theme-color);
            }
          }

          span {
            font-size: 15px;
            font-weight: 600;
            color: black;
          }
        }
      }
    }
  }
}

.detailpage {
  margin-top: 75px;
  margin-bottom: 68px;
  display: flex;
  flex-direction: column;

  &.isLogged {
    margin-top: 119px;
  }

  .sectionBreadcrumb {
    display: flex;
    max-width: $maxWidthContent;
    width: 100%;
    margin: 0 auto 33px;
    padding: 0 64px;

    .sectionBreadcrumb__button {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-size: 15px;
        line-height: 20px;
        text-decoration: underline;
        margin-left: 15px;
        color: black;
      }

      &:hover {
        svg path {
          fill: var(--site-theme-color);
        }
        span {
          color: var(--site-theme-color);
        }
      }
    }
  }

  .sectionTitle {
    background: #f4f4f4;
    min-height: 370px;
    flex-shrink: 0;

    .sectionTitle__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 52px 64px 44px;

      h2 {
        @include ioradFont;
      }
    }
  }

  .sectionContent {
    max-width: $maxWidthContent;
    width: 100%;
    margin: 76px auto 0;
    padding: 0 64px;
    display: flex;
    flex-direction: column;

    .sectionContent__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 810px;
    }

    .sectionContent__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 136%;
      color: #000000;
      margin: 0;
      margin-bottom: 60px;
    }

    .sectionContent__image {
      width: 100%;
      max-width: 808px;
      margin-bottom: 61px;
    }

    .sectionContent__desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin: 0;

      b {
        font-weight: 600;
      }

      a {
        text-decoration: underline;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 22px;
          font-weight: 600;

          &::before {
            position: absolute;
            content: url('~assets/img/redesign2021/customerpage/stories/checked.svg');
            width: 16px;
            height: 16px;
            left: 0;
          }
        }
      }
    }
  }

  .sectionExploreMore {
    display: flex;
    flex-direction: column;
    margin-top: 102px;

    .sectionExploreMore__content {
      max-width: $maxWidthContent;
      width: 100%;
      margin: 0 auto;
      padding: 0 64px;
    }

    .sectionExploreMore__title {
      font-size: 32px;
      line-height: 136%;
      font-weight: 600;
      color: black;
      margin: 0;
      margin-bottom: 22px;
    }

    .sectionExploreMore__desc {
      font-size: 15px;
      line-height: 136%;
      color: black;
      margin-bottom: 62px;
    }

    .sectionExploreMore__list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 52px 20px;
    }

    .sectionExploreMore__item {
      display: flex;
      flex-direction: column;
      width: 294px;

      .sectionExploreMore__item_img {
        width: 100%;
        height: 147px;
        margin-bottom: 21px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .sectionExploreMore__item_title {
        font-weight: 600;
        font-size: 15px;
        line-height: 136%;
        color: #000000;
        margin: 0;
        margin-bottom: 10px;
      }

      .sectionExploreMore__item_desc {
        font-size: 15px;
        line-height: 136%;
        color: #000000;
        margin: 0;
      }
    }
  }

  @include breakpoint-sm {
    margin-top: 3px;

    &.isLogged {
      margin-top: 3px;
    }

    .sectionBreadcrumb {
      max-width: 100%;
      margin-bottom: 13px;
      padding: 0 24px;

      .sectionBreadcrumb__button {
        opacity: 0.4;
        span {
          margin-left: 26px;
          text-decoration: none;
        }
      }
    }

    .sectionTitle {
      min-height: auto;

      .sectionTitle__content {
        padding: 0;

        img {
          width: 100%;
        }
      }
    }

    .sectionContent {
      max-width: 100%;
      margin-top: 33px;
      padding: 0 24px;

      .sectionContent__content {
        max-width: 100%;
      }

      .sectionContent__title {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 19px;
      }

      .sectionContent__desc {
        font-size: 13px;
      }
    }

    .sectionExploreMore {
      margin-top: 114px;

      .sectionExploreMore__content {
        max-width: 100%;
        padding: 0 24px;
      }

      .sectionExploreMore__title {
        font-size: 21px;
        line-height: 24px;
        margin-bottom: 21px;
      }

      .sectionExploreMore__desc {
        font-size: 13px;
        margin-bottom: 82px;
      }

      .sectionExploreMore__list {
        flex-direction: column;
        row-gap: 63px;
      }

      .sectionExploreMore__item {
        width: 100%;

        .sectionExploreMore__item_img {
          height: 163px;
          margin-bottom: 22px;
        }

        .sectionExploreMore__item_title {
          font-size: 13px;
          margin-bottom: 11px;
        }

        .sectionExploreMore__item_desc {
          font-size: 13px;
        }
      }
    }

    &[data-page='finding-the-right-solution'] {
      .sectionTitle__content {
        padding-top: 70px;
        padding-bottom: 82px;
      }
    }
  }
}

.footerWrapper {
  background-image: url('~assets/img/redesign2021/global/gradient_grey_top.png');
  background-position: top center;
  background-repeat: no-repeat;

  .footer {
    max-width: $maxWidthContent;
    margin: 0 auto;
    padding: 170px 158px 140px 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitle {
    font-weight: 600;
    font-size: 32px;
    line-height: 136%;
    color: #000000;
    margin: 0;
  }

  .learnMoreBtn {
    border: none;
    outline: none;
    width: 276px;
    height: 48px;
    background: #000;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    line-height: 136%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--site-theme-color);
    }
  }
}

.videoPopup {
  position: fixed;
  z-index: 40;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 86px 0;
  background-color: #000;
  z-index: #{$embedButtonZIndex + 1};

  .videoContent {
    height: 100%;
  }

  .closeBtn {
    position: absolute;
    right: 64px;
    top: 24px;
    cursor: pointer;
  }
}

@include breakpoint-sm {
  .content {
    margin-top: 3px;

    &.isLogged {
      margin-top: 3px;
    }

    .sectionBreadcrumb {
      max-width: 100%;
      margin-bottom: 13px;
      padding: 0 24px;

      .sectionBreadcrumb__button {
        opacity: 0.4;
        span {
          margin-left: 26px;
          text-decoration: none;
        }
      }
    }

    .titleWrapper {
      .titleContent {
        max-width: 100%;
        padding: 64px 0 104px;

        .title {
          img {
            width: 100%;
          }
        }

        .desc {
          font-size: 13px;
          line-height: 18px;
          max-width: 100%;
          margin: 0;
          margin-top: 41px;
          padding: 0 24px;
        }
      }
    }

    .contentWrapper {
      max-width: 100%;
      padding: 58px 24px 39px;

      .stories_list {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 39px 0;

        .stories_item {
          width: 100%;

          img {
            height: 163px;
          }

          h5 {
            min-height: auto;
          }

          p {
            font-size: 13px;
            margin-top: 21px;
          }

          button {
            column-gap: 21px;
            margin-top: 16px;

            span {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .footerWrapper {
    background-image: url('~assets/img/redesign2021/global/mobile/gradient_grey_top.png');
    background-size: 100% 331px;

    .footer {
      padding: 164px 24px 236px;
      flex-direction: column;
      align-items: flex-start;
    }

    .footerTitle {
      font-size: 21px;
      margin-bottom: 72px;
    }

    .learnMoreBtn {
      width: 295px;
      font-size: 13px;
      margin: 0 auto;
    }
  }

  .videoPopup {
    padding: 0;

    .closeBtn {
      right: 24px;
    }

    .videoContent {
      display: flex;

      iframe {
        max-height: 211px;
        margin: auto 0;
      }
    }
  }
}
